import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import { format } from "date-fns";
import React from "react";
import { CSVLink } from "react-csv";
import copyText from "../../copyText";

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

type Props = {
  csvData: CSVData;
};

export default function AzureCommittedUseRecommendationsTableControls(
  props: Props
) {
  return (
    <Flex justifyContent="end" minHeight={30}>
      <Box>
        <CSVLink
          data={props.csvData.rows}
          headers={props.csvData.headers}
          filename={`Azure-Recommendations-${format(new Date(), "MM-dd-yyyy")}`}
        >
          <Button
            iconStart={<Icon color="inherit" icon={faFileExport} />}
            secondary
            size="small"
          >
            {copyText.exportButtonLabel}
          </Button>
        </CSVLink>
      </Box>
    </Flex>
  );
}
