export const FeatureFlag = {
  ALLOW_TLOS_SLOW_PATH: "ALLOW_TLOS_SLOW_PATH",
  BYPASS_TLOS_SLOW_PATH: "BYPASS_TLOS_SLOW_PATH",
  CUSTOM_PRICING: "CUSTOM_PRICING",
  FOCUS_DATASETS: "FOCUS_DATASETS",
  FOCUS_TRE: "FOCUS_TRE",
  MSP_PUBLIC_SECTOR: "MSP_PUBLIC_SECTOR",
} as const;

export type FeatureFlag = (typeof FeatureFlag)[keyof typeof FeatureFlag];
