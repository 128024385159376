import { createColumnHelper } from "@tanstack/react-table";
import { formatTimestamp } from "@ternary/api-lib/analytics/utils/ChartFormatUtils";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import Text from "@ternary/web-ui-lib/components/Text";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import copyText from "../../copyText";

type TableData = {
  accountName: string;
  averagePerDatabaseDailyDatabaseBytes: number;
  averagePerDatabaseDailyFailsafeBytes: number;
  comment: string;
  databaseID: number;
  databaseName: string;
  databaseOwner: string;
  deletionTime: string;
  lastAlteredTime: string;
  retentionDays: number;
};

interface Props {
  isLoading: boolean;
  databases: TableData[];
}

export function SnowflakeDatabaseUsageTable(props: Props): JSX.Element {
  const columnHelper = createColumnHelper<TableData>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("accountName", {
        header: copyText.snowflakeDatabaseTableHeader_accountName,
        size: 25,
      }),
      columnHelper.accessor("databaseName", {
        cell: ({ getValue }) => getValue().replace(/_/g, " "),
        header: copyText.snowflakeDatabaseTableHeader_databaseName,
        size: 50,
      }),
      columnHelper.accessor("databaseID", {
        header: copyText.snowflakeDatabaseTableHeader_databaseID,
        size: 20,
      }),
      columnHelper.accessor("databaseOwner", {
        header: copyText.snowflakeDatabaseTableHeader_databaseOwner,
        size: 30,
      }),
      columnHelper.accessor("lastAlteredTime", {
        cell: ({ getValue }) => formatTimestamp(getValue(), "MM/dd/yyyy"),
        header: copyText.snowflakeDatabaseTableHeader_lastAltered,
        size: 25,
      }),
      columnHelper.accessor("deletionTime", {
        cell: ({ getValue }) => (getValue().length ? getValue() : "--"),
        header: copyText.snowflakeDatabaseTableHeader_deletionTime,
        size: 25,
      }),
      columnHelper.accessor("retentionDays", {
        header: copyText.snowflakeDatabaseTableHeader_retentionTime,
        size: 25,
      }),
      columnHelper.accessor("averagePerDatabaseDailyDatabaseBytes", {
        cell: ({ getValue }) => prettyBytes(getValue()),
        header: copyText.snowflakeDatabaseTableHeader_avgBytes,
        size: 25,
      }),
      columnHelper.accessor("averagePerDatabaseDailyFailsafeBytes", {
        cell: ({ getValue }) => prettyBytes(getValue()),
        header: copyText.snowflakeDatabaseTableHeader_avgFailsafeBytes,
        size: 25,
      }),
      columnHelper.accessor("comment", {
        cell: ({ getValue }) => (
          <Text overflowWrap="break-word">
            {getValue().length ? getValue() : "--"}
          </Text>
        ),
        header: copyText.snowflakeDatabaseTableHeader_comment,
        size: 25,
      }),
    ],
    [props.databases]
  );

  return (
    <Table
      columns={columns}
      data={props.databases}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}
