import styled from "@emotion/styled";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import palette from "@ternary/api-lib/ui-lib/theme/palette";
import React from "react";
import { createPortal } from "react-dom";

const DRAWER_WIDTH = 560;

const Overlay = styled("div")<{ isOpen?: boolean }>(({ isOpen }) => ({
  backgroundColor: "rgba(30, 35, 40, 0.50)",
  height: isOpen ? "100vh" : 0,
  position: "fixed",
  right: 0,
  top: 0,
  width: isOpen ? "100vw" : 0,
  zIndex: 1_000_000, // To get above zendesk button
}));

const Drawer = styled("div")<{ isOpen?: boolean }>(({ isOpen, theme }) => ({
  backgroundColor: theme.side_drawer_background_color,
  display: "flex",
  flexDirection: "column",
  height: "100%",
  overflowX: "hidden",
  overflowY: "auto",
  position: "fixed",
  right: isOpen ? 0 : `-${DRAWER_WIDTH}px`,
  top: 0,
  transition: "right 0.3s ease",
  width: DRAWER_WIDTH,
}));

const Header = styled("div")(({ theme }) => ({
  flexShrink: 0,
  marginBottom: theme.space_xs,
  paddingLeft: theme.space_lg,
  paddingRight: theme.space_lg,
  paddingTop: theme.space_lg,
}));

const Caption = styled("p")(({ theme }) => ({
  color: theme.text_color_caption,
  lineHeight: "20px",
  marginBottom: 0,
  marginTop: theme.space_xs,
}));

const Content = styled("div")({
  flexGrow: 1,
  overflowY: "auto",
});

interface Props {
  content: () => JSX.Element | null;
  isOpen: boolean;
  title: string;
  titleCaption?: string;
  onClose: () => void;
}

export function SideDrawer(props: Props) {
  const sideDrawer = (
    <Overlay isOpen={props.isOpen}>
      <Drawer isOpen={props.isOpen}>
        <Header>
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <Text bold fontSize="18px">
              {props.title}
            </Text>
            <Button
              iconEnd={<Icon color={palette.grey[60]} icon={faXmark} />}
              onClick={props.onClose}
            />
          </Flex>
          {props.titleCaption && <Caption>{props.titleCaption}</Caption>}
        </Header>
        <Content>{props.content()}</Content>
      </Drawer>
    </Overlay>
  );

  return createPortal(sideDrawer, document.body);
}
