import { UnitType } from "../enums";

const measures = [
  { name: "cost", unit: UnitType.CURRENCY },
  { name: "absoluteCredits", unit: UnitType.CURRENCY },
  { name: "netCost", unit: UnitType.CURRENCY },
  { name: "usageAmount", unit: UnitType.STANDARD },
  { name: "usageAmountInPricingUnits", unit: UnitType.STANDARD },
  { name: "customNetCost", unit: UnitType.CURRENCY },
  { name: "amortizedCost", unit: UnitType.CURRENCY },
  { name: "netAmortizedCost", unit: UnitType.CURRENCY },
  { name: "indirectCost", unit: UnitType.CURRENCY },
  { name: "absoluteCreditsResellerMargin", unit: UnitType.CURRENCY },
];

// If customer has AWS integration we combine these with regular
// billing measures at run time.
export const awsBillingMeasures = [
  { name: "awsUsageBlendedCost", unit: UnitType.CURRENCY },
  { name: "awsNetUnblendedCost", unit: UnitType.CURRENCY },
  { name: "awsReservationEffectiveCost", unit: UnitType.CURRENCY },
  { name: "awsReservationRecurringFeeForUsage", unit: UnitType.CURRENCY },
  {
    name: "awsReservationAmortizedUpfrontCostForUsage",
    unit: UnitType.CURRENCY,
  },
  {
    name: "awsReservationAmortizedUpfrontFeeForBillingPeriod",
    unit: UnitType.CURRENCY,
  },
  { name: "awsSavingsPlanEffectiveCost", unit: UnitType.CURRENCY },
  {
    name: "awsSavingsPlanAmortizedUpfrontCommitmentForBillingPeriod",
    unit: UnitType.CURRENCY,
  },
  {
    name: "awsSavingsPlanRecurringCommitmentForBillingPeriod",
    unit: UnitType.CURRENCY,
  },
];

const dimensions = [
  "ancestryIds",
  "ancestryNames",
  "billingAccountId",
  "billingAccountName",
  "billingEntity",
  "category",
  "cloudId",
  "cloudName",
  "commitmentPaymentModel",
  "customReason",
  "databaseEngine",
  "dataSource",
  "invoiceMonth",
  "lineItemType",
  "location",
  "operatingSystem",
  "parentId",
  "parentName",
  "pricingModel",
  "pricingUnit",
  "projectId",
  "projectName",
  "projectNumber",
  "region",
  "serviceDescription",
  "serviceId",
  "skuDescription",
  "skuId",
  "tenancy",
  "usageUnit",
  "vendor",
  "zone",
];

export const billingSchema = { dimensions, measures };
