import { useTheme } from "@emotion/react";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { DataSource, Operator } from "@ternary/api-lib/analytics/enums";
import {
  JobStatus,
  ReallocationRebuildType,
  ReallocationStatus,
  ReallocationType,
} from "@ternary/api-lib/constants/enums";
import { actions } from "@ternary/api-lib/telemetry";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import { keyBy, uniq } from "lodash";
import React, { useMemo, useState } from "react";
import useGetDimensionValuesByDataSource from "../../../api/analytics/useGetDimensionValuesByDataSource";
import useGetUsersByTenantID from "../../../api/core/hooks/useGetUsersByTenantID";
import SideDrawerLegacy from "../../../components/SideDrawerLegacy";
import { useActivityTracker } from "../../../context/ActivityTrackerProvider";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useAvailableDimensionsByDataSource from "../../../hooks/useAvailableDimensionsByDataSource";
import useAvailableMeasuresByDataSource from "../../../hooks/useAvailableMeasuresByDataSource";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { DateHelper } from "../../../lib/dates";
import ConfirmationModal from "../../../ui-lib/components/ConfirmationModal";
import TextInput from "../../../ui-lib/components/TextInput";
import { AlertType, postAlert } from "../../../utils/alerts";
import getMergeState from "../../../utils/getMergeState";
import { useDebounce } from "../../../utils/timers";
import useGetDimensionPreferencesByTenantID from "../../admin/hooks/useGetDimensionPreferencesByTenantID";
import copyText from "../copyText";
import useCreateReallocation from "../hooks/useCreateReallocation";
import useDeleteReallocation from "../hooks/useDeleteReallocation";
import useGetReallocationJobsByTenantID from "../hooks/useGetReallocationJobsByTenantID";
import useGetReallocationsByTenantID from "../hooks/useGetReallocationsByTenantID";
import useTriggerReallocation from "../hooks/useTriggerReallocation";
import useUpdateReallocation from "../hooks/useUpdateReallocation";
import ReallocationForm, { Action } from "./ReallocationForm";
import ReallocationManagementTable from "./ReallocationManagementTable";

// NOTE: Reallocation jobs typically run quickly
const REFETCH_INTERVAL = 5 * 1000; // Five Seconds
const MODAL_DELETE = "DELETE";

type Interaction =
  | ReallocationForm.Interaction
  | ReallocationManagementTable.Interaction;

type ReallocationFilter = {
  name: string;
  operator: Operator;
  values: string[] | null;
};
interface State {
  actionPanelKey?: Action;
  dynamicFilters: ReallocationFilter[];
  generalFilters: ReallocationFilter[];
  inProgressReallocationIDs: string[];
  modalKey?: string;
  searchText: string;
  selectedReallocationID?: string;
  sourceMatchKeys?: string[];
  targetLabelKeys: string[];
}

const initialState: State = {
  actionPanelKey: undefined,
  dynamicFilters: [],
  generalFilters: [],
  inProgressReallocationIDs: [],
  modalKey: undefined,
  searchText: "",
  selectedReallocationID: undefined,
  sourceMatchKeys: undefined,
  targetLabelKeys: [],
};

const defaultReallocations = [];
const defaultReallocationJobs = [];
const defaultUsers = [];
const defaultDimensionPreferences = [];
const defaultDimensionValuesMap = {};

const now = new DateHelper();

export default function ReallocationManagementContainer() {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  //
  // State
  //

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  const generalDimensionValuesMapDimensions = state.generalFilters.map(
    (filter) => filter.name
  );

  const dynamicDimensionValuesMapDimensions = state.dynamicFilters.map(
    (filter) => filter.name
  );

  const dimensionValuesMapDimensions = [
    ...generalDimensionValuesMapDimensions,
    ...dynamicDimensionValuesMapDimensions,
    "projectId",
    ...(state.sourceMatchKeys ? state.sourceMatchKeys : []),
    ...state.targetLabelKeys,
  ];

  //
  // Queries
  //

  const {
    data: reallocations = defaultReallocations,
    isLoading: isLoadingReallocations,
    refetch: refetchReallocations,
  } = useGetReallocationsByTenantID(authenticatedUser.tenant.fsDocID);

  const reallocationsKeyedByID = keyBy(reallocations, "id");

  const {
    data: reallocationJobs = defaultReallocationJobs,
    isLoading: isLoadingReallocationJobs,
    refetch: refetchReallocationJobs,
  } = useGetReallocationJobsByTenantID(authenticatedUser.tenant.fsDocID, {
    refetchInterval: REFETCH_INTERVAL,
  });

  const { data: users = defaultUsers, isLoading: isLoadingUsers } =
    useGetUsersByTenantID(authenticatedUser.tenant.fsDocID);

  const availableDimensions = useAvailableDimensionsByDataSource(
    DataSource.BILLING
  ).map((dimension) => ({ name: dimension, isDate: false }));

  const {
    data: dimensionPreferences = defaultDimensionPreferences,
    isLoading: isLoadingDimensionPreferences,
  } = useGetDimensionPreferencesByTenantID(authenticatedUser.tenant.id);

  const {
    data: dimensionValuesMap = defaultDimensionValuesMap,
    isLoading: isLoadingDimensionValuesMap,
  } = useGetDimensionValuesByDataSource({
    dataSource: DataSource.BILLING,
    dateRange: [now.nDaysAgo(365), now.date],
    measure: "cost",
    dimensions: uniq(dimensionValuesMapDimensions),
    queryFilters: [
      { name: "dataSource", operator: "EQUALS", values: ["EXTERNAL"] },
    ],
  });

  const availableMeasures = useAvailableMeasuresByDataSource(
    DataSource.BILLING
  );

  //
  // Mutations
  //

  const { mutate: triggerReallocation } = useTriggerReallocation({
    onError: (_error, { reallocationID }) => {
      setState((currentState) => {
        const newInprogressReallocationIDs =
          currentState.inProgressReallocationIDs.filter(
            (id) => id !== reallocationID
          );

        return {
          ...currentState,
          inProgressReallocationIDs: newInprogressReallocationIDs,
        };
      });

      postAlert({
        message: copyText.errorTriggeringReallocationMessage,
        type: AlertType.ERROR,
      });
    },
    onMutate: ({ reallocationID }) => {
      setState((currentState) => {
        return {
          ...currentState,
          inProgressReallocationIDs: [
            ...currentState.inProgressReallocationIDs,
            reallocationID,
          ],
        };
      });
      activityTracker.captureAction(actions.CLICK_REALLOCATION_TRIGGER);
    },
    onSuccess: () => {
      refetchReallocationJobs();

      postAlert({
        message: copyText.successTriggeringReallocationMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  const { isPending: isCreatingReallocation, mutate: createReallocation } =
    useCreateReallocation({
      onError: () => {
        postAlert({
          message: copyText.errorCreatingReallocationMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (reallocationID) => {
        setState((currentState) => {
          return {
            ...currentState,
            inProgressReallocationIDs: [
              ...currentState.inProgressReallocationIDs,
              reallocationID,
            ],
          };
        });

        refetchReallocations();

        refetchReallocationJobs();

        mergeState({
          actionPanelKey: undefined,
          dynamicFilters: [],
          generalFilters: [],
          sourceMatchKeys: undefined,
        });

        postAlert({
          message: copyText.successCreatingReallocationMessage,
          type: AlertType.SUCCESS,
        });
      },
    });

  const { isPending: isDeletingReallocation, mutate: deleteReallocation } =
    useDeleteReallocation({
      onError: () => {
        postAlert({
          message: copyText.errorDeletingReallocationMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: () => {
        refetchReallocations();

        refetchReallocationJobs();

        mergeState({
          selectedReallocationID: undefined,
          modalKey: undefined,
        });

        postAlert({
          message: copyText.successDeletingReallocationMessage,
          type: AlertType.SUCCESS,
        });
      },
    });

  const { isPending: isUpdatingReallocation, mutate: updateReallocation } =
    useUpdateReallocation({
      onError: () => {
        postAlert({
          message: copyText.errorUpdatingReallocationMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (reallocationID, params) => {
        if (params.status === ReallocationStatus.ACTIVE) {
          setState((currentState) => {
            return {
              ...currentState,
              inProgressReallocationIDs: [
                ...currentState.inProgressReallocationIDs,
                reallocationID,
              ],
            };
          });
        }

        refetchReallocations();

        refetchReallocationJobs();

        mergeState({
          actionPanelKey: undefined,
          dynamicFilters: [],
          generalFilters: [],
          selectedReallocationID: undefined,
          sourceMatchKeys: undefined,
        });

        postAlert({
          message: copyText.successUpdatingReallocationMessage,
          type: AlertType.SUCCESS,
        });
      },
    });

  //
  // Handlers
  //

  function handleClickCreate() {
    mergeState({
      actionPanelKey: Action.CREATE,
    });
  }

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case ReallocationForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({
          actionPanelKey: undefined,
          dynamicFilters: [],
          generalFilters: [],
          sourceMatchKeys: undefined,
        });
        return;
      }
      case ReallocationForm.INTERACTION_FILTER_NAME_CHANGED: {
        const { dynamic, index, filter } = interaction;

        const targetFilters = dynamic ? "dynamicFilters" : "generalFilters";

        setState((currentState) => {
          const filters = [
            ...currentState[targetFilters].slice(0, index),
            filter,
            ...currentState[targetFilters].slice(index + 1),
          ];

          return { ...currentState, [targetFilters]: filters };
        });
        return;
      }
      case ReallocationForm.INTERACTION_REMOVE_FILTER_BUTTON_CLICKED: {
        const { dynamic, index } = interaction;

        const targetFilters = dynamic ? "dynamicFilters" : "generalFilters";

        setState((currentState) => {
          return {
            ...currentState,
            generalFilters: [
              ...currentState[targetFilters].slice(0, index),
              ...currentState[targetFilters].slice(index + 1),
            ],
          };
        });
        return;
      }
      case ReallocationForm.INTERACTION_SOURCE_MATCH_KEYS_CHANGED: {
        mergeState({ sourceMatchKeys: interaction.keys });
        return;
      }
      case ReallocationForm.INTERACTION_TARGET_LABEL_KEYS_CHANGED: {
        mergeState({ targetLabelKeys: interaction.targetLabelKeys });
        return;
      }
      case ReallocationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE: {
        const { type: _ignore, ...params } = interaction;

        activityTracker.captureAction(actions.CLICK_REALLOCATION_CREATE);

        createReallocation({
          ...params,
          tenantID: authenticatedUser.tenant.fsDocID,
          reason: "",
          targetServiceDescription: "",
          targetSkuDescription: "",
        });

        return;
      }
      case ReallocationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE: {
        const { type: _ignore, ...params } = interaction;

        activityTracker.captureAction(actions.CLICK_REALLOCATION_UPDATE);

        if (!state.selectedReallocationID) return;

        updateReallocation(params);
        return;
      }
      case ReallocationManagementTable.INTERACTION_ARCHIVE_BUTTON_CLICKED: {
        updateReallocation({
          reallocationID: interaction.reallocationID,
          status: interaction.status,
        });

        return;
      }
      case ReallocationManagementTable.INTERACTION_COPY_BUTTON_CLICKED: {
        const reallocation = reallocationsKeyedByID[interaction.reallocationID];

        mergeState({
          actionPanelKey: Action.COPY,
          selectedReallocationID: interaction.reallocationID,
          dynamicFilters:
            reallocation.typeConfig.type === ReallocationType.DYNAMIC
              ? reallocation.typeConfig.filters
              : [],
          generalFilters: reallocation.filters,
          sourceMatchKeys:
            reallocation.typeConfig.type === ReallocationType.DYNAMIC
              ? reallocation.typeConfig.sourceMatchKeys
              : undefined,
          targetLabelKeys: getTargetLabelKeys(reallocation.typeConfig.targets),
        });

        return;
      }
      case ReallocationManagementTable.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_DELETE,
          selectedReallocationID: interaction.reallocationID,
        });

        return;
      }
      case ReallocationManagementTable.INTERACTION_EDIT_BUTTON_CLICKED: {
        const reallocation = reallocationsKeyedByID[interaction.reallocationID];

        mergeState({
          actionPanelKey: Action.UPDATE,
          selectedReallocationID: interaction.reallocationID,
          dynamicFilters:
            reallocation.typeConfig.type === ReallocationType.DYNAMIC
              ? reallocation.typeConfig.filters
              : [],
          generalFilters: reallocation.filters,
          sourceMatchKeys:
            reallocation.typeConfig.type === ReallocationType.DYNAMIC
              ? reallocation.typeConfig.sourceMatchKeys
              : undefined,
          targetLabelKeys: getTargetLabelKeys(reallocation.typeConfig.targets),
        });
        return;
      }
      case ReallocationManagementTable.INTERACTION_TRIGGER_REALLOCATION_BUTTON_CLICKED: {
        triggerReallocation({ reallocationID: interaction.reallocationID });
        return;
      }
    }
  }

  //
  // Render
  //

  useMemo(() => {
    setState((currentState) => {
      const newInprogressReallocationIDs =
        currentState.inProgressReallocationIDs.filter(
          (id) =>
            !reallocationJobs.find(
              (job) =>
                job.sourceReallocationID === id &&
                job.status === JobStatus.IN_PROGRESS
            )
        );

      return {
        ...currentState,
        inProgressReallocationIDs: newInprogressReallocationIDs,
      };
    });
  }, [reallocationJobs]);

  const debouncedSearchText = useDebounce(state.searchText);

  const filteredReallocations = useMemo(() => {
    return getFilteredReallocations({
      reallocations: reallocations,
      searchText: debouncedSearchText,
    });
  }, [reallocations, debouncedSearchText]);

  const selectedReallocation = state.selectedReallocationID
    ? reallocationsKeyedByID[state.selectedReallocationID]
    : undefined;

  function renderForm(): JSX.Element | null {
    switch (state.actionPanelKey) {
      case Action.CREATE: {
        return (
          <ReallocationForm
            action={Action.CREATE}
            availableDimensions={availableDimensions}
            availableMeasures={availableMeasures}
            dimensionValuesMap={dimensionValuesMap}
            isLoadingDimensionValuesMap={isLoadingDimensionValuesMap}
            isLoadingDimensionPreferences={isLoadingDimensionPreferences}
            isProcessing={isCreatingReallocation}
            dimensionPreferences={dimensionPreferences}
            onInteraction={handleInteraction}
          />
        );
      }
      case Action.COPY: {
        if (!selectedReallocation) return null;

        return (
          <ReallocationForm
            action={Action.COPY}
            availableDimensions={availableDimensions}
            availableMeasures={availableMeasures}
            dimensionValuesMap={dimensionValuesMap}
            isLoadingDimensionValuesMap={isLoadingDimensionValuesMap}
            isLoadingDimensionPreferences={isLoadingDimensionPreferences}
            isProcessing={isCreatingReallocation}
            dimensionPreferences={dimensionPreferences}
            reallocation={selectedReallocation}
            onInteraction={handleInteraction}
          />
        );
      }
      case Action.UPDATE: {
        if (!selectedReallocation) return null;

        return (
          <ReallocationForm
            action={Action.UPDATE}
            availableDimensions={availableDimensions}
            availableMeasures={availableMeasures}
            dimensionValuesMap={dimensionValuesMap}
            isLoadingDimensionValuesMap={isLoadingDimensionValuesMap}
            isLoadingDimensionPreferences={isLoadingDimensionPreferences}
            isProcessing={isUpdatingReallocation}
            dimensionPreferences={dimensionPreferences}
            reallocation={selectedReallocation}
            onInteraction={handleInteraction}
          />
        );
      }
      default: {
        return null;
      }
    }
  }

  function renderModal(): JSX.Element | null {
    switch (state.modalKey) {
      case MODAL_DELETE: {
        if (!selectedReallocation) return null;

        return (
          <ConfirmationModal
            isLoading={isDeletingReallocation}
            title={copyText.deleteReallocationConfirmationTitle}
            message={copyText.deleteReallocationConfirmationMessage}
            variant="danger"
            onConfirm={() =>
              deleteReallocation({
                reallocationID: selectedReallocation.id,
              })
            }
            onCancel={() =>
              mergeState({
                selectedReallocationID: undefined,
                modalKey: undefined,
              })
            }
          />
        );
      }
      default:
        return null;
    }
  }

  return (
    <Flex direction="column">
      <Flex
        alignItems="center"
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        justifyContent="flex-end"
        marginTop={theme.space_md}
        padding={theme.space_md}
      >
        <Box width={300} marginRight={theme.space_sm}>
          <TextInput
            iconEnd={
              <Icon color={theme.text_color_secondary} icon={faSearch} />
            }
            placeholder={copyText.searchInputPlaceholder}
            size="large"
            value={state.searchText}
            onChange={(event) => mergeState({ searchText: event.target.value })}
          />
        </Box>

        <Box>
          <Button
            disabled={!gatekeeper.canCreateReallocations}
            iconStart={<Icon icon={faPlus} />}
            primary
            onClick={handleClickCreate}
          >
            {copyText.createReallocationLabel}
          </Button>
        </Box>
      </Flex>
      <Box marginTop={theme.space_md}>
        <ReallocationManagementTable
          inProgressReallocationIDs={state.inProgressReallocationIDs}
          isLoading={
            isLoadingReallocations ||
            isLoadingReallocationJobs ||
            isLoadingUsers
          }
          reallocations={filteredReallocations}
          reallocationJobs={reallocationJobs}
          users={users}
          onInteraction={handleInteraction}
        />
      </Box>
      <SideDrawerLegacy
        isOpen={!!state.actionPanelKey}
        title={
          state.actionPanelKey === Action.UPDATE
            ? copyText.sideDrawerTitleEdit
            : copyText.sideDrawerTitleCreate
        }
        onClose={() => {
          mergeState({
            actionPanelKey: undefined,
          });
        }}
        renderContent={() => (state.actionPanelKey ? renderForm() : null)}
        width="750px"
      />
      {renderModal()}
    </Flex>
  );
}

type Reallocation = {
  id: string;
  createdByUserID: string;
  name: string;
  rebuildType: ReallocationRebuildType;
  status: ReallocationStatus;
};

type GetFilteredReallocationsParams = {
  reallocations: Reallocation[];
  searchText: string;
};

function reallocationHasSearchText(
  reallocation: Reallocation,
  searchText: string
): boolean {
  if (searchText === "") return true;

  const values = [
    reallocation.name ?? "",
    reallocation.id ?? "",
    reallocation.createdByUserID ?? "",
  ].map((value) => (value === "" ? "null" : value));

  return values.some((value) =>
    value.toLowerCase().trim().includes(searchText)
  );
}

function getFilteredReallocations(
  params: GetFilteredReallocationsParams
): Reallocation[] {
  return params.reallocations.filter((reallocations) => {
    if (!reallocationHasSearchText(reallocations, params.searchText)) {
      return false;
    }

    return true;
  });
}

type ReallocationTarget = {
  identifier: {
    projectID: string;
    labels: Record<string, string>;
  };
  share?: number;
  sourceMatchValue?: string;
};

function getTargetLabelKeys(targets: ReallocationTarget[]): string[] {
  return targets.reduce((accum: string[], target) => {
    const keys = Object.keys(target.identifier.labels);
    return accum.concat(keys);
  }, []);
}
