import { UnitType } from "../enums";

const measures = [
  { name: "cost", unit: UnitType.CURRENCY },
  { name: "savingsPlanCoveredCost", unit: UnitType.CURRENCY },
  { name: "nonSavingsPlanCoveredCost", unit: UnitType.CURRENCY },
];

const dimensions = [
  "accountId",
  "clusterName",
  "nodeName",
  "instanceId",
  "instanceType",
  "instanceTypeFamily",
  "vcpuPerInstance",
  "memoryPerInstance",
  "region",
  "lineItemDescription",
  "timestamp",
  "costKind",
];

export const awsKubernetesNodeCostSchema = { dimensions, measures };
