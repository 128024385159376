import { buildCubeQuery } from "@/api/analytics/utils";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource, TimeGranularity } from "@ternary/api-lib/analytics/enums";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { GCPBillingEntity } from "../types";

interface Params {
  dateRange: Date[];
  granularity: TimeGranularity;
}

export default function useGetCudOnDemandCoverableCost(
  params: Params,
  options?: UseQueryOptions<GCPBillingEntity[], UError>
): UseQueryResult<GCPBillingEntity[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["cudOnDemandCoverableCost", params],
    queryFn: async () => {
      const result = (await client.load(
        buildCubeQuery({
          dateRange: params.dateRange,
          dataSource: DataSource.GCP_CUD_COVERABLE_COST,
          dimensions: ["billingAccountId", "serviceDescription"],
          granularity: params.granularity,
          measures:
            params.granularity === TimeGranularity.DAY
              ? ["minDailyCost"]
              : ["cost"],
        })
      )) as GCPBillingEntity[];

      return result;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
