import paths from "@/constants/paths";
import { useMatchPath } from "@/lib/react-router";
import Dropdown from "@/ui-lib/components/Dropdown";
import Modal from "@/ui-lib/components/Modal";
import TextInput from "@/ui-lib/components/TextInput";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import {
  faChevronLeft,
  faEllipsisV,
  faPlus,
  faSearch,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import { SYSTEM_TENANT_ID } from "@ternary/api-lib/constants/system";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useMemo, useState } from "react";
import useGatekeeper from "../../../hooks/useGatekeeper";
import copyText from "../copyText";
import SavingsOpportunityForm, { Action } from "./SavingsOpportunityForm";
import { SavingsOpportunityFilter } from "./SavingsOpportunityViewContainer";

type Props = {
  actionPanelKey: Action | null;
  filters: SavingsOpportunityFilter[];
  isLoading: boolean;
  selectedSavingOppID: string | null;
  selectedWidgetIndex?: number;
  onInteraction: (
    interaction:
      | SavingsOpportunityListModal.Interaction
      | SavingsOpportunityForm.Interaction
  ) => void;
};

type State = {
  searchText: string;
};

type TableData = {
  id: string;
  tenantID: string;
  name: string;
  timeLastModified: string;
};
const columnHelper = createColumnHelper<TableData>();

const initialState: State = {
  searchText: "",
};

export function SavingsOpportunityListModal(props: Props): JSX.Element {
  const currentPath = useMatchPath();
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  const showDeleteButton =
    currentPath === paths._dashboard && props.actionPanelKey === Action.UPDATE;

  const isSelectedWidget = props.selectedWidgetIndex;

  function handleClickRow(filterID: string): void {
    props.onInteraction({
      type: SavingsOpportunityListModal.INTERACTION_SAVINGS_ROW_CLICKED,
      filterID,
    });
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: copyText.tableHeaderName,
        size: 100,
      }),
      columnHelper.accessor("tenantID", {
        cell: ({ getValue }) => {
          const value = getValue();
          if (value === SYSTEM_TENANT_ID) {
            return copyText.tableScopeGlobal;
          }
          return copyText.tableScopeShared;
        },
        header: copyText.tableHeaderVisibility,
        meta: { align: "center" },
        size: 20,
      }),
      columnHelper.accessor("timeLastModified", {
        cell: ({ getValue }) => {
          const value = getValue();
          return formatDate(new Date(value), "MM/dd/yyyy");
        },
        header: copyText.tableHeaderTimeLastModified,
        meta: { align: "center" },
        size: 80,
      }),
      columnHelper.display({
        id: "actionMenu",
        cell: function renderButton({ row }) {
          const savingFillterOptions = [
            {
              label: copyText.actionMenuItemUpdateSavingOpp,
              onClick: () =>
                props.onInteraction({
                  type: SavingsOpportunityListModal.INTERACTION_UPDATE_BUTTON_CLICKED,
                  filterID: row.original.id,
                }),
            },
            {
              label: copyText.actionMenuItemDeleteSavingOpp,
              onClick: () =>
                props.onInteraction({
                  type: SavingsOpportunityListModal.INTERACTION_DELETE_BUTTON_CLICKED,
                  filterID: row.original.id,
                  index: -1,
                }),
            },
          ];

          if (
            !gatekeeper.canEditGlobalSavingsOpp &&
            row.original.tenantID === SYSTEM_TENANT_ID
          ) {
            return;
          }

          return (
            <Dropdown options={savingFillterOptions} placement="bottom">
              <Button
                iconStart={<Icon icon={faEllipsisV} />}
                secondary
                size="tiny"
              />
            </Dropdown>
          );
        },
        meta: { align: "right" },
        size: 20,
      }),
    ],
    []
  );

  const data = useMemo((): TableData[] => {
    let filteredData = props.filters
      .map((filter) => ({
        id: filter.id,
        tenantID: filter.tenantID,
        name: filter.name,
        timeLastModified: filter.updatedAt ?? filter.createdAt,
      }))
      .sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : 0
      );

    if (state.searchText.length > 0) {
      filteredData = filteredData.filter((filter) => {
        return filter.name
          .toLowerCase()
          .includes(state.searchText.toLowerCase());
      });
    }
    return filteredData;
  }, [props.filters, state.searchText]);

  const modalTitle =
    props.actionPanelKey === Action.CREATE
      ? copyText.modalTitleCreateSavingsOpportunity
      : copyText.modalTitleUpdateSavingsOpportunity;

  const selectedFilter = props.selectedSavingOppID
    ? props.filters.find((filter) => filter.id === props.selectedSavingOppID)
    : undefined;

  return (
    <Modal
      isOpen={true}
      showCloseButton
      width={"50%"}
      onClose={() =>
        props.onInteraction({
          type: SavingsOpportunityListModal.INTERACTION_CANCEL_BUTTON_CLICKED,
        })
      }
    >
      <Modal.Header>
        <Flex alignItems="center" width="100%" justifyContent="space-between">
          <Text appearance="h4">
            {props.actionPanelKey
              ? modalTitle
              : copyText.modalTitleSavingsOpportunity}
          </Text>
          {props.actionPanelKey === Action.CREATE ? (
            <Button
              marginLeft={theme.space_xs}
              size="tiny"
              secondary
              iconStart={<Icon icon={faChevronLeft} />}
              onClick={() =>
                props.onInteraction({
                  type: SavingsOpportunityListModal.INTERACTION_BACK_BUTTON_CLICKED,
                })
              }
            >
              {copyText.backButtonLabel.replace(
                "%TITLE",
                copyText.modalTitleSavingsOpportunity
              )}
            </Button>
          ) : null}
          {isSelectedWidget !== undefined && showDeleteButton && (
            <Button
              iconStart={<Icon icon={faTrashAlt} />}
              marginLeft={theme.space_xs}
              size="small"
              onClick={() =>
                props.onInteraction({
                  type: SavingsOpportunityListModal.INTERACTION_DELETE_BUTTON_CLICKED,
                  filterID: selectedFilter?.id ?? "",
                  index: isSelectedWidget,
                })
              }
            />
          )}
        </Flex>
      </Modal.Header>
      <Modal.Body>
        {!props.actionPanelKey ? (
          <Flex
            justifyContent="right"
            marginBottom={theme.space_md}
            marginRight={theme.size_medium}
            marginTop={-40}
          >
            <Box marginRight={theme.space_md} width="12rem">
              <TextInput
                iconStart={
                  <Icon color={theme.text_color_secondary} icon={faSearch} />
                }
                placeholder={copyText.searchReportsDashboardsPlaceholder}
                onChange={(event) =>
                  mergeState({ searchText: event.target.value })
                }
              />
            </Box>
            <Button
              iconStart={<Icon icon={faPlus} />}
              primary
              onClick={() =>
                props.onInteraction({
                  type: SavingsOpportunityListModal.INTERACTION_ADD_SAVINGS_OPP_CLICKED,
                })
              }
            >
              {copyText.createSavingOpportunity}
            </Button>
          </Flex>
        ) : null}
        <Box flex="1 0 auto">
          {props.actionPanelKey ? (
            <SavingsOpportunityForm
              action={props.actionPanelKey}
              isProcessing={props.isLoading}
              savingsOpportunity={selectedFilter}
              onInteraction={props.onInteraction}
            />
          ) : (
            <Table
              clickableRows
              columns={columns}
              compact
              data={data}
              initialState={{ pagination: { pageSize: 10 } }}
              isLoading={props.isLoading}
              onClick={(row) => handleClickRow(row.id)}
              showPagination
              sortable
            />
          )}
        </Box>
      </Modal.Body>
    </Modal>
  );
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SavingsOpportunityListModal {
  export const INTERACTION_SAVINGS_ROW_CLICKED = `SavingsOpportunityListModal.INTERACTION_SAVINGS_ROW_CLICKED`;
  export const INTERACTION_ADD_SAVINGS_OPP_CLICKED = `SavingsOpportunityListModal.INTERACTION_ADD_SAVINGS_OPP_CLICKED`;
  export const INTERACTION_BACK_BUTTON_CLICKED = `SavingsOpportunityListModal.INTERACTION_BACK_BUTTON_CLICKED`;
  export const INTERACTION_UPDATE_BUTTON_CLICKED = `SavingsOpportunityListModal.INTERACTION_UPDATE_BUTTON_CLICKED`;
  export const INTERACTION_CANCEL_BUTTON_CLICKED = `SavingsOpportunityListModal.INTERACTION_CANCEL_BUTTON_CLICKED`;
  export const INTERACTION_DELETE_BUTTON_CLICKED = `SavingsOpportunityListModal.INTERACTION_DELETE_BUTTON_CLICKED`;

  interface InteractionRowClicked {
    type: typeof SavingsOpportunityListModal.INTERACTION_SAVINGS_ROW_CLICKED;
    filterID: string;
  }

  interface InteractionUpdateButtonClicked {
    type: typeof SavingsOpportunityListModal.INTERACTION_UPDATE_BUTTON_CLICKED;
    filterID: string;
  }

  interface InteractionAddSavingOppFilterClicked {
    type: typeof SavingsOpportunityListModal.INTERACTION_ADD_SAVINGS_OPP_CLICKED;
  }

  interface InteractionBackButtonClicked {
    type: typeof SavingsOpportunityListModal.INTERACTION_BACK_BUTTON_CLICKED;
  }

  interface InteractionCancelClicked {
    type: typeof SavingsOpportunityListModal.INTERACTION_CANCEL_BUTTON_CLICKED;
  }

  interface InteractionDeleteButtonClicked {
    type: typeof SavingsOpportunityListModal.INTERACTION_DELETE_BUTTON_CLICKED;
    filterID: string;
    index: number;
  }

  export type Interaction =
    | InteractionRowClicked
    | InteractionAddSavingOppFilterClicked
    | InteractionBackButtonClicked
    | InteractionUpdateButtonClicked
    | InteractionCancelClicked
    | InteractionDeleteButtonClicked;
}

export default SavingsOpportunityListModal;
