import { Theme, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { formatNumber } from "../../analytics/utils/NumberFormatUtils";
import copyText from "../copyText";
import Box from "./Box";
import Button, { Props as ButtonProps } from "./Button";
import Flex from "./Flex";
import Text from "./Text";

const Ellipsis = styled("div")(({ theme }) => ({
  color: theme.text_color_secondary,
  display: "flex",
  height: "2rem",
  justifyContent: "center",
  marginRight: theme.space_xxs,
  width: "2rem",
}));

const PageButton = styled(Button)<ButtonProps>(
  ({ disabled, primary, theme }) => ({
    ...(!disabled && !primary ? { color: theme.text_color_secondary } : {}),
    marginRight: theme.space_xxs,

    "&:focus": {
      boxShadow: "none",
    },
  })
);

export interface Props {
  currentPageIndex: number;
  loading: boolean;
  pageSize: number;
  totalPageCount: number;
  totalRows: number;
  onPageChange: (page: number) => void;
}

const MAX_ADJACENT_BUTTONS = 5;

export default function Pagination(props: Props): JSX.Element | null {
  const theme = useTheme();

  if (props.loading) return null;

  const groupedNumbers = getGroupedNumbers(
    props.currentPageIndex,
    props.totalPageCount
  );

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      marginVertical={theme.space_md}
      width="100%"
    >
      {/* Put page size selector here */}
      <Box marginLeft={theme.space_sm}>
        {renderPageStatus(
          props.currentPageIndex,
          props.pageSize,
          props.totalRows,
          theme
        )}
      </Box>
      {props.totalRows > props.pageSize && (
        <Flex marginRight={theme.space_sm}>
          <PageButton
            disabled={props.currentPageIndex === 0}
            size="small"
            onClick={() => props.onPageChange(props.currentPageIndex - 1)}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </PageButton>

          {/* Button: Jump to 1st */}
          {shouldRenderFirst(props.currentPageIndex, props.totalPageCount) && (
            <>
              <PageButton size="small" onClick={() => props.onPageChange(0)}>
                1
              </PageButton>
              {shouldRenderFirstEllipses(groupedNumbers) && (
                <Ellipsis>...</Ellipsis>
              )}
            </>
          )}
          {/* Grouping: All adjacent buttons */}
          {groupedNumbers.map((num) => (
            <PageButton
              key={num}
              primary={num === props.currentPageIndex + 1}
              size="small"
              onClick={() => props.onPageChange(num - 1)}
            >
              {num}
            </PageButton>
          ))}
          {/* Button: Jump to final page */}
          {shouldRenderFinal(props.currentPageIndex, props.totalPageCount) && (
            <>
              {shouldRenderFinalEllipses(
                props.totalPageCount,
                groupedNumbers
              ) && <Ellipsis>...</Ellipsis>}
              <PageButton
                size="small"
                onClick={() => props.onPageChange(props.totalPageCount - 1)}
              >
                {props.totalPageCount}
              </PageButton>
            </>
          )}
          {/* Button: Next Page */}
          <PageButton
            disabled={
              !isEnabledRight(props.currentPageIndex, props.totalPageCount)
            }
            size="small"
            onClick={() => props.onPageChange(props.currentPageIndex + 1)}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </PageButton>
        </Flex>
      )}
    </Flex>
  );
}

function renderPageStatus(
  currentPageIndex: number,
  pageSize: number,
  totalRows: number,
  theme: Theme
) {
  const start = currentPageIndex * pageSize + 1;
  const end =
    start + pageSize - 1 > totalRows ? totalRows : start + pageSize - 1;

  return (
    <Text color={theme.text_color_secondary} fontSize={theme.fontSize_small}>
      {`${copyText.paginationStatusLabelShowing} `}
      <Text as="span" bold color={theme.text_color}>
        {`${copyText.paginationStatusLabelRange
          .replace("%start%", formatNumber(start))
          .replace("%end%", formatNumber(end))} `}
      </Text>
      {`${copyText.paginationStatusLabelOutOf} `}
      <Text as="span" bold color={theme.text_color}>
        {totalRows}
      </Text>
    </Text>
  );
}

function getGroupedNumbers(
  currentPageIndex: number,
  totalPageCount: number
): number[] {
  // small number of pages
  if (totalPageCount <= MAX_ADJACENT_BUTTONS) {
    const arr = new Array(totalPageCount).fill(0);
    return arr.map((_, i) => i + 1);
  }

  let NEW_MAX_ADJACENT_BUTTONS = MAX_ADJACENT_BUTTONS;
  const paginationEndRanges = 3;

  const maxOffset = Math.floor(MAX_ADJACENT_BUTTONS / 2);
  let offset = maxOffset - 1;

  // current index is close to left
  if (
    currentPageIndex !== paginationEndRanges &&
    maxOffset - currentPageIndex >= 0
  ) {
    offset = currentPageIndex - 1;
  } else {
    NEW_MAX_ADJACENT_BUTTONS = 3; // < 1 ... 7 8 9 ... 12 >
    offset = 0;
  }

  // current index is close to right
  if (totalPageCount - currentPageIndex - 1 <= paginationEndRanges) {
    NEW_MAX_ADJACENT_BUTTONS = 5; //  < 1 ... 8 9 10 11 12 >
    offset = NEW_MAX_ADJACENT_BUTTONS - (totalPageCount - currentPageIndex + 1);
  }

  const arr = new Array(NEW_MAX_ADJACENT_BUTTONS).fill(0);
  return arr.map((_, i) => i + currentPageIndex - offset);
}

function isEnabledRight(currentPageIndex: number, totalPageCount: number) {
  if (totalPageCount === 1) {
    return false;
  }

  if (totalPageCount === currentPageIndex + 1) {
    return false;
  }

  return true;
}

function shouldRenderFirst(currentPageIndex: number, totalPageCount: number) {
  const maxOffset = Math.ceil(MAX_ADJACENT_BUTTONS / 2);
  if (totalPageCount > 5 && currentPageIndex - maxOffset >= 0) {
    return true;
  }
  return false;
}

function shouldRenderFinal(currentPageIndex: number, totalPageCount: number) {
  const maxOffset = Math.ceil(MAX_ADJACENT_BUTTONS / 2);
  if (totalPageCount > 5 && totalPageCount - maxOffset > currentPageIndex + 1) {
    return true;
  }

  return false;
}

function shouldRenderFirstEllipses(groupedNumbers: number[]) {
  return Math.min(...groupedNumbers) - 1 > 1;
}
function shouldRenderFinalEllipses(
  totalPageCount: number,
  groupedNumbers: number[]
) {
  return totalPageCount - Math.max(...groupedNumbers) > 1;
}
