import { createStructParam } from "@/lib/use-query-params";
import { createColumnHelper } from "@tanstack/react-table";
import {
  formatCurrency,
  formatPercentage,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import { floor } from "lodash";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import { AzureVMComputeInstance, azureVMComputeDimensions } from "../types";
import { AzureComputeCategoryOption } from "./AzureVMComputeContainer";

type Props = {
  instances: AzureVMComputeInstance[];
  isLoadingInstances: boolean;
  selectedCategory: AzureComputeCategoryOption;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

const columnHelper = createColumnHelper<AzureVMComputeInstance>();

export default function AzureVMComputeInstanceTable(props: Props) {
  const [sortRule, setSortRule] = useQueryParam(
    "instance_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "cost",
    })
  );

  const blockStorageCells = [
    [azureVMComputeDimensions.blockStorageClass, 120],
    [azureVMComputeDimensions.blockStorageType, 120],
    [azureVMComputeDimensions.location, 120],
    [azureVMComputeDimensions.projectId, 160],
  ] as const;

  const columns = useMemo(
    () => [
      columnHelper.accessor("instanceName", {
        cell: ({ getValue }) => (
          <Text truncate>
            <Tooltip content={getValue()}>{getValue()}</Tooltip>
          </Text>
        ),
        header: copyText.azureComputeTableHeader_instanceName,
        size: 160,
      }),
      columnHelper.accessor("cost", {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.azureComputeTableHeader_cost,
        meta: { align: "center" },
        sortDescFirst: true,
      }),

      ...(props.selectedCategory === AzureComputeCategoryOption.INSTANCE
        ? [
            columnHelper.accessor("instanceType", {
              cell: ({ getValue }) =>
                getValue() || copyText.azureComputeTableNull,
              header: copyText.azureComputeTableHeader_instanceType,
              meta: { align: "center" },
              size: 100,
            }),
          ]
        : []),
      ...(props.selectedCategory === AzureComputeCategoryOption.BLOCK
        ? blockStorageCells.map(([dimensionKey, size]) => {
            const headerKey: keyof typeof copyText = `azureComputeTableHeader_${dimensionKey}`;

            return columnHelper.accessor(dimensionKey, {
              cell: ({ getValue }) => (
                <Text truncate>
                  {getValue() || copyText.azureComputeTableNull}
                </Text>
              ),
              header: copyText[headerKey],
              meta: { align: "center" },
              size,
            });
          })
        : []),
      columnHelper.accessor("usageAmount", {
        cell: ({ getValue }) => floor(getValue(), 3),
        header: copyText.azureComputeTableHeader_usageAmount,
        meta: { align: "center" },
        sortDescFirst: true,
      }),
      columnHelper.accessor("productVCPU", {
        cell: ({ getValue }) => getValue() || copyText.azureComputeTableNull,
        header: copyText.azureComputeTableHeader_productVCPU,
        meta: { align: "center" },
        size: 140,
        sortDescFirst: true,
      }),
      ...(
        ["cpuUtilizationMin", "cpuUtilizationAvg", "cpuUtilizationMax"] as const
      ).map((accessor) =>
        columnHelper.accessor(accessor, {
          cell: ({ getValue }) => formatPercentage(getValue() / 100),
          header: copyText[`azureComputeTableHeader_${accessor}`],
          meta: { align: "center" },
          sortDescFirst: true,
        })
      ),
      columnHelper.accessor("receivedBytes", {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header: copyText.azureComputeTableHeader_receivedBytes,
        meta: { align: "center" },
        size: 140,
        sortDescFirst: true,
      }),
      columnHelper.accessor("sentBytes", {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header: copyText.azureComputeTableHeader_sentBytes,
        meta: { align: "center" },
        size: 140,
        sortDescFirst: true,
      }),
      columnHelper.accessor("diskReadBytes", {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header: copyText.azureComputeTableHeader_diskReadBytes,
        meta: { align: "center" },
        size: 140,
        sortDescFirst: true,
      }),
      columnHelper.accessor("diskWriteBytes", {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header: copyText.azureComputeTableHeader_diskWriteBytes,
        meta: { align: "center" },
        size: 140,
        sortDescFirst: true,
      }),
      columnHelper.accessor("productMemoryBytes", {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header: copyText.azureComputeTableHeader_productMemoryBytes,
        meta: { align: "center" },
        size: 140,
        sortDescFirst: true,
      }),
      ...(
        [
          "ramAvailableBytesMin",
          "ramAvailableBytesAvg",
          "ramAvailableBytesMax",
        ] as const
      ).map((accessor) =>
        columnHelper.accessor(accessor, {
          cell: ({ getValue }) => prettyBytes(getValue()),
          header: copyText[`azureComputeTableHeader_${accessor}`],
          meta: { align: "center" },
          sortDescFirst: true,
        })
      ),
    ],
    []
  );

  return (
    <Box maxWidth={"100%"} overflowX="auto">
      <Table
        columns={columns}
        compact
        data={props.instances}
        initialState={{ sorting: [sortRule] }}
        isLoading={props.isLoadingInstances}
        showPagination
        sortable
        onChangeSortBy={([sortRule]) => setSortRule(sortRule, "replaceIn")}
      />
    </Box>
  );
}
