import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource, Operator } from "@ternary/api-lib/analytics/enums";
import UError from "unilib-error";
import useBuildDataQuery from "../../../../api/analytics/utils/useDataQuery";
import { ANALYTICS_QUERY_GC_TIME } from "../../../../constants";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import {
  AWSRedshiftResourceGroupEntity,
  awsRedshiftDimensions,
  awsRedshiftMeasures,
} from "../types";

export interface Params {
  dateRange: Date[];
}

export default function useGetAWSRedshiftResourceGroups(
  params: Params,
  options?: UseQueryOptions<AWSRedshiftResourceGroupEntity[], UError>
): UseQueryResult<AWSRedshiftResourceGroupEntity[], UError> {
  const client = useAnalyticsApiClient();

  const dimensions = [
    awsRedshiftDimensions.clusterId,
    awsRedshiftDimensions.region,
    awsRedshiftDimensions.usageAccountId,
  ];

  const measures = [
    awsRedshiftMeasures.cost,
    awsRedshiftMeasures.dataScanCost,
    awsRedshiftMeasures.nodeCost,
    awsRedshiftMeasures.storageCost,
  ];

  const [tenantID, query] = useBuildDataQuery({
    dataSource: DataSource.AWS_REDSHIFT_VISIBILITY,
    dateRange: params.dateRange,
    measures,
    preAggFilters: [
      {
        name: "clusterId",
        operator: Operator.SET,
      },
    ],
    dimensions,
  });

  return useQuery({
    queryKey: ["AWSRedshiftResourceGroups", params],
    queryFn: async () => {
      const result = await client.loadData(tenantID, query);

      const resourceGroups = result.response.map((datum) => {
        return {
          // DIMENSIONS
          clusterId: datum.clusterId ?? "",
          region: datum.region ?? "",
          usageAccountId: datum.usageAccountId ?? "",

          // MEASURES
          cost: datum.cost ?? 0,
          dataScanCost: datum.dataScanCost ?? 0,
          nodeCost: datum.nodeCost ?? 0,
          storageCost: datum.storageCost ?? 0,
        } as AWSRedshiftResourceGroupEntity;
      });

      return resourceGroups;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
