import { useTheme } from "@emotion/react";
import { FormOptions, useForm } from "@tanstack/react-form";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import { isEqual } from "lodash";
import React from "react";
import Form, { FormField } from "../../../../ui-lib/components/Form";
import LoadingSpinner from "../../../../ui-lib/components/LoadingSpinner";
import Select from "../../../../ui-lib/components/Select";
import TextArea from "../../../../ui-lib/components/TextArea";
import TextInput from "../../../../ui-lib/components/TextInput";
import { states } from "../constants";
import _copyText from "../copyText";
import { FormData } from "../types";
import { useFeatureText } from "@/context/FeatureTextProvider";

interface Props {
  defaultValues: FormData;
  isProcessing?: boolean;
  mode?: "create" | "update";
  type?: "global" | "customer";
  onSubmit: FormOptions<FormData>["onSubmit"];
}

const genericRequiredFieldValidator = ({ value }: { value: string }) => {
  return value.length === 0
    ? _copyText.inputFieldRequiredErrorCaption
    : undefined;
};

export function MspBillingStatementInfoForm(props: Props) {
  const theme = useTheme();

  const { copyText } = useFeatureText(_copyText);

  const form = useForm({
    defaultValues: props.defaultValues,
    onSubmit: props.onSubmit,
  });

  const stateOptions = states.map((state) => ({ label: state, value: state }));

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <form.Field
        name="addressLine1"
        validators={{
          onChange: genericRequiredFieldValidator,
          onMount: genericRequiredFieldValidator,
        }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          const errorCaption = shouldShowError
            ? field.state.meta.errors.join(", ")
            : undefined;

          const variant = shouldShowError ? "danger" : undefined;

          return (
            <FormField
              input={TextInput}
              errorCaption={errorCaption}
              label={copyText.addressLine1InputLabel}
              value={field.state.value}
              variant={variant}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <form.Field
        name="addressLine2"
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          const errorCaption = shouldShowError
            ? field.state.meta.errors.join(", ")
            : undefined;

          const variant = shouldShowError ? "danger" : undefined;

          return (
            <FormField
              input={TextInput}
              errorCaption={errorCaption}
              label={copyText.addressLine2InputLabel}
              value={field.state.value}
              variant={variant}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <Flex gap={theme.space_md}>
        <form.Field
          name="city"
          validators={{
            onChange: genericRequiredFieldValidator,
            onMount: genericRequiredFieldValidator,
          }}
          children={(field) => {
            const shouldShowError =
              field.state.meta.isTouched && field.state.meta.errors.length > 0;

            const errorCaption = shouldShowError
              ? field.state.meta.errors.join(", ")
              : undefined;

            const variant = shouldShowError ? "danger" : undefined;

            return (
              <FormField
                input={TextInput}
                errorCaption={errorCaption}
                label={copyText.cityInputLabel}
                value={field.state.value}
                variant={variant}
                onChange={(e) => field.handleChange(e.target.value)}
              />
            );
          }}
        />
        <form.Field
          name="state"
          validators={{
            onChange: genericRequiredFieldValidator,
            onMount: genericRequiredFieldValidator,
          }}
          children={(field) => {
            return (
              <FormField label={copyText.stateInputLabel}>
                <Select
                  options={stateOptions}
                  placeholder={copyText.selectStatePlaceholder}
                  value={stateOptions.find(
                    (option) => option.value === field.state.value
                  )}
                  onChange={(option) =>
                    option && field.handleChange(option?.value)
                  }
                />
              </FormField>
            );
          }}
        />
        <form.Field
          name="zipCode"
          validators={{
            onChange: genericRequiredFieldValidator,
            onMount: genericRequiredFieldValidator,
          }}
          children={(field) => {
            const shouldShowError =
              field.state.meta.isTouched && field.state.meta.errors.length > 0;

            const errorCaption = shouldShowError
              ? field.state.meta.errors.join(", ")
              : undefined;

            const variant = shouldShowError ? "danger" : undefined;

            return (
              <FormField
                input={TextInput}
                errorCaption={errorCaption}
                label={copyText.zipCodeInputLabel}
                value={field.state.value}
                variant={variant}
                onChange={(e) => field.handleChange(e.target.value)}
              />
            );
          }}
        />
      </Flex>
      <form.Field
        name="paymentTerm"
        validators={
          props.type === "global"
            ? {
                onChange: genericRequiredFieldValidator,
                onMount: genericRequiredFieldValidator,
              }
            : undefined
        }
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          const errorCaption = shouldShowError
            ? field.state.meta.errors.join(", ")
            : undefined;

          const variant = shouldShowError ? "danger" : undefined;

          return (
            <FormField
              input={TextInput}
              errorCaption={errorCaption}
              label={copyText.paymentTermInputLabel}
              value={field.state.value}
              variant={variant}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <form.Field
        name="paymentInstructions"
        validators={
          props.type === "global"
            ? {
                onChange: genericRequiredFieldValidator,
                onMount: genericRequiredFieldValidator,
              }
            : undefined
        }
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          const errorCaption = shouldShowError
            ? field.state.meta.errors.join(", ")
            : undefined;

          return (
            <FormField
              input={TextArea}
              errorCaption={errorCaption}
              label={copyText.paymentInstructionsInputLabel}
              placeholder={copyText.paymentInstructionsInputPlaceholder}
              rows={5}
              value={field.state.value}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      {props.type === "customer" && (
        <form.Field
          name="customerDetails"
          children={(field) => {
            return (
              <FormField
                input={TextArea}
                label={copyText.customerDetailsInputLabel}
                placeholder={copyText.customerDetailsInputPlaceholder}
                rows={5}
                value={field.state.value}
                onChange={(e) => field.handleChange(e.target.value)}
              />
            );
          }}
        />
      )}
      <form.Subscribe
        children={(state) => {
          let disabled =
            !state.canSubmit || !state.isTouched || props.isProcessing;

          if (props.mode === "update") {
            const hasChanged = getHasChanged(
              state.values,
              form.options.defaultValues
            );

            disabled = disabled || !hasChanged;
          }

          return (
            <Button disabled={disabled} primary type="submit" width={100}>
              {props.isProcessing ? (
                <LoadingSpinner />
              ) : (
                copyText.submitButtonLabel
              )}
            </Button>
          );
        }}
      />
    </Form>
  );
}

function getHasChanged(values, defaultValues) {
  return Object.keys(values).some(
    (key) => !isEqual(values[key], defaultValues[key])
  );
}
