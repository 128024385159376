import { FormField } from "@/ui-lib/components/Form";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import Modal from "@/ui-lib/components/Modal";
import TextInput from "@/ui-lib/components/TextInput";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useState } from "react";
import { DatePicker } from "../../../ui-lib/components/DateRangeControls/DatePicker";
import copyText from "../copyText";

type Props = {
  isProcessing: boolean;
  onInteraction: (interaction: CreateApiKeyFormModal.Interaction) => void;
};

type State = {
  nameInput: { value: string; isValid: boolean };
  expiresDateInput: Date | undefined;
};

const initialState: State = {
  nameInput: { value: "", isValid: false },
  expiresDateInput: undefined,
};

export function CreateApiKeyFormModal(props: Props) {
  const theme = useTheme();

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  const dateRangeMin = new Date();

  function handleClose() {
    props.onInteraction({
      type: CreateApiKeyFormModal.INTERACTION_CANCEL_BUTTON_CLICKED,
    });
  }

  function handleNameInputChange(name: string) {
    mergeState({
      nameInput: {
        value: name,
        isValid: name.length > 0,
      },
    });
  }

  function handleDateChange(date: Date) {
    mergeState({ expiresDateInput: date });
  }

  function handleSubmit() {
    props.onInteraction({
      type: CreateApiKeyFormModal.INTERACTION_SUBMIT_BUTTON_CLICKED,
      name: state.nameInput.value,
      ...(state.expiresDateInput ? { expiresOn: state.expiresDateInput } : {}),
    });
  }

  return (
    <Modal isOpen showCloseButton onClose={handleClose} width={500}>
      <Modal.Header>
        <Text appearance="h4">{copyText.modalHeaderCreateApiKey}</Text>
      </Modal.Header>
      <Modal.Body>
        <Flex direction="column" justifyContent="space-between">
          <FormField
            autoFocus
            input={TextInput}
            label={
              <Flex alignItems="center">
                <Text marginRight={theme.space_xs}>
                  {copyText.addKeyNameLabel}
                </Text>
                <Tooltip content={copyText.keyNameInfoText}>
                  <Icon icon={faCircleInfo} size="sm" />
                </Tooltip>
              </Flex>
            }
            required
            value={state.nameInput.value}
            onChange={(event) => handleNameInputChange(event.target.value)}
          />
          <FormField
            label={
              <Flex alignItems="center">
                <Text marginRight={theme.space_xs}>
                  {copyText.modalDateRange}
                </Text>
                <Tooltip content={copyText.expirationDateLimitText}>
                  <Icon icon={faCircleInfo} size="sm" />
                </Tooltip>
              </Flex>
            }
          >
            <DatePicker
              dateFormat={"MM/dd/yyyy"}
              minDate={dateRangeMin}
              selected={state.expiresDateInput}
              onChange={(date) => date && handleDateChange(date)}
              popperPlacement="bottom-start"
            />
          </FormField>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={props.isProcessing} onClick={handleClose}>
          {copyText.modalCloseButtonLabel}
        </Button>
        <Button
          disabled={props.isProcessing || !state.nameInput.isValid}
          primary
          width={100}
          onClick={handleSubmit}
        >
          {props.isProcessing ? (
            <LoadingSpinner />
          ) : (
            copyText.modalSubmitButtonLabel
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CreateApiKeyFormModal {
  export const INTERACTION_CANCEL_BUTTON_CLICKED = `CreateApiKeyFormModal.INTERACTION_CANCEL_BUTTON_CLICKED`;
  export const INTERACTION_SUBMIT_BUTTON_CLICKED = `CreateApiKeyFormModal.INTERACTION_SUBMIT_BUTTON_CLICKED`;

  interface InteractionCancelButtonClicked {
    type: typeof INTERACTION_CANCEL_BUTTON_CLICKED;
  }

  interface InteractionSubmitButtonClicked {
    type: typeof INTERACTION_SUBMIT_BUTTON_CLICKED;
    name: string;
    expiresOn?: Date;
  }

  export type Interaction =
    | InteractionCancelButtonClicked
    | InteractionSubmitButtonClicked;
}

export default CreateApiKeyFormModal;
