import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource, Operator } from "@ternary/api-lib/analytics/enums";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { format, startOfDay, sub } from "date-fns";
import UError from "unilib-error";
import useBuildDataQuery from "../../../../api/analytics/utils/useDataQuery";
import { DateHelper } from "../../../../lib/dates";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import {
  AzureCommitmentInventoryDatum,
  AzureCommittedUseDimenstions,
  AzureCommittedUseMeasures,
} from "../types";

export interface Params {
  dateRange: Date[];
  queryFilters?: QueryFilter[];
  isTotals?: boolean;
}

const ISO_TIMESTAMP_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'Z'";

export default function useGetAzureCommitmentInventory(
  params: Params,
  options?: UseQueryOptions<AzureCommitmentInventoryDatum[], UError>
): UseQueryResult<AzureCommitmentInventoryDatum[], UError> {
  const client = useAnalyticsApiClient();
  const dateHelper = new DateHelper();

  const earliest1YPurchaseDate = sub(params.dateRange[0], {
    years: 1,
  });

  const term1YFilter: QueryFilter = {
    and: [
      {
        operator: Operator.GTE,
        name: AzureCommittedUseDimenstions.purchaseDate,
        values: [format(earliest1YPurchaseDate, ISO_TIMESTAMP_FORMAT)],
      },
      {
        operator: Operator.EQUALS,
        name: AzureCommittedUseDimenstions.term,
        values: ["P1Y"],
      },
    ],
  };

  const earliest3YPurchaseDate = sub(params.dateRange[0], {
    years: 3,
  });

  const term3YFilter: QueryFilter = {
    and: [
      {
        operator: Operator.GTE,
        name: AzureCommittedUseDimenstions.purchaseDate,
        values: [format(earliest3YPurchaseDate, ISO_TIMESTAMP_FORMAT)],
      },
      {
        operator: Operator.EQUALS,
        name: AzureCommittedUseDimenstions.term,
        values: ["P3Y"],
      },
    ],
  };

  const dateRange = [earliest3YPurchaseDate, startOfDay(dateHelper.date)];

  const queryFilters: QueryFilter[] = [
    ...(params.queryFilters ?? []),
    { or: [term1YFilter, term3YFilter] },
  ];

  const [tenantID, query] = useBuildDataQuery({
    ...params,
    preAggFilters: queryFilters,
    dataSource: DataSource.AZURE_COMMITMENT_TABLE,
    dateRange,
    dimensions: [
      AzureCommittedUseDimenstions.appliedScopes,
      AzureCommittedUseDimenstions.appliedScopeType,
      AzureCommittedUseDimenstions.billingAccountId,
      AzureCommittedUseDimenstions.billingProfileId,
      AzureCommittedUseDimenstions.billingScopeId,
      AzureCommittedUseDimenstions.commitmentId,
      AzureCommittedUseDimenstions.commitmentName,
      AzureCommittedUseDimenstions.coverageType,
      AzureCommittedUseDimenstions.currencyCode,
      AzureCommittedUseDimenstions.expirationDate,
      AzureCommittedUseDimenstions.instanceType,
      AzureCommittedUseDimenstions.orderId,
      AzureCommittedUseDimenstions.paymentOption,
      AzureCommittedUseDimenstions.purchaseDate,
      AzureCommittedUseDimenstions.recurringFrequency,
      AzureCommittedUseDimenstions.region,
      AzureCommittedUseDimenstions.renew,
      AzureCommittedUseDimenstions.skuName,
      AzureCommittedUseDimenstions.term,
      AzureCommittedUseDimenstions.type,
    ],
    measures: [
      AzureCommittedUseMeasures.avgUtilizationPercent,
      AzureCommittedUseMeasures.instanceCount,
      AzureCommittedUseMeasures.maxUtilizationPercent,
      AzureCommittedUseMeasures.minUtilizationPercent,
      AzureCommittedUseMeasures.recurringCost,
      AzureCommittedUseMeasures.reservedHours,
      AzureCommittedUseMeasures.totalCost,
      AzureCommittedUseMeasures.upfrontCost,
      AzureCommittedUseMeasures.usedHours,
    ],
  });

  return useQuery({
    queryKey: ["azureCommitmentTable", params],
    queryFn: async () => {
      const commitmentInventory = await client.loadData(tenantID, query);

      return commitmentInventory.response.map((datum) => {
        return {
          instanceCount: datum.instanceCount ?? 0,
          recurringCost: datum.recurringCost ?? 0,
          totalCost: datum.totalCost ?? 0,
          upfrontCost: datum.upfrontCost ?? 0,
          minUtilizationPercent: datum.minUtilizationPercent ?? 0,
          maxUtilizationPercent: datum.maxUtilizationPercent ?? 0,
          avgUtilizationPercent: datum.avgUtilizationPercent ?? 0,
          reservedHours: datum.reservedHours ?? 0,
          usedHours: datum.usedHours ?? 0,

          purchaseDate: datum.purchaseDate ?? "",
          expirationDate: datum.expirationDate ?? "",

          billingAccountId: datum.billingAccountId ?? "",
          billingProfileId: datum.billingProfileId ?? "",
          billingScopeId: datum.billingScopeId ?? "",
          commitmentId: datum.commitmentId ?? "",
          commitmentName: datum.commitmentName ?? "",
          coverageType: datum.coverageType ?? "",
          currencyCode: datum.currencyCode ?? "",
          instanceType: datum.instanceType ?? "",
          orderId: datum.orderId ?? "",
          paymentOption: datum.paymentOption ?? "",
          region: datum.region ?? "",
          recurringFrequency: datum.recurringFrequency ?? "",
          renew: datum.renew ?? false,
          appliedScopeType: datum.appliedScopeType ?? "",
          appliedScopes: datum.appliedScopes ?? "",
          skuName: datum.skuName ?? "",
          term: datum.term ?? "",
          type: datum.type ?? "",
        } as AzureCommitmentInventoryDatum;
      });
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
