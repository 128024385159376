import { useFeatureText } from "@/context/FeatureTextProvider";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import React, { useMemo } from "react";
import { maskEmail } from "../../../utils/UserUtils";
import _copyText from "../copyText";

type User = {
  tenantID: string;
  createdAt: string;
  email: string;
  loginCount: number;
  lastLogin: string | null;
  name: string;
  tenantName: string;
};

const columnHelper = createColumnHelper<User>();

interface Props {
  isLoading: boolean;
  showEmails: boolean;
  users: User[];
}

export function MspChildUserAccessTable(props: Props) {
  const { copyText } = useFeatureText(_copyText);

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", { header: copyText.tableHeaderName }),
      columnHelper.accessor("email", {
        cell: ({ getValue }) => {
          const value = getValue();

          return props.showEmails ? value : maskEmail(value);
        },
        header: copyText.tableHeaderEmail,
      }),
      columnHelper.accessor("tenantName", {
        header: copyText.tableHeaderTenantName,
      }),
    ],
    [props.users]
  );

  return (
    <Table
      columns={columns}
      compact
      data={props.users}
      initialState={{ sorting: [{ id: "name", desc: false }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

export default MspChildUserAccessTable;
