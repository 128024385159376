import { UpdateAWSIntegrationParams } from "@/api/core/types";
import useCreateAWSIntegration from "@/features/admin/hooks/useCreateAWSIntegration";
import useUpdateAWSIntegration from "@/features/admin/hooks/useUpdateAWSIntegration";
import { useTheme } from "@emotion/react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FormApi } from "@tanstack/react-form";
import { useQueryClient } from "@tanstack/react-query";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import { AwsIntegrationEntity } from "@ternary/api-lib/core/types/AwsIntegration";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { get, isEqual } from "lodash";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import paths from "../../../../../constants/paths";
import { useActivityTracker } from "../../../../../context/ActivityTrackerProvider";
import useAuthenticatedUser from "../../../../../hooks/useAuthenticatedUser";
import { useNavigateWithSearchParams } from "../../../../../lib/react-router";
import { VerticalStepperForm } from "../../../../../ui-lib/components/Stepper";
import { AlertType, postAlert } from "../../../../../utils/alerts";
import queryKeys from "../../../hooks/queryKeys";
import useValidateDataIntegration from "../../../hooks/useValidateDataIntegration";
import copyText from "../../copyText";
import { useGetAwsIntegrationByID } from "../../hooks/useGetAwsIntegrationByID";
import { AwsFormGeneral } from "./AwsIntegrationFormGeneral";
import { AwsFormLinkedAccounts } from "./AwsIntegrationFormLinkedAccounts";
import AwsFormReports from "./AwsIntegrationFormReports";
import { defaultValues as _defaultValues } from "./constants";
import { CostAndUsageReport, FormData } from "./types";

export function AwsIntegrationFormContainer() {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const location = useLocation();
  const navigate = useNavigateWithSearchParams();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const { integrationID } = useParams();

  //
  // State
  //

  const awsIntegrationLocation: AwsIntegrationEntity | undefined = get(
    location.state,
    "integration"
  );

  //
  // Queries
  //

  // If coming from a fresh session we need to fetch the integration from the server,
  // because it won't exist on location state.
  const { data: awsIntegrationServer, isLoading: isLoadingAwsIntegration } =
    useGetAwsIntegrationByID(integrationID ?? "", {
      enabled: !!integrationID && !awsIntegrationLocation,
    });
  //
  // Mutations
  //

  const { isPending: isCreatingAWSIntegration, mutate: createAWSIntegration } =
    useCreateAWSIntegration({
      onError: () => {
        postAlert({
          message: copyText.errorCreatingAWSIntegrationMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (integrationID) => {
        validateDataIntegration({ integrationID });

        postAlert({
          message: copyText.successCreatingAWSIntegrationMessage,
          type: AlertType.SUCCESS,
        });
        navigate(paths._admin, { searchParams: { tab: "integrations" } });
      },
    });

  const { isPending: isUpdatingAWSIntegration, mutate: updateAWSIntegration } =
    useUpdateAWSIntegration({
      onError: () => {
        postAlert({
          message: copyText.errorUpdatingAWSIntegrationMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (integrationID) => {
        validateDataIntegration({ integrationID });

        postAlert({
          message: copyText.successUpdatingAWSIntegrationMessage,
          type: AlertType.SUCCESS,
        });

        navigate(paths._admin, { searchParams: { tab: "integrations" } });
      },
    });

  const { mutate: validateDataIntegration } = useValidateDataIntegration({
    onError: () => {
      postAlert({
        message: copyText.errorValidatingDataIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSettled: async () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.dataIntegrations, authenticatedUser.tenant.id],
      });
    },
  });

  //
  // Computed Values
  //

  // If use integration on location state first, if we have it.
  const awsIntegration = awsIntegrationLocation ?? awsIntegrationServer;
  const isMspSharedIntegration =
    !!awsIntegration?.mspChildSharedPayerConfiguration;
  //
  // Interaction Handlers
  //

  const defaultValues = awsIntegration
    ? getValuesFromIntegration(awsIntegration)
    : _defaultValues;

  async function handleSubmit({
    formApi,
    value,
  }: {
    value: FormData;
    formApi: FormApi<FormData>;
  }) {
    if (awsIntegration) {
      const defaultValues = formApi.options.defaultValues;

      if (!defaultValues) return;

      handleUpdateAwsIntegration(awsIntegration.id, value, defaultValues);
    } else {
      handleCreateAwsIntegration(authenticatedUser.tenant.id, value);
    }
  }

  function handleCreateAwsIntegration(tenantID: string, value: FormData) {
    activityTracker.captureAction(actions.CLICK_ADMIN_CREATE_INTEGRATION, {
      type: CloudProviderType.AWS,
    });

    const isValidReports =
      value.configuredCURs.length > 0
        ? value.configuredCURs.some((cudReport) => {
            return (
              cudReport.name.length > 0 &&
              cudReport.reportPathPrefix.length > 0 &&
              cudReport.s3BucketName.length > 0
            );
          })
        : [];

    const costAndUsageReports = isValidReports
      ? value.configuredCURs.map((cuds) => ({
          name: cuds.name.trim(),
          region: cuds.region,
          reportPathPrefix: cuds.reportPathPrefix.trim(),
          s3BucketName: cuds.s3BucketName.trim(),
        }))
      : [];

    createAWSIntegration({
      tenantID: tenantID,
      costAndUsageReports: costAndUsageReports,
      linkedAccounts: value.linkedAccounts,
      monitoringRoleARN: value.monitoringRoleARN.trim(),
      name: value.name.trim(),
      roleARN: value.roleARN.trim(),
    });
  }

  function handleUpdateAwsIntegration(
    integrationID: string,
    values: FormData,
    defaultValues: FormData
  ) {
    const changes = getChanges(values, defaultValues);

    activityTracker.captureAction(actions.CLICK_ADMIN_UPDATE_INTEGRATION, {
      type: CloudProviderType.AWS,
    });

    updateAWSIntegration({
      integrationID,
      ...changes,
    });
  }

  //
  // Render
  //

  const steps = [
    {
      component: (form) => (
        <AwsFormGeneral
          form={form}
          isMspSharedIntegration={isMspSharedIntegration}
        />
      ),
      requiredInputs: isMspSharedIntegration ? undefined : ["name", "roleARN"],
      label: copyText.awsFormTabLabelGeneral,
    },
    {
      component: (form) => <AwsFormLinkedAccounts form={form} />,
      isOptional: true,
      label: copyText.awsFormTabLabelLinkedAccounts,
    },
    {
      component: (form) => <AwsFormReports form={form} />,
      isOptional: true,
      label: copyText.awsFormTabLabelAdminReports,
    },
    {
      component: () => (
        <Box width="50%">
          <Text appearance="h4">
            {awsIntegration
              ? copyText.updateIntegrationHeader
              : copyText.createIntegrationHeader}
          </Text>
          <Text marginBottom={theme.space_lg}>
            {awsIntegration
              ? copyText.updateIntegrationLastInstructions
              : copyText.integrationLastInstructions}
          </Text>
        </Box>
      ),
      label: copyText.submitButtonLabel,
    },
  ];

  return (
    <Flex direction="column" height="80vh">
      <Button
        iconStart={<Icon icon={faArrowLeft} />}
        secondary
        width={200}
        onClick={() =>
          navigate(paths._admin, { searchParams: { tab: "integrations" } })
        }
      >
        {copyText.backToIntegrationsButtonLabel}
      </Button>
      <Text appearance="h3" marginVertical={theme.space_md}>
        {awsIntegration
          ? awsIntegration.name
          : copyText.awsFormNewAwsIntegrationHeader}
      </Text>
      <Flex height="100%" width="100%">
        {isLoadingAwsIntegration ? (
          <EmptyPlaceholder loading width="50%" />
        ) : (
          <VerticalStepperForm
            defaultValues={defaultValues}
            isProcessing={isCreatingAWSIntegration || isUpdatingAWSIntegration}
            steps={steps}
            onSubmit={handleSubmit}
          />
        )}
      </Flex>
    </Flex>
  );
}

function getValuesFromIntegration(integration: AwsIntegrationEntity) {
  return {
    // General
    monitoringRoleARN: integration.monitoringRoleARN ?? "",
    name: integration.name,
    roleARN: integration.roleARN ?? "",

    // Linked Accounts
    linkedAccountARN: "",
    linkedAccounts: integration.linkedAccounts
      ? integration.linkedAccounts
      : [],

    // Cost and Usage
    nameCURs: "",
    regionCURs: null,
    reportPathPrefixCURs: "",
    s3BucketNameCURs: "",
    configuredCURs: integration.costAndUsageReports
      ? integration.costAndUsageReports.map((config) => ({
          name: config.name,
          region: config.region,
          reportPathPrefix: config.reportPathPrefix,
          s3BucketName: config.s3BucketName,
        }))
      : ([] as CostAndUsageReport[]),
  };
}

function getChanges(values: FormData, defaultValues: FormData) {
  const changes: UpdateAWSIntegrationParams = {};

  if (!isEqual(defaultValues.name, values.name)) {
    changes.name = values.name.trim();
  }
  if (!isEqual(defaultValues.roleARN, values.roleARN)) {
    changes.roleARN = values.roleARN.trim();
  }
  if (!isEqual(defaultValues.monitoringRoleARN, values.monitoringRoleARN)) {
    changes.monitoringRoleARN = values.monitoringRoleARN.trim();
  }

  if (!isEqual(defaultValues.linkedAccounts, values.linkedAccounts)) {
    changes.linkedAccounts = values.linkedAccounts.map((linkedAccounts) => ({
      accountID: linkedAccounts.accountID,
      roleARN: linkedAccounts.roleARN,
      validations: linkedAccounts.validations,
    }));
  }

  if (!isEqual(defaultValues.configuredCURs, values.configuredCURs)) {
    changes.costAndUsageReports = values.configuredCURs.map((curs) => ({
      name: curs.name.trim(),
      region: curs.region,
      reportPathPrefix: curs.reportPathPrefix.trim(),
      s3BucketName: curs.s3BucketName.trim(),
    }));
  }

  return changes;
}
