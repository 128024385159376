import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import UError from "unilib-error";
import { buildCubeQuery } from "../../../../api/analytics/utils";
import { ANALYTICS_QUERY_GC_TIME } from "../../../../constants";
import { useAnalyticsApiClient } from "../../../../context/AnalyticsQueryLoaderProvider";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { GCPCloudRunService } from "../types";

export interface Params {
  dateRange: Date[];
  queryFilters: QueryFilter[];
}

export default function useGetCloudRunServices(
  params: Params,
  options?: UseQueryOptions<GCPCloudRunService[], UError>
): UseQueryResult<GCPCloudRunService[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["cloudRunServices", params],
    queryFn: async () => {
      const dimensions = ["projectId", "region", "revisionName", "serviceName"];

      const measures = [
        "cpuAllocationTime",
        "memoryAllocationTime",
        "peakInstanceCount",
        "requestCount",
      ];

      const result = (await client.load(
        buildCubeQuery({
          dataSource: DataSource.CLOUD_RUN_SERVICES,
          dateRange: params.dateRange,
          dimensions,
          measures,
          queryFilters: params.queryFilters,
        })
      )) as GCPCloudRunService[];

      const services = result.map((datum): GCPCloudRunService => {
        return {
          // DIMENSIONS
          projectId: datum.projectId,
          region: datum.region,
          revisionName: datum.revisionName,
          serviceName: datum.serviceName,

          // MEASURES
          cpuAllocationTime: datum.cpuAllocationTime,
          memoryAllocationTime: datum.memoryAllocationTime,
          peakInstanceCount: datum.peakInstanceCount,
          requestCount: datum.requestCount,
        };
      });

      return services;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
