import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource, Operator } from "@ternary/api-lib/analytics/enums";
import { isNil } from "lodash";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import { buildCubeQuery } from "../utils";

export interface BillingAccount {
  billingAccountID: string;
  cloudName: string;
}

type BillingAccountDatum = {
  billingAccountId: string | null;
  cloudName: string;
};

export default function useGetBillingAccounts(
  dateRange: Date[],
  options?: UseQueryOptions<BillingAccount[], UError>
): UseQueryResult<BillingAccount[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["billingAccounts"],
    queryFn: async () => {
      const result = (await client.load(
        buildCubeQuery({
          dateRange,
          dataSource: DataSource.BILLING,
          dimensions: ["billingAccountId", "cloudName"],
          queryFilters: [
            {
              name: "dataSource",
              operator: Operator.NOT_EQUALS,
              values: ["INTERNAL"],
            },
          ],
        })
      )) as BillingAccountDatum[];

      return result.reduce((accum: BillingAccount[], entry) => {
        if (!isNil(entry.billingAccountId)) {
          accum.push({
            billingAccountID: entry.billingAccountId,
            cloudName: entry.cloudName,
          });
        }
        return accum;
      }, []);
    },
    ...options,
  });
}
