import useGetUsersByTenantID from "@/api/core/hooks/useGetUsersByTenantID";
import SideDrawerLegacy from "@/components/SideDrawerLegacy";
import { useFilterStore } from "@/context/FilterStoreProvider";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import useAvailableDimensions from "@/hooks/useAvailableDimensions";
import useGatekeeper from "@/hooks/useGatekeeper";
import ConfirmationModal from "@/ui-lib/components/ConfirmationModal";
import { AlertType, postAlert } from "@/utils/alerts";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { faLock, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import { DataSource, DurationType } from "@ternary/api-lib/analytics/enums";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import {
  ScopedViewType,
  UserConfigStatus,
} from "@ternary/api-lib/constants/enums";
import {
  ScopedViewEntity,
  ScopedViewFilter,
  UserConfig,
  UserEntity,
} from "@ternary/api-lib/core/types";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { keyBy } from "lodash";
import React, { useMemo, useState } from "react";
import { isUUID } from "validator";
import useGetDimensionValues from "../../../api/analytics/useGetDimensionValues";
import TextInput from "../../../ui-lib/components/TextInput";
import { getFullName } from "../../../utils/UserUtils";
import useGetDimensionPreferencesByTenantID from "../../admin/hooks/useGetDimensionPreferencesByTenantID";
import copyText from "../copyText";
import scopedViewKeys from "../hooks/queryKeys";
import useCreateScopedView from "../hooks/useCreateScopedView";
import useDeleteScopedView from "../hooks/useDeleteScopedView";
import useGetScopedViewsByTenantID from "../hooks/useGetScopedViewsByTenantID";
import useUpdateScopedView from "../hooks/useUpdateScopedView";
import ScopedViewForm, { Action } from "./ScopedViewForm";
import ScopedViewList from "./ScopedViewList";

const ACTION_PANEL_CREATE_FORM = "ACTION_PANEL_CREATE_FORM";
const ACTION_PANEL_EDIT_FORM = "ACTION_PANEL_EDIT_FORM";

const MODAL_DELETE_CONFIRMATION = "DELETE";

type Interaction = ScopedViewList.Interaction | ScopedViewForm.Interaction;

interface Props {
  isAdmin: boolean;
}

interface State {
  actionPanelKey: string;
  filters: ScopedViewFilter[];
  modalKey: string;
  searchText: string;
  selectedScopedViewID: string;
}

const initialState: State = {
  actionPanelKey: "",
  filters: [],
  modalKey: "",
  searchText: "",
  selectedScopedViewID: "",
};

export default function ScopedViewManagementContainer(
  props: Props
): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const queryClient = useQueryClient();

  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  //
  // State
  //

  const filterStore = useFilterStore();

  const [state, setState] = useState(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const { data = [], isLoading: isLoadingScopedViews } =
    useGetScopedViewsByTenantID(authenticatedUser.tenant.fsDocID, {
      enabled: !props.isAdmin || gatekeeper.canListAdminScopedViews,
    });

  const scopedViews = useMemo(
    () =>
      data.filter((scopedView) =>
        props.isAdmin
          ? scopedView.type !== ScopedViewType.PRIVATE
          : scopedView.type !== ScopedViewType.ADMIN ||
            scopedView.userConfigs.some(
              (userConfig) =>
                userConfig.userID === authenticatedUser.id &&
                userConfig.status !== UserConfigStatus.ENFORCED
            )
      ),
    [data]
  );

  const scopedViewsKeyedByID = keyBy(scopedViews, "id");

  const dataSourceDimensions = state.filters.reduce(
    (accum: { dataSource: DataSource; dimensions: string[] }[], filter) => {
      const currentIndex = accum.findIndex(
        (value) => value.dataSource === filter.dataSource
      );

      if (currentIndex === -1) {
        accum.push({
          dataSource: filter.dataSource,
          dimensions: [filter.name],
        });
      } else {
        accum[currentIndex].dimensions = [
          ...accum[currentIndex].dimensions,
          filter.name,
        ];
      }

      return accum;
    },
    []
  );

  const {
    data: dimensionValuesMap = {},
    isLoading: isLoadingDimensionValuesMap,
  } = useGetDimensionValues(
    {
      dateRange: getCubeDateRangeFromDurationType(
        DurationType.LAST_NINETY_DAYS
      ),
      dataSourceDimensions,
    },
    {
      enabled: dataSourceDimensions.length > 0,
    }
  );

  const { data: users = [] } = useGetUsersByTenantID(
    authenticatedUser.tenant.fsDocID
  );

  const usersKeyedByID = keyBy(users, "id");
  const usersKeyedByEmail = keyBy(users, "email");

  const { data: dimensionPreferences = [] } =
    useGetDimensionPreferencesByTenantID(authenticatedUser.tenant.id);

  //
  // Mutations
  //

  const { isPending: isCreatingScopedView, mutate: createScopedView } =
    useCreateScopedView({
      onError: () => {
        postAlert({
          message: copyText.errorCreatingScopedViewMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (newScopedViewID, params) => {
        queryClient.refetchQueries({ queryKey: scopedViewKeys.all });

        mergeState({
          actionPanelKey: "",
          selectedScopedViewID: "",
        });

        postAlert({
          message: copyText.successCreatingScopedViewMessage,
          type: AlertType.SUCCESS,
        });

        // Add scoped view if applied to current user
        if (
          params.userConfigs.some(
            (userConfig) =>
              userConfig.userID === authenticatedUser.id &&
              userConfig.status === UserConfigStatus.ENABLED
          )
        ) {
          filterStore.set({
            scopedViews: [
              ...filterStore.scopedViews,
              {
                ...params,
                id: newScopedViewID,
              },
            ],
          });
        }
      },
    });

  const { isPending: isDeletingScopedView, mutate: deleteScopedView } =
    useDeleteScopedView({
      onError: () => {
        mergeState({ modalKey: "", selectedScopedViewID: "" });

        postAlert({
          message: copyText.errorDeletingScopedViewMessage,
          type: AlertType.ERROR,
        });
      },

      onSuccess: (scopedViewID) => {
        queryClient.refetchQueries({ queryKey: scopedViewKeys.all });

        mergeState({ modalKey: "", selectedScopedViewID: "" });

        postAlert({
          message: copyText.successDeletingScopedViewMessage,
          type: AlertType.SUCCESS,
        });

        // Remove scoped view if currently applied to current user
        if (filterStore.availableScopedViewIDs.includes(scopedViewID)) {
          filterStore.set({
            scopedViews: filterStore.scopedViews.filter(
              (scopedView) => scopedView.id !== scopedViewID
            ),
          });
        }
      },
    });

  const { isPending: isUpdatingScopedView, mutate: updateScopedView } =
    useUpdateScopedView({
      onError: () => {
        mergeState({
          actionPanelKey: "",
          selectedScopedViewID: "",
        });

        postAlert({
          message: copyText.errorUpdatingScopedViewMessage,
          type: AlertType.ERROR,
        });
      },

      onSuccess: (updatedScopedViewID, updatedParams) => {
        queryClient.refetchQueries({ queryKey: scopedViewKeys.all });

        mergeState({
          actionPanelKey: "",
          selectedScopedViewID: "",
        });

        postAlert({
          message: copyText.successUpdatingScopedViewMessage,
          type: AlertType.SUCCESS,
        });

        const previousScopedView = scopedViewsKeyedByID[updatedScopedViewID];

        // Update scoped view if being applied to current user
        if (
          updatedParams &&
          (previousScopedView.userConfigs.some(
            (userConfig) => userConfig.userID === authenticatedUser.id
          ) ||
            updatedParams.userConfigs?.some(
              (userConfig) => userConfig.userID === authenticatedUser.id
            ))
        ) {
          const changes = {
            ...(updatedParams.filters
              ? { filters: updatedParams.filters }
              : {}),
            ...(updatedParams.name ? { name: updatedParams.name } : {}),
            ...(updatedParams.type ? { type: updatedParams.type } : {}),
            ...(updatedParams.userConfigs
              ? { userConfigs: updatedParams.userConfigs }
              : {}),
          };

          // Remove scoped view if no longer available for user or actioned disabled
          if (
            (previousScopedView.userConfigs.some(
              (userConfig) => userConfig.userID === authenticatedUser.id
            ) &&
              (!changes.userConfigs?.some(
                (userConfig) => userConfig.userID === authenticatedUser.id
              ) ||
                changes.userConfigs?.length === 0)) ||
            changes.userConfigs?.some(
              (userConfig) =>
                userConfig.userID === authenticatedUser.id &&
                userConfig.status === UserConfigStatus.DISABLED
            )
          ) {
            filterStore.set({
              scopedViews: [
                ...filterStore.scopedViews.filter(
                  (scopedView) => scopedView.id !== updatedScopedViewID
                ),
              ],
            });
            return;
          }

          filterStore.set({
            scopedViews: [
              ...filterStore.scopedViews.filter(
                (scopedView) => scopedView.id !== updatedScopedViewID
              ),
              { ...previousScopedView, ...changes },
            ],
          });
          return;
        }
      },
    });

  //
  // Interaction Handlers
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case ScopedViewForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({
          actionPanelKey: "",
          selectedScopedViewID: "",
        });
        return;
      }
      case ScopedViewForm.INTERACTION_FILTER_NAME_CHANGED: {
        const { index, filter } = interaction;

        setState((currentState) => {
          const filters = [
            ...currentState.filters.slice(0, index),
            filter,
            ...currentState.filters.slice(index + 1),
          ];

          return { ...currentState, filters: filters };
        });
        return;
      }
      case ScopedViewForm.INTERACTION_REMOVE_FILTER_BUTTON_CLICKED: {
        const { index } = interaction;

        setState((currentState) => {
          return {
            ...currentState,
            filters: [
              ...currentState.filters.slice(0, index),
              ...currentState.filters.slice(index + 1),
            ],
          };
        });
        return;
      }
      case ScopedViewForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE: {
        createScopedView({
          tenantID: authenticatedUser.tenant.fsDocID,
          applyToNewUserStatus: interaction.applyToNewUserStatus,
          filters: interaction.filters.map((filter) => ({
            dataSource: filter.dataSource,
            name: filter.name,
            operator: filter.operator,
            values: filter.values,
          })),
          name: interaction.name,
          type: props.isAdmin ? ScopedViewType.ADMIN : ScopedViewType.PRIVATE,
          userConfigs: interaction.userConfigs,
        });
        return;
      }
      case ScopedViewForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE: {
        const { type: _ignore, ...params } = interaction;
        updateScopedView({
          ...params,
          filters: params.filters?.map((filter) => ({
            dataSource: filter.dataSource,
            name: filter.name,
            operator: filter.operator,
            values: filter.values,
          })),
          userConfigs: params.userConfigs?.map((userConfig) => ({
            userID: userConfig.userID,
            status: userConfig.status,
          })),
        });
        return;
      }
      case ScopedViewList.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_DELETE_CONFIRMATION,
          selectedScopedViewID: interaction.scopedViewID,
        });

        return;
      }
      case ScopedViewList.INTERACTION_EDIT_BUTTON_CLICKED: {
        const { scopedViewID } = interaction;

        const scopedView = scopedViewsKeyedByID[scopedViewID];

        mergeState({
          actionPanelKey: ACTION_PANEL_EDIT_FORM,
          filters: scopedView.filters,
          selectedScopedViewID: scopedViewID,
        });
        return;
      }
      case ScopedViewList.INTERACTION_MAKE_COPY_BUTTON_CLICKED: {
        mergeState({
          actionPanelKey: ACTION_PANEL_CREATE_FORM,
          selectedScopedViewID: interaction.scopedViewID,
        });
        return;
      }
    }
  }

  //
  // Render
  //

  let filteredScopedViews = scopedViews;

  if (state.searchText.length > 0) {
    filteredScopedViews = scopedViews.filter((scopedView) => {
      return scopedView.name
        .toLowerCase()
        .includes(state.searchText.toLowerCase());
    });
  }

  if (props.isAdmin && !gatekeeper.canListAdminScopedViews) {
    return (
      <Flex alignItems="center" justifyContent="center" minHeight="50vh">
        <EmptyPlaceholder
          icon={faLock}
          loading={false}
          text={copyText.emptyPlaceholderInsufficientPermission}
        />
      </Flex>
    );
  }

  const availableDimensionsMap = useAvailableDimensions();

  const selectedScopedView: ScopedViewEntity | undefined =
    scopedViewsKeyedByID[state.selectedScopedViewID];

  // There is an inconsistency with the userConfigs userID where some can be emails.
  // As well as users in the scoped view no longer having access to the Tenant.
  // This is a bandaid to ensure that we always use the IDs and only users that are in the tenant.
  if (selectedScopedView) {
    selectedScopedView.userConfigs = selectedScopedView.userConfigs.reduce(
      (accum: UserConfig[], userConfig) => {
        let user: UserEntity | undefined;

        if (isUUID(userConfig.userID)) {
          user = usersKeyedByID[userConfig.userID];
        } else {
          user = usersKeyedByEmail[userConfig.userID];
        }

        return user ? [...accum, { ...userConfig, userID: user.id }] : accum;
      },
      []
    );
  }

  function renderForm(): JSX.Element | null {
    switch (state.actionPanelKey) {
      case ACTION_PANEL_CREATE_FORM: {
        return (
          <ScopedViewForm
            action={Action.CREATE}
            availableDimensionsMap={availableDimensionsMap}
            dimensionValuesMap={dimensionValuesMap}
            isLoading={isLoadingScopedViews}
            isLoadingDimensionValuesMap={isLoadingDimensionValuesMap}
            isProcessing={isCreatingScopedView}
            dimensionPreferences={dimensionPreferences}
            scopedView={
              selectedScopedView
                ? {
                    ...selectedScopedView,
                    userConfigs: selectedScopedView.userConfigs.filter(
                      (userConfig) =>
                        userConfig.userID === authenticatedUser.id &&
                        userConfig.status !== UserConfigStatus.ENFORCED
                    ),
                  }
                : undefined
            }
            type={props.isAdmin ? ScopedViewType.ADMIN : ScopedViewType.PRIVATE}
            users={users}
            onInteraction={handleInteraction}
          />
        );
      }
      case ACTION_PANEL_EDIT_FORM: {
        return (
          <ScopedViewForm
            action={Action.UPDATE}
            availableDimensionsMap={availableDimensionsMap}
            dimensionValuesMap={dimensionValuesMap}
            isLoading={isLoadingScopedViews}
            isLoadingDimensionValuesMap={isLoadingDimensionValuesMap}
            isProcessing={isUpdatingScopedView}
            dimensionPreferences={dimensionPreferences}
            scopedView={scopedViewsKeyedByID[state.selectedScopedViewID]}
            type={props.isAdmin ? ScopedViewType.ADMIN : ScopedViewType.PRIVATE}
            users={users}
            onInteraction={handleInteraction}
          />
        );
      }
      default: {
        return null;
      }
    }
  }

  function renderModal(): JSX.Element | undefined {
    switch (state.modalKey) {
      case MODAL_DELETE_CONFIRMATION: {
        const scopedView = scopedViewsKeyedByID[state.selectedScopedViewID];

        const assignedUsers: string[] = [];

        scopedView.userConfigs.forEach((user) =>
          assignedUsers.push(getFullName(usersKeyedByID[user.userID]))
        );

        const scopedViewHasUsersAssigned = scopedView.userConfigs.length > 0;

        const firstFewAssignees = assignedUsers.slice(0, 3).join(", ");

        const deleteDisplayUsers = scopedViewHasUsersAssigned
          ? copyText.deleteScopedViewConfirmationMessage +
            copyText.deleteScopedViewUsersAssignedMessage +
            (assignedUsers.length > 3
              ? firstFewAssignees +
                copyText.deleteScopedViewAnd +
                (assignedUsers.length - 3) +
                copyText.deleteScopedViewOthers
              : firstFewAssignees)
          : copyText.deleteScopedViewConfirmationMessage;

        return (
          <ConfirmationModal
            isLoading={isDeletingScopedView}
            message={deleteDisplayUsers}
            title={copyText.deleteScopedViewConfirmationTitle}
            variant="danger"
            onCancel={() =>
              mergeState({ modalKey: "", selectedScopedViewID: "" })
            }
            onConfirm={() =>
              deleteScopedView({
                scopedViewID: state.selectedScopedViewID,
              })
            }
          />
        );
      }
    }
  }

  return (
    <Box marginBottom={theme.size_large}>
      {renderModal()}
      <Flex alignItems="center" justifyContent="space-between">
        <Text
          fontSize={theme.h3_fontSize}
          fontWeight={theme.h3_fontWeight}
          marginVertical={theme.space_md}
          color={theme.text_color}
        >
          {copyText.scopedViewsSubTitle}
        </Text>

        <Flex>
          <Box width={300} marginRight={theme.space_lg}>
            <TextInput
              iconEnd={
                <Icon color={theme.text_color_secondary} icon={faSearch} />
              }
              placeholder={copyText.searchInputPlaceholder}
              size="large"
              value={state.searchText}
              onChange={(e) => mergeState({ searchText: e.target.value })}
            />
          </Box>

          <Button
            iconStart={<Icon icon={faPlus} />}
            primary
            onClick={() =>
              mergeState({
                actionPanelKey: ACTION_PANEL_CREATE_FORM,
                filters: [],
              })
            }
          >
            {copyText.createScopedViewButtonLabel}
          </Button>
        </Flex>
      </Flex>
      <SideDrawerLegacy
        isOpen={state.actionPanelKey.length > 0}
        title={
          state.actionPanelKey === ACTION_PANEL_CREATE_FORM
            ? copyText.sideDrawerTitleCreate
            : copyText.sideDrawerTitleEdit
        }
        onClose={() =>
          mergeState({
            actionPanelKey: "",
            selectedScopedViewID: "",
          })
        }
        renderContent={() =>
          state.actionPanelKey.length > 0 ? renderForm() : null
        }
        width="850px"
      />
      <ScopedViewList
        isAdmin={props.isAdmin}
        isLoading={isLoadingScopedViews}
        scopedViews={filteredScopedViews}
        users={users}
        onInteraction={(interaction) => handleInteraction(interaction)}
      />
    </Box>
  );
}
