import ErrorBoundary from "@/components/ErrorBoundary";
import Tabs from "@/ui-lib/components/Tabs";
import { useTheme } from "@emotion/react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import paths from "../../../../constants/paths";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import useGetTenantByID from "../../../../hooks/useGetTenantByID";
import { useNavigateWithSearchParams } from "../../../../lib/react-router";
import _copyText from "../copyText";
import { MspBillingStatementManagementContainer } from "./MspBillingStatementManagementContainer";
import { MspBillingStatementTenantInfoContainer } from "./MspBillingStatementTenantInfoContainer";
import { useFeatureText } from "@/context/FeatureTextProvider";

const TAB_BILLING_INFO = "info";
const TAB_BILLING_STATEMENTS = "statements";

export function MspBillingStatementTenantDetailsPage(): JSX.Element {
  const gatekeeper = useGatekeeper();
  const navigate = useNavigateWithSearchParams();
  const theme = useTheme();

  const { copyText } = useFeatureText(_copyText);

  const { tenantID = "" } = useParams();

  //
  // State
  //

  const [searchParamState, setSearchParamState] = useQueryParams({
    tab: withDefault(StringParam, TAB_BILLING_STATEMENTS),
  });

  //
  // Queries
  //

  const { data: tenant, isLoading: isLoadingTenant } =
    useGetTenantByID(tenantID);

  //
  // Render
  //

  if (!gatekeeper.canAccessMspAdmin) {
    return <Navigate to={paths._home} replace />;
  }

  const containers = [
    {
      component: <MspBillingStatementManagementContainer />,
      label: copyText.tabLabelBillingStatements,
      value: TAB_BILLING_STATEMENTS,
    },
    {
      component: <MspBillingStatementTenantInfoContainer />,
      label: copyText.tabLabelBillingInformation,
      value: TAB_BILLING_INFO,
    },
  ];

  return (
    <>
      <ErrorBoundary boundaryName="MspBillingStatementTenantDetailsPage">
        <Box marginBottom={theme.space_sm}>
          <Button
            iconStart={<Icon icon={faArrowLeft} />}
            secondary
            size="small"
            onClick={() => navigate(paths._mspBillingStatements)}
          >
            {copyText.backToAllTenantsButtonLabel}
          </Button>
        </Box>
        {tenant ? (
          <Text appearance="h3" marginBottom={theme.space_lg}>
            {tenant.name}: {copyText.billingDetailsHeader}
          </Text>
        ) : (
          <EmptyPlaceholder
            height={36}
            loading={isLoadingTenant}
            skeletonVariant="bar"
            width={400}
          />
        )}
        <Tabs
          activeValue={searchParamState.tab}
          tabs={containers}
          onSelect={(tab: string) => setSearchParamState({ tab })}
        />
      </ErrorBoundary>
    </>
  );
}
