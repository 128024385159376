import { SortRule } from "@/types";
import Dropdown from "@/ui-lib/components/Dropdown";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import { createSortingUtils } from "@/utils/sort";
import { useTheme } from "@emotion/react";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { formatCurrency } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import copyText from "../../copyText";
import { GCPKubernetesResourceType } from "../types";

type TableData = {
  id: string;
  cpuUtilization: number;
  memoryUtilization: number;
  name: string;
  projectID: string;
  totalBytes: number;
  totalBytesCost: number;
  totalCores: number;
  totalCoresCost: number;
  usedBytes: number;
  usedCores: number;
  waste: number;
};

interface Props {
  dataSource:
    | typeof DataSource.KUBERNETES_CLUSTER_USAGE
    | typeof DataSource.KUBERNETES_CONTAINER_USAGE
    | typeof DataSource.KUBERNETES_NODE_USAGE;
  dayCount: string;
  initialGrouping: GCPKubernetesResourceType;
  initialSortBy: SortRule | null;
  isLoading: boolean;
  isUpdating: boolean;
  resources: TableData[];
  resourceType: GCPKubernetesResourceType;
  selectedProjectID: string;
  selectedResourceID: string;
  onInteraction: (interaction: GCPKubernetesResourceTable.Interaction) => void;
}

const { numberSort } = createSortingUtils<TableData>();

function GCPKubernetesResourceTable(props: Props): JSX.Element {
  const theme = useTheme();
  const columnHelper = createColumnHelper<TableData>();

  const tooltipText = getTooltipText(
    props.dataSource,
    props.initialGrouping,
    props.resourceType
  );

  const hiddenColumns = [
    (props.initialGrouping === GCPKubernetesResourceType.CLUSTER &&
      props.resourceType !== GCPKubernetesResourceType.CLUSTER) ||
    (props.initialGrouping === GCPKubernetesResourceType.NAMESPACE &&
      props.resourceType !== GCPKubernetesResourceType.NAMESPACE)
      ? "totalBytes"
      : "usedOverRequestedBytes",
    (props.initialGrouping === GCPKubernetesResourceType.CLUSTER &&
      props.resourceType !== GCPKubernetesResourceType.CLUSTER) ||
    (props.initialGrouping === GCPKubernetesResourceType.NAMESPACE &&
      props.resourceType !== GCPKubernetesResourceType.NAMESPACE)
      ? "totalCores"
      : "usedOverRequestedCores",

    props.resourceType === GCPKubernetesResourceType.CLUSTER ||
    props.dataSource === DataSource.KUBERNETES_NODE_USAGE
      ? ""
      : "projectID",
    props.selectedProjectID ? "projectID" : "",
  ];

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: copyText.gkeResourceListHeaderName,
        meta: { truncate: true },
        size: 200,
      }),
      columnHelper.accessor("projectID", {
        header: copyText.gkeResourceListHeaderProjectID,
        size: 200,
      }),
      columnHelper.accessor("totalCoresCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.gkeResourceListHeaderCPUCost,
        meta: { align: "right" },
        size: 120,
        sortDescFirst: true,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("cpuUtilization", {
        cell: ({ getValue }) => (
          <>
            {getValue() > 0
              ? `${getValue().toFixed(2)}%`
              : copyText.gkeResourceListLabelNotAvailable}
          </>
        ),
        header: copyText.gkeResourceListHeaderCPUUtilization,
        meta: { align: "right" },
        size: 110,
        sortDescFirst: true,
        sortingFn: numberSort,
      }),
      columnHelper.display({
        id: "usedOverRequestedCores",
        cell: ({ row }) => (
          <>{`${row.original.usedCores.toFixed(
            2
          )} / ${row.original.totalCores.toFixed(2)}`}</>
        ),
        header: copyText.gkeResourceListHeaderUsedOverRequestedCores,
        meta: { align: "right" },
        size: 200,
        sortDescFirst: true,
        sortingFn: (rowA, rowB) =>
          rowA.original.totalCores > rowB.original.totalCores ? 1 : -1,
      }),
      columnHelper.accessor("totalCores", {
        cell: ({ getValue }) => <>{getValue().toFixed(2)}</>,
        header:
          props.resourceType === GCPKubernetesResourceType.CLUSTER
            ? copyText.gkeResourceListHeaderTotalCores
            : copyText.gkeResourceListHeaderTotalRequestedCores,
        meta: { align: "right" },
        size: 120,
        sortDescFirst: true,
      }),
      columnHelper.accessor("totalBytesCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.gkeResourceListHeaderMemoryCost,
        meta: { align: "right" },
        size: 140,
        sortDescFirst: true,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("memoryUtilization", {
        cell: ({ getValue }) => (
          <>
            {getValue() > 0
              ? `${getValue().toFixed(2)}%`
              : copyText.gkeResourceListLabelNotAvailable}
          </>
        ),
        header: copyText.gkeResourceListHeaderMemoryUtilization,
        meta: { align: "right" },
        size: 130,
        sortDescFirst: true,
        sortingFn: numberSort,
      }),
      columnHelper.display({
        id: "usedOverRequestedBytes",
        cell: ({ row }) => (
          <>{`${prettyBytes(row.original.usedBytes)} / ${prettyBytes(
            row.original.totalBytes
          )}`}</>
        ),
        header: copyText.gkeResourceListHeaderUsedOverRequestedBytes,
        meta: { align: "right" },
        size: 200,
        sortDescFirst: true,
        sortingFn: (rowA, rowB) =>
          rowA.original.totalBytes > rowB.original.totalBytes ? 1 : -1,
      }),
      columnHelper.accessor("totalBytes", {
        cell: ({ getValue }) => <>{prettyBytes(getValue())}</>,
        header:
          props.resourceType === GCPKubernetesResourceType.CLUSTER
            ? copyText.gkeResourceListHeaderTotalBytes
            : copyText.gkeResourceListHeaderTotalRequestedBytes,
        meta: { align: "right" },
        size: 110,
        sortDescFirst: true,
      }),
      columnHelper.accessor("waste", {
        cell: ({ getValue }) => (
          <>
            {!isNaN(getValue())
              ? formatCurrency({ number: getValue() })
              : copyText.gkeResourceListLabelNotAvailable}
          </>
        ),
        header: copyText.gkeResourceListHeaderWaste,
        meta: { align: "right" },
        size: 142,
        sortDescFirst: true,
        sortingFn: (rowA, rowB) => {
          const wasteA = rowA.original.waste;
          const wasteB = rowB.original.waste;

          if (typeof wasteA === "number" && typeof wasteB === "number") {
            if (isNaN(wasteA) && isNaN(wasteB)) return 0;
            if (isNaN(wasteA)) return 1;
            if (isNaN(wasteB)) return -1;
            return wasteA === wasteB ? 0 : wasteA < wasteB ? -1 : 1;
          }

          return 0;
        },
      }),
      columnHelper.display({
        id: "drillDown",
        cell: function renderButton({ row }) {
          const drillDownOptions = [
            ...(props.resources.length === 1
              ? []
              : [
                  {
                    label: tooltipText.detail,
                    onClick: () => {
                      props.onInteraction({
                        type: GCPKubernetesResourceTable.INTERACTION_VIEW_RESOURCE_CLICKED,
                        name: row.original.name,
                        projectID: row.original.projectID,
                      });
                    },
                  },
                ]),
            ...(props.resourceType === GCPKubernetesResourceType.WORKLOAD
              ? []
              : [
                  {
                    label: tooltipText.drilldown,
                    onClick: () => {
                      props.onInteraction({
                        type: GCPKubernetesResourceTable.INTERACTION_DRILL_DOWN_CLICKED,
                        name: row.original.name,
                        projectID: row.original.projectID,
                      });
                    },
                  },
                ]),
          ];

          return (
            <Flex alignItems="center">
              {
                <Dropdown options={drillDownOptions} placement="bottom-end">
                  <Button
                    iconStart={<Icon icon={faEllipsisV} />}
                    secondary
                    size="tiny"
                  />
                </Dropdown>
              }
            </Flex>
          );
        },
        header: () =>
          props.isUpdating ? (
            <LoadingSpinner color={theme.table_header_text_color} size="lg" />
          ) : null,
        size: 40,
      }),
    ],
    [
      props.isUpdating,
      props.resourceType,
      props.initialGrouping,
      props.dataSource,
      props.resources,
    ]
  );

  return (
    <Table
      columns={columns}
      data={props.resources}
      initialState={{
        sorting: [props.initialSortBy ?? { id: "totalCoresCost", desc: true }],
        columnVisibility: Object.fromEntries(
          hiddenColumns.filter(Boolean).map((col) => [col, false])
        ),
      }}
      isLoading={
        props.isLoading || (props.isUpdating && !props.resources.length)
      }
      selectedRowID={props.selectedResourceID}
      showPagination
      sortable
      onChangeSortBy={(sortRules) =>
        props.onInteraction({
          type: GCPKubernetesResourceTable.INTERACTION_CHANGE_SORT_BY,
          sortRule: sortRules[0] ?? null,
        })
      }
    />
  );
}

function getTooltipText(dataSource, initialGrouping, resourceType) {
  const tooltipText = {
    detail: "",
    drilldown: "",
  };

  if (dataSource === DataSource.KUBERNETES_NODE_USAGE) {
    tooltipText.detail = copyText.gkeResourceListTooltipLabelViewCluster;
    tooltipText.drilldown = copyText.gkeResourceListTooltipLabelViewNamespaces;
  } else {
    if (initialGrouping === GCPKubernetesResourceType.CLUSTER) {
      if (resourceType === GCPKubernetesResourceType.NAMESPACE) {
        tooltipText.detail = copyText.gkeResourceListTooltipLabelViewNamespace;
        tooltipText.drilldown =
          copyText.gkeResourceListTooltipLabelViewWorkloads;
      } else {
        tooltipText.detail = copyText.gkeResourceListTooltipLabelViewWorkloads;
        tooltipText.drilldown =
          copyText.gkeResourceListTooltipLabelViewNamespaces;
      }
    } else {
      if (resourceType === GCPKubernetesResourceType.NAMESPACE) {
        tooltipText.detail = copyText.gkeResourceListTooltipLabelViewNamespace;
        tooltipText.drilldown =
          copyText.gkeResourceListTooltipLabelViewClusters;
      } else {
        tooltipText.detail = copyText.gkeResourceListTooltipLabelViewWorkload;
      }
      if (resourceType === GCPKubernetesResourceType.CLUSTER) {
        tooltipText.detail = copyText.gkeResourceListTooltipLabelViewCluster;
        tooltipText.drilldown =
          copyText.gkeResourceListTooltipLabelViewWorkloads;
      }
    }
  }
  return tooltipText;
}

GCPKubernetesResourceTable.TABLE_ROW_LIMIT = 10_000 as const;

GCPKubernetesResourceTable.INTERACTION_CHANGE_SORT_BY =
  "GCPKubernetesResourceTable.INTERACTION_CHANGE_SORT_BY" as const;
GCPKubernetesResourceTable.INTERACTION_DRILL_DOWN_CLICKED =
  "GCPKubernetesResourceTable.INTERACTION_DRILL_DOWN_CLICKED" as const;
GCPKubernetesResourceTable.INTERACTION_VIEW_RESOURCE_CLICKED =
  "GCPKubernetesResourceTable.INTERACTION_VIEW_RESOURCE_CLICKED" as const;

interface InteractionChangeSortBy {
  type: typeof GCPKubernetesResourceTable.INTERACTION_CHANGE_SORT_BY;
  sortRule: SortRule | null;
}

interface InteractionDrillDownClicked {
  type: typeof GCPKubernetesResourceTable.INTERACTION_DRILL_DOWN_CLICKED;
  name: string;
  projectID: string;
}

interface InteractionViewResourceClicked {
  type: typeof GCPKubernetesResourceTable.INTERACTION_VIEW_RESOURCE_CLICKED;
  name: string;
  projectID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace GCPKubernetesResourceTable {
  export type Interaction =
    | InteractionChangeSortBy
    | InteractionDrillDownClicked
    | InteractionViewResourceClicked;
}

export default GCPKubernetesResourceTable;
