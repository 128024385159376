import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import SelectCheckbox from "@/ui-lib/components/SelectCheckbox";
import { Option } from "@/ui-lib/components/SelectDropdown";
import TextInput from "@/ui-lib/components/TextInput";
import getMergeState from "@/utils/getMergeState";
import { useDebounce } from "@/utils/timers";
import { useTheme } from "@emotion/react";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { uniqBy } from "lodash";
import React, { useMemo, useState } from "react";
import _copyText from "../copyText";
import { MspSharedIntegrations } from "../types";
import { UPDATE_INTEGRATIONS } from "./MspChildTenantIntegrationManagementContainer";
import MspSharedIntegrationsTable from "./MspSharedIntegrationsTable";
import { SelectedSubAccounts } from "./MspSubaccountsFormContainer";
import { useFeatureText } from "@/context/FeatureTextProvider";
interface Props {
  action: string;
  isProcessing: boolean;
  canSubmit: boolean;
  selectedSubAccounts: SelectedSubAccounts;
  sharedIntegrations: MspSharedIntegrations[];
  onNextStep: (value: string) => void;
  onInteraction: (interaction: MspSharedIntegrationsTable.Interaction) => void;
}

interface State {
  searchText: string;
  integrationFilters: string[];
  providerFilters: string[];
  isLoadingSearch: boolean;
}

const initialState: State = {
  searchText: "",
  integrationFilters: [],
  providerFilters: [],
  isLoadingSearch: false,
};

export default function MspManageSubaccountsSection(props: Props): JSX.Element {
  const theme = useTheme();
  const activityTracker = useActivityTracker();

  const { copyText } = useFeatureText(_copyText);

  //
  // State
  //

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  const debouncedSearchText = useDebounce(state.searchText);
  const isExpanded = state.searchText.length > 0;
  const numberOfSelected = Object.keys(props.selectedSubAccounts).reduce(
    (accum: number, key): number => {
      accum += props.selectedSubAccounts[key].subaccounts?.length ?? 0;
      return accum;
    },
    0
  );

  const integrationOptions: Option[] = props.sharedIntegrations.map(
    (integrations) => ({
      label: integrations.name,
      value: integrations.id,
    })
  );

  const providerOptions: Option[] = uniqBy(
    props.sharedIntegrations.map((integrations) => ({
      label: integrations.provider,
      value: integrations.provider,
    })),
    "value"
  );

  const filteredSharedIntegrations = useMemo(() => {
    const integrations = getFilteredSharedIntegrations({
      sharedIntegrations: props.sharedIntegrations ?? [],
      searchText: debouncedSearchText,
      filters: {
        id: state.integrationFilters,
        provider: state.providerFilters,
      },
    });
    setTimeout(() => mergeState({ isLoadingSearch: false }), 100);
    return integrations;
  }, [
    debouncedSearchText,
    props.sharedIntegrations,
    state.integrationFilters,
    state.providerFilters,
  ]);

  //
  // Render
  //

  const mspSharedIntegrationControls = () => {
    return (
      <Flex direction="column">
        <Flex
          alignItems="center"
          marginBottom={theme.space_md}
          justifyContent={numberOfSelected > 0 ? "space-between" : "flex-end"}
          flex={1}
        >
          {numberOfSelected > 0 ? (
            <Flex alignItems="center">
              <Text fontSize={theme.h4_fontSize}>
                {`${numberOfSelected} ${copyText.integrationsSelectedLabel}`}
              </Text>
            </Flex>
          ) : null}
          <Flex>
            <Box marginRight={theme.space_sm} width={300}>
              <TextInput
                iconEnd={
                  <Icon color={theme.text_color_secondary} icon={faSearch} />
                }
                onChange={(e) =>
                  mergeState({
                    searchText: e.target.value,
                    isLoadingSearch: true,
                  })
                }
                placeholder={copyText.searchSubaccountInputPlaceholder}
                size="medium"
                value={state.searchText}
              />
            </Box>
            <Box width={200} marginRight={theme.space_sm}>
              <SelectCheckbox
                compact
                isLoading={props.isProcessing}
                options={integrationOptions}
                placeholder={copyText.mspSharedIntegrationsFilterPlaceHolder}
                selectedValues={state.integrationFilters}
                onChange={(values) => {
                  activityTracker.captureAction(
                    actions.SELECT_MSP_SUBACCOUNTS_INTEGRATIONS_FILTER,
                    {
                      integrationFilters: values,
                    }
                  );
                  mergeState({ integrationFilters: values });
                }}
              />
            </Box>
            <Box width={155}>
              <SelectCheckbox
                compact
                isLoading={props.isProcessing}
                options={providerOptions}
                placeholder={copyText.mspSharedIntegrationsProviderPlaceHolder}
                selectedValues={state.providerFilters}
                onChange={(values) => {
                  activityTracker.captureAction(
                    actions.SELECT_MSP_SUBACCOUNTS_PROVIDER_FILTER,
                    {
                      providerFilters: values,
                    }
                  );
                  mergeState({ providerFilters: values });
                }}
              />
            </Box>
            <Box marginLeft={theme.space_md}>
              <Button
                primary
                size="small"
                disabled={!props.canSubmit}
                onClick={() => props.onNextStep("2")}
              >
                {copyText.mspSharedIntegrationsReviewLabel}
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex direction="column">
      <Flex marginBottom={theme.space_sm} alignItems="center">
        <Text>
          {props.action === UPDATE_INTEGRATIONS
            ? copyText.mspChildUpdateSubaccountCaption
            : copyText.mspChildAddSubaccountCaption}
        </Text>
      </Flex>
      {mspSharedIntegrationControls()}
      <MspSharedIntegrationsTable
        isProcessing={props.isProcessing || state.isLoadingSearch}
        displayActions
        isExpanded={isExpanded}
        sharedIntegrations={filteredSharedIntegrations ?? []}
        onInteraction={props.onInteraction}
      />
    </Flex>
  );
}

type GetFilteredSharedIntegrationsParams = {
  sharedIntegrations: MspSharedIntegrations[];
  searchText: string | null;
  filters: {
    id: string[];
    provider: string[];
  };
};

function getFilteredSharedIntegrations(
  params: GetFilteredSharedIntegrationsParams
): MspSharedIntegrations[] {
  const searchText = (params.searchText ?? "").toLowerCase().trim();

  return params.sharedIntegrations
    .filter((sharedIntegrations) => {
      if (!isSearchTextInSharedIntegrations(sharedIntegrations, searchText)) {
        return false;
      }

      if (!sharedIntegrationPassesFilters(sharedIntegrations, params.filters)) {
        return false;
      }

      return true;
    })
    .map((filteredIntegrations) => {
      return {
        ...filteredIntegrations,
        subAccounts: filteredIntegrations.subAccounts.filter((account) => {
          if (searchText === "") return true;
          const value =
            account.projectName === "" || account.projectName === null
              ? "null"
              : account.projectName;
          return value.toLocaleLowerCase().trim().includes(searchText);
        }),
      };
    });
}

function isSearchTextInSharedIntegrations(
  sharedIntegrations: MspSharedIntegrations,
  searchText: string
): boolean {
  if (searchText === "") return true;

  const values = sharedIntegrations.subAccounts
    .map((account) => account.projectName)
    .map((value) => (value === "" || value === null ? "null" : value));

  return values.some((value) =>
    value.toLowerCase().trim().includes(searchText)
  );
}

function sharedIntegrationPassesFilters(
  sharedIntegration: MspSharedIntegrations,
  filters: {
    id: string[];
    provider: string[];
  }
): boolean {
  if (!filters.id.length && !filters.provider.length) {
    return true;
  }

  return (
    filters.id.includes(sharedIntegration.id) ||
    filters.provider.includes(sharedIntegration.provider)
  );
}
