import { useTheme } from "@emotion/react";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import IsInHiddenTabProvider from "@ternary/api-lib/ui-lib/context/IsInHiddenTabProvider";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { noop } from "lodash";
import React from "react";
import timing from "../../constants/timing";

export type Tab = {
  component: JSX.Element | null;
  disabled?: boolean;
  disabledText?: string;
  label: string;
  value: string;
};

export interface Props {
  activeValue: string;
  tabs: Tab[];
  width?: string | number;
  filters?: JSX.Element[];
  onSelect: (value: string) => void;
}

interface TabProps {
  active: boolean;
  disabled: boolean;
  disabledText?: string;
  label: string;
  value: string;
  onSelect: (value: string) => void;
}

// Think of the components prop as a form of dependency injection
// We want the parent to supply props directly
// And we don't want the children unmounting on tab change
export default function Tabs(props: Props): JSX.Element {
  const theme = useTheme();

  return (
    <>
      <Flex justifyContent="space-between">
        <Flex
          marginBottom={theme.space_md}
          marginTop={theme.space_xs}
          scrollable
        >
          {props.tabs.map((item) => (
            <Tab
              key={item.value}
              active={item.value === props.activeValue}
              disabled={Boolean(item.disabled)}
              disabledText={item.disabledText}
              label={item.label}
              value={item.value}
              onSelect={props.onSelect}
            />
          ))}
        </Flex>
        {props.filters ? props.filters.map((filter) => filter) : null}
      </Flex>
      {props.tabs.map((item) => (
        <Box
          key={item.value}
          display={item.value === props.activeValue ? "initial" : "none"}
          flex="1 0 0"
          overflowY="auto"
        >
          <IsInHiddenTabProvider isTabHidden={item.value !== props.activeValue}>
            {item.component}
          </IsInHiddenTabProvider>
        </Box>
      ))}
    </>
  );

  function Tab(props: TabProps): JSX.Element {
    let color = theme.tab_text_color;
    if (props.active) color = theme.primary_color_text;
    if (props.disabled) color = theme.text_color_disabled;

    return (
      <Box
        key={props.value}
        cursor={props.disabled ? "default" : "pointer"}
        marginRight={theme.space_lg}
        onClick={() => (props.disabled ? noop : props.onSelect(props.value))}
      >
        {props.disabled && props.disabledText ? (
          <Tooltip content={props.disabledText}>
            <Text
              bold={props.active}
              color={color}
              marginBottom={theme.space_sm}
            >
              {props.label}
            </Text>
          </Tooltip>
        ) : (
          <Text bold={props.active} color={color} marginBottom={theme.space_sm}>
            {props.label}
          </Text>
        )}
        <Box
          borderBottom={
            props.active
              ? `1px solid ${theme.primary_color_background}`
              : "none"
          }
          transition={`width ${timing.selectedDuration}ms`}
          width={props.active ? "100%" : "0%"}
        />
      </Box>
    );
  }
}
