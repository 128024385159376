import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import useGatekeeper from "@/hooks/useGatekeeper";
import ConfirmationModal from "@/ui-lib/components/ConfirmationModal";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import { ScopedViewType } from "@ternary/api-lib/constants/enums";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useState } from "react";
import useGetUsersByTenantID from "../../../../api/core/hooks/useGetUsersByTenantID";
import SideDrawerLegacy from "../../../../components/SideDrawerLegacy";
import TextInput from "../../../../ui-lib/components/TextInput";
import { AlertType, postAlert } from "../../../../utils/alerts";
import useGetScopedViewsByTenantID from "../../../user-settings/hooks/useGetScopedViewsByTenantID";
import copyText from "../../copyText";
import queryKeys from "../../hooks/queryKeys";
import useCreateUserGroupConfig from "../../hooks/useCreateUserGroupConfig";
import useDeleteUserGroupConfig from "../../hooks/useDeleteUserGroupConfig";
import useGetUserGroupConfigs from "../../hooks/useGetUserGroupConfigs";
import useUpdateUserGroupConfig from "../../hooks/useUpdateUserGroupConfig";
import UserGroupConfigForm, { Action } from "./UserGroupConfigForm";
import UserGroupConfigList from "./UserGroupConfigList";

const MODAL_DELETE_CONFIRMATION = "DELETE";

type Interaction =
  | UserGroupConfigList.Interaction
  | UserGroupConfigForm.Interaction;

interface State {
  actionPanelKey?: string;
  modalKey?: string;
  searchText: string;
  selectedUserGroupConfigID?: string;
}

const initialState = {
  actionPanelKey: undefined,
  modalKey: undefined,
  searchText: "",
  selectedUserGroupConfigID: undefined,
};

export default function UserGroupConfigManagementContainer(): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const gatekeeper = useGatekeeper();

  //
  // State
  //

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const { data: userGroupConfigs = [], isLoading: isLoadingUserGroupConfigs } =
    useGetUserGroupConfigs(authenticatedUser.tenant.id);

  const { data: scopedViews = [], isLoading: isLoadingScopedViews } =
    useGetScopedViewsByTenantID(authenticatedUser.tenant.fsDocID);

  const { data: users = [] } = useGetUsersByTenantID(
    authenticatedUser.tenant.fsDocID
  );

  //
  // Mutations
  //

  const {
    isPending: isCreatingUserGroupConfig,
    mutate: createUserGroupConfig,
  } = useCreateUserGroupConfig({
    onError: () => {
      postAlert({
        message: copyText.errorCreatingUserGroupConfigMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: queryKeys.userGroupConfigs });

      mergeState({
        actionPanelKey: undefined,
        selectedUserGroupConfigID: undefined,
      });

      postAlert({
        message: copyText.successCreatingUserGroupConfigMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  const {
    isPending: isDeletingUserGroupConfig,
    mutate: deleteUserGroupConfig,
  } = useDeleteUserGroupConfig({
    onError: () => {
      postAlert({
        message: copyText.errorDeletingUserGroupConfigMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: queryKeys.userGroupConfigs });

      mergeState({
        modalKey: undefined,
        selectedUserGroupConfigID: undefined,
      });

      postAlert({
        message: copyText.successDeletingUserGroupConfigMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  const {
    isPending: isUpdatingUserGroupConfig,
    mutate: updateUserGroupConfig,
  } = useUpdateUserGroupConfig({
    onError: () => {
      postAlert({
        message: copyText.errorUpdatingUserGroupConfigMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: queryKeys.userGroupConfigs });

      mergeState({
        actionPanelKey: undefined,
        selectedUserGroupConfigID: undefined,
      });

      postAlert({
        message: copyText.successUpdatingUserGroupConfigMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  //
  // Interaction Handlers
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case UserGroupConfigForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({
          actionPanelKey: undefined,
          selectedUserGroupConfigID: undefined,
        });
        return;
      }
      case UserGroupConfigForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE: {
        createUserGroupConfig({
          tenantID: authenticatedUser.tenant.id,
          groupName: interaction.groupName,
          scopedViews: interaction.scopedViews,
        });
        return;
      }
      case UserGroupConfigForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE: {
        if (!state.selectedUserGroupConfigID) return;

        updateUserGroupConfig({
          userGroupConfigID: state.selectedUserGroupConfigID,
          ...(interaction.scopedViews
            ? { scopedViews: interaction.scopedViews }
            : {}),
        });
        return;
      }
      case UserGroupConfigList.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_DELETE_CONFIRMATION,
          selectedUserGroupConfigID: interaction.userGroupConfigID,
        });
        return;
      }
      case UserGroupConfigList.INTERACTION_EDIT_BUTTON_CLICKED: {
        mergeState({
          actionPanelKey: Action.UPDATE,
          selectedUserGroupConfigID: interaction.userGroupConfigID,
        });
        return;
      }
      case UserGroupConfigList.INTERACTION_MAKE_COPY_BUTTON_CLICKED: {
        mergeState({
          actionPanelKey: Action.COPY,
          selectedUserGroupConfigID: interaction.userGroupConfigID,
        });
        return;
      }
    }
  }

  //
  // Render
  //

  let filteredUserGroupConfigs = userGroupConfigs;

  if (state.searchText.length > 0) {
    filteredUserGroupConfigs = userGroupConfigs.filter((userGroupConfigs) => {
      return userGroupConfigs.groupName
        .toLowerCase()
        .includes(state.searchText.toLowerCase());
    });
  }

  const userGroupConfig = userGroupConfigs.find(
    (config) => config.id === state.selectedUserGroupConfigID
  );

  const existingGroupNames = userGroupConfigs.map((config) => config.groupName);

  const adminScopedViews = scopedViews.filter(
    (scopedView) => scopedView.type === ScopedViewType.ADMIN
  );

  // Filter out private scoped views

  function renderForm(): JSX.Element | null {
    switch (state.actionPanelKey) {
      case Action.COPY: {
        return (
          <UserGroupConfigForm
            action={Action.COPY}
            existingGroupNames={existingGroupNames}
            isLoading={isLoadingScopedViews}
            isProcessing={
              isCreatingUserGroupConfig || isUpdatingUserGroupConfig
            }
            scopedViews={adminScopedViews}
            userGroupConfig={userGroupConfig}
            onInteraction={handleInteraction}
          />
        );
      }
      case Action.CREATE: {
        return (
          <UserGroupConfigForm
            action={Action.CREATE}
            existingGroupNames={existingGroupNames}
            isLoading={isLoadingScopedViews}
            isProcessing={
              isCreatingUserGroupConfig || isUpdatingUserGroupConfig
            }
            scopedViews={adminScopedViews}
            onInteraction={handleInteraction}
          />
        );
      }
      case Action.UPDATE: {
        return (
          <UserGroupConfigForm
            action={Action.UPDATE}
            existingGroupNames={existingGroupNames}
            isLoading={isLoadingScopedViews}
            isProcessing={
              isCreatingUserGroupConfig || isUpdatingUserGroupConfig
            }
            scopedViews={adminScopedViews}
            userGroupConfig={userGroupConfig}
            onInteraction={handleInteraction}
          />
        );
      }
      default: {
        return null;
      }
    }
  }

  function renderModal(): JSX.Element | null {
    switch (state.modalKey) {
      case MODAL_DELETE_CONFIRMATION: {
        if (!state.selectedUserGroupConfigID) return null;

        return (
          <ConfirmationModal
            isLoading={isDeletingUserGroupConfig}
            message={copyText.userGroupConfigDeleteModalMessage}
            title={copyText.userGroupConfigDeleteModalTitle}
            variant="danger"
            onCancel={() =>
              mergeState({
                modalKey: undefined,
                selectedUserGroupConfigID: undefined,
              })
            }
            onConfirm={() => {
              deleteUserGroupConfig({
                userGroupConfigID: state.selectedUserGroupConfigID ?? "",
              });
            }}
          />
        );
      }
      default: {
        return null;
      }
    }
  }

  return (
    <Flex direction="column">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginVertical={theme.space_md}
      >
        <Text fontSize={theme.h4_fontSize}>
          {copyText.tabLabelUserGroupConfigs}
        </Text>
        <Flex>
          <Box width={300} marginRight={theme.space_lg}>
            <TextInput
              iconEnd={
                <Icon color={theme.text_color_secondary} icon={faSearch} />
              }
              placeholder={copyText.searchInputPlaceholder}
              size="large"
              value={state.searchText}
              onChange={(e) => mergeState({ searchText: e.target.value })}
            />
          </Box>
          <Button
            iconStart={<Icon icon={faPlus} />}
            locked={!gatekeeper.canCreateUserGroupConfig}
            primary
            onClick={() => mergeState({ actionPanelKey: Action.CREATE })}
          >
            {copyText.addUserGroupConfigButtonLabel}
          </Button>
        </Flex>
      </Flex>
      <SideDrawerLegacy
        isOpen={!!state.actionPanelKey}
        title={
          state.actionPanelKey === Action.CREATE ||
          state.actionPanelKey === Action.COPY
            ? copyText.sideDrawerTitleCreate
            : copyText.sideDrawerTitleEdit
        }
        onClose={() =>
          mergeState({
            actionPanelKey: undefined,
            selectedUserGroupConfigID: undefined,
          })
        }
        renderContent={() => (state.actionPanelKey ? renderForm() : null)}
        width="550px"
      />
      <UserGroupConfigList
        isLoading={isLoadingUserGroupConfigs}
        userGroupConfigs={filteredUserGroupConfigs}
        users={users}
        onInteraction={handleInteraction}
      />
      {renderModal()}
    </Flex>
  );
}
