import { buildCubeQuery } from "@/api/analytics/utils";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { DateHelper } from "@/lib/dates";
import { useQueries } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { startOfMonth } from "date-fns";
import UError from "unilib-error";
import { ComputeSpendSummaryEntity } from "../../../api/analytics/types";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

export interface Params {
  dataSource?:
    | typeof DataSource.AWS_COMPUTE_VISIBILITY
    | typeof DataSource.AZURE_COMPUTE_VISIBILITY
    | typeof DataSource.GCP_COMPUTE_VISIBILITY;
  queryFilters?: QueryFilter[];
}

type UseQueriesOptions = [
  [ComputeSpendSummaryEntity, UError],
  [ComputeSpendSummaryEntity, UError],
  [ComputeSpendSummaryEntity, UError],
];

export default function useGetComputeSpendSummary(
  params?: Params,
  options?: UseQueryOptions<ComputeSpendSummaryEntity, UError>
): UseQueryResult<ComputeSpendSummaryEntity, UError>[] {
  const client = useAnalyticsApiClient();
  const dateHelper = new DateHelper();
  const gatekeeper = useGatekeeper();

  // For now the only two possible datasources are AWS & GCP. We need to gate this
  // query depending on which one gets passed in.
  let enabled = gatekeeper.hasGCPIntegration;
  if (params?.dataSource === DataSource.AZURE_COMPUTE_VISIBILITY) {
    enabled = gatekeeper.hasAzureIntegration;
  } else if (params?.dataSource === DataSource.AWS_COMPUTE_VISIBILITY) {
    enabled = gatekeeper.hasAWSIntegration;
  }

  const measures = ["cost"];

  return useQueries<UseQueriesOptions>({
    queries: [
      // This MTD
      {
        queryFn: async () => {
          const result = (await client.load(
            buildCubeQuery({
              ...params,
              dataSource:
                params?.dataSource ?? DataSource.GCP_COMPUTE_VISIBILITY,
              dateRange: [dateHelper.firstOfMonth(), dateHelper.date],
              measures,
            })
          )) as { cost: number | null }[];

          return {
            totalCost: result[0].cost ?? 0,
          };
        },
        queryKey: ["computeSpendSummary", "currentMTD", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        enabled,
        ...options,
      },
      // Last Month Full
      {
        queryFn: async () => {
          const result = (await client.load(
            buildCubeQuery({
              ...params,
              dataSource:
                params?.dataSource ?? DataSource.GCP_COMPUTE_VISIBILITY,
              dateRange: [
                dateHelper.firstOfLastMonth(),
                dateHelper.lastDayLastMonth(),
              ],
              measures,
            })
          )) as { cost: number | null }[];

          return {
            totalCost: result[0].cost ?? 0,
          };
        },
        queryKey: ["computeSpendSummary", "lastMonthFull", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        enabled,
        ...options,
      },
      // LAST MONTH TO DATE
      {
        queryFn: async () => {
          const result = (await client.load(
            buildCubeQuery({
              ...params,
              dataSource:
                params?.dataSource ?? DataSource.GCP_COMPUTE_VISIBILITY,
              dateRange: [
                startOfMonth(dateHelper.sameDayLastMonth()),
                dateHelper.sameDayLastMonth(),
              ],
              measures,
            })
          )) as { cost: number | null }[];

          return {
            totalCost: result[0].cost ?? 0,
          };
        },
        queryKey: ["computeSpendSummary", "lastMTD", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        enabled,
        ...options,
      },
    ],
  });
}
