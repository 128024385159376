import paths from "@/constants/paths";
import useGatekeeper from "@/hooks/useGatekeeper";
import { useMatchPath } from "@/lib/react-router";
import { useTheme } from "@emotion/react";
import {
  faGripHorizontal,
  faPencil,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { TextWidgetEntity } from "@ternary/api-lib/core/types";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import React, { useState } from "react";
import { Input } from "../../../types";
import { FormField } from "../../../ui-lib/components/Form";
import MarkdownWrapper from "../../../ui-lib/components/MarkdownWrapper";
import TextArea from "../../../ui-lib/components/TextArea";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";

interface Props {
  dashboardID: string;
  height: number;
  index: number;
  isEditLayout?: boolean;
  text: TextWidgetEntity;
  onInteraction: (interaction: TextWidgetContainer.Interaction) => void;
}

interface State {
  isEditingDescription: boolean;
  descriptionInput: Input<string>;
}

export function TextWidgetContainer(props: Props): JSX.Element {
  const currentPath = useMatchPath();
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  //
  // State
  //

  const [state, setState] = useState<State>({
    isEditingDescription:
      props.text.description === "" &&
      gatekeeper.getCanEditTextWidget(props.text.createdByID)
        ? true
        : false,
    descriptionInput: {
      hasChanged: false,
      isValid: true,
      value: props.text.description,
    },
  });

  const mergeState = getMergeState(setState);

  //
  // Interaction Handlers
  //

  function handleChangeDescription(value): void {
    mergeState({
      descriptionInput: {
        value,
        hasChanged: value !== props.text.description,
        isValid: true,
      },
    });
  }

  //
  // Render
  //

  const showGripIcon = currentPath === paths._dashboard && props.isEditLayout;
  const showDeleteButton = currentPath === paths._dashboard;
  const showEditButton = currentPath === paths._dashboard;

  return (
    <Box height="100%">
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        height="100%"
        paddingBottom={theme.space_md}
        paddingHorizontal={theme.space_md}
        width="100%"
      >
        {showGripIcon && (
          <Flex
            alignItems="center"
            justifyContent="center"
            paddingTop={theme.space_xxs}
          >
            <Flex marginBottom={`-${theme.space_sm}`}>
              <Icon
                color={theme.report_display_grip_color}
                draggable
                icon={faGripHorizontal}
                size="lg"
              />
              <Box marginLeft={1}>
                <Icon
                  color={theme.report_display_grip_color}
                  draggable
                  icon={faGripHorizontal}
                  size="lg"
                />
              </Box>
            </Flex>
          </Flex>
        )}

        <Box height="100%" width="100%">
          <Box paddingVertical={theme.space_sm}>
            <Flex alignItems="center" justifyContent="space-between">
              <Box
                maxHeight={`calc(${theme.h4_fontSize} * 1.5)`}
                overflow="hidden"
              ></Box>
              <Flex alignItems="center">
                <Box marginHorizontal={theme.space_xs}>
                  {state.isEditingDescription && (
                    <Button
                      secondary
                      onClick={() => {
                        mergeState({
                          descriptionInput: {
                            hasChanged: false,
                            isValid: true,
                            value: props.text.description,
                          },
                          isEditingDescription: false,
                        });
                      }}
                    >
                      {copyText.cancelButtonLabel}
                    </Button>
                  )}
                </Box>
                {showEditButton &&
                  (state.isEditingDescription ? (
                    <Button
                      primary
                      disabled={
                        !state.descriptionInput.hasChanged ||
                        !gatekeeper.canUpdateDashboard(props.dashboardID) ||
                        !gatekeeper.getCanEditTextWidget(props.text.createdByID)
                      }
                      onClick={() => {
                        props.onInteraction({
                          type: TextWidgetContainer.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE,
                          id: props.text.id,
                          description: state.descriptionInput.value,
                        });
                        mergeState({ isEditingDescription: false });
                      }}
                    >
                      {copyText.saveButtonLabel}
                    </Button>
                  ) : (
                    <Button
                      disabled={
                        props.isEditLayout ||
                        !gatekeeper.getCanEditTextWidget(
                          props.text.createdByID
                        ) ||
                        !gatekeeper.canUpdateDashboard(props.dashboardID)
                      }
                      onClick={() => mergeState({ isEditingDescription: true })}
                      iconStart={<Icon icon={faPencil} />}
                    />
                  ))}
                {showDeleteButton && (
                  <Button
                    disabled={
                      !gatekeeper.canUpdateDashboard(props.dashboardID) ||
                      !gatekeeper.getCanDeleteTextWidget(props.text.createdByID)
                    }
                    iconStart={<Icon icon={faTrashAlt} />}
                    marginLeft={theme.space_xs}
                    size="small"
                    onClick={() =>
                      props.onInteraction({
                        type: TextWidgetContainer.INTERACTION_DELETE_BUTTON_CLICKED,
                        index: props.index,
                      })
                    }
                  />
                )}
              </Flex>
            </Flex>
          </Box>
          <Box height="85%" paddingBottom={theme.space_md}>
            {state.isEditingDescription ? (
              <Box height="100%" width="95%">
                <FormField
                  input={TextArea}
                  marginBottom={0}
                  maxLength={2000}
                  name={"TEXT"}
                  resizeable
                  rows={getRows(props.height)}
                  value={state.descriptionInput.value}
                  onChange={(e) => handleChangeDescription(e.target.value)}
                />
              </Box>
            ) : (
              <Box
                marginBottom={theme.space_sm}
                maxHeight="90%"
                maxWidth="95%"
                overflowY="auto"
                onClick={() => {
                  if (props.isEditLayout) return;
                  mergeState({ isEditingDescription: true });
                }}
              >
                <MarkdownWrapper disableHTML>
                  {state.descriptionInput.value}
                </MarkdownWrapper>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function getRows(height) {
  switch (height) {
    case 1:
      return 5;
    case 2:
      return 15;
    case 3:
      return 30;
    case 4:
      return 40;
    default:
      return 55;
  }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace TextWidgetContainer {
  export const INTERACTION_DELETE_BUTTON_CLICKED = `TextWidgetContainer.INTERACTION_DELETE_BUTTON_CLICKED`;
  export const INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE = `TextWidgetContainer.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE`;

  interface InteractionDeleteButtonClicked {
    type: typeof TextWidgetContainer.INTERACTION_DELETE_BUTTON_CLICKED;
    index: number;
  }

  interface InteractionSubmitButtonClickedUpdate {
    type: typeof TextWidgetContainer.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE;
    id: string;
    description?: string;
  }

  export type Interaction =
    | InteractionDeleteButtonClicked
    | InteractionSubmitButtonClickedUpdate;
}

export default TextWidgetContainer;
