import { WidgetType } from "@ternary/api-lib/constants/enums";
import {
  BudgetEntity,
  SavingsOpportunityFilterEntity,
  TextWidgetEntity,
} from "@ternary/api-lib/core/types";
import { ReportEntity } from "@ternary/api-lib/core/types/Report";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import { keyBy } from "lodash";
import React, { useMemo } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import paths from "../../../constants/paths";
import { useMatchPath } from "../../../lib/react-router";
import BudgetViewContainer from "./BudgetViewContainer";
import RealizedCommitmentSavingsContainer from "./RealizedCommitmentSavingsContainer";
import ReportViewContainer from "./ReportViewContainer";
import SavingsOpportunitysViewContainer from "./SavingsOpportunityViewContainer";
import TextWidgetContainer from "./TextWidgetContainer";

type WidgetSpec = {
  budgetID: string | null;
  reportID: string | null;
  savingsOpportunityFilterID: string | null;
  textWidgetID: string | null;
  height: number;
  type: WidgetType;
  width: number;
  xCoordinate: number;
  yCoordinate: number;
};

//Row height at 200 to allow for vertical half size
const ROW_HEIGHT = 200;
const COL_LIMIT = 4;

// NOTE: Generally child components should not know about the api but I am making an
// exception in this case due to this component being a "connector" for two containers.

type GridLayout = {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minW?: number;
  maxW?: number;
  minH?: number;
  maxH?: number;
  static?: boolean;
  isDraggable?: boolean;
  isResizable?: boolean;
  resizeHandles?: string[];
  isBounded?: boolean;
};

interface Props {
  dashboardID: string;
  editLayout: boolean;
  widgetSpecs: WidgetSpec[];
  filters: SavingsOpportunityFilterEntity[];
  reports: ReportEntity[];
  budgets: BudgetEntity[];
  textWidgets: TextWidgetEntity[];
  onInteraction: (
    interaction:
      | BudgetViewContainer.Interaction
      | RealizedCommitmentSavingsContainer.Interaction
      | ReportGrid.Interaction
      | ReportViewContainer.Interaction
      | SavingsOpportunitysViewContainer.Interaction
      | TextWidgetContainer.Interaction
  ) => void;
}
const ReactGridLayout = WidthProvider(RGL);

export function ReportGrid(props: Props): JSX.Element {
  const currentPath = useMatchPath();

  const canEditLayout = Boolean(
    props.editLayout && currentPath === paths._dashboard
  );

  const reportsKeyedByID = keyBy<ReportEntity | undefined>(props.reports, "id");
  const budgetsKeyedByID = keyBy<BudgetEntity | undefined>(props.budgets, "id");
  const filtersKeyedByID = keyBy<SavingsOpportunityFilterEntity | undefined>(
    props.filters,
    "id"
  );
  const textsKeyedByID = keyBy<TextWidgetEntity | undefined>(
    props.textWidgets,
    "id"
  );

  function handleLayoutChange(grid: GridLayout[]): void {
    const widgetSpecs: WidgetSpec[] = [];

    grid.forEach((gridItem) => {
      // This is because we allow the same resource to exist more than once in a dashboard.
      // The react key format is `resourceID:widgetType:index`. We need to split these out
      // before sending this to the container for updating state & the dashboard via api.
      const [resourceID, widgetType] = splitGridItemID(gridItem.i);

      if (!widgetType) return;

      widgetSpecs.push({
        budgetID:
          widgetType === WidgetType.BUDGET_CURRENT_MONTH ||
          widgetType === WidgetType.BUDGET_DAILY_TRENDS
            ? resourceID
            : null,
        reportID: widgetType === WidgetType.REPORT ? resourceID : null,
        savingsOpportunityFilterID:
          widgetType === WidgetType.SAVINGS_OPPORTUNITY_FILTER
            ? resourceID
            : null,
        textWidgetID: widgetType === WidgetType.TEXT ? resourceID : null,
        type: widgetType,
        height: gridItem.h,
        width: gridItem.w,
        xCoordinate: gridItem.x,
        yCoordinate: gridItem.y,
      });
    });

    props.onInteraction({
      type: ReportGrid.INTERACTION_CONFIG_POSITIONS_UPDATED,
      widgetSpecs,
    });
  }

  const layouts = useMemo(() => {
    return props.widgetSpecs.map((spec, index) => ({
      // This is very important that `i` matches whatever the react key is. Otherwise
      // the library won't work as expected.
      i: getGridItemID(spec, index),
      x: spec.xCoordinate,
      y: spec.yCoordinate,
      w: spec.width,
      h: spec.height,
      resizeHandles: ["s", "w", "e", "n", "sw", "nw", "se", "ne"],
      isDraggable: canEditLayout,
      isResizable: canEditLayout,
    }));
  }, [props.widgetSpecs, canEditLayout]);

  function renderContainer(spec: WidgetSpec, index: number) {
    switch (spec.type) {
      case WidgetType.REPORT: {
        const report = reportsKeyedByID[spec.reportID as string];

        if (report) {
          return (
            <ReportViewContainer
              dashboardID={props.dashboardID}
              isEditLayout={canEditLayout}
              index={index}
              report={report}
              onInteraction={props.onInteraction}
            />
          );
        }
        break;
      }
      case WidgetType.BUDGET_DAILY_TRENDS:
      case WidgetType.BUDGET_CURRENT_MONTH: {
        const budget = budgetsKeyedByID[spec.budgetID as string];

        if (budget) {
          return (
            <BudgetViewContainer
              budget={budget}
              budgetType={spec.type}
              dashboardID={props.dashboardID}
              index={index}
              isEditLayout={canEditLayout}
              onInteraction={props.onInteraction}
            />
          );
        }
        break;
      }
      case WidgetType.REALIZED_COMMITMENT_SAVINGS: {
        return (
          <RealizedCommitmentSavingsContainer
            dashboardID={props.dashboardID}
            isEditLayout={canEditLayout}
            index={index}
            onInteraction={props.onInteraction}
          />
        );
      }
      case WidgetType.SAVINGS_OPPORTUNITY_FILTER: {
        const filter =
          filtersKeyedByID[spec.savingsOpportunityFilterID as string];

        if (filter) {
          return (
            <SavingsOpportunitysViewContainer
              dashboardID={props.dashboardID}
              isEditLayout={canEditLayout}
              index={index}
              savingsOpportunity={filter}
              onInteraction={props.onInteraction}
            />
          );
        }
        break;
      }
      case WidgetType.TEXT: {
        const text = textsKeyedByID[spec.textWidgetID as string];

        if (text) {
          return (
            <TextWidgetContainer
              dashboardID={props.dashboardID}
              height={spec.height}
              index={index}
              isEditLayout={canEditLayout}
              text={text}
              onInteraction={props.onInteraction}
            />
          );
        }
        break;
      }
    }
  }

  return (
    <Box
      marginHorizontal="-27px"
      padding={"0.5rem"}
      overflowX="scroll"
      minWidth={1200}
    >
      <ReactGridLayout
        cols={COL_LIMIT}
        isBoudned={false}
        isDraggable={canEditLayout}
        isResizable={canEditLayout}
        layout={layouts}
        margin={[15, 15]}
        rowHeight={ROW_HEIGHT}
        onLayoutChange={handleLayoutChange}
      >
        {props.widgetSpecs.map((spec, index) => {
          return (
            <div
              key={getGridItemID(spec, index)}
              data-grid={layouts[index]}
              style={{ position: "relative" }}
            >
              {renderContainer(spec, index)}
            </div>
          );
        })}
      </ReactGridLayout>
    </Box>
  );
}

const DELIM = ":";

function getGridItemID(spec: WidgetSpec, index: number) {
  let resourceID: string = "";

  switch (spec.type) {
    case WidgetType.BUDGET_CURRENT_MONTH:
    case WidgetType.BUDGET_DAILY_TRENDS:
      resourceID = spec.budgetID as string;
      break;
    case WidgetType.REALIZED_COMMITMENT_SAVINGS:
      resourceID = WidgetType.REALIZED_COMMITMENT_SAVINGS;
      break;
    case WidgetType.REPORT:
      resourceID = spec.reportID as string;
      break;
    case WidgetType.SAVINGS_OPPORTUNITY_FILTER:
      resourceID = spec.savingsOpportunityFilterID as string;
      break;
    case WidgetType.TEXT:
      resourceID = spec.textWidgetID as string;
      break;
  }

  return [resourceID, spec.type, index].join(DELIM);
}

function splitGridItemID(id: string) {
  const [resourceID, widgetType] = id.split(DELIM);

  if (
    widgetType !== WidgetType.BUDGET_CURRENT_MONTH &&
    widgetType !== WidgetType.BUDGET_DAILY_TRENDS &&
    widgetType !== WidgetType.REPORT &&
    widgetType !== WidgetType.SAVINGS_OPPORTUNITY_FILTER &&
    widgetType !== WidgetType.REALIZED_COMMITMENT_SAVINGS &&
    widgetType !== WidgetType.TEXT
  ) {
    return [resourceID, null] as const;
  }

  return [resourceID, widgetType] as const;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ReportGrid {
  export const INTERACTION_CONFIG_POSITIONS_UPDATED = `ReportGrid.INTERACTION_CONFIG_POSITIONS_UPDATED`;

  interface InteractionConfigPositionsUpdated {
    type: typeof ReportGrid.INTERACTION_CONFIG_POSITIONS_UPDATED;
    widgetSpecs: WidgetSpec[];
  }

  export type Interaction = InteractionConfigPositionsUpdated;
}

export default ReportGrid;
