export const ChartType = {
  AREA: "AREA",
  CLUSTERED_BAR: "CLUSTERED_BAR",
  KPI: "KPI",
  LINE: "LINE",
  PIE: "PIE",
  STACKED_AREA: "STACKED_AREA",
  STACKED_BAR: "STACKED_BAR",
  TABLE: "TABLE",
} as const;

export type ChartType = (typeof ChartType)[keyof typeof ChartType];

export const CompareDurationType = {
  PREVIOUS_PERIOD: "PREVIOUS_PERIOD",
  CUSTOM: "CUSTOM",
  INVOICE: "INVOICE",
} as const;

export type CompareDurationType =
  (typeof CompareDurationType)[keyof typeof CompareDurationType];

export const DataSource = {
  ALERT_EVENTS: "ALERT_EVENTS",
  AWS_COMMITMENT_ALLOCATION: "AWS_COMMITMENT_ALLOCATION",
  AWS_COMMITMENT_CHART: "AWS_COMMITMENT_CHART",
  AWS_COMMITMENT_TABLE: "AWS_COMMITMENT_TABLE",
  AWS_COMPUTE_INSTANCES: "AWS_COMPUTE_INSTANCES",
  AWS_COMPUTE_UTILIZATION: "AWS_COMPUTE_UTILIZATION",
  AWS_COMPUTE_VISIBILITY: "AWS_COMPUTE_VISIBILITY",
  AWS_DATABASE_ELASTICACHE: "AWS_DATABASE_ELASTICACHE",
  AWS_DATABASE_INSTANCES: "AWS_DATABASE_INSTANCES",
  AWS_DATABASE_MEMORY_DB: "AWS_DATABASE_MEMORY_DB",
  AWS_DATABASE_VISIBILITY: "AWS_DATABASE_VISIBILITY",
  AWS_EBS_VISIBILITY: "AWS_EBS_VISIBILITY",
  AWS_EBS_VOLUMES: "AWS_EBS_VOLUMES",
  AWS_KUBERNETES_INSTANCES: "AWS_KUBERNETES_INSTANCES",
  AWS_KUBERNETES_NODE_COST: "AWS_KUBERNETES_NODE_COST",
  AWS_KUBERNETES_NODE_USAGE: "AWS_KUBERNETES_NODE_USAGE",
  AWS_OPEN_SEARCH_VISIBILITY: "AWS_OPEN_SEARCH_VISIBILITY",
  AWS_REDSHIFT_VISIBILITY: "AWS_REDSHIFT_VISIBILITY",
  AWS_STORAGE_VISIBILITY: "AWS_STORAGE_VISIBILITY",
  AZURE_COMMITMENT_CHART: "AZURE_COMMITMENT_CHART",
  AZURE_COMMITMENT_TABLE: "AZURE_COMMITMENT_TABLE",
  AZURE_COMPUTE_VISIBILITY: "AZURE_COMPUTE_VISIBILITY",
  AZURE_KUBERNETES_NODE_USAGE: "AZURE_KUBERNETES_NODE_USAGE",
  AZURE_SQL_VISIBILITY: "AZURE_SQL_VISIBILITY",
  AZURE_STORAGE_VISIBILITY: "AZURE_STORAGE_VISIBILITY",
  BIGQUERY_CAPACITY_COMMITTED: "BIGQUERY_CAPACITY_COMMITTED",
  BIGQUERY_COST: "BIGQUERY_COST",
  BIGQUERY_RESERVATION_USAGE: "BIGQUERY_RESERVATION_USAGE",
  BIGQUERY_STORAGE: "BIGQUERY_STORAGE",
  BIGQUERY_TIMELINE: "BIGQUERY_TIMELINE",
  BIGQUERY_USAGE: "BIGQUERY_USAGE",
  BILLING: "BILLING",
  CARBON_FOOTPRINT: "CARBON_FOOTPRINT",
  CLOUD_RUN_SERVICES: "CLOUD_RUN_SERVICES",
  CLOUD_RUN: "CLOUD_RUN",
  CLOUD_SPANNER_USAGE: "CLOUD_SPANNER_USAGE",
  CLOUD_SPANNER: "CLOUD_SPANNER",
  CLOUD_SQL_COST: "CLOUD_SQL_COST",
  CLOUD_SQL_INSTANCE_USAGE_DAILY: "CLOUD_SQL_INSTANCE_USAGE_DAILY",
  CLOUD_SQL_INSTANCE_USAGE: "CLOUD_SQL_INSTANCE_USAGE",
  COMMITTED_USE: "COMMITTED_USE",
  DETAILED_BILLING: "DETAILED_BILLING",
  EXTERNAL_METRICS: "EXTERNAL_METRICS",
  FOCUS_BILLING: "FOCUS_BILLING",
  GCP_BIGQUERY_COMMITMENT_INVENTORY: "GCP_BIGQUERY_COMMITMENT_INVENTORY",
  GCP_COMBINED_CUD_UTILIZATION: "GCP_COMBINED_CUD_UTILIZATION",
  GCP_COMPUTE_CUD_INVENTORY: "GCP_COMPUTE_CUD_INVENTORY",
  GCP_COMPUTE_CUD_SPEND_INVENTORY: "GCP_COMPUTE_CUD_SPEND_INVENTORY",
  GCP_COMPUTE_CUD_UTILIZATION: "GCP_COMPUTE_CUD_UTILIZATION",
  GCP_COMPUTE_INSTANCES: "GCP_COMPUTE_INSTANCES",
  GCP_COMPUTE_VISIBILITY: "GCP_COMPUTE_VISIBILITY",
  GCP_CUD_COVERABLE_COST: "GCP_CUD_COVERABLE_COST",
  GCP_CUSTOM_PRICING: "GCP_CUSTOM_PRICING",
  KUBERNETES_CLUSTER_USAGE: "KUBERNETES_CLUSTER_USAGE",
  KUBERNETES_CONTAINER_USAGE: "KUBERNETES_CONTAINER_USAGE",
  KUBERNETES_NODE_USAGE: "KUBERNETES_NODE_USAGE",
  MSP_ALERT_ROLLUP: "MSP_ALERT_ROLLUP",
  MSP_ROLLUP: "MSP_ROLLUP",
  PREDICTION_BOUNDS: "PREDICTION_BOUNDS",
  SNOWFLAKE_DATABASE_USAGE_METER_HELPER:
    "SNOWFLAKE_DATABASE_USAGE_METER_HELPER",
  SNOWFLAKE_DATABASE_USAGE: "SNOWFLAKE_DATABASE_USAGE",
  SNOWFLAKE_WAREHOUSE_USAGE: "SNOWFLAKE_WAREHOUSE_USAGE",
  STORAGE_COST: "STORAGE_COST",
  STORAGE_USAGE: "STORAGE_USAGE",
} as const;

export type DataSource = (typeof DataSource)[keyof typeof DataSource];

export const DurationType = {
  CUSTOM: "CUSTOM",
  INVOICE: "INVOICE",
  LAST_MONTH: "LAST_MONTH",
  LAST_N_DAYS: "LAST_N_DAYS",
  LAST_N_MONTHS: "LAST_N_MONTHS",
  LAST_NINETY_DAYS: "LAST_NINETY_DAYS",
  LAST_SEVEN_DAYS: "LAST_SEVEN_DAYS",
  LAST_THIRTY_DAYS: "LAST_THIRTY_DAYS",
  MONTH_TO_DATE: "MONTH_TO_DATE",
  QUARTER_TO_DATE: "QUARTER_TO_DATE",
  TODAY: "TODAY",
  YEAR_TO_DATE: "YEAR_TO_DATE",
  YESTERDAY: "YESTERDAY",
} as const;

export type DurationType = (typeof DurationType)[keyof typeof DurationType];

export const MetricAggregate = {
  MAX: "MAX",
  MEAN: "MEAN",
  MIN: "MIN",
  SUM: "SUM",
} as const;

export type MetricAggregate =
  (typeof MetricAggregate)[keyof typeof MetricAggregate];

export const Operator = {
  CONTAINS: "CONTAINS",
  EQUALS: "EQUALS",
  GTE: "GTE",
  LTE: "LTE",
  NOT_CONTAINS: "NOT_CONTAINS",
  NOT_EQUALS: "NOT_EQUALS",
  NOT_SET: "NOT_SET",
  SET: "SET",
} as const;

export type Operator = (typeof Operator)[keyof typeof Operator];

export const ProviderType = {
  ALIBABA: "ALIBABA",
  AWS: "AWS",
  AZURE: "AZURE",
  FOCUS: "FOCUS",
  GCP: "GCP",
  KUBERNETES: "KUBERNETES",
  MONGO_DB: "MONGO_DB",
  OCI: "OCI",
  SNOWFLAKE: "SNOWFLAKE",
  TERNARY: "TERNARY",
} as const;

export type ProviderType = (typeof ProviderType)[keyof typeof ProviderType];

export const TimeGranularity = {
  DAY: "DAY",
  HOUR: "HOUR",
  MINUTE: "MINUTE",
  MONTH: "MONTH",
  QUARTER: "QUARTER",
  SECOND: "SECOND",
  WEEK: "WEEK",
  YEAR: "YEAR",
} as const;

export type TimeGranularity =
  (typeof TimeGranularity)[keyof typeof TimeGranularity];

export const UnitType = {
  BINARY_BYTES: "BINARY_BYTES",
  BYTES: "BYTES",
  CORES: "CORES",
  CURRENCY: "CURRENCY",
  KILOGRAMS: "KILOGRAMS",
  PERCENTAGE: "PERCENTAGE",
  STANDARD: "STANDARD",
} as const;

export type UnitType = (typeof UnitType)[keyof typeof UnitType];
