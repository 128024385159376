import { useTheme } from "@emotion/react";
import { FormApi } from "@tanstack/react-form";
import { useQueryClient } from "@tanstack/react-query";
import { MspBillingStatementInvoiceTemplate } from "@ternary/api-lib/components/MspBillingStatementInvoiceTemplate";
import { MspResourceType } from "@ternary/api-lib/constants/enums";
import { MspBillingInfoEntity } from "@ternary/api-lib/core/types/MspBillingInfo";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import palette from "@ternary/api-lib/ui-lib/theme/palette";
import { isEmpty, isEqual } from "lodash";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useActivityTracker } from "../../../../context/ActivityTrackerProvider";
import useGetTenantByID from "../../../../hooks/useGetTenantByID";
import { useMspStore } from "../../../../lib/zustand";
import IconTimes from "../../../../ui-lib/icons/IconTimes";
import { AlertType, postAlert } from "../../../../utils/alerts";
import useGetMspTenantSettingsByTenantID from "../../hooks/useGetMspTenantSettingsByTenantID";
import _copyText from "../copyText";
import queryKeys from "../hooks/queryKeys";
import { useCreateBillingInfo } from "../hooks/useCreateBillingInfo";
import { useGetMspBillingInfoByTenantID } from "../hooks/useGetMspBillingInfoByTenantID";
import { useUpdateMspBillingInfo } from "../hooks/useUpdateBillingInfo";
import { FormData } from "../types";
import { MspBillingStatementInfoForm } from "./MspBillingStatementInfoForm";
import { useFeatureText } from "@/context/FeatureTextProvider";

export function MspBillingStatementTenantInfoContainer() {
  const activityTracker = useActivityTracker();
  const queryClient = useQueryClient();
  const theme = useTheme();

  const { tenantID = "" } = useParams();

  const { copyText } = useFeatureText(_copyText);

  //
  // State
  //

  const mspStore = useMspStore();

  const mspTenantID = mspStore.selectedParentTenantID as string;

  const [showTemplate, setShowTemplate] = useState(false);

  //
  // Queries
  //

  const { data: _companyTenant, isLoading: isLoadingCompanyTenant } =
    useGetTenantByID(mspTenantID);

  const { data: mspTenantSettings, isLoading: isLoadingMspTenantSettings } =
    useGetMspTenantSettingsByTenantID(mspTenantID);

  const companyTenant =
    _companyTenant && mspTenantSettings
      ? {
          ..._companyTenant,
          logoImageSquareURL: mspTenantSettings.logoImageSquareURL,
        }
      : undefined;

  const { data: customerTenant, isLoading: isLoadingCustomerTenant } =
    useGetTenantByID(tenantID);

  const { data: companyBillingInfo, isLoading: isLoadingCompanyBillingInfo } =
    useGetMspBillingInfoByTenantID(mspTenantID);

  const { data: customerBillingInfo, isLoading: isLoadingCustomerBillingInfo } =
    useGetMspBillingInfoByTenantID(tenantID);

  //
  // Mutations
  //

  const { isPending: isCreatingBillingInfo, mutate: createBillingInfo } =
    useCreateBillingInfo({
      onError: () => {
        queryClient.removeQueries({
          queryKey: queryKeys.billingInfo(tenantID),
        });

        postAlert({
          type: AlertType.ERROR,
          message: copyText.errorCreatingBillingInfoMessage,
        });
      },
      onMutate: async (params) => {
        await queryClient.cancelQueries({
          queryKey: queryKeys.billingInfo(tenantID),
        });

        queryClient.setQueryData(queryKeys.billingInfo(tenantID), params);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: queryKeys.billingInfo(tenantID),
        });

        postAlert({
          type: AlertType.SUCCESS,
          message: copyText.successCreatingBillingInfoMessage,
        });
      },
    });

  const { isPending: isUpdatingBillingInfo, mutate: updateBillingInfo } =
    useUpdateMspBillingInfo({
      onError: (_error, _params, context) => {
        queryClient.setQueryData(
          queryKeys.billingInfo(tenantID),
          context?.previousBillingInfo
        );

        postAlert({
          type: AlertType.ERROR,
          message: copyText.errorUpdatingBillingInfoMessage,
        });
      },
      onMutate: async (params) => {
        await queryClient.cancelQueries({
          queryKey: queryKeys.billingInfo(tenantID),
        });

        const previousBillingInfo = queryClient.getQueryData(
          queryKeys.billingInfo(tenantID)
        ) as MspBillingInfoEntity;

        const newBillingInfo = { ...previousBillingInfo, ...params };

        queryClient.setQueryData(
          queryKeys.billingInfo(tenantID),
          newBillingInfo
        );

        return { previousBillingInfo };
      },
      onSuccess: () => {
        postAlert({
          type: AlertType.SUCCESS,
          message: copyText.successUpdatingBillingInfoMessage,
        });
      },
    });

  //
  // Interaction Handlers
  //

  function handleSubmit({
    formApi,
    value,
  }: {
    formApi: FormApi<FormData>;
    value: FormData;
  }) {
    if (customerBillingInfo) {
      const defaultValues = formApi.options.defaultValues;

      if (!defaultValues) return;

      const changes = Object.keys(value).reduce((accum, key) => {
        if (isEqual(value[key], defaultValues[key])) return accum;

        return { ...accum, [key]: value[key] };
      }, {});

      activityTracker.captureAction(actions.CLICK_MSP_BILLING_INFO_UPDATE);

      updateBillingInfo({
        billingInfoID: customerBillingInfo.id,
        type: MspResourceType.MSP_CUSTOMER,
        ...changes,
      });
    } else {
      activityTracker.captureAction(actions.CLICK_MSP_BILLING_INFO_CREATE);

      createBillingInfo({
        tenantID: tenantID,
        addressLine1: value.addressLine1,
        ...(!isEmpty(value.addressLine2)
          ? { addressLine2: value.addressLine2 }
          : {}),
        city: value.city,
        ...(!isEmpty(value.customerDetails)
          ? { customerDetails: value.customerDetails }
          : {}),
        ...(!isEmpty(value.paymentInstructions)
          ? { paymentInstructions: value.paymentInstructions }
          : {}),
        ...(!isEmpty(value.paymentTerm)
          ? { paymentTerm: value.paymentTerm }
          : {}),
        state: value.state,
        type: MspResourceType.MSP_CUSTOMER,
        zipCode: value.zipCode,
      });
    }
  }

  //
  // Render
  //

  const defaultValues = customerBillingInfo
    ? {
        addressLine1: customerBillingInfo.addressLine1,
        addressLine2: customerBillingInfo.addressLine2 ?? "",
        city: customerBillingInfo.city,
        customerDetails: customerBillingInfo.customerDetails ?? "",
        paymentInstructions: customerBillingInfo.paymentInstructions ?? "",
        paymentTerm: customerBillingInfo.paymentTerm ?? "",
        state: customerBillingInfo.state,
        zipCode: customerBillingInfo.zipCode,
      }
    : {
        addressLine1: "",
        addressLine2: "",
        city: "",
        customerDetails: "",
        paymentInstructions: "",
        paymentTerm: "",
        state: "",
        zipCode: "",
      };

  function renderInvoiceTemplate() {
    return (
      <Box
        backgroundColor={palette.grey[90]}
        height="100vh"
        left={0}
        position="fixed"
        top={0}
        width="100vw"
        zIndex={theme.zIndex_1600}
      >
        <Flex
          direction="column"
          height="100%"
          padding={theme.space_lg}
          width="100%"
        >
          <Flex justifyContent="flex-end">
            <Button
              color={palette.grey[90]}
              iconStart={<IconTimes />}
              secondary
              onClick={() => setShowTemplate(false)}
            />
          </Flex>
          <Flex
            alignItems="center"
            flex={1}
            justifyContent="center"
            overflow="auto"
            position="relative"
            width="100%"
          >
            <MspBillingStatementInvoiceTemplate
              companyBillingInfo={companyBillingInfo}
              companyTenant={companyTenant}
              customerBillingInfo={customerBillingInfo}
              customerTenant={customerTenant}
            />
          </Flex>
        </Flex>
      </Box>
    );
  }

  const disabled =
    isLoadingCompanyBillingInfo ||
    isLoadingCompanyTenant ||
    isLoadingCustomerBillingInfo ||
    isLoadingCustomerTenant ||
    isLoadingMspTenantSettings;

  return (
    <Box position="relative" width="40%">
      {showTemplate && renderInvoiceTemplate()}
      <Text>{copyText.customerSettingsFormMessage}</Text>
      <Button
        disabled={disabled}
        marginVertical={theme.space_lg}
        size="small"
        onClick={() => setShowTemplate(true)}
      >
        {copyText.previewBillingStatementButtonLabel}
      </Button>
      <Text appearance="h3" marginBottom={theme.space_sm}>
        {copyText.customerSettingsFormTitle}
      </Text>
      {isLoadingCustomerBillingInfo ? (
        <EmptyPlaceholder loading />
      ) : (
        <MspBillingStatementInfoForm
          defaultValues={defaultValues}
          isProcessing={isCreatingBillingInfo || isUpdatingBillingInfo}
          mode={customerBillingInfo ? "update" : "create"}
          type="customer"
          onSubmit={handleSubmit}
        />
      )}
    </Box>
  );
}
