import Meter from "@/components/Meter";
import { useTheme } from "@emotion/react";
import {
  formatCurrency,
  formatPercentage,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import copyText from "../copyText";

interface Props {
  isAllAlerts?: boolean;
  isLoading: boolean;
  negativeDelta?: number;
  percentageAnomalous?: number;
  positiveDelta?: number;
  totalCostAlerts?: number;
}

export default function AlertFeedMeters(props: Props): JSX.Element {
  const theme = useTheme();

  return (
    <Flex
      alignItems="center"
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_2}
      height={200}
      justifyContent="space-between"
      marginVertical={theme.space_md}
      padding={theme.space_sm}
      paddingHorizontal={theme.space_jumbo}
    >
      <Meter
        footerValue={" "}
        header={copyText.eventStreamMeterTitleAnomalousSpend}
        loading={props.isLoading}
        value={
          <Flex direction="column" marginTop={theme.space_lg}>
            <Flex direction="column" marginBottom={theme.space_md}>
              <Text bold appearance="h4">
                {`${formatCurrency({
                  number: props.positiveDelta ?? 0,
                })}`}
              </Text>
              <Text
                color={theme.text_color_secondary}
                marginBottom={theme.space_xs}
                marginRight={theme.space_md}
              >
                {copyText.eventStreamMeterPositiveRange}
              </Text>
            </Flex>
            <Flex direction="column">
              <Text bold appearance="h4">
                {`${formatCurrency({
                  number: props.negativeDelta ?? 0,
                })}`}
              </Text>
              <Text
                color={theme.text_color_secondary}
                marginBottom={theme.space_xs}
                marginRight={theme.space_md}
              >
                {copyText.eventStreamMeterNegativeRange}
              </Text>
            </Flex>
          </Flex>
        }
        width="25%"
      />
      <Box
        backgroundColor={theme.secondary_color_background}
        height="100%"
        paddingLeft="2px"
      />
      <Meter
        footerValue={" "}
        header={
          props.isAllAlerts
            ? copyText.eventStreamMeterTitleAnomaliesTriggeredAll
            : copyText.eventStreamMeterTitleAnomaliesTriggeredOne
        }
        loading={props.isLoading}
        value={
          <Flex alignItems="baseline" direction="column">
            <Flex alignItems="flex-end" marginBottom={theme.space_md}>
              <Text bold fontSize={theme.h2_fontSize}>
                {props.totalCostAlerts ?? 0}
              </Text>
            </Flex>
          </Flex>
        }
        width="25%"
      />
      <Box
        backgroundColor={theme.secondary_color_background}
        height="100%"
        paddingLeft="2px"
      />
      <Meter
        footerValue={" "}
        header={copyText.eventStreamMeterTitlePercentage}
        loading={props.isLoading}
        value={
          <Flex alignItems="baseline" direction="column">
            <Flex alignItems="flex-end" marginBottom={theme.space_md}>
              <Text bold fontSize={theme.h2_fontSize}>
                {formatPercentage(props.percentageAnomalous ?? 0)}
              </Text>
            </Flex>
          </Flex>
        }
        width="25%"
      />
    </Flex>
  );
}
