import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { UseQueryOptions, UseQueryResult } from "src/lib/react-query";
import UError from "unilib-error";
import useBuildDataQuery from "../../../../api/analytics/utils/useDataQuery";
import { ANALYTICS_QUERY_GC_TIME } from "../../../../constants";
import { AzureVMComputeInstanceGroup, azureVMComputeMeasures } from "../types";

export interface Params {
  dateRange: Date[];
  dimensions: string[];
  queryFilters: QueryFilter[];
}

export default function useGetAzureVMComputeInstanceGroups(
  params: Params,
  options?: UseQueryOptions<AzureVMComputeInstanceGroup[], UError>
): UseQueryResult<AzureVMComputeInstanceGroup[], UError> {
  const client = useAnalyticsApiClient();

  const measures = [
    azureVMComputeMeasures.cost,
    azureVMComputeMeasures.cpuUtilizationAvg,
    azureVMComputeMeasures.cpuUtilizationMax,
    azureVMComputeMeasures.cpuUtilizationMin,
    azureVMComputeMeasures.diskReadBytes,
    azureVMComputeMeasures.diskWriteBytes,
    azureVMComputeMeasures.instanceCount,
    azureVMComputeMeasures.productMemoryBytes,
    azureVMComputeMeasures.productVCPU,
    azureVMComputeMeasures.ramAvailableBytesAvg,
    azureVMComputeMeasures.ramAvailableBytesMax,
    azureVMComputeMeasures.ramAvailableBytesMin,
    azureVMComputeMeasures.receivedBytes,
    azureVMComputeMeasures.sentBytes,
    azureVMComputeMeasures.usageAmount,
  ];

  const [tenantID, query] = useBuildDataQuery({
    dataSource: DataSource.AZURE_COMPUTE_VISIBILITY,
    dateRange: params.dateRange,
    dimensions: params.dimensions,
    preAggFilters: params.queryFilters,
    measures,
  });

  return useQuery({
    queryKey: ["azureComputeInstanceGroups", query],
    queryFn: async () => {
      const result = await client.loadData(tenantID, query);

      const instanceGroups = result.response.map((datum) => {
        return {
          // DIMENSIONS
          blockStorageClass: datum.blockStorageClass ?? "",
          blockStorageType: datum.blockStorageType ?? "",
          family: datum.family ?? "",
          instanceType: datum.instanceType ?? "",
          location: datum.location ?? "",
          operatingSystem: datum.operatingSystem ?? "",
          projectId: datum.projectId ?? "",

          // MEASURES
          cost: datum.cost ?? 0,
          diskReadBytes: datum.diskReadBytes ?? 0,
          diskWriteBytes: datum.diskWriteBytes ?? 0,
          instanceCount: datum.instanceCount ?? 0,
          productMemoryBytes: datum.productMemoryBytes ?? 0,
          productVCPU: datum.productVCPU ?? 0,
          receivedBytes: datum.receivedBytes ?? 0,
          sentBytes: datum.sentBytes ?? 0,
          usageAmount: datum.usageAmount ?? 0,

          // UTIL
          cpuUtilizationAvg: datum.cpuUtilizationAvg ?? 0,
          cpuUtilizationMax: datum.cpuUtilizationMax ?? 0,
          cpuUtilizationMin: datum.cpuUtilizationMin ?? 0,
          ramAvailableBytesAvg: datum.ramAvailableBytesAvg ?? 0,
          ramAvailableBytesMax: datum.ramAvailableBytesMax ?? 0,
          ramAvailableBytesMin: datum.ramAvailableBytesMin ?? 0,
        } as AzureVMComputeInstanceGroup;
      });

      return instanceGroups;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
