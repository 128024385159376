import Dropdown from "@/ui-lib/components/Dropdown";
import { createColumnHelper } from "@tanstack/react-table";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import { UserConfigStatus } from "@ternary/api-lib/constants/enums";
import Table, {
  ActionMenuButton,
} from "@ternary/api-lib/ui-lib/components/Table/Table";
import { keyBy } from "lodash";
import React, { useMemo } from "react";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import useRefFn from "../../../../hooks/useRefFn";
import copyText from "../../copyText";

export type ScopedView = {
  id: string;
  status: UserConfigStatus;
};

type User = {
  id: string;
  email: string;
};

export type UserGroupConfig = {
  id: string;
  createdAt: string;
  createdByUserID: string;
  groupName: string;
  scopedViews: ScopedView[];
  updatedAt: string | null;
  updatedByUserID: string | null;
};

export interface Props {
  isLoading: boolean;
  userGroupConfigs: UserGroupConfig[];
  users: User[];
  onInteraction: (interaction: UserGroupConfigList.Interaction) => void;
}

type TableData = {
  id: string;
  createdBy: string;
  groupName: string;
  numberOfScopedViews: number;
  timeLastModified: string;
};

const columnHelper = createColumnHelper<TableData>();

export function UserGroupConfigList(props: Props): JSX.Element {
  const gatekeeper = useGatekeeper();
  const usersKeyedByID = keyBy(props.users, "id");

  const onInteraction = useRefFn(props.onInteraction);

  const columns = useMemo(
    () => [
      columnHelper.accessor("groupName", {
        header: copyText.userTableHeader_name,
      }),
      columnHelper.accessor("numberOfScopedViews", {
        header: copyText.tableHeaderNumberOfScopedViews,
      }),
      columnHelper.accessor("createdBy", {
        header: copyText.tableHeaderCreatedBy,
      }),
      columnHelper.accessor("timeLastModified", {
        cell: ({ getValue }) =>
          formatDate(new Date(getValue() ?? 0), "MM/dd/yyyy"),
        header: copyText.tableHeaderTimeLastModified,
      }),
      columnHelper.display({
        id: "actionMenu",
        cell: ({ row }) => (
          <UserGroupConfigListDropdown
            userGroupConfigID={row.original.id}
            onInteraction={onInteraction}
          />
        ),
        header: "",
        meta: { align: "center" },
        size: 50,
      }),
    ],
    [onInteraction]
  );

  const data = useMemo(
    () =>
      props.userGroupConfigs.map((config) => ({
        id: config.id,
        createdBy: usersKeyedByID[config.createdByUserID]?.email,
        groupName: config.groupName,
        numberOfScopedViews: config.scopedViews.length,
        timeLastModified: config.updatedAt ?? config.createdAt,
      })),
    [props.userGroupConfigs, props.users]
  );

  return (
    <Table
      columns={columns}
      data={data}
      initialState={{ sorting: [{ id: "groupName", desc: false }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );

  interface ScopedViewListDropwdownProps {
    userGroupConfigID: string;
    onInteraction: (interaction: UserGroupConfigList.Interaction) => void;
  }

  function UserGroupConfigListDropdown(
    props: ScopedViewListDropwdownProps
  ): JSX.Element {
    const options = [
      {
        // ADD AUTH WHEN IMPLEMENTING UPDATE
        label: copyText.actionMenuItemEditUserGroupConfig,
        onClick: () =>
          props.onInteraction({
            type: UserGroupConfigList.INTERACTION_EDIT_BUTTON_CLICKED,
            userGroupConfigID: props.userGroupConfigID,
          }),
      },
      {
        disabled: !gatekeeper.canCreateUserGroupConfig,
        label: copyText.actionMenuItemMakeCopyUserGroupConfig,
        onClick: () =>
          props.onInteraction({
            type: UserGroupConfigList.INTERACTION_MAKE_COPY_BUTTON_CLICKED,
            userGroupConfigID: props.userGroupConfigID,
          }),
      },
      {
        disabled: !gatekeeper.canDeleteUserGroupConfig,
        label: copyText.actionMenuItemDeleteUserGroupConfig,
        onClick: () =>
          props.onInteraction({
            type: UserGroupConfigList.INTERACTION_DELETE_BUTTON_CLICKED,
            userGroupConfigID: props.userGroupConfigID,
          }),
      },
    ];

    return (
      <Dropdown options={options} placement="bottom-end">
        <ActionMenuButton />
      </Dropdown>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace UserGroupConfigList {
  export const INTERACTION_DELETE_BUTTON_CLICKED =
    "UserGroupConfigList.INTERACTION_DELETE_BUTTON_CLICKED";
  export const INTERACTION_EDIT_BUTTON_CLICKED =
    "UserGroupConfigList.INTERACTION_EDIT_BUTTON_CLICKED";
  export const INTERACTION_MAKE_COPY_BUTTON_CLICKED =
    "UserGroupConfigList.INTERACTION_MAKE_COPY_BUTTON_CLICKED";

  interface InteractionDeleteButtonClicked {
    type: typeof UserGroupConfigList.INTERACTION_DELETE_BUTTON_CLICKED;
    userGroupConfigID: string;
  }

  interface InteractionEditButtonClicked {
    type: typeof UserGroupConfigList.INTERACTION_EDIT_BUTTON_CLICKED;
    userGroupConfigID: string;
  }
  interface InteractionMakeCopyButtonClicked {
    type: typeof UserGroupConfigList.INTERACTION_MAKE_COPY_BUTTON_CLICKED;
    userGroupConfigID: string;
  }

  export type Interaction =
    | InteractionEditButtonClicked
    | InteractionDeleteButtonClicked
    | InteractionMakeCopyButtonClicked;
}

export default UserGroupConfigList;
