import Meter, { getChangePercent } from "@/components/Meter";
import { DateHelper } from "@/lib/dates";
import { useTheme } from "@emotion/react";
import { formatCurrency } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { getDate, getDaysInMonth } from "date-fns";
import prettyBytes from "pretty-bytes";
import React from "react";
import copyText from "../../copyText";

interface Props {
  isLoading: boolean;
  lastMonthSpend: number;
  lastMTDSpend: number;
  latestDBAllocated: number;
  latestDBUsage: number;
  thisMTDSpend: number;
}

export default function CloudSQLMeters(props: Props): JSX.Element {
  const theme = useTheme();
  const now = new DateHelper();

  const latestDBAllocated = prettyBytes(props.latestDBAllocated);
  const latestDBUsage = prettyBytes(props.latestDBUsage);

  const [usedBytesNumber, usedBytesUnit] = latestDBUsage.split(" ");
  const [allocatedBytesNumber, allocatedBytesUnit] =
    latestDBAllocated.split(" ");

  const mtdSpendComparison = getChangePercent({
    biggerIsBetter: false,
    value: props.thisMTDSpend,
    comparison: props.lastMTDSpend,
    theme,
  });

  const avgDailySpend = props.thisMTDSpend / getDate(now.date);
  const forecastedSpend = avgDailySpend * getDaysInMonth(now.date);

  const forecastedSpendComparison = getChangePercent({
    biggerIsBetter: false,
    comparison: props.lastMonthSpend,
    value: forecastedSpend,
    theme,
  });

  return (
    <Flex>
      <Meter
        header={copyText.cloudSQLMetersLastMonthTotalCostLabel}
        loading={props.isLoading}
        value={formatCurrency({ number: props.lastMonthSpend })}
        width="25%"
      />
      <Box
        borderRight={`1px solid ${theme.border_color}`}
        marginHorizontal={theme.space_lg}
      />
      <Meter
        footerColor={mtdSpendComparison.color}
        footerIcon={mtdSpendComparison.icon}
        footerTitle={copyText.cloudSQLMetersMTDSpendComparisonCaption}
        footerValue={mtdSpendComparison.textValue}
        header={copyText.cloudSQLMetersCurrentMonthTotalCostLabel}
        loading={props.isLoading}
        value={formatCurrency({ number: props.thisMTDSpend })}
        width="25%"
      />
      <Box
        borderRight={`1px solid ${theme.border_color}`}
        marginHorizontal={theme.space_lg}
      />
      <Meter
        footerColor={forecastedSpendComparison.color}
        footerIcon={forecastedSpendComparison.icon}
        footerTitle={copyText.cloudSQLMetersForecastedSpendComparisonCaption}
        footerValue={forecastedSpendComparison.textValue}
        header={copyText.cloudSQLMetersForecastedSpendLabel}
        loading={props.isLoading}
        value={formatCurrency({ number: forecastedSpend })}
        width="25%"
      />
      <Box
        borderRight={`1px solid ${theme.border_color}`}
        marginHorizontal={theme.space_lg}
      />
      <Meter
        footerValue={
          <Flex alignItems="flex-end" marginLeft={theme.space_md}>
            <Text
              color={theme.text_color_secondary}
              fontSize={theme.h4_fontSize}
              fontWeight={theme.h4_fontWeight}
            >
              {allocatedBytesNumber}
            </Text>
            <Text
              color={theme.text_color_secondary}
              fontSize={theme.h5_fontSize}
              marginLeft={theme.space_xs}
            >
              {`${copyText.cloudSQLMetersDBStorageAllocated}(${allocatedBytesUnit})`}
            </Text>
          </Flex>
        }
        header={copyText.cloudSQLMetersDBStorageUsageLabel}
        loading={props.isLoading}
        value={
          <Flex alignItems="flex-end">
            <Text bold fontSize={theme.h2_fontSize}>
              {usedBytesNumber}
            </Text>
            <Text
              bold
              fontSize={theme.h5_fontSize}
              marginLeft={theme.space_xs}
              marginBottom={theme.space_xs}
            >
              {`${copyText.cloudSQLMetersDBStorageUsed}(${usedBytesUnit})`}
            </Text>
          </Flex>
        }
        width="25%"
      />
    </Flex>
  );
}
