import { useTheme } from "@emotion/react";
import {
  formatCurrencyRounded,
  formatNumber,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import copyText from "../copyText";

interface Props {
  actualValue: number;
  label: string;
  lowerBound?: number;
  upperBound?: number;
}

export default function AnomalyChartTooltip(props: Props): JSX.Element | null {
  const theme = useTheme();
  if (props.label === undefined) {
    return null;
  }

  return (
    <Box
      backgroundColor={theme.tooltip_background_color}
      borderRadius={theme.borderRadius_1}
      padding={theme.space_xs}
    >
      <Flex
        alignItems="center"
        gap={theme.space_xs}
        justifyContent="space-between"
      >
        <Text
          bold
          color={theme.text_color_inverse}
          fontSize={theme.h5_fontSize}
        >
          {props.label}
        </Text>
        <Text color={theme.text_color_inverse} fontSize={theme.fontSize_ui}>
          {roundAndFormatNumber(props.actualValue)}
        </Text>
      </Flex>
      {props.lowerBound && props.upperBound && (
        <Flex direction="column" marginTop={theme.space_xs}>
          <Flex gap={theme.space_xs} justifyContent="space-between">
            <Text
              bold
              color={theme.text_color_inverse}
              fontSize={theme.fontSize_small}
            >
              {copyText.costAlertTableHeader_expectedRange}
            </Text>
            <Text
              color={theme.text_color_inverse}
              fontSize={theme.fontSize_small}
            >
              {roundAndFormatNumber(props.lowerBound)}
              {" - "}
              {roundAndFormatNumber(props.upperBound)}
            </Text>
          </Flex>
        </Flex>
      )}
    </Box>
  );
}

export function roundAndFormatNumber(n: number) {
  if (n < 1 && n !== 0) {
    return "< $" + formatNumber(1);
  }
  return formatCurrencyRounded({
    number: n,
  });
}
