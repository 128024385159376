export const azureStorageBBDimensions = {
  projectId: "projectId",
  accountId: "accountId",
  resourceId: "resourceId",
  storageAccount: "storageAccount",
  pricingModel: "pricingModel",
  skuDescription: "skuDescription",
} as const;

export type AzureStorageBBDimension =
  (typeof azureStorageBBDimensions)[keyof typeof azureStorageBBDimensions];

export const azureStorageBBMeasures = {
  cost: "cost",
  absoluteCredits: "absoluteCredits",
  usageAmount: "usageAmount",
  blobSizeBytes: "blobSizeBytes",
  containerCount: "containerCount",
  transactions: "transactions",
  bytesDownloaded: "bytesDownloaded",
  bytesUploaded: "bytesUploaded",
} as const;

export type AzureStorageBBMeasure =
  (typeof azureStorageBBMeasures)[keyof typeof azureStorageBBMeasures];

export type AzureStorageBBDatum = {
  absoluteCredits: number;
  accountId: string;
  blobSizeBytes: number;
  bytesDownloaded: number;
  bytesUploaded: number;
  containerCount: number;
  cost: number;
  pricingModel: string;
  projectId: string;
  resourceId: string;
  skuDescription: string;
  storageAccount: string;
  transactions: number;
  usageAmount: number;
};

export type AzureStorageBBTableFilters = {
  pricingModel: string | null;
  projectId: string | null;
  resourceId: string | null;
  skuDescription: string | null;
  storageAccount: string | null;
};
