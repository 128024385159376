import { useTheme } from "@emotion/react";
import { faEllipsisV, faWarning } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import {
  formatCurrency,
  formatNumberRounded,
  formatPercentage,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import { GcpCommitmentServiceType } from "@ternary/api-lib/constants/enums";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Text from "@ternary/web-ui-lib/components/Text";
import { groupBy, isNil } from "lodash";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import Dropdown from "../../../../ui-lib/components/Dropdown";
import copyText from "../../copyText";
import {
  CudInventoryDatum,
  CudInventoryFilters,
  CudInventoryInstanceDatum,
  GcpCommitmentStatusType,
} from "../types";
import { getReadableGcpCommitmentServiceTypeStrings } from "../utils";

type TableData = {
  amountSaved: number;
  expiredCudsCount: number;
  expiringCudsCount: number;
  family: string | null;
  hourlyCommittedAmount: number;
  region: string | null;
  reservedMemoryGiB: number;
  reservedSlots: number;
  reservedVCPU: number;
  service: GcpCommitmentServiceType | null;
  utilizationPercent: number;
};

interface Props {
  instanceData: CudInventoryInstanceDatum[];
  inventoryData: CudInventoryDatum[];
  isLoading: boolean;
  onInteraction: (interaction: CudInventoryTable.Interaction) => void;
}

export function CudInventoryTable(props: Props): JSX.Element {
  const theme = useTheme();
  const columnHelper = createColumnHelper<TableData>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("service", {
        cell: ({ getValue }) => {
          const value = getValue();
          if (value === null) return copyText.cudInventoryTableNotAvailable;
          return (
            <Text
              color={theme.primary_color_text}
              cursor="pointer"
              marginRight={theme.space_xs}
              onClick={() =>
                props.onInteraction({
                  type: CudInventoryTable.INTERACTION_FILTER_CLICKED,
                  filterKey: "service",
                  filterValue: value,
                })
              }
            >
              {getReadableGcpCommitmentServiceTypeStrings(value)}
            </Text>
          );
        },
        header: copyText.cudInventoryTableHeader_service,
        meta: { align: "left" },
        size: 125,
      }),
      columnHelper.accessor("family", {
        cell: ({ getValue }) => {
          const value = getValue();
          if (value === null) return copyText.cudInventoryTableNotAvailable;
          return (
            <Text
              color={theme.primary_color_text}
              cursor="pointer"
              marginRight={theme.space_xs}
              onClick={() => {
                if (value === null) return;
                props.onInteraction({
                  type: CudInventoryTable.INTERACTION_FILTER_CLICKED,
                  filterKey: "family",
                  filterValue: value,
                });
              }}
            >
              {value}
            </Text>
          );
        },
        header: copyText.cudInventoryTableHeader_family,
        meta: { align: "left" },
        size: 100,
      }),
      columnHelper.accessor("region", {
        cell: ({ getValue }) => {
          const value = getValue();
          if (value === null) return copyText.cudInventoryTableNotAvailable;
          return (
            <Text
              color={theme.primary_color_text}
              cursor="pointer"
              marginRight={theme.space_xs}
              onClick={() =>
                props.onInteraction({
                  type: CudInventoryTable.INTERACTION_FILTER_CLICKED,
                  filterKey: "region",
                  filterValue: value,
                })
              }
            >
              {value}
            </Text>
          );
        },
        header: copyText.cudInventoryTableHeader_region,
        meta: { align: "left" },
        size: 100,
      }),
      columnHelper.accessor("amountSaved", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.cudInventoryTableHeader_amountSaved,
        meta: { align: "right" },
      }),
      columnHelper.accessor("utilizationPercent", {
        cell: ({ getValue }) => <>{formatPercentage(getValue() / 100)}</>,
        header: copyText.cudInventoryTableHeader_utilizationPercent,
        meta: { align: "right" },
      }),
      columnHelper.accessor("hourlyCommittedAmount", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.cudInventoryTableHeader_hourlyCommittedAmount,
        meta: { align: "right" },
      }),
      columnHelper.accessor("reservedVCPU", {
        cell: ({ getValue }) => formatNumberRounded(getValue()),
        header: () => (
          <Tooltip content={copyText.cudInventoryTableReservedVCPUTooltip}>
            {copyText.cudInventoryTableHeader_reservedVCPU}
          </Tooltip>
        ),
        meta: { align: "right" },
      }),
      columnHelper.accessor("reservedMemoryGiB", {
        cell: ({ getValue }) => prettyBytes(getValue()),
        header: () => (
          <Tooltip content={copyText.cudInventoryTableReservedRAMTooltip}>
            {copyText.cudInventoryTableHeader_reservedMemoryGiB}
          </Tooltip>
        ),
        meta: { align: "right" },
      }),
      columnHelper.accessor("reservedSlots", {
        cell: ({ getValue }) => formatNumberRounded(getValue()),
        header: () => (
          <Tooltip content={copyText.cudInventoryTableReservedSlotsTooltip}>
            {copyText.cudInventoryTableHeader_reservedSlots}
          </Tooltip>
        ),
        meta: { align: "right" },
      }),
      columnHelper.display({
        id: "expiredCudsCount",
        cell: ({ row }) => {
          if (
            row.original.expiredCudsCount > 0 ||
            row.original.expiringCudsCount > 0
          ) {
            return (
              <Tooltip
                content={getWarningTooltip(
                  row.original.expiredCudsCount,
                  row.original.expiringCudsCount
                )}
              >
                <Icon color={theme.feedback_warn} icon={faWarning} />
              </Tooltip>
            );
          }
          return null;
        },
        meta: { align: "right" },
        size: 50,
      }),
      columnHelper.display({
        id: "showActionMenu",
        cell: ({ row }) => {
          const actionMenuItems = [
            {
              label: copyText.actionMenuItemViewCommitments,
              onClick: () => {
                props.onInteraction({
                  type: CudInventoryTable.INTERACTION_VIEW_INSTANCES_CLICKED,
                  selectedFamily: row.original.family,
                  selectedRegion: row.original.region,
                  selectedService: row.original.service,
                });
              },
            },
          ];
          return (
            <Dropdown options={actionMenuItems} placement="bottom-end">
              <Button
                iconStart={<Icon icon={faEllipsisV} />}
                secondary
                size="tiny"
              />
            </Dropdown>
          );
        },
        meta: { align: "center" },
        size: 50,
      }),
    ],
    [props.onInteraction, theme]
  );

  const data = useMemo(
    () => getTableDataFromEntrys(props.inventoryData, props.instanceData),
    [props.inventoryData, props.instanceData]
  );

  return (
    <Table
      columns={columns}
      data={data}
      initialState={{ sorting: [{ id: "amountSaved", desc: true }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

function getTableDataFromEntrys(
  data: CudInventoryDatum[],
  commitments: CudInventoryInstanceDatum[]
) {
  const commitmentsKeyedByFamilyRegionService = groupBy(
    commitments,
    (commitment) =>
      `${commitment.family}-${commitment.region}-${commitment.service}`
  );

  return data.map((node): TableData => {
    const nodeFamilyRegionService = `${node.family}-${node.region}-${node.service}`;

    const expiredCudsCount = getExpiredCudCount(
      commitmentsKeyedByFamilyRegionService[nodeFamilyRegionService]
    );

    const expiringCudsCount = getExpiringCudCount(
      commitmentsKeyedByFamilyRegionService[nodeFamilyRegionService]
    );

    return {
      amountSaved: node.amountSaved,
      expiredCudsCount,
      expiringCudsCount,
      family: node.family,
      hourlyCommittedAmount: node.hourlyCommittedAmount ?? 0,
      region: node.region,
      reservedMemoryGiB: node.reservedMemoryGiB ?? 0,
      reservedVCPU: node.reservedVCPU ?? 0,
      reservedSlots: node.reservedSlots ?? 0,
      service: node.service,
      utilizationPercent: node.utilizationPercent,
    };
  });
}

function getExpiredCudCount(commitments: CudInventoryInstanceDatum[]): number {
  return commitments?.reduce((accum: number, commitment) => {
    if (
      isNil(commitment.commitmentEndTimestamp) ||
      commitment.status !== GcpCommitmentStatusType.EXPIRED
    )
      return accum;

    const numberOfDaysBetweenNowAndExpiration =
      (new Date().getTime() -
        new Date(commitment.commitmentEndTimestamp).getTime()) /
      (24 * 60 * 60 * 1000);

    if (
      numberOfDaysBetweenNowAndExpiration < 30 &&
      numberOfDaysBetweenNowAndExpiration >= 0
    ) {
      return accum + 1;
    } else return accum;
  }, 0);
}

function getExpiringCudCount(commitments: CudInventoryInstanceDatum[]): number {
  return commitments?.reduce((accum: number, commitment) => {
    if (isNil(commitment.commitmentEndTimestamp)) return accum;

    const numberOfDaysBetweenNowAndEnd =
      (new Date(commitment.commitmentEndTimestamp).getTime() -
        new Date().getTime()) /
      (24 * 60 * 60 * 1000);

    if (
      numberOfDaysBetweenNowAndEnd <= 60 &&
      numberOfDaysBetweenNowAndEnd >= 0
    ) {
      return accum + 1;
    } else return accum;
  }, 0);
}

function getWarningTooltip(
  numberOfExpiredCuds: number,
  numberOfExpiringCuds: number
) {
  return (
    <Box>
      {numberOfExpiredCuds > 0 ? (
        <Box>
          {copyText.cudInventoryTableExpiredCommitmentsWarning.replace(
            "%NUMBER%",
            `${numberOfExpiredCuds}`
          )}
        </Box>
      ) : (
        copyText.cudInventoryTableExpiredCommitmentsWarning.replace(
          "%NUMBER%",
          `${numberOfExpiredCuds}`
        )
      )}
      {numberOfExpiringCuds > 0 ? (
        <Box>
          {copyText.cudInventoryTableExpiringCommitmentsWarning.replace(
            "%NUMBER%",
            `${numberOfExpiringCuds}`
          )}
        </Box>
      ) : null}
    </Box>
  );
}
CudInventoryTable.INTERACTION_FILTER_CLICKED =
  `CudInventoryTable.INTERACTION_FILTER_CLICKED` as const;

CudInventoryTable.INTERACTION_VIEW_INSTANCES_CLICKED =
  `CudInventoryTable.INTERACTION_VIEW_INSTANCES_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof CudInventoryTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof CudInventoryFilters;
  filterValue: string;
}

interface InteractionViewInstancesClicked {
  type: typeof CudInventoryTable.INTERACTION_VIEW_INSTANCES_CLICKED;
  selectedFamily: string | null;
  selectedRegion: string | null;
  selectedService: GcpCommitmentServiceType | null;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CudInventoryTable {
  export type Interaction =
    | InteractionFilterClicked
    | InteractionViewInstancesClicked;
}

export default CudInventoryTable;
