import { DateHelper } from "@/lib/dates";
import { createStructParam } from "@/lib/use-query-params";
import { DateRange } from "@/utils/dates";
import { useDebounce } from "@/utils/timers";
import { useTheme } from "@emotion/react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import {
  ChartType,
  DataSource,
  DurationType,
  Operator,
  TimeGranularity,
  UnitType,
} from "@ternary/api-lib/analytics/enums";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import AreaChart from "@ternary/api-lib/analytics/ui/AreaChart";
import useReferenceIfEqual from "@ternary/api-lib/analytics/ui/hooks/useReferenceIfEqual";
import StackedBarChart from "@ternary/api-lib/analytics/ui/StackedBarChart";
import { getDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { differenceInHours, endOfDay } from "date-fns";
import React, { useMemo, useState } from "react";
import {
  ArrayParam,
  DateParam,
  DecodedValueMap,
  JsonParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { z } from "zod";
import InsightsSelector from "../../../../components/InsightsSelector";
import useAvailableGlobalDate from "../../../../hooks/useAvailableGlobalDate";
import DateRangeControls from "../../../../ui-lib/components/DateRangeControls/DateRangeControls";
import Dropdown from "../../../../ui-lib/components/Dropdown";
import Grid from "../../../../ui-lib/components/Grid";
import copyText from "../../copyText";
import useGetDatabaseSpendSummaries from "../../hooks/useGetDatabaseSpendSummaries";
import useGetAWSDatabaseMemoryDBInstanceGroups from "../hooks/useGetAWSDatabaseMemoryDBInstanceGroups";
import {
  AWSDatabaseMemoryDBDatum,
  AWSDatabaseMemoryDBDimension,
  AWSDatabaseMemoryDBMeasure,
  AWSDatabaseMemoryDBTableFilters,
  awsDatabaseMemoryDBDimensions,
  awsDatabaseMemoryDBMeasures,
} from "../types";
import AWSDatabaseMemoryDBMeters from "./AWSDatabaseMemoryDBMeters";
import AWSDatabaseMemoryDBTable from "./AWSDatabaseMemoryDBTable";
import AWSDatabaseMemoryDBTableControls from "./AWSDatabaseMemoryDBTableControls";

const AWSDatabaseMemoryDBCostChartOption = {
  CLUSTER_ID: "CLUSTER_ID",
  INSTANCE_TYPE: "INSTANCE_TYPE",
  REGION: "REGION",
  USAGE_ACCOUNT_ID: "USAGE_ACCOUNT_ID",
  USAGE_TYPE: "USAGE_TYPE",
} as const;

type AWSDatabaseMemoryDBCostChartOption =
  (typeof AWSDatabaseMemoryDBCostChartOption)[keyof typeof AWSDatabaseMemoryDBCostChartOption];

const AWSDatabaseMemoryDBUsageChartOption = {
  NODE_USAGE: "NODE_USAGE",
  BACKUP_STORAGE_USAGE: "BACKUP_STORAGE_USAGE",
  DATA_WRITE_USAGE: "DATA_WRITE_USAGE",
} as const;

type AWSDatabaseMemoryDBUsageChartOption =
  (typeof AWSDatabaseMemoryDBUsageChartOption)[keyof typeof AWSDatabaseMemoryDBUsageChartOption];

type Interaction =
  | AWSDatabaseMemoryDBTable.Interaction
  | AWSDatabaseMemoryDBTableControls.Interaction;

type QueryParams = DecodedValueMap<typeof queryParamConfigMap>;
type SelectedGroup = z.infer<typeof selectedGroupStruct> | null;

type QueryParamState = {
  tableFilters: AWSDatabaseMemoryDBTableFilters;
  dateRange: DateRange;
  dateRangeGranularity: TimeGranularity;
  duration: DurationType;
  selectedCostChartOption: AWSDatabaseMemoryDBCostChartOption;
  selectedGroup: SelectedGroup;
  selectedUsageChartOption: AWSDatabaseMemoryDBUsageChartOption;
};

const tableFiltersDefault = {
  [awsDatabaseMemoryDBDimensions.dataWriteCost]: null,
  [awsDatabaseMemoryDBDimensions.payerAccountId]: null,
  [awsDatabaseMemoryDBDimensions.usageAccountId]: null,
  [awsDatabaseMemoryDBDimensions.clusterId]: null,
  [awsDatabaseMemoryDBDimensions.instanceType]: null,
  [awsDatabaseMemoryDBDimensions.instanceMemory]: null,
  [awsDatabaseMemoryDBDimensions.instanceVcpu]: null,
  [awsDatabaseMemoryDBDimensions.instanceNetworkPerformance]: null,
  [awsDatabaseMemoryDBDimensions.instanceFamily]: null,
  [awsDatabaseMemoryDBDimensions.nodeCost]: null,
  [awsDatabaseMemoryDBDimensions.region]: null,
};

const tableFiltersStruct = z.object({
  [awsDatabaseMemoryDBDimensions.dataWriteCost]: z.nullable(z.number()),
  [awsDatabaseMemoryDBDimensions.payerAccountId]: z.nullable(z.string()),
  [awsDatabaseMemoryDBDimensions.usageAccountId]: z.nullable(z.string()),
  [awsDatabaseMemoryDBDimensions.clusterId]: z.nullable(z.string()),
  [awsDatabaseMemoryDBDimensions.instanceType]: z.nullable(z.string()),
  [awsDatabaseMemoryDBDimensions.instanceMemory]: z.nullable(z.string()),
  [awsDatabaseMemoryDBDimensions.instanceVcpu]: z.nullable(z.string()),
  [awsDatabaseMemoryDBDimensions.instanceNetworkPerformance]: z.nullable(
    z.string()
  ),
  [awsDatabaseMemoryDBDimensions.instanceFamily]: z.nullable(z.string()),
  [awsDatabaseMemoryDBDimensions.nodeCost]: z.nullable(z.number()),
  [awsDatabaseMemoryDBDimensions.region]: z.nullable(z.string()),
});

const selectedGroupStruct = z.object({
  clusterId: z.nullable(z.string()),
  instanceType: z.nullable(z.string()),
  region: z.nullable(z.string()),
  usageAccountId: z.nullable(z.string()),
  usageType: z.nullable(z.string()),
});

const costChartOptionEnum = createEnumParam(
  Object.values(AWSDatabaseMemoryDBCostChartOption)
);
const durationEnum = createEnumParam(Object.values(DurationType));
const usageChartOptionEnum = createEnumParam(
  Object.values(AWSDatabaseMemoryDBUsageChartOption)
);

const queryParamConfigMap = {
  date_range_end: DateParam,
  date_range_start: DateParam,
  duration: withDefault(durationEnum, DurationType.LAST_THIRTY_DAYS),
  instance_table_sort: JsonParam,
  filters: createStructParam(tableFiltersStruct),
  selected_cost_chart_option: withDefault(
    costChartOptionEnum,
    AWSDatabaseMemoryDBCostChartOption.USAGE_TYPE
  ),
  selected_group: createStructParam(selectedGroupStruct),
  selected_usage_chart_option: withDefault(
    usageChartOptionEnum,
    AWSDatabaseMemoryDBUsageChartOption.NODE_USAGE
  ),
  selected_usage_chart_utilization: ArrayParam,
};

const defaultCostChartData = [];
const defaultTableData = [];
const defaultUsageChartData = [];

export default function AWSDatabaseMemoryDBContainer() {
  const theme = useTheme();
  const globalDate = useAvailableGlobalDate();

  //
  // STATE
  //

  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);

  const queryParamState = useReferenceIfEqual(getQueryParamState(queryParams));

  const [searchText, setSearchText] = useState("");

  const debouncedSearchText = useDebounce(searchText);

  //
  // QUERIES
  //

  const dateRange = globalDate.date ?? queryParamState.dateRange;

  const costDimensions = getCostDimensionsFromOption(
    queryParamState.selectedCostChartOption
  );

  const costMeasures = getCostMeasuresFromOption(
    queryParamState.selectedCostChartOption
  );

  const usageMeasures = getUsageMeasuresFromOption(
    queryParamState.selectedUsageChartOption
  );

  const usageDimensions = getUsageDimensionsFromOption(
    queryParamState.selectedUsageChartOption
  );

  const {
    data: costChartData = defaultCostChartData,
    isFetching: isLoadingCostChartData,
  } = useGetAWSDatabaseMemoryDBInstanceGroups({
    dateRange,
    dimensions: costDimensions,
    filters: [
      ...getCostFiltersFromOption(queryParamState.selectedCostChartOption),
      ...getChartQueryFilters(queryParamState.tableFilters),
    ],
    granularity: queryParamState.dateRangeGranularity,
    measures: costMeasures,
    nullString: copyText.awsDatabaseTableNull,
  });

  const spendSummaries = useGetDatabaseSpendSummaries({
    dataSource: DataSource.AWS_DATABASE_MEMORY_DB,
  });

  const [{ data: currentMTD }, { data: lastMonthFull }, { data: lastMTD }] =
    spendSummaries;

  const isLoadingSpendSummaries = spendSummaries.some(
    (summary) => summary.isFetching
  );

  const {
    data: usageChartData = defaultUsageChartData,
    isFetching: isLoadingUsageChartData,
  } = useGetAWSDatabaseMemoryDBInstanceGroups({
    dateRange,
    dimensions: usageDimensions,
    filters: [
      ...getUsageFiltersFromOption(queryParamState.selectedUsageChartOption),
      ...getChartQueryFilters(queryParamState.tableFilters),
    ],
    granularity: queryParamState.dateRangeGranularity,
    measures: usageMeasures,
    nullString: copyText.awsDatabaseTableNull,
  });

  const { data: tableData = defaultTableData, isFetching: isLoadingTableData } =
    useGetAWSDatabaseMemoryDBInstanceGroups({
      dateRange,
      dimensions: [
        awsDatabaseMemoryDBDimensions.usageAccountId,
        awsDatabaseMemoryDBDimensions.clusterId,
        awsDatabaseMemoryDBDimensions.region,
      ],
      measures: [
        awsDatabaseMemoryDBMeasures.cost,
        awsDatabaseMemoryDBMeasures.nodeCost,
        awsDatabaseMemoryDBMeasures.dataWriteCost,
        awsDatabaseMemoryDBMeasures.backupStorageCost,
        awsDatabaseMemoryDBMeasures.nodeUsage,
        awsDatabaseMemoryDBMeasures.dataWriteUsage,
        awsDatabaseMemoryDBMeasures.backupStorageUsage,
      ],
      filters: [
        {
          name: awsDatabaseMemoryDBDimensions.clusterId,
          operator: Operator.SET,
        },
      ],
    });

  //
  // MODIFIED QUERY DATA
  //

  const filteredTableData = useMemo(() => {
    return getFilteredMemoryDBData({
      data: tableData ?? [],
      tableFilters: queryParamState.tableFilters,
      searchText: debouncedSearchText,
    });
  }, [debouncedSearchText, queryParamState.tableFilters, tableData]);

  const csvData = useMemo(
    () => getCSVData(filteredTableData),
    [filteredTableData]
  );

  //
  // INTERACTIONS
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case AWSDatabaseMemoryDBTable.INTERACTION_FILTER_CLICKED: {
        const filters = { ...queryParamState.tableFilters };
        filters[interaction.filterKey] = interaction.filterValue;
        setQueryParams({ filters: filters });
        break;
      }
      case AWSDatabaseMemoryDBTableControls.INTERACTION_REMOVE_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.tableFilters };
        nextFilters[interaction.filterKey] = null;
        if (Object.values(nextFilters).every((value) => value === null)) {
          setQueryParams({ filters: null });
        } else {
          setQueryParams({ filters: nextFilters });
        }
        break;
      }
      case AWSDatabaseMemoryDBTableControls.INTERACTION_SEARCH_TEXT_UPDATED: {
        setSearchText(interaction.searchText);
        break;
      }
    }
  }

  const costChartReportSnapshot = {
    chartType: ChartType.STACKED_BAR,
    dateRange:
      queryParamState.duration === DurationType.CUSTOM || globalDate
        ? queryParamState.dateRange
        : null,
    dataSource: DataSource.AWS_DATABASE_MEMORY_DB,
    dimensions: costDimensions,
    durationType: queryParamState.duration,
    isFiscalMode: false,
    fiscalPeriodMap: null,
    granularity: queryParamState.dateRangeGranularity,
    measures: costMeasures,
    queryFilters: [
      {
        name: costDimensions[0],
        operator: Operator.NOT_EQUALS,
        values: ["null"],
      },
    ],
    name: copyText.awsDatabaseMemoryDBReportSnapshotNameCost,
    xAxisKey: "timestamp",
  };

  const usageChartReportSnapshot = {
    chartType: ChartType.AREA,
    dateRange:
      queryParamState.duration === DurationType.CUSTOM || globalDate
        ? queryParamState.dateRange
        : null,
    dataSource: DataSource.AWS_DATABASE_MEMORY_DB,
    dimensions: [],
    durationType: queryParamState.duration,
    isFiscalMode: false,
    fiscalPeriodMap: null,
    granularity: queryParamState.dateRangeGranularity,
    measures: usageMeasures,
    name: copyText.awsDatabaseMemoryDBReportSnapshotNameUsage,
    xAxisKey: "timestamp",
  };

  return (
    <Box width="100%" paddingTop={theme.space_md}>
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <AWSDatabaseMemoryDBMeters
          isLoading={isLoadingSpendSummaries}
          lastMonthSpend={lastMonthFull?.totalCost ?? 0}
          lastMTDSpend={lastMTD?.totalCost ?? 0}
          thisMTDSpend={currentMTD?.totalCost ?? 0}
        />
      </Box>
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        justifyContent="flex-end"
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <DateRangeControls
          dateRange={dateRange}
          durationType={queryParamState.duration}
          hiddenOptions={[
            DurationType.LAST_NINETY_DAYS,
            DurationType.QUARTER_TO_DATE,
            DurationType.YEAR_TO_DATE,
          ]}
          maxDate={new DateHelper().date}
          onChangeDateRange={(duration, newDateRange) => {
            setQueryParams({
              duration,
              ...(newDateRange && newDateRange[0] && newDateRange[1]
                ? {
                    date_range_start: newDateRange[0],
                    date_range_end: newDateRange[1],
                  }
                : {
                    date_range_start: null,
                    date_range_end: null,
                  }),
            });
          }}
        />
      </Flex>

      <Grid
        gridColumnGap={theme.space_lg}
        gridTemplateColumns={`repeat(2, calc(50% - (${theme.space_lg} / 2) ))`}
      >
        <InsightsSelector
          resourceName={copyText.awsComputeCostReportSnapshotName}
          reportSnapshot={costChartReportSnapshot}
        >
          <Flex
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            direction="column"
            height={500}
            padding={theme.space_md}
          >
            <Flex justifyContent="space-between" paddingBottom={theme.space_md}>
              <Text fontSize={theme.h3_fontSize}>
                {copyText.awsComputeOptimizationsChartTitleCost}
              </Text>

              <Dropdown
                options={costChartOptions.map((option) => ({
                  ...option,
                  onClick: () =>
                    setQueryParams({
                      selected_cost_chart_option: option.value,
                    }),
                }))}
                placement="bottom-end"
                selectedOption={
                  costChartOptions.find(
                    (option) =>
                      option.value === queryParamState.selectedCostChartOption
                  ) ?? costChartOptions[0]
                }
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  secondary
                  size="small"
                  width={140}
                >
                  {getOptionLabel(queryParamState.selectedCostChartOption)}
                </Button>
              </Dropdown>
            </Flex>

            <Box flex="1 0 0">
              <StackedBarChart
                data={costChartData}
                dimensions={costDimensions.map((dimension) => ({
                  name: dimension,
                  isDate: false,
                }))}
                isLoading={isLoadingCostChartData}
                maxGroupings={7}
                measures={costMeasures.map(getMeasureWithUnit)}
                showLegend
                showTooltip
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                xAxisKey="timestamp"
              />
            </Box>
          </Flex>
        </InsightsSelector>

        <InsightsSelector
          resourceName={copyText.awsComputeUsageReportSnapshotName}
          reportSnapshot={usageChartReportSnapshot}
        >
          <Flex
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            direction="column"
            height={500}
            padding={theme.space_md}
          >
            <Flex justifyContent="space-between" paddingBottom={theme.space_md}>
              <Text fontSize={theme.h3_fontSize}>
                {copyText.awsComputeOptimizationsChartTitleUsage}
              </Text>

              <Box flex="0 1 100%" />

              <Dropdown
                options={usageChartOptions.map((option) => ({
                  ...option,
                  onClick: () =>
                    setQueryParams({
                      selected_usage_chart_option: option.value,
                    }),
                }))}
                placement="bottom-end"
                selectedOption={
                  usageChartOptions.find(
                    (option) =>
                      option.value === queryParamState.selectedUsageChartOption
                  ) ?? usageChartOptions[0]
                }
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  secondary
                  size="small"
                  width={140}
                >
                  {getOptionLabel(queryParamState.selectedUsageChartOption)}
                </Button>
              </Dropdown>
            </Flex>

            <Box flex="1 0 0">
              <AreaChart
                data={usageChartData}
                dimensions={usageDimensions.map((dimension) => ({
                  name: dimension,
                  isDate: false,
                }))}
                hideTotal
                isLoading={isLoadingUsageChartData}
                measures={usageMeasures.map(getMeasureWithUnit)}
                readableKeys={readableMeasures}
                showLegend
                showTooltip
                stacked
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                xAxisKey="timestamp"
                yAxisLabel={getUsageYAxisLabel(
                  queryParamState.selectedUsageChartOption
                )}
              />
            </Box>
          </Flex>
        </InsightsSelector>
      </Grid>

      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginVertical={theme.space_lg}
        padding={theme.space_md}
      >
        <AWSDatabaseMemoryDBTableControls
          csvData={csvData}
          debouncedSearchText={debouncedSearchText}
          tableFilters={queryParamState.tableFilters}
          searchText={searchText}
          onInteraction={handleInteraction}
        />
      </Box>

      <Box width="100%" overflowX="auto">
        <AWSDatabaseMemoryDBTable
          data={filteredTableData}
          isLoading={isLoadingTableData}
          onInteraction={handleInteraction}
        />
      </Box>
    </Box>
  );
}

// COST OPTIONS
const costChartOptions = [
  AWSDatabaseMemoryDBCostChartOption.CLUSTER_ID,
  AWSDatabaseMemoryDBCostChartOption.INSTANCE_TYPE,
  AWSDatabaseMemoryDBCostChartOption.REGION,
  AWSDatabaseMemoryDBCostChartOption.USAGE_ACCOUNT_ID,
  AWSDatabaseMemoryDBCostChartOption.USAGE_TYPE,
].map((costOption) => ({
  label: getOptionLabel(costOption),
  value: costOption,
}));

function getCostDimensionsFromOption(
  option: AWSDatabaseMemoryDBCostChartOption
): AWSDatabaseMemoryDBDimension[] {
  switch (option) {
    case AWSDatabaseMemoryDBCostChartOption.CLUSTER_ID:
      return [awsDatabaseMemoryDBDimensions.clusterId];
    case AWSDatabaseMemoryDBCostChartOption.INSTANCE_TYPE:
      return [awsDatabaseMemoryDBDimensions.instanceType];
    case AWSDatabaseMemoryDBCostChartOption.REGION:
      return [awsDatabaseMemoryDBDimensions.region];
    case AWSDatabaseMemoryDBCostChartOption.USAGE_ACCOUNT_ID:
      return [awsDatabaseMemoryDBDimensions.usageAccountId];
    case AWSDatabaseMemoryDBCostChartOption.USAGE_TYPE:
      return [];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getCostFiltersFromOption(
  option: AWSDatabaseMemoryDBCostChartOption
): QueryFilter[] {
  switch (option) {
    case AWSDatabaseMemoryDBCostChartOption.CLUSTER_ID:
      return [
        {
          name: awsDatabaseMemoryDBDimensions.clusterId,
          operator: Operator.SET,
        },
      ];
    case AWSDatabaseMemoryDBCostChartOption.INSTANCE_TYPE:
      return [
        {
          name: awsDatabaseMemoryDBDimensions.instanceType,
          operator: Operator.SET,
        },
      ];
    case AWSDatabaseMemoryDBCostChartOption.REGION:
    case AWSDatabaseMemoryDBCostChartOption.USAGE_ACCOUNT_ID:
    case AWSDatabaseMemoryDBCostChartOption.USAGE_TYPE:
      return [];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getCostMeasuresFromOption(option: AWSDatabaseMemoryDBCostChartOption) {
  switch (option) {
    case AWSDatabaseMemoryDBCostChartOption.CLUSTER_ID:
      return [awsDatabaseMemoryDBMeasures.cost];
    case AWSDatabaseMemoryDBCostChartOption.INSTANCE_TYPE:
      return [awsDatabaseMemoryDBMeasures.cost];
    case AWSDatabaseMemoryDBCostChartOption.REGION:
      return [awsDatabaseMemoryDBMeasures.cost];
    case AWSDatabaseMemoryDBCostChartOption.USAGE_ACCOUNT_ID:
      return [awsDatabaseMemoryDBMeasures.cost];
    case AWSDatabaseMemoryDBCostChartOption.USAGE_TYPE:
      return [
        awsDatabaseMemoryDBMeasures.nodeCost,
        awsDatabaseMemoryDBMeasures.backupStorageCost,
        awsDatabaseMemoryDBDimensions.dataWriteCost,
      ];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

// USAGE OPTIONS
const usageChartOptions = [
  AWSDatabaseMemoryDBUsageChartOption.BACKUP_STORAGE_USAGE,
  AWSDatabaseMemoryDBUsageChartOption.DATA_WRITE_USAGE,
  AWSDatabaseMemoryDBUsageChartOption.NODE_USAGE,
].map((usageOption) => ({
  label: getOptionLabel(usageOption),
  value: usageOption,
}));

function getUsageFiltersFromOption(
  option: AWSDatabaseMemoryDBUsageChartOption
): QueryFilter[] {
  switch (option) {
    case AWSDatabaseMemoryDBUsageChartOption.BACKUP_STORAGE_USAGE: {
      return [];
    }
    case AWSDatabaseMemoryDBUsageChartOption.DATA_WRITE_USAGE: {
      return [
        {
          name: awsDatabaseMemoryDBDimensions.clusterId,
          operator: Operator.SET,
        },
      ];
    }
    case AWSDatabaseMemoryDBUsageChartOption.NODE_USAGE: {
      return [
        {
          name: awsDatabaseMemoryDBDimensions.clusterId,
          operator: Operator.SET,
        },
      ];
    }
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getUsageMeasuresFromOption(
  option: AWSDatabaseMemoryDBUsageChartOption
): AWSDatabaseMemoryDBMeasure[] {
  switch (option) {
    case AWSDatabaseMemoryDBUsageChartOption.BACKUP_STORAGE_USAGE: {
      return [awsDatabaseMemoryDBMeasures.backupStorageUsage];
    }
    case AWSDatabaseMemoryDBUsageChartOption.DATA_WRITE_USAGE: {
      return [awsDatabaseMemoryDBMeasures.dataWriteUsage];
    }
    case AWSDatabaseMemoryDBUsageChartOption.NODE_USAGE: {
      return [awsDatabaseMemoryDBMeasures.nodeUsage];
    }
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getUsageDimensionsFromOption(
  option: AWSDatabaseMemoryDBUsageChartOption
): AWSDatabaseMemoryDBDimension[] {
  switch (option) {
    case AWSDatabaseMemoryDBUsageChartOption.BACKUP_STORAGE_USAGE: {
      return [awsDatabaseMemoryDBDimensions.usageAccountId];
    }
    case AWSDatabaseMemoryDBUsageChartOption.DATA_WRITE_USAGE: {
      return [
        awsDatabaseMemoryDBDimensions.usageAccountId,
        awsDatabaseMemoryDBDimensions.clusterId,
      ];
    }
    case AWSDatabaseMemoryDBUsageChartOption.NODE_USAGE: {
      return [
        awsDatabaseMemoryDBDimensions.usageAccountId,
        awsDatabaseMemoryDBDimensions.clusterId,
      ];
    }
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getUsageYAxisLabel(
  option: AWSDatabaseMemoryDBUsageChartOption
): string {
  switch (option) {
    case AWSDatabaseMemoryDBUsageChartOption.BACKUP_STORAGE_USAGE:
    case AWSDatabaseMemoryDBUsageChartOption.DATA_WRITE_USAGE:
      return copyText.unitByteMonths;
    case AWSDatabaseMemoryDBUsageChartOption.NODE_USAGE:
      return copyText.unitNodeHours;
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

const measuresWithUnit: { name: string; unit: UnitType }[] = [
  {
    name: awsDatabaseMemoryDBMeasures.backupStorageCost,
    unit: UnitType.CURRENCY,
  },
  {
    name: awsDatabaseMemoryDBMeasures.backupStorageUsage,
    unit: UnitType.BYTES,
  },
  {
    name: awsDatabaseMemoryDBMeasures.cost,
    unit: UnitType.CURRENCY,
  },
  {
    name: awsDatabaseMemoryDBMeasures.dataWriteCost,
    unit: UnitType.CURRENCY,
  },
  {
    name: awsDatabaseMemoryDBMeasures.dataWriteUsage,
    unit: UnitType.BINARY_BYTES,
  },
  {
    name: awsDatabaseMemoryDBMeasures.nodeCost,
    unit: UnitType.CURRENCY,
  },
  {
    name: awsDatabaseMemoryDBMeasures.nodeUsage,
    unit: UnitType.STANDARD,
  },
];

function getMeasureWithUnit(measure: string) {
  return (
    [...measuresWithUnit].find((other) => other.name === measure) ?? {
      name: measure,
      unit: UnitType.STANDARD,
    }
  );
}

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

const csvAccessors = [
  awsDatabaseMemoryDBDimensions.clusterId,
  awsDatabaseMemoryDBDimensions.usageAccountId,
  awsDatabaseMemoryDBDimensions.region,
  awsDatabaseMemoryDBMeasures.nodeCost,
  awsDatabaseMemoryDBMeasures.dataWriteCost,
] as const;

function getCSVData(data: AWSDatabaseMemoryDBDatum[]): CSVData {
  if (!data.length) {
    return { headers: [], rows: [] };
  }

  const rows = data.map((datum) => ({
    clusterId: datum.clusterId,
    usageAccountId: datum.usageAccountId,
    instanceType: datum.instanceType,
    instanceMemory: datum.instanceMemory,
    instanceVcpu: datum.instanceVcpu,
    instanceFamily: datum.instanceFamily,
    region: datum.region,
    instanceNetworkPerformance: datum.instanceNetworkPerformance,
    nodeCost: datum.nodeCost,
    dataWriteCost: datum.dataWriteCost,
  }));

  const headers = csvAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessors
    const copyTextKey: keyof typeof copyText = `awsDatabaseMemoryDBTableHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}

type getFilteredMemoryDBDataParams = {
  data: AWSDatabaseMemoryDBDatum[];
  tableFilters: AWSDatabaseMemoryDBTableFilters;
  searchText: string | null;
};

function getFilteredMemoryDBData(
  params: getFilteredMemoryDBDataParams
): AWSDatabaseMemoryDBDatum[] {
  const searchText = (params.searchText ?? "").toLowerCase().trim();

  return params.data.filter((datum) => {
    if (!isDatumPassingFilters(datum, params.tableFilters)) {
      return false;
    }

    if (!isSearchTextInDatum(datum, searchText)) {
      return false;
    }

    return true;
  });
}

function getChartQueryFilters(tableFilters: AWSDatabaseMemoryDBTableFilters) {
  const queryFilters: QueryFilter[] = [];

  [
    awsDatabaseMemoryDBDimensions.clusterId,
    awsDatabaseMemoryDBDimensions.instanceFamily,
    awsDatabaseMemoryDBDimensions.instanceMemory,
    awsDatabaseMemoryDBDimensions.instanceNetworkPerformance,
    awsDatabaseMemoryDBDimensions.instanceType,
    awsDatabaseMemoryDBDimensions.instanceVcpu,
    awsDatabaseMemoryDBDimensions.payerAccountId,
    awsDatabaseMemoryDBDimensions.region,
    awsDatabaseMemoryDBDimensions.usageAccountId,
  ].forEach((key) => {
    const filterValue = tableFilters[key];

    if (filterValue === null) return;

    if (filterValue === "") {
      queryFilters.push({
        name: key,
        operator: Operator.NOT_SET,
      });

      return;
    }

    queryFilters.push({
      name: key,
      operator: Operator.EQUALS,
      values: [filterValue],
    });
  });

  return queryFilters;
}

function getOptionLabel(
  option:
    | AWSDatabaseMemoryDBCostChartOption
    | AWSDatabaseMemoryDBUsageChartOption
) {
  const copyTextKey: keyof typeof copyText = `awsDatabaseMemoryDBChartOptionLabel_${option}`;

  return copyText[copyTextKey];
}

function getQueryParamState(queryParams: QueryParams): QueryParamState {
  const dateRange =
    queryParams.date_range_start && queryParams.date_range_end
      ? [queryParams.date_range_start, queryParams.date_range_end]
      : getDateRangeFromDurationType(queryParams.duration);

  const dateRangeDurationInHours =
    dateRange[0] && dateRange[1]
      ? differenceInHours(endOfDay(dateRange[1]), dateRange[0])
      : 0;

  const isSmallDateRange =
    dateRangeDurationInHours > 0 && dateRangeDurationInHours <= 48;

  const dateRangeGranularity = isSmallDateRange
    ? TimeGranularity.HOUR
    : TimeGranularity.DAY;

  const selectedUsageChartUtilization: string[] = [];

  (queryParams.selected_usage_chart_utilization ?? []).forEach((value) => {
    if (!value) return;
    selectedUsageChartUtilization.push(value);
  });

  return {
    dateRange,
    dateRangeGranularity,
    duration: queryParams.duration,
    tableFilters: queryParams.filters ?? tableFiltersDefault,
    selectedCostChartOption: queryParams.selected_cost_chart_option,
    selectedGroup: queryParams.selected_group ?? null,
    selectedUsageChartOption: queryParams.selected_usage_chart_option,
  };
}

const readableMeasures = {
  productVCPUChart: "productVCPU",
  productMemoryBytesChart: "productMemoryBytes",
};

function isDatumPassingFilters(
  datum: AWSDatabaseMemoryDBDatum,
  filters: AWSDatabaseMemoryDBTableFilters
): boolean {
  const filterKeys = [
    awsDatabaseMemoryDBDimensions.clusterId,
    awsDatabaseMemoryDBDimensions.instanceFamily,
    awsDatabaseMemoryDBDimensions.instanceMemory,
    awsDatabaseMemoryDBDimensions.instanceNetworkPerformance,
    awsDatabaseMemoryDBDimensions.instanceType,
    awsDatabaseMemoryDBDimensions.instanceVcpu,
    awsDatabaseMemoryDBDimensions.payerAccountId,
    awsDatabaseMemoryDBDimensions.region,
    awsDatabaseMemoryDBDimensions.usageAccountId,
  ];

  for (const key of filterKeys) {
    const filterValue = filters[key];

    if (
      filterValue !== null &&
      datum[key].toLowerCase().trim() !== filterValue.toLowerCase().trim()
    ) {
      return false;
    }
  }

  return true;
}

function isSearchTextInDatum(
  datum: AWSDatabaseMemoryDBDatum,
  searchText: string
): boolean {
  if (searchText === "") return true;

  const values = [
    datum.clusterId,
    datum.instanceFamily,
    datum.instanceMemory,
    datum.instanceNetworkPerformance,
    datum.instanceType,
    datum.instanceVcpu,
    datum.payerAccountId,
    datum.region,
    datum.usageAccountId,
  ].map((value) => (value === "" ? "null" : value));

  return values.some((value) =>
    value.toLowerCase().trim().includes(searchText)
  );
}
