import useGatekeeper from "@/hooks/useGatekeeper";
import DateRangeControls from "@/ui-lib/components/DateRangeControls/DateRangeControls";
import Dropdown from "@/ui-lib/components/Dropdown";
import TextInput from "@/ui-lib/components/TextInput";
import getMergeState from "@/utils/getMergeState";
import { useDebounce } from "@/utils/timers";
import { useTheme } from "@emotion/react";
import {
  faChevronDown,
  faFileExport,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  DataSource,
  DurationType,
  Operator,
  TimeGranularity,
  UnitType,
} from "@ternary/api-lib/analytics/enums";
import { RawData } from "@ternary/api-lib/analytics/types";
import AreaChart from "@ternary/api-lib/analytics/ui/AreaChart";
import StackedBarChart from "@ternary/api-lib/analytics/ui/StackedBarChart";
import { Dimension } from "@ternary/api-lib/analytics/ui/types";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import { formatNumber } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import {
  CloudProviderType,
  RecommendationCategory,
} from "@ternary/api-lib/constants/enums";
import { FindRecommendationsQueryResult } from "@ternary/api-lib/core/queries/FindRecommendationsQuery";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import prettyBytes from "pretty-bytes";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import {
  DateParam,
  JsonParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import useGetRawData from "../../../../api/analytics/useGetRawData";
import useGetSpendSummaries from "../../../../api/analytics/useGetSpendSummaries";
import useGetRecommendationsByTenantID from "../../../../api/core/hooks/useGetRecommendationsByTenantID";
import useAuthenticatedUser from "../../../../hooks/useAuthenticatedUser";
import useAvailableGlobalDate from "../../../../hooks/useAvailableGlobalDate";
import Chip from "../../../../ui-lib/components/Chip";
import { storageCostDimensions, storageUsageMeasures } from "../../constants";
import copyText from "../../copyText";
import StorageResourceTable from "../components/GCPStorageResourceTable";
import StorageSubTable from "../components/GCPStorageSubTable";
import StorageUsageMeters from "../components/GCPStorageUsageMeters";
import useGetStorageCostSummary from "../hooks/useGetStorageCostSummary";
import useGetStorageUsageBuckets from "../hooks/useGetStorageUsageBuckets";
import useGetStorageUsageSummary from "../hooks/useGetStorageUsageSummary";
import {
  Bucket,
  StorageCostDatum,
  StorageResourceType,
  StorageTableData,
  StorageTableUsageDatum,
} from "../types";

type RecommendationEntity =
  FindRecommendationsQueryResult["recommendations"][number];

const ACCEPTED_RECOMENDATION = "accepted";
const DEBOUNCE_SEARCH_MS = 300;
const NEW_RECOMMENDATION = "new";
const SECONDS_IN_DAY = 86400;

type Interaction = StorageResourceTable.Interaction;

const StorageCostGrouping = {
  ALL_BUCKETS: "ALL_BUCKETS",
  PROJECT_ID: "projectId",
  STORAGE_CLASS: "storageClass",
  REGION: "region",
};

const costGroupingLabel = {
  [StorageCostGrouping.ALL_BUCKETS]: copyText.storageDimensionGroupingLabelAll,
  [StorageCostGrouping.PROJECT_ID]:
    copyText.storageDimensionGroupingLabelProjectID,
  [StorageCostGrouping.STORAGE_CLASS]:
    copyText.storageDimensionGroupingLabelStorageClass,
  [StorageCostGrouping.REGION]: copyText.storageDimensionGroupingLabelRegion,
};

const usageGroupingLabel = {
  [StorageResourceType.STORAGE]: copyText.storageUsageTypeLabelStorage,
  [StorageResourceType.DATA_TRANSFER]:
    copyText.storageUsageTypeLabelDataTransfer,
  [StorageResourceType.OPERATIONS]: copyText.storageUsageTypeLabelOperation,
};

type Recommendation = {
  applicable: boolean;
  estimateValue: number;
  snoozeUntil: string | null;
  state: string;
};

type StorageCSVData = {
  projectID: string;
  region: string;
  bucketCount: number;
  lowCarbon: boolean;
  storage: number;
  processing: number;
  network: number;
  ingress: number;
  egress: number;
  operations: number;
  recommendations: number;
};

interface State {
  searchText: string;
  selectedBuckets: Bucket[];
  showSubTable: boolean;
}

const initialState: State = {
  searchText: "",
  selectedBuckets: [],
  showSubTable: false,
};

const today = new Date();

export default function GCPStorageVisibilityContainer(): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const globalDate = useAvailableGlobalDate();
  const theme = useTheme();

  const costGroupingEnum = createEnumParam(Object.values(StorageCostGrouping));
  const durationEnum = createEnumParam(Object.values(DurationType));
  const resourceTypeEnum = createEnumParam(Object.values(StorageResourceType));

  const [searchParamState, setSearchParamState] = useQueryParams({
    cost_grouping: withDefault(
      costGroupingEnum,
      StorageCostGrouping.ALL_BUCKETS
    ),
    date_range_end: DateParam,
    date_range_start: DateParam,
    duration: withDefault(durationEnum, DurationType.LAST_THIRTY_DAYS),
    resource_type: withDefault(resourceTypeEnum, StorageResourceType.STORAGE),
    filters: withDefault(JsonParam, []),
  });

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  let dateRange: Date[] = [];

  const customDateRange =
    (searchParamState.duration === DurationType.CUSTOM ||
      searchParamState.duration === DurationType.INVOICE) &&
    searchParamState.date_range_start &&
    searchParamState.date_range_end
      ? [searchParamState.date_range_start, searchParamState.date_range_end]
      : null;

  if (!globalDate.date && customDateRange) {
    dateRange = customDateRange;
  } else if (globalDate.date) {
    dateRange = globalDate.date;
  } else {
    dateRange = getCubeDateRangeFromDurationType(searchParamState.duration);
  }

  //
  // Queries
  //

  const { data: totalBuckets, isLoading: isLoadingBuckets } =
    useGetStorageUsageBuckets();

  const { data: recommendations = [], isLoading: isLoadingRecommendations } =
    useGetRecommendationsByTenantID(authenticatedUser.tenant.fsDocID, {
      category: RecommendationCategory.STORAGE,
      cloudProviderType: CloudProviderType.GCP,
    });

  const recommendationStats = getRecommendationStats(recommendations);

  const spendSummaries = useGetSpendSummaries(
    {
      dataSource: DataSource.BILLING,
      queryFilters: [
        {
          name: "serviceDescription",
          operator: Operator.EQUALS,
          values: ["Cloud Storage"],
        },
      ],
    },
    {
      enabled: gatekeeper.canListRecommendations,
    }
  );

  const [
    { data: mtdSpendSummary },
    { data: lastMonthSpendSummary },
    { data: lastMTDSpendSummary },
  ] = spendSummaries;

  const isLoadingSpendSummaries = spendSummaries.some(
    (summary) => summary.isFetching
  );

  const selectedCostDimensions =
    searchParamState.cost_grouping === StorageCostGrouping.ALL_BUCKETS
      ? storageCostDimensions
      : [searchParamState.cost_grouping];

  const { data: costData = [], isFetching: isLoadingCostData } = useGetRawData({
    dataSource: DataSource.STORAGE_COST,
    dateRange,
    dimensions: selectedCostDimensions,
    granularity: TimeGranularity.DAY,
    measures: ["cost"],
  });

  const { data: usageData = [], isFetching: isLoadingUsageData } =
    useGetRawData({
      dataSource: DataSource.STORAGE_USAGE,
      dateRange,
      dimensions: getUsageDimensions(searchParamState.resource_type),
      granularity: TimeGranularity.HOUR,
      measures: storageUsageMeasures,
    });

  const { data: costTableData, isLoading: isLoadingTableCostData } =
    useGetStorageCostSummary({
      dateRange,
    });

  const { data: usageTableData, isLoading: isLoadingTableUsageData } =
    useGetStorageUsageSummary({
      dateRange,
    });

  //
  // Handlers
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case StorageResourceTable.INTERACTION_FILTERS_CHANGED: {
        const isFiltered = searchParamState.filters.some(
          (val) => val.value === interaction.filter.value
        );

        if (searchParamState.filters.length <= 2 && !isFiltered) {
          setSearchParamState({
            filters: [...searchParamState.filters, interaction.filter],
          });
        }
        return;
      }
      case StorageResourceTable.INTERACTION_SELECT_BUCKETS_CLICKED: {
        mergeState({
          selectedBuckets: interaction.selectedBuckets,
          showSubTable: true,
        });
        return;
      }
    }
  }

  function handleDelete(filter: string): void {
    const filtered = searchParamState.filters.filter(
      (item) => item.value !== filter
    );
    setSearchParamState({ filters: filtered });
  }

  //
  // Render
  //

  const costGroupingOptions = [
    StorageCostGrouping.ALL_BUCKETS,
    StorageCostGrouping.PROJECT_ID,
    StorageCostGrouping.STORAGE_CLASS,
    StorageCostGrouping.REGION,
  ].map((costGrouping) => ({
    label: costGroupingLabel[costGrouping],
    value: costGrouping,
    onClick: () => setSearchParamState({ cost_grouping: costGrouping }),
  }));

  const usageGroupingOptions = [
    StorageResourceType.STORAGE,
    StorageResourceType.DATA_TRANSFER,
    StorageResourceType.OPERATIONS,
  ].map((usageGrouping) => ({
    label: usageGroupingLabel[usageGrouping],
    value: usageGrouping,
    onClick: () => setSearchParamState({ resource_type: usageGrouping }),
  }));

  const defaultCostGroupingOption =
    costGroupingOptions.find(
      (option) => option.value === searchParamState.cost_grouping
    ) ?? costGroupingOptions[0];

  const defaultUsageGroupingOption =
    usageGroupingOptions.find(
      (option) => option.value === searchParamState.resource_type
    ) ?? usageGroupingOptions[0];

  const joinedTableData = useMemo(
    () =>
      getCostAndUsageTableData(costTableData, usageTableData, recommendations),
    [costTableData, usageTableData, recommendations]
  );

  const debouncedSearchText = useDebounce(state.searchText, DEBOUNCE_SEARCH_MS);

  const filteredTableData = useMemo(() => {
    let filteredData = joinedTableData;

    if (
      debouncedSearchText.length === 0 &&
      searchParamState.filters.length === 0
    ) {
      return filteredData;
    }

    if (debouncedSearchText.length > 0) {
      filteredData = filteredData.filter((entry) => {
        const str = debouncedSearchText.toLowerCase();

        const project = entry.projectID.toLowerCase();
        const region = entry.region.toLowerCase();

        return project.includes(str) || region.includes(str);
      });
    }
    const filters = searchParamState.filters;

    filters.forEach((filter) => {
      if (filter.type === "projectID") {
        filteredData = filteredData.filter((entry) => {
          return filters.some((filter) => filter.value === entry.projectID);
        });
      }
      if (filter.type === "region") {
        filteredData = filteredData.filter((entry) => {
          return filters.some((filter) => filter.value === entry.region);
        });
      }
      if (filter.type === "carbon") {
        filteredData = filteredData.filter((entry) => {
          return filters.some(
            (filter) => Boolean(filter.value) === entry.lowCarbon
          );
        });
      }
    });

    return filteredData;
  }, [debouncedSearchText, joinedTableData, searchParamState.filters]);

  const csvMainTableData = flattenDataToCSV(filteredTableData);

  const usageKeys = {
    totalReceivedBytes: copyText.storageResourceUsageKeyIngress,
    totalSentBytes: copyText.storageResourceUsageKeyEgress,
    totalStoredBytes: copyText.storageResourceUsageKeyStoredBytes,
    totalRequestCount: copyText.storageResourceUsageKeytotalRequestCount,
  };

  const filters = searchParamState.filters.map((filter) => {
    let filterText = filter.value;
    if (filter.type === "carbon") {
      filterText = filter.value
        ? copyText.carbonNeutralTooltipCaption
        : copyText.carbonNonNeutralTooltipCaption;
    }

    return {
      text: filterText,
      onClick: () => handleDelete(filter.value),
    };
  });

  return (
    <Box paddingTop={theme.space_md}>
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <StorageUsageMeters
          buckets={totalBuckets ?? 0}
          isLoadingBucketCount={isLoadingBuckets}
          isLoadingSpendSummaries={isLoadingSpendSummaries}
          isLoadingRecommendations={isLoadingRecommendations}
          lastMonthSpend={lastMonthSpendSummary?.grossCost ?? 0}
          lastMTDSpend={lastMTDSpendSummary?.grossCost ?? 0}
          mtdSpend={mtdSpendSummary?.grossCost ?? 0}
          recommendations={recommendationStats}
        />
      </Box>

      <Flex
        justifyContent="flex-end"
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <DateRangeControls
          dateRange={dateRange}
          durationType={searchParamState.duration}
          hiddenOptions={[
            DurationType.LAST_NINETY_DAYS,
            DurationType.QUARTER_TO_DATE,
            DurationType.YEAR_TO_DATE,
          ]}
          maxDate={today}
          onChangeDateRange={(durationType, dateRange) => {
            setSearchParamState({
              duration: durationType,
              date_range_start: dateRange?.[0] ?? null,
              date_range_end: dateRange?.[1] ?? null,
            });
          }}
        />
      </Flex>
      <Box>
        <Flex height={500} marginBottom={theme.space_lg}>
          <Box
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            flex={1}
            height="100%"
            marginRight={theme.space_lg}
            padding={theme.space_md}
            width="50%"
          >
            <Flex justifyContent="space-between">
              <Text fontSize={theme.h3_fontSize}>
                {copyText.storageCostAggregateChartTitle}
              </Text>

              <Dropdown
                defaultSelectedOption={defaultCostGroupingOption}
                options={costGroupingOptions}
                placement="bottom-end"
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  secondary
                  size="small"
                  width={140}
                >
                  {costGroupingLabel[searchParamState.cost_grouping]}
                </Button>
              </Dropdown>
            </Flex>
            <Box height={450} paddingVertical={theme.space_md}>
              <StackedBarChart
                data={convertCostDataStorageClass(
                  costData,
                  searchParamState.cost_grouping
                )}
                disableDrilldown
                dimensions={getStackedBarChartStorageDimensions(
                  searchParamState.cost_grouping
                )}
                limit={10}
                isLoading={isLoadingCostData}
                measures={[{ name: "cost", unit: UnitType.CURRENCY }]}
                showTooltip
                timeSeriesGranularity={TimeGranularity.DAY}
                emptyPlaceholderText={copyText.storageCostEmptyPlaceholderText}
                xAxisKey="timestamp"
              />
            </Box>
          </Box>
          <Box
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            flex={1}
            height="100%"
            padding={theme.space_md}
            width="50%"
          >
            <Flex
              width="100%"
              justifyContent="space-between"
              marginBottom={theme.space_sm}
            >
              <Text fontSize={theme.h3_fontSize}>
                {copyText.storageUsageChartTitle}
              </Text>
              <Flex justifyContent="space-between">
                <Dropdown
                  defaultSelectedOption={defaultUsageGroupingOption}
                  options={usageGroupingOptions}
                  placement="bottom-end"
                >
                  <Button
                    iconEnd={<Icon icon={faChevronDown} />}
                    secondary
                    size="small"
                    width={140}
                  >
                    {usageGroupingLabel[searchParamState.resource_type]}
                  </Button>
                </Dropdown>
              </Flex>
            </Flex>
            <Box height={400} paddingVertical={theme.space_md}>
              <AreaChart
                data={convertUnits(usageData, searchParamState.resource_type)}
                disableDrilldown
                dimensions={getUsageDimensions(
                  searchParamState.resource_type
                ).map((dimension) => ({
                  name: dimension,
                }))}
                hideTotal
                isLoading={isLoadingUsageData}
                measures={getUsageMeasures(searchParamState.resource_type).map(
                  (measure) => {
                    if (
                      searchParamState.resource_type ===
                      StorageResourceType.OPERATIONS
                    ) {
                      return {
                        name: measure,
                        unit: UnitType.STANDARD,
                      };
                    } else {
                      return {
                        name: measure,
                        unit: UnitType.BYTES,
                      };
                    }
                  }
                )}
                limit={10}
                readableKeys={usageKeys}
                showLegend
                showTooltip
                stacked
                timeSeriesGranularity={TimeGranularity.DAY}
                xAxisKey="timestamp"
                yAxisFormatter={(value) =>
                  tickFormatter(value, searchParamState.resource_type)
                }
              />
            </Box>
          </Box>
        </Flex>
      </Box>

      <Box>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          backgroundColor={theme.panel_backgroundColor}
          borderRadius={theme.borderRadius_1}
          marginBottom={theme.space_lg}
          padding={theme.space_md}
        >
          <Flex alignItems="center">
            <Text fontSize={theme.h3_fontSize} marginRight={theme.space_md}>
              {copyText.storageUsageAboveTableBar}
            </Text>
            {searchParamState.filters.length > 0 ? (
              <Text marginRight={theme.space_xs}>
                {copyText.storageUsageAboveTableFilter}
              </Text>
            ) : null}
            {filters.map((filter) => (
              <Chip
                key={filter.text}
                text={filter.text}
                onClick={filter.onClick}
              />
            ))}
          </Flex>

          <Flex justifyContent="space-between" alignItems="center">
            <Box width={300}>
              <TextInput
                iconEnd={
                  <Icon color={theme.text_color_secondary} icon={faSearch} />
                }
                placeholder={copyText.searchInputPlaceholder}
                size="large"
                value={state.searchText}
                onChange={(e) =>
                  mergeState({ searchText: e.target.value.toLowerCase() })
                }
              />
            </Box>
            <CSVLink
              data={csvMainTableData}
              filename={`Storage-Usage-${formatDate(new Date(), "MM-dd-yyyy")}`}
            >
              <Button
                iconStart={<Icon color="inherit" icon={faFileExport} />}
                primary
                size="small"
                marginLeft={theme.space_md}
              >
                {copyText.exportButtonLabel}
              </Button>
            </CSVLink>
          </Flex>
        </Flex>
        <Box>
          {state.showSubTable && (
            <StorageSubTable
              buckets={state.selectedBuckets}
              date={dateRange}
              onClose={() => mergeState({ showSubTable: false })}
            />
          )}
        </Box>
        <Box>
          <StorageResourceTable
            isLoading={isLoadingTableCostData || isLoadingTableUsageData}
            resources={filteredTableData}
            onInteraction={handleInteraction}
          />
        </Box>
      </Box>
    </Box>
  );
}

function getRecommendationStats(recommendations: Recommendation[]) {
  const current = recommendations.filter(
    (rec) =>
      (rec.state === NEW_RECOMMENDATION ||
        rec.state === ACCEPTED_RECOMENDATION) &&
      rec.applicable
  );

  const value = current.reduce(
    (accum, entry) => accum + entry.estimateValue,
    0
  );

  return {
    count: current.length,
    value,
  };
}

function tickFormatter(value: unknown, type: string): string {
  if (typeof value !== "number" || !Number.isFinite(value)) {
    return "0";
  }

  if (type === StorageResourceType.OPERATIONS) {
    return formatNumber(value);
  }

  return prettyBytes(value);
}

function getCostAndUsageTableData(
  costTableData: StorageCostDatum[] | undefined,
  usageTableData: StorageTableUsageDatum[] | undefined,
  recommendations: RecommendationEntity[]
): StorageTableData[] {
  if (!usageTableData) usageTableData = [];
  if (!costTableData) costTableData = [];
  if (!recommendations) recommendations = [];

  const tableData = costTableData.reduce((accum, datum) => {
    if (!accum[`${datum.projectId}/${datum.region}`]) {
      //Data for main table
      const data: StorageTableData = {
        projectID: datum.projectId,
        region: datum.region,
        lowCarbon: datum.lowCarbon,
        storage: datum.costKind === "STORAGE" ? datum.cost : 0,
        processing: datum.costKind === "PROCESSING" ? datum.cost : 0,
        network: datum.costKind === "NETWORK" ? datum.cost : 0,
        other: datum.costKind === "OTHER" ? datum.cost : 0,
        ingress: 0,
        egress: 0,
        operations: 0,
        buckets: [],
        bucketCount: 0,
        recommendation: [],
      };

      const uniqueBucketsObj: { [key: string]: string[] } = {};

      //Data for sub table
      usageTableData?.forEach((usageDatum) => {
        const location =
          usageDatum.location === "eu" ? "europe" : usageDatum.location;

        if (
          usageDatum.projectId === data.projectID &&
          location === data.region
        ) {
          data.ingress += usageDatum.totalReceivedBytes;
          data.egress += usageDatum.totalSentBytes;
          data.operations += usageDatum.totalRequestCount;

          const usageItem: Bucket = {
            ...usageDatum,
            lifecycleRules:
              typeof usageDatum.lifecycleRules === "string"
                ? JSON.parse(usageDatum.lifecycleRules)
                : [],
            recommendationCount: 0,
          };

          recommendations.forEach((rec) => {
            if (
              rec.resource === usageDatum.bucketId &&
              (rec.state === NEW_RECOMMENDATION ||
                rec.state === ACCEPTED_RECOMENDATION) &&
              rec.applicable
            ) {
              usageItem["recommendationCount"] += 1;
              if (!data.recommendation.includes(rec.resource)) {
                data.recommendation.push(rec.resource);
              }
            }
          });

          if (
            !uniqueBucketsObj[usageItem.bucketId] &&
            usageItem.storageClass !== "null"
          ) {
            uniqueBucketsObj[usageItem.bucketId] = [usageItem.storageClass];
          }

          if (usageItem.storageClass !== "null") {
            data.buckets.push(usageItem);
          }
        }
      });

      data.bucketCount = Object.keys(uniqueBucketsObj).length;

      accum[`${datum.projectId}/${datum.region}`] = data;
    } else {
      accum[`${datum.projectId}/${datum.region}`][datum.costKind] += datum.cost;
    }

    return accum;
  }, {});

  const organizedTableData: StorageTableData[] = Object.values(tableData);

  return organizedTableData;
}

function convertUnits(arr, type: string) {
  if (type === "STORAGE") {
    const convertedBytes = arr.map((storage) => ({
      ...storage,
      totalStoredBytes: Math.floor(
        storage.totalStoredByteSeconds / SECONDS_IN_DAY
      ),
    }));

    return convertedBytes;
  }
  return arr;
}

function flattenDataToCSV(data: StorageTableData[]): StorageCSVData[] {
  if (!data.length) {
    return [];
  }

  return data.map((datum) => ({
    projectID: datum.projectID,
    region: datum.region,
    bucketCount: datum.bucketCount,
    lowCarbon: datum.lowCarbon,
    storage: datum.storage,
    processing: datum.processing,
    network: datum.network,
    ingress: datum.ingress,
    egress: datum.egress,
    operations: datum.operations,
    recommendations: datum.recommendation.length,
  }));
}

function getUsageDimensions(resourceType): string[] {
  switch (resourceType) {
    case StorageResourceType.STORAGE:
      return ["storageClass"];
    case StorageResourceType.DATA_TRANSFER:
      return ["location"];
    case StorageResourceType.OPERATIONS:
      return ["method"];
    default:
      return [];
  }
}

function getUsageMeasures(resourceType): string[] {
  switch (resourceType) {
    case StorageResourceType.STORAGE:
      return ["totalStoredBytes"];
    case StorageResourceType.DATA_TRANSFER:
      return ["totalSentBytes", "totalReceivedBytes"];
    case StorageResourceType.OPERATIONS:
      return ["totalRequestCount"];
    default:
      return [];
  }
}

function getStackedBarChartStorageDimensions(costGrouping) {
  if (costGrouping === StorageCostGrouping.ALL_BUCKETS) {
    const filteredDimensions = storageCostDimensions.reduce(
      (accum: Dimension[], item: string) => {
        if (item !== "costKind" && item !== "lowCarbon") {
          accum.push({ name: item });
        }
        return accum;
      },
      []
    );
    return filteredDimensions;
  }
  return [{ name: costGrouping }];
}

function convertCostDataStorageClass(
  costData: RawData[],
  costGrouping: string
): RawData[] {
  if (costGrouping !== "storageClass") {
    return costData;
  }

  return costData.map((cost) => {
    if (cost.storageClass === null) {
      return {
        ...cost,
        storageClass: copyText.storageAggregateCostLabel,
      };
    }
    return cost;
  });
}
