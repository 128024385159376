import styled from "@emotion/styled";
import { Theme } from "@ternary/web-ui-lib/theme/default";
import * as CSS from "csstype";
import React, {
  InputHTMLAttributes,
  RefObject,
  useEffect,
  useRef,
} from "react";

//
// Theme
//

const textInputTheme = (baseTheme: Theme) => ({
  TextInput_borderColor_danger_focus: baseTheme.feedback_negative_outline,
  TextInput_borderColor_danger_hover: baseTheme.feedback_negative_outline,
  TextInput_borderColor_danger: baseTheme.feedback_negative,
  TextInput_borderColor_success_focus: baseTheme.feedback_positive_outline,
  TextInput_borderColor_success_hover: baseTheme.feedback_positive_outline,
  TextInput_borderColor_success: baseTheme.feedback_positive,
  TextInput_borderColor: baseTheme.secondary_color_border,
  TextInput_icon_color_danger: baseTheme.feedback_negative,
  TextInput_icon_color_success: baseTheme.feedback_positive,
  ...baseTheme,
});

//
// Styles
//

export type Size = "small" | "medium" | "large";
type Variant = "danger" | "success";

interface RootProps {
  cursor?: Props["cursor"];
  disabled?: Props["disabled"];
  variant?: Props["variant"];
}

export const Root = styled("div")<RootProps>(({
  cursor,
  disabled,
  theme: baseTheme,
  variant,
}) => {
  const theme = textInputTheme(baseTheme);

  return {
    alignItems: "center",
    cursor: cursor ? cursor : disabled ? "default" : "text",
    display: "flex",
    fontSize: theme.fontSize_ui,
    position: "relative",
    width: "100%",
    zIndex: 0,

    '& [role="img"]': {
      ...(variant && { color: theme[`TextInput_icon_color_${variant}`] }),
      margin: `0 ${theme.space_sm}`,
    },

    "&:hover": {
      "& > div:last-child": {
        ...(!disabled && {
          borderColor: variant
            ? theme[`TextInput_borderColor_${variant}_hover`]
            : theme.primary_color_border,
        }),
      },
    },

    "&:focus": {
      "& > div:last-child": {
        ...(!disabled && { borderColor: "transparent" }),
        ...(!disabled && {
          boxShadow: variant
            ? `0 0 0 2px ${theme[`TextInput_borderColor_${variant}_focus`]}`
            : `0 0 0 2px ${theme.primary_color_focus}`,
        }),
      },
    },
  };
});

interface InputProps {
  cursor?: Props["cursor"];
  disabled?: Props["disabled"];
  readOnly?: Props["readOnly"];
  size?: Props["size"];
  variant?: Props["variant"];
}

function shouldForwardProp(
  prop: PropertyKey
): prop is Exclude<keyof JSX.IntrinsicElements["input"], "size"> {
  return prop !== "size";
}

export const Input = styled("input", {
  shouldForwardProp,
})<InputProps>(({
  cursor,
  disabled,
  readOnly,
  height,
  size,
  theme: baseTheme,
  variant,
}) => {
  const theme = textInputTheme(baseTheme);

  return {
    backgroundColor: "transparent",
    border: 0,
    boxShadow: "none",
    color: (() => {
      if (disabled) return theme.text_color_disabled;
      if (readOnly) return theme.text_color_secondary;
      return theme.text_color;
    })(),
    ...(cursor ? { cursor } : {}),
    fontSize: theme.fontSize_ui,
    height: (() => {
      if (size === "small") return "28px";
      if (size === "medium") return "32px";
      if (height) return height;
      return "40px";
    })(),
    paddingLeft: theme.space_xs,
    paddingRight: theme.space_xs,
    outline: 0,
    width: "100%",

    "&::placeholder": {
      color: theme.text_color_placeholder,
      userSelect: "none",
    },

    "&:focus": {
      "& ~ div:last-child": {
        borderColor: "transparent",
        boxShadow: variant
          ? `0 0 0 2px ${theme[`TextInput_borderColor_${variant}_focus`]}`
          : `0 0 0 2px ${theme.primary_color_focus}`,
      },

      "&:focus": {
        "& ~ div:last-child": {
          borderColor: "transparent",
          boxShadow: variant
            ? `0 0 0 2px ${theme[`TextInput_borderColor_${variant}_focus`]}`
            : `0 0 0 2px ${theme.primary_color_focus}`,
        },
      },
    },
  };
});

export const Prefix = styled("span")(({ theme }) => ({
  color: theme.text_color,
  marginLeft: theme.space_xs,
}));

export const Suffix = styled("span")(({ theme }) => ({
  color: theme.text_color,
  marginRight: theme.space_xs,
}));

interface UnderlayProps {
  disabled?: Props["disabled"];
  readOnly?: Props["readOnly"];
  variant?: Props["variant"];
}

export const Underlay = styled("div")<UnderlayProps>(({
  disabled,
  readOnly,
  theme: baseTheme,
  variant,
}) => {
  const theme = textInputTheme(baseTheme);

  return {
    backgroundColor:
      disabled || readOnly
        ? theme.background_color_disabled
        : theme.input_background_color,
    borderColor:
      variant && !disabled && !readOnly
        ? theme[`TextInput_borderColor_${variant}`]
        : theme.TextInput_borderColor,
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: theme.borderRadius_2,
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: -1,
  };
});

//
// Main
//

export interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "size"> {
  autoFocus?: boolean;
  cursor?: CSS.Property.Cursor;
  iconEnd?: JSX.Element;
  iconStart?: JSX.Element;
  inputRef?: RefObject<HTMLInputElement>;
  prefix?: string;
  size?: Size;
  suffix?: string;
  variant?: Variant;
}

export default function TextInput({
  autoFocus,
  cursor,
  disabled,
  iconEnd,
  iconStart,
  inputRef,
  onClick,
  prefix,
  readOnly,
  suffix,
  variant,
  ...restProps
}: Props): JSX.Element {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus) ref.current?.focus();
  }, []);

  const rootProps = { cursor, disabled, onClick, variant };
  const underlayProps = { disabled, readOnly, variant };

  const inputProps = {
    cursor,
    disabled,
    readOnly,
    ref: autoFocus ? ref : inputRef,
    variant,
    ...restProps,
  };

  return (
    <Root {...rootProps}>
      {iconStart && iconStart}
      {prefix && <Prefix {...restProps}>{prefix}</Prefix>}
      <Input {...inputProps} />
      {suffix && <Suffix {...restProps}>{suffix}</Suffix>}
      {iconEnd && iconEnd}
      <Underlay {...underlayProps} />
    </Root>
  );
}
