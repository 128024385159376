import { UnitType } from "../enums";

const measures = [
  { name: "cost", unit: UnitType.CURRENCY },
  { name: "absoluteCredits", unit: UnitType.CURRENCY },
  { name: "usageAmount", unit: UnitType.STANDARD },
  { name: "cpuPercentMin", unit: UnitType.PERCENTAGE },
  { name: "cpuPercentMax", unit: UnitType.PERCENTAGE },
  { name: "cpuPercentAvg", unit: UnitType.PERCENTAGE },
  { name: "memoryPercentMin", unit: UnitType.PERCENTAGE },
  { name: "memoryPercentMax", unit: UnitType.PERCENTAGE },
  { name: "memoryPercentAvg", unit: UnitType.PERCENTAGE },
  { name: "cores", unit: UnitType.CORES },
  { name: "memoryGB", unit: UnitType.BINARY_BYTES },
  { name: "storageCapacityGB", unit: UnitType.BINARY_BYTES },
  { name: "connections", unit: UnitType.STANDARD },
  { name: "storagePercent", unit: UnitType.PERCENTAGE },
  { name: "resourceCount", unit: UnitType.STANDARD },
];

const dimensions = [
  "projectId",
  "resourceId",
  "instanceName",
  "location",
  "skuName",
  "skuTier",
  "databaseEngine",
  "billingMethod",
  "computeTier",
  "backupStorageRedundancy",
  "backupRetentionPeriodDays",
  "highAvailabilityEnabled",
];

export const azureSqlVisibilitySchema = { dimensions, measures };
