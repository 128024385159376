import useGatekeeper from "@/hooks/useGatekeeper";
import LinearGradientBox from "@/ui-lib/components/LinearGradientBox";
import { useTheme } from "@emotion/react";
import {
  faEdit,
  faFileExport,
  faInfoCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import { CustomLabelOperator } from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import React from "react";
import { CSVLink } from "react-csv";
import Card from "../../../components/Card";
import Grid from "../../../ui-lib/components/Grid";
import copyText from "../copyText";
import { useCustomLabelsCsvData } from "../hooks/useCustomLabelsCsvData";

type CustomLabel = {
  id: string;
  createdAt: string;
  createdByEmail: string | null;
  matchers: {
    key: string;
    operator: CustomLabelOperator;
    values: string[];
  }[];
  outputs: {
    key: string;
    value: string;
  }[];
  updatedAt: string | null;
  updatedByEmail: string | null;
};

interface Props {
  label: CustomLabel;
  loading: boolean;
  onInteraction: (interaction: CustomLabelCard.Interaction) => void;
}

const MAX_LINES_IN_EXISTING_LABELS = 4;
export const CARD_HEIGHT = 275;

export function CustomLabelCard(props: Props): JSX.Element {
  const theme = useTheme();

  const gatekeeper = useGatekeeper();

  // The `matched` constant is used to extract the first key, as MFE does not support
  // use of multiple matched keys.
  const [matched] = props.label ? props.label.matchers : [];

  const csvData = useCustomLabelsCsvData([props.label]);
  const [firstOutput] = props.label ? props.label.outputs : [];
  const firstOutputString = firstOutput
    ? firstOutput.key + "-" + firstOutput.value
    : "custom_label";
  const csvFileName =
    firstOutputString + `-${format(new Date(), "MM-dd-yyyy")}`;

  const statusGrid = (
    <Grid
      gridTemplateColumns="repeat(4, minmax(100px, 1fr))"
      gridTemplateRows="1fr 1fr"
    >
      <Text color={theme.text_color_inverse}>
        {copyText.tableHeaderCreatedAt}
      </Text>
      <Text color={theme.text_color_inverse}>
        {copyText.tableHeaderCreatedBy}
      </Text>
      <Text color={theme.text_color_inverse}>
        {copyText.tableHeaderUpdatedAt}
      </Text>
      <Text color={theme.text_color_inverse}>
        {copyText.tableHeaderUpdatedBy}
      </Text>
      <Text color={theme.text_color_inverse}>
        {isEmpty(props.label.createdAt)
          ? "--"
          : formatDate(new Date(props.label.createdAt), "MM-dd-yyyy")}
      </Text>
      <Text color={theme.text_color_inverse}>
        {props.label.createdByEmail ?? "--"}
      </Text>
      <Text color={theme.text_color_inverse}>
        {props.label.updatedAt
          ? formatDate(new Date(props.label.updatedAt), "MM-dd-yyyy")
          : "--"}
      </Text>
      <Text color={theme.text_color_inverse}>
        {props.label.updatedByEmail ?? "--"}
      </Text>
    </Grid>
  );

  return (
    <Card
      height={CARD_HEIGHT}
      overflowY="auto"
      padding={theme.space_md}
      paddingBottom={theme.space_sm}
      position="unset"
      width="100%"
    >
      <Flex height="100%" direction="column">
        <Flex justifyContent="space-between">
          <Box flex="1 0 0">
            {props.label.outputs.map((output) => (
              <Text
                key={props.label.id + output.value + output.key}
                fontSize={theme.fontSize_base}
                fontWeight={theme.h4_fontWeight}
              >
                {output.key}: {output.value}
              </Text>
            ))}
          </Box>
          <Tooltip content={statusGrid}>
            <Box padding={theme.space_xs}>
              <Icon color={theme.text_color_secondary} icon={faInfoCircle} />
            </Box>
          </Tooltip>
          {gatekeeper.canUpdateCustomLabel && (
            <Button
              iconStart={
                <Icon color={theme.text_color_secondary} icon={faEdit} />
              }
              size="small"
              onClick={() =>
                props.onInteraction({
                  type: CustomLabelCard.INTERACTION_EDIT_BUTTON_CLICKED,
                  customLabelID: props.label.id,
                })
              }
            />
          )}
        </Flex>

        <Flex
          overflowY="auto"
          direction="column"
          marginTop={theme.space_md}
          flex="0 1 100%"
        >
          <Text
            bold
            fontSize={theme.h6_fontSize}
            color={theme.text_color_secondary}
          >
            {copyText.customLabelCardExistingLabel}
          </Text>
          <Text bold fontSize={theme.h6_fontSize}>
            {matched.key}:
          </Text>
          <Box marginLeft={theme.space_md}>
            {matched.values
              .slice(0, MAX_LINES_IN_EXISTING_LABELS)
              .map((val: string) => {
                return <Text key={props.label.id + val}>{val}</Text>;
              })}
            {matched.values.length > MAX_LINES_IN_EXISTING_LABELS && (
              <Text fontSize={theme.h5_fontSize} key={"more"}>
                {matched.values.length - MAX_LINES_IN_EXISTING_LABELS}{" "}
                {copyText.more}
              </Text>
            )}
          </Box>
        </Flex>

        <Box
          height={0}
          overflowY="visible"
          transform={`translateY(-${theme.space_xl})`}
        >
          <LinearGradientBox
            colorFrom={theme.panel_backgroundColor}
            height={theme.space_xl}
            pointerEvents="none"
          />
        </Box>

        <Flex justifyContent="space-between">
          <CSVLink data={csvData} filename={csvFileName}>
            <Button
              size="tiny"
              marginRight={theme.space_md}
              iconStart={<Icon color="inherit" icon={faFileExport} />}
              secondary
            >
              {copyText.actionExport}
            </Button>
          </CSVLink>
          {gatekeeper.canDeleteCustomLabel && (
            <Button
              iconStart={
                <Icon color={theme.text_color_secondary} icon={faTrash} />
              }
              size="small"
              onClick={() =>
                props.onInteraction({
                  type: CustomLabelCard.INTERACTION_DELETE_BUTTON_CLICKED,
                  customLabelID: props.label.id,
                })
              }
            />
          )}
        </Flex>
      </Flex>
    </Card>
  );
}

CustomLabelCard.INTERACTION_DELETE_BUTTON_CLICKED =
  "INTERACTION_DELETE_BUTTON_CLICKED" as const;
CustomLabelCard.INTERACTION_EDIT_BUTTON_CLICKED =
  "INTERACTION_EDIT_BUTTON_CLICKED" as const;

export interface CustomLabelCardInteractionDeleteButtonClicked {
  type: typeof CustomLabelCard.INTERACTION_DELETE_BUTTON_CLICKED;
  customLabelID: string;
}

export interface CustomLabelCardInteractionEditButtonClicked {
  type: typeof CustomLabelCard.INTERACTION_EDIT_BUTTON_CLICKED;
  customLabelID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CustomLabelCard {
  export type Interaction =
    | CustomLabelCardInteractionDeleteButtonClicked
    | CustomLabelCardInteractionEditButtonClicked;
}

export default CustomLabelCard;
