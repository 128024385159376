import { NOT_SHOWN_KEY } from "../constants";
import { Dimension, GroupingLabelFormatterParams, Measure } from "../ui/types";

/**
 * Interface for top row objects
 */
interface TopRowObject {
  measure: string;
  dimensions: {
    key: string;
    value: string;
  }[];
}

/**
 * Extracts grouping information from a key name and formats it into a structured object
 *
 * @param keyName - The string key name to parse
 * @param allMeasures - Array of all available measures
 * @param allDimensions - Array of all available dimensions
 * @returns Formatted grouping information with dimensions, measure, and grouping type
 */
export function getGroupingFromKeyName(
  keyName: string,
  allMeasures: Measure[],
  allDimensions: Dimension[]
): GroupingLabelFormatterParams {
  if (keyName === NOT_SHOWN_KEY) {
    return {
      dimensions: [],
      isOtherGrouping: true,
      measure: NOT_SHOWN_KEY,
    };
  }

  if (allDimensions.length === 0) {
    return {
      dimensions: [],
      isOtherGrouping: false,
      measure: keyName,
    };
  }

  if (allDimensions.length === 1 && allMeasures.length === 1) {
    return {
      dimensions: [{ key: allDimensions[0].name, value: keyName }],
      isOtherGrouping: false,
      measure: allMeasures[0].name,
    };
  }

  if (allDimensions.length === 1 && allMeasures.length > 1) {
    return {
      dimensions: [{ key: allDimensions[0].name, value: keyName }],
      isOtherGrouping: false,
      measure: keyName.split(": ").slice(0, 1).join(""),
    };
  }

  const measure = keyName.split(": ").slice(0, 1).join("");
  const dimensionsString = keyName.split(": ").slice(1).join("");
  const dimensionsStrings = dimensionsString.split(" / ");
  const resultDimensions = dimensionsStrings.map((keyValueDimensionsString) => {
    const [key, value] = keyValueDimensionsString.split(":");
    return { key, value };
  });

  return {
    dimensions: resultDimensions,
    isOtherGrouping: false,
    measure,
  };
}

/**
 * Parses row string data into structured objects with measure and dimension information
 *
 * @param str - The string to parse, typically in format "measure: dimension1:value1 / dimension2:value2"
 * @param allDimensions - Array of all available dimensions
 * @param allMeasures - Array of all available measures
 * @returns A structured object containing the parsed measure and dimensions
 */
export function parseRowStrings(
  str: string,
  allDimensions: Dimension[],
  allMeasures: Measure[],
  delimiterMeasure = ":",
  delimiterDimension = "/"
): TopRowObject {
  // netCost: projectId:ternary-prod / skuId:2AE5-7980-8EDC
  const splitArr =
    allMeasures.length === 0 ? [str] : str.split(`${delimiterMeasure} `);

  // 1 measure & 1 dimension - minimal annotation
  if (allDimensions.length === 1 && allMeasures.length === 1) {
    return {
      dimensions: [{ key: allDimensions[0].name, value: splitArr[0] }],
      measure: allMeasures[0].name,
    };
  }

  // multiple measures w/ single dimension - capture the measure but not the dimension annotations
  const measure = splitArr[0] ?? "";
  const splitDimensions = splitArr[1]
    ? splitArr[1].split(` ${delimiterDimension} `)
    : [];

  if (allDimensions.length === 1 && allMeasures.length > 1) {
    const dimensions = splitDimensions.map((dimensionValue) => {
      return { key: allDimensions[0].name, value: dimensionValue };
    });

    return { dimensions, measure };
  }

  const dimensions = splitDimensions.map((dimensionPairString) => {
    const splitDimension = dimensionPairString.split(delimiterMeasure);
    return { key: splitDimension[0], value: splitDimension[1] };
  });

  return { dimensions, measure };
}
