import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource, TimeGranularity } from "@ternary/api-lib/analytics/enums";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import UError from "unilib-error";
import useBuildDataQuery from "../../../../api/analytics/utils/useDataQuery";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { AzureCommitmentUsageDatum, AzureCommittedUseMeasures } from "../types";

export interface Params {
  dateRange: Date[];
  queryFilters?: QueryFilter[];
  isTotals?: boolean;
}

export default function useGetAzureCommitmentUsage(
  params: Params,
  options?: UseQueryOptions<AzureCommitmentUsageDatum[], UError>
): UseQueryResult<AzureCommitmentUsageDatum[], UError> {
  const client = useAnalyticsApiClient();

  const [tenantID, query] = useBuildDataQuery({
    ...params,
    dataSource: DataSource.AZURE_COMMITMENT_CHART,
    granularity: TimeGranularity.DAY,
    measures: [
      AzureCommittedUseMeasures.onDemandUsageCost,
      AzureCommittedUseMeasures.onDemandUsageHours,
      AzureCommittedUseMeasures.riAmortizedCost,
      AzureCommittedUseMeasures.riUnusedCost,
      AzureCommittedUseMeasures.riUnusedHours,
      AzureCommittedUseMeasures.riUsageHours,
      AzureCommittedUseMeasures.spAmortizedCost,
      AzureCommittedUseMeasures.spUnusedCost,
      AzureCommittedUseMeasures.spUsageHours,
    ],
  });

  return useQuery({
    queryKey: ["azureCommitmentUsage", params],
    queryFn: async () => {
      const commitmentUsage = await client.loadData(tenantID, query);

      return commitmentUsage.response.map((datum) => {
        return {
          onDemandUsageCost: datum.onDemandUsageCost ?? 0,
          onDemandUsageHours: datum.onDemandUsageHours ?? 0,
          riAmortizedCost: datum.riAmortizedCost ?? 0,
          riUnusedCost: datum.riUnusedCost ?? 0,
          riUnusedHours: datum.riUnusedHours ?? 0,
          riUsageHours: datum.riUsageHours ?? 0,
          spAmortizedCost: datum.spAmortizedCost ?? 0,
          spUnusedCost: datum.spUnusedCost ?? 0,
          spUsageHours: datum.spUsageHours ?? 0,
          timestamp: datum.timestamp ?? "",
        } as AzureCommitmentUsageDatum;
      });
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
