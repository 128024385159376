import { useTheme } from "@emotion/react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { UnitType } from "@ternary/api-lib/analytics/enums";
import { AzureCommitmentLookbackPeriod } from "@ternary/api-lib/constants/enums";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { startOfDay, sub } from "date-fns";
import { fromZonedTime } from "date-fns-tz";
import { isEqual } from "lodash";
import React from "react";
import {
  DecodedValueMap,
  StringParam,
  createEnumParam,
  useQueryParams,
} from "use-query-params";
import { z } from "zod";
import { DateHelper } from "../../../../lib/dates";
import { createStructParam } from "../../../../lib/use-query-params";
import Dropdown from "../../../../ui-lib/components/Dropdown";

import copyText from "../../copyText";
import useGetAzureCommitmentInventory from "../hooks/useGetAzureCommitmentInventory";
import useGetAzureCommitmentInventoryTotals, {
  defaultAzureCommitmentInventoryTotals,
} from "../hooks/useGetAzureCommitmentInventoryTotals";
import useGetAzureCommitmentUsage from "../hooks/useGetAzureCommitmentUsage";
import {
  AzureCommitmentInventoryDatum,
  AzureCommitmentType,
  AzureCommitmentVisibilityChartMode,
  AzureCommittedUseDimenstions,
  AzureCommittedUseMeasures,
} from "../types";
import AzureCommittedUseVisibilityChart from "./AzureCommittedUseVisibilityChart";
import AzureCommittedUseVisibilityControls from "./AzureCommittedUseVisibilityControls";
import AzureCommittedUseVisibilityMeters from "./AzureCommittedUseVisibilityMeters";
import AzureCommitmentInventoryTable from "./AzureCommittedUseVisibilityTable";
import AzureCommittedUseVisibilityTableControls from "./AzureCommittedUseVisibilityTableControls";

type Interaction = AzureCommittedUseVisibilityControls.Interaction;

export type AzureCommittedUseVisibilityTableFilters = z.infer<
  typeof tableFiltersStruct
>;

const tableFiltersStruct = z.object({
  coverageType: z.nullable(z.string()),
  type: z.nullable(z.string()),
  commitmentId: z.nullable(z.string()),
  skuName: z.nullable(z.string()),
  term: z.nullable(z.string()),
  region: z.nullable(z.string()),
  commitmentName: z.nullable(z.string()),
});

const defaultTableFilters: AzureCommittedUseVisibilityTableFilters = {
  commitmentId: null,
  commitmentName: null,
  coverageType: null,
  region: null,
  skuName: null,
  term: null,
  type: null,
};

const queryParamConfigMap = {
  chart: createEnumParam(Object.values(AzureCommitmentVisibilityChartMode)),
  lookback_p: StringParam,
  table_filters: createStructParam(tableFiltersStruct),
  type: createEnumParam(Object.values(AzureCommitmentType)),
};

const chartModeOptions = [
  {
    label: copyText.azureChartModeOptionCost,
    value: AzureCommitmentVisibilityChartMode.Cost,
  },
  {
    label: copyText.azureChartModeOptionUsage,
    value: AzureCommitmentVisibilityChartMode.Usage,
  },
];

export default function AzureCommittedUseVisibilityContainer() {
  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);
  const queryParamState = getQueryParamState(queryParams);
  const dateRange = getDateRangeFromLookback(queryParamState.lookbackPeriod);
  const theme = useTheme();
  const costChartMeasures = getCostChartMeasures(
    queryParamState.type,
    queryParamState.chartMode
  );

  const {
    data: commitmentInventoryTableData = [],
    isLoading: isLoadingCommitmentInventoryTableData,
  } = useGetAzureCommitmentInventory({ dateRange });

  const {
    data: commitmentInventoryTotals = defaultAzureCommitmentInventoryTotals,
    isLoading: isLoadingCommitmentInventoryTotals,
  } = useGetAzureCommitmentInventoryTotals({ dateRange });

  const {
    data: commitmentInventoryChartData = [],
    isLoading: isLoadingCommitmentInventoryChartData,
  } = useGetAzureCommitmentUsage({ dateRange });

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case AzureCommittedUseVisibilityControls.INTERACTION_COMMITMENT_TYPE_CLICKED: {
        setQueryParams({
          type:
            interaction.commitmentType === "All"
              ? null
              : interaction.commitmentType,
        });
        break;
      }
      case AzureCommittedUseVisibilityControls.INTERACTION_COVERAGE_TYPE_CLICKED: {
        setQueryParams({
          table_filters: {
            ...queryParamState.tableFilters,
            coverageType:
              interaction.coverageType === "All"
                ? null
                : interaction.coverageType,
          },
        });
        break;
      }
      case AzureCommittedUseVisibilityControls.INTERACTION_LOOKBACK_PERIOD_CLICKED: {
        setQueryParams({ lookback_p: interaction.lookbackPeriod });
        break;
      }
      default:
        break;
    }
  }

  function updateTableFilter(
    filterKey: keyof AzureCommittedUseVisibilityTableFilters,
    value: string | null
  ) {
    const nextFilter = { ...queryParamState.tableFilters, [filterKey]: value };

    setQueryParams({
      table_filters: isEqual(nextFilter, defaultTableFilters)
        ? null
        : nextFilter,
    });
  }

  const isLoading =
    isLoadingCommitmentInventoryChartData ||
    isLoadingCommitmentInventoryTableData ||
    isLoadingCommitmentInventoryTotals;

  const csvInventoryData = getCSVData(commitmentInventoryTableData);
  const filteredTableData = filterTableData(
    commitmentInventoryTableData,
    queryParamState.tableFilters,
    queryParamState.type
  );

  const selectedChartModeOption = chartModeOptions.find(
    (option) => option.value === queryParamState.chartMode
  );

  return (
    <Box>
      <Box marginBottom={theme.space_lg}>
        <AzureCommittedUseVisibilityControls
          commitmentType={queryParamState.type ?? "All"}
          isLoading={isLoading}
          coverageType={queryParamState.tableFilters.coverageType ?? "All"}
          coverageList={commitmentInventoryTableData}
          lookbackPeriod={queryParamState.lookbackPeriod}
          onInteraction={handleInteraction}
        />
      </Box>

      <Box marginBottom={theme.space_lg}>
        <AzureCommittedUseVisibilityMeters
          commitmentCount={commitmentInventoryTableData.length}
          coveragePercentage={0}
          inventoryTotals={commitmentInventoryTotals}
          isLoading={isLoading}
          lookback={queryParamState.lookbackPeriod}
        />
      </Box>

      <Flex
        height={500}
        marginBottom={theme.space_lg}
        direction="column"
        padding={theme.space_sm}
        borderRadius={theme.borderRadius_1}
        backgroundColor={theme.panel_backgroundColor}
        paddingBottom={theme.space_md}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          marginBottom={theme.space_sm}
        >
          <Text fontSize={theme.h3_fontSize}>
            {copyText.azureChartTitleCoverageDetails}
          </Text>

          <Dropdown
            options={chartModeOptions.map((option) => ({
              ...option,
              onClick: () =>
                setQueryParams({
                  chart:
                    option.value === AzureCommitmentVisibilityChartMode.Cost
                      ? null
                      : AzureCommitmentVisibilityChartMode.Usage,
                }),
            }))}
            placement="bottom-end"
            selectedOption={selectedChartModeOption}
          >
            <Button
              iconEnd={<Icon icon={faChevronDown} />}
              secondary
              size="small"
            >
              {selectedChartModeOption?.label}
            </Button>
          </Dropdown>
        </Flex>
        <Box flex="1 0 0" overflow="hidden">
          <AzureCommittedUseVisibilityChart
            data={commitmentInventoryChartData}
            isLoading={isLoading}
            measures={costChartMeasures}
            unit={
              queryParamState.chartMode ===
              AzureCommitmentVisibilityChartMode.Cost
                ? UnitType.CURRENCY
                : UnitType.STANDARD
            }
          />
        </Box>
      </Flex>

      <Box marginBottom={theme.space_lg}>
        <Box
          padding={theme.space_sm}
          borderRadius={theme.borderRadius_1}
          backgroundColor={theme.panel_backgroundColor}
          marginBottom={theme.space_md}
        >
          <AzureCommittedUseVisibilityTableControls
            csvData={csvInventoryData}
            filters={queryParamState.tableFilters}
            onRemoveFilter={(key) => updateTableFilter(key, null)}
          />
        </Box>

        <Box overflowX="auto">
          <AzureCommitmentInventoryTable
            inventoryData={filteredTableData}
            isLoading={isLoadingCommitmentInventoryTableData}
            lookback={queryParamState.lookbackPeriod}
            onClickFilter={updateTableFilter}
          />
        </Box>
      </Box>
    </Box>
  );
}

function filterTableData(
  data: AzureCommitmentInventoryDatum[],
  filters: AzureCommittedUseVisibilityTableFilters,
  type: AzureCommitmentType | null
) {
  return data.filter((datum) => {
    if (type !== null && datum.type !== type) {
      return false;
    }

    if (
      filters.commitmentId !== null &&
      datum.commitmentId !== filters.commitmentId
    ) {
      return false;
    }
    if (
      filters.commitmentName !== null &&
      datum.commitmentName !== filters.commitmentName
    ) {
      return false;
    }
    if (
      filters.coverageType !== null &&
      datum.coverageType !== filters.coverageType
    ) {
      return false;
    }
    if (filters.region !== null && datum.region !== filters.region) {
      return false;
    }
    if (filters.skuName !== null && datum.skuName !== filters.skuName) {
      return false;
    }
    if (filters.term !== null && datum.term !== filters.term) {
      return false;
    }
    if (filters.type !== null && datum.type !== filters.type) {
      return false;
    }
    if (filters.type !== null && datum.type !== filters.type) {
      return false;
    }

    return true;
  });
}

function getCostChartMeasures(
  commitmentType: AzureCommitmentType | null,
  mode: AzureCommitmentVisibilityChartMode
) {
  switch (commitmentType) {
    case AzureCommitmentType.Reservation:
      return mode === AzureCommitmentVisibilityChartMode.Usage
        ? [
            AzureCommittedUseMeasures.onDemandUsageHours,
            AzureCommittedUseMeasures.riUnusedHours,
            AzureCommittedUseMeasures.riUsageHours,
          ]
        : [
            AzureCommittedUseMeasures.onDemandUsageCost,
            AzureCommittedUseMeasures.riUnusedCost,
            AzureCommittedUseMeasures.riAmortizedCost,
          ];
    case AzureCommitmentType.SavingsPlan:
      return mode === AzureCommitmentVisibilityChartMode.Usage
        ? [
            AzureCommittedUseMeasures.onDemandUsageHours,
            AzureCommittedUseMeasures.spUsageHours,
          ]
        : [
            AzureCommittedUseMeasures.onDemandUsageCost,
            AzureCommittedUseMeasures.spUnusedCost,
            AzureCommittedUseMeasures.spAmortizedCost,
          ];
    case null:
      return mode === AzureCommitmentVisibilityChartMode.Usage
        ? [
            AzureCommittedUseMeasures.onDemandUsageHours,
            AzureCommittedUseMeasures.riUsageHours,
            AzureCommittedUseMeasures.spUsageHours,
            AzureCommittedUseMeasures.riUnusedHours,
          ]
        : [
            AzureCommittedUseMeasures.onDemandUsageCost,
            AzureCommittedUseMeasures.spUnusedCost,
            AzureCommittedUseMeasures.riUnusedCost,
            AzureCommittedUseMeasures.spAmortizedCost,
            AzureCommittedUseMeasures.riAmortizedCost,
          ];
    default:
      return [];
  }
}

function getDateRangeFromLookback(lookback: string) {
  const utcDate = fromZonedTime(startOfDay(new DateHelper().date), "UTC");
  const now = sub(utcDate, { days: 1 });

  switch (lookback) {
    case AzureCommitmentLookbackPeriod.SIXTY_DAYS:
      return [sub(now, { days: 60 }), now];
    case AzureCommitmentLookbackPeriod.THIRTY_DAYS:
      return [sub(now, { days: 30 }), now];
    case AzureCommitmentLookbackPeriod.FOURTEEN_DAYS:
      return [sub(now, { days: 14 }), now];
    case AzureCommitmentLookbackPeriod.SEVEN_DAYS:
      return [sub(now, { days: 7 }), now];
    default:
      return [sub(now, { days: 30 }), now];
  }
}

function getQueryParamState(
  params: DecodedValueMap<typeof queryParamConfigMap>
) {
  return {
    chartMode: params.chart ?? AzureCommitmentVisibilityChartMode.Cost,
    lookbackPeriod: stringToEnumValue(
      params.lookback_p,
      AzureCommitmentLookbackPeriod,
      AzureCommitmentLookbackPeriod.THIRTY_DAYS
    ),
    tableFilters: params.table_filters ?? defaultTableFilters,
    type: stringToEnumValue(params.type, AzureCommitmentType, null),
  };
}

function stringToEnumValue<
  const T extends Record<string, string>,
  const K = T[keyof T],
>(
  string: string | null | undefined,
  enumMap: T,
  defaultValue: K
): T[keyof T] | K {
  for (const key in enumMap) {
    const enumValue = enumMap[key];

    if (string === enumValue) {
      return enumValue;
    }
  }

  return defaultValue;
}

const csvAccessors = [
  AzureCommittedUseDimenstions.type,
  AzureCommittedUseDimenstions.commitmentName,
  AzureCommittedUseMeasures.totalCost,
  AzureCommittedUseMeasures.upfrontCost,
  AzureCommittedUseMeasures.avgUtilizationPercent,
  AzureCommittedUseMeasures.instanceCount,
  AzureCommittedUseDimenstions.skuName,
  AzureCommittedUseDimenstions.term,
  AzureCommittedUseDimenstions.region,
  AzureCommittedUseDimenstions.expirationDate,
  AzureCommittedUseDimenstions.purchaseDate,
  AzureCommittedUseDimenstions.commitmentId,
] as const;

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

function getCSVData(data: AzureCommitmentInventoryDatum[]): CSVData {
  if (!data.length) {
    return { headers: [], rows: [] };
  }

  const rows = data.map((datum) => ({
    avgUtilizationPercent: datum.avgUtilizationPercent,
    commitmentId: datum.commitmentId,
    commitmentName: datum.commitmentName,
    expirationDate: datum.expirationDate,
    instanceCount: datum.instanceCount,
    purchaseDate: datum.purchaseDate,
    region: datum.region,
    skuName: datum.skuName,
    term: datum.term,
    totalCost: datum.totalCost,
    type: datum.type,
    upfrontCost: datum.upfrontCost,
  }));

  const headers = csvAccessors.map((accessor) => {
    // ensure rows has a value for each accessor
    const dataKey: keyof (typeof rows)[number] = accessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `azureTableHeader_${accessor}`;
    const label = copyText[copyTextKey];

    return { key: dataKey, label };
  });

  return { headers, rows };
}
