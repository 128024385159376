import { SideDrawer } from "@/components/SideDrawer";
import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import { useConfig } from "@/context/ConfigProvider";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import useGatekeeper from "@/hooks/useGatekeeper";
import ConfirmationModal from "@/ui-lib/components/ConfirmationModal";
import Dropdown from "@/ui-lib/components/Dropdown";
import { AlertType, postAlert } from "@/utils/alerts";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { faChevronDown, faLock } from "@fortawesome/free-solid-svg-icons";
import {
  faCircleExclamation,
  faCloudPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import {
  CloudProviderType,
  SocialIntegrationType,
} from "@ternary/api-lib/constants/enums";
import { DataIntegrationEntity } from "@ternary/api-lib/core/types";
import { actions } from "@ternary/api-lib/telemetry";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { isNil, keyBy } from "lodash";
import React, { useEffect, useState } from "react";
import { StringParam, useQueryParams } from "use-query-params";
import paths from "../../../constants/paths";
import { useNavigateWithSearchParams } from "../../../lib/react-router";
import copyText from "../copyText";
import useDeleteDataIntegration from "../hooks/useDeleteDataIntegration";
import useDeleteJiraIntegration from "../hooks/useDeleteJiraIntegration";
import useDeleteSlackIntegration from "../hooks/useDeleteSlackIntegration";
import useGetDataIntegrationsByTenantID from "../hooks/useGetDataIntegrationsByTenantID";
import useGetJiraIntegrationByTenantID from "../hooks/useGetJiraIntegrationByTenantID";
import useGetSlackIntegrationByTenantID from "../hooks/useGetSlackIntegrationByTenantID";
import useValidateDataIntegration from "../hooks/useValidateDataIntegration";
import useDeleteMongoDbIntegration from "../integrations/hooks/useDeleteMongoDbIntegration";
import { getIntegrationsWithErrors } from "../utils";
import DataIntegrationDetailsModal from "./DataIntegrationDetailsModal";
import DataIntegrationsTable from "./DataIntegrationsTable";
import SocialIntegrationDetailsModal from "./SocialIntegrationDetailsModal";
import SocialIntegrationsTable from "./SocialIntegrationsTable";
import ValidationErrorList from "./ValidationErrorList";
import ValidationErrorListControls from "./ValidationErrorListControls";

const MODAL_DELETE = "DELETE";
const MODAL_DELETE_JIRA = "MODAL_DELETE_JIRA";
const MODAL_DELETE_SLACK = "MODAL_DELETE_SLACK";
const MODAL_DETAILS = "DETAILS";
const MODAL_SOCIAL = "SOCIAL";
const MODAL_VALIDATIONS = "VALIDATIONS";

type Interaction =
  | DataIntegrationsTable.Interaction
  | DataIntegrationDetailsModal.Interaction
  | SocialIntegrationsTable.Interaction
  | SocialIntegrationDetailsModal.Interaction
  | ValidationErrorListControls.Interaction
  | ValidationErrorList.Interaction;

interface State {
  modalKey: string;
  selectedIntegrationID: string | null;
  selectedSocialIntegrationType: SocialIntegrationType;
  validationErrorFilters: string[] | null;
}

const initialState: State = {
  modalKey: "",
  selectedIntegrationID: null,
  selectedSocialIntegrationType: SocialIntegrationType.JIRA,
  validationErrorFilters: null,
};

export default function DataIntegrationManagementContainer(): JSX.Element {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const config = useConfig();
  const queryClient = useQueryClient();
  const navigate = useNavigateWithSearchParams();
  const theme = useTheme();

  const [queryParamState, setQueryParamState] = useQueryParams({
    integration_id: StringParam,
  });

  //
  // State
  //

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const {
    data: _integrations = [],
    isLoading: isLoadingIntegrations,
    isSuccess: hasLoadedIntegrations,
    refetch: refetchIntegrations,
  } = useGetDataIntegrationsByTenantID(authenticatedUser.tenant.id, {
    enabled: gatekeeper.canListDataIntegrations,
    meta: { errorMessage: copyText.errorLoadingDataIntegrationsMessage },
  });

  const { data: jiraIntegration, isLoading: isLoadingJiraIntegration } =
    useGetJiraIntegrationByTenantID(authenticatedUser.tenant.fsDocID);

  const { data: slackIntegration, isLoading: isLoadingSlackIntegration } =
    useGetSlackIntegrationByTenantID(authenticatedUser.tenant.fsDocID);

  //
  // Mutations
  //

  const {
    isPending: isDeletingDataIntegration,
    mutate: deleteDataIntegration,
  } = useDeleteDataIntegration({
    onError: () => {
      mergeState({ modalKey: "" });
      postAlert({
        message: copyText.errorDeletingDataIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      mergeState({ modalKey: "", selectedIntegrationID: null });

      refetchIntegrations();

      postAlert({
        message: copyText.successDeletingDataIntegrationMessage,
        type: AlertType.SUCCESS,
      });
    },
  });
  const {
    isPending: isDeletingMongoDbIntegration,
    mutate: deleteMongoDbIntegration,
  } = useDeleteMongoDbIntegration({
    onError: () => {
      mergeState({ modalKey: "" });
      postAlert({
        message: copyText.errorDeletingDataIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      mergeState({ modalKey: "", selectedIntegrationID: null });

      refetchIntegrations();

      postAlert({
        message: copyText.successDeletingDataIntegrationMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  const {
    isPending: isDeletingJiraIntegration,
    mutate: deleteJiraIntegration,
  } = useDeleteJiraIntegration({
    onSuccess: () => {
      mergeState({ modalKey: "" });

      // We don't want to show loading state again so just set data to null
      queryClient.setQueryData(["integrations", "jira"], null);

      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successDeletingJiraIntegrationMessage,
      });
    },
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorDeletingJiraIntegrationMessage,
      });
    },
  });

  const {
    isPending: isDeletingSlackIntegration,
    mutate: deleteSlackIntegration,
  } = useDeleteSlackIntegration({
    onSuccess: () => {
      mergeState({ modalKey: "" });
      // We don't want to show loading state again so just set data to null
      queryClient.setQueryData(["integrations", "slack"], null);

      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successDeletingSlackIntegrationMessage,
      });
    },
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.slackErrorDeletingIntegration,
      });
    },
  });

  const {
    isPending: isValidatingIntegration,
    mutate: validateDataIntegration,
  } = useValidateDataIntegration({
    onError: () => {
      postAlert({
        message: copyText.errorValidatingDataIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSettled: () => {
      refetchIntegrations();
    },
  });

  //
  // Computed Values
  //

  const integrationsKeyedByID = keyBy(_integrations, "id");

  const selectedIntegration = state.selectedIntegrationID
    ? integrationsKeyedByID[state.selectedIntegrationID]
    : null;

  //
  // Side Effects
  //
  useEffect(() => {
    if (
      !queryParamState.integration_id ||
      isLoadingIntegrations ||
      isValidatingIntegration
    )
      return;

    mergeState({
      selectedIntegrationID: queryParamState.integration_id,
      modalKey: MODAL_DETAILS,
    });
    setQueryParamState({
      integration_id: null,
    });
  }, [hasLoadedIntegrations]);

  //
  // Interaction Handlers
  //

  function handleDeleteJiraIntegration() {
    if (!jiraIntegration) return;

    deleteJiraIntegration({ tenantID: authenticatedUser.tenant.fsDocID });
  }

  function handleDeleteSlackIntegration() {
    if (!slackIntegration) return;

    deleteSlackIntegration({ tenantID: authenticatedUser.tenant.fsDocID });
  }

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case DataIntegrationDetailsModal.INTERACTION_EDIT_BUTTON_CLICKED: {
        const integration = integrationsKeyedByID[interaction.integrationID];
        const path = getPathFromProviderType(interaction.providerType).replace(
          ":integrationID",
          integration.id
        );

        if (!integration || !path) return;

        // Flatten the shape of integration.
        const { config, ...restIntegration } = integration;

        navigate(path, {
          state: { integration: { ...restIntegration, ...config } },
        });
        return;
      }
      case DataIntegrationDetailsModal.INTERACTION_REFRESH_BUTTON_CLICKED: {
        mergeState({ modalKey: "", selectedIntegrationID: null });
        validateDataIntegration({ integrationID: interaction.integrationID });
        return;
      }
      case DataIntegrationDetailsModal.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          selectedIntegrationID: interaction.integrationID,
          modalKey: MODAL_DELETE,
        });
        return;
      }
      case DataIntegrationsTable.INTERACTION_DETAIL_BUTTON_CLICKED: {
        mergeState({
          selectedIntegrationID: interaction.integrationID,
          modalKey: MODAL_DETAILS,
        });
        return;
      }
      case SocialIntegrationsTable.INTERACTION_DETAIL_BUTTON_CLICKED: {
        mergeState({
          selectedSocialIntegrationType: interaction.providerType,
          modalKey: MODAL_SOCIAL,
        });
        return;
      }
      case SocialIntegrationsTable.INTERACTION_CREATE_INTEGRATION_BUTTON_CLICKED: {
        activityTracker.captureAction(actions.CLICK_ADMIN_CREATE_INTEGRATION, {
          type: SocialIntegrationType.SLACK,
        });

        const searchParams = new URLSearchParams({
          returnURL:
            window.location.origin +
            window.location.pathname +
            "?tab=integrations",
          tenantID: authenticatedUser.tenant.fsDocID,
        });

        const redirectURL = `${
          config.SLACK_API_BASE_URL
        }/connect?${searchParams.toString()}`;
        window.open(redirectURL, "_blank");
        return;
      }
      case SocialIntegrationsTable.INTERACTION_CONFIGURE_INTEGRATION_BUTTON_CLICKED: {
        navigate(paths._adminIntegrationsJira);
        return;
      }
      case SocialIntegrationDetailsModal.INTERACTION_UPDATE_INTEGRATION_BUTTON_CLICKED: {
        if (interaction.providerType === SocialIntegrationType.JIRA) {
          navigate(paths._adminIntegrationsJira, {
            state: { integration: jiraIntegration },
          });
        } else {
          navigate(paths._adminIntegrationsSlack, {
            state: { integration: slackIntegration },
          });
        }
        return;
      }
      case SocialIntegrationDetailsModal.INTERACTION_DELETE_BUTTON_CLICKED: {
        if (interaction.providerType === SocialIntegrationType.JIRA) {
          mergeState({
            modalKey: MODAL_DELETE_JIRA,
          });
        } else {
          mergeState({
            modalKey: MODAL_DELETE_SLACK,
          });
        }
        return;
      }
      case ValidationErrorList.INTERACTION_CONFIGURE_BUTTON_CLICKED: {
        const integration = integrationsKeyedByID[interaction.integrationID];
        const path = getPathFromProviderType(interaction.providerType).replace(
          ":integrationID",
          integration.id
        );

        if (!integration || !path) return;

        // Flatten the shape of integration.
        const { config, ...restIntegration } = integration;

        navigate(path, {
          state: { integration: { ...restIntegration, ...config } },
        });
        return;
      }
      case ValidationErrorListControls.INTERACTION_FILTER_PROVIDER_CLICKED: {
        if (!interaction.providerType) {
          mergeState({ validationErrorFilters: null });
        } else if (
          state.validationErrorFilters?.includes(interaction.providerType)
        ) {
          const updatedValidationFilters = state.validationErrorFilters.filter(
            (filter) => filter !== interaction.providerType
          );

          mergeState({
            validationErrorFilters: !updatedValidationFilters.length
              ? null
              : updatedValidationFilters,
          });
        } else {
          const filters = state.validationErrorFilters ?? [];
          mergeState({
            validationErrorFilters: [...filters, interaction.providerType],
          });
        }
      }
    }
  }

  function getPathFromProviderType(providerType: CloudProviderType) {
    switch (providerType) {
      case CloudProviderType.ALIBABA:
        return paths._adminIntegrationsAlibabaUpdate;
      case CloudProviderType.GCP:
        return paths._adminIntegrationsGcpUpdate;
      case CloudProviderType.AWS:
        return paths._adminIntegrationsAwsUpdate;
      case CloudProviderType.AZURE:
        return paths._adminIntegrationsAzureUpdate;
      case CloudProviderType.MONGO_DB:
        return paths._adminIntegrationsMongoDbUpdate;
      case CloudProviderType.OCI:
        return paths._adminIntegrationsOracleUpdate;
      case CloudProviderType.SNOWFLAKE:
        return paths._adminIntegrationsSnowflakeUpdate;
      default:
        return "";
    }
  }

  //
  // Render
  //

  if (!gatekeeper.canListDataIntegrations) {
    return (
      <Flex alignItems="center" justifyContent="center" minHeight="50vh">
        <EmptyPlaceholder
          icon={faLock}
          loading={false}
          text={copyText.emptyPlaceholderInsufficientPermission}
        />
      </Flex>
    );
  }

  const integrations = _integrations.sort((a, b) =>
    a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
  );

  const integrationsWithErrors = getIntegrationsWithErrors(integrations);

  const createIntegrationOptions = [
    {
      label: copyText.createAlibabaIntegrationButtonLabel,
      onClick: () => navigate(paths._adminIntegrationsAlibabaCreate),
    },
    {
      label: copyText.createAWSIntegrationButtonLabel,
      onClick: () => navigate(paths._adminIntegrationsAwsCreate),
    },
    {
      label: copyText.createAzureIntegrationButtonLabel,
      onClick: () => navigate(paths._adminIntegrationsAzureCreate),
    },
    {
      label: copyText.createGCPIntegrationButtonLabel,
      onClick: () => navigate(paths._adminIntegrationsGcpCreate),
    },
    {
      label: copyText.createMongoDbIntegrationButtonLabel,
      onClick: () => navigate(paths._adminIntegrationsMongoDbCreate),
    },
    {
      label: copyText.createOracleIntegrationButtonLabel,
      onClick: () => navigate(paths._adminIntegrationsOracleCreate),
    },
    {
      label: copyText.createSnowflakeButtonlabel,
      onClick: () => navigate(paths._adminIntegrationsSnowflakeCreate),
    },
  ];

  function renderModal(): JSX.Element | null {
    switch (state.modalKey) {
      case MODAL_DELETE: {
        if (!selectedIntegration) return null;
        return (
          <ConfirmationModal
            isLoading={
              isDeletingDataIntegration || isDeletingMongoDbIntegration
            }
            title={copyText.deleteDataIntegrationConfirmationTitle}
            message={copyText.deleteDataIntegrationConfirmationMessage}
            variant="danger"
            onConfirm={() =>
              selectedIntegration.providerType === CloudProviderType.MONGO_DB &&
              isNil(selectedIntegration.mspChildSharedPayerConfiguration)
                ? deleteMongoDbIntegration({
                    integrationID: selectedIntegration.id,
                  })
                : deleteDataIntegration({
                    integrationID: selectedIntegration.id,
                    providerType: selectedIntegration.providerType,
                  })
            }
            onCancel={() => mergeState({ modalKey: MODAL_DETAILS })}
          />
        );
      }
      case MODAL_DELETE_JIRA: {
        return (
          <ConfirmationModal
            isLoading={isDeletingJiraIntegration}
            message={copyText.deleteJiraIntegrationConfirmationMessage}
            title={copyText.deleteIntegrationConfirmationTitle}
            variant="danger"
            onCancel={() => mergeState({ modalKey: MODAL_SOCIAL })}
            onConfirm={handleDeleteJiraIntegration}
          />
        );
      }
      case MODAL_DELETE_SLACK: {
        return (
          <ConfirmationModal
            isLoading={isDeletingSlackIntegration}
            message={copyText.slackIntegrationDeleteConfirmation}
            title={copyText.deleteIntegrationConfirmationTitle}
            variant="danger"
            onCancel={() => mergeState({ modalKey: MODAL_SOCIAL })}
            onConfirm={handleDeleteSlackIntegration}
          />
        );
      }
      case MODAL_DETAILS: {
        return (
          <DataIntegrationDetailsModal
            integration={selectedIntegration as DataIntegrationEntity}
            onClose={() => mergeState({ modalKey: "" })}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_SOCIAL: {
        return (
          <SocialIntegrationDetailsModal
            jiraIntegration={jiraIntegration}
            slackIntegration={slackIntegration}
            providerType={state.selectedSocialIntegrationType}
            onClose={() => mergeState({ modalKey: "" })}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_VALIDATIONS: {
        return (
          <SideDrawer
            isOpen
            title={copyText.validationErrorsHeader}
            titleCaption={copyText.validationErrorsHelperText}
            onClose={() =>
              mergeState({ modalKey: "", validationErrorFilters: null })
            }
            content={() => (
              <>
                <ValidationErrorListControls
                  integrations={integrationsWithErrors}
                  validationErrorFilters={state.validationErrorFilters}
                  onInteraction={handleInteraction}
                />
                <ValidationErrorList
                  integrations={integrationsWithErrors}
                  validationErrorFilters={state.validationErrorFilters}
                  onClose={() => mergeState({ modalKey: "" })}
                  onInteraction={handleInteraction}
                />
              </>
            )}
          />
        );
      }
    }

    return null;
  }

  return (
    <Flex direction="column">
      {renderModal()}
      {isLoadingIntegrations ? (
        <EmptyPlaceholder loading />
      ) : (
        <Box>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            marginBottom={theme.space_md}
          >
            <Text
              fontSize={theme.h4_fontSize}
            >{`${copyText.dataIntegrationLabel} (${integrations.length})`}</Text>
            <Flex justifyContent="space-between">
              {integrationsWithErrors.length > 0 && (
                <Button
                  iconStart={
                    <Icon
                      icon={faCircleExclamation}
                      color={theme.feedback_negative}
                    />
                  }
                  marginRight={theme.space_sm}
                  onClick={() => mergeState({ modalKey: MODAL_VALIDATIONS })}
                >
                  {copyText.cloudValidationErrorButton}
                </Button>
              )}
              {integrations.length > 0 && (
                <Dropdown
                  options={createIntegrationOptions}
                  placement="bottom-end"
                >
                  <Button
                    iconEnd={<Icon icon={faChevronDown} />}
                    locked={!gatekeeper.canCreateDataIntegration}
                    primary
                  >
                    {copyText.cloudDropDownLabel}
                  </Button>
                </Dropdown>
              )}
            </Flex>
          </Flex>

          <Box marginTop={theme.space_md}>
            {integrations.length > 0 ? (
              <Flex flexWrap="wrap">
                <DataIntegrationsTable
                  data={integrations}
                  isLoading={isLoadingIntegrations || isValidatingIntegration}
                  onInteraction={handleInteraction}
                />
              </Flex>
            ) : (
              <Flex
                direction="column"
                alignItems="center"
                justifyContent="center"
                minHeight="55vh"
                backgroundColor={theme.elevated_background_color}
              >
                <Box
                  border={`2px solid ${theme.border_color}`}
                  padding={theme.space_xs}
                  borderRadius="8px"
                >
                  <Icon
                    size="3x"
                    color={theme.text_color_secondary}
                    icon={faCloudPlus}
                  />
                </Box>
                <Text appearance="h3" marginVertical={theme.space_md}>
                  {copyText.noDataIntegrationsMessage}
                </Text>
                <Dropdown
                  options={createIntegrationOptions}
                  placement="bottom-end"
                >
                  <Button
                    iconEnd={<Icon icon={faChevronDown} />}
                    locked={!gatekeeper.canCreateDataIntegration}
                    primary
                  >
                    {copyText.cloudDropDownLabel}
                  </Button>
                </Dropdown>
              </Flex>
            )}
          </Box>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            marginTop={theme.space_md}
          >
            <Text fontSize={theme.h4_fontSize}>
              {`${copyText.socialIntegrationLabel} (2)`}
            </Text>
          </Flex>
          <Box marginTop={theme.space_md}>
            <Flex flexWrap="wrap">
              <SocialIntegrationsTable
                jiraIntegration={jiraIntegration}
                slackIntegration={slackIntegration}
                isLoading={
                  isLoadingJiraIntegration || isLoadingSlackIntegration
                }
                onInteraction={handleInteraction}
              />
            </Flex>
          </Box>
        </Box>
      )}
    </Flex>
  );
}
