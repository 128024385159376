import { useTheme } from "@emotion/react";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import React from "react";
import { FormField } from "../../../../../ui-lib/components/Form";
import TextInput from "../../../../../ui-lib/components/TextInput";
import copyText from "../../copyText";
import { ALIBABA_ROLE_ARN_REGEX } from "./constants";
import { FormProps, RequiredInputValidation } from "./types";

const validators = {
  name: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
  roleARN: (props: RequiredInputValidation) => {
    if (props.value.length === 0)
      return !props.isMspSharedIntegration && copyText.errorInputFieldRequired;

    return !ALIBABA_ROLE_ARN_REGEX.test(props.value)
      ? copyText.errorInputFieldArnError
      : undefined;
  },
};

export function AlibabaIntegrationFormGeneral(props: FormProps) {
  const theme = useTheme();

  const { form, isMspSharedIntegration } = props;

  return (
    <Box padding={theme.space_xxs}>
      <form.Field
        name="name"
        validators={{ onChange: validators.name, onMount: validators.name }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              input={TextInput}
              disabled={isMspSharedIntegration}
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              label={copyText.alibabaFormReportNameLabel}
              required={isMspSharedIntegration ? undefined : true}
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <form.Field
        name="roleARN"
        validators={{
          onChange: (value) =>
            validators.roleARN({ ...value, isMspSharedIntegration }),
          onMount: (value) =>
            validators.roleARN({ ...value, isMspSharedIntegration }),
        }}
        children={(field) => {
          let shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          if (isMspSharedIntegration && field.state.value.length === 0) {
            shouldShowError = false;
          }

          return (
            <FormField
              input={TextInput}
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              label={copyText.alibabaFormRoleARNInputLabel}
              placeholder={copyText.alibabaFormGeneralRoleARNInputPlaceholder}
              required={isMspSharedIntegration ? undefined : true}
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
    </Box>
  );
}
