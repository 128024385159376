import { useTheme } from "@emotion/react";
import { faCircleInfo, faLeaf } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import {
  formatCurrency,
  formatNumber,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import copyText from "../../copyText";
import { Bucket, StorageTableData } from "../types";

type TableData = {
  buckets: Bucket[];
  bucketCount: number;
  egress: number;
  ingress: number;
  lowCarbon: boolean;
  network: number;
  operations: number;
  other: number;
  processing: number;
  projectId: string;
  recommendation: string[];
  region: string;
  storage: number;
};

interface Props {
  isLoading: boolean;
  resources: StorageTableData[];
  selectedResourceID?: string;
  onInteraction: (interaction: GCPStorageResourceTable.Interaction) => void;
}

export function GCPStorageResourceTable(props: Props): JSX.Element {
  const theme = useTheme();
  const columnHelper = createColumnHelper<TableData>();

  function formatSelectCategory(type: string, value: string) {
    return (
      <Text
        color={theme.primary_color_text}
        cursor="pointer"
        onClick={() =>
          props.onInteraction({
            type: GCPStorageResourceTable.INTERACTION_FILTERS_CHANGED,
            filter: {
              type,
              value,
            },
          })
        }
      >
        {value}
      </Text>
    );
  }

  function formatLowCarbon(isLowCarbon: boolean) {
    return isLowCarbon ? (
      <Tooltip
        width={"200px"}
        content={copyText.gcpStorageResourceTableLowCarbonToolTip}
      >
        <Icon
          clickable
          color={theme.feedback_positive}
          icon={faLeaf}
          onClick={() =>
            props.onInteraction({
              type: GCPStorageResourceTable.INTERACTION_FILTERS_CHANGED,
              filter: {
                type: "carbon",
                value: isLowCarbon.toString(),
              },
            })
          }
        />
      </Tooltip>
    ) : (
      <Tooltip
        width={"150px"}
        content={copyText.gcpStorageResourceTableHighCarbonToolTip}
      >
        <Icon
          clickable
          color={theme.feedback_neutral}
          icon={faLeaf}
          onClick={() =>
            props.onInteraction({
              type: GCPStorageResourceTable.INTERACTION_FILTERS_CHANGED,
              filter: {
                type: "carbon",
                value: null,
              },
            })
          }
        />
      </Tooltip>
    );
  }

  function selectSubTable(value: Bucket[]): JSX.Element {
    return (
      <Flex alignItems="center">
        <Tooltip content={copyText.gcpStorageSubTableViewBuckets}>
          <Button
            onClick={() =>
              props.onInteraction({
                type: GCPStorageResourceTable.INTERACTION_SELECT_BUCKETS_CLICKED,
                selectedBuckets: value,
              })
            }
            iconStart={<Icon icon={faCircleInfo} />}
            primary
            size="tiny"
          />
        </Tooltip>
      </Flex>
    );
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor("lowCarbon", {
        cell: ({ getValue }) => formatLowCarbon(getValue()),
        size: 25,
      }),
      columnHelper.accessor("buckets", {
        cell: ({ getValue }) => selectSubTable(getValue()),
        size: 40,
      }),
      columnHelper.accessor("projectId", {
        cell: ({ getValue }) => formatSelectCategory("projectID", getValue()),
        header: copyText.gcpStorageResourceTableProject,
        size: 100,
      }),
      columnHelper.accessor("region", {
        cell: ({ getValue }) => formatSelectCategory("region", getValue()),
        header: copyText.gcpStorageResourceTableRegion,
        size: 80,
      }),
      columnHelper.accessor("bucketCount", {
        header: copyText.gcpStorageResourceTableBuckets,
        meta: { align: "right" },
        size: 80,
      }),
      columnHelper.accessor("storage", {
        cell: ({ getValue }) => <>{formatCost(getValue())}</>,
        header: copyText.gcpStorageResourceTableStorage,
        meta: { align: "right" },
        size: 80,
      }),
      columnHelper.accessor("processing", {
        cell: ({ getValue }) => <>{formatCost(getValue())}</>,
        header: copyText.gcpStorageResourceTableProcessing,
        meta: { align: "right" },
        size: 90,
      }),
      columnHelper.accessor("network", {
        cell: ({ getValue }) => <>{formatCost(getValue())}</>,
        header: copyText.gcpStorageResourceTableNetwork,
        meta: { align: "right" },
        size: 80,
      }),
      columnHelper.accessor("other", {
        cell: ({ getValue }) => <>{formatCost(getValue())}</>,
        header: copyText.gcpStorageResourceTableOther,
        meta: { align: "right" },
        size: 80,
      }),
      columnHelper.accessor("ingress", {
        cell: ({ getValue }) => <>{prettyBytes(getValue())}</>,
        header: copyText.gcpStorageResourceTableIngress,
        meta: { align: "right" },
        size: 80,
      }),
      columnHelper.accessor("egress", {
        cell: ({ getValue }) => <>{prettyBytes(getValue())}</>,
        header: copyText.gcpStorageResourceTableEgress,
        meta: { align: "right" },
        size: 80,
      }),
      columnHelper.accessor("operations", {
        cell: ({ getValue }) => <>{formatNumber(getValue())}</>,
        header: copyText.gcpStorageResourceTableOperation,
        meta: { align: "right" },
        size: 80,
      }),
      columnHelper.accessor("recommendation", {
        cell: ({ getValue }) => <>{getValue().length}</>,
        header: copyText.gcpStorageResourceTableRecommendation,
        meta: { align: "left" },
        size: 80,
      }),
    ],
    [props.resources]
  );

  const data: TableData[] = useMemo(() => {
    const tableData = props.resources.map((item) => {
      return {
        buckets: item.buckets,
        bucketCount: item.bucketCount,
        projectId: item.projectID ?? "null",
        region: item.region ?? "null",
        lowCarbon: item.lowCarbon,
        network: item.network,
        processing: item.processing,
        storage: item.storage,
        other: item.other,
        ingress: item.ingress,
        egress: item.egress,
        operations: item.operations,
        recommendation: item.recommendation,
      };
    });
    return tableData;
  }, [props.resources]);

  return (
    <Table
      columns={columns}
      data={data}
      isLoading={props.isLoading}
      selectedRowID={props.selectedResourceID}
      showPagination
      sortable
    />
  );
}

const formatCost = (cost: number | null) => {
  if (cost === null) return copyText.cloudSQLResourceTableLabelNotAvailable;
  return formatCurrency({ number: cost });
};

GCPStorageResourceTable.INTERACTION_FILTERS_CHANGED =
  `GCPStorageResourceTable.INTERACTION_FILTERS_CHANGES` as const;

GCPStorageResourceTable.INTERACTION_SELECT_BUCKETS_CLICKED =
  `GCPStorageResourceTable.INTERACTION_SELECT_BUCKETS_CLICKED` as const;

interface InteractionFiltersChanged {
  type: typeof GCPStorageResourceTable.INTERACTION_FILTERS_CHANGED;
  filter: {
    type: string;
    value: string | null;
  };
}

interface InteractionSelectBucketsClicked {
  type: typeof GCPStorageResourceTable.INTERACTION_SELECT_BUCKETS_CLICKED;
  selectedBuckets: Bucket[];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace GCPStorageResourceTable {
  export type Interaction =
    | InteractionFiltersChanged
    | InteractionSelectBucketsClicked;
}

export default GCPStorageResourceTable;
