import { useTheme } from "@emotion/react";
import {
  formatCurrency,
  formatPercentage,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import React from "react";
import Meter from "../../../../components/Meter";
import copyText from "../../copyText";
import { AWSCommitmentInventoryDatum } from "../types";

type Props = {
  commitmentCount: number;
  coveragePercentage: number;
  inventoryTotals: AWSCommitmentInventoryDatum | null;
  isLoading: boolean;
};

export default function AWSCommittedUseVisibilityMeters(props: Props) {
  const theme = useTheme();

  const meterValues = getVisibilityMeterValues(
    props.commitmentCount,
    props.inventoryTotals
  );

  return (
    <Flex
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_1}
      padding={theme.space_md}
      paddingVertical={theme.space_sm}
      overflowX="auto"
    >
      <Box flex="1 0 175px">
        <Meter
          header={copyText.awsMeterVis_0_totalCommitments}
          hideFooter
          loading={props.isLoading}
          value={String(meterValues.totalCommitments)}
        />
      </Box>

      <Box
        borderRight={`1px solid ${theme.border_color}`}
        flex="0 0 auto"
        marginHorizontal={theme.space_lg}
      />

      <Box flex="1 0 175px">
        <Meter
          header={copyText.awsMeterVis_1_totalRealizedSavings}
          hideFooter
          loading={props.isLoading}
          value={formatCurrency({ number: meterValues.totalRealizedSavings })}
        />
      </Box>

      <Box
        borderRight={`1px solid ${theme.border_color}`}
        flex="0 0 auto"
        marginHorizontal={theme.space_lg}
      />

      <Box flex="1 0 175px">
        <Meter
          header={copyText.awsMeterVis_2_totalUnrealizedSavings}
          hideFooter
          loading={props.isLoading}
          value={formatCurrency({ number: meterValues.totalUnrealizedSavings })}
        />
      </Box>

      <Box
        borderRight={`1px solid ${theme.border_color}`}
        flex="0 0 auto"
        marginHorizontal={theme.space_lg}
      />

      <Box flex="1 0 175px">
        <Meter
          header={copyText.awsMeterVis_3_effectiveSavingsRate}
          hideFooter
          loading={props.isLoading}
          value={formatPercentage(meterValues.effectiveSavingsRate)}
        />
      </Box>

      <Box
        borderRight={`1px solid ${theme.border_color}`}
        flex="0 0 auto"
        marginHorizontal={theme.space_lg}
      />

      <Box flex="1 0 175px">
        <Meter
          header={copyText.awsMeterVis_4_utilizationPercentage}
          hideFooter
          loading={props.isLoading}
          value={formatPercentage(meterValues.utilizationPercentage)}
        />
      </Box>

      <Box
        borderRight={`1px solid ${theme.border_color}`}
        flex="0 0 auto"
        marginHorizontal={theme.space_lg}
      />

      <Box flex="1 0 175px">
        <Meter
          header={copyText.awsMeterVis_5_coverage}
          hideFooter
          loading={props.isLoading}
          value={formatPercentage(props.coveragePercentage)}
        />
      </Box>
    </Flex>
  );
}

function getVisibilityMeterValues(
  commitmentCount: number,
  inventory: AWSCommitmentInventoryDatum | null
) {
  if (!inventory) {
    return {
      coverage: 0,
      effectiveSavingsRate: 0,
      totalCommitments: 0,
      totalRealizedSavings: 0,
      totalUnrealizedSavings: 0,
      utilizationPercentage: 0,
    };
  }

  return {
    effectiveSavingsRate: inventory.effectiveSavingsRate / 100,
    totalCommitments: commitmentCount,
    totalRealizedSavings: inventory.realizedSavings,
    totalUnrealizedSavings: inventory.unrealizedSavings,
    utilizationPercentage: inventory.utilizationPercentage / 100,
  };
}
