import DataQuery, {
  Properties,
} from "@ternary/api-lib/analytics/api/DataQuery";
import { getDataSourceFilters } from "@ternary/api-lib/analytics/utils/ReportUtils";
import { useFilterStore } from "../../../context/FilterStoreProvider";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";

type UseDataQueryParams = Omit<Properties, "fiscalPeriodMap">;

// Browser specific wrapper for centralizing some of the logic
// that we always need for doing a Datalligator Query
export default function useBuildDataQuery(params: UseDataQueryParams) {
  const authenticatedUser = useAuthenticatedUser();
  const filterStore = useFilterStore();

  const fiscalPeriodMap = !params.isFiscalMode
    ? undefined
    : authenticatedUser.tenant.fiscalCalendar?.periods;
  const isFiscalMode = params.isFiscalMode
    ? params.isFiscalMode
    : authenticatedUser.settings.fiscalMode;

  const globalFilters = params?.ignoreGlobalFilters
    ? []
    : getDataSourceFilters(filterStore.queryFilters, params.dataSource);

  const query = new DataQuery({
    ...params,
    fiscalPeriodMap,
    isFiscalMode,
    preAggFilters: [...globalFilters, ...(params.preAggFilters ?? [])],
  });

  return [authenticatedUser.tenant.fsDocID, query] as const;
}
