import React, {
  createContext,
  useContext,
  useMemo,
  PropsWithChildren,
} from "react";
import { FeatureFlag } from "@ternary/api-lib/constants/feature-flags";
import useGatekeeper from "@/hooks/useGatekeeper";

/** Conditional text with a default string and optional feature-flag overrides. */
export type ConditionalText = {
  [key in FeatureFlag]?: string;
} & {
  default: string;
};

/** Extracts a union of all string values from a record. */
type ExtractConditionalValues<T> =
  T extends Record<PropertyKey, string> ? T[keyof T] : never;

/**
 * Converts properties of type ConditionalText into a union of their string values
 *
 * Example:
 * ```
 * type Original = {
 *   title: string;
 *   buttonLabel: {
 *     default: "Submit",
 *     [FeatureFlag.MyFeature]: "Apply"
 *   };
 * };
 * ```
 * After applying ResolvedTextType<Original>,
 * the "buttonLabel" property becomes `"Submit" | "Apply"`.
 *
 */
type ResolvedTextType<T> = {
  [K in keyof T]: T[K] extends ConditionalText
    ? ExtractConditionalValues<T[K]>
    : T[K];
};

/** A simple mapping of FeatureFlag -> boolean. */
type FeatureFlags = {
  [key in FeatureFlag]?: boolean;
};

/** The context will expose default flags so any child hook can merge them with local needs. */
interface FeatureTextContextValue {
  defaultFlags: FeatureFlags;
}

/** Create a context to hold our default feature flags. */
const FeatureTextContext = createContext<FeatureTextContextValue | null>(null);

/**
 * This provider sets up a base set of flags (all false),
 * then applies any “global” gatekeeper-based flags (like MSP_PUBLIC_SECTOR).
 */
export function FeatureTextProvider({ children }: PropsWithChildren<unknown>) {
  const gatekeeper = useGatekeeper();

  // 1) Base feature flags: all false initially.
  const baseFeatureFlags = useMemo(() => {
    const flags: Record<FeatureFlag, boolean> = {} as Record<
      FeatureFlag,
      boolean
    >;
    for (const flag of Object.values(FeatureFlag)) {
      flags[flag] = false;
    }
    return flags;
  }, []);

  // 2) Merge in gatekeeper logic to determine global defaults
  //    (e.g., MSP_PUBLIC_SECTOR might be true/false).
  const defaultFlags = useMemo(() => {
    return {
      ...baseFeatureFlags,
      MSP_PUBLIC_SECTOR: gatekeeper.isMSPPublicSector,
      // Add more global defaults if needed
    };
  }, [baseFeatureFlags, gatekeeper.isMSPPublicSector]);

  const contextValue: FeatureTextContextValue = useMemo(() => {
    return { defaultFlags };
  }, [defaultFlags]);

  return (
    <FeatureTextContext.Provider value={contextValue}>
      {children}
    </FeatureTextContext.Provider>
  );
}

/**
 * Hook to resolve any ConditionalText object.
 *
 * Usage:
 * ```ts
 * const { copyText } = useFeatureCopyText({
 *   greeting: "Hello",
 *   label: { default: "Default Label", [FeatureFlag.Special]: "Special Label" }
 * });
 * ```
 */
export function useFeatureText<
  T extends Record<string, string | ConditionalText>,
>(textObj: T, activeFlags?: FeatureFlags) {
  const ctx = useContext(FeatureTextContext);
  if (!ctx) {
    throw new Error(
      "useFeatureCopyText must be used within a <FeatureTextProvider>."
    );
  }

  const { defaultFlags } = ctx;

  // 1) Merge the global default flags with any local overrides
  const mergedFlags = useMemo(() => {
    return { ...defaultFlags, ...activeFlags };
  }, [defaultFlags, activeFlags]);

  // 2) Pick the correct string for each field based on the merged flags
  const copyText = useMemo(() => {
    const result = { ...textObj } as Record<string, unknown>;

    for (const [key, value] of Object.entries(textObj)) {
      // If this object has a "default" string, treat it as ConditionalText
      if (
        value &&
        typeof value === "object" &&
        !Array.isArray(value) &&
        "default" in value
      ) {
        let resolvedValue = value.default;

        // If any matching feature flag is active, override the default
        for (const [flagName, flagText] of Object.entries(value)) {
          if (flagName !== "default" && mergedFlags[flagName as FeatureFlag]) {
            resolvedValue = flagText as string;
            break;
          }
        }

        result[key] = resolvedValue;
      }
    }

    return result as ResolvedTextType<T>;
  }, [textObj, mergedFlags]);

  return { copyText };
}
