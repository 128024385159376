import { buildCubeQuery } from "@/api/analytics/utils";
import { validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { groupBy } from "lodash";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { S3Bucket, S3BucketGroup, S3BucketStruct } from "../types";

export interface Params {
  dateRange: Date[];
}

export default function useGetAWSStorageBuckets(
  params: Params,
  options?: UseQueryOptions<S3BucketGroup[], UError>
): UseQueryResult<S3BucketGroup[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["S3TableData", params],
    queryFn: async () => {
      const dimensions = [
        "accountID",
        "bucketName",
        "lineItemUsageAccountID",
        "region",
        "storageClass",
      ];

      const measures = [
        "bytesDownloaded",
        "bytesUploaded",
        "cost",
        "credits",
        "networkCost",
        "numberOfObjects",
        "operationsCost",
        "requestCount",
        "storageCost",
        "storageUsedBytes",
      ];

      const result = await client.load(
        buildCubeQuery({
          dataSource: DataSource.AWS_STORAGE_VISIBILITY,
          dateRange: params.dateRange,
          dimensions,
          measures,
        })
      );

      const buckets = result.map((datum): S3Bucket => {
        const [error, validData] = validate(datum, S3BucketStruct);
        if (error) {
          throw new UError("INVALID_CLOUD_S3_TABLE_DATUM", {
            context: { error, result: datum },
          });
        }

        return {
          // DIMENSIONS
          accountID: validData.accountID ?? "",
          bucketName: validData.bucketName ?? "",
          lineItemUsageAccountID: validData.lineItemUsageAccountID ?? "",
          region: validData.region ?? "",
          storageClass: validData.storageClass ?? "",

          // MEASURES
          bytesDownloaded: validData.bytesDownloaded ?? 0,
          bytesUploaded: validData.bytesUploaded ?? 0,
          cost: validData.cost ?? 0,
          credits: validData.credits ?? 0,
          networkCost: validData.networkCost ?? 0,
          numberOfObjects: validData.numberOfObjects ?? 0,
          operationsCost: validData.operationsCost ?? 0,
          requestCount: validData.requestCount ?? 0,
          storageCost: validData.storageCost ?? 0,
          storageUsedBytes: validData.storageUsedBytes ?? 0,
        };
      });

      return getGroups(buckets);
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}

const groupedDimensions = ["accountID", "region"] as const;

function getGroupID(datum: S3Bucket) {
  return groupedDimensions
    .map((dimension) => `${dimension}(${datum[dimension]})`)
    .join("-");
}

function getGroups(allBuckets: S3Bucket[]): S3BucketGroup[] {
  const bucketsGroupedByGroupID = groupBy(allBuckets, getGroupID);

  const groupIDs = Object.keys(bucketsGroupedByGroupID);

  const bucketGroups: S3BucketGroup[] = [];

  for (const groupID of groupIDs) {
    const groupBuckets = bucketsGroupedByGroupID[groupID];

    const bucketGroup: S3BucketGroup = {
      // DIMENSIONS
      accountID: groupBuckets[0].accountID,
      region: groupBuckets[0].region,

      // MEASURES
      networkCost: 0,
      operationsCost: 0,
      storageCost: 0,
      cost: 0,
      credits: 0,

      // GROUP FIELDS
      groupID,
      buckets: groupBuckets,
    };

    for (const bucket of groupBuckets) {
      bucketGroup.networkCost += bucket.networkCost;
      bucketGroup.operationsCost += bucket.operationsCost;
      bucketGroup.storageCost += bucket.storageCost;
      bucketGroup.cost += bucket.cost;
    }

    bucketGroups.push(bucketGroup);
  }

  return bucketGroups;
}
