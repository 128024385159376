import { createStructParam } from "@/lib/use-query-params";
import { useTheme } from "@emotion/react";
import { createColumnHelper } from "@tanstack/react-table";
import useRefFn from "@ternary/api-lib/analytics/ui/hooks/useRefFn";
import {
  formatCurrency,
  formatPercentage,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import { AzureCommitmentLookbackPeriod } from "@ternary/api-lib/constants/enums";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import { AzureCommitmentInventoryDatum } from "../types";
import { AzureCommittedUseVisibilityTableFilters } from "./AzureCommittedUseVisibilityContainer";

type Props = {
  inventoryData: AzureCommitmentInventoryDatum[];
  isLoading: boolean;
  lookback: AzureCommitmentLookbackPeriod;
  onClickFilter: (
    key: keyof AzureCommittedUseVisibilityTableFilters,
    value: string
  ) => void;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

const columnHelper = createColumnHelper<AzureCommitmentInventoryDatum>();

export default function AzureCommitmentInventoryTable(props: Props) {
  const theme = useTheme();
  const [sortRule, setSortRule] = useQueryParam(
    "commitment_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "totalCost",
    })
  );

  const handleClickFilter = useRefFn(props.onClickFilter);

  const columns = useMemo(
    () => [
      columnHelper.accessor("type", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() => handleClickFilter("type", getValue())}
          >
            {getValue() || copyText.azureTableNull}
          </Text>
        ),
        header: copyText.azureTableHeader_type,
      }),
      columnHelper.accessor("commitmentName", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() => handleClickFilter("commitmentName", getValue())}
          >
            {getValue() || copyText.azureTableNull}
          </Text>
        ),
        meta: { truncate: true },
        header: copyText.azureTableHeader_commitmentName,
        size: 230,
      }),
      columnHelper.accessor("totalCost", {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.azureTableHeader_totalCost,
        meta: { align: "right" },
        size: 200,
      }),
      columnHelper.accessor("upfrontCost", {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.azureTableHeader_upfrontCost,
        meta: { align: "right" },
      }),
      columnHelper.accessor("avgUtilizationPercent", {
        cell: ({ getValue }) => formatPercentage(getValue() / 100),
        header: copyText.azureTableHeader_avgUtilizationPercent,
        meta: { align: "right" },
      }),
      columnHelper.accessor("instanceCount", {
        header: copyText.azureTableHeader_instanceCount,
        meta: { align: "right" },
      }),
      columnHelper.accessor("skuName", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() => handleClickFilter("skuName", getValue())}
          >
            {getValue() || copyText.azureTableNull}
          </Text>
        ),
        meta: { truncate: true },
        header: copyText.azureTableHeader_skuName,
        size: 200,
      }),
      columnHelper.accessor("term", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() => handleClickFilter("term", getValue())}
          >
            {getValue() || copyText.azureTableNull}
          </Text>
        ),
        header: copyText.azureTableHeader_term,
        size: 100,
      }),
      columnHelper.accessor("purchaseDate", {
        cell: ({ getValue }) => format(new Date(getValue()), "P"),
        header: copyText.azureTableHeader_purchaseDate,
      }),
      columnHelper.accessor("expirationDate", {
        cell: ({ getValue }) => format(new Date(getValue()), "P"),
        header: copyText.azureTableHeader_expirationDate,
      }),
      columnHelper.accessor("region", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() => handleClickFilter("region", getValue())}
          >
            {getValue() || copyText.azureTableNull}
          </Text>
        ),
        header: copyText.azureTableHeader_region,
      }),
      columnHelper.accessor("commitmentId", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() => handleClickFilter("commitmentId", getValue())}
          >
            {getValue() || copyText.azureTableNull}
          </Text>
        ),
        meta: { truncate: true },
        header: copyText.azureTableHeader_commitmentId,
        size: 315,
      }),
    ],
    [props.lookback]
  );

  return (
    <Box maxWidth={"100%"} overflowX="auto">
      <Table
        columns={columns}
        compact
        data={props.inventoryData}
        initialState={{ sorting: [sortRule] }}
        isLoading={props.isLoading}
        showPagination
        sortable
        onChangeSortBy={([sortRule]) => setSortRule(sortRule, "replaceIn")}
      />
    </Box>
  );
}
