import { UnitType } from "../enums";

const measures = [
  { name: "absoluteCredits", unit: UnitType.CURRENCY },
  { name: "blobSizeBytes", unit: UnitType.BINARY_BYTES },
  { name: "bytesDownloaded", unit: UnitType.BYTES },
  { name: "bytesUploaded", unit: UnitType.BYTES },
  { name: "containerCount", unit: UnitType.STANDARD },
  { name: "cost", unit: UnitType.CURRENCY },
  { name: "transactions", unit: UnitType.STANDARD },
  { name: "usageAmount", unit: UnitType.STANDARD },
];

export const dimensions = [
  "accountId",
  "pricingModel",
  "projectId",
  "resourceId",
  "skuDescription",
  "storageAccount",
];

export const azureStorageVisibilitySchema = { dimensions, measures };
