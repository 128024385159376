import { useTheme } from "@emotion/react";
import { createColumnHelper } from "@tanstack/react-table";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import {
  formatCurrency,
  formatNumberRounded,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import {
  GcpCommitmentDurationType,
  GcpCommitmentServiceType,
} from "@ternary/api-lib/constants/enums";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Text from "@ternary/web-ui-lib/components/Text";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import copyText from "../../copyText";
import { CudInventoryCommitmentDatum, GcpCommitmentStatusType } from "../types";
import {
  getReadableGcpCommitmentLengthString,
  getReadableGcpCommitmentServiceTypeStrings,
} from "../utils";

type TableData = {
  commitmentEndTimestamp: string | null;
  commitmentId: string | null;
  commitmentLength: GcpCommitmentDurationType;
  hourlyCommittedAmount: number;
  owner: string | null;
  reservedMemoryGiB: number;
  reservedSlots: number;
  reservedVCPU: number;
  service: GcpCommitmentServiceType | null;
  status: GcpCommitmentStatusType | null;
};

interface Props {
  commitments: CudInventoryCommitmentDatum[];
  isLoading: boolean;
}

export function CudInventoryCommitmentTable(props: Props): JSX.Element {
  const columnHelper = createColumnHelper<TableData>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("commitmentId", {
        cell: ({ getValue }) => {
          const value = getValue();
          if (value === null) {
            return copyText.cudInventoryCommitmentTableNotAvailable;
          }
          return value;
        },
        header: copyText.cudInventoryCommitmentTableHeader_commitmentId,
        meta: { align: "left", truncate: true },
      }),
      columnHelper.accessor("owner", {
        cell: ({ getValue }) => getValue(),
        header: () => (
          <Tooltip content={copyText.cudInventoryCommitmentTableOwnerTooltip}>
            {copyText.cudInventoryCommitmentTableHeader_owner}
          </Tooltip>
        ),
        meta: { align: "left" },
      }),
      columnHelper.accessor("service", {
        cell: ({ getValue }) =>
          getReadableGcpCommitmentServiceTypeStrings(getValue()),
        header: copyText.cudInventoryCommitmentTableHeader_service,
        meta: { align: "left" },
      }),
      columnHelper.accessor("hourlyCommittedAmount", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header:
          copyText.cudInventoryCommitmentTableHeader_hourlyCommittedAmount,
        meta: { align: "right" },
      }),
      columnHelper.accessor("reservedVCPU", {
        cell: ({ getValue }) => formatNumberRounded(getValue()),
        header: copyText.cudInventoryCommitmentTableHeader_reservedVCPU,
        meta: { align: "right" },
      }),
      columnHelper.accessor("reservedMemoryGiB", {
        cell: ({ getValue }) => prettyBytes(getValue()),
        header: copyText.cudInventoryCommitmentTableHeader_reservedMemoryGiB,
        meta: { align: "right" },
      }),
      columnHelper.accessor("reservedSlots", {
        cell: ({ getValue }) => formatNumberRounded(getValue()),
        header: copyText.cudInventoryCommitmentTableHeader_reservedSlots,
        meta: { align: "right" },
      }),
      columnHelper.accessor("commitmentLength", {
        cell: ({ getValue }) =>
          getReadableGcpCommitmentLengthString(getValue()),
        header: copyText.cudInventoryCommitmentTableHeader_commitmentLength,
        meta: { align: "right" },
      }),
      columnHelper.accessor("commitmentEndTimestamp", {
        cell: ({ getValue }) => {
          const value = getValue();
          if (value === null) {
            return copyText.cudInventoryCommitmentTableNotAvailable;
          }
          return getCommitmentEndDateString(value);
        },
        header:
          copyText.cudInventoryCommitmentTableHeader_commitmentEndTimestamp,
        meta: { align: "left" },
      }),
      columnHelper.accessor("status", {
        cell: ({ getValue }) =>
          getReadableGcpCommitmentStatusString(getValue()),
        header: copyText.cudInventoryCommitmentTableHeader_status,
        meta: { align: "left" },
      }),
    ],
    []
  );

  const data = useMemo(() => {
    return getTableDataFromEntrys(props.commitments);
  }, [props.commitments]);

  return (
    <Table
      columns={columns}
      data={data}
      initialState={{ sorting: [{ id: "amountSaved", desc: true }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

function getCommitmentEndDateString(commitmentEndDate: string) {
  const theme = useTheme();

  const numberOfDaysBetweenNowAndEnd =
    (new Date(commitmentEndDate).getTime() - new Date().getTime()) /
    (24 * 60 * 60 * 1000);

  if (numberOfDaysBetweenNowAndEnd >= 0 && numberOfDaysBetweenNowAndEnd < 30) {
    return (
      <Text color={theme.feedback_negative}>
        {formatDate(new Date(commitmentEndDate ?? 0), "dd-MMM-yyyy")}
      </Text>
    );
  }
  return (
    <Text>{formatDate(new Date(commitmentEndDate ?? 0), "dd-MMM-yyyy")}</Text>
  );
}

function getTableDataFromEntrys(instances: CudInventoryCommitmentDatum[]) {
  return instances.map(
    (node): TableData => ({
      commitmentEndTimestamp: node.commitmentEndTimestamp,
      commitmentId: node.commitmentId,
      commitmentLength: node.commitmentLength,
      hourlyCommittedAmount: node.hourlyCommittedAmount ?? 0,
      owner: node.owner,
      reservedMemoryGiB: node.reservedMemoryGiB ?? 0,
      reservedSlots: node.reservedSlots ?? 0,
      reservedVCPU: node.reservedVCPU ?? 0,
      service: node.service,
      status: node.status,
    })
  );
}

export function getReadableGcpCommitmentStatusString(
  commitmentStatus: GcpCommitmentStatusType | null
): JSX.Element {
  const theme = useTheme();
  switch (commitmentStatus) {
    case GcpCommitmentStatusType.ACTIVE:
      return (
        <Text color={theme.feedback_positive}>
          {copyText.cudVisInventoryCommitmentStatusActive}
        </Text>
      );
    case GcpCommitmentStatusType.EXPIRED:
      return (
        <Text color={theme.feedback_negative}>
          {copyText.cudVisInventoryCommitmentStatusExpired}
        </Text>
      );
    case GcpCommitmentStatusType.CANCELLED:
      return (
        <Text color={theme.feedback_neutral}>
          {copyText.cudVisInventoryCommitmentStatusCancelled}
        </Text>
      );
    case GcpCommitmentStatusType.NOT_YET_ACTIVE:
      return (
        <Text color={theme.feedback_neutral}>
          {copyText.cudVisInventoryCommitmentStatusNotYetActive}
        </Text>
      );
    default:
      return (
        <Text color={theme.feedback_neutral}>
          {copyText.cudInventoryTableNotAvailable}
        </Text>
      );
  }
}
