import { useTheme } from "@emotion/react";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import { DurationType } from "@ternary/api-lib/analytics/enums";
import {
  getDateRangeFromLastNDays,
  getDateRangeFromLastNMonths,
  isValidRollingWindow,
} from "@ternary/api-lib/analytics/utils";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import { getDateRangeFromReport } from "@ternary/api-lib/analytics/utils/ReportUtils";
import { ReportEntity } from "@ternary/api-lib/core/types/Report";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { sub } from "date-fns";
import { noop } from "lodash";
import React, { PropsWithChildren } from "react";
import paths from "../../../constants/paths";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import { useNavigateWithSearchParams } from "../../../lib/react-router";
import Divider from "../../../ui-lib/components/Divider";
import Grid from "../../../ui-lib/components/Grid";
import { DateRange } from "../../../utils/dates";
import ReportChart from "../../reporting-engine/components/ReportChart";
import useGetReportByID from "../../reporting-engine/hooks/useGetReportByID";
import useGetReportData, {
  defaultReportDataResult,
} from "../../reporting-engine/hooks/useGetReportData";
import copyText from "../copyText";

interface Props {
  createdDate: string;
  reportID: string;
}

export default function ReportContentContainer(props: Props): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const navigate = useNavigateWithSearchParams();
  const theme = useTheme();

  //
  // Queries
  //

  const { data: _report, isLoading: isLoadingReport } = useGetReportByID(
    props.reportID,
    authenticatedUser.tenant.fsDocID
  );

  // NOTE: Lock the date range to the time the case was made
  const report = _report
    ? getDateRangeSnapshot(_report, props.createdDate)
    : undefined;

  const {
    data: reportDataResult = defaultReportDataResult,
    isFetching: isLoadingReportData,
  } = useGetReportData(report, { enabled: !!report });

  const { main: reportDataMain } = reportDataResult;

  //
  // Interaction Handlers
  //

  function handleClickName() {
    navigate(paths._reportBuilder.replace(":reportID", props.reportID));
  }

  //
  // Render
  //

  if (!report || isLoadingReport) {
    return (
      <EmptyPlaceholder
        icon={faChartBar}
        loading={isLoadingReport}
        skeletonVariant="cartesian"
      />
    );
  }

  const dateRange = getDateRangeFromReport(report);

  const formattedDates = formatDates(dateRange);

  return (
    <Flex direction="column" height="100%" minHeight={500} width="100%">
      <Text
        appearance="link"
        fontSize={theme.h4_fontSize}
        onClick={handleClickName}
      >
        {report.name}
      </Text>
      <Box
        height={335}
        paddingBottom={theme.space_md}
        marginTop={theme.space_sm}
        width="100%"
      >
        <ReportChart
          data={reportDataMain.result.data}
          isLoading={isLoadingReport || isLoadingReportData}
          report={report}
          onInteraction={noop}
        />
      </Box>
      <Divider margin={0} />
      <Grid
        gridTemplateColumns={"repeat(auto-fit, minmax(12rem, 1fr))"}
        gridColumnGap="1rem"
        gridRowGap="1rem"
        marginLeft="3rem"
        padding={theme.space_md}
      >
        <Flex direction="column">
          <Text fontSize={theme.fontSize_ui} fontWeight="bold">
            {copyText.reportSectionSourceLabel}
          </Text>
          <Label>{report.dataSource}</Label>
        </Flex>

        <Flex direction="column">
          <Text fontSize={theme.fontSize_ui} fontWeight="bold">
            {copyText.reportSectionMeasuresLabel}
          </Text>
          {report.measures.map((measure) => (
            <Label key={measure}>- {measure}</Label>
          ))}
        </Flex>

        {report.dimensions.length > 0 && (
          <Flex direction="column">
            <Text fontSize={theme.fontSize_ui} fontWeight="bold">
              {copyText.reportSectionGroupingsLabel}
            </Text>
            {report.dimensions.map((dimension) => (
              <Label key={dimension}>- {dimension}</Label>
            ))}
          </Flex>
        )}

        {formattedDates.length > 0 && (
          <Flex direction="column">
            <Text fontSize={theme.fontSize_ui} fontWeight="bold">
              {copyText.reportSectionDateRangeLabel}
            </Text>
            <Label>{`${formattedDates[0]} - ${formattedDates[1]}`}</Label>
          </Flex>
        )}

        {report.timeGranularity && (
          <Flex direction="column">
            <Text fontSize={theme.fontSize_ui} fontWeight="bold">
              {copyText.reportSectionGranularityLabel}
            </Text>
            <Label>{report.timeGranularity}</Label>
          </Flex>
        )}

        {report.filters.length > 0 && (
          <Flex direction="column">
            <Text fontSize={theme.fontSize_ui} fontWeight="bold">
              {copyText.reportSectionFiltersLabel}
            </Text>
            {report.filters.map((filter) => (
              <Label key={filter.name}>- {filter.name}</Label>
            ))}
          </Flex>
        )}

        {report.metricFilters.length > 0 && (
          <Flex direction="column">
            <Text fontSize={theme.fontSize_base} fontWeight="bold">
              {copyText.reportSectionUnitEconomicsLabel}
            </Text>
            {report.metricFilters.map((metricFilter) => (
              <Label key={metricFilter.name}>- {metricFilter.name}</Label>
            ))}
          </Flex>
        )}
      </Grid>
    </Flex>
  );
}

function Label(props: PropsWithChildren<unknown>): JSX.Element {
  return (
    <Flex alignItems="center">
      <Text overflowWrap="break-word">{props.children}</Text>
    </Flex>
  );
}

function formatDates(dates: DateRange): string[] {
  if (Array.isArray(dates)) {
    return dates.map((date) => {
      return formatDate(new Date(date), "MM/dd/yyyy");
    });
  } else {
    const formattedDate = formatDate(new Date(dates), "MM/dd/yyyy");

    return [formattedDate];
  }
}

function getDateRangeSnapshot(
  report: ReportEntity,
  createdDate: string
): ReportEntity {
  if (report.durationType === DurationType.CUSTOM) {
    return report;
  }

  const createdAt = new Date(createdDate);

  let startDate;
  let endDate;

  let durationType: DurationType;

  switch (report.durationType) {
    case DurationType.LAST_N_DAYS: {
      if (!isValidRollingWindow(report.nLookback)) return report;

      const nDaysRange = getDateRangeFromLastNDays({
        nLookback: report.nLookback,
        startDate: createdAt,
      });

      startDate = nDaysRange[0].toString();
      endDate = nDaysRange[1].toString();
      durationType = DurationType.CUSTOM;

      return { ...report, durationType, endDate, startDate };
    }
    case DurationType.LAST_N_MONTHS: {
      if (!isValidRollingWindow(report.nLookback)) return report;

      const nDaysRange = getDateRangeFromLastNMonths({
        nLookback: report.nLookback,
        startDate: createdAt,
      });

      startDate = nDaysRange[0].toString();
      endDate = nDaysRange[1].toString();
      durationType = DurationType.CUSTOM;

      return { ...report, durationType, endDate, startDate };
    }
    case DurationType.LAST_SEVEN_DAYS: {
      const sevenDays = sub(createdAt, { days: 7 });

      startDate = sevenDays.toString();
      endDate = createdAt.toString();
      durationType = DurationType.CUSTOM;

      return { ...report, durationType, endDate, startDate };
    }
    case DurationType.LAST_THIRTY_DAYS: {
      const thirtyDays = sub(createdAt, { days: 30 });

      startDate = thirtyDays.toString();
      endDate = createdAt.toString();
      durationType = DurationType.CUSTOM;

      return { ...report, durationType, endDate, startDate };
    }
    case DurationType.LAST_NINETY_DAYS: {
      const ninetyDays = sub(createdAt, { days: 90 });

      startDate = ninetyDays.toString();
      endDate = createdAt.toString();
      durationType = DurationType.CUSTOM;

      return { ...report, durationType, endDate, startDate };
    }
    case DurationType.MONTH_TO_DATE: {
      const firstOfMonth = new Date(
        createdAt.getFullYear(),
        createdAt.getMonth(),
        1
      );

      startDate = firstOfMonth.toString();
      endDate = createdAt.toString();
      durationType = DurationType.CUSTOM;

      return { ...report, durationType, endDate, startDate };
    }
    case DurationType.YEAR_TO_DATE: {
      const firstOfYear = new Date(createdAt.getFullYear(), 0, 1);

      startDate = firstOfYear.toString();
      endDate = createdAt.toString();
      durationType = DurationType.CUSTOM;

      return { ...report, durationType, endDate, startDate };
    }
  }

  return report;
}
