import { Theme, useTheme } from "@emotion/react";
import {
  IconDefinition,
  faArrowDown,
  faArrowUp,
  faEquals,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatPercentage } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";

const FOOTER_PLACEHOLDER = "--";

interface Props {
  footerColor?: string;
  footerIcon?: IconDefinition;
  footerTitle?: string;
  footerValue?: JSX.Element | string;
  header: string;
  hideFooter?: boolean;
  loading: boolean;
  value: JSX.Element | string;
  width?: string;
}

export default function Meter(props: Props): JSX.Element {
  const theme = useTheme();

  function renderFooter() {
    if (props.hideFooter) return null;

    return props.footerValue ? (
      <Box>
        <Flex alignItems="center" color={props.footerColor}>
          {props.footerIcon && <FontAwesomeIcon icon={props.footerIcon} />}
          {typeof props.footerValue === "string" ? (
            <Text
              bold
              color={props.footerColor}
              fontSize={theme.h4_fontSize}
              marginLeft={props.footerIcon ? theme.space_xs : 0}
            >
              {props.footerValue}
            </Text>
          ) : (
            props.footerValue
          )}
        </Flex>
        <Text color={theme.text_color_secondary}>{props.footerTitle}</Text>
      </Box>
    ) : (
      <Text
        color={theme.text_color_secondary}
        fontSize={theme.h1_fontSize}
        fontWeight={theme.h1_fontWeight}
      >
        {FOOTER_PLACEHOLDER}
      </Text>
    );
  }

  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      height={"100%"}
      width={props.width}
    >
      {props.loading ? (
        <EmptyPlaceholder
          height="100%"
          icon={faList}
          loading={props.loading}
          small
          skeletonVariant="meter"
        />
      ) : (
        <>
          <Box>
            <Text color={theme.text_color_secondary}>{props.header}</Text>
            {typeof props.value === "string" ? (
              <Text bold fontSize={theme.h2_fontSize}>
                {props.value}
              </Text>
            ) : (
              props.value
            )}
          </Box>
          {renderFooter()}
        </>
      )}
    </Flex>
  );
}

export const getChangePercent = (params: {
  biggerIsBetter?: boolean;
  value: number;
  comparison: number;
  theme: Theme;
}): { icon: IconDefinition; color: string; textValue: string } => {
  const output = {
    icon: faEquals,
    color: "",
    textValue: "0%",
  };

  // no comparison is supported as empty space
  if (isNaN(Number(params.comparison))) {
    output.textValue = "";
    return output;
  }

  // In the case of Infinity, just force the UI to "100%"
  const diffRatio =
    params.comparison > 0 ? params.value / params.comparison : 2;

  // an undefined biggerIsBetter means we just want the number
  if (typeof params.biggerIsBetter !== "boolean") {
    if (params.value > params.comparison) {
      output.textValue = formatPercentage(diffRatio - 1);
    } else if (params.comparison > params.value) {
      output.textValue = formatPercentage(1 - diffRatio);
    }
  }
  // a set biggerIsBetter means we want to express the difference with color & an arrow
  else {
    if (params.value > params.comparison) {
      output.icon = faArrowUp;
      output.color = !params.biggerIsBetter
        ? params.theme.feedback_negative
        : params.theme.feedback_positive;
      output.textValue = formatPercentage(diffRatio - 1);
    } else if (params.comparison > params.value) {
      output.icon = faArrowDown;
      output.color = params.biggerIsBetter
        ? params.theme.feedback_negative
        : params.theme.feedback_positive;
      output.textValue = formatPercentage(1 - diffRatio);
    }
  }

  return output;
};
