export default {
  billToLabel: "Bill To",
  companyAddressPlaceholder: "Billing Address",
  companyLogoPlaceholder: "MSP Company Logo Here",
  companyNamePlaceholder: "MSP Company Name",
  customerAddressPlaceholder: "Billing Address",
  customerDetailsPlaceholder: "Customer Details",
  customerNamePlaceholder: "Customer Name",
  dateLabel: "Invoice Date",
  header: "Billing Statement",
  numberLabel: "Invoice Number",
  paymentInstructionsHeader: "Payment Instructions",
  paymentInstructionsPlaceholder: "Payment Instructions",
  paymentTermLabel: "Term",
  paymentTermPlaceholder: "Payment Term",
  periodLabel: "Invoice Period",
  totalLabel: "Total",
};
