import { useTheme } from "@emotion/react";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import palette from "@ternary/api-lib/ui-lib/theme/palette";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { Tooltip } from "@ternary/web-ui-lib/components/Tooltip";
import { Theme } from "@ternary/web-ui-lib/theme/default";
import React from "react";
import ReactSwitch from "react-switch";

/* prettier-ignore */
const switchTheme = (baseTheme: Theme) => ({
  Switch_backgroundColor_off: palette.grey[50],
  Switch_backgroundColor_on: palette.blue[60],
  Switch_height_small: 16,
  Switch_width_small: 32,
  Switch_height_medium: 20,
  Switch_width_medium: 40,
  ...baseTheme,
});

export interface Props {
  checked: boolean;
  disabled?: boolean;
  name?: string;
  size?: "small" | "medium";
  onChange: (checked: boolean, name?: string) => void;
}

export default function Switch({
  checked,
  disabled,
  name,
  size = "medium",
  onChange,
}: Props): JSX.Element {
  const theme = switchTheme(useTheme());

  const height = theme[`Switch_height_${size}`];
  const width = theme[`Switch_width_${size}`];

  //Should be 2 pixels smaller than the height of the switch
  const diameter = height - 2;

  return (
    <ReactSwitch
      checked={checked}
      checkedIcon={false}
      disabled={disabled}
      handleDiameter={diameter}
      height={height}
      name={name}
      offColor={theme.Switch_backgroundColor_off}
      onColor={theme.Switch_backgroundColor_on}
      uncheckedIcon={false}
      width={width}
      onChange={(checked) => onChange(checked, name)}
    />
  );
}

type LabeledSwitchProps = Props & {
  label: string;
  labelSide?: "left" | "right";
  tooltipCaption?: string;
};

export function LabeledSwitch({
  label,
  labelSide,
  tooltipCaption,
  ...props
}: LabeledSwitchProps) {
  const theme = useTheme();

  const labelText = (
    <Flex
      alignItems="center"
      marginRight={labelSide !== "right" ? theme.space_sm : undefined}
      marginLeft={labelSide === "right" ? theme.space_sm : undefined}
    >
      <Text
        color={props.disabled ? theme.text_color_disabled : theme.text_color}
        whiteSpace="nowrap"
      >
        {label}
      </Text>
      {tooltipCaption && (
        <Box marginLeft={theme.space_xs}>
          <Tooltip content={tooltipCaption}>
            <Icon
              icon={faCircleInfo}
              color={theme.text_color_inverse}
              size="sm"
            />
          </Tooltip>
        </Box>
      )}
    </Flex>
  );

  return (
    <label>
      <Flex
        alignItems="center"
        cursor={props.disabled ? undefined : "pointer"}
        justifyContent="space-between"
      >
        {labelSide !== "right" && labelText}
        <Switch {...props} />
        {labelSide === "right" && labelText}
      </Flex>
    </label>
  );
}
