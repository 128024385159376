import { faList } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { UnitType } from "@ternary/api-lib/analytics/enums";
import { MeasureCell } from "@ternary/api-lib/ui-lib/components/Table/MeasureCell";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useMemo } from "react";
import copyText from "../copyText";

type SpendSummary = {
  credits: number;
  grossCost: number;
  name: string;
  netCost: number;
};

type TableData = {
  credits: number;
  grossCost: number;
  name: string;
  netCost: number;
};

interface Props {
  loading: boolean;
  spendSummaries: SpendSummary[];
}

const columnHelper = createColumnHelper<TableData>();

export default function NetCostTable(props: Props): JSX.Element {
  if (props.spendSummaries.length === 0 || props.loading) {
    return (
      <EmptyPlaceholder
        icon={faList}
        loading={props.loading}
        skeletonVariant="rows"
        text={copyText.netCostTableEmptyPlaceholderText}
      />
    );
  }

  // TODO: Replace this with totals from datalligator
  const aggregatedTotals = props.spendSummaries.reduce(
    (accum, datum) => {
      accum["credits"] += datum.credits;
      accum["grossCost"] += datum.grossCost;
      accum["netCost"] += datum.netCost;

      return accum;
    },
    { credits: 0, grossCost: 0, netCost: 0 }
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: copyText.tableHeaderValue,
        cell: ({ getValue }) => (
          <Text truncate>
            <Tooltip content={getValue()}>{getValue()}</Tooltip>
          </Text>
        ),
      }),
      columnHelper.accessor("grossCost", {
        cell: ({ column, getValue }) => (
          <MeasureCell
            applyMaxCharacters
            accounting
            columnID={column?.id}
            unit={UnitType.CURRENCY}
            value={getValue()}
          />
        ),
        footer: ({ column }) => (
          <MeasureCell
            applyMaxCharacters
            accounting
            columnID={column?.id}
            unit={UnitType.CURRENCY}
            value={aggregatedTotals.grossCost}
          />
        ),
        meta: { align: "right" },
        header: copyText.accountingGrossCost,
      }),
      columnHelper.accessor("credits", {
        cell: ({ column, getValue }) => (
          <MeasureCell
            applyMaxCharacters
            accounting
            columnID={column?.id}
            unit={UnitType.CURRENCY}
            value={getValue()}
          />
        ),
        footer: ({ column }) => (
          <MeasureCell
            applyMaxCharacters
            accounting
            columnID={column?.id}
            unit={UnitType.CURRENCY}
            value={aggregatedTotals.credits}
          />
        ),
        meta: { align: "right" },
        header: copyText.accountingCredits,
      }),
      columnHelper.accessor("netCost", {
        cell: ({ column, getValue }) => (
          <MeasureCell
            applyMaxCharacters
            accounting
            columnID={column?.id}
            unit={UnitType.CURRENCY}
            value={getValue()}
          />
        ),
        footer: ({ column }) => (
          <MeasureCell
            applyMaxCharacters
            accounting
            columnID={column?.id}
            unit={UnitType.CURRENCY}
            value={aggregatedTotals.netCost}
          />
        ),
        meta: { align: "right" },
        header: copyText.accountingNetCost,
      }),
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={props.spendSummaries}
      compact
      footer
      showPagination
      sortable
    />
  );
}
