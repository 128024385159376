import { UnitType } from "../enums";

const measures = [
  { name: "computeCost", unit: UnitType.CURRENCY },
  { name: "storageCost", unit: UnitType.CURRENCY },
  { name: "egressCost", unit: UnitType.CURRENCY },
  { name: "streamingInsertCost", unit: UnitType.CURRENCY },
];

const dimensions = ["projectId"];

export const bigQueryCostSchema = { dimensions, measures };
