import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  arrow,
  autoPlacement,
  offset,
  Placement,
  shift,
  size,
  useFloating,
} from "@floating-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { JSX, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { v4 as uuidv4 } from "uuid";

const DEFAULT_TOOLTIP_HIDE_TIME = 50;

interface PopperContainerProps {
  hasIcon: boolean;
  width: string | undefined;
}

const StyledTarget = styled.div<{ extendedTop: boolean; width?: string }>`
  margin-top: ${(props) => (props.extendedTop ? "-1rem" : "unset")};
  padding-top: ${(props) => (props.extendedTop ? "1rem" : "unset")};
  width: ${(props) => (props.width ? props.width : undefined)};
`;

const PopperContainer = styled.div<PopperContainerProps>`
  font-size: ${(props) => props.theme.fontSize_ui};
  font-weight: normal;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: ${(props) => props.theme.tooltip_background_color};
  padding: 0.5rem;
  text-align: center;
  min-width: ${(props) => (props.width ? props.width : "unset")};
  max-width: ${(props) => (props.width ? props.width : "unset")};
  color: ${(props) => props.theme.text_color_inverse};
  z-index: ${(props) => props.theme.zIndex_1600};
  text-transform: none;
`;

interface Props {
  children?: React.ReactNode;
  content: JSX.Element | string;
  delayHide?: number;
  extendedTop?: boolean;
  hide?: boolean;
  icon?: IconProp;
  iconColor?: string;
  notAppendToBody?: boolean;
  placement?: Placement | "auto";
  targetWidth?: string;
  width?: string;
}

let openID: string | null = null;

export function Tooltip(props: Props) {
  const theme = useTheme();

  const [showPopper, setShowPopper] = useState(false);
  const bodyRef = useRef<HTMLElement | null>(null);

  // the ref for the arrow must be a callback ref
  const [arrowRef, setArrowRef] = useState(null);

  const middleware = [
    arrow({ element: arrowRef }),
    offset(10),
    // Prevents the tooltip from being cut off by the window
    shift({ padding: 8 }),
    size({
      apply({ availableWidth, availableHeight, elements }) {
        Object.assign(elements.floating.style, {
          maxWidth: `${availableWidth - 16}px`,
          maxHeight: `${availableHeight - 16}px`,
        });
      },
    }),
  ];

  let placement: Placement | undefined;

  // If placement is auto, we don't want to use a placement, and we want to use autoPlacement middleware
  if (props.placement === "auto") {
    placement = undefined;
    middleware.push(autoPlacement());
  } else {
    placement = props.placement;
  }

  const { refs, floatingStyles } = useFloating({
    middleware,
    placement,
    strategy: props.width ? "absolute" : "fixed",
  });

  const intervalToClose = useRef<NodeJS.Timeout | null>(null);
  const openIDRef = useRef<string | null>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  // We want to clear the interval any time we set show to false - no longer needed
  function clearIntervalToClose() {
    if (typeof intervalToClose.current === "number") {
      clearInterval(intervalToClose.current);
    }
  }

  function handleMouseEnter() {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    setShowPopper(true);

    const openIDString = uuidv4();
    openID = openIDString;
    openIDRef.current = openIDString;
  }

  function handleMouseLeave() {
    timeout.current = setTimeout(() => {
      setShowPopper(false);
      clearIntervalToClose();
    }, props.delayHide ?? DEFAULT_TOOLTIP_HIDE_TIME);
    intervalToClose.current = setInterval(() => {
      if (showPopper && openID !== openIDRef.current) {
        setShowPopper(false);
        clearIntervalToClose();
      }
    }, 100);
  }

  useEffect(() => {
    // Check if we are in a browser environment
    if (typeof window !== "undefined") {
      let tooltipContainer = document.getElementById("tooltipContainer");

      if (!tooltipContainer) {
        tooltipContainer = document.createElement("div");
        tooltipContainer.id = "tooltipContainer";
        tooltipContainer.style.position = "absolute";
        // This z-index is higher than the LegacyDrawer
        tooltipContainer.style.zIndex = "1000001";
        document.body.appendChild(tooltipContainer);
      }

      bodyRef.current = tooltipContainer;
    }

    return () => {
      if (typeof intervalToClose.current === "number") {
        clearInterval(intervalToClose.current);
      }
      if (typeof timeout.current === "number") {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  const popperElement = (
    <PopperContainer
      hasIcon={props.icon !== undefined}
      ref={refs.setFloating}
      style={floatingStyles}
      width={props.width}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div ref={setArrowRef as React.Ref<HTMLDivElement>} id="arrow" />
      {props.content}
    </PopperContainer>
  );

  return (
    <>
      <StyledTarget
        ref={refs.setReference}
        extendedTop={Boolean(props.extendedTop)}
        width={props.targetWidth}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {props.icon ? (
          <FontAwesomeIcon
            color={props.iconColor ?? theme.text_color}
            icon={props.icon}
            style={{ margin: "0 0.5rem" }}
            onMouseOver={handleMouseEnter}
          />
        ) : (
          props.children
        )}
      </StyledTarget>

      {showPopper && !props.hide
        ? !props.notAppendToBody && bodyRef.current
          ? ReactDOM.createPortal(popperElement, bodyRef.current)
          : popperElement
        : null}
    </>
  );
}
