import { useTheme } from "@emotion/react";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import {
  CaseStatus,
  CaseType,
  ResourceType,
} from "@ternary/api-lib/constants/enums";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useMemo } from "react";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import Checkbox from "../../../ui-lib/components/Checkbox";
import { getFullName } from "../../../utils/UserUtils";
import copyText from "../copyText";
import { CaseCategory } from "../types";

type TableData = {
  assignees: User[];
  createdAt: string;
  createdBy: string;
  description: string;
  followers: User[];
  id: string;
  name: string;
  number: number;
  resourceID: string;
  resourceType: ResourceType;
  selectCaseID: string;
  status: CaseStatus;
  tenantID: string;
  type: CaseType;
  updatedAt: string | null;
  updatedByID: string | null;
};

type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

type Case = {
  assignees: User[];
  createdAt: string;
  createdByID: string;
  description: string;
  followers: User[];
  id: string;
  name: string;
  number: number;
  resourceID: string;
  resourceType: ResourceType;
  status: CaseStatus;
  tenantID: string;
  type: CaseType;
  updatedAt: string | null;
  updatedByID: string | null;
  user: User | null;
};

interface Props {
  cases: Case[];
  categoryFilter: string;
  isLoading: boolean;
  selectedCaseIDs: string[];
  onInteraction: (interaction: CaseManagementTable.Interaction) => void;
}

const columnHelper = createColumnHelper<TableData>();

export function CaseManagementTable(props: Props): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const theme = useTheme();

  function renderFollowing(followers: User[]): JSX.Element | null {
    const isFollowing = followers.some(
      (follower) => getFullName(follower) === getFullName(authenticatedUser)
    );

    return isFollowing ? (
      <Tooltip content={copyText.caseManagementTableFollowingCaption}>
        <Box paddingLeft={theme.space_sm}>
          <Icon icon={faEye} />
        </Box>
      </Tooltip>
    ) : null;
  }

  function renderAssignee(assignees: User[] | null): JSX.Element | string {
    if (assignees !== null && assignees.length === 1) {
      return getFullName(assignees[0]) || assignees[0].email;
    } else if (assignees !== null && assignees.length > 1) {
      const otherAssignees = assignees.slice(1, assignees.length);

      const content = (
        <Flex
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          minWidth={150}
        >
          {otherAssignees.map((assignee, i) => (
            <Text key={`${i}-${assignee.id}`} color={theme.text_color_inverse}>
              {getFullName(assignee) || assignee.email}
            </Text>
          ))}
        </Flex>
      );

      return (
        <Tooltip content={content}>
          <Text>{`${getFullName(assignees[0])} & ${
            otherAssignees.length
          } more `}</Text>
        </Tooltip>
      );
    }

    return copyText.caseManagementTableUnassignedLabel;
  }

  function handleClickRow(caseID: string): void {
    props.onInteraction({
      type: CaseManagementTable.INTERACTION_SELECT_BUTTON_CLICKED,
      caseID,
    });
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor("selectCaseID", {
        cell: function renderCheckBox({ getValue }) {
          const isChecked = props.selectedCaseIDs.includes(getValue());
          return props.categoryFilter !== CaseCategory.CLOSED ? (
            <Checkbox
              name="selectSingleCase"
              checked={isChecked}
              onChange={(event) => {
                props.onInteraction({
                  type: CaseManagementTable.INTERACTION_CHECKBOX_CLICKED,
                  caseID: getValue(),
                  isSelected: event.target.checked,
                });
              }}
            />
          ) : null;
        },
        header: ({ table }) => {
          const tableData = table.getRowModel().rows.map((row) => row.original);
          const grabCaseIds: string[] = tableData.map((datum) => datum.id);

          const isChecked =
            props.selectedCaseIDs.length > 0 &&
            props.selectedCaseIDs.length === grabCaseIds.length;
          return props.categoryFilter !== CaseCategory.CLOSED ? (
            <Checkbox
              name="selectAllCases"
              checked={isChecked}
              onChange={(event) => {
                props.onInteraction({
                  type: CaseManagementTable.INTERACTION_SELECT_ALL_CHECKBOX_CLICKED,
                  caseIDs: grabCaseIds,
                  isSelected: event.target.checked,
                });
              }}
            />
          ) : null;
        },
        size: 10,
      }),
      columnHelper.accessor("number", {
        header: copyText.tableHeaderCaseNumber,
      }),
      columnHelper.accessor("name", {
        header: copyText.tableHeaderCaseName,
      }),
      columnHelper.accessor("type", {
        cell: ({ getValue }) => copyText[`caseType_${getValue()}`],
        header: copyText.tableHeaderCaseType,
      }),
      columnHelper.accessor("createdAt", {
        cell: ({ getValue }) => (
          <>{formatDate(new Date(getValue()), "yyyy-MM-dd")}</>
        ),
        header: copyText.tableHeaderDateCreated,
      }),
      columnHelper.accessor("createdBy", {
        header: copyText.caseManagementContainerHeaderCreatedBy,
      }),
      columnHelper.accessor("assignees", {
        cell: ({ getValue }) => <>{renderAssignee(getValue())}</>,
        header: copyText.caseManagementContainerHeaderAssignees,
      }),
      columnHelper.accessor("followers", {
        cell: ({ row }) => <>{renderFollowing(row.original.followers)}</>,
        header: copyText.tableHeaderCaseFollowers,
        sortDescFirst: true,
        sortingFn: (row) => {
          if (
            row.original.followers !== null &&
            row.original.followers.some(
              (followers) => followers.id === authenticatedUser.id
            )
          ) {
            return 1;
          }
          return 0;
        },
      }),
    ],
    [props.cases, authenticatedUser]
  );

  const data = useMemo(() => {
    return props.cases.map((_case) => {
      return {
        ..._case,
        createdBy: getFullName(_case.user) ?? _case.createdByID,
        selectCaseID: _case.id,
      };
    });
  }, [props.cases]);

  return (
    <Table
      columns={columns}
      clickableRows
      data={data}
      initialState={{ sorting: [{ id: "number", desc: false }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
      onClick={(row) => handleClickRow(row.id)}
    />
  );
}

CaseManagementTable.INTERACTION_SELECT_BUTTON_CLICKED =
  `CaseManagementTable.INTERACTION_SELECT_BUCKETS_CLICKED` as const;

CaseManagementTable.INTERACTION_CHECKBOX_CLICKED =
  `CaseManagementTable.INTERACTION_CHECKBOX_CLICKED` as const;

CaseManagementTable.INTERACTION_SELECT_ALL_CHECKBOX_CLICKED =
  `CaseManagementTable.INTERACTION_SELECT_ALL_CHECKBOX_CLICKED` as const;

interface InteractionSelectCaseClicked {
  type: typeof CaseManagementTable.INTERACTION_SELECT_BUTTON_CLICKED;
  caseID: string;
}
interface InteractionSelectCaseCheckboxClicked {
  type: typeof CaseManagementTable.INTERACTION_CHECKBOX_CLICKED;
  caseID: string;
  isSelected: boolean;
}
interface InteractionSelectAllCasesCheckboxClicked {
  type: typeof CaseManagementTable.INTERACTION_SELECT_ALL_CHECKBOX_CLICKED;
  caseIDs: string[];
  isSelected: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CaseManagementTable {
  export type Interaction =
    | InteractionSelectCaseClicked
    | InteractionSelectCaseCheckboxClicked
    | InteractionSelectAllCasesCheckboxClicked;
}

export default CaseManagementTable;
