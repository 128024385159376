import { buildCubeQuery } from "@/api/analytics/utils";
import { validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { AWSDatabaseInstance, AWSDatabaseInstanceStruct } from "../types";

export interface Params {
  dateRange: Date[];
  queryFilters: QueryFilter[];
}

export default function useGetAWSDatabaseInstances(
  params: Params,
  options?: UseQueryOptions<AWSDatabaseInstance[], UError>
): UseQueryResult<AWSDatabaseInstance[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["awsDatabaseInstances", params],
    queryFn: async () => {
      const dimensions = [
        "databaseType",
        "family",
        "familyType",
        "instanceId",
        "instanceType",
        "lineItemUsageAccountId",
        "region",
      ];

      const measures = [
        "cpuUtilization",
        "databaseConnections",
        "networkBytes",
        "productMemoryBytes",
        "productVCPU",
        "ramUtilization",
        "totalCost",
        "usedDiskBytes",
      ];

      const result = await client.load(
        buildCubeQuery({
          dataSource: DataSource.AWS_DATABASE_INSTANCES,
          dateRange: params.dateRange,
          dimensions,
          measures,
          queryFilters: params.queryFilters,
        })
      );

      const instances = result.map((datum): AWSDatabaseInstance => {
        const [error, validData] = validate(datum, AWSDatabaseInstanceStruct);

        if (error) {
          throw new UError("INVALID_AWS_DATABASE_INSTANCE_TABLE_DATUM", {
            context: { error, result: datum },
          });
        }

        return {
          // DIMENSIONS
          databaseType: validData.databaseType ?? "",
          family: validData.family ?? "",
          familyType: validData.familyType ?? "",
          instanceId: validData.instanceId ?? "",
          instanceType: validData.instanceType ?? "",
          lineItemUsageAccountId: validData.lineItemUsageAccountId ?? "",
          region: validData.region ?? "",

          // MEASURES
          cpuUtilization: validData.cpuUtilization ?? 0,
          databaseConnections: validData.databaseConnections ?? 0,
          networkBytes: validData.networkBytes ?? 0,
          ramUtilization: validData.ramUtilization ?? 0,
          totalCost: validData.totalCost ?? 0,
          usedDiskBytes: validData.usedDiskBytes ?? 0,

          // DIMEASURES
          productMemoryBytes: validData.productMemoryBytes ?? 0,
          productVCPU: validData.productVCPU ?? 0,
        };
      });

      return instances;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
