import SelectDropdown, { Option } from "@/ui-lib/components/SelectDropdown";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  faCheck,
  faCircleInfo,
  faEdit,
  faFileExport,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { UnitType } from "@ternary/api-lib/analytics/enums";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import { formatCurrencyRounded } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import {
  CloudProviderType,
  RecommendationCategory,
  ResourceType,
} from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import { MeasureCell } from "@ternary/api-lib/ui-lib/components/Table/MeasureCell";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import { format } from "date-fns";
import { keyBy, uniq } from "lodash";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import Card from "../../../components/Card";
import Label from "../../../components/Label";
import paths from "../../../constants/paths";
import useGatekeeper from "../../../hooks/useGatekeeper";
import useRefFn from "../../../hooks/useRefFn";
import { useNavigateWithSearchParams } from "../../../lib/react-router";
import Checkbox from "../../../ui-lib/components/Checkbox";
import getCn from "../../../utils/getCn";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";
import { recommendationStatusText } from "./RecommendationDetails";

const COMPONENT_NAME = "RecommendationsTable";
const cn = getCn(COMPONENT_NAME);

const StyledBox = styled(Box)`
  width: 100%;
  min-height: 400px;

  .${cn("link-wrapper")} {
    margin-left: 0.25rem;
  }

  .${cn("totals")} {
    color: ${(props) => props.theme.text_color_secondary};
  }

  .${cn("totals")} span {
    color: ${(props) => props.theme.text_color};
    font-weight: bold;
    margin-left: 0.5rem;
  }

  .${cn("totals")} div:nth-of-type(2) {
    margin-left: 3rem;
  }
`;

type Case = {
  id: string;
  name: string;
  number: number;
};

type Recommendation = {
  id: string;
  cases: Case[];
  cloudID: string;
  applicable: boolean;
  createdAt: string;
  details: { key: string; value: string }[];
  estimateCurrency: string;
  estimateValue: number;
  labels: { key: string; value: string }[];
  number: string;
  projectID: string;
  resource: string;
  snoozeUntil: string | null;
  state: string;
  typeID: string;
};

type RecommendationType = {
  id: string;
  displayName: string;
};

interface Props {
  category: RecommendationCategory;
  cloudProviderType: CloudProviderType;
  loading: boolean;
  recommendations: Recommendation[];
  recommendationTypes: RecommendationType[];
  selectedRecommendation: Recommendation | undefined;
  selectedRecommendationIDs: string[];
  onClickEditMulti: (selectedIDs: string[]) => void;
  onSelectRowMulti: (selectedIDs: string[]) => void;
  onSelectRowSingle: (id: string) => void;
}

interface State {
  labelFilters: string[];
  openFilters: {
    closed: boolean;
    open: boolean;
  };
  projectFilters: string[];
  searchText: string;
  statusFilters: {
    accepted: boolean;
    asDesigned: boolean;
    implemented: boolean;
    inaccurate: boolean;
    new: boolean;
    snoozed: boolean;
  };
}

type TableData = {
  id: string;
  cases: Case[];
  createdAt: string;
  description: string;
  estimateCurrency: string;
  estimateValue: number;
  isMultiSelected: boolean;
  number: string;
  open: boolean;
  resource: JSX.Element;
  resourceID: string;
  resourceName: string;
  state: string;
};

const CLOSED_STATUSES = ["asDesigned", "implemented", "inaccurate"];

const columnHelper = createColumnHelper<TableData>();

export default function RecommendationsTable(props: Props): JSX.Element {
  const theme = useTheme();

  const gatekeeper = useGatekeeper();

  const navigate = useNavigateWithSearchParams();

  const [state, setState] = useState<State>({
    labelFilters: [],
    openFilters: {
      open: true,
      closed: false,
    },
    projectFilters: [],
    statusFilters: {
      new: true,
      accepted: true,
      asDesigned: false,
      implemented: false,
      inaccurate: false,
      snoozed: false,
    },
    searchText: "",
  });
  const mergeState = getMergeState(setState);

  const openOptions = [
    { label: copyText.recommendationOpen, value: "open" },
    { label: copyText.recommendationClosed, value: "closed" },
  ];

  const recommendationTypesKeyedByID = keyBy(props.recommendationTypes, "id");

  const projectsOptions: Option[] = useMemo(() => {
    // `props.recommendations` may have multiple instances of the same `projectID`.
    const uniqueProjectIDs = Array.from(
      new Set(props.recommendations.map((rec) => rec.projectID))
    );

    // Project IDs would be in an arbitrary order based on order of `props.recommendations`, so sort.
    uniqueProjectIDs.sort();

    // Return in selector format.
    return uniqueProjectIDs.map((projectID) => {
      return {
        label: projectID,
        value: projectID,
      };
    });
  }, [props.recommendations]);

  const statusOptions = [
    {
      label: copyText.recommendationStatusNew,
      value: "new",
    },
    {
      label: copyText.recommendationStatusAccepted,
      value: "accepted",
    },
    {
      label: copyText.recommendationStatusSnoozed,
      value: "snoozed",
    },
    {
      label: copyText.recommendationStatusImplemented,
      value: "implemented",
    },
    {
      label: copyText.recommendationStatusInaccurate,
      value: "inaccurate",
    },
    {
      label: copyText.recommendationStatusAsDesigned,
      value: "asDesigned",
    },
  ];

  const filteredRecommendations = useMemo(() => {
    let recommendations = props.recommendations;

    if (state.searchText.length > 0) {
      recommendations = recommendations.filter((recommendation) => {
        const str = state.searchText.toLowerCase();

        const recommendationType =
          recommendationTypesKeyedByID[recommendation.typeID];

        const recommendationTypeDisplayName = recommendationType
          ? recommendationType.displayName
          : "";

        const cases = recommendation.cases ?? [];

        return (
          cases.some((_case) => _case.number.toString().includes(str)) ||
          recommendationTypeDisplayName.toLowerCase().includes(str) ||
          recommendation.resource.toLowerCase().includes(str) ||
          recommendation.number.toLowerCase().includes(str) ||
          recommendation.projectID.toLowerCase().includes(str)
        );
      });
    }

    const mustFilterByOpen =
      uniq(Object.values(state.openFilters)).length === 2 ? true : false;

    const mustFilterByStatus =
      uniq(Object.values(state.statusFilters)).length === 2 ? true : false;

    // Status Filter
    if (mustFilterByStatus) {
      recommendations = recommendations.filter((rec) => {
        const matchesStatus =
          (state.statusFilters.new &&
            !rec.snoozeUntil &&
            rec.state === "new") ||
          (state.statusFilters.accepted &&
            !rec.snoozeUntil &&
            rec.state === "accepted") ||
          (state.statusFilters.snoozed && rec.snoozeUntil) ||
          (state.statusFilters.implemented && rec.state === "implemented") ||
          (state.statusFilters.inaccurate && rec.state === "inaccurate") ||
          (state.statusFilters.asDesigned && rec.state === "asDesigned");
        return matchesStatus;
      });
    }

    // Open Filter
    if (mustFilterByOpen) {
      recommendations = recommendations.filter((rec) => {
        const matchesStatus =
          (state.openFilters.open && isRecommendationOpen(rec)) ||
          (state.openFilters.closed && !isRecommendationOpen(rec));
        return matchesStatus;
      });
    }

    // Projects Filter
    if (state.projectFilters.length > 0) {
      recommendations = recommendations.filter((recommendation) => {
        return state.projectFilters.includes(recommendation.projectID);
      });
    }

    // Labels Filter
    if (state.labelFilters.length > 0) {
      recommendations = recommendations.filter((recommendation) => {
        const findLabel = recommendation.labels.find((label) => {
          return !label.value
            ? state.labelFilters[0] === `${label.key}`
            : state.labelFilters[0] === `${label.key}-${label.value}`;
        });
        return findLabel ? recommendation : false;
      });
    }
    return recommendations;
    // Optional: DROP the UseMemo
  }, [
    props.recommendations,
    state.labelFilters,
    state.projectFilters,
    state.searchText,
    state.statusFilters,
    state.openFilters,
  ]);

  const labelOptions: Option[] = useMemo(() => {
    const recommendationLabels = uniq(
      filteredRecommendations.reduce((accum: string[], recommendation) => {
        const labelKeyValues = recommendation.labels.map((labels) => {
          if (!labels.value) {
            return `${labels.key}`;
          }
          return `${labels.key}-${labels.value}`;
        });
        return [...accum, ...labelKeyValues];
      }, [])
    );

    return recommendationLabels.map((rec) => ({
      label: rec,
      value: rec,
    }));
  }, [filteredRecommendations]);

  function isRecommendationOpen(recommendation: Recommendation): boolean {
    const recIsOpen =
      recommendation.applicable &&
      !CLOSED_STATUSES.includes(recommendation.state);
    return recIsOpen;
  }

  const handleMultiSelectAll = useRefFn(() => {
    const selectedIDSet = new Set(props.selectedRecommendationIDs);

    const visableIDs = filteredRecommendations.map(
      (recommendation) => recommendation.id
    );

    const visableSelectedIDs = visableIDs.filter((id) => selectedIDSet.has(id));

    let selectedIDs: string[];
    if (visableSelectedIDs.length > 0) {
      selectedIDs = [];
    } else {
      selectedIDs = visableIDs;
    }

    props.onSelectRowMulti(selectedIDs);
  });

  const handleMultiSelectOne = useRefFn((id: string, checked: boolean) => {
    const selectedIDSet = new Set(props.selectedRecommendationIDs);

    if (checked) {
      selectedIDSet.add(id);
    } else {
      selectedIDSet.delete(id);
    }

    props.onSelectRowMulti(
      props.recommendations
        .filter((recommendation) => selectedIDSet.has(recommendation.id))
        .map((recommendation) => recommendation.id)
    );
  });

  const handleSelectSingle = useRefFn(props.onSelectRowSingle);

  const handleClickEdit = useRefFn(() =>
    props.onClickEditMulti(
      props.selectedRecommendationIDs.filter((id) =>
        filteredRecommendations.find(
          (recommendation) => recommendation.id === id
        )
      )
    )
  );

  const columns = useMemo(() => {
    const canUpdate = gatekeeper.canUpdateRecommendations;

    const multiSelectColumn = columnHelper.display({
      cell: ({ row }) => {
        return (
          <Checkbox
            checked={row.original.isMultiSelected}
            onChange={(e) =>
              handleMultiSelectOne(row.original.id, e.currentTarget.checked)
            }
          />
        );
      },
      enableSorting: false,
      id: "multiSelect",
      meta: { align: "right" },
      header: ({ table }) => {
        const rows = table.getRowModel().rows;
        const checkedCount = rows.reduce(
          (count, row) => (row.original.isMultiSelected ? count + 1 : count),
          0
        );

        return (
          <Checkbox
            checked={checkedCount > 0 && checkedCount === rows.length}
            dashed={checkedCount > 0}
            onChange={handleMultiSelectAll}
          />
        );
      },
      size: 40,
    });

    return [
      columnHelper.display({
        cell: ({ row }) => {
          return (
            <Button
              iconStart={<Icon icon={faCircleInfo} />}
              primary
              size="tiny"
              onClick={(e) => {
                e.preventDefault();
                handleSelectSingle(row.original.number);
              }}
            />
          );
        },
        enableSorting: false,
        id: "drillDown",
        header: ({ table }) => {
          const rows = table.getRowModel().rows;
          const checkedCount = rows.reduce(
            (count, row) => (row.original.isMultiSelected ? count + 1 : count),
            0
          );

          return (
            <Flex
              alignItems="center"
              height={theme.fontSize_base}
              justifyContent="space-between"
              width="100%"
              opacity={checkedCount > 0 ? 1 : 0}
              transition="opacity 300ms"
            >
              {checkedCount > 0 ? (
                <Tooltip
                  content={
                    checkedCount === 1
                      ? copyText.recommendationsBulkEditSingle
                      : copyText.recommendationsBulkEditMulti.replace(
                          "%COUNT%",
                          String(checkedCount)
                        )
                  }
                >
                  <Button
                    iconStart={<Icon icon={faEdit} />}
                    size="tiny"
                    onClick={(e) => {
                      e.preventDefault();
                      handleClickEdit();
                    }}
                  />
                </Tooltip>
              ) : null}
            </Flex>
          );
        },
        size: 50,
      }),
      ...(canUpdate ? [multiSelectColumn] : []),
      columnHelper.accessor("number", {
        header: copyText.recommendationsTableHeaderId,
        meta: { align: "center" },
        sortDescFirst: false,
        size: 50,
      }),
      columnHelper.accessor("description", {
        header: copyText.recommendationsTableHeaderTypeRecommendation,
        sortDescFirst: true,
        size: 180,
      }),
      columnHelper.accessor("resource", {
        header:
          props.cloudProviderType === CloudProviderType.GCP
            ? copyText.recommendationsTableHeaderResourceAndProject
            : copyText.recommendationsTableHeaderResourceAndAccount,
        sortDescFirst: true,
        cell: ({ getValue }) => {
          return <Text truncate>{getValue()}</Text>;
        },
        size: 270,
      }),
      columnHelper.accessor("open", {
        cell: ({ getValue }) => {
          return getValue() ? (
            <Icon
              icon={faCheck}
              color={theme.recommendations_meter_color_active}
            />
          ) : (
            <Icon
              icon={faTimes}
              color={theme.budgets_chart_fill_overage_actual}
            />
          );
        },
        header: copyText.recommendationsTableHeaderOpen,
        meta: { align: "center" },
        sortDescFirst: false,
        size: 110,
      }),
      columnHelper.accessor("state", {
        cell: ({ getValue }) => {
          return <Label text={getValue()} />;
        },
        header: copyText.recommendationsTableHeaderState,
        meta: { align: "center" },
        sortDescFirst: false,
        size: 130,
      }),
      columnHelper.accessor("cases", {
        cell: ({ getValue }) => {
          const value = getValue();

          if (!value.length) {
            return "--";
          }

          const cases = value.length > 8 ? value.slice(0, 8) : value;

          return (
            <Flex>
              {cases.map((_case, index) => (
                <Text
                  key={_case.id}
                  color={theme.primary_color_text}
                  cursor="pointer"
                  marginRight={theme.space_xxs}
                  onClick={() =>
                    navigate(paths._case.replace(":caseID", _case.id))
                  }
                >
                  <Tooltip
                    content={copyText.recommendationsTableLinkToCaseTooltip
                      .replace("%caseNumber%", _case.number.toString())
                      .replace("%caseName%", _case.name)}
                  >
                    {_case.number}
                    {index < value.length - 1 && ", "}
                  </Tooltip>
                </Text>
              ))}
            </Flex>
          );
        },
        meta: { align: "center" },
        header: copyText.recommendationsTableHeaderCaseID,
        size: 120,
      }),
      columnHelper.accessor("createdAt", {
        cell: ({ getValue }) => {
          return formatDate(new Date(getValue()), "MM/dd/yyyy");
        },
        header: copyText.recommendationsTableHeaderDate,
        meta: { align: "center" },
        size: 100,
      }),
      columnHelper.accessor("estimateValue", {
        cell: ({ getValue, row, column }) => {
          const currencyCode = row.original.estimateCurrency;

          return (
            <MeasureCell
              applyMaxCharacters
              columnID={column?.id}
              currencyCode={currencyCode}
              unit={UnitType.CURRENCY}
              value={getValue()}
            />
          );
        },
        header: copyText.recommendationsTableHeaderEstimateUSD,
        meta: { align: "right" },
        sortDescFirst: true,
        size: 120,
      }),
    ];
  }, [gatekeeper.canUpdateRecommendations]);

  const tableData = useMemo(
    (): TableData[] =>
      filteredRecommendations.map((recommendation) => {
        const recommendationType =
          recommendationTypesKeyedByID[recommendation.typeID];

        const gcpProjectLink = `https://console.cloud.google.com/home/dashboard?project=${recommendation.projectID}`;
        const gcpResourceLink = getHyperlink(props.category, recommendation);

        const resource =
          props.cloudProviderType === CloudProviderType.GCP ? (
            <Box>
              <a
                href={gcpResourceLink}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => e.stopPropagation()}
              >
                {recommendation.resource}
              </a>
              {recommendation.projectID &&
              recommendation.projectID.length === 0 ? null : (
                <span className={cn("link-wrapper")}>
                  (
                  {
                    <a
                      href={gcpProjectLink}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {recommendation.projectID}
                    </a>
                  }
                  )
                </span>
              )}
            </Box>
          ) : (
            <Box>
              {recommendation.resource.length > 0
                ? recommendation.resource
                : "--"}{" "}
              ({recommendation.projectID})
            </Box>
          );

        return {
          id: recommendation.id,
          cases: recommendation.cases,
          createdAt: recommendation.createdAt,
          description: recommendationType ? recommendationType.displayName : "",
          estimateCurrency: recommendation.estimateCurrency,
          estimateValue: recommendation.estimateValue,
          isMultiSelected: props.selectedRecommendationIDs.includes(
            recommendation.id
          ),
          number: recommendation.number,
          open: isRecommendationOpen(recommendation),
          resource: resource,
          resourceID: recommendation.id,
          resourceName: recommendation.projectID,
          state: recommendationStatusText[recommendation.state],
        };
      }),
    [filteredRecommendations, props.selectedRecommendationIDs]
  );

  function handleChangeOpenFilter(keysToEnable: string[]) {
    const openKeys = Object.keys(state.openFilters);
    const updatedFilters = openKeys.reduce(
      (accum, filter) => {
        accum[filter] = keysToEnable.includes(filter);
        return accum;
      },
      { ...state.openFilters }
    );
    mergeState({ openFilters: updatedFilters });
  }

  function handleChangeProjectFilter(selectedProjectNames: string[]) {
    mergeState({ projectFilters: selectedProjectNames });
  }

  function handleChangeLabelFilter(selectedLabel: string[]) {
    mergeState({ labelFilters: selectedLabel });
  }

  function handleChangeStatusFilter(keysToEnable: string[]) {
    const statusKeys = Object.keys(state.statusFilters);
    const updatedFilters = statusKeys.reduce(
      (accum, filter) => {
        accum[filter] = keysToEnable.includes(filter);
        return accum;
      },
      { ...state.statusFilters }
    );
    mergeState({ statusFilters: updatedFilters });
  }

  const csvData = getCSVDataFromEntries(filteredRecommendations, props);

  return (
    <StyledBox>
      <Card width="100%" marginBottom={theme.space_md} padding={theme.space_lg}>
        <Flex width="100%" alignItems="center" justifyContent="space-between">
          <Flex className={cn("totals")}>
            <Flex>
              {copyText.recommendationsTableStatsEntriesCount}
              <span>{filteredRecommendations.length}</span>
            </Flex>
            <Flex>
              {copyText.recommendationsTableStatsSavings}
              <span>
                {formatCurrencyRounded({
                  number: filteredRecommendations.reduce(
                    (accum, entry) => accum + entry.estimateValue,
                    0
                  ),
                })}
              </span>
            </Flex>
          </Flex>
          {
            <Flex flexGrow={1} justifyContent="flex-end" alignItems="center">
              <SelectDropdown
                closeOnSubmit
                isMulti
                options={labelOptions}
                placement="bottom-start"
                selectedValues={state.labelFilters}
                onChange={handleChangeLabelFilter}
              >
                <Button
                  marginHorizontal={theme.space_md}
                  secondary
                  size="small"
                >
                  {copyText.recommendationsLabelsFilter}
                </Button>
              </SelectDropdown>
              <SelectDropdown
                closeOnSubmit
                isMulti
                options={openOptions}
                placement="bottom-start"
                selectedValues={Object.keys(state.openFilters).filter(
                  (key) => state.openFilters[key]
                )}
                onChange={handleChangeOpenFilter}
              >
                <Button marginRight={theme.space_md} secondary size="small">
                  {copyText.recommendationsOpenFilter}
                </Button>
              </SelectDropdown>
              <SelectDropdown
                closeOnSubmit
                isMulti
                options={statusOptions}
                placement="bottom-start"
                selectedValues={Object.keys(state.statusFilters).filter(
                  (key) => state.statusFilters[key]
                )}
                onChange={handleChangeStatusFilter}
              >
                <Button marginRight={theme.space_md} secondary size="small">
                  {copyText.recommendationsStatusFilter}
                </Button>
              </SelectDropdown>
              <SelectDropdown
                closeOnSubmit
                isMulti
                onChange={handleChangeProjectFilter}
                options={projectsOptions}
                placement="bottom-start"
                selectedValues={state.projectFilters}
              >
                <Button marginRight={theme.space_md} secondary size="small">
                  {`${copyText.recommendationsProjectFilter} (${state.projectFilters.length})`}
                </Button>
              </SelectDropdown>
              <Box width={260}>
                <TextInput
                  iconEnd={
                    <Icon color={theme.text_color_secondary} icon={faSearch} />
                  }
                  placeholder={
                    copyText.recommendationsTableControlsSearchPlaceholder
                  }
                  size="medium"
                  value={state.searchText}
                  onChange={(e) => mergeState({ searchText: e.target.value })}
                />
              </Box>
            </Flex>
          }
          <CSVLink
            data={csvData.data}
            filename={`${"recommendations"}-${format(
              new Date(),
              "MM-dd-yyyy"
            )}`}
            headers={csvData.headers}
          >
            <Button
              iconStart={<Icon color="inherit" icon={faFileExport} />}
              secondary
              size="tiny"
              marginLeft="1.5rem"
            >
              {copyText.actionExport}
            </Button>
          </CSVLink>
        </Flex>
      </Card>
      <Table
        columns={columns}
        data={tableData}
        initialState={{ sorting: [{ id: "createdAt", desc: true }] }}
        isLoading={props.loading}
        resourceType={ResourceType.RECOMMENDATION}
        selectedRowID={props.selectedRecommendation?.id}
        showPagination
        sortable
      />
    </StyledBox>
  );
}

type CSVData = {
  headers: { label: string; key: string }[];
  data: { [key: string]: string }[];
};

function getCSVDataFromEntries(
  entries: Recommendation[],
  props: Props
): CSVData {
  if (!entries) return { headers: [], data: [] };

  const recommendationTypesKeyedByID = keyBy(props.recommendationTypes, "id");

  const data: { [key: string]: string }[] = entries.map((entry) => {
    const recommendationType = recommendationTypesKeyedByID[entry.typeID]
      ? recommendationTypesKeyedByID[entry.typeID].displayName
      : "";

    const createdOn = formatDate(new Date(entry.createdAt), "MM/dd/yyyy");
    const status = recommendationStatusText[entry.state];

    return {
      id: entry.id,
      recommendationType: recommendationType,
      resource: entry.resource,
      projectID: entry.projectID,
      status: status,
      createdOn: createdOn,
      savings: formatCurrencyRounded({
        currencyCode: entry.estimateCurrency,
        number: entry.estimateValue,
      }),
    };
  });

  const headers: { label: string; key: string }[] = [
    { label: copyText.recommendationsTableHeaderId, key: "id" },
    {
      label: copyText.recommendationsTableHeaderTypeRecommendation,
      key: "recommendationType",
    },
    {
      label: copyText.recommendationsCSVTableHeaderResource,
      key: "resource",
    },
    {
      label: copyText.recommendationsTableHeaderProject,
      key: "projectID",
    },
    { label: copyText.recommendationsTableHeaderState, key: "status" },
    { label: copyText.recommendationsTableHeaderDate, key: "createdOn" },
    {
      label: copyText.recommendationsTableHeaderEstimateUSD,
      key: "savings",
    },
  ];

  return { headers, data };
}

function getHyperlink(
  category: RecommendationCategory,
  recommendation: Recommendation
): string {
  let gcpLink = "https://console.cloud.google.com/";

  switch (category) {
    case RecommendationCategory.COMPUTE: {
      gcpLink += `compute/instances?project=${recommendation.projectID}`;
      return gcpLink;
    }
    case RecommendationCategory.KUBERNETES: {
      gcpLink += `kubernetes/list/overview?project=${recommendation.projectID}`;
      return gcpLink;
    }
    case RecommendationCategory.STORAGE: {
      gcpLink += `storage/browser/${recommendation.resource}`;
      return gcpLink;
    }
    default: {
      return gcpLink + "home/dashboard";
    }
  }
}
