import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import { useTheme } from "@emotion/react";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  Operator,
  TimeGranularity,
  UnitType,
} from "@ternary/api-lib/analytics/enums";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import StackedBarChart from "@ternary/api-lib/analytics/ui/StackedBarChart";
import { formatPercentage } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import {
  AWSRateRecommendationTerm,
  AWSSavingsPlanServiceType,
  ServiceType,
} from "@ternary/api-lib/constants/enums";
import {
  AWSRateReservedInstanceRecommendationEntity,
  AWSRateSavingsPlanRecommendationEntity,
} from "@ternary/api-lib/core/types";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { sub } from "date-fns";
import React, { useMemo } from "react";
import {
  DecodedValueMap,
  NumberParam,
  StringParam,
  createEnumParam,
  useQueryParams,
} from "use-query-params";
import useAuthenticatedUser from "../../../../hooks/useAuthenticatedUser";
import { DateHelper } from "../../../../lib/dates";
import useGetDataIntegrationsByTenantID from "../../../admin/hooks/useGetDataIntegrationsByTenantID";
import copyText from "../../copyText";
import useGetAWSCommitmentUsage from "../hooks/useGetAWSCommitmentUsage";
import useGetAWSReservedInstanceRecommendations from "../hooks/useGetAWSReservedInstanceRecommendations";
import useGetAWSSavingsPlanRecommendations from "../hooks/useGetAWSSavingsPlanRecommendations";
import {
  AWSAccountLevel,
  AWSCommitmentUsageDatum,
  AWSCommittedUseDimensions,
  AWSCommittedUseFilter,
  AWSCommittedUseLookbackPeriod,
  AWSCommittedUseMeasures,
  AWSCommittedUseOfferingClass,
  AWSCommittedUsePaymentOption,
  AWSCommittedUseTerm,
  AWSCommittedUseType,
} from "../types";
import { getPayerAccountIDs } from "../utils";
import AWSCommittedUseChart from "./AWSCommittedUseChart";
import AWSCommittedUseFilterControls from "./AWSCommittedUseFilterControls";
import AWSCommittedUseMeter from "./AWSCommittedUseMeter";
import AWSCommittedUseRecommendationsTableControls from "./AWSCommittedUseRecommendationsTableControls";
import AWSReservedInstanceRecommendationTable from "./AWSReservedInstanceRecommendationTable";
import AWSSavingsPlanRecommendationTable from "./AWSSavingsPlanRecommendationTable";

const defaultType = AWSCommittedUseType.SP;

const queryParamConfigMap = {
  type: createEnumParam(Object.values(AWSCommittedUseType)),

  selected_rows: StringParam,
  payer_id: StringParam,
  rec_payer_id: StringParam,

  // FILTERS
  linked_ids: StringParam,
  lookback_p: StringParam,
  acc_lvl: createEnumParam(Object.values(AWSAccountLevel)),
  min_sav: NumberParam,
  min_util: NumberParam,
  o_class: StringParam,
  p_option: StringParam,
  ri_service_type: createEnumParam(Object.values(ServiceType)),
  sp_type: createEnumParam(Object.values(AWSSavingsPlanServiceType)),
  term: StringParam,
};

const defaultSavingsPlanRecommendations = [];
const defaultReservedInstanceRecommendations = [];

type Interaction = AWSCommittedUseFilterControls.Interaction;

export default function AWSCommittedUseRecommendationsContainer() {
  const theme = useTheme();
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();

  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);

  const filters = useMemo(
    () => getFiltersFromQueryParams(queryParams),
    [queryParams]
  );

  const dateRange = useMemo(
    () => getDateRangeFromLookback(filters.lookbackPeriod),
    [filters.lookbackPeriod]
  );

  const {
    data: savingsPlanRecommendations = defaultSavingsPlanRecommendations,
    isLoading: isLoadingAwsSavingsPlanRecommendations,
  } = useGetAWSSavingsPlanRecommendations(authenticatedUser.tenant.id);

  const {
    data: reservedInstanceRecommendations = defaultReservedInstanceRecommendations,
    isLoading: isLoadingReservedInstanceRecommendations,
  } = useGetAWSReservedInstanceRecommendations(authenticatedUser.tenant.id);

  const { data: integrations = [], isLoading: isLoadingClouds } =
    useGetDataIntegrationsByTenantID(authenticatedUser.tenant.id);

  const integrationNamesKeyedByIntegrationId = Object.fromEntries(
    integrations.map((integration) => [integration.id, integration.name])
  );
  const committedUseType = queryParams.type ?? defaultType;

  const selectedIDs = getSelectedRecIDs(queryParams);

  // RESERVED INSTANCES
  const filteredRIs = useMemo(
    () => getFilteredRIs(reservedInstanceRecommendations, filters),
    [filters, reservedInstanceRecommendations]
  );
  const unselectedRIs = removeSelectedRecsByIDs(filteredRIs, selectedIDs);
  const unselectedRIsCSVData = getRICSVData(unselectedRIs);
  const selectedRIs = getSelectedRecsByIDs(
    reservedInstanceRecommendations,
    selectedIDs
  );
  const selectedRIsCSVData = getRICSVData(selectedRIs);

  // SAVINGS PLANS
  const filteredSPs = useMemo(
    () => getFilteredSPs(savingsPlanRecommendations, filters),
    [filters, savingsPlanRecommendations]
  );
  const unselectedSPs = removeSelectedRecsByIDs(filteredSPs, selectedIDs);
  const unselectedSPsCSVData = getSavingsCSVData(unselectedSPs);
  const selectedSPs = getSelectedRecsByIDs(
    savingsPlanRecommendations,
    selectedIDs
  );
  const selectedSPsCSVData = getSavingsCSVData(selectedSPs);

  const csvDataPair = getCommittedUseCSVData(committedUseType, {
    selectedRIsCSVData,
    unselectedRIsCSVData,
    selectedSPsCSVData,
    unselectedSPsCSVData,
  });

  const payerAccountIDs = getPayerAccountIDs(reservedInstanceRecommendations);
  const selectedPayerAccountID = queryParams.rec_payer_id ?? null;
  if (selectedPayerAccountID === null && payerAccountIDs.length > 0) {
    setQueryParams({ rec_payer_id: payerAccountIDs[0] }, "replaceIn");
  }
  const dailyNormalizedRIHoursInCart = selectedRIs.reduce(
    (sum, ri) =>
      sum +
      (ri.serviceType === ServiceType.OPEN_SEARCH
        ? ri.estimatedOnDemandCost
        : ri.normalizedUnitsToPurchase * 24),
    0
  );

  const dailySPCommitmentCostInCart = selectedSPs.reduce(
    (sum, sp) => sum + sp.hourlyCommitmentToPurchase * 24,
    0
  );

  const {
    data: awsCommitmentUsageData = [],
    isLoading: isLoadingAWSCommitmentUsageData,
  } = useGetAWSCommitmentUsage(
    {
      dateRange: dateRange,
      granularity: TimeGranularity.DAY,
      queryFilters: getAccountQueryFilters(
        selectedPayerAccountID,
        filters.linkedAccountIDs
      ),
    },
    { enabled: !!selectedPayerAccountID }
  );

  const chartData = useMemo(
    () =>
      committedUseType === AWSCommittedUseType.RI
        ? getRIChartData(
            awsCommitmentUsageData,
            dailyNormalizedRIHoursInCart,
            filters.serviceType
          )
        : getSPChartData(awsCommitmentUsageData, dailySPCommitmentCostInCart),
    [
      awsCommitmentUsageData,
      committedUseType,
      dailyNormalizedRIHoursInCart,
      dailySPCommitmentCostInCart,
      filters.serviceType,
    ]
  );

  function toggleRecSelection(rec: { id: string }) {
    const nextSelectedIDs = selectedIDs.includes(rec.id)
      ? selectedIDs.filter((id) => id !== rec.id)
      : [rec.id, ...selectedIDs];
    setQueryParams({ selected_rows: nextSelectedIDs.join(",") });
  }

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case AWSCommittedUseFilterControls.INTERACTION_CHANGE_LOOKBACK_PERIOD:
        setQueryParams({
          selected_rows: null,
          lookback_p:
            interaction.lookbackPeriod !== defaultFilters.lookbackPeriod
              ? interaction.lookbackPeriod
              : null,
        });
        break;
      case AWSCommittedUseFilterControls.INTERACTION_CHANGE_MIN_SAVINGS:
        setQueryParams({
          min_sav:
            interaction.minSavings !== defaultFilters.minSavings
              ? interaction.minSavings
              : null,
        });
        break;
      case AWSCommittedUseFilterControls.INTERACTION_CHANGE_MIN_UTIL:
        setQueryParams({
          min_util:
            interaction.minUtil !== defaultFilters.minUtil
              ? interaction.minUtil
              : null,
        });
        break;
      case AWSCommittedUseFilterControls.INTERACTION_CHANGE_OFFERING_CLASS:
        setQueryParams({
          selected_rows: null,
          o_class:
            interaction.offeringClass !== defaultFilters.offeringClass
              ? interaction.offeringClass
              : null,
        });
        break;
      case AWSCommittedUseFilterControls.INTERACTION_CHANGE_PAYER_ACCOUNT_ID:
        setQueryParams({
          linked_ids: null,
          payer_id: interaction.accountID,
          rec_payer_id: interaction.accountID,
          selected_rows: null,
        });
        break;
      case AWSCommittedUseFilterControls.INTERACTION_CHANGE_PAYMENT_OPTION:
        setQueryParams({
          selected_rows: null,
          p_option:
            interaction.paymentOption !== defaultFilters.paymentOption
              ? interaction.paymentOption
              : null,
        });
        break;
      case AWSCommittedUseFilterControls.INTERACTION_CHANGE_ACCOUNT_SCOPE:
        setQueryParams({
          ...(interaction.accountScope === AWSAccountLevel.PAYER
            ? { linked_ids: null }
            : {}),
          selected_rows: null,
          acc_lvl:
            interaction.accountScope !== defaultFilters.accountScope
              ? interaction.accountScope
              : null,
        });
        break;
      case AWSCommittedUseFilterControls.INTERACTION_CHANGE_SAVINGS_PLAN_TYPE:
        activityTracker.captureAction(
          actions.SELECT_CUD_AWS_SAVINGS_PLAN_TYPE,
          {
            item: interaction.savingsPlanType,
          }
        );
        setQueryParams({
          selected_rows: null,
          sp_type:
            interaction.savingsPlanType !== defaultFilters.savingsPlanType
              ? interaction.savingsPlanType
              : null,
        });
        break;
      case AWSCommittedUseFilterControls.INTERACTION_CHANGE_TERM:
        setQueryParams({
          selected_rows: null,
          term:
            interaction.term !== defaultFilters.term ? interaction.term : null,
        });
        break;
      case AWSCommittedUseFilterControls.INTERACTION_CHANGE_TYPE:
        setQueryParams({
          selected_rows: null,
          linked_ids: null,
          type:
            interaction.committedUseType !== defaultType
              ? interaction.committedUseType
              : null,
        });
        break;
      case AWSCommittedUseFilterControls.INTERACTION_CHANGE_SERVICE_TYPE:
        activityTracker.captureAction(actions.SELECT_CUD_AWS_SERVICE_TYPE, {
          item: interaction.serviceType,
        });
        setQueryParams({
          selected_rows: null,
          linked_ids: null,
          ri_service_type:
            interaction.serviceType !== defaultFilters.serviceType
              ? interaction.serviceType
              : null,
        });
        break;
      default:
        break;
    }
  }

  const isLoading =
    isLoadingClouds ||
    isLoadingAwsSavingsPlanRecommendations ||
    isLoadingReservedInstanceRecommendations ||
    isLoadingAWSCommitmentUsageData;
  return (
    <Box>
      <Box marginBottom={theme.space_lg}>
        <AWSCommittedUseFilterControls
          cloudNamesKeyedByCloudID={integrationNamesKeyedByIntegrationId}
          committedUseType={committedUseType}
          filters={filters}
          isLoading={isLoading}
          reservedInstanceRecommendations={reservedInstanceRecommendations}
          savingsPlanRecommendations={savingsPlanRecommendations}
          onInteraction={handleInteraction}
        />
      </Box>

      <Box borderRadius={theme.borderRadius_1} marginBottom={theme.space_lg}>
        <AWSCommittedUseMeter
          committedUseType={committedUseType}
          isLoading={isLoading}
          reservedInstanceRecommendations={filteredRIs}
          savingsPlanRecommendations={filteredSPs}
        />
      </Box>

      <Flex height={500} marginBottom={theme.space_lg}>
        <Flex
          direction="column"
          padding={theme.space_sm}
          borderRadius={theme.borderRadius_1}
          backgroundColor={theme.panel_backgroundColor}
          paddingBottom={theme.space_md}
          width={`calc(50% - (${theme.space_lg} / 2))`}
        >
          <Text fontSize={theme.h3_fontSize} marginBottom={theme.space_sm}>
            {copyText.awsChartTitleUsage}
          </Text>
          <Box flex="1 0 0">
            {committedUseType === AWSCommittedUseType.RI ? (
              <AWSCommittedUseChart
                committedUseType={AWSCommittedUseType.RI}
                data={chartData}
                hasEstimated={dailyNormalizedRIHoursInCart > 0}
                isLoading={isLoading}
                serviceType={filters.serviceType}
              />
            ) : (
              <AWSCommittedUseChart
                committedUseType={AWSCommittedUseType.SP}
                data={chartData}
                hasEstimated={dailySPCommitmentCostInCart > 0}
                isLoading={isLoading}
                serviceType={filters.serviceType}
              />
            )}
          </Box>
        </Flex>

        <Box width={theme.space_lg} />

        <Flex
          direction="column"
          padding={theme.space_sm}
          borderRadius={theme.borderRadius_1}
          backgroundColor={theme.panel_backgroundColor}
          paddingBottom={theme.space_md}
          width={`calc(50% - (${theme.space_lg} / 2))`}
        >
          <Text fontSize={theme.h3_fontSize} marginBottom={theme.space_sm}>
            {copyText.awsChartTitleCost}
          </Text>
          <Box flex="1 0 0">
            <StackedBarChart
              clustered
              data={awsCommitmentUsageData}
              dimensions={[]}
              isLoading={isLoading}
              readableKeys={readableKeys}
              measures={[
                {
                  name:
                    committedUseType === AWSCommittedUseType.RI
                      ? "riCoveredUsageCost"
                      : "spCoveredUsageCost",
                  unit: UnitType.CURRENCY,
                },
                { name: "onDemandCost", unit: UnitType.CURRENCY },
              ].map((measure) => ({
                ...measure,
                name: measure.name,
              }))}
              showLegend
              showTooltip
              timeSeriesGranularity={TimeGranularity.DAY}
              xAxisKey="timestamp"
            />
          </Box>
        </Flex>
      </Flex>

      {/* CART */}
      {selectedIDs.length > 0 && (
        <Box marginBottom={theme.space_lg}>
          <Box>
            <Text fontSize={theme.h3_fontSize} marginBottom={theme.space_sm}>
              {copyText.awsTableTitlePurchaseSelections}
            </Text>
          </Box>

          <Box overflowX="auto">
            <Box
              padding={theme.space_sm}
              borderRadius={theme.borderRadius_1}
              backgroundColor={theme.panel_backgroundColor}
              marginBottom={theme.space_md}
            >
              <AWSCommittedUseRecommendationsTableControls
                csvData={csvDataPair.selectedCSV}
              />
            </Box>
            <Box overflowX="auto">
              {committedUseType === AWSCommittedUseType.RI ? (
                <AWSReservedInstanceRecommendationTable
                  buttonIcon={<Icon icon={faMinus} />}
                  isLoading={isLoading}
                  accountScope={filters.accountScope}
                  serviceType={filters.serviceType}
                  reservedInstanceRecommendations={selectedRIs}
                  onSelectRecommendation={toggleRecSelection}
                />
              ) : (
                <AWSSavingsPlanRecommendationTable
                  buttonIcon={<Icon icon={faMinus} />}
                  isLoading={isLoading}
                  accountScope={filters.accountScope}
                  savingsPlanRecommendations={selectedSPs}
                  onSelectRecommendation={toggleRecSelection}
                />
              )}
            </Box>
          </Box>
        </Box>
      )}

      {/* OPTIONS */}
      <Box marginBottom={theme.space_lg}>
        <Box>
          <Text fontSize={theme.h3_fontSize} marginBottom={theme.space_sm}>
            {copyText.awsTableTitleRecommendations}
          </Text>
        </Box>

        <Box overflowX="auto">
          <Box
            padding={theme.space_sm}
            borderRadius={theme.borderRadius_1}
            backgroundColor={theme.panel_backgroundColor}
            marginBottom={theme.space_md}
          >
            <AWSCommittedUseRecommendationsTableControls
              csvData={csvDataPair.unselectedCSV}
            />
          </Box>
          <Box overflowX="auto">
            {committedUseType === AWSCommittedUseType.RI ? (
              <AWSReservedInstanceRecommendationTable
                buttonIcon={<Icon icon={faPlus} />}
                isLoading={isLoading}
                accountScope={filters.accountScope}
                serviceType={filters.serviceType}
                reservedInstanceRecommendations={unselectedRIs}
                onSelectRecommendation={toggleRecSelection}
              />
            ) : (
              <AWSSavingsPlanRecommendationTable
                buttonIcon={<Icon icon={faPlus} />}
                isLoading={isLoading}
                accountScope={filters.accountScope}
                savingsPlanRecommendations={unselectedSPs}
                onSelectRecommendation={toggleRecSelection}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const csvRIAccessors = [
  AWSCommittedUseMeasures.estimatedMonthlySavingsAmount,
  AWSCommittedUseMeasures.estimatedMonthlySavingsPercentage,
  AWSCommittedUseMeasures.upfrontCost,
  AWSCommittedUseMeasures.recurringStandardMonthlyCost,
  AWSCommittedUseMeasures.totalCost,
  AWSCommittedUseMeasures.averageUtilization,
  AWSCommittedUseMeasures.recommendedNumberOfInstancesToPurchase,
  AWSCommittedUseMeasures.normalizedUnitsToPurchase,
  AWSCommittedUseMeasures.instanceSize,
  AWSCommittedUseDimensions.family,
  AWSCommittedUseDimensions.region,
  AWSCommittedUseDimensions.tenancy,
  AWSCommittedUseDimensions.platform,
  AWSCommittedUseMeasures.estimatedBreakEvenInMonths,
];

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

function getRICSVData(
  data: AWSRateReservedInstanceRecommendationEntity[]
): CSVData {
  if (!data.length) {
    return { headers: [], rows: [] };
  }

  const rows = data.map((datum) => {
    const termMultiplyer =
      datum.term === AWSRateRecommendationTerm.ONE_YEAR ? 12 : 36;
    return {
      estimatedMonthlySavingsAmount: datum.estimatedMonthlySavingsAmount,
      estimatedMonthlySavingsPercentage: formatPercentage(
        datum.estimatedMonthlySavingsAmount / datum.estimatedOnDemandCost
      ),
      upfrontCost: datum.upfrontCost,
      recurringStandardMonthlyCost: datum.recurringStandardMonthlyCost,
      totalCost:
        (datum.recurringStandardMonthlyCost + datum.upfrontCost) *
        termMultiplyer,
      averageUtilization: datum.averageUtilization,
      normalizedUnitsToPurchase: datum.normalizedUnitsToPurchase,
      recommendedNumberOfInstancesToPurchase:
        datum.recommendedNumberOfInstancesToPurchase,
      instanceSize: datum.instanceSize ?? "",
      family: datum.family ?? "",
      region: datum.region ?? "",
      tenancy: datum.tenancy ?? "",
      platform: datum.platform ?? "",
      estimatedBreakEvenInMonths: datum.estimatedBreakEvenInMonths,
    };
  });

  const headers = csvRIAccessors.map((accessor) => {
    // ensure rows has a value for each accessor
    const dataKey: keyof (typeof rows)[number] = accessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `awsRITableHeader_${accessor}`;
    const label = copyText[copyTextKey];

    return { key: dataKey, label };
  });
  return { headers, rows };
}

const csvSavingsAccessors = [
  AWSCommittedUseMeasures.estimatedMonthlySavingsAmount,
  AWSCommittedUseMeasures.estimatedMonthlySavingsPercentage,
  AWSCommittedUseMeasures.hourlyCommitmentToPurchase,
  AWSCommittedUseMeasures.estimatedSavingsPlanCost,
  AWSCommittedUseMeasures.estimatedOnDemandCost,
  AWSCommittedUseMeasures.currentAverageHourlyOnDemandSpend,
  AWSCommittedUseMeasures.estimatedAverageUtilization,
  AWSCommittedUseMeasures.estimatedROI,
  AWSCommittedUseDimensions.type,
  AWSCommittedUseDimensions.offeringID,
];

function getSavingsCSVData(
  data: AWSRateSavingsPlanRecommendationEntity[]
): CSVData {
  if (!data.length) {
    return { headers: [], rows: [] };
  }

  const rows = data.map((datum) => {
    return {
      estimatedMonthlySavingsAmount: datum.estimatedMonthlySavingsAmount,
      estimatedMonthlySavingsPercentage: formatPercentage(
        datum.estimatedMonthlySavingsAmount / datum.estimatedOnDemandCost
      ),
      hourlyCommitmentToPurchase: datum.hourlyCommitmentToPurchase,
      estimatedSavingsPlanCost: datum.estimatedSavingsPlanCost,
      estimatedOnDemandCost: datum.estimatedOnDemandCost,
      currentAverageHourlyOnDemandSpend:
        datum.currentAverageHourlyOnDemandSpend,
      estimatedAverageUtilization: datum.estimatedAverageUtilization,
      estimatedROI: datum.estimatedROI,
      type: datum.savingsPlanType,
      offeringID: datum.offeringID,
    };
  });

  const headers = csvSavingsAccessors.map((accessor) => {
    // ensure rows has a value for each accessor
    const dataKey: keyof (typeof rows)[number] = accessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `awsSPTableHeader_${accessor}`;
    const label = copyText[copyTextKey];

    return { key: dataKey, label };
  });
  return { headers, rows };
}

type CSVDataPair = {
  selectedCSV: CSVData;
  unselectedCSV: CSVData;
};

function getCommittedUseCSVData(
  committedUseType: AWSCommittedUseType,
  {
    selectedRIsCSVData,
    unselectedRIsCSVData,
    selectedSPsCSVData,
    unselectedSPsCSVData,
  }: {
    selectedRIsCSVData: CSVData;
    unselectedRIsCSVData: CSVData;
    selectedSPsCSVData: CSVData;
    unselectedSPsCSVData: CSVData;
  }
): CSVDataPair {
  return committedUseType === AWSCommittedUseType.RI
    ? {
        selectedCSV: selectedRIsCSVData,
        unselectedCSV: unselectedRIsCSVData,
      }
    : {
        selectedCSV: selectedSPsCSVData,
        unselectedCSV: unselectedSPsCSVData,
      };
}

const readableKeys: Record<string, string> = {
  onDemandCost: copyText.awsReadableKey_onDemandCost,
  riCoveredUsageCost: copyText.awsReadableKey_riDailyCost,
  riUnusedCommitmentCost: copyText.awsReadableKey_riUnusedCommitmentCost,
  spCoveredUsageCost: copyText.awsReadableKey_spDailyCost,
};

export const defaultFilters: AWSCommittedUseFilter = {
  accountScope: AWSAccountLevel.PAYER,
  linkedAccountIDs: [],
  lookbackPeriod: AWSCommittedUseLookbackPeriod.THIRTY_DAYS,
  minSavings: 0,
  minUtil: 0,
  offeringClass: AWSCommittedUseOfferingClass.STANDARD,
  payerAccountID: "",
  paymentOption: AWSCommittedUsePaymentOption.NO_UPFRONT,
  savingsPlanType: AWSSavingsPlanServiceType.CSP,
  serviceType: ServiceType.EC2,
  term: AWSCommittedUseTerm.ONE_YEAR,
};

function getFiltersFromQueryParams(
  params: DecodedValueMap<typeof queryParamConfigMap>
): AWSCommittedUseFilter {
  return {
    accountScope: params.acc_lvl ?? defaultFilters.accountScope,
    linkedAccountIDs: params.linked_ids
      ? params.linked_ids.split(",")
      : defaultFilters.linkedAccountIDs,
    lookbackPeriod: params.lookback_p ?? defaultFilters.lookbackPeriod,
    minSavings: params.min_sav ?? defaultFilters.minSavings,
    minUtil: params.min_util ?? defaultFilters.minUtil,
    offeringClass: params.o_class ?? defaultFilters.offeringClass,
    payerAccountID: params.rec_payer_id
      ? params.rec_payer_id
      : defaultFilters.payerAccountID,
    paymentOption: params.p_option ?? defaultFilters.paymentOption,
    savingsPlanType: params.sp_type ?? defaultFilters.savingsPlanType,
    serviceType: params.ri_service_type ?? defaultFilters.serviceType,
    term: params.term ?? defaultFilters.term,
  };
}

function getSelectedRecIDs(
  params: DecodedValueMap<typeof queryParamConfigMap>
) {
  return params.selected_rows ? params.selected_rows.split(",") : [];
}

function getSelectedRecsByIDs<Rec extends { id: string }>(
  recs: Rec[],
  ids: string[]
): Rec[] {
  const idsSet = Object.fromEntries(ids.map((id) => [id, true]));
  return recs.filter((rec) => !!idsSet[rec.id]);
}

function removeSelectedRecsByIDs<Rec extends { id: string }>(
  recs: Rec[],
  ids: string[]
): Rec[] {
  const idsSet = Object.fromEntries(ids.map((id) => [id, true]));
  return recs.filter((rec) => !idsSet[rec.id]);
}

function getFilteredRIs(
  ris: AWSRateReservedInstanceRecommendationEntity[],
  filter: AWSCommittedUseFilter
) {
  const linkedAccountIDsSet = Object.fromEntries(
    filter.linkedAccountIDs.map((id) => [id, true])
  );

  return ris.filter((ri) => {
    if (
      ri.accountID !== null &&
      filter.linkedAccountIDs.length > 0 &&
      !linkedAccountIDsSet[ri.accountID]
    ) {
      return false;
    }

    if (ri.cloudID !== filter.payerAccountID) {
      return false;
    }

    if (ri.estimatedMonthlySavingsAmount < filter.minSavings) {
      return false;
    }

    if (ri.averageUtilization < filter.minUtil) {
      return false;
    }

    if (
      ri.offeringClass !== null &&
      filter.offeringClass.toUpperCase() !== ri.offeringClass.toUpperCase()
    ) {
      return false;
    }

    if (filter.paymentOption.toUpperCase() !== ri.paymentOption.toUpperCase()) {
      return false;
    }

    if (filter.accountScope.toUpperCase() !== ri.accountScope.toUpperCase()) {
      return false;
    }

    if (filter.serviceType.toUpperCase() !== ri.serviceType.toUpperCase()) {
      return false;
    }

    if (filter.term.toUpperCase() !== ri.term.toUpperCase()) {
      return false;
    }

    if (
      filter.lookbackPeriod.toUpperCase() !== ri.lookbackPeriod.toUpperCase()
    ) {
      return false;
    }

    return true;
  });
}

function getFilteredSPs(
  sps: AWSRateSavingsPlanRecommendationEntity[],
  filter: AWSCommittedUseFilter
) {
  const linkedAccountIDsSet = Object.fromEntries(
    filter.linkedAccountIDs.map((id) => [id, true])
  );
  return sps.filter((sp) => {
    if (
      sp.accountID !== null &&
      filter.linkedAccountIDs.length > 0 &&
      !linkedAccountIDsSet[sp.accountID]
    ) {
      return false;
    }

    if (sp.cloudID !== filter.payerAccountID) {
      return false;
    }

    if (sp.estimatedSavingsPercentage < filter.minSavings) {
      return false;
    }

    if (sp.estimatedAverageUtilization < filter.minUtil) {
      return false;
    }

    if (filter.paymentOption.toUpperCase() !== sp.paymentOption.toUpperCase()) {
      return false;
    }

    if (filter.accountScope.toUpperCase() !== sp.accountScope.toUpperCase()) {
      return false;
    }

    if (
      filter.savingsPlanType.toUpperCase() !== sp.savingsPlanType.toUpperCase()
    ) {
      return false;
    }

    if (filter.term.toUpperCase() !== sp.term.toUpperCase()) {
      return false;
    }

    if (
      filter.lookbackPeriod.toUpperCase() !== sp.lookbackPeriod.toUpperCase()
    ) {
      return false;
    }

    return true;
  });
}

function getDateRangeFromLookback(lookback: string) {
  const now = new DateHelper();

  switch (lookback) {
    case AWSCommittedUseLookbackPeriod.SIXTY_DAYS:
      return [sub(now.date, { days: 60 }), now.date];
    case AWSCommittedUseLookbackPeriod.THIRTY_DAYS:
      return [sub(now.date, { days: 30 }), now.date];
    case AWSCommittedUseLookbackPeriod.FOURTEEN_DAYS:
      return [sub(now.date, { days: 14 }), now.date];
    case AWSCommittedUseLookbackPeriod.SEVEN_DAYS:
      return [sub(now.date, { days: 7 }), now.date];
    default:
      return [sub(now.date, { days: 30 }), now.date];
  }
}

function getRIChartData(
  data: AWSCommitmentUsageDatum[],
  estimatedAmount: number,
  serviceType: ServiceType
) {
  const isCurrency = serviceType === ServiceType.OPEN_SEARCH;
  return data.map((datum) => {
    const covered = isCurrency
      ? datum.riCoveredUsageCost
      : datum.riCoveredNormalizedUsageHours;
    const usage = isCurrency
      ? datum.onDemandCost + datum.riCoveredUsageCost
      : datum.onDemandNormalizedUsageHours +
        datum.riCoveredNormalizedUsageHours;
    return {
      covered,
      estimated: estimatedAmount,
      usage,
      timestamp: datum.timestamp,
    };
  });
}

function getSPChartData(
  data: AWSCommitmentUsageDatum[],
  estimatedAmount: number
) {
  return data.map((datum) => ({
    covered: datum.spCoveredUsageCost,
    estimated: estimatedAmount,
    usage: datum.onDemandCost + datum.spCoveredUsageCost,
    timestamp: datum.timestamp,
  }));
}

function getAccountQueryFilters(
  payerAccountID: string | null,
  linkedAccountIDs: string[] | null
): QueryFilter[] {
  const filters: QueryFilter[] = [];

  if (payerAccountID) {
    filters.push({
      name: "cloudId",
      operator: Operator.EQUALS,
      values: [payerAccountID],
    });
  }

  if (linkedAccountIDs && linkedAccountIDs.length > 0) {
    filters.push({
      name: "linkedAccountID",
      operator: Operator.EQUALS,
      values: [...linkedAccountIDs],
    });
  }

  return filters;
}
