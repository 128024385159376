import { format as dateFnsFormat } from "date-fns";
import { TimeGranularity } from "../enums";

const fiscalDateRegex = /^FY\d{4}(-Q\d|-M\d{1,2}|-W\d{1,2}){0,1}$/;

export function isFiscalDate(timestamp: string) {
  return timestamp.search(fiscalDateRegex) === 0;
}

export function formatDate(date: number | Date, format: string): string {
  return dateFnsFormat(date, format);
}

export function getFormatForGranularity(
  timeGranularity?: TimeGranularity | null
): string {
  switch (timeGranularity) {
    case TimeGranularity.YEAR:
      return "yyyy";
    case TimeGranularity.MONTH:
      return "MM/yyyy";
    case TimeGranularity.WEEK:
    case TimeGranularity.DAY:
      return "MM/dd";
    case TimeGranularity.HOUR:
    case TimeGranularity.MINUTE:
      return "MM/dd/yyyy hh:mm a";
    case TimeGranularity.SECOND:
      return "MM/dd/yyyy hh:mm::ss a";
    default:
      return "yyyy/MM/dd";
  }
}
