import { useTheme } from "@emotion/react";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import {
  add,
  addMonths,
  addYears,
  format,
  startOfMonth,
  startOfYear,
  sub,
} from "date-fns";
import React, { forwardRef, useEffect, useState } from "react";
import ReactDatePicker, { DatePickerProps } from "react-datepicker";
import TextInput, { Size, Props as TextInputProps } from "../TextInput";
import { DatePickerWrapper } from "./styled";

// TODO: Remove forwardRef when upgrading to react 19.
const InputRef = forwardRef<HTMLInputElement, TextInputProps>((props, _) => {
  return <TextInput {...props} />;
});

interface Props {
  dayClassName?: DatePickerProps["dayClassName"];
  dateFormat?: DatePickerProps["dateFormat"];
  endDate?: DatePickerProps["endDate"];
  disabled?: DatePickerProps["disabled"];
  inline?: DatePickerProps["inline"];
  isClearable?: DatePickerProps["isClearable"];
  maxDate?: DatePickerProps["maxDate"];
  minDate?: DatePickerProps["minDate"];
  openToDate?: DatePickerProps["openToDate"];
  placeholderText?: DatePickerProps["placeholderText"];
  popperModifiers?: DatePickerProps["popperModifiers"];
  popperPlacement?: DatePickerProps["popperPlacement"];
  select?: "start" | "end";
  selected?: DatePickerProps["selected"];
  shouldCloseOnSelect?: DatePickerProps["shouldCloseOnSelect"];
  showMonthYearPicker?: DatePickerProps["showMonthYearPicker"];
  showTimeSelect?: DatePickerProps["showTimeSelect"];
  showTimeSelectOnly?: DatePickerProps["showTimeSelectOnly"];
  showYearPicker?: DatePickerProps["showYearPicker"];
  size?: Size;
  startDate?: DatePickerProps["startDate"];
  timeCaption?: DatePickerProps["timeCaption"];
  timeIntervals?: DatePickerProps["timeIntervals"];
  timeSelectWidth?: string;
  onChange: (date: Date | null) => void;
  onClick?: () => void;
}

export function DatePicker(props: Props): JSX.Element {
  const [selectState, setSelect] = useState(props.select);

  useEffect(() => {
    setSelect(props.select);
  }, [props.select]);

  // Remove props that are specific to our DatePicker component
  const { select, size, timeSelectWidth, onChange, onClick, ...restProps } =
    props;

  // Construct the props that are used for ReactDatePicker
  const datePickerProps = { dateFormat: "MM/dd/yyyy", ...restProps };

  return (
    <DatePickerWrapper
      dateFormat={datePickerProps.dateFormat}
      hasStart={!!datePickerProps.startDate}
      hasEnd={!!datePickerProps.endDate}
      timeSelectWidth={timeSelectWidth}
      onClick={onClick}
    >
      <ReactDatePicker
        renderCustomHeader={(params) => (
          <CustomHeader
            {...params}
            maxDate={datePickerProps.maxDate}
            showMonthYearPicker={datePickerProps.showMonthYearPicker}
            showYearPicker={datePickerProps.showYearPicker}
          />
        )}
        allowSameDay
        customInput={<InputRef size={size} />}
        selectsEnd={select ? selectState === "end" : undefined}
        selectsStart={select ? selectState === "start" : undefined}
        onChange={(date) => onChange(date)}
        onDayMouseEnter={() => setSelect(select)}
        onMonthMouseLeave={() => setSelect(undefined)}
        {...datePickerProps}
      />
    </DatePickerWrapper>
  );
}

interface CustomHeaderProps {
  date: Date;
  maxDate?: Date | null;
  decreaseMonth: () => void;
  decreaseYear: () => void;
  increaseMonth: () => void;
  increaseYear: () => void;
  changeYear: (year: number) => void;
  showMonthYearPicker?: boolean;
  showYearPicker?: boolean;
}

function CustomHeader(props: CustomHeaderProps): JSX.Element {
  const theme = useTheme();

  function handleClickLeft() {
    if (props.showYearPicker) {
      props.changeYear(Number(format(sub(props.date, { years: 12 }), "yyyy")));
      return;
    }

    if (props.showMonthYearPicker) {
      props.decreaseYear();
      return;
    }

    props.decreaseMonth();
  }

  function handleClickRight() {
    if (props.showYearPicker) {
      props.changeYear(Number(format(add(props.date, { years: 12 }), "yyyy")));
      return;
    }

    if (props.showMonthYearPicker) {
      props.increaseYear();
      return;
    }

    props.increaseMonth();
  }

  const canClickRight = props.maxDate
    ? props.showMonthYearPicker
      ? startOfYear(addYears(props.date, 1)) <= props.maxDate
      : startOfMonth(addMonths(props.date, 1)) <= props.maxDate
    : true;

  return (
    <Flex
      alignItems="center"
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_2}
      justifyContent="space-between"
      padding={theme.space_sm}
    >
      <Button
        iconEnd={<Icon icon={faChevronLeft} />}
        size="tiny"
        type="button"
        onClick={handleClickLeft}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
      />
      <Text>
        {formatDate(
          props.date,
          props.showMonthYearPicker ? "yyyy" : "MMMM yyyy"
        )}
      </Text>
      <Button
        disabled={!canClickRight}
        iconEnd={<Icon icon={faChevronRight} />}
        size="tiny"
        type="button"
        onClick={handleClickRight}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
      />
    </Flex>
  );
}
