import { useTheme } from "@emotion/react";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/web-ui-lib/components/Flex";
import React, { useState } from "react";
import DualListbox from "../../../ui-lib/components/DualListBox";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import Modal from "../../../ui-lib/components/Modal";
import copyText from "../copyText";
import { User } from "../types";

interface Props {
  assigneeIDs: string[];
  isOpen: boolean;
  isProcessing: boolean;
  users: User[];
  onInteraction: (interaction: AssigneeModal.Interaction) => void;
}

export function AssigneeModal(props: Props): JSX.Element {
  const theme = useTheme();

  const [assigneesIDs, setAssigneesIDs] = useState(props.assigneeIDs);

  const options = props.users.map((user) => ({
    label: user.email,
    value: user.id,
  }));

  return (
    <Modal
      closeOnClickOutside={false}
      isOpen={props.isOpen}
      onClose={() => {
        props.onInteraction({
          type: AssigneeModal.INTERACTION_CLOSE_BUTTON_CLICKED,
        });
      }}
    >
      <Modal.Body>
        <Flex
          height={400}
          width={650}
          padding={`${theme.space_xl} ${theme.space_md}`}
        >
          <DualListbox
            options={options}
            selectedOptions={assigneesIDs}
            onChange={(userIDs) => setAssigneesIDs(userIDs)}
          />
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Flex justifyContent="flex-end">
          <Button
            marginRight={theme.space_sm}
            secondary
            width={100}
            onClick={() => {
              setAssigneesIDs(props.assigneeIDs);
              props.onInteraction({
                type: AssigneeModal.INTERACTION_CLOSE_BUTTON_CLICKED,
              });
            }}
          >
            {copyText.cancelButtonLabel}
          </Button>
          <Button
            disabled={props.isProcessing}
            primary
            width={100}
            onClick={() =>
              props.onInteraction({
                type: AssigneeModal.INTERACTION_UPDATE_BUTTON_CLICKED,
                assigneeIDs: assigneesIDs,
              })
            }
          >
            {props.isProcessing ? (
              <LoadingSpinner />
            ) : (
              copyText.submitButtonLabel
            )}
          </Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}

AssigneeModal.INTERACTION_CLOSE_BUTTON_CLICKED =
  `AssigneeModal.INTERACTION_CLOSE_BUTTON_CLICKED` as const;

AssigneeModal.INTERACTION_UPDATE_BUTTON_CLICKED =
  `AssigneeModal.INTERACTION_UPDATE_BUTTON_CLICKED` as const;

interface InteractionCloseButtonClicked {
  type: typeof AssigneeModal.INTERACTION_CLOSE_BUTTON_CLICKED;
}

interface InteractionUpdateButtonClicked {
  type: typeof AssigneeModal.INTERACTION_UPDATE_BUTTON_CLICKED;
  assigneeIDs: string[];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AssigneeModal {
  export type Interaction =
    | InteractionCloseButtonClicked
    | InteractionUpdateButtonClicked;
}

export default AssigneeModal;
