import { useQuery } from "@tanstack/react-query";
import DataQuery from "@ternary/api-lib/analytics/api/DataQuery";
import DataQueryExperimental from "@ternary/api-lib/analytics/api/DataQueryExperimental";
import {
  COMPARISON_KEY,
  FORECASTED_KEY,
} from "@ternary/api-lib/analytics/constants";
import {
  DataSource,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/analytics/enums";
import { RawValue } from "@ternary/api-lib/analytics/types";
import { ANALYTICS_QUERY_GC_TIME } from "../../../constants";
import { useAnalyticsApiClient } from "../../../context/AnalyticsQueryLoaderProvider";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { DateHelper } from "../../../lib/dates";
import { getLastInvoiceMonth } from "../../../utils/dates";

export function useGetCostTableData(params: {
  lookBackDate?: Date;
  dimensions: string[];
  measure: string;
  vendor: string | undefined;
}) {
  const authenticatedUser = useAuthenticatedUser();
  const client = useAnalyticsApiClient();
  const gatekeeper = useGatekeeper();

  const dateHelper = new DateHelper();

  const enabled = gatekeeper.hasConfiguredDataIntegration;

  const tenantID = authenticatedUser.tenant.fsDocID;

  return useQuery({
    queryKey: ["forecastingCostTable", params],
    queryFn: async () => {
      //
      // MTD
      //

      const currentMTD = new DataQuery({
        dataSource: DataSource.BILLING,
        dateRange: [dateHelper.firstOfMonth(), dateHelper.date],
        dimensions: params.dimensions,
        measures: [params.measure],
        ...(params.vendor
          ? {
              preAggFilters: [
                {
                  name: "vendor",
                  operator: Operator.EQUALS,
                  values: [params.vendor],
                },
              ],
            }
          : {}),
      });

      const currentMTDResult = await client.loadData(tenantID, currentMTD);

      //
      // Forecasted MTD
      //

      const currentMtdForecastedSpend = new DataQueryExperimental({
        dataSource: DataSource.BILLING,
        dateRange: params.lookBackDate
          ? [params.lookBackDate, dateHelper.date]
          : [dateHelper.firstOfLastMonth(), dateHelper.date],
        dimensions: params.dimensions,
        measures: [params.measure],
        granularity: TimeGranularity.DAY,
        forecasting: {
          algorithm: "linear-regression",
          dateRange: [dateHelper.date, dateHelper.lastDayThisMonth()],
          measure: params.measure,
        },
        ...(params.vendor
          ? {
              preAggFilters: [
                {
                  name: "vendor",
                  operator: Operator.EQUALS,
                  values: [params.vendor],
                },
              ],
            }
          : {}),
      });

      const _currentMtdForecastedSpendResult =
        await client.loadDataExperimental(tenantID, currentMtdForecastedSpend);

      const currentMtdForecastedSpendResult = Object.values(
        (_currentMtdForecastedSpendResult.forecast ?? []).reduce(
          (accum: Record<string, { [key: string]: RawValue }>, entry) => {
            const key = params.dimensions
              .map((dimension) => entry[dimension])
              .join("_");

            const value = Number(entry[params.measure] ?? 0);

            if (accum[key]) {
              delete entry[params.measure];

              accum[key] = {
                ...accum[key],
                [`${params.measure}${FORECASTED_KEY}`]:
                  Number(
                    accum[key][`${params.measure}${FORECASTED_KEY}`] ?? 0
                  ) + value,
              };
            } else {
              delete entry[params.measure];

              accum[key] = {
                ...entry,
                [`${params.measure}${FORECASTED_KEY}`]: value,
              };
            }

            return accum;
          },
          {}
        )
      );

      //
      // Last Month Total
      //

      const lastMonthTotal = new DataQuery({
        dataSource: DataSource.BILLING,
        dateRange: params.lookBackDate
          ? [
              new DateHelper(
                params.lookBackDate
              ).firstOfLastMonthMinusThreeDays(),
              dateHelper.lastDayLastMonthPlusThreeDays(),
            ]
          : [
              dateHelper.firstOfLastMonthMinusThreeDays(),
              dateHelper.lastDayLastMonthPlusThreeDays(),
            ],
        dimensions: params.dimensions,
        measures: [params.measure],
        preAggFilters: [
          {
            name: "invoiceMonth",
            operator: Operator.EQUALS,
            values: [getLastInvoiceMonth()],
          },
          ...(params.vendor
            ? [
                {
                  name: "vendor",
                  operator: Operator.EQUALS,
                  values: [params.vendor],
                },
              ]
            : []),
        ],
      });

      const _lastMonthTotalResult = await client.loadData(
        tenantID,
        lastMonthTotal
      );

      const lastMonthTotalResult = _lastMonthTotalResult.response.map(
        (entry) => {
          const value = entry[params.measure];
          delete entry[params.measure];

          return {
            ...entry,
            [`${params.measure}${COMPARISON_KEY}`]: value,
          };
        }
      );

      const combinedCostTableData = Object.values(
        [
          ...currentMTDResult.response,
          ...currentMtdForecastedSpendResult,
          ...lastMonthTotalResult,
        ].reduce(
          (accum: Record<string, { [key: string]: RawValue }>, entry) => {
            const key = params.dimensions
              .map((dimension) => entry[dimension])
              .join("_");

            if (accum[key]) {
              accum[key] = {
                ...accum[key],
                ...entry,
              };
            } else {
              accum[key] = entry;
            }

            return accum;
          },
          {}
        )
      );

      return combinedCostTableData;
    },
    enabled,
    gcTime: ANALYTICS_QUERY_GC_TIME,
  });
}
