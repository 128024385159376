import { useTheme } from "@emotion/react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FormApi } from "@tanstack/react-form";
import { useQueryClient } from "@tanstack/react-query";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import { AlibabaIntegrationEntity } from "@ternary/api-lib/core/types/AlibabaIntegration";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { get, isEqual } from "lodash";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { UpdateAlibabaIntegrationParams } from "../../../../../api/core/types";
import paths from "../../../../../constants/paths";
import { useActivityTracker } from "../../../../../context/ActivityTrackerProvider";
import useAuthenticatedUser from "../../../../../hooks/useAuthenticatedUser";
import { useNavigateWithSearchParams } from "../../../../../lib/react-router";
import { VerticalStepperForm } from "../../../../../ui-lib/components/Stepper";
import { AlertType, postAlert } from "../../../../../utils/alerts";
import queryKeys from "../../../hooks/queryKeys";
import useCreateAlibabaIntegration from "../../../hooks/useCreateAlibabaIntegration";
import useUpdateAlibabaIntegration from "../../../hooks/useUpdateAlibabaIntegration";
import useValidateDataIntegration from "../../../hooks/useValidateDataIntegration";
import copyText from "../../copyText";
import { useGetAlibabaIntegrationByID } from "../../hooks/useGetAlibabaIntegrationByID";
import { AlibabaIntegrationFormGeneral } from "./AlibabaIntegrationFormGeneral";
import AlibabaFormReports from "./AlibabaIntegrationFormReports";
import { defaultValues as _defaultValues } from "./constants";
import { CostAndUsageReport, FormData } from "./types";

export function AlibabaIntegrationFormContainer() {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const location = useLocation();
  const navigate = useNavigateWithSearchParams();
  const queryClient = useQueryClient();
  const theme = useTheme();

  const { integrationID } = useParams();

  //
  // State
  //

  const alibabaIntegrationLocation: AlibabaIntegrationEntity | undefined = get(
    location.state,
    "integration"
  );

  //
  // Queries
  //

  // If coming from a fresh session we need to fetch the integration from the server,
  // because it won't exist on location state.
  const {
    data: alibabaIntegrationServer,
    isLoading: isLoadingAlibabaIntegration,
  } = useGetAlibabaIntegrationByID(integrationID ?? "", {
    enabled: !!integrationID && !alibabaIntegrationLocation,
  });

  //
  // Mutations
  //

  const {
    isPending: isCreatingAlibabaIntegration,
    mutate: createAlibabaIntegration,
  } = useCreateAlibabaIntegration({
    onError: () => {
      postAlert({
        message: copyText.errorCreatingAlibabaIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (integrationID) => {
      validateDataIntegration({ integrationID });

      postAlert({
        message: copyText.successCreatingAlibabaIntegrationMessage,
        type: AlertType.SUCCESS,
      });

      navigate(paths._admin, { searchParams: { tab: "integrations" } });
    },
  });

  const {
    isPending: isUpdatingAlibabaIntegration,
    mutate: updateAlibabaIntegration,
  } = useUpdateAlibabaIntegration({
    onError: () => {
      postAlert({
        message: copyText.errorUpdatingAlibabaIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (integrationID) => {
      validateDataIntegration({ integrationID });

      postAlert({
        message: copyText.successUpdatingAlibabaIntegrationMessage,
        type: AlertType.SUCCESS,
      });

      navigate(paths._admin, { searchParams: { tab: "integrations" } });
    },
  });

  const { mutate: validateDataIntegration } = useValidateDataIntegration({
    onError: () => {
      postAlert({
        message: copyText.errorValidatingDataIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSettled: () => {
      return queryClient.invalidateQueries({
        queryKey: [queryKeys.dataIntegrations, authenticatedUser.tenant.id],
      });
    },
  });

  //
  // Computed Values
  //

  // If use integration on location state first, if we have it.
  const alibabaIntegration =
    alibabaIntegrationLocation ?? alibabaIntegrationServer;
  const isMspSharedIntegration =
    !!alibabaIntegration?.mspChildSharedPayerConfiguration;

  //
  // Interaction Handlers
  //

  const defaultValues = alibabaIntegration
    ? getValuesFromIntegration(alibabaIntegration)
    : _defaultValues;

  async function handleSubmit({
    formApi,
    value,
  }: {
    value: FormData;
    formApi: FormApi<FormData>;
  }) {
    if (alibabaIntegration) {
      const defaultValues = formApi.options.defaultValues;

      if (!defaultValues) return;

      handleUpdateAlibabaIntegration(
        alibabaIntegration.id,
        value,
        defaultValues
      );
    } else {
      handleCreateAlibabaIntegration(authenticatedUser.tenant.id, value);
    }
  }

  function handleCreateAlibabaIntegration(tenantID: string, value: FormData) {
    activityTracker.captureAction(actions.CLICK_ADMIN_CREATE_INTEGRATION, {
      type: CloudProviderType.ALIBABA,
    });

    const isValidReports =
      value.configuredCURs.length > 0
        ? value.configuredCURs.some((cudReport) => {
            return (
              cudReport.name.length > 0 &&
              cudReport.reportPathPrefix.length > 0 &&
              cudReport.s3BucketName.length > 0
            );
          })
        : [];

    const costAndUsageReports = isValidReports
      ? value.configuredCURs.map((cuds) => ({
          name: cuds.name.trim(),
          region: cuds.region,
          reportPathPrefix: cuds.reportPathPrefix.trim(),
          s3BucketName: cuds.s3BucketName.trim(),
        }))
      : [];

    createAlibabaIntegration({
      tenantID: tenantID,
      costAndUsageReports: costAndUsageReports,
      name: value.name.trim(),
      roleARN: value.roleARN.trim(),
    });
  }

  function handleUpdateAlibabaIntegration(
    integrationID: string,
    values: FormData,
    defaultValues: FormData
  ) {
    const changes = getChanges(values, defaultValues);

    activityTracker.captureAction(actions.CLICK_ADMIN_UPDATE_INTEGRATION, {
      type: CloudProviderType.ALIBABA,
    });

    updateAlibabaIntegration({
      integrationID,
      ...changes,
    });
  }

  //
  // Render
  //

  const steps = [
    {
      component: (form) => (
        <AlibabaIntegrationFormGeneral
          form={form}
          isMspSharedIntegration={isMspSharedIntegration}
        />
      ),
      label: copyText.alibabaFormTabLabelGeneral,
      requiredInputs: isMspSharedIntegration ? undefined : ["name", "roleARN"],
    },
    {
      component: (form) => <AlibabaFormReports form={form} />,
      isOptional: true,
      label: copyText.alibabaFormTabLabelCostAndUsageReports,
    },
    {
      component: () => (
        <Box width="50%">
          <Text appearance="h4">{copyText.createIntegrationHeader}</Text>
          <Text marginBottom={theme.space_lg}>
            {copyText.integrationLastInstructions}
          </Text>
        </Box>
      ),
      label: copyText.submitButtonLabel,
    },
  ];
  return (
    <Flex direction="column" height="80vh">
      <Button
        iconStart={<Icon icon={faArrowLeft} />}
        secondary
        width={200}
        onClick={() =>
          navigate(paths._admin, { searchParams: { tab: "integrations" } })
        }
      >
        {copyText.backToIntegrationsButtonLabel}
      </Button>
      <Text appearance="h3" marginVertical={theme.space_md}>
        {alibabaIntegration
          ? alibabaIntegration.name
          : copyText.alibabaFormNewAlibabaIntegrationHeader}
      </Text>
      <Flex height="100%" width="100%">
        {isLoadingAlibabaIntegration ? (
          <EmptyPlaceholder loading width="50%" />
        ) : (
          <VerticalStepperForm
            defaultValues={defaultValues}
            isProcessing={
              isCreatingAlibabaIntegration || isUpdatingAlibabaIntegration
            }
            steps={steps}
            onSubmit={handleSubmit}
          />
        )}
      </Flex>
    </Flex>
  );
}

function getValuesFromIntegration(integration: AlibabaIntegrationEntity) {
  return {
    // General
    name: integration.name ?? "",
    roleARN: integration.roleARN ?? "",

    // Cost and Usage Report
    nameCURs: "",
    regionCURs: null,
    reportPathPrefixCURs: "",
    s3BucketNameCURs: "",
    configuredCURs: integration.costAndUsageReports
      ? integration.costAndUsageReports.map((config) => ({
          name: config.name,
          region: config.region,
          reportPathPrefix: config.reportPathPrefix,
          s3BucketName: config.s3BucketName,
        }))
      : ([] as CostAndUsageReport[]),
  };
}

function getChanges(values: FormData, defaultValues: FormData) {
  const changes: UpdateAlibabaIntegrationParams = {};

  if (!isEqual(defaultValues.name, values.name)) {
    changes.name = values.name.trim();
  }

  if (!isEqual(defaultValues.roleARN, values.roleARN)) {
    changes.roleARN = values.roleARN.trim();
  }

  if (!isEqual(defaultValues.configuredCURs, values.configuredCURs)) {
    changes.costAndUsageReports = values.configuredCURs.map((cuds) => ({
      name: cuds.name.trim(),
      region: cuds.region,
      reportPathPrefix: cuds.reportPathPrefix.trim(),
      s3BucketName: cuds.s3BucketName.trim(),
    }));
  }

  return changes;
}
