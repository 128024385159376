import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource, DurationType } from "@ternary/api-lib/analytics/enums";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import UError from "unilib-error";
import { CreditBreakdownEntity } from "../../../api/analytics/types";
import useBuildDataQuery from "../../../api/analytics/utils/useDataQuery";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

type CreditBreakdownDatum = {
  absoluteCreditsCommittedUsageDiscount: number | null;
  absoluteCreditsCommittedUsageDiscountDollarBase: number | null;
  absoluteCreditsDiscount: number | null;
  absoluteCreditsFreeTier: number | null;
  absoluteCreditsPromotion: number | null;
  absoluteCreditsSubscriptionBenefit: number | null;
  absoluteCreditsSustainedUsageDiscount: number | null;
};

interface Params {
  dateRange: Date[];
  durationType: DurationType;
  preAggFilters: QueryFilter[];
}

export default function useGetCreditsBreakdown(
  params: Params,
  options?: UseQueryOptions<CreditBreakdownEntity[], UError>
): UseQueryResult<CreditBreakdownEntity[], UError> {
  const client = useAnalyticsApiClient();
  const gatekeeper = useGatekeeper();

  const [tenantID, query] = useBuildDataQuery({
    ...params,
    dataSource: DataSource.BILLING,
    measures: [
      "absoluteCreditsCommittedUsageDiscount",
      "absoluteCreditsCommittedUsageDiscountDollarBase",
      "absoluteCreditsDiscount",
      "absoluteCreditsFreeTier",
      "absoluteCreditsPromotion",
      "absoluteCreditsSubscriptionBenefit",
      "absoluteCreditsSustainedUsageDiscount",
    ],
  });

  return useQuery({
    queryKey: ["creditsBreakdown", params],
    queryFn: async () => {
      const result = await client.loadData<CreditBreakdownDatum>(
        tenantID,
        query
      );

      const breakdown = result.response[0];

      return [
        {
          total: breakdown.absoluteCreditsCommittedUsageDiscount ?? 0,
          type: "COMMITTED_USAGE_DISCOUNT",
        },
        {
          total: breakdown.absoluteCreditsCommittedUsageDiscountDollarBase ?? 0,
          type: "COMMITTED_USAGE_DISCOUNT_DOLLAR_BASE",
        },
        {
          total: breakdown.absoluteCreditsDiscount ?? 0,
          type: "DISCOUNT",
        },
        {
          total: breakdown.absoluteCreditsFreeTier ?? 0,
          type: "FREE_TIER",
        },
        {
          total: breakdown.absoluteCreditsPromotion ?? 0,
          type: "PROMOTION",
        },
        {
          total: breakdown.absoluteCreditsSubscriptionBenefit ?? 0,
          type: "SUBSCRIPTION_BENEFIT",
        },
        {
          total: breakdown.absoluteCreditsSustainedUsageDiscount ?? 0,
          type: "SUSTAINED_USAGE_DISCOUNT",
        },
      ];
    },
    enabled: gatekeeper.canViewCostReports,
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
