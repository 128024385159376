import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../../api/core/CoreAPIClient";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import queryKeys from "./queryKeys";

type MspBillingStatementEntity = Awaited<
  ReturnType<CoreAPIClient["getMspBillingStatementsByTenantID"]>
>[0];

export function useGetMspBillingStatementsByTenantID(
  tenantID: string,
  options?: UseQueryOptions<MspBillingStatementEntity[], CoreAPIClientError>
): UseQueryResult<MspBillingStatementEntity[], CoreAPIClientError> {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: queryKeys.billingStatements(tenantID),
    queryFn: () => client.getMspBillingStatementsByTenantID(tenantID),
    ...options,
  });
}
