import { useTheme } from "@emotion/react";
import { faCircle, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { formatTimestamp } from "@ternary/api-lib/analytics/utils/ChartFormatUtils";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useMemo } from "react";
import copyText from "../copyText";

type ApiKey = {
  id: string;
  createdAt: string;
  expiresAt: string | null;
  name: string;
};

type TableData = {
  id: string;
  createdAt: string;
  expiresAt: string | null;
  name: string;
};

const columnHelper = createColumnHelper<TableData>();

type Props = {
  apiKeys: ApiKey[];
  isLoading: boolean;
  onInteraction: (interaction: ApiKeyTable.Interaction) => void;
};

export function ApiKeyTable(props: Props) {
  const theme = useTheme();

  function handleDeleteAPIKey(apiKeyID: string) {
    props.onInteraction({
      type: ApiKeyTable.INTERACTION_DELETE_BUTTON_CLICKED,
      keyID: apiKeyID,
    });
  }

  function renderStatusIcon(expirationDate: string | null) {
    if (!expirationDate) {
      return (
        <Flex alignItems="center">
          <Icon color={theme.feedback_positive} icon={faCircle} />
          <Text marginLeft={theme.space_sm}>{copyText.statusActive}</Text>
        </Flex>
      );
    }

    const expireStatus = getExpirationStatus(expirationDate);

    const expireDate = new Date(expirationDate);
    const today = new Date();

    const exprationRange = Math.abs(expireDate.getTime() - today.getTime());
    const daysRemaining = Math.ceil(exprationRange / (1000 * 60 * 60 * 24.0));

    if (expireStatus) {
      return (
        <Flex alignItems="center">
          <Icon color={theme.feedback_negative} icon={faCircle} />
          <Text marginLeft={theme.space_sm}>{copyText.statusInactive}</Text>
        </Flex>
      );
    } else if (!expireStatus && daysRemaining > 15) {
      return (
        <Flex alignItems="center">
          <Icon color={theme.feedback_positive} icon={faCircle} />
          <Text marginLeft={theme.space_sm}>{copyText.statusActive}</Text>
        </Flex>
      );
    } else {
      return (
        <Flex alignItems="center">
          <Icon color={theme.tag_button_color_warning} icon={faCircle} />
          <Text marginLeft={theme.space_sm}>
            {copyText.statusExpiresWarning.replace(
              "%number%",
              daysRemaining.toString()
            )}
          </Text>
        </Flex>
      );
    }
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: copyText.tableHeaderDisplayName,
        size: 100,
      }),
      columnHelper.accessor("id", {
        header: copyText.tableHeaderID,
        size: 130,
      }),
      columnHelper.accessor("createdAt", {
        cell: ({ getValue }) => formatTimestamp(getValue(), "MM/dd/yyyy"),
        header: copyText.tableHeaderCreatedOn,
        meta: { align: "center" },
        size: 100,
      }),
      columnHelper.accessor("expiresAt", {
        cell: ({ getValue }) => {
          const value = getValue();
          return value ? formatTimestamp(value, "MM/dd/yyyy") : "--";
        },
        header: copyText.tableHeaderExpiresOn,
        size: 100,
      }),
      columnHelper.accessor("expiresAt", {
        cell: ({ getValue }) => renderStatusIcon(getValue()),
        header: copyText.tableHeaderStatus,
        size: 100,
      }),
      columnHelper.display({
        id: "deleteButton",
        cell: ({ row }) => (
          <Tooltip key={row.original.id} content={copyText.deleteApiKeyLabel}>
            <Button
              iconStart={<Icon icon={faTrashCan} />}
              size="small"
              onClick={() => {
                handleDeleteAPIKey(row.original.id);
              }}
            />
          </Tooltip>
        ),
        size: 40,
      }),
    ],
    [props.apiKeys]
  );

  const data = useMemo(
    () =>
      props.apiKeys.map((apiKey) => ({
        id: apiKey.id,
        createdAt: apiKey.createdAt,
        expiresAt: apiKey.expiresAt,
        name: apiKey.name,
      })),
    [props.apiKeys]
  );

  return (
    <Table
      columns={columns}
      data={data}
      initialState={{
        sorting: [{ id: "name", desc: false }],
      }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

function getExpirationStatus(date: string) {
  const expiration = new Date(date);
  const today = new Date();

  if (
    expiration.getDay() === today.getDay() &&
    today.getHours() < expiration.getHours()
  ) {
    return false;
  }

  return expiration < today;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ApiKeyTable {
  export const INTERACTION_DELETE_BUTTON_CLICKED = `ApiKeyTable.INTERACTION_DELETE_BUTTON_CLICKED`;

  interface InteractionDeleteButtonClicked {
    type: typeof INTERACTION_DELETE_BUTTON_CLICKED;
    keyID: string;
  }
  export type Interaction = InteractionDeleteButtonClicked;
}

export default ApiKeyTable;
