import { UnitType } from "../enums";

const measures = [
  { name: "activeLogicalB", unit: UnitType.BYTES },
  { name: "longTermLogicalB", unit: UnitType.BYTES },
  { name: "activePhysicalB", unit: UnitType.BYTES },
  { name: "longTermPhysicalB", unit: UnitType.BYTES },
];

const dimensions = [
  "datasetProjectId",
  "datasetName",
  "location",
  "storageBillingModel",
];

export const bigQueryStorageSchema = { dimensions, measures };
