import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQueries } from "@tanstack/react-query";
import {
  DataSource,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/analytics/enums";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import { buildCubeQuery } from "../utils";

type DimensionalNetCostDatum = {
  category: string;
  customNetCost: number;
  netCost: number;
  timestamp: string;
};

type NetCostsByDateDimensional = {
  date: string;
  dimensions: { customNetCost: number; dimension: string; netCost: number }[];
};

type NetCostConfig = {
  billingAccountIDs: string[];
  dateRange: Date[];
  granularity: TimeGranularity;
};

export default function useGetDimensionalNetCostByBillingAccountIDs(
  netCostConfigs: NetCostConfig[],
  options?: UseQueryOptions<NetCostsByDateDimensional[], UError>
): UseQueryResult<NetCostsByDateDimensional[], UError>[] {
  const client = useAnalyticsApiClient();

  return useQueries({
    queries: netCostConfigs.map((config) => ({
      queryKey: ["actualDimensionalSpend", config],
      queryFn: async () => {
        const result = (await client.load(
          buildCubeQuery({
            dateRange: config.dateRange,
            dataSource: DataSource.BILLING,
            dimensions: ["category"],
            granularity: config.granularity,
            measures: ["customNetCost", "netCost"],
            order: { ["timestamp"]: "asc" },
            queryFilters: [
              {
                name: "billingAccountId",
                operator: Operator.EQUALS,
                values: config.billingAccountIDs,
              },
              {
                name: "serviceId",
                operator: Operator.NOT_EQUALS,
                values: [
                  "1934-AA85-20F9", // Maps API
                  "B7D9-FDCB-15D8", // Directions API
                  "0AC2-4B25-C429", // Geocoding API
                  "CF79-2786-ADFC", // Maps Static API
                  "213C-9623-1402", // Places API
                ],
              },
            ],
          })
        )) as DimensionalNetCostDatum[];

        const dimensionsKeyedByDate = result.reduce(
          (
            accum: {
              [date: string]: {
                [category: string]: {
                  customNetCost: number;
                  netCost: number;
                };
              };
            },
            entry
          ) => {
            if (!entry.category) return accum;

            if (accum[entry.timestamp]) {
              accum[entry.timestamp][entry.category] = {
                customNetCost: entry.customNetCost,
                netCost: entry.netCost,
              };
            } else {
              accum[entry.timestamp] = {
                [entry.category]: {
                  customNetCost: entry.customNetCost,
                  netCost: entry.netCost,
                },
              };
            }

            return accum;
          },
          {}
        );

        return Object.keys(dimensionsKeyedByDate).map((date) => ({
          date,
          dimensions: Object.keys(dimensionsKeyedByDate[date]).reduce(
            (accum: NetCostsByDateDimensional["dimensions"], dimension) => {
              accum.push({
                customNetCost:
                  dimensionsKeyedByDate[date][dimension].customNetCost,
                dimension,
                netCost: dimensionsKeyedByDate[date][dimension].netCost,
              });
              return accum;
            },
            []
          ),
        }));
      },
      ...options,
    })),
  });
}
