import { buildCubeQuery } from "@/api/analytics/utils";
import { validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import UError from "unilib-error";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import { DateHelper } from "../../../../lib/dates";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { AWSDatabaseUsageDatum, AWSDatabaseUsageStruct } from "../types";

export default function useGetAWSDatabaseUsageSummary(
  options?: UseQueryOptions<AWSDatabaseUsageDatum, UError>
): UseQueryResult<AWSDatabaseUsageDatum, UError> {
  const client = useAnalyticsApiClient();
  const now = new DateHelper();
  const gatekeeper = useGatekeeper();

  return useQuery({
    queryKey: ["awsDatabaseUsageSummary"],
    queryFn: async () => {
      const measures = ["productMemoryBytes", "usedMemoryBytes"];

      const result = await client.load(
        buildCubeQuery({
          dataSource: DataSource.AWS_DATABASE_INSTANCES,
          dateRange: [now.firstOfMonth(), now.date],
          measures,
        })
      );

      const data = result.map((datum): AWSDatabaseUsageDatum => {
        const [error, validData] = validate(datum, AWSDatabaseUsageStruct);
        if (error) {
          throw new UError("INVALID_AWS_DATABASE_USAGE_DATA", {
            context: { error, result: datum },
          });
        }

        return {
          productMemoryBytes: validData.productMemoryBytes ?? 0,
          usedMemoryBytes: validData.usedMemoryBytes ?? 0,
        };
      });

      return data[0];
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    enabled: gatekeeper.hasAWSIntegration,
    ...options,
  });
}
