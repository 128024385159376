import { operatorOptions } from "@/constants";
import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import Divider from "@/ui-lib/components/Divider";
import Modal from "@/ui-lib/components/Modal";
import Select, { DefaultOption as Option } from "@/ui-lib/components/Select";
import SelectDropdown from "@/ui-lib/components/SelectDropdown";
import { SelectDropdownFilter } from "@/ui-lib/components/SelectDropdownFilter";
import IconTimes from "@/ui-lib/icons/IconTimes";
import { groupOptionsByPreferences } from "@/utils/groupOptionsByPreferences";
import { isOperator } from "@/utils/typeGuards";
import { useTheme } from "@emotion/react";
import {
  faEye,
  faEyeSlash,
  faLock,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { DataSource, Operator } from "@ternary/api-lib/analytics/enums";
import {
  ScopedViewType,
  UserConfigStatus,
} from "@ternary/api-lib/constants/enums";
import { ScopedViewFilter, UserConfig } from "@ternary/api-lib/core/types";
import { actions } from "@ternary/api-lib/telemetry";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import { getEnumAtKey } from "@ternary/api-lib/utils/typeGuards";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { noop, uniq } from "lodash";
import React, { useState } from "react";
import commonCopyText from "../common.copyText";
import featureCopyText from "../features/user-settings/copyText";

const copyText = { ...commonCopyText, ...featureCopyText };

const dataSourceSelectOptions = [
  {
    label: copyText.dataSource_BILLING_label,
    value: DataSource.BILLING,
  },
  {
    label: copyText.dataSource_KUBERNETES_CONTAINER_USAGE_label,
    value: DataSource.KUBERNETES_CONTAINER_USAGE,
  },
  {
    label: copyText.dataSource_KUBERNETES_NODE_USAGE_label,
    value: DataSource.KUBERNETES_NODE_USAGE,
  },
];

type ScopedView = {
  id: string;
  createdBy: string;
  name: string;
  type: ScopedViewType;
  userConfigs: UserConfig[];
};

type DimensionPreference = {
  dataSource: DataSource;
  category: string;
  values: string[];
};

export interface Props {
  availableDimensionsMap: { [key in DataSource]?: string[] };
  availableScopedViewIDs: string[];
  dimensionValuesMap: { [key: string]: string[] };
  filters: ScopedViewFilter[];
  isLoadingDimensionValuesMap: boolean;
  dimensionPreferences: DimensionPreference[];
  scopedViews: ScopedView[];
  onInteraction: (interaction: GlobalFilterModal.Interaction) => void;
}

export function GlobalFilterModal(props: Props): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const activityTracker = useActivityTracker();
  const theme = useTheme();

  const [customFilter, setCustomFilter] = useState<string[]>([]);

  //
  // Interaction Handlers
  //

  function handleAddFilter(dataSource: DataSource): void {
    props.onInteraction({
      type: GlobalFilterModal.INTERACTION_ADD_FILTER_BUTTON_CLICKED,
      dataSource,
    });
  }

  function handleAddScopedView(scopedViewID: string) {
    props.onInteraction({
      type: GlobalFilterModal.INTERACTION_ADD_SCOPED_VIEW_BUTTON_CLICKED,
      scopedViewID,
    });
  }

  function handleCancel() {
    props.onInteraction({
      type: GlobalFilterModal.INTERACTION_CANCEL_BUTTON_CLICKED,
    });
  }

  function handleDeselectFilters(): void {
    props.onInteraction({
      type: GlobalFilterModal.INTERACTION_CLEAR_FILTERS,
    });
  }

  function handleRemoveFilter(index: number): void {
    props.onInteraction({
      type: GlobalFilterModal.INTERACTION_REMOVE_FILTER_BUTTON_CLICKED,
      index,
    });
  }

  function handleSubmit(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    props.onInteraction({
      type: GlobalFilterModal.INTERACTION_SUBMIT_BUTTON_CLICKED,
    });
  }

  function handleToggleScopedView(
    scopedView: ScopedView,
    isMuted: boolean
  ): void {
    if (scopedView.type === ScopedViewType.ADMIN && !isMuted) {
      activityTracker.captureAction(actions.CLICK_SCOPED_VIEW_ADMIN_MUTE);
    }

    props.onInteraction({
      type: GlobalFilterModal.INTERACTION_TOGGLE_SCOPED_VIEW_BUTTON_CLICKED,
      scopedViewID: scopedView.id,
      isMuted,
    });
  }

  function handleUpdateFilterName(option: Option | null, index: number): void {
    if (!option) return;

    props.onInteraction({
      type: GlobalFilterModal.INTERACTION_FILTER_NAME_CHANGED,
      index,
      name: option.value,
    });
  }

  function handleUpdateFilterOperator(
    option: Option | null,
    index: number
  ): void {
    if (!option || !isOperator(option.value)) return;

    props.onInteraction({
      type: GlobalFilterModal.INTERACTION_FILTER_OPERATOR_CHANGED,
      index,
      operator: option.value,
    });
  }

  function handleUpdateFilterValues(value: string[], index: number): void {
    props.onInteraction({
      type: GlobalFilterModal.INTERACTION_FILTER_VALUES_CHANGED,
      index,
      values: value,
    });
  }

  //
  // Render
  //

  // Note: Passes filter if it is in available scoped views and not ENABLED_STRICT or if it is enabled by default/enforced.
  const orgLevelScopedViews = props.scopedViews
    .filter(
      (scopedView) =>
        (scopedView.createdBy !== authenticatedUser.id &&
          props.availableScopedViewIDs.includes(scopedView.id) &&
          scopedView.userConfigs.some(
            (userConfig) =>
              userConfig.userID === authenticatedUser.id &&
              (userConfig.status === UserConfigStatus.ENABLED ||
                userConfig.status === UserConfigStatus.ENFORCED ||
                userConfig.status === UserConfigStatus.DISABLED)
          )) ||
        (scopedView.createdBy !== authenticatedUser.id &&
          !props.availableScopedViewIDs.includes(scopedView.id) &&
          scopedView.userConfigs.some(
            (userConfig) =>
              userConfig.userID === authenticatedUser.id &&
              (userConfig.status === UserConfigStatus.ENABLED ||
                userConfig.status === UserConfigStatus.ENFORCED)
          ))
    )
    .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));

  // Note: strictly enabled scoped views are admin scoped views with ENABLED_STRICT status
  const strictlyEnabledScopedViews = props.scopedViews
    .filter((scopedView) =>
      scopedView.userConfigs.some(
        (userConfig) =>
          userConfig.userID === authenticatedUser.id &&
          userConfig.status === UserConfigStatus.ENABLED_STRICT
      )
    )
    .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));

  const numberOfActiveStrictlyEnabledScopedViews =
    strictlyEnabledScopedViews.reduce((accum, scopedView) => {
      if (
        props.availableScopedViewIDs.find(
          (scopedViewID) => scopedViewID === scopedView.id
        )
      ) {
        return accum + 1;
      } else return accum;
    }, 0);

  // Note: Does Not include admin scoped views with ENAABLED_STRICT status, even if created by authenticated user
  const personalScopedViews = props.scopedViews
    .filter(
      (scopedView) =>
        scopedView.createdBy === authenticatedUser.id &&
        !scopedView.userConfigs.some(
          (userConfig) =>
            userConfig.userID === authenticatedUser.id &&
            userConfig.status === UserConfigStatus.ENABLED_STRICT
        ) &&
        (props.availableScopedViewIDs.includes(scopedView.id) ||
          scopedView.userConfigs.some(
            (userConfig) =>
              userConfig.userID === authenticatedUser.id &&
              userConfig.status === UserConfigStatus.ENABLED
          ))
    )
    .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));

  const orgLevelScopedViewOptions = props.scopedViews
    .filter(
      (scopedView) =>
        !props.availableScopedViewIDs.includes(scopedView.id) &&
        scopedView.createdBy !== authenticatedUser.id &&
        scopedView.userConfigs.some(
          (userConfig) =>
            userConfig.userID === authenticatedUser.id &&
            userConfig.status !== UserConfigStatus.ENABLED_STRICT &&
            userConfig.status !== UserConfigStatus.ENABLED
        )
    )
    .map((scopedView) => ({
      label: `${scopedView.name} ${
        scopedView.type === ScopedViewType.ADMIN ? copyText.sharedLabel : ""
      }`,
      value: scopedView.id,
    }));

  const personalScopedViewOptions = props.scopedViews
    .filter(
      (scopedView) =>
        !props.availableScopedViewIDs.includes(scopedView.id) &&
        scopedView.createdBy === authenticatedUser.id &&
        scopedView.userConfigs.some(
          (userConfig) =>
            userConfig.userID === authenticatedUser.id &&
            userConfig.status !== UserConfigStatus.ENABLED &&
            userConfig.status !== UserConfigStatus.ENABLED_STRICT
        )
    )
    .map((scopedView) => ({
      label: `${scopedView.name} ${
        scopedView.type === ScopedViewType.ADMIN ? copyText.sharedLabel : ""
      }`,
      value: scopedView.id,
    }));

  const selectedDataSources = props.filters
    .reduce((accum: { label: string; value: DataSource }[], filter) => {
      if (accum.some((option) => filter.dataSource === option.value)) {
        return accum;
      } else {
        return [
          ...accum,
          {
            label: copyText[`dataSource_${filter.dataSource}_label`],
            value: filter.dataSource,
          },
        ];
      }
    }, [])
    .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

  const selectedDimensions = props.filters.map((filter) => ({
    dataSource: filter.dataSource,
    name: filter.name,
  }));

  const isValid = props.filters.every((filter) => {
    if (
      filter.operator === Operator.SET ||
      filter.operator === Operator.NOT_SET
    ) {
      return filter.name.length > 0;
    } else {
      return (
        filter.name.length > 0 && filter.values && filter.values.length > 0
      );
    }
  });

  function getAvailableDimensions(filter: ScopedViewFilter): string[] {
    return props.availableDimensionsMap &&
      props.availableDimensionsMap[filter.dataSource]
      ? (props.availableDimensionsMap[filter.dataSource] ?? [])
      : [];
  }

  return (
    <Modal
      isOpen={true}
      minWidth={1100}
      position="top-start"
      showCloseButton
      closeOnClickOutside={false}
      onClose={handleCancel}
    >
      <Modal.Header>
        <Flex justifyContent="space-between" width="100%">
          <Text fontSize={theme.h4_fontSize}>
            {copyText.globalFilterModalTitle}
          </Text>
          <Button
            primary
            iconEnd={<Icon icon={faPlus} />}
            size="small"
            type="button"
            marginRight={theme.space_sm}
            onClick={() => {
              props.onInteraction({
                type: GlobalFilterModal.INTERACTION_CREATE_SCOPED_VIEW_BUTTON_CLICKED,
              });
            }}
          >
            {copyText.createScopedViewButtonLabel}
          </Button>
        </Flex>
      </Modal.Header>
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        maxHeight={500}
        minHeight={200}
        overflow="scroll"
      >
        <Box minWidth={300}>
          <Text bold>{copyText.adminFiltersHeader}</Text>
          {orgLevelScopedViews.map((scopedView) => {
            const isMuted = !props.availableScopedViewIDs.includes(
              scopedView.id
            );
            const isEnforced = scopedView.userConfigs.some(
              (userConfig) =>
                userConfig.userID === authenticatedUser.id &&
                userConfig.status === UserConfigStatus.ENFORCED
            );

            return (
              <ScopedViewLabel
                key={scopedView.id}
                isEnforced={isEnforced}
                isMuted={isMuted}
                scopedView={scopedView}
                onToggle={handleToggleScopedView}
              />
            );
          })}
          <SelectDropdown
            placement="bottom-start"
            options={orgLevelScopedViewOptions}
            onChange={handleAddScopedView}
          >
            <Button
              iconStart={<Icon icon={faPlus} />}
              marginTop={personalScopedViews.length === 0 ? theme.space_sm : 0}
              size="small"
            >
              {copyText.addFilterButtonLabel}
            </Button>
          </SelectDropdown>
          {strictlyEnabledScopedViews.length > 0 && (
            <Box>
              <Flex alignItems="center">
                <Box flex="1 0 0">
                  <Divider />
                </Box>
              </Flex>
              <Tooltip content={copyText.strictlyEnabledFiltersTooltip}>
                <Text> {copyText.strictlyEnabledFiltersHeader}</Text>
              </Tooltip>
              {strictlyEnabledScopedViews.map((scopedView) => {
                const isMuted = !props.availableScopedViewIDs.includes(
                  scopedView.id
                );

                return (
                  <ScopedViewLabel
                    key={scopedView.id}
                    isLastEnabledStrict={
                      numberOfActiveStrictlyEnabledScopedViews <= 1
                    }
                    isMuted={isMuted}
                    scopedView={scopedView}
                    onToggle={handleToggleScopedView}
                  />
                );
              })}
            </Box>
          )}
        </Box>
        <Divider direction="vertical" minHeight={200} />
        <Box minWidth={300}>
          <Text bold>{copyText.privateFiltersHeader}</Text>
          {personalScopedViews.map((scopedView) => {
            const isMuted = !props.availableScopedViewIDs.includes(
              scopedView.id
            );

            return (
              <ScopedViewLabel
                key={scopedView.id}
                isMuted={isMuted}
                scopedView={scopedView}
                onToggle={handleToggleScopedView}
              />
            );
          })}
          <SelectDropdown
            placement="bottom-start"
            options={personalScopedViewOptions}
            onChange={handleAddScopedView}
          >
            <Button
              iconStart={<Icon icon={faPlus} />}
              marginTop={personalScopedViews.length === 0 ? theme.space_sm : 0}
              size="small"
            >
              {copyText.addFilterButtonLabel}
            </Button>
          </SelectDropdown>
        </Box>
        <Divider direction="vertical" minHeight={200} />
        <Flex direction="column" paddingBottom={150}>
          <Text bold>{copyText.additionalFiltersHeader}</Text>
          {selectedDataSources.map((dataSourceOption, dataSourceIndex) => {
            return (
              <React.Fragment key={dataSourceIndex}>
                <Flex>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    transform="rotate(-90deg)"
                    width="60px"
                  >
                    <Text bold textDecoration="underline">
                      {dataSourceOption.label}
                    </Text>
                  </Flex>
                  <Flex direction="column">
                    {props.filters.map((filter, filterIndex) => {
                      if (filter.dataSource !== dataSourceOption.value)
                        return null;

                      const availableDimensions = getAvailableDimensions(
                        filter
                      ).map((filter) => ({
                        label: filter,
                        value: filter,
                      }));

                      const filteredDimension = availableDimensions.filter(
                        (option) =>
                          !selectedDimensions.some(
                            (dimension) =>
                              option.value === dimension.name &&
                              dataSourceOption.value === dimension.dataSource
                          )
                      );

                      const groupedDimensions = groupOptionsByPreferences(
                        filteredDimension,
                        props.dimensionPreferences,
                        filter.dataSource
                      );

                      const filterOption = availableDimensions.find(
                        (option) => option.value === filter.name
                      );

                      const operatorOption = operatorOptions.find(
                        (option) => option.value === filter.operator
                      );

                      const currentValues = props.dimensionValuesMap[
                        filter.dataSource
                      ]
                        ? props.dimensionValuesMap[filter.dataSource][
                            filter.name
                          ]
                        : [];

                      const filterOptions = uniq([
                        ...customFilter.slice().sort(),
                        ...(currentValues ?? []).slice().sort(),
                      ]).map((value) => ({ label: value, value }));

                      return (
                        <Flex
                          key={`${filterIndex}-${filter.name}`}
                          alignItems="center"
                          marginTop={theme.space_sm}
                        >
                          <Box marginRight={theme.space_md} minWidth={190}>
                            <Select
                              compact
                              hideSelectedOptions
                              options={groupedDimensions}
                              placeholder={copyText.selectFilterNameLabel}
                              searchable
                              value={filterOption}
                              onChange={(option) =>
                                handleUpdateFilterName(option, filterIndex)
                              }
                            />
                          </Box>
                          <Box marginRight={theme.space_md} minWidth={140}>
                            <Select
                              compact
                              defaultValue={operatorOption}
                              options={operatorOptions}
                              onChange={(option) =>
                                handleUpdateFilterOperator(option, filterIndex)
                              }
                            />
                          </Box>
                          <Box width={240}>
                            <SelectDropdownFilter
                              compact
                              filter={filter}
                              options={filterOptions}
                              operator={
                                operatorOption?.value ?? filter?.operator
                              }
                              isLoading={props.isLoadingDimensionValuesMap}
                              showInputStyle
                              onChange={(value: string | string[]) => {
                                handleUpdateFilterValues(
                                  typeof value === "string" ? [value] : value,
                                  filterIndex
                                );
                              }}
                              onCreateOption={(value: string) => {
                                setCustomFilter((currentState) => [
                                  ...currentState,
                                  value,
                                ]);
                              }}
                            />
                          </Box>
                          <Box alignSelf="end" marginLeft={theme.space_sm}>
                            <Tooltip
                              content={copyText.removeFilterTooltipCaption}
                            >
                              <Button
                                iconStart={<IconTimes />}
                                size="small"
                                type="button"
                                onClick={() => handleRemoveFilter(filterIndex)}
                              />
                            </Tooltip>
                          </Box>
                        </Flex>
                      );
                    })}
                    <Flex marginTop={theme.space_sm}>
                      <Button
                        iconStart={<Icon icon={faPlus} />}
                        size="tiny"
                        type="button"
                        width={120}
                        onClick={() => handleAddFilter(dataSourceOption.value)}
                      >
                        {copyText.addFilterButtonLabel}
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
                {dataSourceIndex !== dataSourceSelectOptions.length - 1 && (
                  <Divider margin={theme.space_sm} />
                )}
              </React.Fragment>
            );
          })}
          {selectedDataSources.length !== dataSourceSelectOptions.length && (
            <Flex marginTop={theme.space_sm}>
              <SelectDropdown
                options={dataSourceSelectOptions.filter(
                  (option) =>
                    !selectedDataSources.some(
                      (dataSource) => option.value === dataSource.value
                    )
                )}
                onChange={(dataSource) =>
                  dataSource
                    ? handleAddFilter(
                        getEnumAtKey(DataSource, dataSource) ??
                          DataSource.BILLING
                      )
                    : noop
                }
              >
                <Button
                  marginBottom={theme.space_jumbo}
                  iconStart={<Icon icon={faPlus} />}
                  size="small"
                >
                  {copyText.addDatasetButtonLabel}
                </Button>
              </SelectDropdown>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Modal.Footer>
        <Flex width="100%" alignItems="flex-start">
          <Button
            disabled={
              props.filters.length || props.availableScopedViewIDs.length
                ? false
                : true
            }
            secondary
            width={100}
            type="button"
            onClick={handleDeselectFilters}
          >
            {copyText.deselectFiltersButtonLabel}
          </Button>
        </Flex>
        <Button
          marginRight={theme.space_xxl}
          secondary
          type="reset"
          width={100}
          onClick={handleCancel}
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button disabled={!isValid} primary width={100} onClick={handleSubmit}>
          {copyText.applyFiltersButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

interface ScopedViewLabelProps {
  isEnforced?: boolean;
  isLastEnabledStrict?: boolean;
  isMuted: boolean;
  scopedView: ScopedView;
  onToggle: (scopedView: ScopedView, isMuted: boolean) => void;
}

function ScopedViewLabel(props: ScopedViewLabelProps): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const theme = useTheme();

  const tooltipText = props.scopedView.userConfigs.some(
    (userConfig) =>
      userConfig.userID === authenticatedUser.id &&
      (userConfig.status === UserConfigStatus.ENABLED ||
        userConfig.status === UserConfigStatus.ENABLED_STRICT)
  )
    ? props.isMuted
      ? copyText.unmuteTooltipCaption
      : props.isLastEnabledStrict
        ? copyText.enabledStrictScopedViewTooltip
        : copyText.muteTooltipCaption
    : copyText.removeScopedViewTooltipCaption;

  const icon = props.scopedView.userConfigs.some(
    (userConfig) =>
      userConfig.userID === authenticatedUser.id &&
      (userConfig.status === UserConfigStatus.ENABLED ||
        userConfig.status === UserConfigStatus.ENFORCED ||
        userConfig.status === UserConfigStatus.ENABLED_STRICT)
  ) ? (
    <Icon
      color={
        props.isEnforced
          ? undefined
          : props.isMuted
            ? theme.feedback_negative
            : undefined
      }
      icon={props.isEnforced ? faLock : props.isMuted ? faEyeSlash : faEye}
    />
  ) : (
    <Icon icon={faTrashAlt} />
  );

  return (
    <Flex
      key={props.scopedView.id}
      marginVertical={theme.space_sm}
      alignItems="center"
    >
      <Flex
        alignItems="center"
        backgroundColor={theme.secondary_color_background}
        borderRadius={theme.borderRadius_2}
        flexGrow={1}
        height={theme.size_small}
        paddingHorizontal={theme.space_md}
        width={250}
      >
        <Text
          color={
            props.scopedView.userConfigs.some(
              (userConfig) =>
                userConfig.userID === authenticatedUser.id &&
                userConfig.status === UserConfigStatus.ENABLED
            ) && props.isMuted
              ? theme.text_color_secondary_disabled
              : theme.text_color
          }
          truncate
        >
          {`${props.scopedView.name} ${
            props.scopedView.type === ScopedViewType.ADMIN
              ? copyText.sharedLabel
              : ""
          }`}
        </Text>
      </Flex>
      <Tooltip
        content={
          props.isEnforced ? copyText.enforcedScopedViewTooltip : tooltipText
        }
      >
        <Button
          disabled={
            props.isEnforced || (props.isLastEnabledStrict && !props.isMuted)
          }
          iconStart={icon}
          marginLeft={theme.space_md}
          secondary
          size="small"
          type="button"
          width={theme.size_small}
          onClick={() => props.onToggle(props.scopedView, props.isMuted)}
        />
      </Tooltip>
    </Flex>
  );
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace GlobalFilterModal {
  export const INTERACTION_ADD_FILTER_BUTTON_CLICKED = `GlobalFilterModal.INTERACTION_ADD_FILTER_BUTTON_CLICKED`;
  export const INTERACTION_ADD_SCOPED_VIEW_BUTTON_CLICKED = `GlobalFilterModal.INTERACTION_ADD_SCOPED_VIEW_BUTTON_CLICKED`;
  export const INTERACTION_CANCEL_BUTTON_CLICKED = `GlobalFilterModal.INTERACTION_CANCEL_BUTTON_CLICKED`;
  export const INTERACTION_CLEAR_FILTERS = `GlobalFilterModal.INTERACTION_CLEAR_FILTERS`;
  export const INTERACTION_FILTER_NAME_CHANGED = `GlobalFilterModal.INTERACTION_FILTER_NAME_CHANGED`;
  export const INTERACTION_FILTER_OPERATOR_CHANGED = `GlobalFilterModal.INTERACTION_FILTER_OPERATOR_CHANGED`;
  export const INTERACTION_FILTER_VALUES_CHANGED = `GlobalFilterModal.INTERACTION_FILTER_VALUES_CHANGED`;
  export const INTERACTION_REMOVE_FILTER_BUTTON_CLICKED = `GlobalFilterModal.INTERACTION_REMOVE_FILTER_BUTTON_CLICKED`;
  export const INTERACTION_SUBMIT_BUTTON_CLICKED = `GlobalFilterModal.INTERACTION_SUBMIT_BUTTON_CLICKED`;
  export const INTERACTION_TOGGLE_SCOPED_VIEW_BUTTON_CLICKED = `GlobalFilterModal.INTERACTION_TOGGLE_SCOPED_VIEW_BUTTON_CLICKED`;
  export const INTERACTION_CREATE_SCOPED_VIEW_BUTTON_CLICKED = `GlobalFilterModal.INTERACTION_CREATE_SCOPED_VIEW_BUTTON_CLICKED`;

  interface InteractionAddFilterButtonClicked {
    type: typeof INTERACTION_ADD_FILTER_BUTTON_CLICKED;
    dataSource: DataSource;
  }

  interface InteractionAddScopedViewButtonClicked {
    type: typeof INTERACTION_ADD_SCOPED_VIEW_BUTTON_CLICKED;
    scopedViewID: string;
  }

  interface InteractionCancelButtonClicked {
    type: typeof INTERACTION_CANCEL_BUTTON_CLICKED;
  }

  interface InteractionClearFilters {
    type: typeof INTERACTION_CLEAR_FILTERS;
  }

  interface InteractionFilterNameChanged {
    type: typeof INTERACTION_FILTER_NAME_CHANGED;
    index: number;
    name: string;
  }

  interface InteractionFilterOperatorChanged {
    type: typeof INTERACTION_FILTER_OPERATOR_CHANGED;
    index: number;
    operator: Operator;
  }

  interface InteractionFilterValuesChanged {
    type: typeof INTERACTION_FILTER_VALUES_CHANGED;
    index: number;
    values: string[];
  }

  interface InteractionRemoveFilterButtonClicked {
    type: typeof INTERACTION_REMOVE_FILTER_BUTTON_CLICKED;
    index: number;
  }

  interface InteractionSubmitButtonClicked {
    type: typeof INTERACTION_SUBMIT_BUTTON_CLICKED;
  }

  interface InteractionToggleScopedViewButtonClicked {
    type: typeof INTERACTION_TOGGLE_SCOPED_VIEW_BUTTON_CLICKED;
    scopedViewID: string;
    isMuted: boolean;
  }
  interface InteractionCreateScopedViewButtonClicked {
    type: typeof INTERACTION_CREATE_SCOPED_VIEW_BUTTON_CLICKED;
  }

  export type Interaction =
    | InteractionAddFilterButtonClicked
    | InteractionAddScopedViewButtonClicked
    | InteractionCancelButtonClicked
    | InteractionClearFilters
    | InteractionFilterNameChanged
    | InteractionFilterOperatorChanged
    | InteractionFilterValuesChanged
    | InteractionRemoveFilterButtonClicked
    | InteractionSubmitButtonClicked
    | InteractionToggleScopedViewButtonClicked
    | InteractionCreateScopedViewButtonClicked;
}

export default GlobalFilterModal;
