import { format } from "date-fns-tz";
import { Operator, TimeGranularity } from "../enums";
import { BinaryFilter, LabelMap, QueryFilter, UnaryFilter } from "../types";
import { DateRange } from "../utils";
import {
  CubeBinaryOperator,
  CubeFilter,
  CubeTimeDimensionGranularity,
  CubeUnaryOperator,
} from "./types";

export function isStringTuple(value: unknown[]): value is [string, string] {
  if (typeof value[0] !== "string" || typeof value[1] !== "string") {
    return false;
  }

  return true;
}

//
// Cube Query Utils
//

// TODO: Remove these when fully cutover to datalligator

export function getCubeDateRange(dateRange: DateRange): [string, string] {
  return [
    format(dateRange[0], "yyyy-MM-dd"),
    format(dateRange[1], "yyyy-MM-dd"),
  ];
}

function getCubeBinaryOperator(operator: Operator): CubeBinaryOperator {
  switch (operator) {
    case Operator.CONTAINS:
      return "contains";
    case Operator.EQUALS:
      return "equals";
    case Operator.GTE:
      return "gte";
    case Operator.LTE:
      return "lte";
    case Operator.NOT_CONTAINS:
      return "notContains";
    case Operator.NOT_EQUALS:
      return "notEquals";
    default:
      return "equals";
  }
}

function getCubeUnaryOperator(operator: Operator): CubeUnaryOperator {
  return operator === Operator.SET ? "set" : "notSet";
}

export function getCubeGranularity(
  granularity: TimeGranularity
): CubeTimeDimensionGranularity {
  switch (granularity) {
    case TimeGranularity.DAY:
      return "day";
    case TimeGranularity.HOUR:
      return "hour";
    case TimeGranularity.MONTH:
      return "month";
    case TimeGranularity.WEEK:
      return "week";
    case TimeGranularity.QUARTER:
      return "quarter";
    default:
      return "day";
  }
}

export function transformFilterCube(
  filter: QueryFilter,
  labelMap: LabelMap,
  schemaName: string
): CubeFilter {
  if ("name" in filter) {
    return convertFilter(filter, labelMap, schemaName);
  }

  if ("and" in filter) {
    return {
      and: filter.and.map((filter) =>
        transformFilterCube(filter, labelMap, schemaName)
      ),
    };
  }

  if ("or" in filter) {
    return {
      or: filter.or.map((filter) =>
        transformFilterCube(filter, labelMap, schemaName)
      ),
    };
  }

  throw new Error("INVALID FILTER");
}

function convertFilter(
  filter: BinaryFilter | UnaryFilter,
  labelMap: LabelMap,
  schemaName: string
): CubeFilter {
  if (
    !filter.values ||
    filter.values.length > 1 ||
    filter.values[0] !== "null"
  ) {
    return mapQueryFilter(filter, labelMap, schemaName);
  }

  let newOperator: typeof Operator.NOT_SET | typeof Operator.SET;

  switch (filter.operator) {
    case Operator.EQUALS:
      newOperator = Operator.NOT_SET;
      break;

    case Operator.NOT_EQUALS:
      newOperator = Operator.SET;
      break;

    default:
      newOperator = Operator.SET;
  }

  return mapQueryFilter(
    {
      name: filter.name,
      operator: newOperator,
    },
    labelMap,
    schemaName
  );
}

function mapQueryFilter(
  filter: BinaryFilter | UnaryFilter,
  labelMap: LabelMap,
  schemaName: string
): CubeFilter {
  const mappedDimension = labelMap[filter.name]
    ? labelMap[filter.name]
    : filter.name;

  return filter.values
    ? {
        member: `${schemaName}.${mappedDimension}`,
        operator: getCubeBinaryOperator(filter.operator),
        values: filter.values,
      }
    : {
        member: `${schemaName}.${mappedDimension}`,
        operator: getCubeUnaryOperator(filter.operator),
      };
}
