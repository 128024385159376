import { createStructParam } from "@/lib/use-query-params";
import { useTheme } from "@emotion/react";
import { createColumnHelper } from "@tanstack/react-table";
import useRefFn from "@ternary/api-lib/analytics/ui/hooks/useRefFn";
import { formatCurrency } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useMemo } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import {
  AWSRedshiftGroupFilters,
  AWSRedshiftResourceGroupEntity,
  awsRedshiftDimensions,
  awsRedshiftMeasures,
} from "../types";

type Props = {
  clusterGroups: AWSRedshiftResourceGroupEntity[];
  isLoadingResourceGroups: boolean;
  onInteraction: (interaction: AWSWarehouseResourceTable.Interaction) => void;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

const columnHelper = createColumnHelper<AWSRedshiftResourceGroupEntity>();

function AWSWarehouseResourceTable(props: Props) {
  const theme = useTheme();

  const [sortRule, setSortRule] = useQueryParam(
    "group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "cost",
    })
  );

  const onInteraction = useRefFn(props.onInteraction);

  const columns = useMemo(
    () => [
      ...(
        [
          [awsRedshiftDimensions.clusterId, 120],
          [awsRedshiftDimensions.usageAccountId, 120],
          [awsRedshiftDimensions.region, 120],
        ] as const
      ).map(([dimensionKey, size], index) => {
        const headerKey: keyof typeof copyText = `awsWarehouseResourceTableGroupHeader_${dimensionKey}`;

        return columnHelper.accessor(dimensionKey, {
          cell: ({ getValue }) => (
            <Text
              color={theme.primary_color_text}
              truncate
              cursor="pointer"
              onClick={() =>
                onInteraction({
                  type: AWSWarehouseResourceTable.INTERACTION_FILTER_CLICKED,
                  filterKey: dimensionKey,
                  filterValue: getValue(),
                })
              }
            >
              <Tooltip content={getValue()}>
                {getValue() || copyText.azureKubernetesTableNull}
              </Tooltip>
            </Text>
          ),
          header:
            dimensionKey === awsRedshiftDimensions.usageAccountId
              ? () => (
                  <Tooltip
                    content={
                      <Box width={200}>
                        <Text color={theme.text_color_inverse}>
                          {copyText.awsIAMListPermissionTooltipMessage}
                        </Text>
                      </Box>
                    }
                  >
                    {copyText[headerKey]}
                  </Tooltip>
                )
              : copyText[headerKey],
          meta: { align: index === 0 ? "left" : "center" },
          size,
        });
      }),
      ...(
        [
          [awsRedshiftMeasures.cost, 120],
          [awsRedshiftMeasures.nodeCost, 120],
          [awsRedshiftMeasures.storageCost, 120],
          [awsRedshiftMeasures.dataScanCost, 120],
        ] as const
      ).map(([dimensionKey, size]) => {
        const headerKey: keyof typeof copyText = `awsWarehouseResourceTableGroupHeader_${dimensionKey}`;

        return columnHelper.accessor(dimensionKey, {
          cell: ({ getValue }) => formatCurrency({ number: getValue() }),
          header: copyText[headerKey],
          meta: { align: "center" },
          size,
        });
      }),
    ],
    []
  );

  return (
    <Box maxWidth={"100%"} overflowX="auto">
      <Table
        columns={columns}
        compact
        data={props.clusterGroups}
        initialState={{ sorting: [sortRule] }}
        isLoading={props.isLoadingResourceGroups}
        showPagination
        sortable
        onChangeSortBy={([sortRule]) => setSortRule(sortRule, "replaceIn")}
      />
    </Box>
  );
}

AWSWarehouseResourceTable.INTERACTION_FILTER_CLICKED =
  `AWSWarehouseResourceTable.INTERACTION_FILTER_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof AWSWarehouseResourceTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof AWSRedshiftGroupFilters;
  filterValue: AWSRedshiftGroupFilters[keyof AWSRedshiftGroupFilters];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AWSWarehouseResourceTable {
  export type Interaction = InteractionFilterClicked;
}
export default AWSWarehouseResourceTable;
