import { FeatureFlag } from "@ternary/api-lib/constants/feature-flags";
import copyText from "../../common.copyText";

/* prettier-ignore */
export default {
  ...copyText,
  actionButtonLable: "Actions",
  actionDropdownOptionLabelAccessTenant: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Access Business Unit",
    default: "Access Tenant",
  },
  actionDropdownOptionLabelEditTenant: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Manage Business Unit",
    default: "Manage Tenant",
  },
  actionDropdownOptionLabelManageFeatureFlags: "Manage Feature Flags",
  actionDropdownOptionLabelViewTenants: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "View Business Units",
    default: "View Tenants",
  },
  actionDropdownOptionLabelViewUsers: "View Users",
  addFeatureFlagButtonLabel: "Add Feature Flag",
  addUsersButtonLabel: "Add Users",
  auditLogModalTitle: "Audit Log",
  backToTenantsButtonLabel: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Back to all Business Units",
    default: "Back To All Tenants",
  },
  createTenantButtonLabel: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Create Business Unit",
    default: "Create Tenant",
  },
  dashboardsTabLabel: "Dashboards",
  deactivateTenantConfirmationMessage: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Deactivating this business unit will cause the following: \n- All data ingestion will stop \n- Users will be unable to access the platform \n- Notifications will no longer be sent \n\nAre you sure you want to do this?",
    default: "Deactivating this tenant will cause the following: \n- All data ingestion will stop \n- Users will be unable to access the platform \n- Notifications will no longer be sent \n\nAre you sure you want to do this?"
  },
  deactivateTenantConfirmationTitle: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Deactivate Business Unit",
    default: "Deactivate Tenant",
  },
  detailsTabLabel: "Details",
  emailDomainCSVHeader: "Email Domain",
  emailDomainInputLabel: "Email Domain",
  emailDomainInputPlaceholder: "ternary.app",
  errorAddingFeatureFlagsMessage: "An error occurred while attempting to add the feature flag. Please try again.",
  errorCreateingMspSharedPayerIntegrationsMessage: "An error occurred while attempting to create Subaccount integrations. Please try again.",
  errorCreatingTenantMessage: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "An error occurred while attempting to create the business unit. Please try again.",
    default: "An error occurred while attempting to create the tenant. Please try again.",
  },
  errorGrantingUsersGlobalRolesMessage: "An error occurred when attempting to give users global roles. Please try again.",
  errorGrantingUserTenantAccessMessage: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "An error occurred when attempting to grant access to the business unit. Please try again.",
    default: "An error occurred when attempting to grant access to the tenant. Please try again.",
  },
  errorRemovingFeatureFlagsMessage: "An error occurred while attempting to remove the feature flag. Please try again.",
  errorRevokingUserGlobalRoleMessage: "An error occurred while attempting to revoke user global roles. Please try again.",
  errorUpdateingMspSharedPayerIntegrationsMessage: "An error occurred while attempting to update Subaccount integrations. Please try again.",
  errorUpdatingTenantMessage: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "An error occurred while attempting to update the business unit. Please try again.",
    default: "An error occurred while attempting to update the tenant. Please try again."
  },
  errorUpdatingUserTenantRolesMessage: "An error occurred while attempting to update the users roles. Please try again.",
  featureFlagOptionLabel_ALLOW_TLOS_SLOW_PATH: "Allow TLOS Slow Path",
  featureFlagOptionLabel_BYPASS_TLOS_SLOW_PATH: "Bypass TLOS Slow Path",
  featureFlagOptionLabel_CUSTOM_PRICING: "Custom Pricing",
  featureFlagOptionLabel_FOCUS_DATASETS: "Focus Datasets",
  featureFlagOptionLabel_FOCUS_TRE: "Focus TRE",
  featureFlagOptionLabel_MSP_INVOICING: "MSP Invoicing",
  featureFlagOptionLabel_TEXT_WIDGET: "Dashboard Text Widgets",
  featureFlagsModalTitle: "Feature Flags",
  featureFlagTableHeaderCreatedAt: "Created At",
  featureFlagTableHeaderCreatedBy: "Created By",
  featureFlagTableHeaderFlag: "Flag",
  filterStatusPlaceholderText: "Filter Status",
  filterTenantsPlaceholderText: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Filter Business Units",
    default: "Filter Tenants",
  },
  formTitleCreate: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Create New Business Unit",
    default: "Create New Tenant",
  },
  formTitleInformation: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Business Unit Information",
    default: "Tenant Information",
  },
  formTitleUpdate: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Update Business Unit",
    default: "Update Tenant",
  },
  globalRolesTabLabel: "Global Roles",
  goBackButtonLabel: "Go Back",
  groupedLastRefreshTooltip: "This date represents the oldest data refresh.",
  integrationsIncludeBigQueryLabel: "Included BigQuery data",
  integrationsSelectedLabel: "Selected",
  integrationsSettingCaption: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: `Integration configuration and optimizations are enabled by default for all business units. Partners can customize settings for individual or all business units by going to the <link>Business Units Settings tab.</link>`,
    default: `Integration configuration and optimizations are enabled by default for all child tenants. Partners can customize settings for individual or all child tenants by going to the <link>MSP Customer Management Settings tab.</link>`
  },
  integrationsSettingHeader: "Integrations Settings",
  integrationsTabLabel: "Integrations",
  isInternalLabel: "Internal Tenant?",
  lastRefresh: "%VALUE% ago",
  modalTitleMspPartnerTenants: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Business Units",
    default: "MSP Tenants",
  },
  mspChildAddSubaccountCaption: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Create integrations for this business unit by selecting the relevant subaccounts below",
    default: "Create integrations for this child tenant by selecting the relevant subaccounts below"
  },
  mspChildIntegrationDocsCaption: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Prior to configuring cloud integrations for your business units please review this %link% to understand the nuances between integrated billing and separate billing deployments",
    default: "Prior to configuring cloud integrations for your child tenants please review this %link% to understand the nuances between integrated billing and separate billing deployments"
  },
  mspChildIntegrationDocsLink: "documentation article",
  mspChildReviewAddSubaccountCaption: "Integrations will be created after submitting.",
  mspChildReviewUpdateSubaccountCaption: "Integrations will be updated after submitting.",
  mspChildUpdateSubaccountCaption: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Update integrations for this child business unit by selecting / deselecting the relevant subaccounts below",
    default: "Update integrations for this child tenant by selecting / deselecting the relevant subaccounts below",
  },
  mspIncludeBigQueryExcludeConfirmationMessage: `Are you sure you want to exclude BigQuery for this  Data Integration?`,
  mspIncludeBigQueryExcludeTitle: `BigQuery data will be exclude for all subaccount in %name%`,
  mspIncludeBigQueryIncludeConfirmationMessage: `Are you sure you want to include BigQuery for this  Data Integration?`,
  mspIncludeBigQueryIncludeTitle: `BigQuery data will be included for all subaccount in %name%`,
  mspSharedIntegrationsFilterPlaceHolder: "Filter by Integration",
  mspSharedIntegrationsProviderPlaceHolder: "Filter by Provider",
  mspSharedIntegrationsRefreshStatus_1: "integration(s) initial data ingestion has not completed.",
  mspSharedIntegrationsRefreshStatus_2: "integration(s) have not been refreshed in the last 24 hours.",
  mspSharedIntegrationsRefreshStatus_3: "integration(s) have not been refreshed in the last 8 hours.",
  mspSharedIntegrationsRefreshStatus_4: "integration(s) have been refreshed in the last 8 hours.",
  mspSharedIntegrationsReviewLabel: "Review Selection",
  mspSharedIntegrationsTableHeaderAssignment: "Assignment",
  mspSharedIntegrationsTableHeaderID: "ID",
  mspSharedIntegrationsTableHeaderLastRefresh: "Last Refresh",
  mspSharedIntegrationsTableHeaderLastRefreshTooltip: "Last Refresh shows when Ternary last completed data ingestion.",
  mspSharedIntegrationsTableHeaderName: "Integration Name",
  mspSharedIntegrationsTableHeaderProvider: "Provider",
  mspSharedIntegrationsTableHeaderSubAccountID: "Subaccount ID",
  mspSharedIntegrationsTableHeaderSubAccountName: "Subaccount Name",
  mspStepCount: "Step %Step% of 2",
  mspSubaccountFormHeaderStep_1: "%action% Subaccounts",
  mspSubaccountFormHeaderStep_2: "Review Subaccount Selections",
  permissionModalTitle: "Permissions",
  reasonInputLabel: "Reason",
  removeFeatureFlagLabel: "Remove Feature Flag",
  removeFeatureFlagWarningText: "Are you sure you want to delete this feature flag? This operation cannot be undone.",
  reportsTabLabel: "Reports",
  revokeUserGlobalRolesConfirmationMessage: "Are you sure you want to revoke all global roles from %name%?",
  revokeUserGlobalRolesConfirmationTitle: "Revoke Roles",
  rolesTabLabel: "Roles",
  rolesTableHeaderCreatedAt: "Created At",
  rolesTableHeaderCreatedBy: "Created By",
  rolesTableHeaderID: "ID",
  rolesTableHeaderName: "Name",
  rolesTableHeaderUpdatedAt: "Updated At",
  rolesTableHeaderUpdatedBy: "Updated By",
  searchSubaccountInputPlaceholder: "Search Subacount...",
  settingsTabLabel: "Settings",
  statusInputLabel: "Active",
  successAddingFeatureFlagsMessage: "The feature flag has been successfully added.",
  successCreateingMspSharedPayerIntegrationsMessage: "The MSP Subaccounts have been successfully created.",
  successCreatingTenantMessage: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "The business unit has been successfully created.",
    default: "The tenant has been successfully created.",
  },
  successGrantingUsersGlobalRolesMessage: "All users have successfully been granted global roles.",
  successRemovingFeatureFlagsMessage: "The feature flag has been successfully removed.",
  successRevokingUserGlobalRolesMessage: "The users global roles have been successfully revoked.",
  successUpdateMspSharedPayerIntegrationsMessage: "The MSP Subaccounts have been successfully Updated.",
  successUpdatingTenantMessage: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "The business unit has been successfully updated.",
    default: "The tenant has been successfully updated.",
  },
  successUpdatingUserTenantRolesMessage: "The users roles have been successfully updated.",
  tableEmptyPlaceholderText: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "No Business Units to Display",
    default: "No Tenants to Display",
  },
  tableHeaderCreatedAt: "Created At",
  tableHeaderDateRange: "Date Range",
  tableHeaderExpiresOn: "Expires On",
  tableHeaderID: "ID",
  tableHeaderName: "Name",
  tableHeaderOwner: "Owner",
  tableHeaderServiceAccountEmail: "Service Account",
  tableHeaderStatus: "Status",
  tableHeaderStatusTooltip_1: " Incomplete indicates initial data ingestion has not completed",
  tableHeaderStatusTooltip_2: " Danger indicates data was not refreshed within the last 24 hours",
  tableHeaderStatusTooltip_3: " Warning indicates data was refreshed within the last 24 hours",
  tableHeaderStatusTooltip_4: " Success indicates data was refreshed within the last 8 hours",
  tableHeaderStatusTooltip: "Status indicates when Ternary last refreshed your data.",
  tableHeaderType: "Type",
  tableTypeColumnLabel_DIRECT_CUSTOMER: "Direct",
  tableTypeColumnLabel_INTERNAL: "Internal",
  tableTypeColumnLabel_MSP_CUSTOMER: "Channel Customer",
  tableTypeColumnLabel_MSP_PARENT: "MSP",
  tenantActionOption_CREATE: "Create integrations",
  tenantActionOption_DELETE: "Delete Subaccounts",
  tenantActionOption_UPDATE: "Update integrations",
  tenantCountLabel: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Business Units",
    default: "Tenants",
  },
  tenantsTabLabel: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Business Units",
    default: "Tenants",
  },
  tenantStatusLabel_ACTIVE_POV: "POV",
  tenantStatusLabel_ACTIVE: "Active",
  tenantStatusLabel_INACTIVE_DATA_DELETED: "Inactive Data Deleted",
  tenantStatusLabel_INACTIVE: "Inactive",
  tenantTypeOption_ALL: "All",
  tenantTypeOption_DIRECT_CUSTOMER: "Direct",
  tenantTypeOption_MSP_CUSTOMER: "Channel Customer",
  tenantTypeOption_MSP_PARENT: "MSP",
  typeInputLabel: "Type",
  unnassignedActivityCaption: "Unassigned",
  userCountLabel: "Users",
  usersTabLabel: "Users",
  viewPermissionsButtonLabel: "View Permissions",
  viewTenantSubaccountsButtonLabel: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "View Business Unit Subaccounts",
    default: "View Tenant Subaccounts",
  },
} as const;
