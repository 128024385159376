import { useQuery } from "@tanstack/react-query";
import { DataSource, TimeGranularity } from "@ternary/api-lib/analytics/enums";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import UError from "unilib-error";
import useBuildDataQuery from "../../../api/analytics/utils/useDataQuery";
import { ANALYTICS_QUERY_GC_TIME } from "../../../constants";
import { useAnalyticsApiClient } from "../../../context/AnalyticsQueryLoaderProvider";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import { mspRollupDimensions, mspRollupMeasures } from "../mspRollupSchema";

type BaseRow = {
  billingAccountId?: string;
  billedCost: number;
  listCost: number;
  provider?: string;
  region?: string;
  serviceName?: string;
  tenantId: string;
  userCost: number;
};

type TimestampConditional<TGranularity> = TGranularity extends TimeGranularity
  ? { timestamp: string }
  : Record<string, never>;

export type MspSpendDataRow<TGranularity> = BaseRow &
  TimestampConditional<TGranularity>;

interface Params<TGranularity> {
  dateRange: Date[];
  dimensions: string[];
  granularity?: TGranularity;
  parentTenantID: string;
  preAggFilters: QueryFilter[];
}

type Options<TGranularity> = UseQueryOptions<
  MspSpendDataRow<TGranularity>[],
  UError
>;
type Result<TGranularity> = UseQueryResult<
  MspSpendDataRow<TGranularity>[],
  UError
>;

export default function useGetMspSpendData<
  TGranularity extends TimeGranularity | undefined = undefined,
>(
  params: Params<TGranularity>,
  options?: Options<TGranularity>
): Result<TGranularity> {
  const client = useAnalyticsApiClient();
  const gatekeeper = useGatekeeper();

  const { dimensions, parentTenantID, ...restParams } = params;

  const [_, query] = useBuildDataQuery({
    ...restParams,
    isFiscalMode: false,
    dataSource: DataSource.MSP_ROLLUP,
    dimensions: [mspRollupDimensions.tenantID, ...dimensions],
    measures: Object.values(mspRollupMeasures),
  });

  return useQuery({
    queryKey: ["mspSpendData", params],
    queryFn: async () => {
      const result = await client.loadData<MspSpendDataRow<TGranularity>>(
        parentTenantID,
        query
      );

      return result.response;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    enabled: gatekeeper.canViewCostReports,
    ...options,
  });
}
