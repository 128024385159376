import { RawData } from "@ternary/api-lib/analytics/types";
import Box from "@ternary/web-ui-lib/components/Box";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";

interface Props {
  data: RawData[];
  fileName: string;
  headers: { label: string; key: string }[];
}

export default function CSVDownloader(props: Props): JSX.Element {
  const linkRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    setTimeout(() => {
      linkRef.current?.click();
    }, 250);
  }, []);

  return (
    <CSVLink
      data={props.data}
      filename={props.fileName}
      headers={props.headers}
      target="_blank"
    >
      <span ref={linkRef} />
    </CSVLink>
  );
}

type CSVData = {
  data: RawData[];
  fileName: string;
  headers: { label: string; key: string }[];
};

export function useCSVDownloader() {
  const [key, setKey] = useState(0);
  const [csvDownloadProps, setCSVDownloaderProps] = useState<CSVData | null>(
    null
  );

  const downloadCSV = (csvData: CSVData) => {
    if (csvData.data.length === 0) return;
    setCSVDownloaderProps(csvData);
    setKey((current) => current + 1);
  };

  return {
    downloadCSV,
    csvElement: csvDownloadProps && (
      <InstantCSVDownloader key={key} {...csvDownloadProps} />
    ),
  };
}

function InstantCSVDownloader(props: CSVData) {
  const linkRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    linkRef.current?.click();
  }, []);

  return (
    <Box display="none">
      <CSVLink
        data={props.data}
        filename={props.fileName}
        headers={props.headers}
        target="_blank"
      >
        <span ref={linkRef} />
      </CSVLink>
    </Box>
  );
}
