import { useTheme } from "@emotion/react";
import {
  faClose,
  faPlus,
  faSearch,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { partition } from "lodash";
import React, { useRef, useState } from "react";
import { FixedSizeList } from "react-window";
import copyText from "../copyText";
import TextInput from "./TextInput";

const ITEM_HEIGHT = 36;
const TOTAL_LIST_HEIGHT = 240;
const LIST_HEIGHT = TOTAL_LIST_HEIGHT - 40;

type Option = {
  label: string;
  value: string;
};

type Props = {
  isLoading?: boolean;
  label?: string | JSX.Element;
  options: Option[];
  selectedOptions: string[];
  variant?: "success" | "error";
  onChange: (selectedOptions: string[]) => void;
};

export default function DualListbox(props: Props): JSX.Element {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [searchText, setSearchText] = useState("");

  const filteredOptions = React.useMemo(() => {
    return props.options.filter((option) => {
      if (searchText.length === 0) return true;
      return option.label.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [props.options, searchText]);

  const filteredOptionValues = React.useMemo(() => {
    return new Set(filteredOptions.map(({ value }) => value));
  }, [filteredOptions]);

  const [selectedOptions, unselectedOptions] = React.useMemo(() => {
    return partition(props.options, (option) =>
      props.selectedOptions.includes(option.value)
    );
  }, [props.options, props.selectedOptions]);

  const filteredSelectedOptions = React.useMemo(() => {
    return selectedOptions.filter((option) => {
      if (searchText.length === 0) return true;
      return option.label.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [selectedOptions, searchText]);

  const filteredSelectedOptionValues = React.useMemo(() => {
    return new Set(filteredSelectedOptions.map(({ value }) => value));
  }, [filteredSelectedOptions]);

  const filteredUnselectedOptions = React.useMemo(() => {
    return unselectedOptions.filter(({ value }) =>
      filteredOptionValues.has(value)
    );
  }, [unselectedOptions, filteredOptionValues]);

  function handleSelect(option: string) {
    const nextSelectedOptions = [...props.selectedOptions, option];
    props.onChange(nextSelectedOptions);
    inputRef.current?.focus();
  }

  function handleUnselect(value: string) {
    const nextSelectedOptions = props.selectedOptions.filter(
      (option) => option !== value
    );

    props.onChange(nextSelectedOptions);
    inputRef.current?.focus();
  }

  function handleSelectAll() {
    const allOptions = props.options.reduce((accum: string[], option) => {
      if (
        filteredOptionValues.has(option.value) ||
        props.selectedOptions.includes(option.value)
      ) {
        accum.push(option.value);
      }
      return accum;
    }, []);

    props.onChange(allOptions);
    inputRef.current?.focus();
  }

  function handleUnselectAll() {
    const allOptions = selectedOptions.reduce((accum: string[], option) => {
      if (!filteredSelectedOptionValues.has(option.value)) {
        accum.push(option.value);
      }
      return accum;
    }, []);

    props.onChange(allOptions);
    inputRef.current?.focus();
  }

  //
  // Render
  //

  // Define item renderer for the selected list
  const renderSelectedItem = ({
    index,
    style,
  }: {
    index: number;
    style: React.CSSProperties;
  }) => {
    const option = filteredSelectedOptions[index];

    return (
      <div style={style}>
        <Option
          key={option.value}
          isSelected
          label={option.label}
          onClick={() => handleUnselect(option.value)}
        />
      </div>
    );
  };

  // Define item renderer for the unselected list
  const renderUnselectedItem = ({
    index,
    style,
  }: {
    index: number;
    style: React.CSSProperties;
  }) => {
    const option = filteredUnselectedOptions[index];

    return (
      <div style={style}>
        <Option
          key={option.value}
          isSelected={false}
          label={option.label}
          onClick={() => handleSelect(option.value)}
        />
      </div>
    );
  };

  function renderLabel() {
    if (!props.label) {
      // Placholder for Flex to work.
      return <div></div>;
    }

    if (typeof props.label === "string") {
      return <Text fontSize={theme.fontSize_base}>{props.label}</Text>;
    }

    return props.label;
  }

  return (
    <Box height="100%" width="100%">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginBottom={theme.space_sm}
      >
        {renderLabel()}
        <Box width={240}>
          <TextInput
            iconEnd={
              <Icon
                color={theme.text_color_secondary}
                clickable
                icon={searchText.length > 0 ? faClose : faSearch}
                onClick={() => {
                  setSearchText("");
                  inputRef.current?.focus();
                }}
              />
            }
            inputRef={inputRef}
            placeholder={copyText.searchInputPlaceholder}
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                handleSelectAll();
                setSearchText("");
              }
            }}
          />
        </Box>
      </Flex>

      <Flex gap={theme.space_xs} height={TOTAL_LIST_HEIGHT}>
        <Flex direction="column" width="50%">
          {/* Add All Button */}
          <Box marginBottom={theme.space_xs}>
            <Button
              disabled={filteredUnselectedOptions.length === 0}
              secondary
              size="tiny"
              type="button"
              onClick={() => handleSelectAll()}
            >
              {copyText.addAllButtonLabel}
            </Button>
          </Box>

          {/* Can Add List */}
          <Box
            border={`2px solid ${
              props.variant === "error"
                ? theme.feedback_negative_outline
                : theme.secondary_color_border
            }`}
            borderRadius={theme.borderRadius_2}
            height={"100%"}
            padding={theme.space_xxs}
          >
            {props.isLoading ? (
              <EmptyPlaceholder loading={props.isLoading} />
            ) : (
              <FixedSizeList
                height={LIST_HEIGHT}
                itemCount={filteredUnselectedOptions.length}
                itemSize={ITEM_HEIGHT}
                width="100%"
              >
                {renderUnselectedItem}
              </FixedSizeList>
            )}
          </Box>
        </Flex>

        <Flex direction="column" width="50%">
          {/* Remove All Button */}
          <Box marginBottom={theme.space_xs}>
            <Button
              disabled={filteredSelectedOptions.length === 0}
              secondary
              size="tiny"
              type="button"
              onClick={() => handleUnselectAll()}
            >
              {copyText.removeAllButtonLabel}
            </Button>
          </Box>

          {/* Can Remove List */}
          <Box
            border={`2px solid ${
              props.variant === "error"
                ? theme.feedback_negative_outline
                : theme.secondary_color_border
            }`}
            borderRadius={theme.borderRadius_2}
            height={"100%"}
            padding={theme.space_xxs}
          >
            {props.isLoading ? (
              <EmptyPlaceholder loading={props.isLoading} />
            ) : (
              <FixedSizeList
                height={LIST_HEIGHT}
                itemCount={filteredSelectedOptions.length}
                itemSize={ITEM_HEIGHT}
                width="100%"
              >
                {renderSelectedItem}
              </FixedSizeList>
            )}
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}

type OptionProps = {
  label: string;
  isSelected: boolean;
  onClick: () => void;
};

function Option(props: OptionProps) {
  const theme = useTheme();

  return (
    <Button fullWidth size="small" type="button" onClick={props.onClick}>
      <Flex alignItems="center" justifyContent="space-between">
        <Text truncate>{props.label}</Text>
        <Icon
          color={theme.text_color_secondary}
          icon={props.isSelected ? faXmark : faPlus}
        />
      </Flex>
    </Button>
  );
}
