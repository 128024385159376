import { useTheme } from "@emotion/react";
import {
  formatCurrency,
  formatPercentage,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import { AzureCommitmentLookbackPeriod } from "@ternary/api-lib/constants/enums";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import React from "react";
import Meter from "../../../../components/Meter";
import copyText from "../../copyText";
import { AzureCommitmentInventoryTotal } from "../types";

type Props = {
  commitmentCount: number;
  coveragePercentage: number;
  inventoryTotals: AzureCommitmentInventoryTotal | null;
  isLoading: boolean;
  lookback: AzureCommitmentLookbackPeriod;
};

export default function AzureCommittedUseVisibilityMeters(props: Props) {
  const theme = useTheme();

  const meterValues = getVisibilityMeterValues(
    props.commitmentCount,
    props.inventoryTotals
  );

  return (
    <Flex
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_1}
      padding={theme.space_md}
      paddingVertical={theme.space_sm}
      overflowX="auto"
    >
      <Box flex="1 0 175px">
        <Meter
          header={copyText.azureMeterVis_0_totalCommitments}
          hideFooter
          loading={props.isLoading}
          value={String(meterValues.totalCommitments)}
        />
      </Box>

      <Box
        borderRight={`1px solid ${theme.border_color}`}
        flex="0 0 auto"
        marginHorizontal={theme.space_lg}
      />

      <Box flex="1 0 175px">
        <Meter
          header={copyText.azureTableHeader_totalCost}
          hideFooter
          loading={props.isLoading}
          value={formatCurrency({ number: meterValues.totalCost })}
        />
      </Box>

      <Box
        borderRight={`1px solid ${theme.border_color}`}
        flex="0 0 auto"
        marginHorizontal={theme.space_lg}
      />

      <Box flex="1 0 175px">
        <Meter
          header={copyText.azureMeterVis_1_recurringCost}
          hideFooter
          loading={props.isLoading}
          value={formatCurrency({ number: meterValues.recurringCost })}
        />
      </Box>

      <Box
        borderRight={`1px solid ${theme.border_color}`}
        flex="0 0 auto"
        marginHorizontal={theme.space_lg}
      />

      <Box flex="1 0 175px">
        <Meter
          header={copyText.azureMeterVis_2_utilizationPercentage}
          hideFooter
          loading={props.isLoading}
          value={formatPercentage(meterValues.utilizationPercentage)}
        />
      </Box>
    </Flex>
  );
}

function getVisibilityMeterValues(
  commitmentCount: number,
  inventory: AzureCommitmentInventoryTotal | null
) {
  if (!inventory) {
    return {
      totalCommitments: commitmentCount,
      recurringCost: 0,
      totalCost: 0,
      utilizationPercentage: 0,
    };
  }

  return {
    totalCommitments: commitmentCount,
    recurringCost: inventory.recurringCost,
    totalCost: inventory.totalCost,
    utilizationPercentage: inventory.avgUtilizationPercent / 100,
  };
}
