import { createColumnHelper } from "@tanstack/react-table";
import Table, {
  ActionMenuButton,
} from "@ternary/api-lib/ui-lib/components/Table/Table";
import React, { useMemo } from "react";
import Dropdown from "../../../../ui-lib/components/Dropdown";
import { TenantStatusLabel } from "../../../global-admin/components/TenantTable";
import copyText from "../copyText";

type Tenant = { id: string; name: string; status: string };

type TableData = Tenant;

const columnHelper = createColumnHelper<TableData>();

interface Props {
  isLoading: boolean;
  tenants: TableData[];
  onInteraction: (
    interaction: MspBillingStatementTenantTable.Interaction
  ) => void;
}

export function MspBillingStatementTenantTable(props: Props) {
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("name", {
        header: copyText.tableHeaderName,
        size: 150,
      }),
      columnHelper.accessor("status", {
        header: copyText.tableHeaderStatus,
        cell: TenantStatusLabel,
        size: 80,
      }),
      columnHelper.display({
        id: "actionMenu",
        cell: ({ row }) => (
          <ActionDropdown
            tenantID={row.original.id}
            onInteraction={props.onInteraction}
          />
        ),
        size: 40,
        meta: { align: "right" },
      }),
    ];
  }, [props.tenants]);

  return (
    <Table
      columns={columns}
      data={props.tenants}
      initialState={{ sorting: [{ id: "name", desc: false }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

interface ActionDropdownProps {
  tenantID: string;
  onInteraction: (
    interaction: MspBillingStatementTenantTable.Interaction
  ) => void;
}

function ActionDropdown(props: ActionDropdownProps): JSX.Element {
  const options = [
    {
      label: copyText.actionMenuItemViewStatements,
      onClick: () =>
        props.onInteraction({
          type: MspBillingStatementTenantTable.INTERACTION_VIEW_BILLING_STATEMENTS_CLICKED,
          tenantID: props.tenantID,
        }),
    },
    {
      label: copyText.actionMenuItemCreateStatement,
      onClick: () =>
        props.onInteraction({
          type: MspBillingStatementTenantTable.INTERACTION_CREATE_BILLING_STATEMENT_CLICKED,
          tenantID: props.tenantID,
        }),
    },
  ];

  return (
    <Dropdown options={options} placement="bottom-end">
      <ActionMenuButton />
    </Dropdown>
  );
}

MspBillingStatementTenantTable.INTERACTION_CREATE_BILLING_STATEMENT_CLICKED =
  "MspBillingStatementTenantTable.INTERACTION_CREATE_BILLING_STATEMENT_CLICKED" as const;
MspBillingStatementTenantTable.INTERACTION_VIEW_BILLING_STATEMENTS_CLICKED =
  "MspBillingStatementTenantTable.INTERACTION_VIEW_BILLING_STATEMENTS_CLICKED" as const;

type InteractionCreateBillingStatementClicked = {
  type: typeof MspBillingStatementTenantTable.INTERACTION_CREATE_BILLING_STATEMENT_CLICKED;
  tenantID: string;
};

type InteractionViewBillingStatementsClicked = {
  type: typeof MspBillingStatementTenantTable.INTERACTION_VIEW_BILLING_STATEMENTS_CLICKED;
  tenantID: string;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MspBillingStatementTenantTable {
  export type Interaction =
    | InteractionCreateBillingStatementClicked
    | InteractionViewBillingStatementsClicked;
}

export default MspBillingStatementTenantTable;
