import { actions } from "@ternary/api-lib/telemetry";
import React from "react";
import paths from "../../../../constants/paths";
import { useActivityTracker } from "../../../../context/ActivityTrackerProvider";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import { useNavigateWithSearchParams } from "../../../../lib/react-router";
import { useMspStore } from "../../../../lib/zustand";
import useGetTenantsByParentTenantID from "../../../global-admin/hooks/useGetTenantsByParentTenantID";
import MspBillingStatementTenantTable from "./MspBillingStatementTenantTable";

type Interaction = MspBillingStatementTenantTable.Interaction;

export function MspBillingStatementTenantsContainer() {
  const activityTracker = useActivityTracker();
  const gatekeeper = useGatekeeper();
  const navigate = useNavigateWithSearchParams();

  const mspStore = useMspStore();

  //
  // Queries
  //

  const { data: _mspTenants = [], isLoading: isLoadingMspTenants } =
    useGetTenantsByParentTenantID(mspStore.selectedParentTenantID ?? "", {
      enabled:
        gatekeeper.canReadTenantsPartner &&
        mspStore.selectedParentTenantID !== null,
    });

  //
  // Interaction Handlers
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case MspBillingStatementTenantTable.INTERACTION_CREATE_BILLING_STATEMENT_CLICKED: {
        activityTracker.captureAction(
          actions.CLICK_MSP_BILLING_STATEMENT_CREATE_SHORTCUT
        );

        const path = paths._mspBillingStatementsTenantDetails.replace(
          ":tenantID",
          interaction.tenantID
        );

        navigate(path, { state: { showSideDrawer: true } });
        return;
      }
      case MspBillingStatementTenantTable.INTERACTION_VIEW_BILLING_STATEMENTS_CLICKED: {
        navigate(
          paths._mspBillingStatementsTenantDetails.replace(
            ":tenantID",
            interaction.tenantID
          )
        );
        return;
      }
    }
  }

  return (
    <MspBillingStatementTenantTable
      isLoading={isLoadingMspTenants}
      tenants={_mspTenants}
      onInteraction={handleInteraction}
    />
  );
}
