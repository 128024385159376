import Permission from "@ternary/api-lib/constants/permissions";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type CostAssistGates = ReturnType<typeof costAssistGates>;

export default function costAssistGates(params: GateGetterParameters) {
  const canCreateRampPlans = params._permissionsIncludeAllOf([
    Permission.CREATE_RAMP_PLAN,
  ]);

  const canCreateReallocations = params._permissionsIncludeAllOf([
    Permission.CREATE_REALLOCATION,
  ]);

  const canDeleteRampPlans = params._permissionsIncludeAllOf([
    Permission.DELETE_RAMP_PLAN,
  ]);

  const canDeleteReallocations = params._permissionsIncludeAllOf([
    Permission.DELETE_REALLOCATION,
  ]);

  const canExecuteReallocations = params._permissionsIncludeAllOf([
    Permission.TRIGGER_REALLOCATION,
  ]);

  const canListRampPlans = params._permissionsIncludeAllOf([
    Permission.READ_RAMP_PLANS,
  ]);

  const canListReallocationJobs = params._permissionsIncludeAllOf([
    Permission.READ_REALLOCATION_JOBS,
  ]);

  const canListReallocations = params._permissionsIncludeAllOf([
    Permission.READ_REALLOCATIONS,
  ]);

  const canUpdateRampPlans = params._permissionsIncludeAllOf([
    Permission.UPDATE_RAMP_PLAN,
  ]);

  const canUpdateReallocations = params._permissionsIncludeAllOf([
    Permission.UPDATE_REALLOCATION,
  ]);

  const costAssistGates = {
    canCreateRampPlans,
    canCreateReallocations,
    canDeleteReallocations,
    canExecuteReallocations,
    canListRampPlans,
    canListReallocationJobs,
    canListReallocations,
    canUpdateRampPlans,
    canUpdateReallocations,
  };

  const canViewCostAssist = Object.values(costAssistGates).every(
    (gate) => gate === true
  );

  return {
    ...costAssistGates,
    canDeleteRampPlans,
    canViewCostAssist,
  };
}
