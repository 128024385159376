import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import TimeSeriesChartTooltip from "@ternary/api-lib/analytics/ui/TimeSeriesChartTooltip";
import { formatCurrencyRounded } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Text from "@ternary/web-ui-lib/components/Text";
import { isSameDay } from "date-fns";
import React, { useMemo } from "react";
import {
  Bar,
  BarChart,
  Tooltip as ChartTooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { BudgetSpendEntity } from "../../../api/analytics/types";
import Legend from "../../../components/Legend";
import timing from "../../../constants/timing";
import Select from "../../../ui-lib/components/Select";
import { getUTCMonthString } from "../../../utils/dates";
import getCn from "../../../utils/getCn";
import copyText from "../copyText";
import { Budget } from "../types";
import {
  getPeriodVersionsGroupedByMonth,
  getSortedBudgetPeriodVersions,
  getSortedMonthsFromPeriodVersions,
  getYearsFromBudget,
} from "../utils";

const COMPONENT_NAME = "BudgetVersionsMonthlyBarChart";
const cn = getCn(COMPONENT_NAME);

type MonthSpend = {
  month: string;
  actualSpend: number | null;
  highlightedVersion: number | null;
  latestVersion: number | null;
};

type ChartData = MonthSpend[];

const StyledDiv = styled.div`
  width: 100%;

  .${cn("year-select")} {
    margin-top: -3.5rem;
    float: right;
    width: 10rem;
  }
`;

interface Props {
  budget: Budget;
  height: number;
  loading: boolean;
  selectedYear: number;
  spendData: BudgetSpendEntity[];
  onSelectYear: (selectedYear: number) => void;
}

export default function BudgetVersionedMonthlyBarChart(
  props: Props
): JSX.Element {
  const theme = useTheme();

  const data: ChartData = useMemo(() => {
    if (!props.budget) return [];

    const sortedMonths = getSortedMonthsFromPeriodVersions(props.budget, true);
    const versionsGroupedByMonth = getPeriodVersionsGroupedByMonth(
      props.budget
    );

    const output: MonthSpend[] = [];
    const months = sortedMonths.filter((dateString) =>
      dateString.includes(props.selectedYear.toString())
    );

    for (let i = 0; i < months.length; i++) {
      const monthString = months[i];
      const actualSpend = props.spendData.find((sd) => {
        const transformedDate = new Date(sd.timestamp);
        transformedDate.setUTCHours(0);

        return isSameDay(transformedDate, new Date(monthString));
      });

      const thisMonthsVersions = versionsGroupedByMonth[monthString] || [];

      const highlightedVersion = thisMonthsVersions.find(
        (version) => version.highlighted
      );
      const latestVersion = getSortedBudgetPeriodVersions(
        thisMonthsVersions,
        true
      )[0];

      output.push({
        month: monthString,
        actualSpend: actualSpend
          ? actualSpend[props.budget?.measure ?? ""]
          : null,
        highlightedVersion: highlightedVersion
          ? highlightedVersion.amount
          : null,
        latestVersion: latestVersion ? latestVersion.amount : null,
      });
    }
    return output;
  }, [props.budget, props.spendData, props.selectedYear]);

  const dataKeyedByDate = data.reduce((accum, datum) => {
    accum[datum.month] = {
      [copyText.budgetVersionedMonthlyBarChartHighlighted]:
        datum.highlightedVersion,
      [copyText.budgetVersionedMonthlyBarChartLatest]: datum.latestVersion,
      [copyText.budgetVersionedMonthlyBarChartActual]: datum.actualSpend,
    };

    return accum;
  }, {});

  const hasHighlighted =
    data.find((datum) => datum.highlightedVersion) !== undefined;

  const yearOptions = getYearsFromBudget(props.budget).map((year) => ({
    label: year,
    value: year,
  }));

  return (
    <StyledDiv>
      <Select
        className={cn("year-select")}
        compact
        placeholder={copyText.unitYear}
        options={yearOptions}
        value={{ label: props.selectedYear, value: props.selectedYear }}
        onChange={(option) => option && props.onSelectYear(option.value)}
      />
      <Text appearance="h4" marginLeft={theme.space_sm}>
        {copyText.budgetVersionedMonthlyBarChartTitle}
      </Text>
      {!props.budget || props.loading ? (
        <EmptyPlaceholder
          icon={faBars}
          small
          loading={props.loading}
          skeletonVariant="cartesian"
          height="150px"
        />
      ) : (
        <>
          <ResponsiveContainer width="95%" height={props.height - 20}>
            <BarChart
              data={data}
              margin={{
                bottom: 5,
                left: 10,
                right: 0,
                top: 0,
              }}
            >
              <XAxis
                dataKey="month"
                stroke={theme.chart_axis_text}
                tick={{ fontSize: 12 }}
                tickSize={8}
                tickFormatter={getUTCMonthString}
              />
              <YAxis
                padding={{ top: 20 }}
                stroke={theme.chart_axis_text}
                tick={{ fontSize: 12 }}
                tickFormatter={(number) =>
                  formatCurrencyRounded({
                    number,
                  })
                }
                tickSize={8}
              />
              <ChartTooltip
                content={(e) => (
                  <TimeSeriesChartTooltip
                    customColors={[
                      theme.budgets_chart_fill_actual,
                      theme.budgets_chart_fill_latest_version,
                      theme.budgets_chart_fill_highlighted_version,
                    ]}
                    dateFormat="yyyy-MM"
                    entry={{
                      date: e.label,
                      ...dataKeyedByDate[e.label],
                    }}
                    excludedGroupings={[]}
                    hideTotal
                    label={e.label}
                    reverseSortedGroupings={[
                      copyText.budgetVersionedMonthlyBarChartHighlighted,
                      copyText.budgetVersionedMonthlyBarChartLatest,
                      copyText.budgetVersionedMonthlyBarChartActual,
                    ]}
                    tooltipDataKey={undefined}
                  />
                )}
              />
              <Bar
                animationDuration={timing.chartAnimationDuration}
                barSize={20}
                dataKey="highlightedVersion"
                fill={theme.budgets_chart_fill_highlighted_version}
              />
              <Bar
                animationDuration={timing.chartAnimationDuration}
                barSize={20}
                dataKey="latestVersion"
                fill={theme.budgets_chart_fill_latest_version}
              />
              <Bar
                animationDuration={timing.chartAnimationDuration}
                barSize={20}
                dataKey="actualSpend"
                fill={theme.budgets_chart_fill_actual}
              />
            </BarChart>
          </ResponsiveContainer>
          <Legend
            items={[
              [
                ...(hasHighlighted
                  ? [
                      {
                        color: theme.budgets_chart_fill_highlighted_version,
                        text: copyText.budgetVersionedMonthlyBarChartHighlighted,
                        opacity: "1",
                      },
                    ]
                  : []),
                {
                  color: theme.budgets_chart_fill_latest_version,
                  text: copyText.budgetVersionedMonthlyBarChartLatest,
                  opacity: "1",
                },
                {
                  color: theme.budgets_chart_fill_actual,
                  text: copyText.budgetVersionedMonthlyBarChartActual,
                  opacity: "1",
                },
              ],
            ]}
          />
        </>
      )}
    </StyledDiv>
  );
}
