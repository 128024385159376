import { LinkWithSearchParams } from "@/lib/react-router";
import { createStructParam } from "@/lib/use-query-params";
import { createSortingUtils } from "@/utils/sort";
import { useTheme } from "@emotion/react";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { formatCurrency } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useMemo } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import { S3BucketGroup, S3BucketGroupFilters } from "../types";

type TableData = {
  accountID: string;
  bucketCount: number;
  groupID: string;
  networkCost: number;
  operationsCost: number;
  region: string;
  storageCost: number;
  totalCost: number;
};

type Props = {
  bucketGroups: S3BucketGroup[];
  isLoadingBucketGroups: boolean;
  onInteraction: (interaction: AWSStorageS3GroupTable.Interaction) => void;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

const { numberSort, stringSort } = createSortingUtils<TableData>({
  validKeys: [
    "accountID",
    "bucketCount",
    "groupID",
    "networkCost",
    "operationsCost",
    "region",
    "storageCost",
  ],
});

function AWSStorageS3GroupTable(props: Props) {
  const theme = useTheme();
  const columnHelper = createColumnHelper<TableData>();

  const [sortRule, setSortRule] = useQueryParam(
    "buckets_group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "storageCost",
    })
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("groupID", {
        cell: ({ getValue }) => (
          <Tooltip content={copyText.storageSubTableViewBuckets}>
            <LinkWithSearchParams
              searchParams={{ selected_group_id: getValue() }}
            >
              <Button
                iconStart={<Icon icon={faTableList} />}
                primary
                size="tiny"
              />
            </LinkWithSearchParams>
          </Tooltip>
        ),
        enableSorting: false,
        size: 50,
      }),
      columnHelper.accessor("accountID", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: AWSStorageS3GroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "accountID",
                filterValue: getValue(),
              })
            }
          >
            {getValue() || copyText.s3TableNull}
          </Text>
        ),
        header: copyText.s3TableBucketGroupHeader_accountID,
        meta: { align: "left" },
        size: 60,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("region", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: AWSStorageS3GroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "region",
                filterValue: getValue(),
              })
            }
          >
            {getValue() || copyText.s3TableNull}
          </Text>
        ),
        header: copyText.s3TableBucketGroupHeader_region,
        meta: { align: "left" },
        size: 100,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("bucketCount", {
        cell: ({ getValue }) => getValue(),
        header: copyText.s3TableBucketGroupHeader_bucketCount,
        meta: { align: "right" },
        size: 60,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("storageCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.s3TableBucketGroupHeader_storageCost,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("networkCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.s3TableBucketGroupHeader_networkCost,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("operationsCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.s3TableBucketGroupHeader_operationsCost,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("totalCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.s3TableBucketGroupHeader_totalCost,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
    ],
    [props.bucketGroups]
  );

  const data: TableData[] = useMemo(
    () =>
      props.bucketGroups.map((bucketsGroup) => ({
        accountID: bucketsGroup.accountID,
        bucketCount: bucketsGroup.buckets.length,
        groupID: bucketsGroup.groupID,
        networkCost: bucketsGroup.networkCost,
        operationsCost: bucketsGroup.operationsCost,
        region: bucketsGroup.region,
        storageCost: bucketsGroup.storageCost,
        totalCost: bucketsGroup.cost,
      })),
    [props.bucketGroups]
  );

  return (
    <Table
      columns={columns}
      data={data}
      initialState={{ sorting: [sortRule] }}
      isLoading={props.isLoadingBucketGroups}
      showPagination
      sortable
      onChangeSortBy={([sortRule]) => setSortRule(sortRule)}
    />
  );
}

AWSStorageS3GroupTable.INTERACTION_FILTER_CLICKED =
  `AWSStorageS3GroupTable.INTERACTION_FILTER_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof AWSStorageS3GroupTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof S3BucketGroupFilters;
  filterValue: S3BucketGroupFilters[keyof S3BucketGroupFilters];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AWSStorageS3GroupTable {
  export type Interaction = InteractionFilterClicked;
}

export default AWSStorageS3GroupTable;
