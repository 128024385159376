import { createColumnHelper } from "@tanstack/react-table";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import Table, {
  ActionMenuButton,
} from "@ternary/api-lib/ui-lib/components/Table/Table";
import { endOfMonth } from "date-fns";
import React, { useMemo } from "react";
import paths from "../../../../constants/paths";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import { useNavigateWithSearchParams } from "../../../../lib/react-router";
import Dropdown from "../../../../ui-lib/components/Dropdown";
import copyText from "../copyText";

type BillingStatement = {
  id: string;
  createdAt: string;
  createdByEmail: string;
  endDate: string | null;
  invoiceMonth: string | null;
  name: string;
  startDate: string | null;
};

type TableData = BillingStatement;

const columnHelper = createColumnHelper<TableData>();

interface Props {
  billingStatements: TableData[];
  disableStatementExport: boolean;
  isLoading: boolean;
  onInteraction: (interaction: MspBillingStatementTable.Interaction) => void;
}

export function MspBillingStatementTable(props: Props) {
  const navigate = useNavigateWithSearchParams();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("name", {
        header: copyText.tableHeaderName,
        size: 150,
      }),
      columnHelper.accessor("createdAt", {
        header: copyText.tableHeaderCreatedAt,
        cell: (cell) => formatDate(new Date(cell.getValue()), "MM/dd/yyyy"),
        size: 80,
      }),
      columnHelper.display({
        id: "dateRange",
        header: copyText.tableHeaderDateRange,
        cell: (cell) => {
          if (cell.row.original.invoiceMonth) {
            const startSplit = cell.row.original.invoiceMonth.split("/");

            const startDate = new Date(
              [startSplit[1], startSplit[0], "1"].join("-")
            );

            const endDate = endOfMonth(startDate);

            return `${formatDate(startDate, "MM/dd/yyyy")} - ${formatDate(endDate, "MM/dd/yyyy")}`;
          }

          const startDate = formatDate(
            new Date(cell.row.original.startDate ?? ""),
            "MM/dd/yyyy"
          );

          const endDate = formatDate(
            new Date(cell.row.original.endDate ?? ""),
            "MM/dd/yyyy"
          );

          return `${startDate} - ${endDate}`;
        },
        size: 80,
      }),
      columnHelper.accessor("createdByEmail", {
        header: copyText.tableHeaderOwner,
        size: 80,
      }),
      columnHelper.display({
        id: "actionMenu",
        cell: ({ row }) => (
          <ActionDropdown
            disableStatementExport={props.disableStatementExport}
            statementID={row.original.id}
            onInteraction={props.onInteraction}
          />
        ),
        size: 40,
        meta: { align: "right" },
      }),
    ];
  }, [props.billingStatements, props.isLoading]);

  return (
    <Table
      columns={columns}
      data={props.billingStatements}
      initialState={{ sorting: [{ id: "name", desc: false }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
      onClick={(row) =>
        navigate(
          paths._mspBillingStatementsTenantDetails.replace(":tenantID", row.id)
        )
      }
    />
  );
}

interface ActionDropdownProps {
  disableStatementExport: boolean;
  statementID: string;
  onInteraction: (interaction: MspBillingStatementTable.Interaction) => void;
}

function ActionDropdown(props: ActionDropdownProps): JSX.Element {
  const gatekeeper = useGatekeeper();

  const options = [
    {
      label: copyText.actionMenuItemExportStatement,
      locked:
        !gatekeeper.canViewMspBillingStatements || props.disableStatementExport,
      onClick: () =>
        props.onInteraction({
          type: MspBillingStatementTable.INTERACTION_EXPORT_BILLING_STATEMENT_CLICKED,
          statementID: props.statementID,
        }),
    },
    {
      label: copyText.actionMenuItemExportRawData,
      locked: !gatekeeper.canViewMspBillingStatements,
      onClick: () =>
        props.onInteraction({
          type: MspBillingStatementTable.INTERACTION_EXPORT_RAW_DATA_CLICKED,
          statementID: props.statementID,
        }),
    },
    {
      label: copyText.actionMenuItemDeleteStatement,
      locked: !gatekeeper.canDeleteMspBillingStatement,
      onClick: () =>
        props.onInteraction({
          type: MspBillingStatementTable.INTERACTION_DELETE_BILLING_STATEMENT_CLICKED,
          statementID: props.statementID,
        }),
    },
  ];

  return (
    <Dropdown options={options} placement="bottom-end">
      <ActionMenuButton />
    </Dropdown>
  );
}

MspBillingStatementTable.INTERACTION_DELETE_BILLING_STATEMENT_CLICKED =
  "MspBillingStatementTable.INTERACTION_DELETE_BILLING_STATEMENT_CLICKED" as const;
MspBillingStatementTable.INTERACTION_EXPORT_BILLING_STATEMENT_CLICKED =
  "MspBillingStatementTable.INTERACTION_EXPORT_BILLING_STATEMENT_CLICKED" as const;
MspBillingStatementTable.INTERACTION_EXPORT_RAW_DATA_CLICKED =
  "MspBillingStatementTable.INTERACTION_EXPORT_RAW_DATA_CLICKED" as const;

type InteractionDeleteBillingStatementClicked = {
  type: typeof MspBillingStatementTable.INTERACTION_DELETE_BILLING_STATEMENT_CLICKED;
  statementID: string;
};

type InteractionExportBillingStatementClicked = {
  type: typeof MspBillingStatementTable.INTERACTION_EXPORT_BILLING_STATEMENT_CLICKED;
  statementID: string;
};

type InteractionExportRawDataClicked = {
  type: typeof MspBillingStatementTable.INTERACTION_EXPORT_RAW_DATA_CLICKED;
  statementID: string;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MspBillingStatementTable {
  export type Interaction =
    | InteractionDeleteBillingStatementClicked
    | InteractionExportBillingStatementClicked
    | InteractionExportRawDataClicked;
}

export default MspBillingStatementTable;
