import { SelectDropdownFilter } from "@/ui-lib/components/SelectDropdownFilter";
import { useTheme } from "@emotion/react";
import {
  faEye,
  faEyeSlash,
  faFont,
  faPlus,
  faRandom,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { MetricAggregate, Operator } from "@ternary/api-lib/analytics/enums";
import { Dimension, Measure } from "@ternary/api-lib/analytics/ui/types";
import { LabelMap } from "@ternary/api-lib/core/types";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { uniq } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import SideDrawerLegacy from "../../../components/SideDrawerLegacy";
import { operatorOptions } from "../../../constants";
import Dropdown from "../../../ui-lib/components/Dropdown";
import Select from "../../../ui-lib/components/Select";
import SelectDropdown, {
  Option,
} from "../../../ui-lib/components/SelectDropdown";
import TextInput from "../../../ui-lib/components/TextInput";
import { isOperator } from "../../../utils/typeGuards";
import {
  FILTER_NAME_MIN_WIDTH,
  FILTER_NAME_MIN_WIDTH_EXPANDED,
  FILTER_VALUE_MIN_WIDTH,
  FILTER_VALUE_MIN_WIDTH_EXPANDED,
  SIDE_PANEL_WIDTH_OPEN,
} from "../constants";
import copyText from "../copyText";
import { FilterWithDate } from "../types";
import CustomMetricForm, { CustomMetricEntity } from "./CustomMetricForm";

const DEBOUNCE_SEARCH_MS = 550;

type DataDogMetricMap = { [metricID: string]: { name: string } };

type BqMetadata = { rowCount: number; schema: Record<string, string>[] };

type Props = {
  alias: string;
  availableMetricDimensions: Dimension[];
  bigQueryMetadata?: BqMetadata;
  customMetrics: CustomMetricEntity[];
  dimensions: Dimension[];
  externalDimensionValuesMap: { [key: string]: string[] };
  externalLabelMap?: LabelMap;
  externalMetrics?: DataDogMetricMap;
  formula: string;
  impactMode: boolean;
  isComparisonMode: boolean;
  isHiddenFormula: boolean;
  isHiddenMetric: boolean;
  isLoadingCustomMetrics: boolean;
  isLoadingBigQueryMetadata: boolean;
  isLoadingExternalDimensionValues: boolean;
  isLoadingIntegrations: boolean;
  isProcessing: boolean;
  isSideDrawerOpen: boolean;
  measures: Measure[];
  metric?: string;
  metricAggregate?: string;
  metricFilters: FilterWithDate[];
  selectedCustomMetric?: CustomMetricEntity;
  showUnitEconomics: boolean;
  width: number;
  onInteraction: (
    interaction:
      | CustomMetricForm.Interaction
      | ReportBuilderSidePanelUnitEconTab.Interaction
  ) => void;
};

export function ReportBuilderSidePanelUnitEconTab(props: Props) {
  const theme = useTheme();

  const [currentAlias, setCurrentAlias] = useState("");
  const [customFilter, setCustomFilter] = useState<string[]>([]);

  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setCurrentAlias(props.alias);

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [props.alias]);

  function handleAddMetricFilter(value: string): void {
    const dimension = props.availableMetricDimensions.find(
      (dimension) => dimension.name === value
    );

    if (!dimension) return;

    props.onInteraction({
      type: ReportBuilderSidePanelUnitEconTab.INTERACTION_ADD_METRIC_FILTER_CLICKED,
      metricFilter: {
        name: dimension.name,
        operator: Operator.EQUALS,
        values: [],
      },
    });
  }

  function handleChangeAlias(alias: string): void {
    if (debounceTimeout.current) {
      setCurrentAlias(alias);
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      props.onInteraction({
        type: ReportBuilderSidePanelUnitEconTab.INTERACTION_ALIAS_CHANGED,
        alias,
      });
    }, DEBOUNCE_SEARCH_MS);
  }

  function handleRemoveMetricFilter(index: number): void {
    props.onInteraction({
      type: ReportBuilderSidePanelUnitEconTab.INTERACTION_REMOVE_METRIC_FILTER_CLICKED,
      index,
    });
  }

  function handleUpdateMetricFilter(value: string, index: number): void {
    const dimension = props.availableMetricDimensions.find(
      (dimension) => dimension.name === value
    );

    if (!dimension) return;

    props.onInteraction({
      type: ReportBuilderSidePanelUnitEconTab.INTERACTION_UPDATE_METRIC_FILTER_CLICKED,
      metricFilter: {
        ...dimension,
        operator: Operator.EQUALS,
        values: [],
      },
      index,
    });
  }

  function handleUpdateFilterOperator(value: string, index: number): void {
    if (!isOperator(value)) return;

    props.onInteraction({
      type: ReportBuilderSidePanelUnitEconTab.INTERACTION_UPDATE_FILTER_OPERATOR_CLICKED,
      operator: value,
      index,
    });
  }

  function handleUpdateMetricFilterValues(
    value: string | string[],
    index: number
  ): void {
    props.onInteraction({
      type: ReportBuilderSidePanelUnitEconTab.INTERACTION_UPDATE_METRIC_FILTER_VALUES_CLICKED,
      values: Array.isArray(value) ? value : [value],
      index,
    });
  }

  function handleUpdateFormula(variableName: string, index: number) {
    props.onInteraction({
      type: ReportBuilderSidePanelUnitEconTab.INTERACTION_UPDATE_FORMULA,
      variableName,
      index,
    });
  }

  const dataDogMetricOptions = props.externalMetrics
    ? Object.entries(props.externalMetrics).map(([key, value]) => ({
        label: value.name,
        value: key,
      }))
    : [];

  const metricAggregateSelectOptions = [
    {
      label: copyText.selectMetricAggregateMaxLabel,
      value: MetricAggregate.MAX,
    },
    {
      label: copyText.selectMetricAggregateMeanLabel,
      value: MetricAggregate.MEAN,
    },
    {
      label: copyText.selectMetricAggregateMinLabel,
      value: MetricAggregate.MIN,
    },
    {
      label: copyText.selectMetricAggregateSumLabel,
      value: MetricAggregate.SUM,
    },
  ];

  const metricFilterOptions = props.availableMetricDimensions.reduce(
    (accum: Option[], metricDimension) =>
      props.metricFilters.some((filter) => filter.name === metricDimension.name)
        ? accum
        : [
            ...accum,
            { label: metricDimension.name, value: metricDimension.name },
          ],
    []
  );

  const formulaDropdownOptions = props.measures.map((_, i) => ({
    label: String.fromCharCode(i + 65),
    value: String.fromCharCode(i + 65),
  }));

  if (props.metric) {
    formulaDropdownOptions.push({ label: "X", value: "X" });
  }

  const nameTruncateWidth =
    props.width > SIDE_PANEL_WIDTH_OPEN
      ? props.width - FILTER_NAME_MIN_WIDTH_EXPANDED
      : FILTER_NAME_MIN_WIDTH;

  const valueTruncateWidth =
    props.width > SIDE_PANEL_WIDTH_OPEN
      ? props.width - FILTER_VALUE_MIN_WIDTH_EXPANDED
      : FILTER_VALUE_MIN_WIDTH;

  return (
    <Flex direction="column" paddingHorizontal={theme.space_md}>
      <Box>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          marginBottom={theme.space_sm}
        >
          <Text
            appearance="h4"
            color={props.impactMode ? theme.eco_impact : undefined}
            marginRight={theme.space_sm}
          >
            {copyText.customMetricFormTitle}
          </Text>
          <Button
            secondary
            size="small"
            onClick={() =>
              props.onInteraction({
                type: ReportBuilderSidePanelUnitEconTab.INTERACTION_TOGGLE_METRIC_TABLE,
              })
            }
          >
            {copyText.showCustomMetricsButtonLabel}
          </Button>
        </Flex>
        <Flex alignItems="center" marginBottom={theme.space_sm}>
          <Box marginRight={theme.space_xs} width={10}>
            <Text>X</Text>
          </Box>
          <Box marginRight={theme.space_xs} width={225}>
            <Select
              compact
              isLoading={props.isLoadingIntegrations}
              options={dataDogMetricOptions}
              placeholder={copyText.selectMetricPlaceholder}
              value={dataDogMetricOptions.find(
                (option) => props.metric && option.value === props.metric
              )}
              onChange={(option) => {
                if (!option) return;

                props.onInteraction({
                  type: ReportBuilderSidePanelUnitEconTab.INTERACTION_SELECT_METRIC_CLICKED,
                  id: option.value,
                });
              }}
            />
          </Box>
          <Box marginRight={theme.space_xs} width={115}>
            <Select
              compact
              options={metricAggregateSelectOptions}
              placeholder={copyText.selectMetricAggregatePlaceholder}
              value={metricAggregateSelectOptions.find(
                (option) => option.value === props.metricAggregate
              )}
              onChange={(option) => {
                if (!option) return;

                props.onInteraction({
                  type: ReportBuilderSidePanelUnitEconTab.INTERACTION_SELECT_METRIC_AGGREGATE_CLICKED,
                  value: option.value,
                });
              }}
            />
          </Box>
          <Button
            iconStart={
              <Icon icon={props.isHiddenMetric ? faEyeSlash : faEye} />
            }
            size="tiny"
            width={10}
            onClick={() =>
              props.onInteraction({
                type: ReportBuilderSidePanelUnitEconTab.INTERACTION_TOGGLE_METRIC,
              })
            }
          />
        </Flex>

        {/* Formula */}

        {props.measures.length + (props.metric ? 1 : 0) > 1 && (
          <Box marginVertical={theme.space_sm}>
            <Text
              appearance="h4"
              color={props.impactMode ? theme.eco_impact : undefined}
              marginBottom={theme.space_xs}
            >
              {copyText.formulaHeader}
            </Text>
            <Flex alignItems="center" marginBottom={theme.space_xs}>
              <Button
                iconStart={<Icon icon={faRandom} />}
                size="tiny"
                onClick={() =>
                  props.onInteraction({
                    type: ReportBuilderSidePanelUnitEconTab.INTERACTION_FLIP_FORMULA_CLICKED,
                  })
                }
              />
              <Box marginHorizontal={theme.space_sm} width={60}>
                <Select
                  compact
                  options={formulaDropdownOptions}
                  value={formulaDropdownOptions.find(
                    (option) =>
                      props.formula &&
                      option.value === props.formula.slice(0, 1)
                  )}
                  onChange={(option) =>
                    option && handleUpdateFormula(option?.value, 0)
                  }
                />
              </Box>
              <Text align="center">/</Text>
              <Box
                marginLeft={theme.space_sm}
                marginRight={theme.space_md}
                width={60}
              >
                <Select
                  compact
                  options={formulaDropdownOptions}
                  value={formulaDropdownOptions.find(
                    (option) =>
                      props.formula && option.value === props.formula.slice(-1)
                  )}
                  onChange={(option) =>
                    option && handleUpdateFormula(option?.value, 1)
                  }
                />
              </Box>
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              marginLeft={theme.space_xs}
            >
              <Flex alignItems="center">
                <Text align="center">
                  {copyText.unitEconomicsAliasAsCaption}
                </Text>
                <Box
                  marginLeft={theme.space_sm}
                  marginRight={theme.space_xs}
                  width={225}
                >
                  <TextInput
                    size="small"
                    placeholder={copyText.unitEconomicsAliasPlaceholder}
                    value={currentAlias}
                    onChange={(e) => handleChangeAlias(e.target.value)}
                  />
                </Box>
              </Flex>
              <Button
                iconStart={
                  <Icon icon={props.isHiddenFormula ? faEyeSlash : faEye} />
                }
                marginRight={theme.space_xs}
                size="tiny"
                width={10}
                onClick={() =>
                  props.onInteraction({
                    type: ReportBuilderSidePanelUnitEconTab.INTERACTION_TOGGLE_FORMULA,
                  })
                }
              />
            </Flex>
          </Box>
        )}

        {/* Metric Filters */}

        <Box marginBottom={theme.space_sm}>
          <Box marginBottom={theme.space_sm}>
            <SelectDropdown
              options={metricFilterOptions}
              placement="bottom-end"
              onChange={handleAddMetricFilter}
            >
              <Button fullWidth size="small">
                <Flex alignItems="center" justifyContent="space-between">
                  <Text
                    fontSize={theme.fontSize_base}
                    fontWeight={theme.fontWeight_regular}
                  >
                    {copyText.filtersHeader}
                  </Text>
                  <Flex alignItems="center">
                    <Icon icon={faPlus} />
                    <Text>{copyText.addButtonLabel}</Text>
                  </Flex>
                </Flex>
              </Button>
            </SelectDropdown>
          </Box>
          <Box>
            {props.metricFilters.map((filter, i) => {
              const operatorOption = operatorOptions.find(
                (option) => option.value === filter.operator
              );

              const values = props.externalDimensionValuesMap[filter.name];
              const filterOptions = uniq([
                ...(values ?? []),
                ...customFilter,
              ]).map((value) => ({ label: value, value }));

              return (
                <Box key={i} marginBottom={theme.space_xs} width={"100%"}>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    marginBottom={theme.space_xs}
                    flexWrap="wrap"
                    width={"100%"}
                  >
                    <SelectDropdown
                      options={metricFilterOptions}
                      placement="bottom-end"
                      onChange={(value) => handleUpdateMetricFilter(value, i)}
                    >
                      <Button
                        iconStart={<Icon icon={faFont} />}
                        marginRight={theme.space_xs}
                        secondary
                        size="tiny"
                      >
                        <Text truncate={nameTruncateWidth}>{filter.name}</Text>
                      </Button>
                    </SelectDropdown>
                    <Button
                      iconStart={<Icon icon={faTrashAlt} />}
                      size="tiny"
                      onClick={() => handleRemoveMetricFilter(i)}
                    />
                  </Flex>
                  <Flex>
                    <Dropdown
                      defaultSelectedOption={operatorOptions[0]}
                      options={operatorOptions.map((option) => ({
                        ...option,
                        onClick: (value: string) =>
                          handleUpdateFilterOperator(value, i),
                      }))}
                      placement="bottom-end"
                      selectedOption={operatorOption}
                    >
                      <Button
                        marginRight={theme.space_xs}
                        secondary
                        size="tiny"
                      >
                        {getOperatorText(operatorOption?.value)}
                      </Button>
                    </Dropdown>
                    <SelectDropdownFilter
                      filter={filter}
                      isLoading={props.isLoadingExternalDimensionValues}
                      options={filterOptions}
                      operator={operatorOption?.value ?? filter?.operator}
                      valueTruncateWidth={valueTruncateWidth}
                      onChange={(value: string | string[]) =>
                        handleUpdateMetricFilterValues(value, i)
                      }
                      onCreateOption={(value: string) =>
                        setCustomFilter((currentState) => [
                          ...currentState,
                          value,
                        ])
                      }
                    />
                  </Flex>
                </Box>
              );
            })}
          </Box>
        </Box>

        {/* Manage Custom Metrics */}
        {props.isSideDrawerOpen ? (
          <SideDrawerLegacy
            isOpen
            title={
              props.selectedCustomMetric
                ? copyText.customMetricFormUpdateCustomMetricTitle
                : copyText.customMetricFormCreateCustomMetricTitle
            }
            onClose={() =>
              props.onInteraction({
                type: ReportBuilderSidePanelUnitEconTab.INTERACTION_CLOSE_SIDE_DRAWER,
              })
            }
            renderContent={() => (
              <CustomMetricForm
                customMetrics={props.customMetrics}
                bigQueryMetadata={props.bigQueryMetadata}
                externalLabelMap={props.externalLabelMap}
                isLoadingBigQueryMetadata={props.isLoadingBigQueryMetadata}
                isLoadingCustomMetrics={props.isLoadingCustomMetrics}
                isProcessing={props.isProcessing}
                selectedCustomMetric={props.selectedCustomMetric}
                onInteraction={props.onInteraction}
              />
            )}
          />
        ) : null}
      </Box>
    </Flex>
  );
}

function getOperatorText(operator?: Operator): string {
  switch (operator) {
    case Operator.CONTAINS:
      return "∋";
    case Operator.EQUALS:
      return "=";
    case Operator.NOT_CONTAINS:
      return "∌";
    case Operator.NOT_EQUALS:
      return "≠";
    case Operator.NOT_SET:
      return copyText.operatorOptionNotSetLabel;
    case Operator.SET:
      return copyText.operatorOptionSetLabel;
    default:
      return "=";
  }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ReportBuilderSidePanelUnitEconTab {
  export const INTERACTION_ADD_METRIC_FILTER_CLICKED = `ReportBuilderSidePanelUnitEconTab.INTERACTION_ADD_METRIC_FILTER_CLICKED`;
  export const INTERACTION_ALIAS_CHANGED = `ReportBuilderSidePanelUnitEconTab.INTERACTION_ALIAS_CHANGED`;
  export const INTERACTION_CLOSE_SIDE_DRAWER = `ReportBuilderSidePanelUnitEconTab.INTERACTION_CLOSE_SIDE_DRAWER`;
  export const INTERACTION_FLIP_FORMULA_CLICKED = `ReportBuilderSidePanelUnitEconTab.INTERACTION_FLIP_FORMULA_CLICKED`;
  export const INTERACTION_REMOVE_METRIC_FILTER_CLICKED = `ReportBuilderSidePanelUnitEconTab.INTERACTION_REMOVE_METRIC_FILTER_CLICKED`;
  export const INTERACTION_SELECT_METRIC_AGGREGATE_CLICKED = `ReportBuilderSidePanelUnitEconTab.INTERACTION_SELECT_METRIC_AGGREGATE_CLICKED`;
  export const INTERACTION_SELECT_METRIC_CLICKED = `ReportBuilderSidePanelUnitEconTab.INTERACTION_METRIC_CLICKED`;
  export const INTERACTION_TOGGLE_FORMULA = `ReportBuilderSidePanelUnitEconTab.INTERACTION_TOGGLE_FORMULA`;
  export const INTERACTION_TOGGLE_METRIC = `ReportBuilderSidePanelUnitEconTab.INTERACTION_TOGGLE_METRIC`;
  export const INTERACTION_TOGGLE_METRIC_TABLE = `ReportBuilderSidePanelUnitEconTab.INTERACTION_TOGGLE_METRIC_TABLE`;
  export const INTERACTION_TOGGLE_UNIT_ECONOMICS = `ReportBuilderSidePanelUnitEconTab.INTERACTION_TOGGLE_UNIT_ECONOMICS`;
  export const INTERACTION_UPDATE_FILTER_OPERATOR_CLICKED = `ReportBuilderSidePanelUnitEconTab.INTERACTION_UPDATE_FILTER_OPERATOR_CLICKED`;
  export const INTERACTION_UPDATE_METRIC_FILTER_CLICKED = `ReportBuilderSidePanelUnitEconTab.INTERACTION_UPDATE_METRIC_FILTER_CLICKED`;
  export const INTERACTION_UPDATE_METRIC_FILTER_VALUES_CLICKED = `ReportBuilderSidePanelUnitEconTab.INTERACTION_UPDATE_METRIC_FILTER_VALUES_CLICKED`;
  export const INTERACTION_UPDATE_FORMULA = `ReportBuilderSidePanelUnitEconTab.INTERACTION_UPDATE_FORMULA`;

  interface InteractionAddMetricFilterClicked {
    type: typeof INTERACTION_ADD_METRIC_FILTER_CLICKED;
    metricFilter: FilterWithDate;
  }

  interface InteractionAliasChanged {
    type: typeof INTERACTION_ALIAS_CHANGED;
    alias: string;
  }

  interface InteractionToggleSideDrawer {
    type: typeof INTERACTION_CLOSE_SIDE_DRAWER;
  }

  interface InteractionFlipFormulaClicked {
    type: typeof INTERACTION_FLIP_FORMULA_CLICKED;
  }

  interface InteractionRemoveMetricFilterClicked {
    type: typeof INTERACTION_REMOVE_METRIC_FILTER_CLICKED;
    index: number;
  }

  interface InteractionSelectMetricAggregateClicked {
    type: typeof INTERACTION_SELECT_METRIC_AGGREGATE_CLICKED;
    value: MetricAggregate;
  }

  interface InteractionSelectMetricClicked {
    type: typeof INTERACTION_SELECT_METRIC_CLICKED;
    id: string;
  }

  interface InteractionToggleFormula {
    type: typeof INTERACTION_TOGGLE_FORMULA;
  }

  interface InteractionToggleMetric {
    type: typeof INTERACTION_TOGGLE_METRIC;
  }

  interface InteractionToggleMetricTable {
    type: typeof INTERACTION_TOGGLE_METRIC_TABLE;
  }

  interface InteractionToggleUnitEconomics {
    type: typeof INTERACTION_TOGGLE_UNIT_ECONOMICS;
  }

  interface InteractionUpdateFilterOperatorClicked {
    type: typeof INTERACTION_UPDATE_FILTER_OPERATOR_CLICKED;
    operator: Operator;
    index: number;
  }

  interface InteractionUpdateMetricFilterClicked {
    type: typeof INTERACTION_UPDATE_METRIC_FILTER_CLICKED;
    metricFilter: FilterWithDate;
    index: number;
  }

  interface InteractionUpdateMetricFilterValuesClicked {
    type: typeof INTERACTION_UPDATE_METRIC_FILTER_VALUES_CLICKED;
    values: string[];
    index: number;
  }

  interface InteractionUpdateFormula {
    type: typeof INTERACTION_UPDATE_FORMULA;
    variableName: string;
    index: number;
  }

  export type Interaction =
    | InteractionAddMetricFilterClicked
    | InteractionAliasChanged
    | InteractionFlipFormulaClicked
    | InteractionRemoveMetricFilterClicked
    | InteractionSelectMetricAggregateClicked
    | InteractionSelectMetricClicked
    | InteractionToggleFormula
    | InteractionToggleMetric
    | InteractionToggleMetricTable
    | InteractionToggleSideDrawer
    | InteractionToggleUnitEconomics
    | InteractionUpdateFilterOperatorClicked
    | InteractionUpdateFormula
    | InteractionUpdateMetricFilterClicked
    | InteractionUpdateMetricFilterValuesClicked;
}

export default ReportBuilderSidePanelUnitEconTab;
