import { FeatureFlag } from "@ternary/api-lib/constants/feature-flags";
import common from "../../../common.copyText";

/* prettier-ignore */
export default {
  ...common,
  actionMenuItemCreateStatement: "Create Billing Statement",
  actionMenuItemDeleteStatement: "Delete Billing Statement",
  actionMenuItemExportRawData: "Export Raw Data",
  actionMenuItemExportStatement: "Export Billing Statement",
  actionMenuItemViewStatements: "View Billing Statements",
  addressLine1InputLabel: "Address Line 1",
  addressLine2InputLabel: "Address Line 2",
  backToAllTenantsButtonLabel: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Business Units",
    default: "Back to all Tenants"
  },
  billingDetailsHeader: "Billing Details",
  billingStatementsTableHeader: "Billing Statements",
  cityInputLabel: "City",
  createStatementButtonLabel: "Create Billing Statement",
  createStatementFormMessage: "A Billing Statement creates a snapshot of billing data, at the time of creation. Creating another Billing Statement for the same start and end dates at a later time will result in different data.",
  createStatementFormTitle: "Create Billing Statement",
  customerDetailsInputLabel: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Business Unit Details",
    default: "Customer Details"
  },
  customerDetailsInputPlaceholder: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Enter business unit details here",
    default: "Enter customer details here"
  },
  customerSettingsFormMessage: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Fields below will be displayed for this business unit on their Billing Statements. To adjust your Billing Address or Payment Information, go to Global Bill Settings. Payment Term & Payment Instructions will override the global settings.",
    default: "Fields below will be displayed for this customer on their Billing Statements. To adjust your Billing Address or Payment Information, go to Global Bill Settings. Payment Term & Payment Instructions will override the global settings."
  },
  customerSettingsFormTitle: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Business Unit Billing Info",
    default: "Customer Billing Info"
  },
  deleteStatementConfirmationMessage: "Are you sure you want to do this?",
  deleteStatementConfirmationTitle: "Delete Billing Statement",
  errorCreatingBillingInfoMessage: "An error occurred when attempting to create the billing information. Please try again.",
  errorCreatingBillingStatementMessage: "An error occurred when attempting to create the statement. Please try again.", 
  errorDeletingBillingStatementMessage: "An error occurred when attempting to delete the statement. Please try again.", 
  errorGeneratingMspBillingStatementPdfMessage: "There was an error generating the selected PDF. Please try again.",
  errorUpdatingBillingInfoMessage: "An error occurred while attempting to update the billing information. Please try again.",
  globalSettingsFormHeader: "Company Billing Info",
  globalSettingsFormMessage: "Fields below will be displayed across all of your company's Billing Statements.",
  groupingInputLabelPlaceholder: "Select a grouping",
  inputFieldRequiredErrorCaption: "This field is required",
  invoiceNumberInputLabel: "Invoice Number",
  invoicePeriodInputLabel: "Invoice Period",
  nameInputLabel: "Name",
  paymentInstructionsInputLabel: "Payment Instructions",
  paymentInstructionsInputPlaceholder: "Enter payment instructions here",
  paymentTermInputLabel: "Payment Term",
  previewBillingStatementButtonLabel: "See Sample Billing Statement",
  primaryGroupingInputLabel: "Primary Grouping",
  secondaryGroupingInputLabel: "Secondary Grouping",
  selectStatePlaceholder: "Select a state",
  stateInputLabel: "State",
  successCreatingBillingInfoMessage: "The billing information has been successfully created.",
  successUpdatingBillingInfoMessage: "The billing information has been successfully updated.",
  successCreatingBillingStatementMessage: "The statement has been successfully created.",
  successDeletingBillingStatementMessage: "The statement has been successfully deleted.",
  tabLabelBillingInformation: "Billing Information",
  tabLabelBillingStatements: "Billing Statements",
  tabLabelGlobalBillSettings: "Global Bill Settings",
  tabLabelTenants: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Business Units",
    default: "Tenants"
  },
  tableHeaderCreatedAt: "Created At",
  tableHeaderDateRange: "Date Range",
  tableHeaderExpiresIn: "Expires In",
  tableHeaderName: "Name",
  tableHeaderOwner: "Owner",
  tableHeaderStatus: "Status",
  tenantStatusLabel_ACTIVE_POV: "POV",
  tenantStatusLabel_ACTIVE: "Active",
  tenantStatusLabel_INACTIVE_DATA_DELETED: "Inactive Data Deleted",
  tenantStatusLabel_INACTIVE: "Inactive",
  zipCodeInputLabel: "Zip Code"
} as const;
