import { CloudCapability } from "@ternary/api-lib/constants/enums";
import { DataIntegrationEntity } from "@ternary/api-lib/core/types";
import { Theme } from "@ternary/api-lib/ui-lib/theme/default";
import { differenceInHours } from "date-fns";
import { IntegrationStatusVariant } from "./constants";

export function getIntegrationStatus(params: {
  latestRefresh: string | null;
  latestUpstream: string | null;
}): IntegrationStatusVariant {
  if (
    !params.latestRefresh ||
    params.latestRefresh.startsWith("0") ||
    !params.latestUpstream ||
    params.latestUpstream.startsWith("0")
  ) {
    return IntegrationStatusVariant.INCOMPLETE;
  }

  const now = new Date();
  const absoluteRefresh = new Date(params.latestRefresh);
  const absoluteUpstream = new Date(params.latestUpstream);
  const refreshDifference = differenceInHours(now, absoluteRefresh);
  const upstreamDifference = differenceInHours(now, absoluteUpstream);

  let status: IntegrationStatusVariant = IntegrationStatusVariant.DANGER;
  if (refreshDifference <= 8 && upstreamDifference <= 24) {
    status = IntegrationStatusVariant.SUCCESS;
  } else if (refreshDifference <= 24 && upstreamDifference <= 72) {
    status = IntegrationStatusVariant.WARNING;
  }

  return status;
}

export function getIntegrationStatusColor(
  status: IntegrationStatusVariant,
  theme: Theme
): string {
  switch (status) {
    case IntegrationStatusVariant.DANGER:
      return theme.feedback_negative;
    case IntegrationStatusVariant.WARNING:
      return theme.cloud_status_warning;
    case IntegrationStatusVariant.SUCCESS:
      return theme.feedback_positive;
    default:
      return theme.feedback_neutral;
  }
}

function getAwsBillingValidations(integration: DataIntegrationEntity) {
  const validations = integration.validations ?? [];
  const awsBillingValidations = new Set<string>([
    CloudCapability.BILL_DATA_AWS_CUR2,
    CloudCapability.BILL_DATA_AWS_FOCUS,
    CloudCapability.BILL_DATA_AWS,
  ]);

  const hasAwsSuccess = validations.some(
    (validation) =>
      awsBillingValidations.has(validation.name) && validation.success
  );

  return hasAwsSuccess
    ? validations.filter((e) => !awsBillingValidations.has(e.name))
    : validations;
}

export function getValidationsWithErrors(integration: DataIntegrationEntity) {
  return getAwsBillingValidations(integration).filter(
    (validation) =>
      !validation.success && validation.errorType !== "Informational"
  );
}

export function getIntegrationsWithErrors(
  integrations: DataIntegrationEntity[]
) {
  return integrations.filter((integration) => {
    return getAwsBillingValidations(integration).find(
      (validation) =>
        !validation.success && validation.errorType !== "Informational"
    );
  });
}
