import { useTheme } from "@emotion/react";
import {
  faChevronDown,
  faChevronUp,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import { AlertRuleEntity, LabelMapsEntity } from "@ternary/api-lib/core/types";
import { CostAlertEntity } from "@ternary/api-lib/core/types/CostAlert";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import { formatDistance, isAfter } from "date-fns";
import React, { useState } from "react";
import copyText from "../copyText";
import {
  LEGACY_BIG_QUERY_RULE_ID,
  LEGACY_BILLING_RULE_ID,
} from "../defaultAlertRules";
import CostAlertStatusButton from "./CostAlertStatusButton";
import CostAlertTable from "./CostAlertTable";

type Interaction =
  | CostAlertStatusButton.Interaction
  | CostAlertTable.Interaction
  | InteractionEditButtonClicked;

type Props = {
  alertRule: AlertRuleEntity;
  costAlerts: CostAlertEntity[];
  labelMaps?: LabelMapsEntity;
  onInteraction: (interaction: Interaction) => void;
};

export function AlertRuleGroup(props: Props) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const isLegacy =
    props.alertRule.id === LEGACY_BIG_QUERY_RULE_ID ||
    props.alertRule.id === LEGACY_BILLING_RULE_ID;
  const hasAlerts = props.costAlerts.length > 0;

  const lastTriggered = props.costAlerts.reduce<null | Date>((accum, alert) => {
    if (!accum) {
      return new Date(alert.eventTime);
    }

    if (isAfter(new Date(alert.eventTime), accum)) {
      return new Date(alert.eventTime);
    }

    return accum;
  }, null);

  return (
    <Box>
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        cursor={hasAlerts ? "pointer" : undefined}
        justifyContent="space-between"
        marginBottom={theme.space_sm}
        onClick={() => setIsOpen(!isOpen)}
        padding={theme.space_md}
      >
        <Flex alignItems="center">
          <Box opacity={hasAlerts ? 1 : 0}>
            <Icon
              clickable={hasAlerts}
              color={theme.text_color}
              icon={isOpen ? faChevronUp : faChevronDown}
            />
          </Box>
          <Text appearance="h4" bold marginLeft={theme.space_md}>
            {props.alertRule.name}
          </Text>
        </Flex>

        <Flex>
          <Box marginRight={theme.space_md} width={135}>
            <Text color={theme.text_color_secondary}>
              {copyText.alertRuleHeaderAlertsTriggered}
            </Text>
            <Text bold>
              {props.costAlerts.length > 0 ? props.costAlerts.length : "---"}
            </Text>
          </Box>

          <Box marginRight={theme.space_md} width={135}>
            <Text color={theme.text_color_secondary}>
              {copyText.alertRuleHeaderLastTriggered}
            </Text>
            <Text bold>
              {lastTriggered
                ? formatDistance(lastTriggered, new Date(), { addSuffix: true })
                : "---"}
            </Text>
          </Box>

          <Box>
            <Tooltip
              content={copyText.systemAlertDisableSettingTooltip}
              hide={!isLegacy}
              placement="left"
            >
              <Button
                iconEnd={<Icon icon={faGear} />}
                secondary
                disabled={isLegacy}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onInteraction({
                    ruleID: props.alertRule.id,
                    type: AlertRuleGroup.INTERACTION_EDIT_BUTTON_CLICKED,
                  });
                }}
              />
            </Tooltip>
          </Box>
        </Flex>
      </Flex>

      {isOpen && (
        <Box>
          {props.costAlerts.length ? (
            <Box>
              <CostAlertTable
                alertRule={props.alertRule}
                alertRuleGranularity={props.alertRule.timeGranularity}
                costAlerts={props.costAlerts}
                isLoading={false}
                labelMaps={props.labelMaps}
                onInteraction={props.onInteraction}
              />
            </Box>
          ) : (
            <Box></Box>
          )}
        </Box>
      )}
    </Box>
  );
}

AlertRuleGroup.INTERACTION_EDIT_BUTTON_CLICKED =
  "AlertRuleGroup.INTERACTION_EDIT_BUTTON_CLICKED" as const;

type InteractionEditButtonClicked = {
  type: typeof AlertRuleGroup.INTERACTION_EDIT_BUTTON_CLICKED;
  ruleID: string;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AlertRuleGroup {
  export type Interaction = InteractionEditButtonClicked;
}

export default AlertRuleGroup;
