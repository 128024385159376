import { UnitType } from "../enums";

const measures = [
  { name: "gpuRequests", unit: UnitType.STANDARD },
  { name: "limitCPUCores", unit: UnitType.STANDARD },
  { name: "limitMemoryBytes", unit: UnitType.BYTES },
  { name: "requestCPUCores", unit: UnitType.STANDARD },
  { name: "requestCPUCost", unit: UnitType.CURRENCY },
  { name: "requestMemoryBytes", unit: UnitType.BYTES },
  { name: "requestMemoryCost", unit: UnitType.CURRENCY },
  { name: "totalGpuMemoryBytes", unit: UnitType.BYTES },
  { name: "totalRequestCost", unit: UnitType.CURRENCY },
  { name: "totalWasteCost", unit: UnitType.CURRENCY },
  { name: "usedCPUCores", unit: UnitType.STANDARD },
  { name: "usedCPUCost", unit: UnitType.CURRENCY },
  { name: "usedGpuMemoryBytes", unit: UnitType.BYTES },
  { name: "usedMemoryBytes", unit: UnitType.BYTES },
  { name: "usedMemoryCost", unit: UnitType.CURRENCY },
  { name: "wasteCPUs", unit: UnitType.STANDARD },
  { name: "wasteMemoryBytes", unit: UnitType.BYTES },
  { name: "wasteCPUCost", unit: UnitType.CURRENCY },
  { name: "wasteMemoryCost", unit: UnitType.CURRENCY },
];

const dimensions = [
  "cloudId",
  "clusterName",
  "containerName",
  "location",
  "namespace",
  "nodeName",
  "nodepool",
  "podName",
  "projectId",
  "workload",
];

export const gcpKubernetesContainerUsageSchema = {
  dimensions,
  measures,
};
