import { AuthenticatedUserEntity } from "@/api/core/types";
import useUpdateUserSettings from "@/api/core/useUpdateUserTenantSetting";
import paths from "@/constants/paths";
import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import useGatekeeper from "@/hooks/useGatekeeper";
import { useNavigateWithSearchParams } from "@/lib/react-router";
import ConfirmationModal from "@/ui-lib/components/ConfirmationModal";
import Dropdown from "@/ui-lib/components/Dropdown";
import { AlertType, postAlert } from "@/utils/alerts";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";

import {
  faChartLine,
  faCheck,
  faEllipsisH,
  faRotate,
} from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import { DataSource, DurationType } from "@ternary/api-lib/analytics/enums";
import {
  getDashboardDateRange,
  getDateRangeFromDashboard,
  getReportsWithModifiedDates,
} from "@ternary/api-lib/analytics/utils/ReportUtils";
import {
  DashboardScope,
  DashboardType,
  ReportType,
  WidgetType,
} from "@ternary/api-lib/constants/enums";
import systemUser, {
  SYSTEM_TENANT_ID,
} from "@ternary/api-lib/constants/system";
import { DashboardEntity } from "@ternary/api-lib/core/types";
import { ReportEntity } from "@ternary/api-lib/core/types/Report";
import { actions } from "@ternary/api-lib/telemetry";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { isEqual, keyBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useGetUsersByTenantID from "../../../api/core/hooks/useGetUsersByTenantID";
import { UpdateDashboardParameters } from "../../../api/core/types";
import NameInputModal from "../../../components/NameInputModal";
import useAvailableGlobalDate, {
  GlobalDateResult,
} from "../../../hooks/useAvailableGlobalDate";
import DateRangeControls from "../../../ui-lib/components/DateRangeControls/DateRangeControls";
import { FavoriteButton } from "../../../ui-lib/components/FavoriteButton";
import { DateRange } from "../../../utils/dates";
import useGetBudgetsByTenantID from "../../budget-management/hooks/useGetBudgetsByTenantID";
import copyText from "../copyText";
import queryKeys from "../hooks/queryKeys";
import useCreateDashboard from "../hooks/useCreateDashboard";
import useCreateSavingsOpportunityFilter from "../hooks/useCreateSavingsOpportunityFilter";
import useCreateTextWidget from "../hooks/useCreateTextWidget";
import useDeleteDashboard from "../hooks/useDeleteDashboard";
import useDeleteSavingsOpportunityFilter from "../hooks/useDeleteSavingsOpportunityFilter";
import useDeleteTextWidget from "../hooks/useDeleteTextWidget";
import useGetDashboardByID from "../hooks/useGetDashboardByID";
import useGetDashboardsByTenantID from "../hooks/useGetDashboardsByTenantID";
import useGetReportsByTenantID from "../hooks/useGetReportsByTenantID";
import useGetSavingsOpportunityFiltersByTenantID from "../hooks/useGetSavingsOpportunityFiltersByTenantID";
import useGetTextWidgetsByTenantID from "../hooks/useGetTextWidgetsByTenantID";
import useUpdateDashboard from "../hooks/useUpdateDashboard";
import useUpdateSavingsOpportunityFilter from "../hooks/useUpdateSavingsOpportunityFilter";
import useUpdateTextWidget from "../hooks/useUpdateTextWidget";
import { formatWidgetSpec, getDurationTypeFromDashboard } from "../utils";
import BudgetListModal from "./BudgetListModal";
import BudgetViewContainer from "./BudgetViewContainer";
import DashboardFormModal from "./DashboardFormModal";
import DashboardSubscriptionContainer from "./DashboardSubscriptionContainer";
import RealizedCommitmentSavingsContainer from "./RealizedCommitmentSavingsContainer";
import ReportGrid from "./ReportGrid";
import ReportListModal from "./ReportListModal";
import ReportViewContainer from "./ReportViewContainer";
import SavingsOpportunityForm, { Action } from "./SavingsOpportunityForm";
import SavingsOpportunityListModal from "./SavingsOpportunityListModal";
import { SavingsOpportunityViewContainer } from "./SavingsOpportunityViewContainer";
import TextWidgetContainer from "./TextWidgetContainer";

export const MAX_ALLOWED_WIDGETS = 25;

const MODAL_ADD_BUDGET = "MODAL_ADD_BUDGET";
const MODAL_ADD_SAVING_OPP = "MODAL_ADD_SAVING_OPP";
const MODAL_CREATE_DASHBOARD = "MODAL_CREATE_DASHBOARD";
const MODAL_DELETE_BUDGET = "MODAL_DELETE_BUDGET";
const MODAL_DELETE_DASHBOARD = "MODAL_DELETE_DASHBOARD";
const MODAL_DELETE_SAVING_OPP_FILTER = "MODAL_DELETE_SAVING_OPP_FILTER";
const MODAL_DELETE_REALIZED_COMMITMENT_SAVINGS =
  "MODAL_DELETE_REALIZED_COMMITMENT_SAVINGS";
const MODAL_DELETE_REPORT = "MODAL_DELETE_REPORT";
const MODAL_DELETE_SAVING_OPP = "MODAL_DELETE_SAVING_OPP";
const MODAL_DELETE_TEXT_WIDGET = "MODAL_DELETE_TEXT_WIDGET";
const MODAL_EDIT_DASHBOARD = "MODAL_EDIT_DASHBOARD";
const MODAL_LIST_REPORTS = "MODAL_LIST_REPORTS";
const MODAL_UPDATE_DASHBOARD_SYSTEM = "MODAL_UPDATE_DASHBOARD_SYSTEM";
const MODAL_UPDATE_SAVING_OPP = "MODAL_UPDATE_SAVING_OPP";
const MODAL_SUBSCRIBE_DASHBOARD = "MODAL_SUBSCRIBE_DASHBOARD";

type WidgetSpec = {
  budgetID: string | null;
  reportID: string | null;
  savingsOpportunityFilterID: string | null;
  textWidgetID: string | null;
  height: number;
  type: WidgetType;
  width: number;
  xCoordinate: number;
  yCoordinate: number;
};

type Interaction =
  | BudgetViewContainer.Interaction
  | BudgetListModal.Interaction
  | RealizedCommitmentSavingsContainer.Interaction
  | ReportGrid.Interaction
  | ReportListModal.Interaction
  | ReportViewContainer.Interaction
  | SavingsOpportunityForm.Interaction
  | SavingsOpportunityListModal.Interaction
  | SavingsOpportunityViewContainer.Interaction
  | TextWidgetContainer.Interaction;

interface State {
  actionPanelKey: Action | null;
  dateRange: DateRange | null;
  durationType: DurationType;
  editLayout: boolean;
  isHiddenFilters: boolean;
  invoiceMonthRange: DateRange | null;
  modalKey: string;
  selectedDashboardParams: UpdateDashboardParameters | null;
  selectedSavingOppID: string | null;
  selectedWidgetIndex: number;
  widgetSpecs: WidgetSpec[];
}

const initialState: State = {
  actionPanelKey: null,
  dateRange: null,
  durationType: DurationType.LAST_THIRTY_DAYS,
  editLayout: false,
  invoiceMonthRange: null,
  isHiddenFilters: true,
  modalKey: "",
  selectedDashboardParams: null,
  selectedSavingOppID: null,
  selectedWidgetIndex: -1,
  widgetSpecs: [],
};

const defaultTextWidgets = [];

export function DashboardViewContainer(): JSX.Element {
  const { dashboardID = "" } = useParams();

  const authenticatedUser = useAuthenticatedUser();
  const navigate = useNavigateWithSearchParams();
  const globalDate = useAvailableGlobalDate();

  const activityTracker = useActivityTracker();
  const queryClient = useQueryClient();
  const theme = useTheme();

  //
  // State
  //

  const gatekeeper = useGatekeeper();

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const {
    data: dashboard,
    isFetching: isFetchingDashboard,
    isLoading: isLoadingDashboard,
    refetch: refetchDashboard,
  } = useGetDashboardByID(dashboardID);

  // In the case a Ternary system admin is attempting to view a system
  // dashboard from internal admin. We need to query the relevant data
  // from the system tenant.

  const { data: dashboards = [], isLoading: isLoadingDashboards } =
    useGetDashboardsByTenantID(dashboard?.tenantID as string, {
      enabled: !!dashboard,
    });

  const { data: _reports = [], isLoading: isLoadingReports } =
    useGetReportsByTenantID(dashboard?.tenantID as string, {
      enabled: !!dashboard,
    });

  // There is no system level concept for budgets
  const { data: budgets, isLoading: isLoadingBudgets } =
    useGetBudgetsByTenantID(authenticatedUser.tenant.id);

  // TODO: Introduce tenant level concept for savings opp filters.
  const {
    data: filters,
    isLoading: isLoadingFilters,
    refetch: refetchFilters,
  } = useGetSavingsOpportunityFiltersByTenantID(dashboard?.tenantID as string, {
    enabled: !!dashboard,
  });

  const {
    data: textWidgets = defaultTextWidgets,
    isLoading: isLoadingTextWidgets,
    refetch: refetchTextWidgets,
  } = useGetTextWidgetsByTenantID(authenticatedUser.tenant.id, {
    enabled: !!dashboard,
  });

  const { data: users = [] } = useGetUsersByTenantID(
    dashboard?.tenantID as string,
    { enabled: !!dashboard }
  );

  //
  // Mutations
  //

  const { isPending: isCreatingDashboard, mutate: createDashboard } =
    useCreateDashboard({
      onError: () => {
        mergeState({ modalKey: "" });
        postAlert({
          message: copyText.errorCreatingDashboardMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (dashboardID) => {
        navigate(paths._dashboard.replace(":dashboardID", dashboardID));

        mergeState({ modalKey: "" });
        postAlert({
          message: copyText.successCreatingDashboardMessage,
          type: AlertType.SUCCESS,
        });
      },
    });

  const {
    isPending: isCreatingSavingsOpportunityFilter,
    mutate: createSavingsOpportunityFilter,
  } = useCreateSavingsOpportunityFilter({
    onError: () => {
      mergeState({ actionPanelKey: null, modalKey: "" });
      postAlert({
        message: copyText.errorCreatingSavingOpportunityMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (filterID) => {
      refetchFilters();

      const widgetSpec = formatWidgetSpec(
        filterID,
        WidgetType.SAVINGS_OPPORTUNITY_FILTER,
        state.widgetSpecs
      );

      setState((currentState) => {
        return {
          ...currentState,
          actionPanelKey: null,
          selectedSavingOppID: null,
          modalKey: "",
          ...(state.widgetSpecs.length <= MAX_ALLOWED_WIDGETS
            ? { widgetSpecs: [...currentState.widgetSpecs, widgetSpec] }
            : {}),
        };
      });
      postAlert({
        message: copyText.SUCCESS_SAVING_OPP_CREATED_message,
        type: AlertType.SUCCESS,
      });
    },
  });

  const {
    isPending: isUpdateSavingsOpportunityFilter,
    mutate: updateSavingsOpportunityFilter,
  } = useUpdateSavingsOpportunityFilter({
    onError: () => {
      mergeState({
        actionPanelKey: null,
        modalKey: "",
        selectedSavingOppID: null,
      });
      postAlert({
        message: copyText.errorUpdateSavingOpportunityMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      refetchFilters();
      mergeState({
        actionPanelKey: null,
        modalKey: "",
        selectedSavingOppID: null,
      });

      postAlert({
        message: copyText.SUCCESS_SAVING_OPP_UPDATED_message,
        type: AlertType.SUCCESS,
      });
    },
  });

  const {
    isPending: isDeleteSavingsOpportunityFilter,
    mutate: deleteSavingsOpportunityFilter,
  } = useDeleteSavingsOpportunityFilter({
    onError: () => {
      mergeState({
        actionPanelKey: null,
        modalKey: "",
        selectedSavingOppID: null,
      });
      postAlert({
        message: copyText.errorDeletingSavingOpportunityMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (filterID) => {
      refetchFilters();
      if (state.selectedWidgetIndex === -1) {
        const updateWidgetSpecs = state.widgetSpecs.filter(
          (widgetSpecs) => widgetSpecs.savingsOpportunityFilterID !== filterID
        );

        setState((currentState) => ({
          ...currentState,
          actionPanelKey: null,
          modalKey: "",
          selectedSavingOppID: null,
          widgetSpecs: updateWidgetSpecs,
        }));
      }
      handleDeleteWidget();
      mergeState({
        actionPanelKey: null,
        modalKey: "",
        selectedSavingOppID: null,
      });
      postAlert({
        message: copyText.SUCCESS_SAVING_OPP_DELETED_message,
        type: AlertType.SUCCESS,
      });
    },
  });

  const { isPending: isCreatingTextWidget, mutate: createTextWidget } =
    useCreateTextWidget({
      onError: () => {
        mergeState({ actionPanelKey: null, modalKey: "" });
        postAlert({
          message: copyText.errorCreatingTextWidgetMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (textWidgetID) => {
        refetchTextWidgets();

        setState((currentState) => {
          const widgetSpec = formatWidgetSpec(
            textWidgetID,
            WidgetType.TEXT,
            currentState.widgetSpecs,
            1
          );

          return {
            ...currentState,
            actionPanelKey: null,
            modalKey: "",
            ...(currentState.widgetSpecs.length <= MAX_ALLOWED_WIDGETS
              ? { widgetSpecs: [...currentState.widgetSpecs, widgetSpec] }
              : {}),
          };
        });

        postAlert({
          message: copyText.SUCCESS_TEXT_WIDGET_CREATED_message,
          type: AlertType.SUCCESS,
        });
      },
    });

  const { isPending: isDeletingTextWidget, mutate: deleteTextWidget } =
    useDeleteTextWidget({
      onError: () => {
        mergeState({ actionPanelKey: null, modalKey: "" });
        postAlert({
          message: copyText.errorDeletingTextWidgetMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: () => {
        refetchTextWidgets();

        postAlert({
          message: copyText.SUCCESS_TEXT_WIDGET_DELETED_message,
          type: AlertType.SUCCESS,
        });
      },
    });

  const { isPending: isUpdatingTextWidget, mutate: updateTextWidget } =
    useUpdateTextWidget({
      onError: () => {
        mergeState({ actionPanelKey: null, modalKey: "" });
        postAlert({
          message: copyText.errorUpdatingTextWidgetMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: () => {
        refetchTextWidgets();

        postAlert({
          message: copyText.SUCCESS_TEXT_WIDGET_UPDATED_message,
          type: AlertType.SUCCESS,
        });
      },
    });

  const { isPending: isDeletingDashboard, mutate: deleteDashboard } =
    useDeleteDashboard({
      onError: () => {
        mergeState({ modalKey: "" });
        postAlert({
          message: copyText.errorDeletingDashboardMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (dashboardID) => {
        queryClient.setQueryData<DashboardEntity[]>(
          ["dashboards"],
          (dashboards = []) =>
            dashboards.filter((dashboard) => dashboard.id !== dashboardID)
        );

        navigate(paths._dashboards);

        postAlert({
          message: copyText.SUCCESS_DASHBOARD_DELETED_message,
          type: AlertType.SUCCESS,
        });
      },
    });

  const {
    isError: isErrorUpdatingDashboard,
    isPending: isUpdatingDashboard,
    mutate: updateDashboard,
    reset: resetUpdateDashboard,
  } = useUpdateDashboard({
    retry: false,
    onMutate: async (params) => {
      const tenantID = authenticatedUser.tenant.id;
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: queryKeys.tenantDashboards(tenantID),
      });

      const previousDashboards: DashboardEntity[] =
        queryClient.getQueryData(queryKeys.tenantDashboards(tenantID)) ?? [];

      // Optimistically update to the new value.
      // Currently only using this for favoriting.
      if (params.favoritedUserIDs) {
        queryClient.setQueryData(
          queryKeys.tenantDashboards(tenantID),
          (currentDashboards: DashboardEntity[] | undefined) => {
            return (currentDashboards ?? []).map((dashboard) =>
              dashboard.id === params.dashboardID
                ? {
                    ...dashboard,
                    favoritedUserIDs: params.favoritedUserIDs ?? [],
                  }
                : dashboard
            );
          }
        );
      }

      return { previousDashboards };
    },
    onError: () => {
      mergeState({ modalKey: "" });

      refetchDashboard();

      postAlert({
        message: copyText.errorUpdatingDashboardMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      mergeState({ modalKey: "" });

      refetchDashboard();

      if (isSystemDashboard) {
        postAlert({
          message: copyText.successUpdatingSystemDashboardMessage,
          type: AlertType.SUCCESS,
        });
      }
    },
  });

  const { mutate: updateSettings } = useUpdateUserSettings({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorUpdatingPreferredDashboardMessage,
      });
    },
    onSuccess: (_, params) => {
      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successUpdatingPreferredDashboardMessage,
      });

      queryClient.setQueryData<AuthenticatedUserEntity | undefined>(
        ["authenticatedUser"],
        (authenticatedUser) => {
          if (!authenticatedUser) return;

          return {
            ...authenticatedUser,
            settings: { ...authenticatedUser.settings, ...params },
          };
        }
      );
    },
  });

  //
  // Computed Values
  //

  const usersKeyedByID = keyBy([...users, systemUser], "id");

  const reports = _reports.map((report) => {
    const createdByUser = usersKeyedByID[report.createdByID];
    const updatedByUser = usersKeyedByID[report.updatedByID ?? ""];

    return {
      ...report,
      createdByEmail: createdByUser ? createdByUser.email : null,
      updatedByEmail: updatedByUser ? updatedByUser.email : null,
    };
  });

  const modifiedReports = getModifiedReports(reports, state, globalDate);

  const selectedReports = modifiedReports.filter((report) =>
    state.widgetSpecs
      .filter((spec) => spec.type === WidgetType.REPORT)
      .some((reportSpec) => reportSpec.reportID === report.id)
  );

  const isEcoMode = selectedReports.some(
    (report) => report.dataSource === DataSource.CARBON_FOOTPRINT
  );

  //
  // Side Effects
  //

  // NOTE: This is to account for the multitenant case where you could directly
  // set the URL with a dashboard from a different tenant that you have access to.
  useEffect(() => {
    if (!dashboard) return;

    if (
      authenticatedUser.tenant.id !== dashboard.tenantID &&
      dashboard.scope !== DashboardScope.GLOBAL
    ) {
      navigate(paths._dashboards);
    }
  }, [dashboard]);

  // Load existing dashboard into state
  useEffect(() => {
    if (!dashboard) return;

    mergeState({
      dateRange:
        dashboard.durationType === DurationType.CUSTOM
          ? getDateRangeFromDashboard(dashboard)
          : null,
      durationType: getDurationTypeFromDashboard(
        dashboard,
        isEcoMode,
        globalDate.enabled ? globalDate : null
      ),
      isHiddenFilters: dashboard.durationType === null,
      invoiceMonthRange:
        dashboard.invoiceMonthEnd &&
        dashboard.invoiceMonthStart &&
        dashboard.durationType === DurationType.INVOICE
          ? getDateRangeFromDashboard(dashboard)
          : null,
      widgetSpecs: dashboard.widgetSpecs,
    });
  }, [dashboardID, dashboard === undefined]);

  const isSystemDashboard =
    dashboard && dashboard.type === DashboardType.SYSTEM;

  const isSystemDashboardCopy =
    dashboard && dashboard.type === DashboardType.SYSTEM_COPY;

  const changeSet = getChangeSet(dashboard, state);

  // Autosave
  useEffect(() => {
    if (isSystemDashboard || isSystemDashboardCopy) return;

    if (
      state === initialState ||
      isUpdatingDashboard ||
      isFetchingDashboard ||
      Object.keys(changeSet).length === 0
    ) {
      return;
    }

    updateDashboard({
      dashboardID: dashboardID,
      ...changeSet,
    });
  }, [dashboard, dashboardID, isFetchingDashboard, isUpdatingDashboard, state]);

  // Recover from autosave error
  useEffect(() => {
    if (
      !dashboard ||
      !isErrorUpdatingDashboard ||
      isFetchingDashboard ||
      isSystemDashboard ||
      isSystemDashboardCopy
    ) {
      return;
    }

    // sets isErrorUpdatingDashboard back to false
    resetUpdateDashboard();

    mergeState({
      dateRange:
        dashboard.durationType === DurationType.CUSTOM
          ? getDateRangeFromDashboard(dashboard)
          : null,
      durationType: getDurationTypeFromDashboard(
        dashboard,
        isEcoMode,
        globalDate.enabled ? globalDate : null
      ),
      isHiddenFilters: dashboard.durationType === null,
      invoiceMonthRange:
        dashboard.invoiceMonthEnd &&
        dashboard.invoiceMonthStart &&
        dashboard.durationType === DurationType.INVOICE
          ? getDateRangeFromDashboard(dashboard)
          : null,
      widgetSpecs: dashboard.widgetSpecs,
    });
  }, [dashboard, globalDate, isErrorUpdatingDashboard, isFetchingDashboard]);

  //
  // Interaction Handlers
  //

  function handleAddRealizedSavings() {
    setState((currentState) => {
      const widgetSpec = formatWidgetSpec(
        WidgetType.REALIZED_COMMITMENT_SAVINGS,
        WidgetType.REALIZED_COMMITMENT_SAVINGS,
        currentState.widgetSpecs
      );

      return {
        ...currentState,
        actionPanelKey: null,
        modalKey: "",
        ...(state.widgetSpecs.length <= MAX_ALLOWED_WIDGETS
          ? { widgetSpecs: [...currentState.widgetSpecs, widgetSpec] }
          : {}),
      };
    });
  }

  function handleAddText() {
    if (!dashboard) return;

    createTextWidget({
      tenantID: authenticatedUser.tenant.id,
      dashboardID: dashboard.id,
      description: "",
    });
  }

  function handleCreateDashboard(name: string): void {
    if (!dashboard) return;

    const widgetSpecs = state.widgetSpecs.map((spec) => {
      return {
        ...(spec.budgetID ? { budgetID: spec.budgetID } : {}),
        ...(spec.reportID ? { reportID: spec.reportID } : {}),
        ...(spec.savingsOpportunityFilterID
          ? { savingsOpportunityFilterID: spec.savingsOpportunityFilterID }
          : {}),
        ...(spec.textWidgetID ? { textWidgetID: spec.textWidgetID } : {}),
        type: spec.type,
        height: spec.height,
        width: spec.width,
        xCoordinate: spec.xCoordinate,
        yCoordinate: spec.yCoordinate,
      };
    });

    const [startDate, endDate] =
      state.dateRange && state.durationType === DurationType.CUSTOM
        ? getDashboardDateRange(state.dateRange, state.durationType)
        : [undefined, undefined];

    const [invoiceMonthStart, invoiceMonthEnd] =
      state.invoiceMonthRange && state.durationType === DurationType.INVOICE
        ? getDashboardDateRange(state.invoiceMonthRange, state.durationType)
        : [undefined, undefined];

    createDashboard({
      tenantID: authenticatedUser.tenant.id,
      ...(endDate ? { endDate } : {}),
      ...(!state.isHiddenFilters ? { durationType: state.durationType } : {}),
      ...(invoiceMonthEnd ? { invoiceMonthEnd } : {}),
      ...(invoiceMonthStart ? { invoiceMonthStart } : {}),
      name,
      scope: DashboardScope.SHARED,
      ...(startDate ? { startDate } : {}),
      type: DashboardType.DEFAULT,
      widgetSpecs,
    });
  }

  function handleDeleteDashboard(): void {
    deleteDashboard({ dashboardID: dashboardID });
  }

  function handleDeleteWidget(): void {
    if (state.selectedWidgetIndex === -1) return;

    setState((currentState) => ({
      ...currentState,
      widgetSpecs: [
        ...currentState.widgetSpecs.slice(0, state.selectedWidgetIndex),
        ...currentState.widgetSpecs.slice(state.selectedWidgetIndex + 1),
      ],
    }));

    mergeState({ modalKey: "" });
  }

  function handleUpdateDashboard(params: {
    name: string;
    tags: string[];
  }): void {
    if (!dashboard) return;

    if (dashboard.type === DashboardType.SYSTEM) {
      mergeState({
        modalKey: MODAL_UPDATE_DASHBOARD_SYSTEM,
        selectedDashboardParams: {
          name: params.name,
          tags: params.tags,
        },
      });
      return;
    }

    updateDashboard({
      dashboardID: dashboardID,
      name: params.name,
      tags: params.tags,
    });
  }

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case BudgetViewContainer.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_DELETE_BUDGET,
          selectedWidgetIndex: interaction.index,
        });
        return;
      }
      case BudgetViewContainer.INTERACTION_BUDGET_NAME_CLICKED: {
        const { budgetID } = interaction;

        navigate(paths._budgets, { searchParams: { budget_id: budgetID } });
        return;
      }
      case BudgetListModal.INTERACTION_ADD_BUDGET_CLICKED: {
        const widgetSpec = formatWidgetSpec(
          interaction.budgetID,
          interaction.widgetType,
          state.widgetSpecs
        );

        setState((currentState) => {
          return {
            ...currentState,
            ...(state.widgetSpecs.length <= MAX_ALLOWED_WIDGETS
              ? { widgetSpecs: [...currentState.widgetSpecs, widgetSpec] }
              : {}),
          };
        });
        return;
      }
      case RealizedCommitmentSavingsContainer.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_DELETE_REALIZED_COMMITMENT_SAVINGS,
          selectedWidgetIndex: interaction.index,
        });
        return;
      }
      case ReportGrid.INTERACTION_CONFIG_POSITIONS_UPDATED: {
        mergeState({ widgetSpecs: interaction.widgetSpecs });
        return;
      }
      case ReportListModal.INTERACTION_ROW_CLICKED: {
        const newReport = reports?.find(
          (report) => report.id === interaction.reportID
        );

        const isEcoMode = newReport?.dataSource === DataSource.CARBON_FOOTPRINT;

        const widgetSpec = formatWidgetSpec(
          interaction.reportID,
          WidgetType.REPORT,
          state.widgetSpecs
        );

        setState((currentState) => {
          const hasHiddenOptions = isEcoMode
            ? hiddenEcoModeOptions.some(
                (option) => option === currentState.durationType
              )
            : false;

          return {
            ...currentState,
            durationType: hasHiddenOptions
              ? DurationType.LAST_NINETY_DAYS
              : currentState.durationType,
            ...(state.widgetSpecs.length <= MAX_ALLOWED_WIDGETS
              ? { widgetSpecs: [...currentState.widgetSpecs, widgetSpec] }
              : {}),
          };
        });

        return;
      }
      case ReportViewContainer.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_DELETE_REPORT,
          selectedWidgetIndex: interaction.index,
        });
        return;
      }
      case ReportViewContainer.INTERACTION_REPORT_NAME_CLICKED: {
        const { reportID, report } = interaction;

        navigate(paths._reportBuilder.replace(":reportID", reportID), {
          state: {
            report,
            from: paths._dashboard.replace(":dashboardID", dashboardID),
            title: copyText.navSearchOptionNameDashboard,
          },
        });
        return;
      }
      case SavingsOpportunityListModal.INTERACTION_SAVINGS_ROW_CLICKED: {
        const widgetSpec = formatWidgetSpec(
          interaction.filterID,
          WidgetType.SAVINGS_OPPORTUNITY_FILTER,
          state.widgetSpecs
        );

        setState((currentState) => {
          return {
            ...currentState,
            actionPanelKey: null,
            modalKey: "",
            ...(state.widgetSpecs.length <= MAX_ALLOWED_WIDGETS
              ? { widgetSpecs: [...currentState.widgetSpecs, widgetSpec] }
              : {}),
          };
        });
        return;
      }
      case SavingsOpportunityListModal.INTERACTION_ADD_SAVINGS_OPP_CLICKED: {
        mergeState({ actionPanelKey: Action.CREATE });
        return;
      }
      case SavingsOpportunityListModal.INTERACTION_BACK_BUTTON_CLICKED: {
        mergeState({ actionPanelKey: null });
        return;
      }
      case SavingsOpportunityListModal.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({
          actionPanelKey: null,
          modalKey: "",
          selectedSavingOppID: null,
          selectedWidgetIndex: -1,
        });
        return;
      }
      case SavingsOpportunityListModal.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_DELETE_SAVING_OPP_FILTER,
          selectedSavingOppID: interaction.filterID,
          selectedWidgetIndex: interaction.index,
        });
        return;
      }
      case SavingsOpportunityViewContainer.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_DELETE_SAVING_OPP,
          selectedWidgetIndex: interaction.index,
        });
        return;
      }
      case SavingsOpportunityListModal.INTERACTION_UPDATE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_UPDATE_SAVING_OPP,
          actionPanelKey: Action.UPDATE,
          selectedSavingOppID: interaction.filterID,
        });
        return;
      }
      case SavingsOpportunityViewContainer.INTERACTION_SAVINGS_OPPORTUNIITY_FILTER_NAME_CLICKED: {
        mergeState({
          modalKey: MODAL_UPDATE_SAVING_OPP,
          actionPanelKey: Action.UPDATE,
          selectedSavingOppID: interaction.savingsOpportunityFilterID,
          selectedWidgetIndex: interaction.index,
        });
        return;
      }

      case SavingsOpportunityForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE: {
        createSavingsOpportunityFilter({
          tenantID: interaction.isConfirmingSystemSavingOpp
            ? SYSTEM_TENANT_ID
            : authenticatedUser.tenant.id,
          category: interaction.categoryInput,
          cloudProviderType: interaction.cloudProviderTypeInput,
          name: interaction.nameInput,
          savingsType: interaction.savingsTypeInput,
          serviceType: interaction.serviceTypeInput,
        });
        return;
      }
      case SavingsOpportunityForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({
          actionPanelKey: null,
          modalKey: "",
          selectedWidgetIndex: -1,
        });
        return;
      }
      case SavingsOpportunityForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE: {
        updateSavingsOpportunityFilter({
          filterID: interaction.savingOpportunityFilterID,
          category: interaction.category,
          cloudProviderType: interaction.cloudProviderType,
          name: interaction.name,
          savingsType: interaction.savingsType,
          serviceType: interaction.serviceType,
        });
        return;
      }
      case TextWidgetContainer.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE: {
        updateTextWidget({
          textWidgetID: interaction.id,
          description: interaction.description,
        });
        return;
      }

      case TextWidgetContainer.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_DELETE_TEXT_WIDGET,
          selectedWidgetIndex: interaction.index,
        });
        return;
      }
    }
  }

  function handleFavoriteDashboard(): void {
    activityTracker.captureAction(actions.CLICK_TRE_DASHBOARD_FAVORITE_BUTTON);

    if (!dashboardID) return;
    const favoritedUserIDs = dashboard?.favoritedUserIDs ?? [];
    if (!favoritedUserIDs.includes(authenticatedUser.id)) {
      updateDashboard({
        dashboardID,
        favoritedUserIDs: [...favoritedUserIDs, authenticatedUser.id],
      });
    } else {
      updateDashboard({
        dashboardID,
        favoritedUserIDs: favoritedUserIDs?.filter(
          (id) => id !== authenticatedUser.id
        ),
      });
    }
  }
  //
  // Render
  //

  function renderModal(): JSX.Element | null {
    switch (state.modalKey) {
      case MODAL_ADD_BUDGET: {
        return (
          <BudgetListModal
            isLoading={isLoadingBudgets}
            budgets={budgets ?? []}
            onClose={() => mergeState({ modalKey: "" })}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_CREATE_DASHBOARD: {
        const name = dashboard
          ? `${dashboard.name} (${copyText.dashboardNameCopyLabel})`
          : undefined;

        return (
          <NameInputModal
            isProcessing={isCreatingDashboard}
            name={name}
            title={copyText.modalTitleCreateDashboard}
            onClose={() => mergeState({ modalKey: "" })}
            onSubmit={handleCreateDashboard}
          />
        );
      }
      case MODAL_DELETE_BUDGET: {
        return (
          <ConfirmationModal
            message={copyText.removeReportConfirmationMessage}
            title={copyText.removeBudgetConfirmationTitle}
            variant="danger"
            onCancel={() =>
              mergeState({ modalKey: "", selectedWidgetIndex: -1 })
            }
            onConfirm={handleDeleteWidget}
          />
        );
      }
      case MODAL_DELETE_DASHBOARD: {
        return (
          <ConfirmationModal
            isLoading={isDeletingDashboard}
            message={copyText.deleteDashboardConfirmationMessage}
            title={copyText.deleteDashboardConfirmationTitle}
            variant="danger"
            onCancel={() => mergeState({ modalKey: "" })}
            onConfirm={handleDeleteDashboard}
          />
        );
      }
      case MODAL_DELETE_REALIZED_COMMITMENT_SAVINGS: {
        return (
          <ConfirmationModal
            message={copyText.removeReportConfirmationMessage}
            title={copyText.removeRealizedSavingsConfirmationTitle}
            variant="danger"
            onCancel={() => mergeState({ modalKey: "" })}
            onConfirm={handleDeleteWidget}
          />
        );
      }
      case MODAL_DELETE_REPORT: {
        return (
          <ConfirmationModal
            message={copyText.removeReportConfirmationMessage}
            title={copyText.removeReportConfirmationTitle}
            variant="danger"
            onCancel={() =>
              mergeState({ modalKey: "", selectedWidgetIndex: -1 })
            }
            onConfirm={handleDeleteWidget}
          />
        );
      }
      case MODAL_DELETE_TEXT_WIDGET: {
        return (
          <ConfirmationModal
            message={copyText.deleteTextWidgetConfirmationMessage}
            title={copyText.deleteTextWidgetConfirmationTitle}
            variant="danger"
            onCancel={() =>
              mergeState({ modalKey: "", selectedWidgetIndex: -1 })
            }
            onConfirm={() => {
              handleDeleteWidget();

              const selectedTextWidgetID =
                state.widgetSpecs[state.selectedWidgetIndex].textWidgetID;

              if (!selectedTextWidgetID) return;

              deleteTextWidget({
                textWidgetID: selectedTextWidgetID,
              });
            }}
          />
        );
      }
      case MODAL_EDIT_DASHBOARD: {
        const existingTags = dashboards.reduce((accum: string[], dashboard) => {
          dashboard.tags.forEach((tag) => {
            if (!accum.includes(tag)) {
              accum.push(tag);
            }
          });
          return accum;
        }, []);

        return (
          <DashboardFormModal
            isProcessing={isUpdatingDashboard || isLoadingDashboards}
            existingTags={existingTags}
            tags={dashboard?.tags ?? []}
            title={copyText.modalTitleEditDashboard}
            name={dashboard?.name}
            onClose={() => mergeState({ modalKey: "" })}
            onSubmit={handleUpdateDashboard}
          />
        );
      }
      case MODAL_LIST_REPORTS: {
        let filteredReports = reports ?? [];

        filteredReports =
          dashboard?.type === DashboardType.SYSTEM
            ? filteredReports.filter(
                (report) =>
                  report.type === ReportType.SYSTEM &&
                  report.isDistributed === true
              )
            : filteredReports;

        return (
          <ReportListModal
            isLoading={isLoadingReports}
            reports={filteredReports}
            onClose={() => mergeState({ modalKey: "" })}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_ADD_SAVING_OPP: {
        let filteredSavingsOpp = filters ?? [];

        filteredSavingsOpp =
          dashboard?.scope === DashboardScope.GLOBAL
            ? filteredSavingsOpp.filter(
                (savingsOpp) => savingsOpp.tenantID === SYSTEM_TENANT_ID
              )
            : filteredSavingsOpp;

        return (
          <SavingsOpportunityListModal
            actionPanelKey={state.actionPanelKey}
            isLoading={isLoadingFilters}
            filters={filteredSavingsOpp ?? []}
            selectedWidgetIndex={state.selectedWidgetIndex}
            selectedSavingOppID={state?.selectedSavingOppID}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_UPDATE_SAVING_OPP: {
        return (
          <SavingsOpportunityListModal
            actionPanelKey={state.actionPanelKey}
            isLoading={isLoadingFilters}
            filters={filters ?? []}
            selectedSavingOppID={state?.selectedSavingOppID}
            selectedWidgetIndex={state.selectedWidgetIndex}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_DELETE_SAVING_OPP: {
        return (
          <ConfirmationModal
            message={copyText.removeReportConfirmationMessage}
            title={copyText.removeSavingOppConfirmationTitle}
            variant="danger"
            onCancel={() =>
              mergeState({ modalKey: "", selectedWidgetIndex: -1 })
            }
            onConfirm={handleDeleteWidget}
          />
        );
      }
      case MODAL_DELETE_SAVING_OPP_FILTER: {
        return (
          <ConfirmationModal
            message={copyText.removeReportConfirmationMessage}
            title={copyText.removeSavingOppFilterConfirmationTitle}
            variant="danger"
            onCancel={() =>
              mergeState({
                actionPanelKey: null,
                modalKey: "",
                selectedSavingOppID: null,
                selectedWidgetIndex: -1,
              })
            }
            onConfirm={() =>
              deleteSavingsOpportunityFilter({
                filterID: state.selectedSavingOppID ?? "",
              })
            }
          />
        );
      }
      case MODAL_SUBSCRIBE_DASHBOARD: {
        if (dashboard) {
          return (
            <DashboardSubscriptionContainer
              isLoadingDashboard={isLoadingDashboard}
              dashboard={dashboard}
              users={users}
              refetch={refetchDashboard}
              onClose={() => mergeState({ modalKey: "" })}
            />
          );
        }
        return null;
      }
      case MODAL_UPDATE_DASHBOARD_SYSTEM: {
        return (
          <ConfirmationModal
            isLoading={isUpdatingDashboard}
            message={copyText.modalMessageUpdateSystemDashboard}
            title={copyText.modalTitleEditSystemDashboard}
            variant="danger"
            onCancel={() => mergeState({ modalKey: "" })}
            onConfirm={() =>
              updateDashboard({
                dashboardID,
                ...changeSet,
                ...state.selectedDashboardParams,
              })
            }
          />
        );
      }
      default:
        return null;
    }
  }

  const dashboardOptions = [
    {
      label: copyText.actionMenuItemSubscribeToDashboard,
      locked: !gatekeeper.canUpdateDashboard(dashboard?.createdByID ?? ""),
      onClick: () => mergeState({ modalKey: MODAL_SUBSCRIBE_DASHBOARD }),
    },
    {
      label: copyText.actionMenuItemMakeHomePageDashboard,
      locked: false,
      onClick: () =>
        updateSettings({
          settingsID: authenticatedUser.settings.id,
          preferredDashboardID: dashboardID,
        }),
    },
  ];

  if (!isSystemDashboardCopy) {
    dashboardOptions.unshift(
      {
        label: copyText.actionMenuItemEditDashboard,
        locked: !canUpdateDashboard(),
        onClick: () => mergeState({ modalKey: MODAL_EDIT_DASHBOARD }),
      },
      {
        label: copyText.actionMenuItemeditDashboardLayout,
        locked: dashboard?.widgetSpecs.length === 0 || !canUpdateDashboard(),
        onClick: () => mergeState({ editLayout: true }),
      },
      {
        label: copyText.actionMenuItemDeleteDashboard,
        locked:
          !dashboard?.id ||
          !gatekeeper.getCanDeleteSpecificDashboard(dashboard.createdByID),
        onClick: () => mergeState({ modalKey: MODAL_DELETE_DASHBOARD }),
      }
    );
  }

  const addWidgetLocked =
    state.widgetSpecs.length >= MAX_ALLOWED_WIDGETS ||
    state.editLayout ||
    !canUpdateDashboard();

  const addWidgetOptions = [
    {
      label: copyText.actionMenuItemAddReport,
      locked: addWidgetLocked,
      onClick: () => mergeState({ modalKey: MODAL_LIST_REPORTS }),
    },
    {
      label: copyText.addBudget,
      locked:
        addWidgetLocked ||
        (dashboard && dashboard.scope === DashboardScope.GLOBAL),
      onClick: () => mergeState({ modalKey: MODAL_ADD_BUDGET }),
    },
    {
      label: copyText.addSavingOpportunity,
      locked: addWidgetLocked,
      onClick: () => mergeState({ modalKey: MODAL_ADD_SAVING_OPP }),
    },
    {
      label: copyText.addSavingsRealized,
      locked: addWidgetLocked,
      onClick: () => handleAddRealizedSavings(),
    },
    {
      label: copyText.addText,
      locked: addWidgetLocked || !gatekeeper.canCreateTextWidgets,
      onClick: () => handleAddText(),
    },
  ];

  const isFavorited = dashboard
    ? dashboard.favoritedUserIDs.includes(authenticatedUser.id)
    : false;

  function canUpdateDashboard(): boolean {
    if (!dashboard) return false;

    switch (dashboard.type) {
      case DashboardType.DEFAULT:
        return gatekeeper.canUpdateDashboard(dashboard.id);
      case DashboardType.SYSTEM_COPY:
        return false;
      case DashboardType.SYSTEM:
        return gatekeeper.canUpdateSystemDashboard;
    }
  }

  const hiddenEcoModeOptions = [
    DurationType.LAST_MONTH,
    DurationType.YESTERDAY,
    DurationType.LAST_SEVEN_DAYS,
    DurationType.LAST_THIRTY_DAYS,
    DurationType.MONTH_TO_DATE,
    DurationType.QUARTER_TO_DATE,
  ];

  return (
    <Box minWidth={1000}>
      {state.modalKey && renderModal()}
      <Box
        backgroundColor={theme.background_color}
        marginHorizontal="-5px"
        minWidth={1200}
        paddingBottom={theme.space_md}
        paddingTop={theme.space_md}
        position="sticky"
        top={0}
        zIndex={theme.zIndex_400}
      >
        <Flex
          justifyContent="space-between"
          marginBottom={state.isHiddenFilters ? 0 : theme.space_xs}
        >
          <Text fontSize={theme.h3_fontSize}>{dashboard?.name}</Text>
          <Flex>
            <AutosaveIndicator
              key={dashboard?.id}
              isSaving={isUpdatingDashboard}
            />

            {isSystemDashboard && (
              <Button
                disabled={Object.keys(changeSet).length === 0}
                locked={!gatekeeper.canCreateDashboards}
                primary={state.widgetSpecs.length !== 0}
                secondary={state.widgetSpecs.length === 0}
                size="small"
                onClick={() =>
                  mergeState({ modalKey: MODAL_UPDATE_DASHBOARD_SYSTEM })
                }
              >
                {copyText.saveSystemDashboardButtonLabel}
              </Button>
            )}

            <Button
              disabled={state.widgetSpecs.length === 0 || state.editLayout}
              locked={!gatekeeper.canCreateDashboards}
              marginHorizontal={theme.space_sm}
              primary={state.widgetSpecs.length !== 0}
              secondary={state.widgetSpecs.length === 0}
              size="small"
              onClick={() => mergeState({ modalKey: MODAL_CREATE_DASHBOARD })}
            >
              {copyText.saveAsCopyButtonLabel}
            </Button>

            <Button
              disabled={state.editLayout}
              locked={!gatekeeper.canUpdateDashboard(dashboard?.id ?? "")}
              marginRight={theme.space_sm}
              primary={state.widgetSpecs.length !== 0}
              secondary={state.widgetSpecs.length === 0}
              size="small"
              width={130}
              onClick={() =>
                setState((currentState) => ({
                  ...currentState,
                  isHiddenFilters: !currentState.isHiddenFilters,
                }))
              }
            >
              {state.isHiddenFilters
                ? copyText.dashboardFilterButtonLabel
                : copyText.removeFilterButtonLabel}
            </Button>

            <Dropdown
              disabled={addWidgetLocked}
              options={addWidgetOptions}
              placement="bottom-end"
            >
              <Button
                disabled={addWidgetLocked}
                locked={!gatekeeper.canUpdateDashboard(dashboard?.id ?? "")}
                marginRight={theme.space_sm}
                primary={state.widgetSpecs.length < MAX_ALLOWED_WIDGETS}
                secondary={state.widgetSpecs.length === MAX_ALLOWED_WIDGETS}
                size="small"
              >
                {copyText.addWidgetButtonLabel}
              </Button>
            </Dropdown>

            <Box>
              <FavoriteButton
                isLoading={isLoadingDashboard}
                disabled={!dashboard}
                favorited={isFavorited}
                marginRight={theme.space_xs}
                size="small"
                onClick={handleFavoriteDashboard}
              />
            </Box>

            {state.editLayout ? (
              <Button
                primary
                size="small"
                onClick={() => mergeState({ editLayout: false })}
              >
                {copyText.actionClose}
              </Button>
            ) : (
              <Dropdown options={dashboardOptions} placement="bottom-end">
                <Button
                  iconStart={<Icon icon={faEllipsisH} />}
                  primary
                  size="small"
                />
              </Dropdown>
            )}
          </Flex>
        </Flex>

        {!state.isHiddenFilters && (
          <Flex
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            justifyContent="right"
            padding={theme.space_md}
          >
            <DateRangeControls
              dateRange={
                state.durationType === DurationType.INVOICE
                  ? state.invoiceMonthRange
                  : state.dateRange
              }
              durationType={state.durationType}
              hiddenOptions={
                isEcoMode
                  ? hiddenEcoModeOptions
                  : [DurationType.QUARTER_TO_DATE]
              }
              isFiscalMode={authenticatedUser.settings.fiscalMode}
              onChangeDateRange={(durationType, dateRange) => {
                if (durationType === DurationType.INVOICE) {
                  mergeState({
                    dateRange: null,
                    durationType,
                    invoiceMonthRange: dateRange,
                  });
                } else {
                  mergeState({
                    dateRange,
                    durationType,
                    invoiceMonthRange: null,
                  });
                }
              }}
            />
          </Flex>
        )}
      </Box>
      {!dashboard || !reports || state.widgetSpecs.length === 0 ? (
        <Box height={800} marginTop={theme.space_lg}>
          <EmptyPlaceholder
            icon={faChartLine}
            loading={
              isCreatingDashboard ||
              isLoadingDashboard ||
              isLoadingReports ||
              isLoadingFilters ||
              isLoadingTextWidgets ||
              isCreatingSavingsOpportunityFilter ||
              isCreatingTextWidget ||
              isDeletingTextWidget ||
              isUpdateSavingsOpportunityFilter ||
              isUpdatingTextWidget ||
              isDeleteSavingsOpportunityFilter
            }
            text={copyText.emptyReports}
            skeletonVariant="cards_large"
          />
        </Box>
      ) : (
        <ReportGrid
          budgets={budgets ?? []}
          dashboardID={dashboard.id}
          editLayout={state.editLayout}
          reports={selectedReports}
          filters={filters ?? []}
          textWidgets={textWidgets}
          widgetSpecs={state.widgetSpecs}
          onInteraction={handleInteraction}
        />
      )}
    </Box>
  );
}

type AutosaveIndicatorProps = {
  isSaving: boolean;
};

function AutosaveIndicator(props: AutosaveIndicatorProps) {
  const [hasSaved, setHasSaved] = useState(false);
  const theme = useTheme();

  if (props.isSaving && !hasSaved) {
    setHasSaved(true);
  }

  if (!props.isSaving && !hasSaved) {
    return null;
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      paddingHorizontal={theme.space_sm}
      transition="none"
    >
      <Text color={theme.text_color_secondary}>
        {props.isSaving
          ? copyText.dashboardAutosaveSaving
          : copyText.dashboardAutosaveChangesSaved}
      </Text>

      <Box color={theme.text_color_secondary} marginLeft={theme.space_sm}>
        {props.isSaving ? <Icon icon={faRotate} /> : <Icon icon={faCheck} />}
      </Box>
    </Flex>
  );
}

function getChangeSet(dashboard: DashboardEntity | undefined, state: State) {
  if (!dashboard) return {};

  const [startDate, endDate] =
    state.durationType === DurationType.CUSTOM && state.dateRange
      ? [state.dateRange[0].toISOString(), state.dateRange[1].toISOString()]
      : [null, null];

  const [invoiceMonthStart, invoiceMonthEnd] =
    state.invoiceMonthRange && state.durationType === DurationType.INVOICE
      ? getDashboardDateRange(state.invoiceMonthRange, state.durationType)
      : [null, null];

  const pendingSpecs = state.widgetSpecs.map((spec) => ({
    budgetID: spec.budgetID,
    reportID: spec.reportID,
    savingsOpportunityFilterID: spec.savingsOpportunityFilterID,
    textWidgetID: spec.textWidgetID,
    height: spec.height,
    type: spec.type,
    width: spec.width,
    xCoordinate: spec.xCoordinate,
    yCoordinate: spec.yCoordinate,
  }));

  const pendingChanges = state.isHiddenFilters
    ? {
        endDate: null,
        durationType: null,
        invoiceMonthEnd: null,
        invoiceMonthStart: null,
        startDate: null,
        widgetSpecs: pendingSpecs,
      }
    : {
        endDate,
        durationType: state.durationType,
        invoiceMonthEnd,
        invoiceMonthStart,
        startDate,
        widgetSpecs: pendingSpecs,
      };

  const currentSpecs = dashboard.widgetSpecs.map((spec) => ({
    budgetID: spec.budgetID,
    reportID: spec.reportID,
    savingsOpportunityFilterID: spec.savingsOpportunityFilterID,
    textWidgetID: spec.textWidgetID,
    height: spec.height,
    type: spec.type,
    width: spec.width,
    xCoordinate: spec.xCoordinate,
    yCoordinate: spec.yCoordinate,
  }));

  const widgetSpecsChanged = !isEqual(currentSpecs, pendingChanges.widgetSpecs);

  const changeSet = {
    ...(dashboard.endDate !== pendingChanges.endDate
      ? { endDate: pendingChanges.endDate }
      : {}),
    ...(dashboard.durationType !== pendingChanges.durationType
      ? { durationType: pendingChanges.durationType }
      : {}),
    ...(dashboard.invoiceMonthEnd !== pendingChanges.invoiceMonthEnd
      ? { invoiceMonthEnd: pendingChanges.invoiceMonthEnd }
      : {}),
    ...(dashboard.invoiceMonthStart !== pendingChanges.invoiceMonthStart
      ? { invoiceMonthStart: pendingChanges.invoiceMonthStart }
      : {}),
    ...(dashboard.startDate !== pendingChanges.startDate
      ? { startDate: pendingChanges.startDate }
      : {}),
    ...(widgetSpecsChanged
      ? {
          widgetSpecs: pendingChanges.widgetSpecs.map((spec) => ({
            ...(spec.budgetID ? { budgetID: spec.budgetID } : {}),
            ...(spec.reportID ? { reportID: spec.reportID } : {}),
            ...(spec.savingsOpportunityFilterID
              ? { savingsOpportunityFilterID: spec.savingsOpportunityFilterID }
              : {}),
            ...(spec.textWidgetID ? { textWidgetID: spec.textWidgetID } : {}),
            height: spec.height,
            type: spec.type,
            width: spec.width,
            xCoordinate: spec.xCoordinate,
            yCoordinate: spec.yCoordinate,
          })),
        }
      : {}),
  };

  return changeSet;
}

export function getModifiedReports(
  reports: ReportEntity[] | undefined,
  state: State,
  globalDate: GlobalDateResult
): ReportEntity[] {
  if (state.isHiddenFilters && !globalDate.enabled) {
    return reports ?? [];
  }

  if (!globalDate.enabled) {
    return getReportsWithModifiedDates(reports, {
      dateRange: state.dateRange,
      durationType: state.durationType,
      invoiceMonthRange: state.invoiceMonthRange,
    });
  }

  return getReportsWithModifiedDates(reports, {
    dateRange: globalDate.date,
    durationType: globalDate.durationType,
    invoiceMonthRange: null,
  });
}

export default DashboardViewContainer;
