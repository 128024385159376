import common from "../common.copyText";

export default {
  ...common,
  addAllButtonLabel: "Add All",
  addFilterValueDialogue: 'Add "%VALUE%"',
  applyFilterButtonLabel: "Apply Filter",
  cancelButtonLabel: "Cancel",
  chartEmptyPlaceholderText: "No Usage Data to Display",
  closeButtonLabel: "Close",
  compareToggleLabel: "Compare",
  confirmButtonLabel: "Confirm",
  dataAttributeMeasure: "Measure",
  dataTableColumnLimitReached: "Column limit reached. To see the full result, you may want to export the dataset as a CSV",
  dataTableRowLimitReached: "Row limit reached. To see the full result, you may want to export the dataset as a CSV",
  datePickerModalDays: "Days",
  datePickerModalIncludeCurrentLabel: "Include Current",
  datePickerModalMonths: "Months",
  dataTableTotalsHeader: "Totals",
  dataTableTotalsDeltasHeader: "Totals Deltas",
  datePickerCustomCompareHeader: "Custom compare range",
  datePickerComparePreviousPeriodOption: "Previous period",
  datePickerCompareCustomOption: "Custom date range",
  dateRangeControlsCompareLabel: "Compare",
  datePickerModalComparision: "Comparison",
  datePickerModalEndLabel: "End",
  datePickerModalFixed: "Fixed",
  datePickerModalInvoiceMonthLabel: "Invoice Month",
  datePickerModalLast: "Last",
  datePickerModalOr: "Or",
  datePickerModalRolling: "Rolling",
  datePickerModalStartLabel: "Start",
  datePickerButtonApply: "Apply",
  datePickerButtonReset: "Reset",
  datePickerModalTitleCustom: "Custom Date Range",
  datePickerModalTitleInvoice: "Invoice Month Range",
  datePickerSelectEndPlaceholderText: "Select End Date",
  datePickerSelectSingleMonthPlaceholderText: "Select a Month",
  datePickerSelectStartPlaceholderText: "Select Start Date",
  datePickerTabFixed: "Fixed",
  datePickerTabInvoice: "Invoice",
  datePickerTabRolling: "Rolling",
  dateRangeControlsCustomLabel: "Custom",
  dateRangeControlsRollingDaysLabel: "Last %n% Days",
  dateRangeControlsRollingMonthsLabel: "Last %n% Months",
  dateRangeControlsInvoiceLabel: "Invoice",
  dateRangeControlsLast30DaysLabel_default: "30D",
  dateRangeControlsLast30DaysLabel_fiscal: "Prev. Month",
  dateRangeControlsLast7DaysLabel_default: "7D",
  dateRangeControlsLast7DaysLabel_fiscal: "Prev. Week",
  dateRangeControlsLast90DaysLabel_default: "90D",
  dateRangeControlsLast90DaysLabel_fiscal: "Prev. Quarter",
  dateRangeControlsLastMonthLabel_default: "Last Month",
  dateRangeControlsMTDLabel_default: "MTD",
  dateRangeControlsMTDLabel_fiscal: "Fiscal MTD",
  dateRangeControlsQTDLabel_default: "QTD",
  dateRangeControlsQTDLabel_fiscal: "Fiscal QTD",
  dateRangeControlsYesterdayLabel: "Yesterday",
  dateRangeControlsYTDLabel_default: "YTD",
  dateRangeControlsYTDLabel_fiscal: "Fiscal YTD",
  defaultSelectPlaceholder: "Select...",
  defaultSelectAllPlaceholder: "Select All",
  defaultDeselectAllPlaceholder: "Deselect All",
  disableCompareToggleTooltipCaption: "Chart type not supported, please select Line or Table to execute comparison.",
  disableDateControlTooltipCaption:"Resource only supported for 31 days or less",
  dropdownNoOptionsPlaceholder: "No Options Available",
  excessiveCardinalityExplanation: "We have detected that this configuration resulted in a dataset that is too large to display here.",
  excessiveCardinalityNextSteps: "Please try adding additional filters and / or removing unnecessary groupings.",
  excessiveCardinalityTitle: "Large Dataset Detected",
  exportButtonLabel: "Export",
  favoriteButtonLabel: "Favorite",
  moreCaption: "more",
  moreOptionsLabel: "More...",
  nextButtonLabel: "Next",
  noDataPlaceholderMessage: "No Data To Display",
  notShownCaption: "others", 
  optionalStepCaption: "Optional",
  previousButtonLabel: "Previous",
  removeAllButtonLabel: "Remove All",
  rollingWindowHelpMessageDay: "Count does not include today.",
  rollingWindowHelpMessageMonth: "Count does not include this month.",
  searchInputPlaceholder: "Search...",
  selectValueLabel: "Select Value...",
  submitButtonLabel: "Submit",
  textInputLabel: "Search",
};

