/* prettier-ignore */
export default {
  announceKitWidgetID: "https://announcekit.co/widgets/v2/2RdKeY",
  companyWebsite: "https://ternary.app",
  gcpGeneralPurposeCommitmentsDocumentation: "https://cloud.google.com/compute/docs/instances/signing-up-committed-use-discounts#general-purpose_commitments",
  gcpComputeOptimizedCommitmentsDocumentation: "https://cloud.google.com/compute/docs/instances/signing-up-committed-use-discounts#compute-optimized_commitments",
  readmeBillingDataPerformanceModeDocumentation: "https://docs.ternary.app/docs/billing-data-performance-mode",
  readmeBudgetsDocumentation: "https://docs.ternary.app/docs/budgets",
  readmeCostAllocationDocumentation: "https://docs.ternary.app/docs/cost-allocation",
  readmeCustomLabelsDocumentation: "https://docs.ternary.app/docs/custom-labels-1",
  readmeCustomGroupingDocumentation: "https://docs.ternary.app/docs/custom-labels#custom-groupings",
  readmeDocumentationHome: "https://docs.ternary.app/docs/home",
  readmeAlibabaOnboardingDocumentation: "https://docs.ternary.app/docs/alibaba-cloud",
  readmeAwsOnboardingDocumentation: "https://docs.ternary.app/docs/amazon-web-services",
  readmeAzureOnboardingDocumentation: "https://docs.ternary.app/docs/microsoft-azure",
  readmeGCPOnboardingDocumentation: "https://docs.ternary.app/docs/google-cloud-platform",
  readmeMongoDBOnboardingDocumentation: "https://docs.ternary.app/docs/mongodb-atlas",
  readmeOCIOnboardingDocumentation: "https://docs.ternary.app/docs/oracle-cloud-infrastructure",
  readmeSnowflakeOnboardingDocumentation: "https://docs.ternary.app/docs/snowflake",
  readmeJiraIntegrationDocumentation: "https://docs.ternary.app/docs/jira",
  readmeRampPlanDocumentation: "https://docs.ternary.app/docs/ramp-plans",
  readmeRolePermissionsDocumentation: "https://docs.ternary.app/docs/users-and-roles",
  readmeSlackIntegrationDocumentation: "https://docs.ternary.app/docs/slack",
  mspBillingConfigDocs: "https://docs.ternary.app/docs/msp-billing-configuration",
  awsIntegrationPayerAccountDocumentation: "https://github.com/TernaryInc/ternary-onb-permissions/tree/master/aws/payer-account",
  awsIntegrationMonitoringDocumentation: "https://github.com/TernaryInc/ternary-onb-permissions/tree/master/aws/centralized-monitoring",
  awsIntegrationCentralizedMonitoringDocumentation: "https://github.com/TernaryInc/aws-centralized-monitoring",
  awsIntegrationLinkedAccountsDocumentation: "https://github.com/TernaryInc/ternary-onb-permissions/tree/master/aws/linked-account",
};
