import { UnitType } from "../enums";

const measures = [
  { name: "CPUCoreUsageTime", unit: UnitType.STANDARD },
  { name: "cost", unit: UnitType.CURRENCY },
  { name: "totalCPUCores", unit: UnitType.STANDARD },
  { name: "totalCPUCost", unit: UnitType.CURRENCY },
  { name: "totalGPUMemoryBytes", unit: UnitType.BYTES },
  { name: "totalMemoryCost", unit: UnitType.CURRENCY },
  { name: "totalMemoryBytes", unit: UnitType.BYTES },
  { name: "usedGPUMemoryBytes", unit: UnitType.BYTES },
  { name: "usedMemoryBytes", unit: UnitType.BYTES },
  { name: "wasteCPUs", unit: UnitType.STANDARD },
  { name: "wasteMemoryBytes", unit: UnitType.BYTES },
];

const dimensions = [
  "cloudId",
  "clusterName",
  "location",
  "nodeName",
  "nodepool",
  "projectId",
];

export const gcpKubernetesNodeUsageSchema = { dimensions, measures };
