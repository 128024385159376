import { getFilterValuesButtonLabel } from "@/utils/filters";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Operator } from "@ternary/api-lib/analytics/enums";
import { Filter } from "@ternary/api-lib/analytics/ui/types";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import copyText from "../copyText";
import SelectDropdown from "./SelectDropdown";

interface Props {
  compact?: boolean;
  filter: Filter;
  operator: Filter["operator"];
  isLoading: boolean;
  options: {
    label: string;
    value: string;
  }[];
  showInputStyle?: boolean;
  valueTruncateWidth?: number;
  onBlur?: () => void;
  onChange: (value: string | string[]) => void;
  onClick?: () => void;
  onCreateOption: (value: string) => void;
}

export const InputDropdown = styled("div")<{
  compact?: boolean;
  disabled?: boolean;
}>(({ compact, disabled, theme }) => ({
  alignItems: "center",
  backgroundColor: disabled
    ? theme.background_color_disabled
    : theme.panel_backgroundColor,
  borderColor: theme.secondary_color_border,
  borderRadius: theme.borderRadius_2,
  borderStyle: "solid",
  borderWidth: "1px",
  cursor: disabled ? undefined : "pointer",
  display: "flex",
  height: compact ? "30px" : "38px",
  justifyContent: "space-between",
  minHeight: compact ? "32px" : "38px",
  paddingLeft: theme.space_md,
  paddingRight: theme.space_md,
  width: "100%",

  "&:hover": {
    borderColor: disabled
      ? theme.secondary_color_border
      : theme.primary_color_border,
  },

  "&:focus": {
    borderColor: "transparent",
    boxShadow: `0 0 0 2px ${theme.primary_color_focus}`,
  },

  "&:active": {
    borderColor: disabled ? theme.secondary_color_border : "transparent",
    boxShadow: disabled
      ? theme.secondary_color_border
      : `0 0 0 2px ${theme.primary_color_focus}`,
  },
}));

export function SelectDropdownFilter({
  compact,
  filter,
  operator,
  isLoading,
  options,
  showInputStyle,
  valueTruncateWidth,
  onBlur,
  onChange,
  onClick,
  onCreateOption,
}: Props) {
  const theme = useTheme();

  return (
    !([Operator.NOT_SET, Operator.SET] as Operator[]).includes(operator) && (
      <SelectDropdown
        closeOnSubmit
        createDialogue={(input) =>
          copyText.addFilterValueDialogue.replace("%VALUE%", input)
        }
        createOption={(inputValue) => ({
          label: inputValue,
          value: inputValue,
        })}
        isLoading={isLoading}
        isCreatable
        isMulti={(
          [
            Operator.CONTAINS,
            Operator.EQUALS,
            Operator.NOT_EQUALS,
          ] as Operator[]
        ).includes(operator)}
        inputValidator={
          operator === Operator.CONTAINS ? (e) => e.length > 1 : undefined
        }
        options={options}
        placement="bottom-end"
        selectedValues={filter.values ?? undefined}
        sortSelected
        submitButtonText={copyText.applyFilterButtonLabel}
        onBlur={onBlur}
        onChange={(value: string | string[]) => {
          onChange(value);
        }}
        onCreateOption={(value: string) => onCreateOption(value)}
        onClick={onClick}
      >
        {filter.values &&
          (showInputStyle ? (
            <InputDropdown compact={compact}>
              <Text
                truncate
                style={{
                  textWrap: "nowrap",
                }}
              >
                {filter.values.length === 0
                  ? copyText.selectValueLabel
                  : getFilterValuesButtonLabel(filter.values)}
              </Text>
              <Icon
                clickable
                color={theme.text_color_placeholder}
                icon={faChevronDown}
              />
            </InputDropdown>
          ) : (
            <Button marginRight={theme.space_xs} secondary size="tiny">
              <Text truncate={valueTruncateWidth}>
                {filter.values.length === 0
                  ? copyText.selectValueLabel
                  : getFilterValuesButtonLabel(filter.values)}
              </Text>
            </Button>
          ))}
      </SelectDropdown>
    )
  );
}
