export const forecastingActions = {
  CLICK_FORECASTING_CHART_COLLAPSE: "CLICK_FORECASTING_CHART_COLLAPSE",
  CLICK_FORECASTING_CHART_EXPAND: "CLICK_FORECASTING_CHART_EXPAND",
  CLICK_FORECASTING_EXPORT: "CLICK_FORECASTING_EXPORT",
  SELECT_FORECASTING_CHART_TYPE_OPTION: "SELECT_FORECASTING_CHART_TYPE_OPTION",
  SELECT_FORECASTING_GRANULARITY: "SELECT_FORECASTING_GRANULARITY",
  SELECT_FORECASTING_GROUPINGS: "SELECT_FORECASTING_GROUPINGS",
  SELECT_FORECASTING_LOOK_AHEAD_RANGE: "SELECT_FORECASTING_LOOK_AHEAD_RANGE",
  SELECT_FORECASTING_LOOKBACK_RANGE: "SELECT_FORECASTING_LOOKBACK_RANGE",
  SELECT_FORECASTING_MEASURE: "SELECT_FORECASTING_MEASURE",
  SELECT_FORECASTING_VENDOR: "SELECT_FORECASTING_VENDOR",
} as const;
