import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import copyText from "../copyText";

type Options = UseQueryOptions<number[], CoreAPIClientError>;
type Result = UseQueryResult<number[], CoreAPIClientError>;

export function useGenerateMspBillingStatementPdf(
  statementID: string,
  options?: Options
): Result {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["mspBillingStatements", statementID, "pdf"],
    queryFn: () => client.generateMspBillingStatementPdf(statementID),
    meta: {
      errorMessage: copyText.errorGeneratingMspBillingStatementPdfMessage,
    },
    ...options,
  });
}
