import Card from "@/components/Card";
import { DateHelper } from "@/lib/dates";
import { keyframes, useTheme } from "@emotion/react";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { endOfDay, format, isAfter, isBefore } from "date-fns";
import React from "react";
import copyText from "../../copyText";
import { fiscalDateFns } from "../../hooks/useFiscalYearsData";
import { FiscalMonth, FiscalQuarter, FiscalYear } from "../../types";

type Props = {
  fiscalYear: FiscalYear | null;
};

const slideIn = keyframes({
  from: {
    transform: "translateX(-50px)",
    opacity: 0,
  },
  to: {
    transform: "translateX(0)",
    opacity: 1,
  },
});

const MONTH_BOX_WIDTH = 100;

export default function FiscalCalendarView(props: Props) {
  const theme = useTheme();

  if (!props.fiscalYear) return null;

  return (
    <Box>
      <Box
        animation={`${slideIn} 400ms 0ms forwards`}
        key={`${props.fiscalYear.yearNumber}-Q1`}
        marginVertical={theme.space_sm}
        opacity={0}
      >
        <Quarter quarter={props.fiscalYear.Q1} year={props.fiscalYear} />
      </Box>
      <Box
        animation={`${slideIn} 400ms 50ms forwards`}
        key={`${props.fiscalYear.yearNumber}-Q2`}
        marginVertical={theme.space_sm}
        opacity={0}
      >
        <Quarter quarter={props.fiscalYear.Q2} year={props.fiscalYear} />
      </Box>
      <Box
        animation={`${slideIn} 400ms 100ms forwards`}
        key={`${props.fiscalYear.yearNumber}-Q3`}
        marginVertical={theme.space_sm}
        opacity={0}
      >
        <Quarter quarter={props.fiscalYear.Q3} year={props.fiscalYear} />
      </Box>
      <Box
        animation={`${slideIn} 400ms 150ms forwards`}
        key={`${props.fiscalYear.yearNumber}-Q4`}
        marginVertical={theme.space_sm}
        opacity={0}
      >
        <Quarter quarter={props.fiscalYear.Q4} year={props.fiscalYear} />
      </Box>
    </Box>
  );
}

type QuarterProps = {
  quarter: FiscalQuarter;
  year: FiscalYear;
};

function Quarter(props: QuarterProps) {
  const now = new DateHelper().date;
  const theme = useTheme();
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Box flex="0 0 30px">
        <Text
          color={theme.text_color_disabled}
          fontSize={theme.h4_fontSize}
          bold
        >
          {copyText.fiscalCalendarViewQuarterNumber.replace(
            "%QUARTER_NUMBER%",
            props.quarter.quarterNumber
          )}
        </Text>
      </Box>

      {props.quarter.months.map((month, index) => {
        const monthStart = fiscalDateFns.stringToDate(month.startDate);
        const monthEnd = endOfDay(fiscalDateFns.stringToDate(month.endDate));
        const isCurrentMonth =
          isAfter(now, monthStart) && isBefore(now, monthEnd);
        const isPastMonth = !isCurrentMonth && isAfter(now, monthEnd);

        return (
          <React.Fragment key={month.monthNumber}>
            <Box
              flex={index === 0 ? "1 0 0" : "2 0 0"}
              borderTop={`3px solid ${theme.secondary_color_border}`}
            />

            <Box flex={`0 0 ${MONTH_BOX_WIDTH}px`}>
              <Month
                isCurrentMonth={isCurrentMonth}
                isPastMonth={isPastMonth}
                month={month}
                quarter={props.quarter}
                year={props.year}
              />
            </Box>
          </React.Fragment>
        );
      })}

      <Box flex="1 0 0" />
      <Box flex="0 0 30px" />
    </Flex>
  );
}

type MonthProps = {
  isCurrentMonth: boolean;
  isPastMonth: boolean;
  month: FiscalMonth;
  quarter: FiscalQuarter;
  year: FiscalYear;
};

function Month(props: MonthProps) {
  const theme = useTheme();

  const headerBackgroundColor = props.isPastMonth
    ? theme.fiscal_calendar_month_header_background_past
    : theme.fiscal_calendar_month_header_background;

  const mainBackgroundColor = props.isCurrentMonth
    ? theme.primary_color_background
    : props.isPastMonth
      ? theme.fiscal_calendar_month_background_past
      : theme.panel_backgroundColor;

  const headerTextColor = props.isPastMonth
    ? theme.text_color
    : theme.text_color;

  const mainTextColor = props.isCurrentMonth
    ? theme.text_color_inverse
    : props.isPastMonth
      ? theme.text_color
      : theme.text_color;

  const tooltipContent = (
    <Flex>
      <Box width={60}>
        <Text color={theme.text_color_inverse} fontSize={theme.fontSize_xs}>
          {copyText.fiscalCalendarViewTooltipWeek}
        </Text>
        <Text
          color={theme.text_color_inverse}
          fontWeight={theme.fontWeight_bold}
        >
          {props.month.firstWeekNumber} - {props.month.lastWeekNumber}
        </Text>
      </Box>

      <Box width={60}>
        <Text color={theme.text_color_inverse} fontSize={theme.fontSize_xs}>
          {copyText.fiscalCalendarViewTooltipQuarter}
        </Text>
        <Text
          color={theme.text_color_inverse}
          fontWeight={theme.fontWeight_bold}
        >
          {props.quarter.quarterNumber}
        </Text>
      </Box>

      <Box width={60}>
        <Text color={theme.text_color_inverse} fontSize={theme.fontSize_xs}>
          {copyText.fiscalCalendarViewTooltipYear}
        </Text>
        <Text
          color={theme.text_color_inverse}
          fontWeight={theme.fontWeight_bold}
        >
          {props.year.yearNumber}
        </Text>
      </Box>
    </Flex>
  );

  return (
    <Tooltip content={tooltipContent}>
      <Card overflow="hidden" padding={0}>
        <Box backgroundColor={headerBackgroundColor} padding={theme.space_xxs}>
          <Text
            align="center"
            color={headerTextColor}
            fontSize={theme.fontSize_xs}
            fontWeight={theme.fontWeight_bold}
            whiteSpace="nowrap"
          >
            {format(
              fiscalDateFns.stringToDate(props.month.startDate),
              "MM/dd/yy"
            ) +
              " - " +
              format(
                fiscalDateFns.stringToDate(props.month.endDate),
                "MM/dd/yy"
              )}
          </Text>
        </Box>

        <Box backgroundColor={mainBackgroundColor} padding={theme.space_md}>
          <Text
            align="center"
            color={mainTextColor}
            fontSize={theme.fontSize_base}
            fontWeight={theme.fontWeight_semiBold}
            lineHeight={1.2}
          >
            {copyText.fiscalCalendarViewMonth}
          </Text>
          <Text
            align="center"
            color={mainTextColor}
            fontSize={theme.h3_fontSize}
            lineHeight={1}
            fontWeight={theme.h3_fontWeight}
          >
            {Number(props.month.monthNumber)}
          </Text>
        </Box>
      </Card>
    </Tooltip>
  );
}
