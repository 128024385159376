import { createColumnHelper } from "@tanstack/react-table";
import {
  formatCurrency,
  formatNumber,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import copyText from "../../copyText";
import { CloudSQLResourceType, CloudSQLUsageDatum } from "../types";

type TableData = {
  databaseId: string;
  latestProvisionedCpu: number | null;
  latestProvisionedDisk: number | null;
  latestProvisionedRam: number | null;
  maxCpuAverageUsedCores: number | null;
  maxDiskUsedBytes: number | null;
  maxRamAverageUsedBytes: number | null;
  projectId: string;
  totalCost: number;
};

interface Props {
  instances: CloudSQLUsageDatum[];
  isLoading: boolean;
  resourceType: CloudSQLResourceType;
}

export default function GCPDatabaseInstanceTable(props: Props): JSX.Element {
  const columnHelper = createColumnHelper<TableData>();

  const getDefaultSortBy = () => {
    switch (props.resourceType) {
      case CloudSQLResourceType.MEMORY:
        return "latestProvisionedRam";
      case CloudSQLResourceType.DISK:
        return "latestProvisionedDisk";
      case CloudSQLResourceType.NETWORK:
        return "latestProvisionedRam";
      default:
        return "latestProvisionedCpu";
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor("databaseId", {
        header: copyText.cloudSQLInstanceTableHeaderDatabaseId,
      }),
      columnHelper.accessor("totalCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.cloudSQLInstanceTableHeaderTotalCost,
      }),
      columnHelper.accessor("latestProvisionedCpu", {
        cell: ({ getValue }) => (
          <>{formatUsage(CloudSQLResourceType.CPU, getValue())}</>
        ),
        header: copyText.cloudSQLInstanceTableHeaderMaxCpuReservedCores,
      }),
      columnHelper.accessor("maxCpuAverageUsedCores", {
        cell: ({ getValue }) => (
          <>{formatUsage(CloudSQLResourceType.CPU, getValue())}</>
        ),
        header: copyText.cloudSQLInstanceTableHeaderMaxCpuAverageUsedCores,
      }),
      columnHelper.accessor("latestProvisionedRam", {
        cell: ({ getValue }) => (
          <>{formatUsage(CloudSQLResourceType.MEMORY, getValue())}</>
        ),
        header: copyText.cloudSQLInstanceTableHeaderRamReservedBytes,
      }),
      columnHelper.accessor("maxRamAverageUsedBytes", {
        cell: ({ getValue }) => (
          <>{formatUsage(CloudSQLResourceType.MEMORY, getValue())}</>
        ),
        header: copyText.cloudSQLInstanceTableHeaderMaxRamAverageUsedBytes,
      }),
      columnHelper.accessor("latestProvisionedDisk", {
        cell: ({ getValue }) => (
          <>{formatUsage(CloudSQLResourceType.DISK, getValue())}</>
        ),
        header: copyText.cloudSQLInstanceTableHeaderMaxDiskSizeBytes,
      }),
      columnHelper.accessor("maxDiskUsedBytes", {
        cell: ({ getValue }) => (
          <>{formatUsage(CloudSQLResourceType.DISK, getValue())}</>
        ),
        header: copyText.cloudSQLInstanceTableHeaderMaxDiskUsedBytes,
      }),
    ],
    []
  );

  const data = useMemo(
    () =>
      props.instances.map(
        (node): TableData => ({
          databaseId: node.databaseId ?? "",
          latestProvisionedCpu: node.latestProvisionedCpu ?? 0,
          latestProvisionedDisk: node.latestProvisionedDisk ?? 0,
          latestProvisionedRam: node.latestProvisionedRam ?? 0,
          maxCpuAverageUsedCores: node.maxCpuAverageUsedCores ?? 0,
          maxDiskUsedBytes: node.avgDiskUsedBytes ?? 0,
          maxRamAverageUsedBytes: node.avgRamUsedBytes ?? 0,
          projectId: node.projectId ?? "",
          totalCost: node.totalCost ?? "",
        })
      ),
    [props.instances]
  );

  return (
    <Table
      columns={columns}
      data={data}
      initialState={{
        sorting: [{ id: getDefaultSortBy(), desc: true }],
      }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

const formatUsage = (usageType: CloudSQLResourceType, bytes: number | null) => {
  if (bytes === null) return copyText.cloudSQLResourceTableLabelNotAvailable;
  return usageType !== CloudSQLResourceType.CPU
    ? prettyBytes(bytes)
    : formatNumber(bytes, 2);
};
