import { UnitType } from "../enums";

const measures = [
  { name: "sum_TotalCPUHour", unit: UnitType.STANDARD },
  { name: "sum_TotalMemoryByteHour", unit: UnitType.BYTES },
  { name: "sum_RemainingCPUHour", unit: UnitType.STANDARD },
  { name: "sum_RemainingMemoryByteHour", unit: UnitType.BYTES },
  { name: "sum_UsedCPUHour", unit: UnitType.BYTES },
  { name: "sum_UsedMemoryByteHour", unit: UnitType.BYTES },
  { name: "max_PerNodeHour_CPUUtilization", unit: UnitType.STANDARD },
  { name: "max_PerNodeHour_FilesystemUtilization", unit: UnitType.STANDARD },
  { name: "max_PerNodeHour_CPUReservedCapacity", unit: UnitType.STANDARD },
  { name: "max_PerNodeHour_MemoryReservedCapacity", unit: UnitType.STANDARD },
  { name: "max_PerNodeHour_MemoryUtilization", unit: UnitType.STANDARD },
  {
    name: "max_PerNodeHour_NetworkTotalBytesPerSecond",
    unit: UnitType.STANDARD,
  },
  {
    name: "max_PerNodeHour_NumberOfRunningContainers",
    unit: UnitType.STANDARD,
  },
  { name: "max_PerNodeHour_NumberOfRunningPods", unit: UnitType.STANDARD },
];

const dimensions = [
  "accountId",
  "clusterName",
  "nodeName",
  "instanceId",
  "instanceType",
  "instanceTypeFamily",
  "vcpuPerInstance",
  "memoryPerInstance",
  "region",
  "timestamp",
];

export const awsKubernetesNodeUsageSchema = { dimensions, measures };
