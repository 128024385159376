import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import getBudgetData from "@ternary/api-lib/analytics/api/getBudgetData";
import { TimeGranularity } from "@ternary/api-lib/analytics/enums";
import { BudgetEntity, LabelMapsEntity } from "@ternary/api-lib/core/types";
import UError from "unilib-error";
import { BudgetSpendEntity } from "../../../api/analytics/types";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

interface Params {
  budget: BudgetEntity | undefined;
  dateRange: Date[];
  granularity: TimeGranularity;
}

export default function useGetBudgetSpendByGranularity(
  params: Params,
  options?: UseQueryOptions<BudgetSpendEntity[], UError>
): UseQueryResult<BudgetSpendEntity[], UError> {
  const queryClient = useQueryClient();
  const client = useAnalyticsApiClient();
  const authenticatedUser = useAuthenticatedUser();
  const tenantID = authenticatedUser.tenant.id;

  const labelMaps = queryClient.getQueryData<LabelMapsEntity>(["labelMaps"]);

  if (!labelMaps) {
    throw new Error("ERROR_MAPS_UNDEFINED");
  }

  return useQuery({
    queryKey: ["budgetSpendSummary", params],
    queryFn: async () => {
      const budget = params.budget;

      if (!budget) return [];

      const result = await getBudgetData({
        ...params,
        tenantID,
        budget,
        client,
      });

      return result;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
