import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import { TimeGranularity, UnitType } from "@ternary/api-lib/analytics/enums";
import {
  areaStyleProps,
  barStyleProps,
  cartesianStyleProps,
  xAxisStyleProps,
  yAxisStyleProps,
} from "@ternary/api-lib/analytics/ui/styles";
import TimeSeriesChartTooltip from "@ternary/api-lib/analytics/ui/TimeSeriesChartTooltip";
import {
  formatMeasureValueWithUnit,
  formatTimestamp,
} from "@ternary/api-lib/analytics/utils/ChartFormatUtils";
import { getFormatForGranularity } from "@ternary/api-lib/analytics/utils/DateUtils";
import { ServiceType } from "@ternary/api-lib/constants/enums";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import { groupBy, isString, noop, uniq } from "lodash";
import React, { useMemo, useState } from "react";
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import copyText from "../../copyText";
import { AWSCommittedUseChartData, AWSCommittedUseType } from "../types";

type Props = {
  committedUseType: AWSCommittedUseType;
  data: AWSCommittedUseChartData[];
  hasEstimated: boolean;
  isLoading: boolean;
  serviceType: ServiceType;
};

export default function AWSCommittedUseChart(props: Props) {
  const theme = useTheme();
  const [excludedKeysMap, setExcludedKeysMap] = useState({
    covered: false,
    estimated: false,
    usage: false,
  });

  const dateFormat = getFormatForGranularity(TimeGranularity.DAY);

  const colors = {
    covered: theme.aws_cud_color_covered,
    estimated: theme.aws_cud_color_estimated,
    usage: theme.aws_cud_color_on_demand,
    disabled: theme.background_color_disabled,
  };

  const mergedData = useMemo(
    () => mergeDataAtTimestamps(props.data, ["covered", "estimated", "usage"]),
    [props.data]
  );

  if (props.isLoading || props.data.length === 0) {
    return (
      <EmptyPlaceholder
        loading={props.isLoading}
        icon={faChartBar}
        skeletonVariant="cartesian"
        text={copyText.chartEmptyPlaceholderText}
      />
    );
  }

  const toggleKey = (key: string) =>
    setExcludedKeysMap((keyMap) => ({ ...keyMap, [key]: !keyMap[key] }));

  const formatYAxisValue = (value: number) =>
    formatMeasureValueWithUnit({
      unit:
        props.committedUseType === AWSCommittedUseType.RI &&
        props.serviceType !== ServiceType.OPEN_SEARCH
          ? UnitType.STANDARD
          : UnitType.CURRENCY,
      value,
    });
  const dataWithExcludedRemoved = mergedData.map((datum) => ({
    covered: excludedKeysMap.covered ? 0 : datum.covered,
    estimated: excludedKeysMap.estimated ? 0 : datum.estimated,
    timestamp: datum.timestamp,
    usage: excludedKeysMap.usage ? 0 : datum.usage,
  }));

  const readableKeys = {
    estimated: "Estimated Coverage",
    covered: "Current Coverage",
    usage:
      props.committedUseType === AWSCommittedUseType.RI
        ? "Usage Hours"
        : "Compute Usage",
  };

  return (
    <StyledBox>
      <ResponsiveContainer debounce={1} height="100%" width="100%">
        <ComposedChart
          data={dataWithExcludedRemoved}
          margin={{ left: 25, right: 25 }}
        >
          <defs>
            <linearGradient id="cudColorCovered" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={colors.covered} stopOpacity={0.6} />
              <stop offset="95%" stopColor={colors.covered} stopOpacity={0.3} />
            </linearGradient>
            <linearGradient id="cudColorEstimated" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={colors.estimated}
                stopOpacity={0.6}
              />
              <stop
                offset="95%"
                stopColor={colors.estimated}
                stopOpacity={0.3}
              />
            </linearGradient>
            <linearGradient id="cudColorDisabled" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={colors.disabled} stopOpacity={0.6} />
              <stop
                offset="95%"
                stopColor={colors.disabled}
                stopOpacity={0.3}
              />
            </linearGradient>
          </defs>

          <CartesianGrid
            {...cartesianStyleProps}
            stroke={theme.chart_cartesian_grid_lines}
          />

          <XAxis
            {...xAxisStyleProps}
            dataKey="timestamp"
            stroke={theme.chart_axis_text}
            tick={{
              stroke: theme.chart_axis_text,
              fontWeight: 100,
              fontSize: "0.8rem",
            }}
            tickFormatter={(value) => formatTimestamp(value, dateFormat)}
          />

          <YAxis
            {...yAxisStyleProps}
            domain={[0, "auto"]}
            label={
              props.committedUseType === AWSCommittedUseType.RI
                ? {
                    value: copyText.awsChartYAxisLabel,
                    angle: -90,
                    position: "left",
                    offset: 0,
                  }
                : undefined
            }
            stroke={theme.chart_axis_text}
            tick={{
              stroke: theme.chart_axis_text,
              fontWeight: 100,
              fontSize: "0.8rem",
            }}
            tickCount={8}
            tickFormatter={formatYAxisValue}
          />

          <Tooltip
            content={(tooltipProps) => {
              return (
                <TimeSeriesChartTooltip
                  customColorsKeyedByGrouping={colors}
                  dateFormat={dateFormat}
                  entry={tooltipProps.payload?.[0]?.payload}
                  excludedGroupings={[]}
                  hideTotal
                  label={tooltipProps.label}
                  reverseSortedGroupings={["usage", "estimated", "covered"]}
                  formatter={formatYAxisValue}
                  formatGroupingLabelFromKey={(key) => readableKeys[key] ?? key}
                />
              );
            }}
            wrapperStyle={{ outline: "none" }}
          />

          <Legend
            iconType="square"
            verticalAlign="bottom"
            onClick={(payload) =>
              isString(payload.dataKey) ? toggleKey(payload.dataKey) : noop()
            }
            formatter={(key) => readableKeys[key] ?? key}
          />

          <Bar
            {...barStyleProps}
            fillOpacity={theme.chart_fill_opacity}
            barSize={20}
            dataKey="usage"
            fill={excludedKeysMap.usage ? colors.disabled : colors.usage}
          />

          <Area
            {...areaStyleProps}
            dataKey="covered"
            fill={
              excludedKeysMap.covered
                ? "url(#cudColorDisabled)"
                : "url(#cudColorCovered)"
            }
            fillOpacity={1}
            stackId="area"
            strokeOpacity={excludedKeysMap.covered ? 0 : 1}
            stroke={excludedKeysMap.covered ? colors.disabled : colors.covered}
          />

          <Area
            {...areaStyleProps}
            dataKey="estimated"
            fill={
              excludedKeysMap.estimated
                ? "url(#cudColorDisabled)"
                : "url(#cudColorEstimated)"
            }
            fillOpacity={1}
            stackId="area"
            strokeOpacity={
              excludedKeysMap.estimated || !props.hasEstimated ? 0 : 1
            }
            stroke={
              excludedKeysMap.estimated ? colors.disabled : colors.estimated
            }
          />
        </ComposedChart>
      </ResponsiveContainer>
    </StyledBox>
  );
}

const StyledBox = styled(Box)`
  height: 100%;

  .recharts-legend-wrapper {
    max-height: 8rem;
    overflow-y: auto;
  }

  .recharts-legend-item {
    display: flex !important;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: ${({ theme }) => theme.space_xs};

    .recharts-symbols {
      d: path("M -8 -16 h 32 v 32 h -32 Z");
    }
  }

  .recharts-text.recharts-label {
    color: ${(props) => props.theme.text_color} !important;
    font-size: ${({ theme }) => theme.fontSize_ui};
  }

  span.recharts-legend-item-text {
    color: ${(props) => props.theme.text_color} !important;
    display: block;
    font-size: ${({ theme }) => theme.fontSize_ui};
    /* Bring in the below if legend key lengths become an issue */
    /* max-width: 40rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
  }

  .recharts-default-legend {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;

    li {
      cursor: pointer;
    }
  }
`;

function mergeAtKeys<Datum extends Record<string, unknown>>(
  data: Datum[],
  keys: (keyof Datum)[]
): Datum {
  return data.reduce((sum, datum) => {
    const copy: Record<string, unknown> = { ...sum };

    keys.forEach((key) => {
      const current = datum[key];
      const total = copy[key as string];
      if (typeof current === "number" && typeof total === "number") {
        copy[key as string] = current + total;
      }
    });

    return copy as Datum;
  });
}

function mergeDataAtTimestamps<Datum extends Record<string, unknown>>(
  data: Datum[],
  keysToMerge: (keyof Datum)[]
): Datum[] {
  const timestamps = uniq(data.map((datum) => datum.timestamp));
  const dataGroupedByTimestamp = groupBy(data, "timestamp");
  return timestamps.map((timestamp) =>
    mergeAtKeys(dataGroupedByTimestamp[`${timestamp}`], keysToMerge)
  );
}
