import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { statementID: string }
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { statementID: string }
>;

export function useDeleteMspBillingStatement(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ statementID }) =>
      client.deleteMspBillingStatement(statementID),
    ...options,
  });
}
