import useRefFn from "@/hooks/useRefFn";
import { LinkWithSearchParams } from "@/lib/react-router";
import { createStructParam } from "@/lib/use-query-params";
import { createSortingUtils } from "@/utils/sort";
import { useTheme } from "@emotion/react";
import { faLeaf, faTableList } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import {
  formatCurrency,
  formatNumber,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import {
  GCPCloudRunServiceGroup,
  GCPCloudRunServiceGroupFilters,
} from "../types";

type TableData = {
  averageMemory: number;
  averageVCPU: number;
  cost: number;
  lowCarbon: boolean;
  projectId: string;
  region: string;
  regionalPriceTier: string;
  serviceCount: number;
};

type Props = {
  isLoading: boolean;
  serviceGroups: GCPCloudRunServiceGroup[];
  onInteraction: (
    interaction: GCPCloudRunServiceGroupTable.Interaction
  ) => void;
};

const sortRuleStruct = z.object({
  id: z.string(),
  desc: z.boolean(),
});

const { numberSort, stringSort } = createSortingUtils<TableData>();

function GCPCloudRunServiceGroupTable(props: Props) {
  const theme = useTheme();
  const columnHelper = createColumnHelper<TableData>();

  const [sortRule, setSortRule] = useQueryParam(
    "group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "cost",
    })
  );

  const tableData = useMemo(
    () =>
      props.serviceGroups.map((serviceGroup) => ({
        averageMemory: serviceGroup.averageMemory ?? 0,
        averageVCPU: serviceGroup.averageVCPU ?? 0,
        cost: serviceGroup.cost ?? 0,
        lowCarbon: serviceGroup.lowCarbon ?? false,
        projectId: serviceGroup.projectId ?? copyText.cloudRunTableNull,
        region: serviceGroup.region ?? copyText.cloudRunTableNull,
        regionalPriceTier:
          serviceGroup.regionalPriceTier ?? copyText.cloudRunTableNull,
        serviceCount: serviceGroup.averageNumServices ?? 0,
      })),
    [props.serviceGroups]
  );

  // useRefFn keeps the props.onInteraction closure from becoming stale
  const onInteraction = useRefFn(props.onInteraction);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "viewServices",
        cell: ({ row }) => (
          <Tooltip
            content={copyText.cloudRunTableServiceGroupTooltipLabelViewServices}
          >
            <LinkWithSearchParams
              searchParams={{
                selected_group: getSelectedGroupJSON(row.original),
              }}
            >
              <Button
                iconStart={<Icon icon={faTableList} />}
                primary
                size="tiny"
              />
            </LinkWithSearchParams>
          </Tooltip>
        ),
        enableSorting: false,
        size: 50,
      }),
      columnHelper.accessor("lowCarbon", {
        cell: ({ getValue }) => (
          <Tooltip
            width="200px"
            content={
              getValue()
                ? copyText.cloudRunTableServiceGroupLowCarbonToolTip
                : copyText.cloudRunTableServiceGroupHighCarbonToolTip
            }
          >
            <Icon
              clickable
              color={
                getValue() ? theme.feedback_positive : theme.feedback_neutral
              }
              icon={faLeaf}
              onClick={() =>
                props.onInteraction({
                  type: GCPCloudRunServiceGroupTable.INTERACTION_FILTER_CLICKED,
                  filterKey: "lowCarbon",
                  filterValue: getValue().toString(),
                })
              }
            />
          </Tooltip>
        ),
        size: 25,
      }),
      columnHelper.accessor("projectId", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              onInteraction({
                type: GCPCloudRunServiceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "projectId",
                filterValue: getValue(),
              })
            }
          >
            {getValue() || "null"}
          </Text>
        ),
        header: copyText.cloudRunTableServiceGroupHeader_projectId,
        meta: { align: "left" },
        size: 100,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("region", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              onInteraction({
                type: GCPCloudRunServiceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "region",
                filterValue: getValue(),
              })
            }
          >
            {getValue()}
          </Text>
        ),
        header: copyText.cloudRunTableServiceGroupHeader_region,
        meta: { align: "left" },
        size: 100,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("regionalPriceTier", {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              onInteraction({
                type: GCPCloudRunServiceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "regionalPriceTier",
                filterValue: getValue(),
              })
            }
          >
            {getValue()}
          </Text>
        ),
        header: copyText.cloudRunTableServiceGroupHeader_type,
        meta: { align: "left" },
        size: 100,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("cost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.cloudRunTableServiceGroupHeader_cost,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("serviceCount", {
        cell: ({ getValue }) => getValue(),
        header: copyText.cloudRunTableServiceGroupHeader_serviceCount,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("averageVCPU", {
        cell: ({ getValue }) => <>{formatNumber(getValue(), 1)}</>,
        header: copyText.cloudRunTableServiceGroupHeader_averageVCPU,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("averageMemory", {
        cell: ({ getValue }) => (
          <>{prettyBytes(getValue(), { binary: true })}</>
        ),
        header: copyText.cloudRunTableServiceGroupHeader_averageMemory,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
    ],
    [onInteraction]
  );

  return (
    <Table
      columns={columns}
      data={tableData}
      initialState={{ sorting: [sortRule] }}
      isLoading={props.isLoading}
      showPagination
      sortable
      onChangeSortBy={([newSortRule]) => setSortRule(newSortRule)}
    />
  );
}

function getSelectedGroupJSON(datum: TableData) {
  try {
    return JSON.stringify({
      projectId: datum.projectId,
      region: datum.region,
      regionalPriceTier: datum.regionalPriceTier,
    });
  } catch {
    return "";
  }
}

GCPCloudRunServiceGroupTable.INTERACTION_FILTER_CLICKED =
  `GCPCloudRunServiceGroupTable.INTERACTION_FILTER_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof GCPCloudRunServiceGroupTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof GCPCloudRunServiceGroupFilters;
  filterValue: GCPCloudRunServiceGroupFilters[keyof GCPCloudRunServiceGroupFilters];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace GCPCloudRunServiceGroupTable {
  export type Interaction = InteractionFilterClicked;
}

export default GCPCloudRunServiceGroupTable;
