import { buildCubeQuery } from "@/api/analytics/utils";
import { validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import { CostAlertSummary, CostDatum } from "../types";
import { CostAlertStruct, CostStruct } from "./schema";

export interface Params {
  dateRange: Date[];
  filters?: QueryFilter[];
}

export default function useGetCostAlertSummary(
  params: Params,
  options?: UseQueryOptions<CostAlertSummary, UError>
): UseQueryResult<CostAlertSummary, UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["costAlertSummary", params],
    queryFn: async () => {
      const costResult = await client.load(
        buildCubeQuery({
          dataSource: DataSource.BILLING,
          dateRange: params.dateRange,
          measures: ["cost"],
        })
      );

      const measures = [
        "negativeDelta",
        "positiveDelta",
        "totalAlertEvents",
        "totalDelta",
      ];

      const alertResult = await client.load(
        buildCubeQuery({
          dataSource: DataSource.ALERT_EVENTS,
          dateRange: params.dateRange,
          queryFilters: params.filters,
          measures,
        })
      );

      const costData = costResult.map((datum): CostDatum => {
        const [error, validData] = validate(datum, CostStruct);
        if (error) {
          throw new UError("INVALID_COST_DATA", {
            context: { error, result: datum },
          });
        }

        return {
          cost: validData.cost,
        };
      });

      const alertData = alertResult.map(
        (datum): Omit<CostAlertSummary, "percentageAnomalous"> => {
          const [error, validData] = validate(datum, CostAlertStruct);

          if (error) {
            throw new UError("INVALID_ALERT_EVENT_DATA", {
              context: { error, result: datum },
            });
          }

          return {
            negativeDelta: validData.negativeDelta ?? 0,
            positiveDelta: validData.positiveDelta ?? 0,
            totalAlerts: validData.totalAlertEvents ?? 0,
            totalDelta: validData.totalDelta ?? 0,
          };
        }
      );

      return {
        negativeDelta: alertData[0].negativeDelta,
        percentageAnomalous:
          alertData[0].totalDelta /
          (costData[0].cost ? costData[0].cost : alertData[0].totalDelta),
        positiveDelta: alertData[0].positiveDelta,
        totalAlerts: alertData[0].totalAlerts,
        totalDelta: alertData[0].totalDelta,
      };
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
