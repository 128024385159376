import { FeatureFlag } from "@ternary/api-lib/constants/feature-flags";
import common from "../../common.copyText";

/* prettier-ignore */
export default {
  ...common,
  accountLogoHeadingMessage: "To customize your account, you can provide a hyperlink to your company’s logo. Supported file formats include PNG, JPG, and SVG. For the best display in dark mode, we recommend using JPG files. PNG files may not render as well in dark mode due to transparency, especially with certain brand colors.",
  accountLogoTabLabel: "Account Logo",
  allTenantsLabel: "All",
  anomaliesAlertedAtColumnGroupedRowTooltip: "This date represents the most recent alert. Expand to view the dates of each individual alert.",
  anomaliesDeltaColumnSeparator: "to",
  anomaliesKpiHeaderPercentAnomalousSpend: "% of Spend (Last 90 Days)",
  anomaliesKpiHeaderTotalAnomalousSpend: "Anomalous Spend (Last 90 Days)",
  anomaliesTabHeader: "Anomaly Detection",
  cancelMspTenantsSettingModalMessage: "Are you sure you want to cancel changes This operation will clear all changes",
  createRuleSetButtonLabel: "Create Rule Set",
  createRuleSetTooltipContent: "Create a set of rules that will be applied each time your billing data is updated in Ternary.",
  deleteRuleSetModalMessage: "This will permanently delete the ruleset and all its rules. Are you sure you want to do this?",
  deleteRuleSetModalTitle: "Delete Rule Set",
  dimensionLabel_billingAccountId: "Billing Account ID",
  dimensionLabel_provider: "Provider",
  dimensionLabel_region: "Region",
  dimensionLabel_serviceName: "Service Name",
  errorCreatingRuleSetMessage: "An error occurred while attempting to create the rule set. Please try again.",
  errorDeletingRuleSetMessage: "An error occurred while attempting to delete the rule set. Please try again.",
  errorGrantingUserTenantAccessMessage: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "An error occurred when attempting to grant access to the business unit. Please try again.",
    default: "An error occurred when attempting to grant access to the tenant. Please try again."
  },
  errorLoadingRuleSetsMessage: "There was an error loading rule sets. Please try again.",
  errorLoadingTenantSettingsMessage: "There was an error loading the settings. Please try again.",
  errorUpdatingMspTenantSettingsMessage: "An error occurred when attempting to update the settings. Please try again.",
  errorUpdatingRuleSetsMessage: "An error occurred while attempting to update the rule set. Please try again.",
  filterTenantsAnomaliesText: "anomalies",
  filterTenantsDropdownPlaceholder: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Filter Business Units",
    default: "Filter Tenants",
  },
  filterTenantsRateOptimizationText: "rate optimizations",
  filterTenantsUsageOptimizationText: "usage optimizations",
  formLabelAWS: "AWS",
  formLabelAzure: "Azure",
  formLabelGCP: "GCP",
  goToAnomalyDetailButtonLabel: "View Anomaly Detail",
  goToReportButtonLabel: "Identify Root Cause",
  groupedDataRefreshStatus_1: "integration(s) initial data ingestion has not completed.",
  groupedDataRefreshStatus_2: "integration(s) have not been refreshed in the last 24 hours.",
  groupedDataRefreshStatus_3: "integration(s) have not been refreshed in the last 8 hours.",
  groupedDataRefreshStatus_4: "integration(s) have been refreshed in the last 8 hours.",
  groupedLastRefreshTooltip: "This date represents the oldest data refresh. Expand to see the refresh times for each individual integration.",
  headerLabelClouds: "Clouds",
  headerLabelIntegrations: "Integrations",
  headerLabelOptimizations: "Optimizations",
  hideEmailsButtonLabel: "Hide Emails",
  imageLinkPlaceholder: "Add image link",
  imageLinkSquareCaption: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "This logo will replace the Ternary logo in the collapsed view of the left-hand navigation pane. It will also appear in the upper left corner of any billing statements generated for your business units. For optimal display, we recommend a 1:1 aspect ratio.",
    default: "This logo will replace the Ternary logo in the collapsed view of the left-hand navigation pane. It will also appear in the upper left corner of any billing statements generated for your child tenants. For optimal display, we recommend a 1:1 aspect ratio."
  },
  imageLinkSquareLabel: "Logo Square",
  imageLinkWideCaption: "This logo will replace the Ternary logo in the left-hand navigation pane. For optimal display, we recommend a 4:1 aspect ratio.",
  imageLinkWideLabel: "Logo Horizontal",
  inputFieldRequiredErrorCaption: "This field is required",
  inputPlaceholderBillingAccountIDs: "Billing Account IDs",
  inputPlaceholderFilterProvider: "Filter by Provider",
  inputPlaceholderFilterStatus: "Filter by Status",
  inputPlaceholderProviders: "Providers",
  inputPlaceholderRegions: "Regions",
  inputPlaceholderSelectGroupings: "Select Groupings",
  inputPlaceholderServiceNames: "Service Names",
  inputPlaceholderTenants: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Business Units",
    default: "Tenants",
  },
  integrationsSelectedTenantsTooltipContent: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Disabling this option will disable the integrations tab for selected business units.",
    default: "Disabling this option will disable the integrations tab for selected tenants."
  },
  integrationsTooltipContent: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Disabling this option will disable the integrations tab for non-MSP users across all of your business units.",
    default: "Disabling this option will disable the integrations tab for non-MSP users across all of your child tenants."
  },
  lastRefresh: "%VALUE% ago",
  mspTenantSettingBatchModalLabel: "Change Settings",
  mspTenantSettingBatchModalTitle: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "%amount% selected business units",
    default: "%amount% selected tenants"
  },
  noAnomaliesAfterFilteringMessage: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "The selected business unit(s) have no anomalies in the last 90 days ranking in the top 1,000 across all managed business units. Please visit the selected child business unit(s) for anomaly details.",
    default: "The selected tenant(s) have no anomalies in the last 90 days ranking in the top 1,000 across all managed tenants. Please visit the selected child tenant(s) for anomaly details."
  },
  nowDateLabel: "Now",
  optimizationsTooltipContent: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Disabling these options will disable the corresponding resource utilization and commitment discount pages for all non-MSP users across all of your business units.",
    default: "Disabling these options will disable the corresponding resource utilization and commitment discount pages for all non-MSP users across all of your child tenants."
  },
  optimizationsTooltipSelectedTenantsContent: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Disabling these options will disable the corresponding resource utilization and commitment discount pages for selected business units.", 
    default: "Disabling these options will disable the corresponding resource utilization and commitment discount pages for selected tenants."
  },
  providerTypeLabel_AWS: "AWS",
  providerTypeLabel_AZURE: "Azure",
  providerTypeLabel_GCP: "GCP",
  rateRecTermLabel_ONE_YEAR: "1 Year",
  rateRecTermLabel_THIRTY_SIX_MONTH: "3 Years",
  rateRecTermLabel_THREE_YEARS: "3 Years",
  rateRecTermLabel_TWELVE_MONTH: "1 Year",
  rateRecTypeAbbreviation_COMMITTED_USE_DISCOUNT: "CUDs",
  rateRecTypeAbbreviation_RESERVED_INSTANCE_AWS: "RIs",
  rateRecTypeAbbreviation_RESERVED_INSTANCE_AZURE: "RIs",
  rateRecTypeAbbreviation_SAVINGS_PLAN_AWS: "SPs",
  rateRecTypeAbbreviation_SAVINGS_PLAN_AZURE: "SPs",
  rateRecTypeLabel_COMMITTED_USE_DISCOUNT: "Committed Use Discount (GCP)",
  rateRecTypeLabel_RESERVED_INSTANCE_AWS: "Reserved Instance (AWS)",
  rateRecTypeLabel_RESERVED_INSTANCE_AZURE: "Reserved Instance (Azure)",
  rateRecTypeLabel_SAVINGS_PLAN_AWS: "Savings Plan (AWS)",
  rateRecTypeLabel_SAVINGS_PLAN_AZURE: "Savings Plan (Azure)",
  ruleSetFilterAppliesToAllLabel: "Applies to all",
  ruleSetFormAddDimension: "Add Dimension",
  ruleSetFormAddFilterButtonLabel: "Add Filter",
  ruleSetFormAddRuleLabel: "Add Rule",
  ruleSetFormAddTenant: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Add Business Unit",
    default: "Add Tenant",
  },
  ruleSetFormAdjustToListLabel: "Adjust to List",
  ruleSetFormAdjustToListMarginTooltip: "Line items will be adjusted to list pricing before margin is applied",
  ruleSetFormAdjustToListTooltip: "Line items will be adjusted to list pricing before rerating is applied",
  ruleSetFormApplyToAllTenants: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Apply to all Business Units?",
    default: "Apply to all tenants?",
  },
  ruleSetFormBackoutLineItems: "Backout Line Items?",
  ruleSetFormCostLabel: "Cost",
  ruleSetFormCreditsLabel: "Credits",
  ruleSetFormCurrencyLabel: "Currency",
  ruleSetFormDestinationLabel: "Destination",
  ruleSetFormDimensionsLabel: "Dimensions",
  ruleSetFormDimensionsTooltipLabel: "Assign label key-value pairs to be applied to the recurring charge.",
  ruleSetFormEndDateLabel: "End Date",
  ruleSetFormFiltersLabel: "Filters",
  ruleSetFormMarginLabel: "Target Margin",
  ruleSetFormMarginTooltip: "Apply margin by a given percentage",
  ruleSetFormMarkUpByLabel: "Mark Up By",
  ruleSetFormMetricLabel: "Metric",
  ruleSetFormNetCostLabel: "Net Cost",
  ruleSetFormPercentageLabel: "Percentage",
  ruleSetFormRecurringChargeTooltip: "Recurring charges are stated in a fixed amount. An amortized portion of this charge will be applied on the first day of each invoice month between the start and end date of the rule set.",
  ruleSetFormRequiredLabel: "(required)",
  ruleSetFormRerateLabel: "Rerate",
  ruleSetFormRerateTooltip: "Mark up by or discount by a given percentage (+/-)",
  ruleSetFormRulesLabel: "Rules",
  ruleSetFormSelectBillingAccountPlaceholder: "Select a billing account...",
  ruleSetFormSelectTenantPlaceholder: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Select a business unit...",
    default: "Select a tenant..."
  },
  ruleSetFormSelectTypePlaceholder: "Select Type",
  ruleSetFormStartDateLabel: "Start Date",
  ruleSetFormTenantsLabel: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Business Units",
    default: "Tenants",
  },
  ruleSetFormUnitLabel: "Unit",
  ruleSetFormValueLabel: "Value",
  ruleSetPageTitle: "Billing Rules Engine",
  ruleSetTableSelectTenantsPlacholder: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Select Business Units...",
    default: "Select Tenants...",
  },
  ruleSetTypeLabel_ADJUST_TO_LIST: "Adjust To List",
  ruleSetTypeLabel_BACKOUT_LINE_ITEM: "Back Out Line Item",
  ruleSetTypeLabel_MARGIN: "Target Margin",
  ruleSetTypeLabel_MOVE_BILLING_ACCOUNT: "Move Billing Line Items",
  ruleSetTypeLabel_RECURRING_CHARGE: "Recurring Charge",
  ruleSetTypeLabel_RERATE: "Rerate",
  savingsKpiHeaderPercentTotalSpend: "% of Total Spend",
  savingsKpiHeaderTotalMonthlyRateSavings: "Total Monthly Rate Savings Potential",
  savingsKpiHeaderTotalMonthlySavings: "Total Monthly Savings Potential",
  savingsKpiHeaderTotalMonthlyUsageSavings: "Total Monthly Usage Savings Potential",
  savingsTabHeader: "Savings Opportunities",
  sectionHeaderAnomalies: "Anomalies",
  sectionHeaderCloudDataStatus: "Integration Status",
  sectionHeaderMonthlyTrends: "Monthly Trends",
  sectionHeaderRateOptimizations: "Rate Optimizations",
  sectionHeaderTenantBreakdown: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Business Unit Breakdown",
    default: "Tenant Breakdown",
  },
  sectionHeaderUsageOptimizations: "Usage Optimizations",
  sectionHeaderUserAccess: "Access",
  sectionHeaderUserActivity: "Activity",
  serviceTypeLabel_ACS: "ACS",
  serviceTypeLabel_AKS: "AKS",
  serviceTypeLabel_AZURE_BLOB: "Azure Blob",
  serviceTypeLabel_AZURE_SQL: "Azure SQL",
  serviceTypeLabel_AZURE_STORAGE: "Azure Storage",
  serviceTypeLabel_AZURE_VM: "Azure VM",
  serviceTypeLabel_BIGQUERY: "BigQuery",
  serviceTypeLabel_CLOUD_RUN: "Cloud Run",
  serviceTypeLabel_CLOUDSQL: "Cloud SQL",
  serviceTypeLabel_EBS: "EBS",
  serviceTypeLabel_EC2: "EC2",
  serviceTypeLabel_EKS: "EKS",
  serviceTypeLabel_ELASTICACHE: "Elasticache",
  serviceTypeLabel_GCE: "GCE",
  serviceTypeLabel_GCS: "GCS",
  serviceTypeLabel_GKE: "GKE",
  serviceTypeLabel_MEMORY_DB: "Memory DB",
  serviceTypeLabel_OPEN_SEARCH: "Open Search",
  serviceTypeLabel_RDS: "RDS",
  serviceTypeLabel_REDSHIFT: "Redshift",
  serviceTypeLabel_S3: "S3",
  serviceTypeLabel_SAGEMAKER: "Sagemaker",
  settingsCustomSettingButtonLabel: "Custom Settings",
  settingsSideDrawerTitleCaptionCustomSetting: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Settings will be applied to all newly created business units. These can be changed individually at any time.",
    default: "Settings will be applied to all newly created tenants. These can be changed individually at any time."
  },
  settingsSideDrawerTitleCaptionDefaultSetting: "Changes will be applied to all selected Tenants. These are not applied until they have been submited.",
  settingsSideDrawerTitleCustomSetting: "Custom Settings",
  settingsSideDrawerTitleDefaultSetting: "Default Settings",
  settingsTabLabel: "Settings",
  showEmailsButtonLabel: "Show Emails",
  sideDrawerTitleCreate: "Create Rule Set",
  sideDrawerTitleEdit: "Edit Rule Set",
  statusVariantLabel_1: "Incomplete",
  statusVariantLabel_2: "Danger",
  statusVariantLabel_3: "Warning",
  statusVariantLabel_4: "Success",
  SUCCESS_RULE_SET_CREATED_message: "The rule set has been successfully created.",
  SUCCESS_RULE_SET_DELETED_message: "The rule set has been successfully deleted.",
  SUCCESS_RULE_SET_UPDATED_message: "The rule set has been successfully updated.",
  successUpdatingMspTenantSettingsMessage: "Settings have been successfully updated.",
  switchLabelAllowConfiguration: "Allow",
  switchLabelRate: "Rate",
  switchLabelUsage: "Usage",
  tabLabelAnomalies: "Anomalies",
  tabLabelClouds: "Integrations",
  tabLabelReporting: "Reporting",
  tabLabelSavings: "Savings",
  tabLabelUsers: "Users",
  tableDeltaExpandableRowTooltip: "This range shows the variation of Deltas within this group of alerts.",
  tableGroupingTooltipName: "Name",
  tableGroupingTooltipValue: "Value",
  tableHeaderAlertedAt: "Alerted At",
  tableHeaderAlertedAtTooltip: "Alerted At shows when Ternary last detected an anomaly. For grouped rows, click to expand and see the individual alert dates.",
  tableHeaderAws: "AWS",
  tableHeaderAzure: "Azure",
  tableHeaderBilledCost: "Billed Cost",
  tableHeaderConfiguration: "Configuration",
  tableHeaderCreatedAt: "Created At",
  tableHeaderCreatedBy: "Created By",
  tableHeaderDateRange: "Date Range",
  tableHeaderDelta: "Delta",
  tableHeaderDeltaTooltip: "The Delta represents the difference between the actual value and the expected range for a tenant.",
  tableHeaderEmail: "Email",
  tableHeaderGcp: "GCP",
  tableHeaderGroupings: "Groupings",
  tableHeaderIntegrations: "Integrations",
  tableHeaderLastLogin: "Last Login",
  tableHeaderLastModified: "Last Modified",
  tableHeaderLastRefresh: "Last Refresh",
  tableHeaderLastRefreshTooltip: "Last Refresh shows when Ternary last completed data ingestion.",
  tableHeaderLogins: "Total Logins",
  tableHeaderMomAbsolute: "MoM Delta $",
  tableHeaderMomPercent: "MoM Delta %",
  tableHeaderName: "Name",
  tableHeaderOptimizations: "Optimizations",
  tableHeaderPotentialSavingsMonthly: "Est. Monthly Savings",
  tableHeaderProviderType: "Provider",
  tableHeaderRules: "Rules",
  tableHeaderRuleSetName: "Rule Set Name",
  tableHeaderServiceType: "Service Type",
  tableHeaderStatus: "Status",
  tableHeaderStatusTooltip_1: " Incomplete indicates initial data ingestion has not completed",
  tableHeaderStatusTooltip_2: " Danger indicates data was not refreshed within the last 24 hours",
  tableHeaderStatusTooltip_3: " Warning indicates data was refreshed within the last 24 hours",
  tableHeaderStatusTooltip_4: " Success indicates data was refreshed within the last 8 hours",
  tableHeaderStatusTooltip: "Status indicates when Ternary last refreshed your data.",
  tableHeaderTenantID: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Business Unit ID",
    default: "Tenant ID"
  },
  tableHeaderTenantName: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Business Unit Name",
    default: "Tenant Name"
  },
  tableHeaderTenants: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Business Units",
    default: "Tenants",  
  },
  tableHeaderTerm: "Term",
  tableHeaderType: "Type",
  tableHeaderUserCost: "User Cost",
  tenantAccessModalMessage: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "You do not currently have access to this Business Unit. If you have permission from your administrator to access this business unit, clicking confirm will take you there.",
    default: "You do not currently have access to this Tenant. If you have permission from your administrator to access this tenant, clicking confirm will take you there.",
  },
  tenantAccessModalTitle: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Access Business Unit",
    default: "Access Tenant",
  },
  tenantsTabLabel: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Business Units",
    default: "Tenants",
  },
  unitYear: "Year",
  unitYears: "Years",
  updateChangesMspTenantsSettingModalMessage: "All changes will be applied based on your manual selections",
  updateMspTenantSettingModalMessage: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Changes will apply to all new business units. Existing business units won't be affected.",
    default: "Changes will apply to all new tenants. Existing tenants won't be affected.",
  },
  updateMspTenantsSettingModalMessage: {
    [FeatureFlag.MSP_PUBLIC_SECTOR]: "Changes will apply to all selected Business Units.",
    default: "Changes will apply to all selected Tenants.",
  },
  updateMspTenantsSettingModalTitle: "Are you sure?",
  usersTabLabel: "Users",
} as const;
