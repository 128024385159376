import { DateHelper } from "@/lib/dates";
import { createStructParam } from "@/lib/use-query-params";
import Dropdown from "@/ui-lib/components/Dropdown";
import Grid from "@/ui-lib/components/Grid";
import Modal from "@/ui-lib/components/Modal";
import { DateRange } from "@/utils/dates";
import { useDebounce } from "@/utils/timers";
import { useTheme } from "@emotion/react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import {
  ChartType,
  DataSource,
  DurationType,
  Operator,
  TimeGranularity,
  UnitType,
} from "@ternary/api-lib/analytics/enums";
import { awsComputeUtilizationSchema } from "@ternary/api-lib/analytics/schemas/awsComputeUtilization";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import AreaChart from "@ternary/api-lib/analytics/ui/AreaChart";
import StackedBarChart from "@ternary/api-lib/analytics/ui/StackedBarChart";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { differenceInHours, endOfDay } from "date-fns";
import { keyBy } from "lodash";
import React, { useMemo, useState } from "react";
import {
  ArrayParam,
  DateParam,
  DecodedValueMap,
  JsonParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { z } from "zod";
import useGetRawData from "../../../../api/analytics/useGetRawData";
import InsightsSelector from "../../../../components/InsightsSelector";
import useAvailableGlobalDate from "../../../../hooks/useAvailableGlobalDate";
import DateRangeControls from "../../../../ui-lib/components/DateRangeControls/DateRangeControls";
import SelectDropdown from "../../../../ui-lib/components/SelectDropdown";
import copyText from "../../copyText";
import useGetAWSComputeInstanceGroups from "../hooks/useGetAWSComputeInstanceGroups";
import useGetAWSComputeInstances from "../hooks/useGetAWSComputeInstances";
import useGetAWSComputeSpendSummaries from "../hooks/useGetAWSComputeSpendSummaries";
import {
  AWSComputeInstanceGroup,
  AWSComputeInstanceGroupFilters,
} from "../types";
import AWSComputeInstanceGroupTable from "./AWSComputeInstanceGroupTable";
import AWSComputeInstanceGroupTableControls from "./AWSComputeInstanceGroupTableControls";
import AWSComputeInstanceMeters from "./AWSComputeInstanceMeters";
import AWSComputeInstanceTable from "./AWSComputeInstanceTable";

const AWSComputeCostChartOption = {
  INSTANCE_TYPE: "INSTANCE_TYPE",
  USAGE_ACT: "USAGE_ACT",
  FAMILY: "FAMILY",
  REGION: "REGION",
} as const;

type AWSComputeCostChartOption =
  (typeof AWSComputeCostChartOption)[keyof typeof AWSComputeCostChartOption];

const AWSComputeUsageChartOption = {
  DISK: "DISK",
  MEMORY: "MEMORY",
  NETWORK: "NETWORK",
  VCPU: "VCPU",
} as const;

type AWSComputeUsageChartOption =
  (typeof AWSComputeUsageChartOption)[keyof typeof AWSComputeUsageChartOption];

type Interaction =
  | AWSComputeInstanceGroupTable.Interaction
  | AWSComputeInstanceGroupTableControls.Interaction;

type QueryParams = DecodedValueMap<typeof queryParamConfigMap>;

type QueryParamState = {
  dateRange: DateRange;
  dateRangeGranularity: TimeGranularity;
  duration: DurationType;
  instanceGroupFilters: z.infer<typeof instanceGroupFiltersStruct>;
  selectedCostChartOption: AWSComputeCostChartOption;
  selectedInstanceGroup: z.infer<typeof selectedInstanceGroupStruct> | null;
  selectedUsageChartOption: AWSComputeUsageChartOption;
  selectedUsageChartUtilization: string[];
};

const instanceGroupFiltersDefault = {
  family: null,
  familyType: null,
  lineItemUsageAccountId: null,
  operatingSystem: null,
  region: null,
};

const instanceGroupFiltersStruct = z.object({
  family: z.nullable(z.string()),
  familyType: z.nullable(z.string()),
  lineItemUsageAccountId: z.nullable(z.string()),
  operatingSystem: z.nullable(z.string()),
  region: z.nullable(z.string()),
});

const selectedInstanceGroupStruct = z.object({
  family: z.string(),
  familyType: z.string(),
  lineItemUsageAccountId: z.string(),
  operatingSystem: z.string(),
  region: z.string(),
});

const costChartOptionEnum = createEnumParam(
  Object.values(AWSComputeCostChartOption)
);
const durationEnum = createEnumParam(Object.values(DurationType));
const usageChartOptionEnum = createEnumParam(
  Object.values(AWSComputeUsageChartOption)
);

const queryParamConfigMap = {
  date_range_end: DateParam,
  date_range_start: DateParam,
  duration: withDefault(durationEnum, DurationType.LAST_THIRTY_DAYS),
  instance_table_sort: JsonParam,
  instance_group_filters: createStructParam(instanceGroupFiltersStruct),
  selected_cost_chart_option: withDefault(
    costChartOptionEnum,
    AWSComputeCostChartOption.INSTANCE_TYPE
  ),
  selected_group: createStructParam(selectedInstanceGroupStruct),
  selected_usage_chart_option: withDefault(
    usageChartOptionEnum,
    AWSComputeUsageChartOption.VCPU
  ),
  selected_usage_chart_utilization: ArrayParam,
};

export default function AWSComputeContainer() {
  const theme = useTheme();
  const globalDate = useAvailableGlobalDate();

  //
  // STATE
  //
  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);

  const queryParamState = getQueryParamState(queryParams);

  const [searchText, setSearchText] = useState("");

  const debouncedSearchText = useDebounce(searchText);

  //
  // QUERIES
  //

  const dateRange = globalDate.date ?? queryParamState.dateRange;

  const costDimensions = getCostDimensionsFromOption(
    queryParamState.selectedCostChartOption
  );

  const costMeasures = ["cost"];

  const usageMeasures = getUsageMeasuresFromOption(
    queryParamState.selectedUsageChartOption,
    queryParamState.dateRangeGranularity
  );

  const utilizationMeasures = getUtilizationMeasuresFromOption(
    queryParamState.selectedUsageChartOption,
    queryParamState.selectedUsageChartUtilization
  );

  const { data: costChartData = [], isFetching: isLoadingCostChartData } =
    useGetRawData({
      dataSource: DataSource.AWS_COMPUTE_VISIBILITY,
      dateRange,
      dimensions: costDimensions,
      granularity: queryParamState.dateRangeGranularity,
      measures: costMeasures,
    });

  const AWSComputeSpendSummaries = useGetAWSComputeSpendSummaries({});

  const [{ data: currentMTD }, { data: lastMonthFull }, { data: lastMTD }] =
    AWSComputeSpendSummaries;

  const isLoadingSpendSummaries = AWSComputeSpendSummaries.some(
    (summary) => summary.isFetching
  );

  const { data: usageChartData = [], isFetching: isLoadingUsageChartData } =
    useGetRawData({
      dataSource: DataSource.AWS_COMPUTE_VISIBILITY,
      dateRange,
      granularity: queryParamState.dateRangeGranularity,
      measures: usageMeasures,
    });

  const {
    data: utilizationChartData = [],
    isFetching: isLoadingUtilizationChartData,
  } = useGetRawData(
    {
      dataSource: DataSource.AWS_COMPUTE_UTILIZATION,
      dateRange: queryParamState.dateRange,
      granularity: queryParamState.dateRangeGranularity,
      measures: utilizationMeasures,
    },
    { enabled: utilizationMeasures.length > 0 }
  );

  const { data: instanceGroups, isFetching: isLoadingInstanceGroups } =
    useGetAWSComputeInstanceGroups({
      dateRange,
    });

  const { data: instances = [], isFetching: isLoadingInstances } =
    useGetAWSComputeInstances(
      {
        dateRange,
        queryFilters: queryParamState.selectedInstanceGroup
          ? getInstanceGroupQueryFilters(queryParamState.selectedInstanceGroup)
          : [],
      },
      { enabled: Boolean(queryParamState.selectedInstanceGroup) }
    );

  //
  // MODIFIED QUERY DATA
  //

  const filteredInstanceGroups = useMemo(() => {
    return getFilteredInstanceGroups({
      allInstanceGroups: instanceGroups ?? [],
      instanceGroupFilters: queryParamState.instanceGroupFilters,
      searchText: debouncedSearchText,
    });
  }, [
    debouncedSearchText,
    queryParamState.instanceGroupFilters,
    instanceGroups,
  ]);

  const csvData = useMemo(
    () => getCSVData(filteredInstanceGroups),
    [filteredInstanceGroups]
  );

  const usageDataWithUtilization = useMemo(() => {
    if (isLoadingUsageChartData || isLoadingUtilizationChartData) return [];
    if (utilizationMeasures.length === 0) return usageChartData;
    const utilizationChartDataKeyedByTimestamp = keyBy(
      utilizationChartData,
      "timestamp"
    );
    return usageChartData.map((datum) => {
      const utilizationDatum =
        utilizationChartDataKeyedByTimestamp[String(datum.timestamp)];
      return {
        ...utilizationDatum,
        ...datum,
      };
    });
  }, [
    usageChartData,
    isLoadingUsageChartData,
    utilizationChartData,
    isLoadingUtilizationChartData,
    usageMeasures.length > 0,
    utilizationMeasures.length > 0,
  ]);

  //
  // INTERACTIONS
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case AWSComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.instanceGroupFilters };

        nextFilters[interaction.filterKey] = interaction.filterValue;

        setQueryParams({ instance_group_filters: nextFilters });
        break;
      }
      case AWSComputeInstanceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.instanceGroupFilters };

        nextFilters[interaction.filterKey] = null;

        if (Object.values(nextFilters).every((value) => value === null)) {
          setQueryParams({ instance_group_filters: null });
        } else {
          setQueryParams({ instance_group_filters: nextFilters });
        }
        break;
      }
      case AWSComputeInstanceGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED: {
        setSearchText(interaction.searchText);
        break;
      }
    }
  }

  const costChartReportSnapshot = {
    chartType: ChartType.STACKED_BAR,
    dateRange:
      queryParamState.duration === DurationType.CUSTOM || globalDate
        ? queryParamState.dateRange
        : null,
    dataSource: DataSource.AWS_COMPUTE_VISIBILITY,
    dimensions: costDimensions,
    durationType: queryParamState.duration,
    isFiscalMode: false,
    fiscalPeriodMap: null,
    granularity: queryParamState.dateRangeGranularity,
    measures: costMeasures,
    queryFilters: [
      {
        name: costDimensions[0],
        operator: Operator.NOT_EQUALS,
        values: ["null"],
      },
    ],
    name: copyText.awsComputeCostReportSnapshotName,
    xAxisKey: "timestamp",
  };

  const usageChartReportSnapshot = {
    chartType: ChartType.AREA,
    dateRange:
      queryParamState.duration === DurationType.CUSTOM || globalDate
        ? queryParamState.dateRange
        : null,
    dataSource: DataSource.AWS_COMPUTE_VISIBILITY,
    dimensions: [],
    durationType: queryParamState.duration,
    isFiscalMode: false,
    fiscalPeriodMap: null,
    granularity: queryParamState.dateRangeGranularity,
    measures: [...usageMeasures, ...utilizationMeasures],
    name: copyText.awsComputeUsageReportSnapshotName,
    xAxisKey: "timestamp",
  };

  return (
    <Box width="100%" paddingTop={theme.space_md}>
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <AWSComputeInstanceMeters
          isLoading={isLoadingSpendSummaries}
          lastMonthSpend={lastMonthFull?.totalCost ?? 0}
          lastMTDSpend={lastMTD?.totalCost ?? 0}
          thisMTDSpend={currentMTD?.totalCost ?? 0}
        />
      </Box>
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        justifyContent="flex-end"
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <DateRangeControls
          dateRange={dateRange}
          durationType={queryParamState.duration}
          hiddenOptions={[
            DurationType.LAST_NINETY_DAYS,
            DurationType.QUARTER_TO_DATE,
            DurationType.YEAR_TO_DATE,
          ]}
          maxDate={new DateHelper().date}
          onChangeDateRange={(duration, newDateRange) => {
            setQueryParams({
              duration,
              ...(newDateRange && newDateRange[0] && newDateRange[1]
                ? {
                    date_range_start: newDateRange[0],
                    date_range_end: newDateRange[1],
                  }
                : {
                    date_range_start: null,
                    date_range_end: null,
                  }),
            });
          }}
        />
      </Flex>

      <Grid
        gridColumnGap={theme.space_lg}
        gridTemplateColumns={`repeat(2, calc(50% - (${theme.space_lg} / 2) ))`}
      >
        <InsightsSelector
          resourceName={copyText.awsComputeCostReportSnapshotName}
          reportSnapshot={costChartReportSnapshot}
        >
          <Flex
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            direction="column"
            height={500}
            padding={theme.space_md}
          >
            <Flex justifyContent="space-between" paddingBottom={theme.space_md}>
              <Text fontSize={theme.h3_fontSize}>
                {copyText.awsComputeOptimizationsChartTitleCost}
              </Text>

              {/* COST DROPDOWN */}
              <Dropdown
                options={costChartOptions.map((option) => ({
                  ...option,
                  onClick: () =>
                    setQueryParams({
                      selected_cost_chart_option: option.value,
                    }),
                }))}
                placement="bottom-end"
                selectedOption={
                  costChartOptions.find(
                    (option) =>
                      option.value === queryParamState.selectedCostChartOption
                  ) ?? costChartOptions[0]
                }
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  secondary
                  size="small"
                  width={140}
                >
                  {getOptionLabel(queryParamState.selectedCostChartOption)}
                </Button>
              </Dropdown>
            </Flex>

            {/* COST CHART */}
            <Box flex="1 0 0">
              <StackedBarChart
                data={costChartData}
                dimensions={costDimensions.map((dimension) => ({
                  name: dimension,
                  isDate: false,
                }))}
                isLoading={isLoadingCostChartData}
                maxGroupings={7}
                measures={costMeasures.map(getMeasureWithUnit)}
                showLegend
                showTooltip
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                xAxisKey="timestamp"
              />
            </Box>
          </Flex>
        </InsightsSelector>
        <InsightsSelector
          resourceName={copyText.awsComputeUsageReportSnapshotName}
          reportSnapshot={usageChartReportSnapshot}
        >
          <Flex
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            direction="column"
            height={500}
            padding={theme.space_md}
          >
            <Flex justifyContent="space-between" paddingBottom={theme.space_md}>
              <Text fontSize={theme.h3_fontSize}>
                {copyText.awsComputeOptimizationsChartTitleUsage}
              </Text>

              <Box flex="0 1 100%" />

              {/* UTIL DROPDOWN */}
              <SelectDropdown
                isMulti
                closeOnSubmit
                options={utilizationMeasureTypes.map((value) => ({
                  value,
                  label: value,
                }))}
                disabled={
                  queryParamState.selectedUsageChartOption ===
                  AWSComputeUsageChartOption.NETWORK
                }
                selectedValues={queryParamState.selectedUsageChartUtilization}
                onChange={(value) => {
                  setQueryParams({
                    selected_usage_chart_utilization: value.length
                      ? value
                      : null,
                  });
                }}
              >
                <Button
                  disabled={
                    queryParamState.selectedUsageChartOption ===
                    AWSComputeUsageChartOption.NETWORK
                  }
                  iconEnd={<Icon icon={faChevronDown} />}
                  marginHorizontal={theme.space_md}
                  secondary
                  size="small"
                  width={140}
                >
                  {copyText.awsComputeOptimizationsChartUtilization}
                </Button>
              </SelectDropdown>

              {/* USAGE DROPDOWN */}
              <Dropdown
                options={usageChartOptions.map((option) => ({
                  ...option,
                  onClick: () =>
                    setQueryParams({
                      selected_usage_chart_option: option.value,
                    }),
                }))}
                placement="bottom-end"
                selectedOption={
                  usageChartOptions.find(
                    (option) =>
                      option.value === queryParamState.selectedUsageChartOption
                  ) ?? usageChartOptions[0]
                }
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  secondary
                  size="small"
                  width={140}
                >
                  {getOptionLabel(queryParamState.selectedUsageChartOption)}
                </Button>
              </Dropdown>
            </Flex>

            {/* USAGE CHART */}
            <Box flex="1 0 0">
              <AreaChart
                data={usageDataWithUtilization}
                hideTotal
                isLoading={
                  isLoadingUsageChartData || isLoadingUtilizationChartData
                }
                measures={[...usageMeasures, ...utilizationMeasures].map(
                  getMeasureWithUnit
                )}
                readableKeys={readableMeasures}
                showLegend
                showTooltip
                stacked
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                xAxisKey="timestamp"
              />
            </Box>
          </Flex>
        </InsightsSelector>
      </Grid>

      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginVertical={theme.space_lg}
        padding={theme.space_md}
      >
        <AWSComputeInstanceGroupTableControls
          csvData={csvData}
          debouncedSearchText={debouncedSearchText}
          instanceGroupFilters={queryParamState.instanceGroupFilters}
          searchText={searchText}
          onInteraction={handleInteraction}
        />
      </Box>

      <Box width="100%" overflowX="auto">
        <AWSComputeInstanceGroupTable
          instanceGroups={filteredInstanceGroups}
          isLoadingInstanceGroups={isLoadingInstanceGroups}
          onInteraction={handleInteraction}
        />
      </Box>

      {queryParamState.selectedInstanceGroup !== null && (
        <Modal
          isOpen
          showCloseButton
          onClose={() =>
            setQueryParams({
              instance_table_sort: null,
              selected_group: null,
            })
          }
          minWidth={1100}
        >
          <Modal.Header>
            <Flex
              justifyContent="space-between"
              marginLeft={theme.space_sm}
              width="100%"
            >
              <Text fontSize={theme.h4_fontSize}>{"Instances"}</Text>
            </Flex>
          </Modal.Header>
          <Modal.Body>
            <AWSComputeInstanceTable
              instances={instances}
              isLoadingInstances={isLoadingInstances}
            />
          </Modal.Body>
        </Modal>
      )}
    </Box>
  );
}

// COST OPTIONS
const costChartOptions = [
  AWSComputeCostChartOption.INSTANCE_TYPE,
  AWSComputeCostChartOption.USAGE_ACT,
  AWSComputeCostChartOption.FAMILY,
  AWSComputeCostChartOption.REGION,
].map((costOption) => ({
  label: getOptionLabel(costOption),
  value: costOption,
}));

// USAGE OPTIONS
const usageChartOptions = [
  AWSComputeUsageChartOption.VCPU,
  AWSComputeUsageChartOption.MEMORY,
  AWSComputeUsageChartOption.NETWORK,
  AWSComputeUsageChartOption.DISK,
].map((usageOption) => ({
  label: getOptionLabel(usageOption),
  value: usageOption,
}));

function getCostDimensionsFromOption(option: AWSComputeCostChartOption) {
  switch (option) {
    case AWSComputeCostChartOption.FAMILY:
      return ["family"];
    case AWSComputeCostChartOption.REGION:
      return ["region"];
    case AWSComputeCostChartOption.INSTANCE_TYPE:
      return ["instanceType"];
    case AWSComputeCostChartOption.USAGE_ACT:
      return ["lineItemUsageAccountId"];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

const measuresWithUnit: { name: string; unit: UnitType }[] = [
  { name: "cost", unit: UnitType.CURRENCY },
  { name: "productVCPU", unit: UnitType.STANDARD },
  { name: "productVCPUChart", unit: UnitType.BYTES },
  { name: "productMemoryBytes", unit: UnitType.BYTES },
  { name: "productMemoryBytesChart", unit: UnitType.BYTES },
  { name: "diskReadBytes", unit: UnitType.BYTES },
  { name: "diskWriteBytes", unit: UnitType.BYTES },
  { name: "networkInBytes", unit: UnitType.BYTES },
  { name: "networkOutBytes", unit: UnitType.BYTES },
];

function getMeasureWithUnit(measure: string) {
  return (
    [...measuresWithUnit, ...awsComputeUtilizationSchema.measures].find(
      (other) => other.name === measure
    ) ?? {
      name: measure,
      unit: UnitType.STANDARD,
    }
  );
}

const csvAccessors = [
  "family",
  "region",
  "lineItemUsageAccountId",
  "operatingSystem",
  "familyType",
  "instanceCount",
  "totalCost",
  "productVCPU",
  "diskReadBytes",
  "diskWriteBytes",
  "productMemoryBytes",
  "networkInBytes",
  "networkOutBytes",
] as const;

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

function getCSVData(groups: AWSComputeInstanceGroup[]): CSVData {
  if (!groups.length) {
    return { headers: [], rows: [] };
  }

  const rows = groups.map((group) => ({
    diskReadBytes: group.diskReadBytes,
    diskWriteBytes: group.diskWriteBytes,
    family: group.family,
    familyType: group.familyType,
    instanceCount: group.instanceIDCount,
    lineItemUsageAccountId: group.lineItemUsageAccountId,
    networkInBytes: group.networkInBytes,
    networkOutBytes: group.networkOutBytes,
    operatingSystem: group.operatingSystem,
    productMemoryBytes: group.productMemoryBytes,
    productVCPU: group.productVCPU,
    region: group.region,
    totalCost: group.totalCost,
  }));

  const headers = csvAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `awsComputeTableInstanceGroupHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}

type GetFilteredInstanceGroupsParams = {
  allInstanceGroups: AWSComputeInstanceGroup[];
  instanceGroupFilters: AWSComputeInstanceGroupFilters;
  searchText: string | null;
};

function getFilteredInstanceGroups(
  params: GetFilteredInstanceGroupsParams
): AWSComputeInstanceGroup[] {
  const searchText = (params.searchText ?? "").toLowerCase().trim();

  return params.allInstanceGroups.filter((instanceGroup) => {
    if (
      !isInstanceGroupPassingFilters(instanceGroup, params.instanceGroupFilters)
    ) {
      return false;
    }

    if (!isSearchTextInInstanceGroup(instanceGroup, searchText)) {
      return false;
    }

    return true;
  });
}

function getInstanceGroupQueryFilters(
  selectedInstanceGroup: z.infer<typeof selectedInstanceGroupStruct>
): QueryFilter[] {
  const groupDimnesionKeys = [
    "family",
    "familyType",
    "lineItemUsageAccountId",
    "operatingSystem",
    "region",
  ] as const;

  const andFilter = groupDimnesionKeys.map(
    (key): QueryFilter =>
      selectedInstanceGroup[key] === ""
        ? {
            name: key,
            operator: Operator.NOT_SET,
          }
        : {
            name: key,
            operator: Operator.EQUALS,
            values: [selectedInstanceGroup[key]],
          }
  );

  return [{ and: andFilter }];
}

function getOptionLabel(
  option: AWSComputeCostChartOption | AWSComputeUsageChartOption
) {
  return copyText[`awsComputeChartOptionLabel_${option}`];
}

function getQueryParamState(queryParams: QueryParams): QueryParamState {
  const dateRange =
    queryParams.date_range_start && queryParams.date_range_end
      ? [queryParams.date_range_start, queryParams.date_range_end]
      : getCubeDateRangeFromDurationType(queryParams.duration);

  const dateRangeDurationInHours =
    dateRange[0] && dateRange[1]
      ? differenceInHours(endOfDay(dateRange[1]), dateRange[0])
      : 0;

  const isSmallDateRange =
    dateRangeDurationInHours > 0 && dateRangeDurationInHours <= 48;

  const dateRangeGranularity = isSmallDateRange
    ? TimeGranularity.HOUR
    : TimeGranularity.DAY;

  const selectedUsageChartUtilization: string[] = [];

  (queryParams.selected_usage_chart_utilization ?? []).forEach((value) => {
    if (!value || !utilizationMeasureTypes.includes(value)) return;
    selectedUsageChartUtilization.push(value);
  });

  return {
    dateRange,
    dateRangeGranularity,
    duration: queryParams.duration,
    instanceGroupFilters:
      queryParams.instance_group_filters ?? instanceGroupFiltersDefault,
    selectedCostChartOption: queryParams.selected_cost_chart_option,
    selectedInstanceGroup: queryParams.selected_group ?? null,
    selectedUsageChartOption: queryParams.selected_usage_chart_option,
    selectedUsageChartUtilization,
  };
}

type UtilizationMeasureResources =
  | "CPUUtilization"
  | "DiskUtilization"
  | "GPUUtilization"
  | "MemoryUtilization";

const utilizationMeasureTypes = [
  "maxOfAverage",
  "minOfAverage",
  "p50OfMax",
  "p95OfMax",
  "p99OfMax",
];

const readableMeasures = {
  productVCPUChart: "productVCPU",
  productMemoryBytesChart: "productMemoryBytes",
};

function getUsageMeasuresFromOption(
  option: AWSComputeUsageChartOption,
  granularity: TimeGranularity
) {
  switch (option) {
    case AWSComputeUsageChartOption.VCPU: {
      return granularity === TimeGranularity.HOUR
        ? ["productVCPU"]
        : ["productVCPUChart"];
    }
    case AWSComputeUsageChartOption.MEMORY: {
      return granularity === TimeGranularity.HOUR
        ? ["productMemoryBytes"]
        : ["productMemoryBytesChart"];
    }
    case AWSComputeUsageChartOption.NETWORK: {
      return ["networkInBytes", "networkOutBytes"];
    }
    case AWSComputeUsageChartOption.DISK: {
      return ["diskReadBytes", "diskWriteBytes"];
    }
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getUtilizationMeasuresFromOption(
  usageOption: AWSComputeUsageChartOption,
  measuresTypes: string[]
) {
  let utilizationMeasureResource: UtilizationMeasureResources;

  switch (usageOption) {
    case AWSComputeUsageChartOption.VCPU: {
      utilizationMeasureResource = "CPUUtilization";
      break;
    }
    case AWSComputeUsageChartOption.MEMORY: {
      utilizationMeasureResource = "MemoryUtilization";
      break;
    }
    case AWSComputeUsageChartOption.NETWORK: {
      return [];
    }
    case AWSComputeUsageChartOption.DISK: {
      utilizationMeasureResource = "DiskUtilization";
      break;
    }
    default: {
      const _exhaustiveCheck: never = usageOption;
      return _exhaustiveCheck;
    }
  }

  return measuresTypes.map((measureType) => {
    const measureName = `${measureType}${utilizationMeasureResource}`;

    return (
      awsComputeUtilizationSchema.measures.find(
        (measure) => measure.name.toLowerCase() === measureName.toLowerCase()
      )?.name ?? measureName
    );
  });
}

function isInstanceGroupPassingFilters(
  instanceGroup: AWSComputeInstanceGroup,
  filters: AWSComputeInstanceGroupFilters
): boolean {
  if (
    filters.family !== null &&
    instanceGroup.family.toLowerCase().trim() !==
      filters.family.toLowerCase().trim()
  ) {
    return false;
  }
  if (
    filters.familyType !== null &&
    instanceGroup.familyType.toLowerCase().trim() !==
      filters.familyType.toLowerCase().trim()
  ) {
    return false;
  }
  if (
    filters.lineItemUsageAccountId !== null &&
    instanceGroup.lineItemUsageAccountId.toLowerCase().trim() !==
      filters.lineItemUsageAccountId.toLowerCase().trim()
  ) {
    return false;
  }
  if (
    filters.operatingSystem !== null &&
    instanceGroup.operatingSystem.toLowerCase().trim() !==
      filters.operatingSystem.toLowerCase().trim()
  ) {
    return false;
  }
  if (
    filters.region !== null &&
    instanceGroup.region.toLowerCase().trim() !==
      filters.region.toLowerCase().trim()
  ) {
    return false;
  }

  return true;
}

function isSearchTextInInstanceGroup(
  instanceGroup: AWSComputeInstanceGroup,
  searchText: string
): boolean {
  if (searchText === "") return true;

  const values = [
    instanceGroup.family,
    instanceGroup.familyType,
    instanceGroup.lineItemUsageAccountId,
    instanceGroup.operatingSystem,
    instanceGroup.region,
  ].map((value) => (value === "" ? "null" : value));

  return values.some((value) =>
    value.toLowerCase().trim().includes(searchText)
  );
}
