import { createStructParam } from "@/lib/use-query-params";
import { useCSVDownloader } from "@/ui-lib/components/CSVDownloader";
import DateRangeControls from "@/ui-lib/components/DateRangeControls/DateRangeControls";
import Dropdown from "@/ui-lib/components/Dropdown";
import Grid from "@/ui-lib/components/Grid";
import Modal from "@/ui-lib/components/Modal";
import { DateRange } from "@/utils/dates";
import { useDebounce } from "@/utils/timers";
import { useTheme } from "@emotion/react";
import { faChevronDown, faFileExport } from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import {
  DataSource,
  DurationType,
  Operator,
  TimeGranularity,
  UnitType,
} from "@ternary/api-lib/analytics/enums";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import AreaChart from "@ternary/api-lib/analytics/ui/AreaChart";
import StackedBarChart from "@ternary/api-lib/analytics/ui/StackedBarChart";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import { formatNumber } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { differenceInHours, endOfDay, format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import {
  DateParam,
  DecodedValueMap,
  JsonParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { z } from "zod";
import useGetRawData from "../../../../api/analytics/useGetRawData";
import useAvailableGlobalDate from "../../../../hooks/useAvailableGlobalDate";
import copyText from "../../copyText";
import useGetDatabaseSpendSummaries from "../../hooks/useGetDatabaseSpendSummaries";
import useGetAzureDatabaseInstanceGroups from "../hooks/useGetAzureDatabaseInstanceGroups";
import useGetAzureDatabaseInstances from "../hooks/useGetAzureDatabaseInstances";
import useGetAzureDatabaseUsageSummary from "../hooks/useGetAzureDatabaseUsageSummary";
import {
  AzureDatabaseInstanceEntity,
  AzureDatabaseInstanceGroupEntity,
  AzureDatabaseInstanceGroupFilters,
  azureDatabaseSQLDimensions,
  azureDatabaseSQLMeasures,
} from "../types";
import { default as AzureDatabaseInstanceGroupTable } from "./AzureDatabaseInstanceGroupTable";
import AzureDatabaseInstanceGroupTableControls from "./AzureDatabaseInstanceGroupTableControls";
import AzureDatabaseInstanceTable from "./AzureDatabaseInstanceTable";
import AzureDatabaseMeters from "./AzureDatabaseMeters";

type Interaction =
  | AzureDatabaseInstanceGroupTable.Interaction
  | AzureDatabaseInstanceGroupTableControls.Interaction;

const AzureDatabaseCostChartOption = {
  DATABASE_TYPE: "DATABASE_TYPE",
  INSTANCE: "INSTANCE",
  PROJECT_ID: "PROJECT_ID",
  REGION: "REGION",
  SKU: "SKU",
  BILLING_METHOD: "BILLING_METHOD",
  COMPUTE_TIER: "COMPUTE_TIER",
} as const;

type AzureDatabaseCostChartOption =
  (typeof AzureDatabaseCostChartOption)[keyof typeof AzureDatabaseCostChartOption];

const AzureDatabaseUsageChartOption = {
  CONNECTIONS: "CONNECTIONS",
  CPU: "CPU",
  DISK: "DISK",
  MEMORY: "MEMORY",
} as const;

type AzureDatabaseUsageChartOption =
  (typeof AzureDatabaseUsageChartOption)[keyof typeof AzureDatabaseUsageChartOption];
const costGroupingEnum = createEnumParam(
  Object.values(AzureDatabaseCostChartOption)
);
const usageChartOptionEnum = createEnumParam(
  Object.values(AzureDatabaseUsageChartOption)
);
const durationEnum = createEnumParam(Object.values(DurationType));

type QueryParams = DecodedValueMap<typeof queryParamConfigMap>;

type QueryParamState = {
  dateRange: DateRange;
  dateRangeGranularity: TimeGranularity;
  duration: DurationType;
  instanceGroupFilters: z.infer<typeof instanceGroupFiltersStruct>;
  selectedCostChartOption: AzureDatabaseCostChartOption;
  selectedInstanceGroup: z.infer<typeof selectedInstanceGroupStruct> | null;
  selectedUsageChartOption: AzureDatabaseUsageChartOption;
};

const instanceGroupFiltersDefault = {
  projectId: null,
  databaseEngine: null,
  location: null,
};

const instanceGroupFiltersStruct = z.object({
  projectId: z.nullable(z.string()),
  databaseEngine: z.nullable(z.string()),
  location: z.nullable(z.string()),
});

const selectedInstanceGroupStruct = z.object({
  projectId: z.string(),
  databaseEngine: z.string(),
  location: z.string(),
});

const queryParamConfigMap = {
  cost_grouping: withDefault(
    costGroupingEnum,
    AzureDatabaseCostChartOption.DATABASE_TYPE
  ),
  date_range_end: DateParam,
  date_range_start: DateParam,
  duration: withDefault(durationEnum, DurationType.LAST_THIRTY_DAYS),
  filters: withDefault(JsonParam, []),
  instance_table_sort: JsonParam,
  instance_group_filters: createStructParam(instanceGroupFiltersStruct),
  selected_group: createStructParam(selectedInstanceGroupStruct),
  usage_grouping: withDefault(
    usageChartOptionEnum,
    AzureDatabaseUsageChartOption.CPU
  ),
};

const costGroupingLabel = {
  [AzureDatabaseCostChartOption.DATABASE_TYPE]:
    copyText.azureDatabaseCostChartOptionLabel_databaseType,
  [AzureDatabaseCostChartOption.INSTANCE]:
    copyText.azureDatabaseCostChartOptionLabel_instance,
  [AzureDatabaseCostChartOption.PROJECT_ID]:
    copyText.azureDatabaseCostChartOptionLabel_projectId,
  [AzureDatabaseCostChartOption.SKU]:
    copyText.azureDatabaseCostChartOptionLabel_sku_teir,
  [AzureDatabaseCostChartOption.REGION]:
    copyText.azureDatabaseCostChartOptionLabel_region,
  [AzureDatabaseCostChartOption.COMPUTE_TIER]:
    copyText.azureDatabaseCostChartOptionLabel_computeTier,
  [AzureDatabaseCostChartOption.BILLING_METHOD]:
    copyText.azureDatabaseCostChartOptionLabel_billingMethod,
};

const usageGroupingLabel = {
  [AzureDatabaseUsageChartOption.CONNECTIONS]:
    copyText.azureDatabaseUsageChartOptionLabel_connections,
  [AzureDatabaseUsageChartOption.CPU]:
    copyText.azureDatabaseUsageChartOptionLabel_cpu,
  [AzureDatabaseUsageChartOption.DISK]:
    copyText.azureDatabaseUsageChartOptionLabel_disk,
  [AzureDatabaseUsageChartOption.MEMORY]:
    copyText.azureDatabaseUsageChartOptionLabel_memory,
};

const today = new Date();

export default function AzureDatabaseVisibilityContainer(): JSX.Element {
  const theme = useTheme();
  const globalDate = useAvailableGlobalDate();
  const queryClient = useQueryClient();

  //
  // STATE
  //

  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);

  const [searchText, setSearchText] = useState("");

  const debouncedSearchText = useDebounce(searchText);

  const queryParamState = getQueryParamState(queryParams);

  const { downloadCSV, csvElement } = useCSVDownloader();

  //
  // QUERIES
  //

  const dateRange = globalDate.date ?? queryParamState.dateRange;

  const spendSummaries = useGetDatabaseSpendSummaries({
    dataSource: DataSource.AZURE_SQL_VISIBILITY,
  });

  const [{ data: currentMTD }, { data: lastMonthFull }, { data: lastMTD }] =
    spendSummaries;

  const isLoadingSpendSummaries = spendSummaries.some(
    (summary) => summary.isFetching
  );

  const costDimensions = getCostDimensionsFromOption(
    queryParamState.selectedCostChartOption
  );

  const costMeasures = [azureDatabaseSQLMeasures.cost];

  const { data: latestMemoryUsage, isFetching: isLoadingLatestMemoryUsage } =
    useGetAzureDatabaseUsageSummary();

  const { data: costData = [], isFetching: isLoadingCostData } = useGetRawData({
    dataSource: DataSource.AZURE_SQL_VISIBILITY,
    dateRange,
    dimensions: costDimensions,
    granularity: queryParamState.dateRangeGranularity,
    measures: costMeasures,
  });

  const usageMeasures = getUsageMeasuresFromOption(
    queryParamState.selectedUsageChartOption
  );

  const { data: usageData = [], isFetching: isLoadingUsageData } =
    useGetRawData({
      dataSource: DataSource.AZURE_SQL_VISIBILITY,
      dateRange,
      granularity: queryParamState.dateRangeGranularity,
      measures: usageMeasures,
    });

  const { data: instanceGroups, isFetching: isLoadingInstanceGroups } =
    useGetAzureDatabaseInstanceGroups({
      dateRange,
    });

  const { data: instances = [], isFetching: isLoadingInstances } =
    useGetAzureDatabaseInstances(
      {
        dateRange: queryParamState.dateRange,
        queryFilters: queryParamState.selectedInstanceGroup
          ? getInstanceGroupQueryFilters(queryParamState.selectedInstanceGroup)
          : [],
      },
      { enabled: queryParamState.selectedInstanceGroup !== null }
    );

  const {
    data: allInstances = [],
    isFetching: isLoadingAllInstances,
    refetch: fetchAllInstances,
  } = useGetAzureDatabaseInstances(
    {
      dateRange: queryParamState.dateRange,
      queryFilters: [],
    },
    { enabled: false, refetchInterval: false }
  );

  function clearAllInstancesQuery() {
    queryClient.removeQueries({ queryKey: ["azureDatabaseInstancesCSV"] });
  }

  //
  // EFFECTS
  //

  useEffect(() => {
    if (allInstances.length === 0) {
      return;
    }

    const allInstancesCSVData = getInstanceCSVData(allInstances);

    downloadCSV({
      data: allInstancesCSVData.rows,
      fileName: `azure-all-database-instances-${format(
        new Date(),
        "MM-dd-yyyy"
      )}`,
      headers: allInstancesCSVData.headers,
    });

    // clear allInstances query from the cache.
    clearAllInstancesQuery();
  }, [allInstances]);

  //
  // MODIFIED QUERY DATA
  //

  const filteredInstanceGroups = useMemo(() => {
    return getFilteredInstanceGroups({
      allInstanceGroups: instanceGroups ?? [],
      instanceGroupFilters: queryParamState.instanceGroupFilters,
      searchText: debouncedSearchText,
    });
  }, [
    debouncedSearchText,
    instanceGroups,
    queryParamState.instanceGroupFilters,
  ]);

  const instanceGroupCSVData = useMemo(
    () => getInstanceGroupCSVData(filteredInstanceGroups),
    [filteredInstanceGroups]
  );

  const instanceCSVData = useMemo(
    () => getInstanceCSVData(instances),
    [instances]
  );

  //
  // INTERACTIONS
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case AzureDatabaseInstanceGroupTable.INTERACTION_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.instanceGroupFilters };

        nextFilters[interaction.filterKey] = interaction.filterValue;

        setQueryParams({ instance_group_filters: nextFilters });
        break;
      }
      case AzureDatabaseInstanceGroupTableControls.INTERACTION_DOWNLOAD_INSTANCES_CLICKED: {
        fetchAllInstances();
        break;
      }
      case AzureDatabaseInstanceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.instanceGroupFilters };

        nextFilters[interaction.filterKey] = null;

        setQueryParams({ instance_group_filters: nextFilters });
        break;
      }
      case AzureDatabaseInstanceGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED: {
        setSearchText(interaction.searchText);
        break;
      }
    }
  }

  //
  // RENDER
  //

  const costGroupingOptions = [
    AzureDatabaseCostChartOption.DATABASE_TYPE,
    AzureDatabaseCostChartOption.INSTANCE,
    AzureDatabaseCostChartOption.PROJECT_ID,
    AzureDatabaseCostChartOption.REGION,
    AzureDatabaseCostChartOption.SKU,
    AzureDatabaseCostChartOption.COMPUTE_TIER,
    AzureDatabaseCostChartOption.BILLING_METHOD,
  ].map((costGrouping) => ({
    label: costGroupingLabel[costGrouping],
    value: costGrouping,
    onClick: () => setQueryParams({ cost_grouping: costGrouping }),
  }));

  const defaultCostGroupingOption =
    costGroupingOptions.find(
      (option) => option.value === queryParams.cost_grouping
    ) ?? costGroupingOptions[0];

  const usageGroupingOptions = [
    AzureDatabaseUsageChartOption.CONNECTIONS,
    AzureDatabaseUsageChartOption.CPU,
    AzureDatabaseUsageChartOption.DISK,
    AzureDatabaseUsageChartOption.MEMORY,
  ].map((usageGrouping) => ({
    label: usageGroupingLabel[usageGrouping],
    value: usageGrouping,
    onClick: () => setQueryParams({ usage_grouping: usageGrouping }),
  }));

  const defaultUsageGroupingOption =
    usageGroupingOptions.find(
      (option) => option.value === queryParams.usage_grouping
    ) ?? usageGroupingOptions[0];

  function formatUsageMeasure(value: number | string) {
    value = Number(value);

    if (
      queryParamState.selectedUsageChartOption ===
      AzureDatabaseUsageChartOption.MEMORY
    ) {
      return `${formatNumber(value)} GB`;
    }

    return formatNumber(value);
  }

  return (
    <Box>
      <AzureDatabaseMeters
        isLoadingSpendSummaries={
          isLoadingSpendSummaries || isLoadingLatestMemoryUsage
        }
        lastMonthSpend={lastMonthFull?.totalCost ?? 0}
        lastMTDSpend={lastMTD?.totalCost ?? 0}
        mtdSpend={currentMTD?.totalCost ?? 0}
        provisionedMemoryBytes={latestMemoryUsage?.memoryGB ?? 0}
        usedMemoryBytes={latestMemoryUsage?.memoryBytesUsage ?? 0}
      />
      <Flex
        justifyContent="flex-end"
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginVertical={theme.space_lg}
        padding={theme.space_md}
      >
        <DateRangeControls
          dateRange={dateRange}
          durationType={queryParams.duration}
          hiddenOptions={[
            DurationType.LAST_NINETY_DAYS,
            DurationType.QUARTER_TO_DATE,
            DurationType.YEAR_TO_DATE,
          ]}
          maxDate={today}
          onChangeDateRange={(durationType, dateRange) => {
            setQueryParams({
              duration: durationType,
              date_range_start: dateRange?.[0] ?? null,
              date_range_end: dateRange?.[1] ?? null,
            });
          }}
        />
      </Flex>
      <Grid
        gridColumnGap={theme.space_lg}
        gridTemplateColumns={`repeat(2, calc(50% - (${theme.space_lg} / 2) ))`}
      >
        <Flex height={500} marginBottom={theme.space_lg}>
          <Box
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            flex={1}
            height="100%"
            padding={theme.space_md}
            width="50%"
          >
            <Flex justifyContent="space-between">
              <Text fontSize={theme.h3_fontSize}>
                {copyText.azureDatabaseCostAggregateChartTitle}
              </Text>

              <Dropdown
                defaultSelectedOption={defaultCostGroupingOption}
                options={costGroupingOptions}
                placement="bottom-end"
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  secondary
                  size="small"
                  width={140}
                >
                  {costGroupingLabel[queryParams.cost_grouping]}
                </Button>
              </Dropdown>
            </Flex>
            <Box height={450} paddingVertical={theme.space_md}>
              <StackedBarChart
                data={costData}
                disableDrilldown
                dimensions={costDimensions.map((dimension) => ({
                  name: dimension,
                  isDate: false,
                }))}
                limit={10}
                isLoading={isLoadingCostData}
                measures={costMeasures.map(getMeasureWithUnit)}
                showLegend
                showTooltip
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                emptyPlaceholderText={copyText.noDataPlaceholderMessage}
                xAxisKey="timestamp"
              />
            </Box>
          </Box>
        </Flex>
        <Flex height={500} marginBottom={theme.space_lg}>
          <Box
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            flex={1}
            height="100%"
            padding={theme.space_md}
            width="50%"
          >
            <Flex justifyContent="space-between">
              <Text fontSize={theme.h3_fontSize}>
                {copyText.azureDatabaseUsageChartTitle}
              </Text>

              <Dropdown
                defaultSelectedOption={defaultUsageGroupingOption}
                options={usageGroupingOptions}
                placement="bottom-end"
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  secondary
                  size="small"
                  width={140}
                >
                  {usageGroupingLabel[queryParams.usage_grouping]}
                </Button>
              </Dropdown>
            </Flex>
            <Box height={450} paddingVertical={theme.space_md}>
              <AreaChart
                data={usageData}
                dimensions={[]}
                disableDrilldown
                isLoading={isLoadingUsageData}
                measures={usageMeasures.map((measure) => {
                  return {
                    name: measure,
                  };
                })}
                mergeMeasures
                readableKeys={readableKeys}
                showLegend
                showTooltip
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                tooltipFormatter={formatUsageMeasure}
                xAxisKey="timestamp"
                yAxisFormatter={formatUsageMeasure}
              />
            </Box>
          </Box>
        </Flex>
      </Grid>
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginVertical={theme.space_lg}
        padding={theme.space_md}
      >
        <AzureDatabaseInstanceGroupTableControls
          csvData={instanceGroupCSVData}
          debouncedSearchText={debouncedSearchText}
          instanceGroupFilters={queryParamState.instanceGroupFilters}
          isLoadingAllInstances={isLoadingAllInstances}
          searchText={searchText}
          onInteraction={handleInteraction}
        />
        {csvElement}
      </Box>

      <AzureDatabaseInstanceGroupTable
        instanceGroups={filteredInstanceGroups}
        isLoadingInstanceGroups={isLoadingInstanceGroups}
        onInteraction={handleInteraction}
      />
      {queryParamState.selectedInstanceGroup !== null && (
        <Modal
          isOpen
          showCloseButton
          onClose={() =>
            setQueryParams({
              instance_table_sort: null,
              selected_group: null,
            })
          }
          minWidth={1100}
        >
          <Modal.Header>
            <Flex
              justifyContent="space-between"
              marginLeft={theme.space_sm}
              width="100%"
            >
              <Text fontSize={theme.h4_fontSize}>
                {copyText.azureDatabaseInstanceTableModalHeader}
              </Text>
            </Flex>
            {instanceCSVData.rows.length > 0 && (
              <CSVLink
                data={instanceCSVData.rows}
                headers={instanceCSVData.headers}
                filename={`azure-database-instances-${format(
                  new Date(),
                  "MM-dd-yyyy"
                )}`}
              >
                <Button
                  iconStart={<Icon color="inherit" icon={faFileExport} />}
                  marginRight={theme.space_sm}
                  secondary
                  size="small"
                >
                  {copyText.exportButtonLabel}
                </Button>
              </CSVLink>
            )}
          </Modal.Header>
          <Modal.Body>
            <AzureDatabaseInstanceTable
              instances={instances}
              isLoading={isLoadingInstances}
            />
          </Modal.Body>
        </Modal>
      )}
    </Box>
  );
}

const readableKeys: Record<string, string> = {
  memoryBytesUsage: copyText.azureDatabaseMemoryBytesUsage,
  memoryGB: copyText.azureDatabaseMemoryGB,
  cores: copyText.azureDatabaseCores,
  coreUsage: copyText.azureDatabaseCoreUsage,
  connections: copyText.azureDatabaseConnections,
  usageAmount: copyText.azureDatabaseUsageAmount,
  storageCapacityGB: copyText.azureDatabaseStorageCapacityGB,
};

function getCostDimensionsFromOption(option: AzureDatabaseCostChartOption) {
  switch (option) {
    case AzureDatabaseCostChartOption.DATABASE_TYPE:
      return [azureDatabaseSQLDimensions.databaseEngine];
    case AzureDatabaseCostChartOption.INSTANCE:
      return [azureDatabaseSQLDimensions.instanceName];
    case AzureDatabaseCostChartOption.PROJECT_ID:
      return [azureDatabaseSQLDimensions.projectId];
    case AzureDatabaseCostChartOption.SKU:
      return [azureDatabaseSQLDimensions.skuTier];
    case AzureDatabaseCostChartOption.REGION:
      return [azureDatabaseSQLDimensions.location];
    case AzureDatabaseCostChartOption.COMPUTE_TIER:
      return [azureDatabaseSQLDimensions.computeTier];
    case AzureDatabaseCostChartOption.BILLING_METHOD:
      return [azureDatabaseSQLDimensions.billingMethod];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getUsageMeasuresFromOption(
  option: AzureDatabaseUsageChartOption
): string[] {
  if (option === AzureDatabaseUsageChartOption.CONNECTIONS) {
    return [azureDatabaseSQLMeasures.connections];
  } else if (option === AzureDatabaseUsageChartOption.DISK) {
    return [
      azureDatabaseSQLMeasures.usageAmount,
      azureDatabaseSQLMeasures.storageCapacityGB,
    ];
  } else if (option === AzureDatabaseUsageChartOption.MEMORY) {
    return [
      azureDatabaseSQLMeasures.memoryGB,
      azureDatabaseSQLMeasures.memoryBytesUsage,
    ];
  } else {
    return [azureDatabaseSQLMeasures.cores, azureDatabaseSQLMeasures.coreUsage];
  }
  return [];
}

const measuresWithUnit: { name: string; unit: UnitType }[] = [
  { name: "cost", unit: UnitType.CURRENCY },
];

function getMeasureWithUnit(measure: string) {
  return (
    measuresWithUnit.find((other) => other.name === measure) ?? {
      name: measure,
      unit: UnitType.STANDARD,
    }
  );
}

function getInstanceGroupQueryFilters(
  selectedInstanceGroup: z.infer<typeof selectedInstanceGroupStruct>
): QueryFilter[] {
  const groupDimensionKeys = [
    azureDatabaseSQLDimensions.databaseEngine,
    azureDatabaseSQLDimensions.location,
    azureDatabaseSQLDimensions.projectId,
  ] as const;

  const andFilter = groupDimensionKeys.map(
    (key): QueryFilter =>
      selectedInstanceGroup[key] === ""
        ? {
            name: key,
            operator: Operator.NOT_SET,
          }
        : {
            name: key,
            operator: Operator.EQUALS,
            values: [selectedInstanceGroup[key]],
          }
  );

  return [{ and: andFilter }];
}

function getQueryParamState(queryParams: QueryParams): QueryParamState {
  const dateRange =
    queryParams.date_range_start && queryParams.date_range_end
      ? [queryParams.date_range_start, queryParams.date_range_end]
      : getCubeDateRangeFromDurationType(queryParams.duration);

  const dateRangeDurationInHours =
    dateRange[0] && dateRange[1]
      ? differenceInHours(endOfDay(dateRange[1]), dateRange[0])
      : 0;

  const isSmallDateRange =
    dateRangeDurationInHours > 0 && dateRangeDurationInHours <= 48;

  const dateRangeGranularity = isSmallDateRange
    ? TimeGranularity.HOUR
    : TimeGranularity.DAY;

  return {
    dateRange,
    dateRangeGranularity,
    duration: queryParams.duration,
    instanceGroupFilters:
      queryParams.instance_group_filters ?? instanceGroupFiltersDefault,
    selectedCostChartOption: queryParams.cost_grouping,
    selectedInstanceGroup: queryParams.selected_group ?? null,
    selectedUsageChartOption: queryParams.usage_grouping,
  };
}

function isInstanceGroupPassingFilters(
  instanceGroup: AzureDatabaseInstanceGroupEntity,
  filters: AzureDatabaseInstanceGroupFilters
): boolean {
  if (
    filters.databaseEngine !== null &&
    instanceGroup.databaseEngine.toLowerCase().trim() !==
      filters.databaseEngine.toLowerCase().trim()
  ) {
    return false;
  }
  if (
    filters.location !== null &&
    instanceGroup.location.toLowerCase().trim() !==
      filters.location.toLowerCase().trim()
  ) {
    return false;
  }
  if (
    filters.projectId !== null &&
    instanceGroup.projectId.toLowerCase().trim() !==
      filters.projectId.toLowerCase().trim()
  ) {
    return false;
  }

  return true;
}

function isSearchTextInInstanceGroup(
  instanceGroup: AzureDatabaseInstanceGroupEntity,
  searchText: string
): boolean {
  if (searchText === "") return true;

  const values = [
    instanceGroup.databaseEngine,
    instanceGroup.location,
    instanceGroup.projectId,
  ].map((value) => (value === "" ? "null" : value));

  return values.some((value) =>
    value.toLowerCase().trim().includes(searchText)
  );
}

const csvInstanceGroupAccessors = [
  "projectId",
  "backupRetentionPeriodDays",
  "connections",
  "cores",
  "coreUsage",
  "cost",
  "cpuPercentAvg",
  "cpuPercentMax",
  "cpuPercentMin",
  "databaseEngine",
  "location",
  "memoryBytesUsage",
  "memoryGB",
  "memoryPercentAvg",
  "memoryPercentMax",
  "memoryPercentMin",
  "resourceCount",
  "storageCapacityGB",
  "storagePercent",
  "usageAmount",
] as const;

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number | boolean>[];
};

function getInstanceGroupCSVData(
  groups: AzureDatabaseInstanceGroupEntity[]
): CSVData {
  if (!groups.length) {
    return { headers: [], rows: [] };
  }

  const rows = groups.map((group) => ({
    projectId: group.projectId,
    backupRetentionPeriodDays: group.backupRetentionPeriodDays,
    connections: group.connections,
    cores: group.cores,
    coreUsage: group.coreUsage,
    cost: group.cost,
    cpuPercentAvg: group.cpuPercentAvg,
    cpuPercentMax: group.cpuPercentMax,
    cpuPercentMin: group.cpuPercentMin,
    databaseEngine: group.databaseEngine,
    location: group.location,
    memoryBytesUsage: group.memoryBytesUsage,
    memoryGB: group.memoryGB,
    memoryPercentAvg: group.memoryPercentAvg,
    memoryPercentMax: group.memoryPercentMax,
    memoryPercentMin: group.memoryPercentMin,
    resourceCount: group.resourceCount,
    storageCapacityGB: group.storageCapacityGB,
    storagePercent: group.storagePercent,
    usageAmount: group.usageAmount,
  }));

  const headers = csvInstanceGroupAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `azureDatabaseTableInstanceGroupHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}
const csvInstanceAccessors = [
  "projectId",
  "instanceName",
  "absoluteCredits",
  "backupRetentionPeriodDays",
  "backupStorageRedundancy",
  "billingMethod",
  "computeTier",
  "connections",
  "cores",
  "coreUsage",
  "cost",
  "cpuPercentAvg",
  "cpuPercentMax",
  "cpuPercentMin",
  "databaseEngine",
  "highAvailabilityEnabled",
  "location",
  "memoryBytesUsage",
  "memoryGB",
  "memoryPercentAvg",
  "memoryPercentMax",
  "memoryPercentMin",
  "skuName",
  "skuTier",
  "storageCapacityGB",
  "storagePercent",
  "usageAmount",
] as const;

function getInstanceCSVData(groups: AzureDatabaseInstanceEntity[]): CSVData {
  if (!groups.length) {
    return { headers: [], rows: [] };
  }

  const rows = groups.map((group) => ({
    projectId: group.projectId,
    instanceName: group.instanceName,
    absoluteCredits: group.absoluteCredits,
    backupRetentionPeriodDays: group.backupRetentionPeriodDays,
    backupStorageRedundancy: group.backupStorageRedundancy,
    billingMethod: group.billingMethod,
    computeTier: group.computeTier,
    connections: group.connections,
    cores: group.cores,
    coreUsage: group.coreUsage,
    cost: group.cost,
    cpuPercentAvg: group.cpuPercentAvg,
    cpuPercentMax: group.cpuPercentMax,
    cpuPercentMin: group.cpuPercentMin,
    databaseEngine: group.databaseEngine,
    highAvailabilityEnabled: group.highAvailabilityEnabled,
    location: group.location,
    memoryGB: group.memoryGB,
    memoryBytesUsage: group.memoryBytesUsage,
    memoryPercentAvg: group.memoryPercentAvg,
    memoryPercentMax: group.memoryPercentMax,
    memoryPercentMin: group.memoryPercentMin,
    skuName: group.skuName,
    skuTier: group.skuTier,
    storageCapacityGB: group.storageCapacityGB,
    storagePercent: group.storagePercent,
    usageAmount: group.usageAmount,
  }));

  const headers = csvInstanceAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `azureDatabaseTableInstanceHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}

type GetFilteredInstanceGroupsParams = {
  allInstanceGroups: AzureDatabaseInstanceGroupEntity[];
  instanceGroupFilters: AzureDatabaseInstanceGroupFilters;
  searchText: string | null;
};

function getFilteredInstanceGroups(
  params: GetFilteredInstanceGroupsParams
): AzureDatabaseInstanceGroupEntity[] {
  const searchText = (params.searchText ?? "").toLowerCase().trim();

  return params.allInstanceGroups.filter((instanceGroup) => {
    if (
      !isInstanceGroupPassingFilters(instanceGroup, params.instanceGroupFilters)
    ) {
      return false;
    }

    if (!isSearchTextInInstanceGroup(instanceGroup, searchText)) {
      return false;
    }

    return true;
  });
}
