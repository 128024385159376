import useGetRecommendationTypes from "@/api/core/hooks/useGetRecommendationTypes";
import useGetRecommendationsByTenantID from "@/api/core/hooks/useGetRecommendationsByTenantID";
import useUpdateRecommendation from "@/api/core/hooks/useUpdateRecommendation";
import {
  UpdateRecommendationParameters,
  UpdateRecommendationsParameters,
} from "@/api/core/types";
import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import useGatekeeper from "@/hooks/useGatekeeper";
import { AlertType, postAlert } from "@/utils/alerts";
import { useTheme } from "@emotion/react";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { DataSource, Operator } from "@ternary/api-lib/analytics/enums";
import { Filter } from "@ternary/api-lib/analytics/ui/types";
import {
  CaseStatus,
  CloudProviderType,
  RecommendationCategory,
  ServiceType,
} from "@ternary/api-lib/constants/enums";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import { groupBy, keyBy } from "lodash";
import React, { useMemo, useState } from "react";
import { StringParam, useQueryParams } from "use-query-params";
import useGetSpendSummaries from "../../../api/analytics/useGetSpendSummaries";
import useUpdateRecommendations from "../../../api/core/hooks/useUpdateRecommendations";
import useGetLabelMapsByTenantID from "../../../api/core/useGetLabelMapsByTenantID";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import { getVendorFromCloudProviderType } from "../../../utils/cloudProviderConverter";
import getMergeState from "../../../utils/getMergeState";
import useGetCasesByTenantID from "../../case-management/hooks/useGetCasesByTenantID";
import copyText from "../copyText";
import RecommendationDetails from "./RecommendationDetails";
import RecommendationsBulkEditForm from "./RecommendationsBulkEditForm";
import RecommendationsMetersSection from "./RecommendationsMetersSection";
import RecommendationsTable from "./RecommendationsTable";

const defaultRecommendations = [];
const defaultCases = [];

interface Props {
  category: RecommendationCategory;
  cloudProviderType: CloudProviderType;
  dataSource?: DataSource;
  excludeCredits?: boolean;
  recommendationsKind: string;
  serviceType: ServiceType;
  skuCategory: string;
  summaryFilters?: Filter[];
}

interface State {
  selectedRecommendationIDs: string[];
  isBulkEditModalOpen: boolean;
}

const defaultState: State = {
  selectedRecommendationIDs: [],
  isBulkEditModalOpen: false,
};

export default function RecommendationsContainer(props: Props): JSX.Element {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  const [searchParamState, setSearchParamState] = useQueryParams({
    id: StringParam,
  });

  const [state, setState] = useState(defaultState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const { data: cases = defaultCases, isLoading: isLoadingCases } =
    useGetCasesByTenantID(authenticatedUser.tenant.id);

  const {
    data: _recommendations = defaultRecommendations,
    isLoading: isLoadingRecommendations,
    refetch: refetchRecommendations,
  } = useGetRecommendationsByTenantID(authenticatedUser.tenant.fsDocID, {
    category: props.category,
    cloudProviderType: props.cloudProviderType,
    serviceType: props.serviceType,
  });

  const casesKeyedByResourceID = groupBy(
    cases.filter((_case) => _case.status === CaseStatus.OPEN),
    "resourceID"
  );

  const recommendations = useMemo(() => {
    return _recommendations.map((recommendation) => {
      if (casesKeyedByResourceID[recommendation.id]) {
        return {
          ...recommendation,
          cases: casesKeyedByResourceID[recommendation.id],
        };
      } else {
        return {
          ...recommendation,
          cases: [],
        };
      }
    });
  }, [_recommendations, cases]);

  const {
    data: recommendationTypes = [],
    isLoading: isLoadingRecommendationTypes,
  } = useGetRecommendationTypes({
    enabled: gatekeeper.canListRecommendations,
  });

  const selectedRecommendation = useMemo(() => {
    if (!searchParamState.id) {
      // Nothing selected, we're done
      return undefined;
    }

    const found = recommendations.find(
      (rec) => rec.number === searchParamState.id
    );

    if (!found && !isLoadingRecommendations) {
      activityTracker.captureMessage(
        `NO RECOMMENDATION FOUND: provided id = ${searchParamState.id}`
      );
    }

    return found;
  }, [searchParamState.id, recommendations]);

  const selectedRecommendations = useMemo(() => {
    if (!state.isBulkEditModalOpen) return [];

    const recommendationsKeyedByID = keyBy(recommendations, "id");

    return state.selectedRecommendationIDs
      .filter((id) => recommendationsKeyedByID[id])
      .map((id) => recommendationsKeyedByID[id]);
  }, [
    recommendations,
    state.isBulkEditModalOpen,
    state.selectedRecommendationIDs,
  ]);

  const { data: labelMaps } = useGetLabelMapsByTenantID(
    authenticatedUser.tenant.fsDocID
  );

  // TODO: Check if this is still revelant with Analytics API
  // NOTE: Since "vendor" only exists in the label map we don't want to use the filter
  // incases where it isn't present. Otherwise we will get 400's from cube.

  const billingLabelMap = labelMaps ? labelMaps[DataSource.BILLING] : {};

  const vendorLabelNumber = billingLabelMap ? billingLabelMap["vendor"] : null;

  const spendSummaryFilters = props.dataSource
    ? []
    : [
        ...(vendorLabelNumber
          ? [
              {
                name: "vendor",
                operator: Operator.EQUALS,
                values: [
                  getVendorFromCloudProviderType(props.cloudProviderType),
                ],
              },
            ]
          : []),
      ];

  // Use the dedicated data source for each service. If none is available, fall back to billing + vendor filter.
  const spendSummaries = useGetSpendSummaries(
    {
      dataSource: props.dataSource ? props.dataSource : DataSource.BILLING,
      excludeCredits: props.excludeCredits,
      ...(props.dataSource ? {} : { dimensions: ["vendor"] }),
      queryFilters: [...(props.summaryFilters ?? []), ...spendSummaryFilters],
    },
    {
      enabled: gatekeeper.canListRecommendations,
    }
  );

  const [
    { data: mtdSpendSummary },
    { data: lastMonthSpendSummary },
    { data: lastMTDSpendSummary },
  ] = spendSummaries;

  const isLoadingSpendSummaries = spendSummaries.some(
    (summary) => summary.isFetching
  );

  //
  // Mutations
  //

  const { isPending: isUpdatingRecommendation, mutate: updateRecommendation } =
    useUpdateRecommendation({
      onError: () => {
        postAlert({
          message: copyText.errorUpdatingRecommendationMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: () => {
        handleCloseModal();

        refetchRecommendations();

        activityTracker.captureAction(
          actions.CLICK_INSIGHTS_UPDATE_RECOMMENDATION
        );

        postAlert({
          message: copyText.successUpdatingRecommendationMessage,
          type: AlertType.SUCCESS,
        });
      },
    });

  const {
    isPending: isUpdatingRecommendations,
    mutate: updateRecommendations,
  } = useUpdateRecommendations({
    onError: () => {
      postAlert({
        message: copyText.errorUpdatingRecommendationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      refetchRecommendations();

      mergeState({ isBulkEditModalOpen: false });

      activityTracker.captureAction(
        actions.CLICK_INSIGHTS_UPDATE_RECOMMENDATION
      );

      postAlert({
        message: copyText.successUpdatingRecommendationMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  //
  // Interaction Handlers
  //

  function handleCloseModal(): void {
    setSearchParamState({ id: "" });
  }

  async function handleSelectRecommendation(id: string) {
    activityTracker.captureAction(actions.CLICK_INSIGHTS_SELECT_RECOMMENDATION);
    setSearchParamState({ id });
  }

  function handleSubmitModification(
    params: { recommendationID: string } & UpdateRecommendationParameters
  ): void {
    updateRecommendation(params);
  }

  function handleSubmitModifications(
    updates: UpdateRecommendationsParameters["paramsArray"]
  ): void {
    updateRecommendations({ paramsArray: updates });
  }

  //
  // Render
  //

  if (!gatekeeper.canListRecommendations) {
    return (
      <Flex alignItems="center" justifyContent="center" minHeight="50vh">
        <EmptyPlaceholder
          icon={faLock}
          loading={false}
          text={copyText.emptyPlaceholderInsufficientPermission}
        />
      </Flex>
    );
  }

  const isLoading =
    isLoadingCases || isLoadingRecommendations || isLoadingRecommendationTypes;

  return (
    <Box paddingTop={theme.space_md}>
      <RecommendationsMetersSection
        category={props.category}
        isLoading={isLoadingSpendSummaries || isLoadingRecommendations}
        lastMonthSpend={lastMonthSpendSummary?.grossCost ?? null}
        lastMTDSpend={lastMTDSpendSummary?.grossCost ?? null}
        mtdSpend={mtdSpendSummary?.grossCost ?? null}
        recommendations={recommendations}
      />
      <>
        <RecommendationDetails
          cloudProviderType={props.cloudProviderType}
          isOpen={selectedRecommendation !== undefined}
          loadingSubmit={isUpdatingRecommendation}
          recommendation={selectedRecommendation}
          recommendationTypes={recommendationTypes}
          onClose={handleCloseModal}
          onSubmitModification={handleSubmitModification}
        />
        <RecommendationsBulkEditForm
          isOpen={state.isBulkEditModalOpen}
          loadingSubmit={isUpdatingRecommendations}
          recommendationTypes={recommendationTypes}
          selectedRecommendations={selectedRecommendations}
          onClose={() => mergeState({ isBulkEditModalOpen: false })}
          onSubmitModification={handleSubmitModifications}
        />
        <RecommendationsTable
          category={props.category}
          cloudProviderType={props.cloudProviderType}
          loading={isLoading}
          recommendations={recommendations}
          recommendationTypes={recommendationTypes}
          selectedRecommendation={selectedRecommendation}
          selectedRecommendationIDs={state.selectedRecommendationIDs}
          onClickEditMulti={(selectedRecommendationIDs) =>
            mergeState({ isBulkEditModalOpen: true, selectedRecommendationIDs })
          }
          onSelectRowMulti={(selectedRecommendationIDs) =>
            mergeState({ selectedRecommendationIDs })
          }
          onSelectRowSingle={handleSelectRecommendation}
        />
      </>
    </Box>
  );
}
