import { useTheme } from "@emotion/react";
import {
  faArrowDown,
  faArrowUp,
  faEquals,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { formatPercentage } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";

export default function useGetChangePercent(params: {
  biggerIsBetter?: boolean;
  value: number;
  comparison: number;
}): { icon: IconDefinition; color: string; textValue: string } {
  const theme = useTheme();

  const output = {
    icon: faEquals,
    color: "",
    textValue: "0%",
  };

  // no comparison is supported as empty space
  if (isNaN(Number(params.comparison))) {
    output.textValue = "";
    return output;
  }

  // In the case of Infinity, just force the UI to "100%"
  const diffRatio =
    params.comparison > 0 ? params.value / params.comparison : 2;

  // an undefined biggerIsBetter means we just want the number
  if (typeof params.biggerIsBetter !== "boolean") {
    if (params.value > params.comparison) {
      output.textValue = formatPercentage(diffRatio - 1);
    } else if (params.comparison > params.value) {
      output.textValue = formatPercentage(1 - diffRatio);
    }
  }
  // a set biggerIsBetter means we want to express the difference with color & an arrow
  else {
    if (params.value > params.comparison) {
      output.icon = faArrowUp;
      output.color = !params.biggerIsBetter
        ? theme.feedback_negative
        : theme.feedback_positive;
      output.textValue = formatPercentage(diffRatio - 1);
    } else if (params.comparison > params.value) {
      output.icon = faArrowDown;
      output.color = params.biggerIsBetter
        ? theme.feedback_negative
        : theme.feedback_positive;
      output.textValue = formatPercentage(1 - diffRatio);
    }
  }

  return output;
}
