export const mspRollupMeasures = {
  billedCost: "billedCost",
  listCost: "listCost",
  userCost: "userCost",
} as const;

export const mspRollupDimensions = {
  billingAccountID: "billingAccountId",
  invoiceIssuer: "invoiceIssuer",
  invoiceMonth: "invoiceMonth",
  provider: "provider", // TODO: Remove this as a follow up
  providerName: "providerName",
  region: "region",
  serviceCategory: "serviceCategory",
  serviceName: "serviceName",
  tenantID: "tenantId",
  subAccountId: "subAccountId",
} as const;
