import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import {
  ChartType,
  DataSource,
  DurationType,
  TimeGranularity,
} from "@ternary/api-lib/analytics/enums";
import { Order } from "@ternary/api-lib/analytics/types";
import { Filter } from "@ternary/api-lib/analytics/ui/types";
import {
  CaseType,
  ReportType,
  ResourceType,
} from "@ternary/api-lib/constants/enums";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";
import { FiscalPeriodMap } from "../../../api/core/types";

interface ReportSnapshotParams {
  chartType: ChartType;
  dataSource: DataSource;
  dateRange: Date[] | null;
  dimensions?: string[];
  durationType: DurationType;
  filters?: Filter[];
  fiscalPeriodMap?: FiscalPeriodMap | null;
  granularity?: TimeGranularity;
  isFiscalMode?: boolean;
  limit?: number;
  measures: string[];
  name: string;
  order?: Order;
  overflow?: boolean;
  xAxisKey: string;
}

export type CreateInsightsCaseParams = {
  tenantID: string;
  description: string;
  name: string;
  resourceType: ResourceType;
  type: CaseType;
  assigneeIDs?: string[];
  followerIDs?: string[];
  reportSnapshot: ReportSnapshotParams;
};

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  CreateInsightsCaseParams
>;
type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  CreateInsightsCaseParams
>;

export default function useCreateInsightsCase(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: async (params) => {
      const { reportSnapshot, ...caseParams } = params;

      const [startDate, endDate] = reportSnapshot.dateRange
        ? [
            reportSnapshot.dateRange[0].toISOString(),
            reportSnapshot.dateRange[1].toISOString(),
          ]
        : [undefined, undefined];

      const reportSnapshotID = await client.createReport({
        tenantID: caseParams.tenantID,
        chartType: reportSnapshot.chartType,
        isCumulative: false,
        dataSource: reportSnapshot.dataSource,
        ...(endDate ? { endDate } : {}),
        dimensions: reportSnapshot.dimensions,
        durationType: reportSnapshot.durationType,
        excludeNegativeNumbers: false,
        excludeOther: false,
        filters: reportSnapshot.filters,
        isFormulaHidden: false,
        isMetricHidden: false,
        measures: reportSnapshot.measures,
        name: reportSnapshot.name,
        nLookback: null,
        reverse: false,
        ...(startDate ? { startDate } : {}),
        timeGranularity: reportSnapshot.granularity,
        type: ReportType.SNAPSHOT,
        xAxisKey: reportSnapshot.xAxisKey,
      });

      return client.createCase({
        ...caseParams,
        resourceID: reportSnapshotID,
      });
    },
    ...options,
  });
}
