import { createStructParam } from "@/lib/use-query-params";
import { useTheme } from "@emotion/react";
import { createColumnHelper } from "@tanstack/react-table";
import useRefFn from "@ternary/api-lib/analytics/ui/hooks/useRefFn";
import {
  formatCurrency,
  formatNumber,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import React, { useMemo } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import {
  AWSDatabaseElastiCacheGroup,
  awsDatabaseElastiCacheDimensions,
  awsDatabaseElastiCacheMeasures,
} from "../types";

type Props = {
  instanceGroups: AWSDatabaseElastiCacheGroup[];
  isLoadingInstanceGroups: boolean;
  onInteraction: (
    interaction: AWSDatabaseElastiCacheGroupTable.Interaction
  ) => void;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

const columnHelper = createColumnHelper<AWSDatabaseElastiCacheGroup>();

function AWSDatabaseElastiCacheGroupTable(props: Props) {
  const theme = useTheme();

  const [sortRule, setSortRule] = useQueryParam(
    "group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "cost",
    })
  );

  const onInteraction = useRefFn(props.onInteraction);

  const columns = useMemo(
    () => [
      ...(
        [
          awsDatabaseElastiCacheDimensions.clusterId,
          awsDatabaseElastiCacheDimensions.payerAccountId,
          awsDatabaseElastiCacheDimensions.usageAccountId,
          awsDatabaseElastiCacheDimensions.instanceType,
          awsDatabaseElastiCacheDimensions.cacheEngine,
          awsDatabaseElastiCacheDimensions.instanceMemory,
          awsDatabaseElastiCacheDimensions.instanceVcpu,
          awsDatabaseElastiCacheDimensions.region,
        ] as const
      ).map((dimensionKey, index) => {
        const headerKey: keyof typeof copyText = `awsDatabaseElastiCacheTableHeader_${dimensionKey}`;

        return columnHelper.accessor(dimensionKey, {
          cell: ({ getValue }) => (
            <Text
              color={theme.primary_color_text}
              truncate
              cursor="pointer"
              onClick={() =>
                onInteraction({
                  type: AWSDatabaseElastiCacheGroupTable.INTERACTION_FILTER_CLICKED,
                  filterKey: dimensionKey,
                  filterValue: getValue(),
                })
              }
            >
              <Tooltip
                content={getValue() || copyText.awsDatabaseElastiCacheTableNull}
              >
                {getValue() || copyText.awsDatabaseElastiCacheTableNull}
              </Tooltip>
            </Text>
          ),
          header:
            dimensionKey === awsDatabaseElastiCacheDimensions.usageAccountId
              ? () => (
                  <Tooltip
                    content={
                      <Box width={200}>
                        <Text color={theme.text_color_inverse}>
                          {copyText.awsIAMListPermissionTooltipMessage}
                        </Text>
                      </Box>
                    }
                  >
                    {copyText[headerKey]}
                  </Tooltip>
                )
              : copyText[headerKey],
          meta: { align: index === 0 ? "left" : "center" },
          size: 140,
        });
      }),
      columnHelper.accessor(awsDatabaseElastiCacheMeasures.cost, {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.awsDatabaseElastiCacheTableHeader_cost,
        meta: { align: "right" },
        sortDescFirst: true,
      }),
      columnHelper.accessor(awsDatabaseElastiCacheMeasures.nodeCost, {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.awsDatabaseElastiCacheTableHeader_nodeCost,
        meta: { align: "right" },
        sortDescFirst: true,
      }),
      columnHelper.accessor(awsDatabaseElastiCacheMeasures.nodeUsage, {
        cell: ({ getValue }) =>
          copyText.awsNodeHoursNumber.replace(
            "%NUMBER%",
            formatNumber(getValue())
          ),
        header: copyText.awsDatabaseElastiCacheTableHeader_nodeUsage,
        meta: { align: "right" },
        sortDescFirst: true,
      }),
    ],
    []
  );

  return (
    <Table
      columns={columns}
      compact
      data={props.instanceGroups}
      initialState={{ sorting: [sortRule] }}
      isLoading={props.isLoadingInstanceGroups}
      showPagination
      sortable
      onChangeSortBy={([sortRule]) => setSortRule(sortRule)}
    />
  );
}

AWSDatabaseElastiCacheGroupTable.INTERACTION_FILTER_CLICKED =
  `AWSDatabaseElastiCacheGroupTable.INTERACTION_FILTER_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof AWSDatabaseElastiCacheGroupTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof AWSDatabaseElastiCacheGroup;
  filterValue: AWSDatabaseElastiCacheGroup[keyof AWSDatabaseElastiCacheGroup];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AWSDatabaseElastiCacheGroupTable {
  export type Interaction = InteractionFilterClicked;
}
export default AWSDatabaseElastiCacheGroupTable;
