import Permission from "@ternary/api-lib/constants/permissions";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type MspBillingInfoGates = ReturnType<typeof mspBillingInfoGates>;

export default function mspBillingInfoGates(params: GateGetterParameters) {
  const canReadMspBillingInfo = params._permissionsIncludeAllOf([
    Permission.READ_MSP_BILLING_INFO,
  ]);

  const canCreateMspBillingInfo = params._permissionsIncludeAllOf([
    Permission.CREATE_MSP_BILLING_INFO,
  ]);

  const canDeleteMspBillingInfo = params._permissionsIncludeAllOf([
    Permission.DELETE_MSP_BILLING_INFO,
  ]);

  const canUpdateMspBillingInfo = params._permissionsIncludeAllOf([
    Permission.UPDATE_MSP_BILLING_INFO,
  ]);

  const canDeleteMspBillingStatement = params._permissionsIncludeAllOf([
    Permission.DELETE_MSP_BILLING_STATEMENT,
  ]);

  const canViewMspBillingStatements = params._permissionsIncludeAllOf([
    Permission.READ_MSP_BILLING_STATEMENTS,
  ]);

  return {
    canCreateMspBillingInfo,
    canDeleteMspBillingInfo,
    canDeleteMspBillingStatement,
    canReadMspBillingInfo,
    canUpdateMspBillingInfo,
    canViewMspBillingStatements,
  };
}
