import { UnitType } from "@ternary/api-lib/analytics/enums";
import { formatCurrency } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import {
  GcpCommitmentDurationType,
  GcpCommitmentRecommendationTierType,
  GcpCommitmentServiceType,
  GcpCommitmentType,
  GcpTargetCommitmentType,
  ServiceType,
} from "@ternary/api-lib/constants/enums";
import prettyBytes from "pretty-bytes";
import paths from "../../../constants/paths";
import copyText from "../copyText";
import { CustomPricingEntity } from "./types";

export type SpendData = {
  cud1YearCost: number;
  cud3YearCost: number;
  remainingOnDemandSpend: number;
  cost: number;
};

type HourUsageDatum = {
  cud1YearCost: number;
  cud1YearUsageAmount: number;
  cud3YearCost: number;
  cud3YearUsageAmount: number;
  cudsCreditAmount: number;

  family: string;
  region: string;
  resourceCost: number;
  resourceUsageAmount: number;
  resourceUsageUnit: string;
  sudsCreditAmount: number;
  totalResourceCreditsAmount: number;
  timestamp: string;
};

// Possible BUG: in case of reduced CUD during month, we get into a bad state
// This is quite possibly due to the mock data, where I'm not accounting for totalCUDs Credit increase during firsthalf

// Limitations and Assumptions
// This ignores totalResourceCreditAmount completely, which could be either a good thing or a bad thing. (other credits)
// Zero resource usage AND zero CUDs precludes us from calculating CUD prices at all
// In the case of overcomit, the wasted cud will be proportional between 1s and 3s based on ratio of actual commits
// we do not capture implicit waste due to burstiness within the hour (which would be modeled as something like a guaranteed wasteage ratio): very doable.

export const DEFAULT_CUD_1_MULTIPLIER = 0.63;
export const DEFAULT_CUD_3_MULTIPLIER = 0.45;

//
// fabricator modifies actual usage to reflect hypothetical increasing commitments
//
export const fabricator = (params: {
  datum: HourUsageDatum;
  cud1YrAmount: number;
  cud3YrAmount: number;
  customPricing: CustomPricingEntity | undefined;
  selectedSUDToggle: boolean;
}): HourUsageDatum => {
  // constants
  const onDemandPrice =
    params.datum.resourceCost / params.datum.resourceUsageAmount;
  const cud1Price =
    onDemandPrice *
    (params.customPricing && params.customPricing.oneYearDiscount
      ? 1 - params.customPricing.oneYearDiscount
      : DEFAULT_CUD_1_MULTIPLIER);
  const cud3Price =
    onDemandPrice *
    (params.customPricing && params.customPricing.threeYearDiscount
      ? 1 - params.customPricing.threeYearDiscount
      : DEFAULT_CUD_3_MULTIPLIER);
  const unmodifiedCUDTotalUsage =
    params.datum.cud1YearUsageAmount + params.datum.cud3YearUsageAmount;

  params.datum = {
    ...params.datum,
    cud1YearUsageAmount: params.cud1YrAmount,
    cud3YearUsageAmount: params.cud3YrAmount,
  };
  const { cud1YrPortion, cud3YrPortion } = getCUDPortions(params.datum);

  const cudsDeltaMultiplier =
    unmodifiedCUDTotalUsage > 0
      ? (params.cud1YrAmount + params.cud3YrAmount) / unmodifiedCUDTotalUsage
      : 0;

  // credits
  let cud1YearCredit =
    unmodifiedCUDTotalUsage <= 0
      ? params.cud1YrAmount * onDemandPrice
      : Math.abs(
          cud1YrPortion * params.datum.cudsCreditAmount * cudsDeltaMultiplier
        );

  let cud3YearCredit =
    unmodifiedCUDTotalUsage <= 0
      ? params.cud3YrAmount * onDemandPrice
      : Math.abs(
          cud3YrPortion * params.datum.cudsCreditAmount * cudsDeltaMultiplier
        );

  let totalCUDsCredit = cud1YearCredit + cud3YearCredit;

  // Excessive CUD handling
  let receivedCUD1YrAmount = params.cud1YrAmount;
  let receivedCUD3YrAmount = params.cud3YrAmount;
  const maxCUD = params.datum.resourceUsageAmount;

  if (params.cud1YrAmount + params.cud3YrAmount > maxCUD) {
    // cud usage is capped at real usage. cost is not. limitting what the actual benefit is here
    receivedCUD1YrAmount = cud1YrPortion * maxCUD;
    receivedCUD3YrAmount = cud3YrPortion * maxCUD;

    // Re-calculate credits, for lower applicable usage
    cud1YearCredit = Math.abs(cud1YrPortion * params.datum.resourceCost);
    cud3YearCredit = Math.abs(cud3YrPortion * params.datum.resourceCost);
    totalCUDsCredit = cud1YearCredit + cud3YearCredit;
  }

  // SUDS
  const nonSudsNetCost =
    params.datum.resourceCost + params.datum.cudsCreditAmount;

  let sudsRatio = params.datum.sudsCreditAmount / nonSudsNetCost;

  const sudsableAmount = Math.max(
    params.datum.resourceCost - totalCUDsCredit,
    0
  );

  if (params.datum.sudsCreditAmount === 0 && nonSudsNetCost === 0) {
    sudsRatio = 0;
  }

  const sudsCreditAmount = sudsRatio * sudsableAmount;

  return {
    ...params.datum,
    cudsCreditAmount: totalCUDsCredit,
    cud1YearCost: cud1Price * params.cud1YrAmount,
    cud3YearCost: cud3Price * params.cud3YrAmount,
    cud1YearUsageAmount: receivedCUD1YrAmount,
    cud3YearUsageAmount: receivedCUD3YrAmount,
    sudsCreditAmount: params.selectedSUDToggle ? sudsCreditAmount : 0,
  };
};

//
// megatron transforms usage data to rolled-up spend data
//
export default function megatron(datum: HourUsageDatum): SpendData {
  const { cud1YrPortion, cud3YrPortion } = getCUDPortions(datum);
  const cud1YearCredit = Math.abs(cud1YrPortion * datum.cudsCreditAmount);
  const cud3YearCredit = Math.abs(cud3YrPortion * datum.cudsCreditAmount);

  const sudsDiscount = Math.abs(datum.sudsCreditAmount);

  let remainingOnDemandSpend =
    datum.resourceCost - cud1YearCredit - cud3YearCredit - sudsDiscount;

  if (remainingOnDemandSpend < 0) {
    // TODO: investigate why this case even happens after having gone through fabricator.
    // -- Not SUDs apparently, based on logs showing === 0
    // -- how is it possible for fabricator credits to exceed resource cost
    // -- -- Maybe credits can exceed cost **before** usage does. Might just be the issue with using random-generated data
    // Keep an eye on this. Log here in staging to see if it happens.
    remainingOnDemandSpend = 0;
  }

  const cud1YearCost = datum.cud1YearCost;
  const cud3YearCost = datum.cud3YearCost;
  return {
    cud1YearCost,
    cud3YearCost,
    remainingOnDemandSpend,
    cost: remainingOnDemandSpend + cud1YearCost + cud3YearCost,
  };
}

//
// Helper Functions
//

function getCUDPortions(datum: HourUsageDatum) {
  const totalCUDUsage = datum.cud1YearUsageAmount + datum.cud3YearUsageAmount;

  const cud1YrPortion =
    totalCUDUsage === 0 ? 0 : datum.cud1YearUsageAmount / totalCUDUsage;

  const cud3YrPortion =
    totalCUDUsage === 0 ? 0 : datum.cud3YearUsageAmount / totalCUDUsage;

  return { cud1YrPortion, cud3YrPortion };
}

export const getActualSpend = (usage: HourUsageDatum[]): SpendData => {
  return usage.reduce(
    (accum: SpendData, element): SpendData => {
      const spend = megatron(element);
      accum.remainingOnDemandSpend += spend.remainingOnDemandSpend;
      accum.cud1YearCost += spend.cud1YearCost;
      accum.cud3YearCost += spend.cud3YearCost;
      accum.cost += spend.cost;

      return accum;
    },
    {
      cud1YearCost: 0,
      cud3YearCost: 0,
      remainingOnDemandSpend: 0,
      cost: 0,
    }
  );
};

export function getFormattedTargetCommitmentValue(params: {
  estimatedCurrency: string;
  type: GcpTargetCommitmentType;
  value: number;
}): string {
  switch (params.type) {
    case GcpTargetCommitmentType.CURRENCY: {
      return `${formatCurrency({
        currencyCode: params.estimatedCurrency,
        number: params.value,
      })}${copyText.cudRecPerHourLabel}`;
    }
    case GcpTargetCommitmentType.MEMORY: {
      return prettyBytes(params.value, { binary: true });
    }
    case GcpTargetCommitmentType.SLOTS: {
      return copyText.cudRecSlotsLabel.replace("%slots%", `${params.value}`);
    }
    case GcpTargetCommitmentType.VCPU: {
      return `${params.value} ${copyText.cudRecCoresLabel}`;
    }
    default: {
      return params.value.toString();
    }
  }
}

export const getModifiedSpend = (params: {
  customPricing: CustomPricingEntity | undefined;
  usage: HourUsageDatum[];
  cud1Yr: number;
  cud3yr: number;
  selectedSUDToggle: boolean;
}): SpendData => {
  return (
    params.usage
      // consider a usage element to be pathological and reject it if usage amount is <= 0
      .filter((element) => element.resourceUsageAmount > 0)
      .reduce(
        (accum: SpendData, element): SpendData => {
          const spend = megatron(
            fabricator({
              datum: element,
              customPricing: params.customPricing,
              cud1YrAmount: params.cud1Yr,
              cud3YrAmount: params.cud3yr,
              selectedSUDToggle: params.selectedSUDToggle,
            })
          );
          accum.remainingOnDemandSpend += spend.remainingOnDemandSpend;
          accum.cud1YearCost += spend.cud1YearCost;
          accum.cud3YearCost += spend.cud3YearCost;
          accum.cost += spend.cost;

          return accum;
        },
        {
          cud1YearCost: 0,
          cud3YearCost: 0,
          remainingOnDemandSpend: 0,
          cost: 0,
        }
      )
  );
};

export const getAverageUsage = (usage: HourUsageDatum[]): number => {
  return (
    usage.reduce((accum: number, element) => {
      return accum + element.resourceUsageAmount;
    }, 0) / usage.length
  );
};

export const minimizeCostDumb = (
  step: number,
  f: (x: number) => number
): number => {
  const seed = 0;

  let minimumCost = f(seed);
  let optimalPosition = seed;

  for (let j = 0; j < 120; j += 1) {
    const position = seed + step * j;
    const cost = f(position);
    if (cost <= minimumCost) {
      minimumCost = cost;
      optimalPosition = position;
    }
  }

  return optimalPosition;
};

export const getOptimalPositionFor1yrCUDs = (params: {
  averageUsage: number;
  cuds3Year: number;
  customPricing: CustomPricingEntity | undefined;
  selectedSUDToggle: boolean;
  usage: HourUsageDatum[];
}): number => {
  const callback = (input: number) => {
    const { cost } = getModifiedSpend({
      customPricing: params.customPricing,
      usage: params.usage,
      cud1Yr: input,
      cud3yr: params.cuds3Year,
      selectedSUDToggle: params.selectedSUDToggle,
    });
    return cost;
  };

  const step = params.averageUsage / 100;

  return minimizeCostDumb(step, callback);
};

export const getOptimalPositionFor3yrCUDs = (params: {
  averageUsage: number;
  cuds1Year: number;
  customPricing: CustomPricingEntity | undefined;
  selectedSUDToggle: boolean;
  usage: HourUsageDatum[];
}): number => {
  const callback = (input: number) => {
    const { cost } = getModifiedSpend({
      customPricing: params.customPricing,
      usage: params.usage,
      cud1Yr: params.cuds1Year,
      cud3yr: input,
      selectedSUDToggle: params.selectedSUDToggle,
    });
    return cost;
  };

  const step = params.averageUsage / 100;

  return minimizeCostDumb(step, callback);
};

//
// CUD Visibility Helper Functions
//

export function getReadableGcpCommitmentLengthString(
  commitmentPlan: GcpCommitmentDurationType
) {
  switch (commitmentPlan) {
    case GcpCommitmentDurationType.TWELVE_MONTH:
      return copyText.cudVisInventoryCommitmentPlan1Year;
    case GcpCommitmentDurationType.THIRTY_SIX_MONTH:
      return copyText.cudVisInventoryCommitmentPlan3Year;
    default:
      return copyText.cudInventoryTableNotAvailable;
  }
}

export function getReadableGcpCommitmentTierStrings(
  tier: GcpCommitmentRecommendationTierType | null
): string {
  switch (tier) {
    case GcpCommitmentRecommendationTierType.FULL_UTILIZATION: {
      return copyText.gcpCommitmentRecommendationTierTypeFullUtilization;
    }
    case GcpCommitmentRecommendationTierType.MAXIMUM_SAVINGS: {
      return copyText.gcpCommitmentRecommendationTierTypeMaximumSavings;
    }
    default:
      return copyText.notAvailable;
  }
}

export function getReadableGcpCommitmentServiceTypeStrings(
  serviceType: GcpCommitmentServiceType | null
): string {
  switch (serviceType) {
    case GcpCommitmentServiceType.ALLOYDB: {
      return copyText.gcpCommitmentServiceTypeAlloyDB;
    }
    case GcpCommitmentServiceType.BIGQUERY_ENTERPRISE: {
      return copyText.gcpCommitmentServiceTypeBigQueryEnterprise;
    }
    case GcpCommitmentServiceType.BIGQUERY_ENTERPRISE_PLUS: {
      return copyText.gcpCommitmentServiceTypeBigQueryEnterprisePlus;
    }
    case GcpCommitmentServiceType.CLOUD_BIGTABLE: {
      return copyText.gcpCommitmentServiceTypeCloudBigTable;
    }
    case GcpCommitmentServiceType.CLOUD_MEMORY_STORE: {
      return copyText.gcpCommitmentServiceTypeCloudMemoryStore;
    }
    case GcpCommitmentServiceType.CLOUD_RUN: {
      return copyText.gcpCommitmentServiceTypeCloudRun;
    }
    case GcpCommitmentServiceType.CLOUD_SPANNER: {
      return copyText.gcpCommitmentServiceTypeCloudSpanner;
    }
    case GcpCommitmentServiceType.CLOUD_SQL: {
      return copyText.gcpCommitmentServiceTypeCloudSql;
    }
    case GcpCommitmentServiceType.CLOUD_VMWARE_ENGINE: {
      return copyText.gcpCommitmentServiceTypeCloudVmWareEngine;
    }
    case GcpCommitmentServiceType.COMPUTE_ENGINE: {
      return copyText.gcpCommitmentServiceTypeComputeEngine;
    }
    case GcpCommitmentServiceType.KUBERNETES_ENGINE: {
      return copyText.gcpCommitmentServiceTypeKubernetesEngine;
    }
    case null: {
      return copyText.cudInventoryTableNotAvailable;
    }
    default:
      return serviceType;
  }
}

export function getYAxisLabel(units: UnitType) {
  if (units === UnitType.BYTES) {
    return copyText.cudVisRAMUsageChartAxis;
  } else {
    return copyText.cudVisCPUUsageChartAxis;
  }
}

export function isGCPCommitmentDurationType(
  input: string
): input is GcpCommitmentDurationType {
  return Object.keys(GcpCommitmentDurationType).some(
    (key) => GcpCommitmentDurationType[key] === input
  );
}

export function isGCPCommitmentType(input: string): input is GcpCommitmentType {
  return Object.keys(GcpCommitmentType).some(
    (key) => GcpCommitmentType[key] === input
  );
}

export function getInsightsUrl(serviceType: ServiceType): string {
  switch (serviceType) {
    case ServiceType.BIGQUERY:
      return paths._insightsBigQuery;
    case ServiceType.GCS:
      return paths._insightsStorageGCS;
    case ServiceType.CLOUD_RUN:
      return paths._insightsComputeCloudRun;
    case ServiceType.SQL:
      return paths._insightsDatabaseCloudSQL;
    case ServiceType.GCE:
      return paths._insightsComputeGCE;
    case ServiceType.GKE:
      return paths._insightsKubernetesGKE;
    case ServiceType.EC2:
      return paths._insightsComputeEC2;
    case ServiceType.RDS:
      return paths._insightsDatabaseRDS;
    case ServiceType.EKS:
      return paths._insightsKubernetesEKS;
    case ServiceType.S3:
      return paths._insightsStorageS3;
    case ServiceType.EBS:
      return paths._insightsStorageEBS;
    default:
      return paths._home;
  }
}
