import Modal from "@/ui-lib/components/Modal";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import { faClipboard, faKey } from "@fortawesome/free-solid-svg-icons";
import { formatTimestamp } from "@ternary/api-lib/analytics/utils/ChartFormatUtils";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useState } from "react";
import copyText from "../copyText";

type Props = {
  apiKey: {
    name: string;
    expirationDate: Date | null;
    secret: string;
  };
  onInteraction: (interaction: GeneratedApiKeyModal.Interaction) => void;
};

type State = {
  isCopied: boolean;
};

const initialState: State = {
  isCopied: false,
};

export function GeneratedApiKeyModal(props: Props) {
  const theme = useTheme();

  const [state, setState] = useState<State>(initialState);

  const newExpirationTime = props.apiKey.expirationDate
    ? formatTimestamp(props.apiKey.expirationDate.toISOString(), "MM-dd-yyyy")
    : undefined;

  function handleClose() {
    props.onInteraction({
      type: GeneratedApiKeyModal.INTERACTION_CLOSE_BUTTON_CLICKED,
    });
  }

  function handleCopyAPIKey() {
    navigator.clipboard.writeText(props.apiKey.secret);
    setState({ isCopied: true });
  }

  return (
    <Modal isOpen={true} width={500}>
      <Modal.Header>
        <Text appearance="h3">{copyText.generatedApiKeyText}</Text>
      </Modal.Header>
      <Modal.Body>
        <Box marginBottom={theme.space_lg}>
          <Flex justifyContent="center">
            <Icon color={theme.feedback_positive} icon={faKey} size="3x" />
          </Flex>
        </Box>
        <Box marginBottom={theme.space_lg} marginTop={theme.space_lg}>
          <Flex justifyContent="center">
            <Text fontSize={theme.h4_fontSize} align="center">
              {copyText.keySecretMessage}
            </Text>
          </Flex>
        </Box>
        <Flex justifyContent="space-between">
          <Text bold marginBottom={theme.space_sm}>
            {props.apiKey.name}
          </Text>
          <Flex>
            <Text bold marginRight={theme.space_sm}>
              {copyText.generatedApiExpiresOn}
            </Text>
            <Text bold>{newExpirationTime}</Text>
          </Flex>
        </Flex>
        <Flex justifyContent="space-between">
          <TextInput
            readOnly
            value={props.apiKey.secret}
            variant={state.isCopied ? "success" : undefined}
          />
          <Tooltip
            content={
              state.isCopied
                ? copyText.copiedToClipboardText
                : copyText.copyToClipboardText
            }
          >
            <Button
              secondary
              iconStart={
                <Icon
                  color={theme.secondary_color}
                  icon={faClipboard}
                  size="lg"
                />
              }
              marginLeft={theme.space_md}
              onClick={handleCopyAPIKey}
            ></Button>
          </Tooltip>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Box marginTop={theme.space_md}>
          <Button disabled={!state.isCopied} primary onClick={handleClose}>
            {copyText.generatedApiCloseText}
          </Button>
        </Box>
      </Modal.Footer>
    </Modal>
  );
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace GeneratedApiKeyModal {
  export const INTERACTION_CLOSE_BUTTON_CLICKED = `GeneratedApiKeyFormModal.INTERACTION_CLOSE_BUTTON_CLICKED`;

  interface InteractionCloseButtonClicked {
    type: typeof INTERACTION_CLOSE_BUTTON_CLICKED;
  }
  export type Interaction = InteractionCloseButtonClicked;
}

export default GeneratedApiKeyModal;
