import Checkbox from "@/ui-lib/components/Checkbox";
import Modal from "@/ui-lib/components/Modal";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { format } from "date-fns";
import { noop } from "lodash";
import React, { useState } from "react";
import { DatePicker } from "../../../../ui-lib/components/DateRangeControls/DatePicker";
import copyText from "../../copyText";
import useFiscalYearsData, {
  fiscalDateFns,
} from "../../hooks/useFiscalYearsData";
import { createUpdatedFiscalCalendar } from "../../hooks/useUpdateFiscalCalendar";
import { EditedFiscalCalendar, QuarterPattern } from "../../types";
import FiscalCalendarYearForm from "./FiscalCalendarForm";
import FiscalYearValidations from "./FiscalYearValidations";

type Props = {
  calendar: EditedFiscalCalendar | null;
  yearOptions: [string, string] | "all";
  onInteraction: (interaction: AddYearModal.Interaction) => void;
};

type State = {
  calendar: EditedFiscalCalendar | null;
  hasChangedCalendar: boolean;
  isAutofill: boolean;
  selectedYearNumber: string | null;
};

function AddYearModal(props: Props) {
  const theme = useTheme();

  const [state, setState] = useState<State>({
    calendar: props.calendar,
    hasChangedCalendar: false,
    isAutofill: true,
    selectedYearNumber: null,
  });

  const fiscalYearsData = useFiscalYearsData(props.calendar);
  const updatedYearsData = useFiscalYearsData(state.calendar);

  const mergeState = getMergeState(setState);

  function handleClickYear(yearNumber: string) {
    if (!state.isAutofill) {
      mergeState({ selectedYearNumber: yearNumber });
      return;
    }

    let startDate: string;
    let endDate: string;
    let quarterPattern: QuarterPattern;

    const prevYearNumber = String(Number(yearNumber) - 1);
    const prevYear = fiscalYearsData.yearMap[prevYearNumber];

    const nextYearNumber = String(Number(yearNumber) + 1);
    const nextYear = fiscalYearsData.yearMap[nextYearNumber];

    if (prevYear) {
      startDate = fiscalDateFns.dayAfter(prevYear.endDate);
      endDate = fiscalDateFns.add(prevYear.endDate, { weeks: 52 });
      quarterPattern = prevYear.quarterPattern;
    } else if (nextYear) {
      startDate = fiscalDateFns.sub(nextYear.startDate, { weeks: 52 });
      endDate = fiscalDateFns.dayBefore(nextYear.startDate);
      quarterPattern = nextYear.quarterPattern;
    } else {
      return;
    }

    const updatedCalendar = createUpdatedFiscalCalendar({
      prevCalendar: props.calendar,
      fiscalYearNumber: yearNumber,
      startDate,
      endDate,
      quarterPattern,
    });

    props.onInteraction({
      type: AddYearModal.INTERACTION_SUBMIT_BUTTON_CLICKED,
      calendar: updatedCalendar,
      yearNumber,
    });
  }

  function handleCalendarFormInteraction(
    interaction: FiscalCalendarYearForm.Interaction
  ) {
    switch (interaction.type) {
      case FiscalCalendarYearForm.INTERACTION_UPDATE_CALENDAR:
        mergeState({
          calendar: interaction.calendar,
          hasChangedCalendar: true,
        });
    }
  }

  const showCalendarForm =
    props.yearOptions === "all"
      ? Boolean(state.selectedYearNumber)
      : Boolean(state.selectedYearNumber && !state.isAutofill);

  return (
    <Modal
      closeOnClickOutside={false}
      isOpen
      minWidth={250}
      position="center"
      showCloseButton
      onClose={() =>
        props.onInteraction({
          type: AddYearModal.INTERACTION_CLOSE_BUTTON_CLICKED,
        })
      }
    >
      <Modal.Header>
        <Text appearance="h4">{copyText.fiscalCalendarAddYear}</Text>
      </Modal.Header>

      <Box paddingBottom={theme.space_lg}>
        {props.yearOptions === "all"
          ? renderYearPickerDropdown()
          : renderYearPickerButtons()}

        {state.selectedYearNumber && showCalendarForm && (
          <>
            <Box>
              <FiscalCalendarYearForm
                key={state.selectedYearNumber}
                calendar={state.calendar}
                fiscalYearNumber={state.selectedYearNumber}
                onInteraction={handleCalendarFormInteraction}
              />
            </Box>

            {state.selectedYearNumber &&
              updatedYearsData.yearMap[state.selectedYearNumber] && (
                <Box marginVertical={theme.space_sm}>
                  <FiscalYearValidations
                    fiscalYearsData={updatedYearsData}
                    hideNoIssues
                    yearNumber={state.selectedYearNumber}
                  />
                </Box>
              )}

            <Flex justifyContent="flex-end" marginTop={theme.space_sm}>
              {state.selectedYearNumber && (
                <Button
                  marginRight={theme.space_xs}
                  onClick={() =>
                    mergeState({
                      calendar: props.calendar,
                      hasChangedCalendar: false,
                      selectedYearNumber: null,
                    })
                  }
                  type="button"
                >
                  {copyText.fiscalCalendarAddYearReset}
                </Button>
              )}

              <Button
                disabled={!state.calendar || !state.hasChangedCalendar}
                primary
                type="button"
                onClick={() =>
                  state.calendar && state.selectedYearNumber
                    ? props.onInteraction({
                        type: AddYearModal.INTERACTION_SUBMIT_BUTTON_CLICKED,
                        calendar: state.calendar,
                        yearNumber: state.selectedYearNumber,
                      })
                    : noop()
                }
              >
                {copyText.fiscalCalendarAddYearAdd}
              </Button>
            </Flex>
          </>
        )}
      </Box>
    </Modal>
  );

  function renderYearPickerDropdown() {
    if (state.selectedYearNumber || props.yearOptions !== "all") return null;

    return (
      <Box>
        <DatePicker
          placeholderText={copyText.fiscalCalendarYearPickerPlaceholder}
          selected={
            state.selectedYearNumber
              ? new Date(Number(state.selectedYearNumber), 0, 1)
              : undefined
          }
          onChange={(date) =>
            mergeState({
              selectedYearNumber: date ? format(date, "yyyy") : null,
            })
          }
          showYearPicker
          dateFormat="yyyy"
        />
      </Box>
    );
  }

  function renderYearPickerButtons() {
    if (state.selectedYearNumber || props.yearOptions === "all") return null;
    const [low, high] = props.yearOptions;
    return (
      <Box>
        <Flex justifyContent="space-between" marginBottom={theme.space_sm}>
          <Button
            onClick={() => handleClickYear(low)}
            primary
            size="small"
            type="button"
          >
            {low}
          </Button>
          <Button
            onClick={() => handleClickYear(high)}
            primary
            size="small"
            type="button"
          >
            {high}
          </Button>
        </Flex>
        <Flex justifyContent="flex-end">
          <Checkbox
            checked={state.isAutofill}
            label={copyText.fiscalCalendarAddYearAutofill}
            onChange={(e) =>
              mergeState({ isAutofill: e.currentTarget.checked })
            }
          />
        </Flex>
      </Box>
    );
  }
}

AddYearModal.INTERACTION_CLOSE_BUTTON_CLICKED =
  "AddYearModal.INTERACTION_CLOSE_BUTTON_CLICKED" as const;

AddYearModal.INTERACTION_SUBMIT_BUTTON_CLICKED =
  "AddYearModal.INTERACTION_SUBMIT_BUTTON_CLICKED" as const;

interface InteractionSubmitButtonClicked {
  type: typeof AddYearModal.INTERACTION_SUBMIT_BUTTON_CLICKED;
  calendar: EditedFiscalCalendar;
  yearNumber: string;
}

interface InteractionCloseButtonClicked {
  type: typeof AddYearModal.INTERACTION_CLOSE_BUTTON_CLICKED;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AddYearModal {
  export type Interaction =
    | InteractionCloseButtonClicked
    | InteractionSubmitButtonClicked;
}

export default AddYearModal;
