import Modal from "@/ui-lib/components/Modal";
import IconAlibabaCloud from "@/ui-lib/icons/IconAlibabaCloud";
import IconAwsCloud from "@/ui-lib/icons/IconAws";
import IconAzure from "@/ui-lib/icons/IconAzure";
import IconGCP from "@/ui-lib/icons/IconGCP";
import IconMongoDb from "@/ui-lib/icons/IconMongoDb";
import IconOracleCloud from "@/ui-lib/icons/IconOracleCloud";
import IconSnowflake from "@/ui-lib/icons/IconSnowflake";
import { useTheme } from "@emotion/react";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import {
  CloudCapability,
  CloudProviderType,
} from "@ternary/api-lib/constants/enums";
import {
  AlibabaDataIntegrationEntity,
  AWSDataIntegrationEntity,
  AzureDataIntegrationEntity,
  DataIntegrationEntity,
  GCPDataIntegrationEntity,
  OracleDataIntegrationEntity,
  SnowflakeDataIntegrationEntity,
} from "@ternary/api-lib/core/types";
import { PublicMongoDbIntegrationEntity } from "@ternary/api-lib/core/types/MongoDbIntegration";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import React from "react";
import copyText from "../copyText";
import DataStatusTooltip from "./DataStatusTooltip";

interface Props {
  integration: DataIntegrationEntity;
  onClose: () => void;
  onInteraction: (interaction: DataIntegrationDetailsModal.Interaction) => void;
}

export function DataIntegrationDetailsModal(props: Props): JSX.Element | null {
  const theme = useTheme();

  function Detail({
    label,
    validationName,
    value,
  }: {
    label: string;
    validationName?: CloudCapability;
    value: string;
  }): JSX.Element {
    const validation = props.integration.validations.find(
      (validation) => validation.name === validationName
    );

    const error = validation?.error ? validation?.error : null;

    return (
      <Box marginBottom={theme.space_md}>
        <Flex alignItems="center">
          <Text bold marginRight={theme.space_xxs}>
            {label}
          </Text>
          {error && (
            <Tooltip
              content={
                <Flex maxWidth={500} minWidth={325}>
                  {error}
                </Flex>
              }
            >
              <Icon
                icon={faCircleExclamation}
                color={theme.feedback_negative}
              />
            </Tooltip>
          )}
        </Flex>
        <Text color={error ? theme.feedback_negative : undefined}>
          {value || copyText.notSpecifiedCaption}
        </Text>
      </Box>
    );
  }

  const handleIntegrationDetailsHeader = () => {
    const billingStatus = props.integration.dataStatus.BILLING;

    let iconComponent: JSX.Element = <IconGCP size={24} />;
    let validationNames: CloudCapability | null = null;

    switch (props.integration.providerType) {
      case CloudProviderType.ALIBABA:
        iconComponent = <IconAlibabaCloud size={24} />;
        validationNames = CloudCapability.BILL_DATA_ALIBABA;
        break;
      case CloudProviderType.AWS:
        iconComponent = <IconAwsCloud size={24} />;
        validationNames = CloudCapability.BILL_DATA_AWS;
        break;
      case CloudProviderType.AZURE:
        iconComponent = <IconAzure size={24} />;
        validationNames = CloudCapability.BILL_DATA_AZURE;
        break;
      case CloudProviderType.MONGO_DB:
        iconComponent = <IconMongoDb size={24} />;
        validationNames = CloudCapability.BILL_DATA_MONGO_DB;
        break;
      case CloudProviderType.OCI:
        iconComponent = <IconOracleCloud size={24} />;
        validationNames = CloudCapability.BILL_DATA_OCI;
        break;
      case CloudProviderType.SNOWFLAKE:
        iconComponent = <IconSnowflake size={24} />;
        validationNames = CloudCapability.BILL_DATA_SNOWFLAKE;
        break;
      default:
        break;
    }

    const validation = props.integration.validations.find(
      (validation) => validation.name === validationNames
    );

    const error = validation?.error ? validation?.error : null;

    return (
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Flex
            alignItems="center"
            backgroundColor="white"
            border={`1px solid ${theme.border_color}`}
            borderRadius="8px"
            height={48}
            marginRight={theme.space_sm}
            justifyContent="center"
            width={48}
          >
            {iconComponent}
          </Flex>
          <Text appearance="h4">{props.integration.name}</Text>
          <Box marginLeft={theme.space_sm}>
            <DataStatusTooltip
              latestRefresh={billingStatus.latestRefresh}
              latestUpstream={billingStatus.latestUpstreamTimestamp}
            />
          </Box>
          {error && (
            <Box marginLeft={theme.space_xs}>
              <Tooltip width="432px" content={error} placement="bottom">
                <Icon
                  icon={faCircleExclamation}
                  color={theme.feedback_negative}
                />
              </Tooltip>
            </Box>
          )}
        </Flex>
      </Flex>
    );
  };

  const handleIntegrationDetailsBody = () => {
    switch (props.integration.providerType) {
      case CloudProviderType.ALIBABA: {
        const config = props.integration
          .config as AlibabaDataIntegrationEntity["config"];
        return (
          <Box>
            <Box marginBottom={theme.space_md}>
              <Text bold>{copyText.roleARNLabel}</Text>
              <Text overflowWrap="break-word">{config.roleARN}</Text>
            </Box>
            <Box>
              <Text bold>{copyText.curBucketAndPathLabel}</Text>
              <Text overflowWrap="break-word">
                {config.costAndUsageReports
                  ? config.costAndUsageReports
                      .map(
                        (cur) => `${cur.s3BucketName}/${cur.reportPathPrefix}`
                      )
                      .join(", ")
                  : copyText.noCURsMessage}
              </Text>
            </Box>
          </Box>
        );
      }
      case CloudProviderType.AWS: {
        const config = props.integration
          .config as AWSDataIntegrationEntity["config"];
        return (
          <Box>
            <Box marginBottom={theme.space_md}>
              <Text bold>{copyText.roleARNLabel}</Text>
              <Text overflowWrap="break-word">{config.roleARN}</Text>
            </Box>
            {Boolean(config.monitoringRoleARN) && (
              <Box marginBottom={theme.space_md}>
                <Text bold>{copyText.monitoringRoleARNLabel}</Text>
                <Text overflowWrap="break-word">
                  {config.monitoringRoleARN}
                </Text>
              </Box>
            )}
            <Box>
              <Text bold>{copyText.curBucketAndPathLabel}</Text>
              <Text overflowWrap="break-word">
                {config.costAndUsageReports
                  ? config.costAndUsageReports
                      .map(
                        (cur) => `${cur.s3BucketName}/${cur.reportPathPrefix}`
                      )
                      .join(", ")
                  : copyText.noCURsMessage}
              </Text>
            </Box>
          </Box>
        );
      }
      case CloudProviderType.AZURE: {
        const config = props.integration
          .config as AzureDataIntegrationEntity["config"];
        return (
          <Box>
            <Box marginBottom={theme.space_md}>
              <Text bold>{copyText.appIDLabel}</Text>
              <Text>{config.appID}</Text>
            </Box>
            <Box marginBottom={theme.space_md}>
              <Text bold>{copyText.cloudAzureAttributeDirectory}</Text>
              <Text>{config.directoryID}</Text>
            </Box>
            <Box marginBottom={theme.space_md}>
              <Text bold>{copyText.cloudAzureAttributeAzureType}</Text>
              <Text>{copyText[`azureCloudFormTypeLabel_${config.type}`]}</Text>
            </Box>
            <Box>
              {config.billingExports
                ? config.billingExports.map((cur, index) => {
                    return (
                      <Box key={index}>
                        <Box>
                          <Text bold marginTop={theme.space_sm}>
                            {copyText.azureCloudCardServiceUrl}
                          </Text>
                          <Text overflowWrap="break-word">{`${cur.serviceURL}/${cur.storageContainer}/${cur.storagePrefix}`}</Text>
                        </Box>
                        {cur.startDate && (
                          <>
                            <Text bold marginTop={theme.space_sm}>
                              {copyText.azureCloudCardDateRange}
                            </Text>
                            <Flex width="25%" justifyContent="space-between">
                              <Text>
                                {`${copyText.azureCloudCardDateRangeStartLabel} ${
                                  cur.startDate
                                    ? formatDate(
                                        new Date(cur.startDate),
                                        "MM-dd-yyyy"
                                      )
                                    : ""
                                }`}
                              </Text>
                              <Text>
                                {`${copyText.azureCloudCardDateRangeEndLabel} ${
                                  cur.endDate
                                    ? formatDate(
                                        new Date(cur.endDate),
                                        "MM-dd-yyyy"
                                      )
                                    : ""
                                }`}
                              </Text>
                            </Flex>
                          </>
                        )}
                      </Box>
                    );
                  })
                : copyText.noCURsMessage}
            </Box>
          </Box>
        );
      }
      case CloudProviderType.OCI: {
        const config = props.integration
          .config as OracleDataIntegrationEntity["config"];
        return (
          <Box>
            <Box marginBottom={theme.space_md}>
              <Text bold>{copyText.cloudOCIAttributeTenancy}</Text>
              <Text>{config.tenancyOCID}</Text>
            </Box>
            <Box marginBottom={theme.space_md}>
              <Text bold>{copyText.cloudOCIAttributeUser}</Text>
              <Text>{config.userOCID}</Text>
            </Box>
            <Box marginBottom={theme.space_md}>
              <Text bold>{copyText.cloudOCIAttributeRegion}</Text>
              <Text>{config.region}</Text>
            </Box>
            <Box marginBottom={theme.space_md}>
              <Text bold>{copyText.cloudOCIAttributeStorageBucket}</Text>
              <Text>{config.storageBucket ?? ""}</Text>
            </Box>
            <Box marginBottom={theme.space_md}>
              <Text bold>{copyText.cloudOCIAttributeStorageNamespace}</Text>
              <Text>{config.storageNamespace ?? ""}</Text>
            </Box>
          </Box>
        );
      }
      case CloudProviderType.MONGO_DB: {
        const config = props.integration
          .config as PublicMongoDbIntegrationEntity;
        return (
          <Box>
            <Box marginBottom={theme.space_md}>
              <Text bold>{copyText.mongoDbIntegrationCardOrganizationID}</Text>
              <Text>{config.organizationID}</Text>
            </Box>
          </Box>
        );
      }
      case CloudProviderType.SNOWFLAKE: {
        const config = props.integration
          .config as SnowflakeDataIntegrationEntity["config"];
        return (
          <Box>
            <Box marginBottom={theme.space_md}>
              <Text bold>{copyText.snowflakeIntegrationCardOrgName}</Text>
              <Text>{config.orgName}</Text>
            </Box>

            <Box marginBottom={theme.space_md}>
              <Text bold>
                {copyText.snowflakeIntegrationCardConnectedAccounts}
              </Text>
              <Text>{config.accounts?.length}</Text>
            </Box>
          </Box>
        );
      }
      default: {
        const config = props.integration
          .config as GCPDataIntegrationEntity["config"];
        return (
          <Box>
            <Box marginBottom={theme.space_md}>
              <Text bold>{copyText.billingAccountIDLabel}</Text>
              <Text>{config.billingAccountID}</Text>
            </Box>
            <Detail
              label={copyText.billingExportTableIDLabel}
              validationName={CloudCapability.BILL_DATA}
              value={config.billingExportSource?.tableID ?? ""}
            />
            <Detail
              label={copyText.rootResourceIDLabel}
              validationName={CloudCapability.INVENTORY_EXPORT}
              value={config.rootElement ?? ""}
            />
            <Detail
              label={copyText.monitoredBigQueryProjectsLabel}
              validationName={
                config.bigQueryMonitoring
                  ? CloudCapability.BIGQUERY_DATA
                  : undefined
              }
              value={
                config.bigQueryMonitoring
                  ? config.bigQueryMonitoring
                      .map((config) => config.projectID)
                      .join(", ")
                  : copyText.noBQProjectsMessage
              }
            />
          </Box>
        );
      }
    }
  };

  return (
    <Modal isOpen showCloseButton onClose={props.onClose} width="640px">
      <Modal.Header>{handleIntegrationDetailsHeader()}</Modal.Header>
      <Modal.Body
        style={{
          overflowY: "auto",
          flex: 1,
        }}
      >
        {handleIntegrationDetailsBody()}
      </Modal.Body>
      <Modal.Footer>
        <Flex width="100%" justifyContent="space-between">
          <Button
            type="button"
            variant="danger"
            onClick={() =>
              props.onInteraction({
                type: DataIntegrationDetailsModal.INTERACTION_DELETE_BUTTON_CLICKED,
                integrationID: props.integration.id,
              })
            }
          >
            {copyText.actionDelete}
          </Button>
          <Box>
            <Button
              marginRight={theme.space_sm}
              secondary
              type="button"
              onClick={() =>
                props.onInteraction({
                  type: DataIntegrationDetailsModal.INTERACTION_REFRESH_BUTTON_CLICKED,
                  integrationID: props.integration.id,
                })
              }
            >
              {copyText.actionMenuItemValidateCloud}
            </Button>
            <Button
              primary
              type="button"
              onClick={() =>
                props.onInteraction({
                  type: DataIntegrationDetailsModal.INTERACTION_EDIT_BUTTON_CLICKED,
                  integrationID: props.integration.id,
                  providerType: props.integration.providerType,
                })
              }
            >
              {copyText.actionMenuItemEditCloud}
            </Button>
          </Box>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}

DataIntegrationDetailsModal.INTERACTION_EDIT_BUTTON_CLICKED =
  `DataIntegrationDetailsModal.INTERACTION_EDIT_BUTTON_CLICKED` as const;

DataIntegrationDetailsModal.INTERACTION_REFRESH_BUTTON_CLICKED =
  `DataIntegrationDetailsModal.INTERACTION_REFRESH_BUTTON_CLICKED` as const;

DataIntegrationDetailsModal.INTERACTION_DELETE_BUTTON_CLICKED =
  `DataIntegrationDetailsModal.INTERACTION_DELETE_BUTTON_CLICKED` as const;

interface InteractionEditButtonClicked {
  type: typeof DataIntegrationDetailsModal.INTERACTION_EDIT_BUTTON_CLICKED;
  integrationID: string;
  providerType: CloudProviderType;
}

interface InteractionRefreshButtonClicked {
  type: typeof DataIntegrationDetailsModal.INTERACTION_REFRESH_BUTTON_CLICKED;
  integrationID: string;
}
interface InteractionDeleteButtonClicked {
  type: typeof DataIntegrationDetailsModal.INTERACTION_DELETE_BUTTON_CLICKED;
  integrationID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DataIntegrationDetailsModal {
  export type Interaction =
    | InteractionEditButtonClicked
    | InteractionRefreshButtonClicked
    | InteractionDeleteButtonClicked;
}

export default DataIntegrationDetailsModal;
