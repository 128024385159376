import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faCheckDouble } from "@fortawesome/pro-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Divider from "@ternary/api-lib/ui-lib/components/Divider";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { Theme } from "@ternary/api-lib/ui-lib/theme/default";
import palette from "@ternary/api-lib/ui-lib/theme/palette";
import Icon from "@ternary/web-ui-lib/components/Icon";
import React from "react";
import { createPortal } from "react-dom";
import copyText from "../copyText";

const batchModalTheme = (baseTheme: Theme) => ({
  Batch_Modal_backgroundColor: palette.offBlack,
  Batch_Modal_backgroundColor_hover: baseTheme.secondary_color_background_hover,
  Batch_Modal_fontSize_jumbo: baseTheme.h3_fontSize,
  Batch_Modal_fontSize_large: baseTheme.fontSize_base,
  Batch_Modal_fontSize_medium: baseTheme.fontSize_ui,
  Batch_Modal_fontSize_small: baseTheme.fontSize_ui,
  Batch_Modal_fontSize_tiny: baseTheme.h6_fontSize,
  Batch_Modal_textColor: baseTheme.text_color_inverse,
  ...baseTheme,
});

const POSITION = {
  left: "left",
  center: "center",
  right: "right",
};

type Position = keyof typeof POSITION;

const Overlay = styled("div")<{ placement?: Position; isOpen?: boolean }>(({
  placement,
  isOpen,
  theme,
}) => {
  return {
    alignItems: (() => {
      switch (placement) {
        case POSITION.left:
          return "flex-start";
        case POSITION.right:
          return "flex-end";
        default:
          return "center";
      }
    })(),
    bottom: 0,
    display: isOpen ? "flex" : "none",
    justifyContent: (() => {
      switch (placement) {
        case POSITION["top-start"]:
          return "flex-start";
        default:
          return "center";
      }
    })(),
    position: "sticky",
    right: 0,
    zIndex: theme.zIndex_1600,
  };
});

const Modal = styled("div")(({ theme }) => ({
  display: "flex",
  position: "fixed",
  bottom: 0,
  zIndex: theme.zIndex_1600, // To get above zendesk button
}));

const Content = styled("div")<{
  minWidth?: number | string;
  width?: number | string;
}>(({ minWidth, theme, width }) => ({
  backgroundColor: "#1E2328",
  borderRadius: theme.borderRadius_2,
  display: "inline-flex",
  margin: theme.space_xxl,
  alignItems: "center",
  minWidth: minWidth ?? "400px",
  position: "relative",
  padding: `${theme.space_md} ${theme.space_lg}`,
  ...(width && { width }),
}));

const CloseButton = styled(Button)(({ theme: baseTheme }) => {
  const theme = batchModalTheme(baseTheme);
  return {
    backgroundColor: theme.Batch_Modal_backgroundColor,
    padding: 0,
    ":hover": {
      backgroundColor: theme.Batch_Modal_backgroundColor,
    },
    ":focus": {
      boxShadow: "none",
    },
  };
});

interface Props {
  allSelected: boolean;
  isOpen: boolean;
  interactionLabel: string;
  title: string;
  placement?: Position;
  onClose: () => void;
  onSelectAll: () => void;
  onInteraction: () => void;
}

export default function BatchModal(props: Props): JSX.Element {
  const baseTheme = useTheme();
  const theme = batchModalTheme(baseTheme);
  const batchModal = (
    <Overlay isOpen={props.isOpen} placement={props.placement ?? "center"}>
      <Modal>
        <Content>
          <CloseButton
            iconStart={<Icon size="xl" color={palette.white} icon={faTimes} />}
            onClick={props.onClose}
          />
          <Text color={palette.white} fontSize="14px" marginRight="16px">
            {props.title}
          </Text>
          <Divider
            margin={0}
            direction="vertical"
            color={palette.grey[90]}
            minHeight="24px"
          />
          <Button
            disabled={props.allSelected}
            style={{ backgroundColor: theme.Batch_Modal_backgroundColor }}
            iconStart={<Icon color={palette.white} icon={faCheckDouble} />}
            onClick={props.onSelectAll}
            size="small"
          >
            <Text color={palette.white} fontSize="14px">
              {copyText.defaultSelectAllPlaceholder}
            </Text>
          </Button>
          <Button marginLeft={"40px"} primary onClick={props.onInteraction}>
            <Text color={palette.white} fontSize="14px">
              {props.interactionLabel}
            </Text>
          </Button>
        </Content>
      </Modal>
    </Overlay>
  );
  return createPortal(batchModal, document.body);
}
