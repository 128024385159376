import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import { format } from "date-fns";
import React from "react";
import { CSVLink } from "react-csv";
import Chip from "../../../../ui-lib/components/Chip";
import copyText from "../../copyText";
import { AWSCommitmentInventoryFilter } from "../types";

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

type Props = {
  csvData: CSVData;
  filters: AWSCommitmentInventoryFilter;
  onRemoveFilter: (filterKey: keyof AWSCommitmentInventoryFilter) => void;
};

export default function AWSCommittedUseVisibilityTableControls(props: Props) {
  return (
    <Flex alignItems="center" minHeight={30}>
      <Flex flex="1 0 0">
        {props.filters.type !== null ? (
          <Chip
            label={copyText.awsTableFilters_0_type}
            text={props.filters.type}
            onClick={() => props.onRemoveFilter("type")}
          />
        ) : null}
        {props.filters.family !== null ? (
          <Chip
            label={copyText.awsTableFilters_1_family}
            text={props.filters.family}
            onClick={() => props.onRemoveFilter("family")}
          />
        ) : null}
        {props.filters.instanceSize !== null ? (
          <Chip
            label={copyText.awsTableFilters_2_instanceSize}
            text={props.filters.instanceSize}
            onClick={() => props.onRemoveFilter("instanceSize")}
          />
        ) : null}
        {props.filters.region !== null ? (
          <Chip
            label={copyText.awsTableFilters_3_region}
            text={props.filters.region}
            onClick={() => props.onRemoveFilter("region")}
          />
        ) : null}
        {props.filters.operatingSystem !== null ? (
          <Chip
            label={copyText.awsTableFilters_4_operatingSystem}
            text={props.filters.operatingSystem}
            onClick={() => props.onRemoveFilter("operatingSystem")}
          />
        ) : null}
        {props.filters.commitmentId !== null ? (
          <Chip
            label={copyText.awsTableFilters_5_commitmentId}
            text={props.filters.commitmentId}
            onClick={() => props.onRemoveFilter("commitmentId")}
          />
        ) : null}
        {props.filters.status !== null ? (
          <Chip
            label={copyText.awsTableFilters_6_status}
            text={props.filters.status}
            onClick={() => props.onRemoveFilter("status")}
          />
        ) : null}
        {props.filters.serviceDescription !== null ? (
          <Chip
            label={copyText.awsTableFilters_7_serviceDescription}
            text={props.filters.serviceDescription}
            onClick={() => props.onRemoveFilter("serviceDescription")}
          />
        ) : null}
      </Flex>

      <Box>
        <CSVLink
          data={props.csvData.rows}
          headers={props.csvData.headers}
          filename={`AWS-Commitment-Inventory-${format(
            new Date(),
            "MM-dd-yyyy"
          )}`}
        >
          <Button
            iconStart={<Icon color="inherit" icon={faFileExport} />}
            secondary
            size="small"
          >
            {copyText.exportButtonLabel}
          </Button>
        </CSVLink>
      </Box>
    </Flex>
  );
}
