import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../../api/core/CoreAPIClient";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import queryKeys from "./queryKeys";

type MspBillingInfoEntity = Awaited<
  ReturnType<CoreAPIClient["getMspBillingInfoByTenantID"]>
>;

export function useGetMspBillingInfoByTenantID(
  tenantID: string,
  options?: UseQueryOptions<MspBillingInfoEntity, CoreAPIClientError>
): UseQueryResult<MspBillingInfoEntity, CoreAPIClientError> {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: queryKeys.billingInfo(tenantID),
    queryFn: () => client.getMspBillingInfoByTenantID(tenantID),
    retry: (failureCount, error) => {
      // No need to retry if user hasn't set up integration yet.
      if (failureCount === 0 && error.reason === "NOT_FOUND") {
        return false;
      }

      // If different failure reason we still want to retry 2 more times.
      return failureCount > -1 && failureCount < 2;
    },
    ...options,
  });
}
