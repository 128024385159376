import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import UError from "unilib-error";
import useBuildDataQuery from "../../../api/analytics/utils/useDataQuery";
import { ANALYTICS_QUERY_GC_TIME } from "../../../constants";
import { useAnalyticsApiClient } from "../../../context/AnalyticsQueryLoaderProvider";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import {
  mspAlertRollupDimensions,
  mspAlertRollupMeasures,
} from "../mspAlertRollupSchema";

type MspCostAlertDataRow = {
  tenantId: string;
  totalAlertEvents: number;
  totalDelta: number;
};

interface Params {
  dateRange: Date[];
  parentTenantID: string;
  preAggFilters: QueryFilter[];
}

type Options = UseQueryOptions<MspCostAlertDataRow[], UError>;
type Result = UseQueryResult<MspCostAlertDataRow[], UError>;

export default function useGetMspCostAlertData(
  params: Params,
  options?: Options
): Result {
  const client = useAnalyticsApiClient();

  const { parentTenantID, ...restParams } = params;

  const [_, query] = useBuildDataQuery({
    ...restParams,
    dataSource: DataSource.MSP_ALERT_ROLLUP,
    dimensions: [mspAlertRollupDimensions.tenantID],
    measures: Object.values(mspAlertRollupMeasures),
  });

  return useQuery({
    queryKey: ["mspCostAlertData", params],
    queryFn: async () => {
      const result = await client.loadData<MspCostAlertDataRow>(
        parentTenantID,
        query
      );

      return result.response;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
