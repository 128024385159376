import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React from "react";
import copyText from "../copyText";

interface Props {
  canSubmit: boolean;
  isLoading: boolean;
  searchTenant: string | null;
  onInteraction: (
    interaction: MspTenantSettingsTableControls.Interaction
  ) => void;
}
export function MspTenantSettingsTableControls(props: Props) {
  const theme = useTheme();
  if (props.isLoading) {
    return <EmptyPlaceholder loading={props.isLoading} width={625} />;
  }
  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Text fontSize={theme.h4_fontSize}>{copyText.settingsTabLabel}</Text>
      <Flex marginTop={theme.space_xxs}>
        <Box marginRight={theme.space_sm} width={300}>
          <TextInput
            iconEnd={
              <Icon color={theme.text_color_secondary} icon={faSearch} />
            }
            onChange={(event) =>
              props.onInteraction({
                type: MspTenantSettingsTableControls.INTERACTION_SEARCH_TEXT_UPDATED,
                searchText: event.target.value,
              })
            }
            placeholder={copyText.searchInputPlaceholder}
            value={props.searchTenant ?? ""}
          />
        </Box>
        <Button
          marginRight={theme.space_sm}
          onClick={() =>
            props.onInteraction({
              type: MspTenantSettingsTableControls.INTERACTION_CUSTOM_SETTINGS_BUTTON_CLICKED,
            })
          }
        >
          {copyText.settingsCustomSettingButtonLabel}
        </Button>
        <Button
          disabled={props.canSubmit}
          marginRight={theme.space_sm}
          onClick={() =>
            props.onInteraction({
              type: MspTenantSettingsTableControls.INTERACTION_CANCEL_BUTTON_CLICKED,
            })
          }
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={props.canSubmit}
          primary
          onClick={() =>
            props.onInteraction({
              type: MspTenantSettingsTableControls.INTERACTION_SUBMIT_BUTTON_CLICKED,
            })
          }
        >
          {copyText.submitButtonLabel}
        </Button>
      </Flex>
    </Flex>
  );
}

MspTenantSettingsTableControls.INTERACTION_SEARCH_TEXT_UPDATED =
  "MspTenantSettingsTableControls.INTERACTION_SEARCH_TEXT_UPDATED" as const;

MspTenantSettingsTableControls.INTERACTION_CANCEL_BUTTON_CLICKED =
  `MspTenantSettingsTableControls.INTERACTION_CANCEL_BUTTON_CLICKED` as const;

MspTenantSettingsTableControls.INTERACTION_CUSTOM_SETTINGS_BUTTON_CLICKED =
  `MspTenantSettingsTableControls.INTERACTION_CUSTOM_SETTINGS_BUTTON_CLICKED` as const;

MspTenantSettingsTableControls.INTERACTION_SUBMIT_BUTTON_CLICKED =
  `MspTenantSettingsTableControls.INTERACTION_SUBMIT_BUTTON_CLICKED` as const;

interface InteractionSearchTextUpdated {
  type: typeof MspTenantSettingsTableControls.INTERACTION_SEARCH_TEXT_UPDATED;
  searchText: string;
}

interface InteractionCancelButtonClicked {
  type: typeof MspTenantSettingsTableControls.INTERACTION_CANCEL_BUTTON_CLICKED;
}

interface InteractionCustomSettingButtonClicked {
  type: typeof MspTenantSettingsTableControls.INTERACTION_CUSTOM_SETTINGS_BUTTON_CLICKED;
}
interface InteractionSubmitButtonClicked {
  type: typeof MspTenantSettingsTableControls.INTERACTION_SUBMIT_BUTTON_CLICKED;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MspTenantSettingsTableControls {
  export type Interaction =
    | InteractionSearchTextUpdated
    | InteractionCancelButtonClicked
    | InteractionCustomSettingButtonClicked
    | InteractionSubmitButtonClicked;
}

export default MspTenantSettingsTableControls;
