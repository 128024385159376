import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { QueryClient, useQuery } from "@tanstack/react-query";
import DataQuery from "@ternary/api-lib/analytics/api/DataQuery";
import {
  Dependencies,
  getReportData,
  GetReportDataResult,
} from "@ternary/api-lib/analytics/api/getReportData";
import {
  ChartType,
  DataSource,
  DurationType,
} from "@ternary/api-lib/analytics/enums";
import { RawData } from "@ternary/api-lib/analytics/types";
import { getDataSourceFilters } from "@ternary/api-lib/analytics/utils/ReportUtils";
import { startOfDay } from "date-fns";
import { keyBy } from "lodash";
import registry from "unilib-registry/instance";
import AnalyticsApiClient from "../../../api/analytics/AnalyticsApiClient";
import { useAnalyticsApiClient } from "../../../context/AnalyticsQueryLoaderProvider";
import { useFilterStore } from "../../../context/FilterStoreProvider";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import copyText from "../copyText";
import { ReportDataConfig } from "../types";
import useGetCustomMetricsByTenantID from "./useGetCustomMetricsByTenantID";
import useGetDatadogIntegrationByTenantID from "./useGetDatadogIntegrationByTenantID";

type ReportDataResult = {
  comparison: GetReportDataResult;
  comparisonGroupingTotals: GetReportDataResult;
  main: GetReportDataResult;
  mainGroupingTotals: GetReportDataResult;
};

type Options = UseQueryOptions<ReportDataResult, CoreAPIClientError>;
type Result = UseQueryResult<ReportDataResult, CoreAPIClientError>;

const REPORT_STALE_TIME_MS = 2 * 60 * 1000;

const defaultReport: ReportDataConfig = {
  id: "",
  compareEndDate: null,
  compareStartDate: null,
  compareDurationType: null,
  chartType: ChartType.STACKED_AREA,
  dataSource: DataSource.BILLING,
  dimensions: [],
  durationType: DurationType.LAST_THIRTY_DAYS,
  endDate: null,
  excludedCreditTypes: [],
  excludeNegativeNumbers: false,
  excludeOther: false,
  filters: [],
  formula: null,
  formulaAlias: null,
  hiddenMeasures: [],
  invoiceMonthEnd: null,
  invoiceMonthStart: null,
  isCumulative: false,
  isFormulaHidden: false,
  isMetricHidden: false,
  limit: null,
  measures: [],
  metric: null,
  metricAggregate: null,
  metricFilters: [],
  nLookback: null,
  reverse: false,
  sortRule: null,
  startDate: null,
  timeGranularity: null,
  xAxisKey: null,
};

const defaultCustomMetrics = [];

const defaultDataResult: GetReportDataResult = {
  metaData: [],
  report: defaultReport,
  result: {
    data: [],
    isLargeDataSet: false,
  },
  query: new DataQuery({
    dataSource: DataSource.BILLING,
    dateRange: [startOfDay(new Date()), startOfDay(new Date())],
  }),
};

export const defaultReportDataResult: ReportDataResult = {
  comparison: { ...defaultDataResult },
  comparisonGroupingTotals: { ...defaultDataResult },
  main: { ...defaultDataResult },
  mainGroupingTotals: { ...defaultDataResult },
};

export default function useGetReportData(
  report?: ReportDataConfig,
  options?: Options
): Result {
  const authenticatedUser = useAuthenticatedUser();
  const client = useAnalyticsApiClient();
  const filterStore = useFilterStore();

  const hasReport = !!report;

  report ??= defaultReport;

  const globalFilters = getDataSourceFilters(
    filterStore.queryFilters,
    report.dataSource
  );

  const { data: integration, isFetching: isLoadingDatadogIntegration } =
    useGetDatadogIntegrationByTenantID(authenticatedUser.tenant.fsDocID);

  const {
    data: customMetrics = defaultCustomMetrics,
    isFetching: isLoadingCustomMetrics,
  } = useGetCustomMetricsByTenantID(authenticatedUser.tenant.id);

  const customMetricMap = keyBy(customMetrics, "name");

  const fiscalPeriodMap =
    authenticatedUser.tenant.fiscalCalendar?.periods ?? {};

  const isFiscalMode = authenticatedUser.settings.fiscalMode;

  const integrationMetricMap = {
    ...customMetricMap,
    ...integration?.metrics,
  };

  const queryClient = registry.get<QueryClient>("ReactQueryClient");

  const dependenciesForCache: Omit<Dependencies, "client"> = {
    tenantID: authenticatedUser.tenant.id,
    fiscalPeriodMap,
    globalFilters,
    integrationMetricMap,
    isFiscalMode,
  };

  const reportForCache = {
    ...report,

    // NOTE: Changes between time-series chart types should be ignored by the cache.
    chartType:
      report.chartType === ChartType.TABLE ||
      report.chartType === ChartType.PIE ||
      report.chartType === ChartType.KPI
        ? report.chartType
        : "TIME_SERIES",

    // NOTE: Changes in these fields should not require refetching data
    id: null,
    tenantID: null,
    createdAt: null,
    createdBy: null,
    name: null,
    scope: null,
    type: null,
    updatedAt: null,
    updatedBy: null,
  };

  const _options = options ? options : {};

  const { enabled: extraEnabled, ...restOptions } = _options;

  const defaultEnabled =
    !isLoadingCustomMetrics && !isLoadingDatadogIntegration && hasReport;

  const enabled = extraEnabled
    ? extraEnabled && defaultEnabled
    : defaultEnabled;

  const dataQueryCacheClient = getDataQueryCacheClient(queryClient, client);

  return useQuery({
    queryFn: async (): Promise<ReportDataResult> => {
      const dependencies: Dependencies = {
        client: dataQueryCacheClient,
        fiscalPeriodMap: dependenciesForCache.fiscalPeriodMap,
        globalFilters,
        integrationMetricMap: dependenciesForCache.integrationMetricMap,
        isFiscalMode: dependenciesForCache.isFiscalMode,
        tenantID: dependenciesForCache.tenantID,
      };

      const { comparison, comparisonGroupingTotals, main, mainGroupingTotals } =
        await getReportData({ dependencies, report });

      return {
        comparison,
        comparisonGroupingTotals,
        main,
        mainGroupingTotals,
      };
    },
    queryKey: [
      "reports",
      "data",
      {
        ...dependenciesForCache,
        report: reportForCache,
      },
    ],
    enabled,
    meta: { errorMessage: copyText.errorLoadingReportData },
    // NOTE: Don't refetch on new hook instances for X milliseconds
    staleTime: REPORT_STALE_TIME_MS,
    ...restOptions,
  });
}

const getDataQueryCacheClient = (
  queryClient: QueryClient,
  analyticsClient: AnalyticsApiClient
): AnalyticsApiClient => {
  return {
    async load(query) {
      const data = await queryClient.fetchQuery({
        queryFn: () => analyticsClient.load(query),
        queryKey: ["cubeshim", { ...query }],
        staleTime: REPORT_STALE_TIME_MS,
      });

      return data;
    },
    async loadData<TData extends RawData = RawData>(tenantID, query) {
      const result = await queryClient.fetchQuery({
        queryFn: () => analyticsClient.loadData<TData>(tenantID, query),
        queryKey: ["datalligator", tenantID, { ...query }],
        staleTime: REPORT_STALE_TIME_MS,
      });

      return result;
    },
  } as AnalyticsApiClient;
};
