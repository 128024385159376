import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { BudgetsCurrentDailyGraph } from "@ternary/api-lib/analytics/ui/BudgetsCurrentDailyGraph";
import BudgetsHistoricalDailyGraph from "@ternary/api-lib/analytics/ui/BudgetsHistoricalDailyGraph";
import { formatCurrencyRounded } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import { BudgetSpendEntity } from "../../../api/analytics/types";
import Modal from "../../../ui-lib/components/Modal";
import getCn from "../../../utils/getCn";
import copyText from "../copyText";
import { Budget } from "../types";
import { getAmountFromBudget } from "../utils";
import BudgetsHistoricalMonthlyGraph from "./BudgetsHistoricalMonthlyGraph";
import { renderVariance } from "./BudgetsTable";

const CHART_HEIGHT = 250;

const COMPONENT_NAME = "BudgetsReportSummaryModal";
const cn = getCn(COMPONENT_NAME);

const StyledFlex = styled(Flex)`
  flex-grow: 1;
  color: ${(props) => props.theme.text_color};

  .${cn("item")} {
    display: flex;
    font-size: 1.25rem;
  }

  label {
    display: flex;
    align-items: center;
    font-weight: bold;
    margin: 0;
    margin-right: 0.5rem;
    font-size: 0.8rem;

    ::after {
      content: ":";
    }
  }
`;

interface Props {
  budget: Budget;
  dailySpend: BudgetSpendEntity[];
  loading: boolean;
  show: boolean;
  onHide: () => void;
}

export default function BudgetsReportSummaryModal(props: Props): JSX.Element {
  const theme = useTheme();

  const amount = props.budget ? (getAmountFromBudget(props.budget) ?? 0) : 0;

  const status = props.budget && props.budget.status;

  return (
    <Modal isOpen={props.show} showCloseButton onClose={props.onHide}>
      <Modal.Header>
        {props.budget && (
          <StyledFlex alignItems="center" justifyContent="space-between">
            <div>
              <div>
                <label>{copyText.budgetsTableHeaderName}</label>
                <Text
                  fontSize={theme.h4_fontSize}
                  fontWeight={theme.h4_fontWeight}
                >
                  {props.budget.name}
                </Text>
              </div>
            </div>
            <div>
              <div className={cn("item")}>
                <label>{copyText.budgetsTableHeaderAmount}</label>
                {formatCurrencyRounded({ number: amount })}
              </div>
              <div className={cn("item")}>
                <label>{copyText.budgetsTableHeaderActual}</label>
                {props.budget.status
                  ? formatCurrencyRounded({
                      number: props.budget.status.actual,
                    })
                  : copyText.statusPending}
              </div>
            </div>
            <div>
              <div className={cn("item")}>
                <label>{copyText.budgetsTableHeaderProjected}</label>
                {props.budget.status
                  ? formatCurrencyRounded({
                      number: props.budget.status.forecasted,
                    })
                  : copyText.statusPending}
              </div>
              <div className={cn("item")}>
                <label>{copyText.budgetsTableHeaderVariance}</label>
                {amount === 0
                  ? "(-)"
                  : renderVariance({
                      absolute: (status ? status.forecasted : 0) - amount,
                      percent:
                        (((status ? status.forecasted : 0) - amount) / amount) *
                        100,
                      theme,
                    })}
              </div>
            </div>
          </StyledFlex>
        )}
      </Modal.Header>
      <Modal.Body>
        <Box minWidth={900}>
          <Box marginBottom={theme.space_md} minHeight="250px" width="100%">
            <BudgetsCurrentDailyGraph
              budget={props.budget}
              data={props.dailySpend}
              height={CHART_HEIGHT - 50}
              loading={props.loading}
            />
          </Box>
          <Flex justifyContent="space-between">
            <Box minHeight="300px" width="calc(50% - 0.5rem)">
              <BudgetsHistoricalDailyGraph
                budget={props.budget}
                data={props.dailySpend}
                height={CHART_HEIGHT}
                loading={props.loading}
              />
            </Box>
            <Box minHeight="300px" width="calc(50% - 0.5rem)">
              <BudgetsHistoricalMonthlyGraph
                height={CHART_HEIGHT}
                budget={props.budget}
                dailySpend={props.dailySpend}
                loading={props.loading}
              />
            </Box>
          </Flex>
        </Box>
      </Modal.Body>
    </Modal>
  );
}
