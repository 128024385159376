import { buildCubeQuery } from "@/api/analytics/utils";
import { validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { AWSComputeInstance, AWSComputeInstanceStruct } from "../types";

export interface Params {
  dateRange: Date[];
  queryFilters: QueryFilter[];
}

export default function useGetAWSComputeInstances(
  params: Params,
  options?: UseQueryOptions<AWSComputeInstance[], UError>
): UseQueryResult<AWSComputeInstance[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["awsComputeInstances", params],
    queryFn: async () => {
      const dimensions = [
        "family",
        "familyType",
        "instanceId",
        "instanceType",
        "lineItemUsageAccountId",
        "operatingSystem",
        "region",
      ];

      const measures = [
        "cpuUtilization",
        "diskReadBytes",
        "diskWriteBytes",
        "instanceUnits",
        "networkInBytes",
        "networkOutBytes",
        "productMemoryBytes",
        "productVCPU",
        "totalCost",
      ];

      const result = await client.load(
        buildCubeQuery({
          dataSource: DataSource.AWS_COMPUTE_INSTANCES,
          dateRange: params.dateRange,
          dimensions,
          measures,
          queryFilters: params.queryFilters,
        })
      );

      const instances = result.map((datum): AWSComputeInstance => {
        const [error, validData] = validate(datum, AWSComputeInstanceStruct);

        if (error) {
          throw new UError("INVALID_AWS_COMPUTE_INSTANCE_TABLE_DATUM", {
            context: { error, result: datum },
          });
        }

        return {
          // DIMENSIONS
          family: validData.family ?? "",
          familyType: validData.familyType ?? "",
          instanceId: validData.instanceId ?? "",
          instanceType: validData.instanceType ?? "",
          lineItemUsageAccountId: validData.lineItemUsageAccountId ?? "",
          operatingSystem: validData.operatingSystem ?? "",
          region: validData.region ?? "",

          // MEASURES
          cpuUtilization: validData.cpuUtilization ?? 0,
          diskReadBytes: validData.diskReadBytes ?? 0,
          diskWriteBytes: validData.diskWriteBytes ?? 0,
          instanceUnits: validData.instanceUnits ?? 0,
          networkInBytes: validData.networkInBytes ?? 0,
          networkOutBytes: validData.networkOutBytes ?? 0,
          productMemoryBytes: validData.productMemoryBytes ?? 0,
          productVCPU: validData.productVCPU ?? 0,
          totalCost: validData.totalCost ?? 0,
        };
      });

      return instances;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
