import { useTheme } from "@emotion/react";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import { FeatureFlag } from "@ternary/api-lib/constants/feature-flags";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { keyBy } from "lodash";
import React, { useMemo } from "react";
import Modal from "../../../ui-lib/components/Modal";
import SelectDropdown, {
  Option,
} from "../../../ui-lib/components/SelectDropdown";
import { getFullName } from "../../../utils/UserUtils";
import copyText from "../copyText";

interface FeatureFlagDatum {
  flag: FeatureFlag;
  createdByID: string;
  createdAt: string;
}

type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

type TableData = {
  flag: FeatureFlag;
  createdBy: string;
  createdAt: string;
};
interface Props {
  existingFeatureFlags: FeatureFlagDatum[];
  isLoading: boolean;
  tenantName?: string;
  users: User[];
  onInteraction: (interaction: FeatureFlagModal.Interaction) => void;
}

export function FeatureFlagModal(props: Props) {
  const theme = useTheme();
  const columnHelper = createColumnHelper<TableData>();

  function handleClose() {
    props.onInteraction({
      type: FeatureFlagModal.INTERACTION_CANCEL_BUTTON_CLICKED,
    });
  }

  const featureFlagOptions = Object.values(FeatureFlag).reduce(
    (accum: Option[], flag) => {
      if (
        !props.existingFeatureFlags.some(
          (existingFlag) => existingFlag.flag === flag
        )
      ) {
        accum.push({
          label: copyText[`featureFlagOptionLabel_${flag}`] ?? flag,
          value: flag,
        });
      }
      return accum;
    },
    []
  );

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("flag", {
        header: copyText.featureFlagTableHeaderFlag,
        cell: (cell) =>
          copyText[`featureFlagOptionLabel_${cell.getValue()}`] ??
          cell.getValue(),
      }),
      columnHelper.accessor("createdBy", {
        header: copyText.featureFlagTableHeaderCreatedBy,
      }),
      columnHelper.accessor("createdAt", {
        header: copyText.featureFlagTableHeaderCreatedAt,
        cell: (cell) => formatDate(new Date(cell.getValue()), "MM/dd/yyyy"),
      }),
      columnHelper.display({
        id: "select",
        cell: ({ row }) => (
          <Button
            iconStart={<Icon color={theme.text_color_inverse} icon={faTrash} />}
            primary
            size="tiny"
            variant="danger"
            onClick={() =>
              props.onInteraction({
                type: FeatureFlagModal.INTERACTION_REMOVE_FEATURE_FLAG_CLICKED,
                flag: row.original.flag,
              })
            }
          />
        ),
        size: 48,
      }),
    ];
  }, [props.existingFeatureFlags, props.users]);

  const usersKeyedByID = keyBy(props.users, "id");

  const data: TableData[] = props.existingFeatureFlags.map((flag) => {
    return {
      createdBy: getFullName(usersKeyedByID[flag.createdByID]),
      createdAt: flag.createdAt,
      flag: flag.flag,
    };
  });

  return (
    <Modal
      closeOnClickOutside={true}
      isOpen={true}
      showCloseButton
      width={650}
      onClose={handleClose}
    >
      <Modal.Header>
        <Text
          appearance="h4"
          marginRight={theme.space_xl}
        >{`${props.tenantName ?? ""} ${copyText.featureFlagsModalTitle}`}</Text>
      </Modal.Header>
      <Modal.Body>
        <Box>
          <Flex justifyContent="flex-end" marginBottom={theme.space_sm}>
            <SelectDropdown
              options={featureFlagOptions}
              placement="bottom-start"
              onChange={(value) => {
                props.onInteraction({
                  type: FeatureFlagModal.INTERACTION_ADD_FEATURE_FLAG_CLICKED,
                  flag: value as FeatureFlag,
                });
              }}
            >
              <Button
                disabled={props.isLoading}
                iconStart={<Icon icon={faPlus} />}
                secondary
              >
                {copyText.addFeatureFlagButtonLabel}
              </Button>
            </SelectDropdown>
          </Flex>
          <Table
            columns={columns}
            data={data}
            initialState={{ sorting: [{ id: "flag", desc: true }] }}
            isLoading={props.isLoading}
            showPagination
            sortable
          />
        </Box>
      </Modal.Body>
    </Modal>
  );
}

FeatureFlagModal.INTERACTION_CANCEL_BUTTON_CLICKED =
  "FeatureFlagModal.INTERACTION_CANCEL_BUTTON_CLICKED" as const;

FeatureFlagModal.INTERACTION_ADD_FEATURE_FLAG_CLICKED =
  "FeatureFlagModal.INTERACTION_ADD_FEATURE_FLAG_CLICKED" as const;

FeatureFlagModal.INTERACTION_REMOVE_FEATURE_FLAG_CLICKED =
  "FeatureFlagModal.INTERACTION_REMOVE_FEATURE_FLAG_CLICKED" as const;

type InteractionCancelButtonClicked = {
  type: typeof FeatureFlagModal.INTERACTION_CANCEL_BUTTON_CLICKED;
};

type InteractionAddFeatureFlagClicked = {
  type: typeof FeatureFlagModal.INTERACTION_ADD_FEATURE_FLAG_CLICKED;
  flag: FeatureFlag;
};

type InteractionRemoveFeatureFlagClicked = {
  type: typeof FeatureFlagModal.INTERACTION_REMOVE_FEATURE_FLAG_CLICKED;
  flag: FeatureFlag;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace FeatureFlagModal {
  export type Interaction =
    | InteractionCancelButtonClicked
    | InteractionAddFeatureFlagClicked
    | InteractionRemoveFeatureFlagClicked;
}

export default FeatureFlagModal;
