import Meter, { getChangePercent } from "@/components/Meter";
import { DateHelper } from "@/lib/dates";
import Divider from "@/ui-lib/components/Divider";
import { useTheme } from "@emotion/react";
import {
  formatCurrency,
  formatNumberRounded,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Flex from "@ternary/web-ui-lib/components/Flex";
import { getDate, getDaysInMonth } from "date-fns";
import prettyBytes from "pretty-bytes";
import React from "react";
import copyText from "../../copyText";

interface Props {
  avgBytes: number;
  avgFailsafeBytes: number;
  currentMTDSpend: number;
  isLoadingSpend: boolean;
  lastMonthSpend: number;
  lastMTDSpend: number;
}

export default function SnowflakeDatabaseMeters(props: Props): JSX.Element {
  const theme = useTheme();
  const now = new DateHelper();

  const mtdSpendComparison = getChangePercent({
    biggerIsBetter: false,
    value: props.currentMTDSpend,
    comparison: props.lastMTDSpend,
    theme,
  });

  const avgDailySpend = props.currentMTDSpend / getDate(now.date);
  const forecastedSpend = avgDailySpend * getDaysInMonth(now.date);

  const forecastedSpendComparison = getChangePercent({
    biggerIsBetter: false,
    comparison: props.lastMonthSpend,
    value: forecastedSpend,
    theme,
  });

  return (
    <Flex justifyContent="space-between" width="100%">
      <Meter
        header={copyText.snowflakeDatabaseMeterLastMonthTotalSpend}
        loading={props.isLoadingSpend}
        value={formatNumberRounded(props.lastMonthSpend)}
        width="20%"
      />
      <Divider direction="vertical" />
      <Meter
        footerColor={mtdSpendComparison.color}
        footerIcon={mtdSpendComparison.icon}
        footerTitle={copyText.meterMTDSpendComparisonCaption}
        footerValue={mtdSpendComparison.textValue}
        header={copyText.snowflakeDatabaseMeterMonthlySpendToDate}
        loading={props.isLoadingSpend}
        value={formatNumberRounded(props.currentMTDSpend)}
        width="20%"
      />
      <Divider direction="vertical" />
      <Meter
        footerColor={forecastedSpendComparison.color}
        footerIcon={forecastedSpendComparison.icon}
        footerTitle={copyText.meterForecastedSpendComparisonCaption}
        footerValue={forecastedSpendComparison.textValue}
        header={copyText.meterForecastedSpendComparisonTitle}
        loading={props.isLoadingSpend}
        value={formatCurrency({
          number: forecastedSpend,
        })}
        width="30%"
      />
      <Divider direction="vertical" />
      <Meter
        header={copyText.snowflakeDatabaseMeterAvgDatabaseBytes}
        loading={props.isLoadingSpend}
        value={prettyBytes(props.avgBytes)}
        width="15%"
      />
      <Divider direction="vertical" />
      <Meter
        header={copyText.snowflakeDatabaseMeterAvgFailsafeBytes}
        loading={props.isLoadingSpend}
        value={prettyBytes(props.avgFailsafeBytes)}
        width="15%"
      />
    </Flex>
  );
}
