import { useTheme } from "@emotion/react";
import {
  faChevronDown,
  faClose,
  faFileExport,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  ChartType,
  DataSource,
  DurationType,
  Operator,
  TimeGranularity,
  UnitType,
} from "@ternary/api-lib/analytics/enums";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import AreaChart from "@ternary/api-lib/analytics/ui/AreaChart";
import StackedBarChart from "@ternary/api-lib/analytics/ui/StackedBarChart";
import { Measure } from "@ternary/api-lib/analytics/ui/types";
import { getDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import { formatCurrency } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import { getMergeState } from "@ternary/api-lib/analytics/utils/StateUtils";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Box from "@ternary/web-ui-lib/components/Box";
import Text from "@ternary/web-ui-lib/components/Text";
import { groupBy, keyBy, noop } from "lodash";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import {
  DateParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import useGetRawData from "../../../../api/analytics/useGetRawData";
import useGetBillingSpendSummaries from "../../../../api/analytics/useGetSpendSummaries";
import InsightsSelector from "../../../../components/InsightsSelector";
import useAvailableGlobalDate from "../../../../hooks/useAvailableGlobalDate";
import { DateHelper } from "../../../../lib/dates";
import DateRangeControls from "../../../../ui-lib/components/DateRangeControls/DateRangeControls";
import Dropdown from "../../../../ui-lib/components/Dropdown";
import TextInput from "../../../../ui-lib/components/TextInput";
import copyText from "../../copyText";
import useGetCloudSpannerInstanceCostSummary from "../hooks/useGetCloudSpannerInstanceCostSummary";
import useGetCloudSpannerInstanceSummary from "../hooks/useGetCloudSpannerInstanceSummary";
import useGetCloudSpannerResourceSummary from "../hooks/useGetCloudSpannerResourceSummary";
import useGetCloudSpannerUsageSummary from "../hooks/useGetCloudSpannerUsageSummary";
import {
  cloudSpannerDimensions,
  cloudSpannerInstanceMeasures,
  cloudSpannerResourceMeasures,
} from "../types";
import GCPSpannerInstanceTable from "./GCPCloudSpannerInstanceTable";
import GCPCloudSpannerMeters from "./GCPCloudSpannerMeters";
import GCPSpannerResourceTable from "./GCPCloudSpannerResourceTable";

const CloudSpannerCostChartOption = {
  PROJECT_ID: cloudSpannerDimensions.projectID,
  REGION: cloudSpannerDimensions.region,
  INSTANCE_ID: cloudSpannerDimensions.instanceID,
  USAGE_TYPE: "usageType",
} as const;

type CloudSpannerCostChartOption =
  (typeof CloudSpannerCostChartOption)[keyof typeof CloudSpannerCostChartOption];

const CostGroupingLabel: { [key in CloudSpannerCostChartOption]: string } = {
  [CloudSpannerCostChartOption.PROJECT_ID]:
    copyText.cloudSannerCostChartOption_projectID,
  [CloudSpannerCostChartOption.REGION]:
    copyText.cloudSpannerCostChartOption_region,
  [CloudSpannerCostChartOption.INSTANCE_ID]:
    copyText.cloudSpannerCostChartOption_instanceID,
  [CloudSpannerCostChartOption.USAGE_TYPE]:
    copyText.cloudSpannerCostChartOption_usageType,
};

const CloudSpannerUsageChartOption = {
  COMPUTE_REGIONAL: "computeRegion",
  COMPUTE_MULTIREGIONAL: "computeMultiRegion",
  DB_STORAGE: "dbStorage",
  BACKUP: "backup",
  NETWORK_EGRESS: "networkEgress",
} as const;

type CloudSpannerUsageChartOption =
  (typeof CloudSpannerUsageChartOption)[keyof typeof CloudSpannerUsageChartOption];

const UsageGroupingLabel: { [key in CloudSpannerUsageChartOption]: string } = {
  [CloudSpannerUsageChartOption.COMPUTE_REGIONAL]:
    copyText.cloudSpannerUsageChartOption_computeRegion,
  [CloudSpannerUsageChartOption.COMPUTE_MULTIREGIONAL]:
    copyText.cloudSpannerUsageChartOption_computeMultiRegion,
  [CloudSpannerUsageChartOption.DB_STORAGE]:
    copyText.cloudSpannerUsageChartOption_dbStorage,
  [CloudSpannerUsageChartOption.BACKUP]:
    copyText.cloudSpannerUsageChartOption_backup,
  [CloudSpannerUsageChartOption.NETWORK_EGRESS]:
    copyText.cloudSpannerUsageChartOption_networkEgress,
};

type SpannerInstance = {
  projectID: string | null;
  instanceID: string | null;
  cost: number;
  backupUsedB: number;
  processingUnits: number;
  storageLimitB: number;
  totalEgressGib: number;
};

type Interaction =
  | GCPSpannerInstanceTable.Interaction
  | GCPSpannerResourceTable.Interaction;

interface State {
  searchText: string;
  selectedProjectID: string;
  showInstanceTable: boolean;
}

const initialState: State = {
  searchText: "",
  selectedProjectID: "",
  showInstanceTable: false,
};

export function GCPCloudSpannerVisibilityContainer(): JSX.Element {
  const theme = useTheme();
  const globalDate = useAvailableGlobalDate();

  const costGroupingEnum = createEnumParam(
    Object.values(CloudSpannerCostChartOption)
  );

  const usageGroupingEnum = createEnumParam(
    Object.values(CloudSpannerUsageChartOption)
  );

  const durationEnum = createEnumParam(Object.values(DurationType));

  const [searchParamState, setSearchParamState] = useQueryParams({
    cost_grouping: withDefault(
      costGroupingEnum,
      CloudSpannerCostChartOption.PROJECT_ID
    ),
    date_range_end: DateParam,
    date_range_start: DateParam,
    duration: withDefault(durationEnum, DurationType.LAST_THIRTY_DAYS),
    usage_grouping: withDefault(
      usageGroupingEnum,
      CloudSpannerUsageChartOption.COMPUTE_REGIONAL
    ),
  });

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  const now = useMemo(() => new DateHelper(), []);

  const earliestDate = now.nDaysAgo(30);

  const dateRange = useMemo(() => {
    const customDateRange =
      (searchParamState.duration === DurationType.CUSTOM ||
        searchParamState.duration === DurationType.INVOICE) &&
      searchParamState.date_range_start &&
      searchParamState.date_range_end
        ? [searchParamState.date_range_start, searchParamState.date_range_end]
        : null;

    if (globalDate.date) {
      return globalDate.date;
    }

    if (customDateRange) {
      return customDateRange.map((date) =>
        date < earliestDate ? new Date(earliestDate) : date
      );
    }

    return getDateRangeFromDurationType(searchParamState.duration);
  }, [
    searchParamState.duration,
    searchParamState.date_range_start,
    searchParamState.date_range_end,
    globalDate,
  ]);

  //
  // Queries
  //

  const spendSummaries = useGetBillingSpendSummaries({
    dataSource: DataSource.CLOUD_SPANNER,
    excludeCredits: true,
  });

  const [{ data: currentMTD }, { data: lastMonthFull }, { data: lastMTD }] =
    spendSummaries;

  const isLoadingSpendSummaries = spendSummaries.some(
    (summary) => summary.isLoading
  );

  const { data: usageSummary, isLoading: isLoadingCloudSpannerUsage } =
    useGetCloudSpannerUsageSummary();

  const costMeasures =
    searchParamState.cost_grouping === CloudSpannerCostChartOption.USAGE_TYPE
      ? [
          cloudSpannerResourceMeasures.totalBackupCost,
          cloudSpannerResourceMeasures.totalComputeCost,
          cloudSpannerResourceMeasures.totalDataBoostComputeCost,
          cloudSpannerResourceMeasures.totalEgressCost,
          cloudSpannerResourceMeasures.totalStorageCost,
        ]
      : [cloudSpannerResourceMeasures.cost];

  const { data: costData = [], isFetching: isLoadingCostData } = useGetRawData({
    dataSource: DataSource.CLOUD_SPANNER,
    dateRange,
    dimensions:
      searchParamState.cost_grouping !== CloudSpannerCostChartOption.USAGE_TYPE
        ? [searchParamState.cost_grouping]
        : [],
    granularity: TimeGranularity.DAY,
    measures: costMeasures,
  });

  const { data: usageData = [], isFetching: isLoadingUsageData } =
    useGetRawData({
      dataSource: DataSource.CLOUD_SPANNER_USAGE,
      dateRange,
      dimensions: [cloudSpannerDimensions.instanceConfig],
      granularity: TimeGranularity.DAY,
      measures: getUsageMeasuresFromOption(searchParamState.usage_grouping),
      queryFilters: getUsageChartFilters(searchParamState.usage_grouping),
    });

  const { data: resourceData = [], isFetching: isLoadingResourceData } =
    useGetCloudSpannerResourceSummary({
      dateRange,
    });

  const {
    data: instanceUsageData = [],
    isFetching: isLoadingInstanceUsageData,
  } = useGetCloudSpannerInstanceSummary({ dateRange });

  const { data: instanceCostData = [], isFetching: isLoadingInstanceCostData } =
    useGetCloudSpannerInstanceCostSummary({ dateRange });

  const instanceCostDataKeyedByID = keyBy(instanceCostData, "instanceId");

  const combinedInstanceData: SpannerInstance[] = instanceUsageData.map(
    (instance) => ({
      projectID: instance.projectId,
      instanceID: instance.instanceId,
      cost:
        instance.instanceId && instanceCostDataKeyedByID[instance.instanceId]
          ? instanceCostDataKeyedByID[instance.instanceId].cost
          : 0,
      backupUsedB: instance.backupUsedB,
      processingUnits: instance.processingUnits,
      storageLimitB: instance.storageLimitB,
      totalEgressGib: instance.totalEgressGib,
    })
  );

  const combinedInstancesKeyedByProjectID = groupBy(
    combinedInstanceData,
    "projectID"
  );

  //
  // Interaction
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case GCPSpannerResourceTable.INTERACTION_RESOURCE_CLICKED: {
        mergeState({ selectedProjectID: interaction.selectedProjectID });
        return;
      }
      case GCPSpannerInstanceTable.INTERACTION_CLOSE_BUTTON_CLICKED: {
        mergeState({ selectedProjectID: "" });
        return;
      }
    }
  }

  //
  // Render
  //

  const costGroupingOptions = [
    CloudSpannerCostChartOption.PROJECT_ID,
    CloudSpannerCostChartOption.REGION,
    CloudSpannerCostChartOption.USAGE_TYPE,
    CloudSpannerCostChartOption.INSTANCE_ID,
  ].map((costGrouping) => ({
    label: CostGroupingLabel[costGrouping],
    value: costGrouping,
    onClick: () => setSearchParamState({ cost_grouping: costGrouping }),
  }));

  const defaultCostGroupingOption =
    costGroupingOptions.find(
      (option) => option.value === searchParamState.cost_grouping
    ) ?? costGroupingOptions[0];

  const usageGroupingOptions = [
    CloudSpannerUsageChartOption.COMPUTE_REGIONAL,
    CloudSpannerUsageChartOption.COMPUTE_MULTIREGIONAL,
    CloudSpannerUsageChartOption.DB_STORAGE,
    CloudSpannerUsageChartOption.BACKUP,
    CloudSpannerUsageChartOption.NETWORK_EGRESS,
  ].map((usageGrouping) => ({
    label: UsageGroupingLabel[usageGrouping],
    value: usageGrouping,
    onClick: () => setSearchParamState({ usage_grouping: usageGrouping }),
  }));

  const defaultUsageGroupingOption =
    usageGroupingOptions.find(
      (option) => option.value === searchParamState.usage_grouping
    ) ?? usageGroupingOptions[0];

  const costKeys = {
    intanceId: copyText.cloudSpannerCostKeysIntanceID,
    projectId: copyText.cloudSpannerCostKeysProjectID,
    totalBackupCost: copyText.cloudSpannerCostKeysBackupCost,
    totalComputeCost: copyText.cloudSpannerCostKeysComputeCost,
    totalDataBoostComputeCost: copyText.cloudSpannerCostKeysDataboostCost,
    totalEgressCost: copyText.cloudSpannerCostKeysEgressCost,
    totalStorageCost: copyText.cloudSpannerCostKeysStorageCost,
  };

  const usageKeys = {
    avgCpuUtilization: copyText.cloudSpannerUsageKeysAvgCpuUtil,
    maxCpuUtilization: copyText.cloudSpannerUsageKeysMaxCpuUtil,
    storageUsedB: copyText.cloudSpannerUsageKeysStorageUsed,
    backupUsedB: copyText.cloudSpannerUsageKeysBackupUsed,
    totalEgressGib: copyText.cloudSpannerUsageKeysEgress,
    instanceConfig: copyText.cloudSpannerUsageKeysInstanceConfig,
  };

  const filteredTableData = (() => {
    if (state.searchText.length === 0) {
      return resourceData;
    }

    return resourceData.filter((entry) => {
      const str = state.searchText.toLowerCase();

      const projectID = entry.projectId?.toLowerCase();
      const region = entry.region?.toLowerCase();

      return projectID?.includes(str) || region?.includes(str);
    });
  })();

  const costChartReportSnapshot = {
    chartType: ChartType.STACKED_BAR,
    dataSource: DataSource.CLOUD_SPANNER,
    dateRange:
      searchParamState.duration === DurationType.CUSTOM ? dateRange : null,
    dimensions:
      searchParamState.cost_grouping !== CloudSpannerCostChartOption.USAGE_TYPE
        ? [searchParamState.cost_grouping]
        : [],
    durationType: searchParamState.duration,
    isFiscalMode: false,
    fiscalPeriodMap: null,
    granularity: TimeGranularity.DAY,
    measures: costMeasures,
    name: copyText.cloudSpannerCostReportSnapshotTitle,
    xAxisKey: "timestamp",
  };

  const usageChartReportSnapshot = {
    chartType: ChartType.AREA,
    dateRange:
      searchParamState.duration === DurationType.CUSTOM ? dateRange : null,
    dataSource: DataSource.CLOUD_SPANNER_USAGE,
    dimensions:
      searchParamState.usage_grouping !==
      CloudSpannerUsageChartOption.NETWORK_EGRESS
        ? [cloudSpannerDimensions.instanceConfig]
        : [],
    durationType: searchParamState.duration,
    isFiscalMode: false,
    fiscalPeriodMap: null,
    granularity: TimeGranularity.DAY,
    measures: getUsageMeasuresFromOption(searchParamState.usage_grouping),
    name: copyText.cloudSpannerUsageChartTitle,
    queryFilters: getUsageChartFilters(searchParamState.usage_grouping),
    xAxisKey: "timestamp",
  };

  return (
    <Box paddingTop={theme.space_md}>
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <GCPCloudSpannerMeters
          isLoading={isLoadingSpendSummaries || isLoadingCloudSpannerUsage}
          lastMonthSpend={lastMonthFull?.grossCost ?? 0}
          lastMTDSpend={lastMTD?.grossCost ?? 0}
          latestDBAllocated={usageSummary?.storageLimitB ?? 0}
          latestDBUsage={usageSummary?.storageUsedB ?? 0}
          thisMTDSpend={currentMTD?.grossCost ?? 0}
        />
      </Box>

      <Flex
        alignItems="center"
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        justifyContent="flex-end"
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <DateRangeControls
          dateRange={dateRange}
          durationType={searchParamState.duration}
          hiddenOptions={[
            DurationType.LAST_NINETY_DAYS,
            DurationType.QUARTER_TO_DATE,
            DurationType.YEAR_TO_DATE,
          ]}
          maxDate={now.date}
          minDate={earliestDate}
          onChangeDateRange={(duration, newDateRange) => {
            setSearchParamState({
              duration,
              date_range_start: newDateRange?.[0] ?? null,
              date_range_end: newDateRange?.[1] ?? null,
            });
          }}
        />
      </Flex>
      <Flex height={500} marginBottom={theme.space_lg} width={"100%"}>
        <Box width="50%" marginRight={theme.space_lg}>
          <InsightsSelector
            resourceName={copyText.cloudSpannerCostChartTitle}
            reportSnapshot={costChartReportSnapshot}
          >
            <Box
              backgroundColor={theme.panel_backgroundColor}
              borderRadius={theme.borderRadius_2}
              flex={1}
              height="100%"
              padding={theme.space_md}
              width="100%"
            >
              <Flex justifyContent="space-between">
                <Text fontSize={theme.h3_fontSize}>
                  {copyText.cloudSpannerCostChartTitle}
                </Text>

                <Dropdown
                  defaultSelectedOption={defaultCostGroupingOption}
                  options={costGroupingOptions}
                  placement="bottom-end"
                >
                  <Button
                    iconEnd={<Icon icon={faChevronDown} />}
                    secondary
                    size="small"
                    width={140}
                  >
                    {CostGroupingLabel[searchParamState.cost_grouping]}
                  </Button>
                </Dropdown>
              </Flex>
              <Box height={450} paddingVertical={theme.space_md}>
                <StackedBarChart
                  data={costData}
                  dimensions={getCostDimensionsFromOption(
                    searchParamState.cost_grouping
                  ).map((dimension) => ({
                    name: dimension,
                  }))}
                  readableKeys={costKeys}
                  isLoading={isLoadingCostData}
                  measures={costMeasures.map((measure) => ({
                    name: measure,
                    unit: UnitType.CURRENCY,
                  }))}
                  showLegend
                  showTooltip
                  timeSeriesGranularity={TimeGranularity.DAY}
                  xAxisKey="timestamp"
                />
              </Box>
            </Box>
          </InsightsSelector>
        </Box>
        <Box width="50%">
          <InsightsSelector
            resourceName={copyText.cloudSpannerUsageChartTitle}
            reportSnapshot={usageChartReportSnapshot}
          >
            <Box
              backgroundColor={theme.panel_backgroundColor}
              borderRadius={theme.borderRadius_2}
              flex={1}
              height="100%"
              padding={theme.space_md}
              width="100%"
            >
              <Flex justifyContent="space-between">
                <Text fontSize={theme.h3_fontSize}>
                  {copyText.cloudSpannerUsageChartTitle}
                </Text>

                <Dropdown
                  defaultSelectedOption={defaultUsageGroupingOption}
                  options={usageGroupingOptions}
                  placement="bottom-end"
                >
                  <Button
                    iconEnd={<Icon icon={faChevronDown} />}
                    secondary
                    size="small"
                    width={160}
                  >
                    {UsageGroupingLabel[searchParamState.usage_grouping]}
                  </Button>
                </Dropdown>
              </Flex>
              <Box height={450} paddingVertical={theme.space_md}>
                <AreaChart
                  data={usageData}
                  dimensions={
                    searchParamState.usage_grouping !==
                    CloudSpannerUsageChartOption.NETWORK_EGRESS
                      ? [{ name: cloudSpannerDimensions.instanceConfig }]
                      : []
                  }
                  disableDrilldown
                  isLoading={isLoadingUsageData}
                  measures={formatUsageMeasure(searchParamState.usage_grouping)}
                  readableKeys={usageKeys}
                  showLegend
                  showTooltip
                  timeSeriesGranularity={TimeGranularity.DAY}
                  xAxisKey="timestamp"
                />
              </Box>
            </Box>
          </InsightsSelector>
        </Box>
      </Flex>
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <Flex justifyContent="flex-end" alignItems="center">
          <Box width={300}>
            <TextInput
              iconEnd={
                <Icon
                  color={theme.text_color_secondary}
                  icon={state.searchText.length > 0 ? faClose : faSearch}
                  onClick={() =>
                    state.searchText.length > 0
                      ? mergeState({ searchText: "" })
                      : noop
                  }
                />
              }
              placeholder={copyText.searchInputPlaceholder}
              size="large"
              value={state.searchText}
              onChange={(e) =>
                mergeState({ searchText: e.target.value.toLowerCase() })
              }
            />
          </Box>
          <CSVLink
            data={filteredTableData.map((datum) => ({
              projectID: datum.projectId,
              backupCost: formatCurrency({ number: datum.totalBackupCost }),
              computeCost: formatCurrency({ number: datum.totalComputeCost }),
              dataBoostComputeCost: formatCurrency({
                number: datum.totalDataBoostComputeCost,
              }),
              egressCost: formatCurrency({ number: datum.totalEgressCost }),
              storageCost: formatCurrency({ number: datum.totalStorageCost }),
              totalCost: formatCurrency({ number: datum.cost }),
            }))}
            filename={`Cloud-Spanner-${formatDate(new Date(), "MM-dd-yyyy")}`}
          >
            <Button
              iconStart={<Icon color="inherit" icon={faFileExport} />}
              secondary
              size="medium"
              marginLeft={theme.space_md}
            >
              {copyText.exportButtonLabel}
            </Button>
          </CSVLink>
        </Flex>
      </Box>
      {state.selectedProjectID && (
        <GCPSpannerInstanceTable
          instances={combinedInstancesKeyedByProjectID[state.selectedProjectID]}
          isLoading={isLoadingInstanceCostData || isLoadingInstanceUsageData}
          onInteraction={handleInteraction}
        />
      )}
      <Box borderRadius={theme.borderRadius_1} marginVertical={theme.space_lg}>
        <GCPSpannerResourceTable
          isLoading={isLoadingResourceData}
          resources={filteredTableData}
          onInteraction={handleInteraction}
        />
      </Box>
    </Box>
  );
}

export default GCPCloudSpannerVisibilityContainer;

function getUsageChartFilters(
  option: CloudSpannerUsageChartOption
): QueryFilter[] {
  const regionKeys = {
    [CloudSpannerUsageChartOption.COMPUTE_MULTIREGIONAL]:
      copyText.cloudSpannerRegionKeyMulti,
    [CloudSpannerUsageChartOption.COMPUTE_REGIONAL]:
      copyText.cloudSpannerRegionKeysRegion,
  };

  switch (option) {
    case CloudSpannerUsageChartOption.COMPUTE_MULTIREGIONAL:
    case CloudSpannerUsageChartOption.COMPUTE_REGIONAL: {
      return [
        {
          name: cloudSpannerDimensions.instanceConfig,
          operator: Operator.EQUALS,
          values: [regionKeys[option]],
        },
      ];
    }
    case CloudSpannerUsageChartOption.DB_STORAGE:
    case CloudSpannerUsageChartOption.BACKUP: {
      return [
        {
          name: cloudSpannerDimensions.instanceConfig,
          operator: Operator.CONTAINS,
          values: ["REGIONAL", "MULTI_REGION"],
        },
      ];
    }
    default: {
      return [];
    }
  }
}

function getUsageMeasuresFromOption(
  option: CloudSpannerUsageChartOption
): string[] {
  switch (option) {
    case CloudSpannerUsageChartOption.COMPUTE_MULTIREGIONAL:
    case CloudSpannerUsageChartOption.COMPUTE_REGIONAL: {
      return [
        cloudSpannerInstanceMeasures.avgCpuUtilization,
        cloudSpannerInstanceMeasures.maxCpuUtilization,
      ];
    }
    case CloudSpannerUsageChartOption.DB_STORAGE: {
      return [cloudSpannerInstanceMeasures.storageUsedB];
    }
    case CloudSpannerUsageChartOption.BACKUP: {
      return [cloudSpannerInstanceMeasures.backupUsedB];
    }
    case CloudSpannerUsageChartOption.NETWORK_EGRESS: {
      return [cloudSpannerInstanceMeasures.totalEgressGib];
    }
  }
}

function formatUsageMeasure(option: CloudSpannerUsageChartOption): Measure[] {
  switch (option) {
    case CloudSpannerUsageChartOption.COMPUTE_MULTIREGIONAL:
    case CloudSpannerUsageChartOption.COMPUTE_REGIONAL: {
      return [
        {
          name: cloudSpannerInstanceMeasures.avgCpuUtilization,
          unit: UnitType.PERCENTAGE,
        },
        {
          name: cloudSpannerInstanceMeasures.maxCpuUtilization,
          unit: UnitType.PERCENTAGE,
        },
      ];
    }
    case CloudSpannerUsageChartOption.DB_STORAGE: {
      return [
        {
          name: cloudSpannerInstanceMeasures.storageUsedB,
          unit: UnitType.BYTES,
        },
      ];
    }
    case CloudSpannerUsageChartOption.BACKUP: {
      return [
        {
          name: cloudSpannerInstanceMeasures.backupUsedB,
          unit: UnitType.BYTES,
        },
      ];
    }
    case CloudSpannerUsageChartOption.NETWORK_EGRESS: {
      return [
        {
          name: cloudSpannerInstanceMeasures.totalEgressGib,
          unit: UnitType.BYTES,
        },
      ];
    }
  }
}

function getCostDimensionsFromOption(
  option: CloudSpannerCostChartOption
): string[] {
  switch (option) {
    case CloudSpannerCostChartOption.PROJECT_ID: {
      return [cloudSpannerDimensions.projectID];
    }
    case CloudSpannerCostChartOption.REGION: {
      return [cloudSpannerDimensions.region];
    }
    case CloudSpannerCostChartOption.INSTANCE_ID: {
      return [cloudSpannerDimensions.instanceID];
    }
    case CloudSpannerCostChartOption.USAGE_TYPE: {
      return [];
    }
  }
}
