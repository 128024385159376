import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ProviderType } from "@ternary/api-lib/analytics/enums";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { Theme } from "@ternary/web-ui-lib/theme/default";
import React, { PropsWithChildren, useMemo } from "react";
import {
  components,
  ControlProps,
  GroupBase,
  InputProps,
  MenuProps,
  StylesConfig,
} from "react-select";
import SelectDropdown, {
  Option,
  SelectDropdownProps,
} from "../../../ui-lib/components/SelectDropdown";
import copyText from "../copyText";

const ChipButton = styled(Button)(({ selected, theme }) => ({
  borderRadius: theme.borderRadius_4,
  padding: `4px 12px`,
  backgroundColor: selected
    ? theme.date_picker_selected_highlight
    : theme.background_color,
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: selected
    ? theme.primary_color_background_inverse
    : theme.background_color_disabled,
  color: selected ? theme.primary_color_background : theme.text_color,
  ":focus": {
    boxShadow: "none",
  },
}));

export function ChipInputSelector({
  options,
  onSelect,
  selectedOptions,
}: {
  options: { label: string; value: string }[];
  onSelect: (selectedOptions: string[]) => void;
  selectedOptions: string[];
}) {
  const theme = useTheme();

  return (
    <Flex flexWrap="wrap" gap={theme.space_xs}>
      {options.map((option) => {
        const isSelected = selectedOptions.includes(option.value);
        return (
          <ChipButton
            iconEnd={
              isSelected ? (
                <Icon color={theme.primary_color_background} icon={faTimes} />
              ) : undefined
            }
            onClick={() => {
              if (selectedOptions.includes(option.value)) {
                onSelect(selectedOptions.filter((s) => s !== option.value));
              } else {
                onSelect([...selectedOptions, option.value]);
              }
            }}
            selected={isSelected}
            size="tiny"
          >
            {option.label}
          </ChipButton>
        );
      })}
    </Flex>
  );
}

function Control<IsMulti extends boolean = false>(
  props: ControlProps<Option, IsMulti, GroupBase<Option>>
) {
  const theme = useTheme();

  return (
    <components.Control {...props}>
      <Flex direction="column" width={"100%"}>
        <Box
          paddingVertical={theme.space_sm}
          paddingHorizontal={theme.space_xxs}
        >
          {props.children}
        </Box>
      </Flex>
    </components.Control>
  );
}

function Input<IsMulti extends boolean = false>(
  props: InputProps<Option, IsMulti, GroupBase<Option>>
) {
  const theme = useTheme();

  return (
    <Flex
      alignItems="center"
      direction="row"
      gap={theme.space_xs}
      justifyContent="center"
      position="relative"
    >
      <Flex alignItems="center" justifyContent="center">
        <Icon icon={faSearch} size="lg" color={theme.text_color_placeholder} />
      </Flex>
      <components.Input {...props} />
    </Flex>
  );
}

function Menu<IsMulti extends boolean = false>(
  props: MenuProps<Option, IsMulti, GroupBase<Option>> & {
    filterLabel: string;
    selectProps?: {
      selectProps?: {
        selectedCount: number;
        submitButtonText?: string;
        onSubmit: (props) => void;
      };
      clearProps?: {
        clearAll: () => void;
      };
      customProps?: {
        menuProps?: {
          clearProviders: () => void;
        };
        controlProps?: {
          options?: string[];
          selectedFilter?: string[];
          onSelect?: (options: string[]) => void;
        };
      };
    };
  }
) {
  const theme = useTheme();
  const customProps = props.selectProps?.selectProps;
  const clearProps = props.selectProps?.clearProps;
  const menuProps = props.selectProps?.customProps?.menuProps;

  const externalFilterProps = props.selectProps?.customProps?.controlProps;
  const {
    options = [],
    selectedFilter = [],
    onSelect = () => {},
  } = externalFilterProps ?? {};

  return (
    <>
      <components.Menu {...props}>
        <Flex
          borderBottom={`1px solid ${theme.border_color}`}
          borderTop={`1px solid ${theme.border_color}`}
          direction="column"
          gap={theme.space_sm}
          paddingHorizontal={theme.space_sm}
          paddingVertical={theme.space_sm}
          width={"100%"}
        >
          <Text color={theme.text_color_caption} fontSize={theme.h5_fontSize}>
            Vendor
          </Text>

          <ChipInputSelector
            options={options.map((option) => {
              if (option === ProviderType.FOCUS) {
                return { label: copyText.focusProviderName, value: option };
              }
              return { label: option, value: option };
            })}
            selectedOptions={selectedFilter}
            onSelect={(e) => {
              onSelect(e);

              if (props.selectProps.inputValue) {
                // React Select filters options when the user types.
                // If an external filter updates the options, React Select doesn't reapply its filter.
                // Manually triggering onInputChange ensures the filter applies to the updated options.
                props.selectProps.onInputChange(props.selectProps.inputValue, {
                  action: "input-change",
                  prevInputValue: props.selectProps.inputValue,
                });
              }
            }}
          />
        </Flex>
        <Flex direction="column" height="100%">
          {props.children}

          <Flex
            alignItems="flex-end"
            borderTop={`1px solid ${theme.border_color}`}
            gap={theme.space_sm}
            justifyContent="flex-end"
            padding={theme.space_xs}
            marginTop={"auto"}
          >
            <Button
              size="small"
              onClick={() => {
                menuProps?.clearProviders();
                clearProps?.clearAll();
                props.clearValue();
              }}
            >
              <Text fontSize={theme.fontSize_small}>Clear all</Text>
            </Button>

            <Button
              primary
              size="small"
              onClick={() => {
                customProps?.onSubmit(props);
              }}
            >
              <Text
                color={theme.text_color_inverse}
                fontSize={theme.fontSize_small}
              >
                {`${customProps?.submitButtonText ?? copyText.submitButtonLabel} (${
                  customProps?.selectedCount
                })`}
              </Text>
            </Button>
          </Flex>
        </Flex>
      </components.Menu>
    </>
  );
}

export type SelectDropdownWithChipFiltersProps<isMulti extends boolean> =
  SelectDropdownProps<isMulti> & {
    filterLabel: string;
    filterOptions: string[];
    selectedFilter: string[];
    onChangeFilter: (options: string[]) => void;
  };

export function SelectDropdownWithChipFilters<IsMulti extends boolean = false>(
  props: PropsWithChildren<SelectDropdownWithChipFiltersProps<IsMulti>>
) {
  const theme = useTheme();

  const customComponents = useMemo(() => {
    return {
      Control: (inputProps) => <Control<IsMulti> {...inputProps} />,
      Input: (inputProps) => <Input<IsMulti> {...inputProps} />,
      Menu: (inputProps) => (
        <Menu<IsMulti> filterLabel={props.filterLabel} {...inputProps} />
      ),
    };
  }, [props.filterLabel]);

  return (
    <SelectDropdown
      {...props}
      isClearable={!!props.isClearable}
      customComponents={customComponents}
      customProps={{
        controlProps: {
          options: props.filterOptions,
          selectedFilter: props.selectedFilter,
          onSelect: (options) => props.onChangeFilter(options),
        },
        menuProps: {
          clearProviders: () => props.onChangeFilter([]),
        },
      }}
      customStyles={focusCustomStyle(theme)}
      dropdownContentStyles={{
        backgroundColor: theme.input_background_color,
        borderRadius: theme.borderRadius_3,
        height: "100%",
        maxHeight: "500px",
        maxWidth: "500px",
        padding: 0,
        width: "100%",
      }}
    >
      {props.children}
    </SelectDropdown>
  );
}

export const focusCustomStyle = (
  theme: Theme
): Partial<StylesConfig<Option, boolean>> => {
  return {
    container: (styles) => ({
      ...styles,
      backgroundColor: theme.input_background_color,
      borderRadius: theme.borderRadius_3,
      height: "100%",
      padding: 0,
      display: "flex",
      flexDirection: "column",
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: theme.input_background_color,
      border: 0,
      borderRadius: theme.borderRadius_3,
      boxShadow: "none",
      color: `${theme.select_color} !important`,
      cursor: "text",
      fontSize: theme.fontSize_ui,
      minHeight: "49px",
      minWidth: "250px",
      padding: 0,
      zIndex: theme.zIndex_200,
    }),
    input: (styles) => ({
      ...styles,
      color: theme.text_color,
      fontSize: theme.fontSize_ui,
      padding: "0",
      width: "100%",
      zIndex: theme.zIndex_200,
    }),
    placeholder: (styles) => ({
      ...styles,
      color: theme.text_color_placeholder,
      left: "1.55rem",
      position: "absolute",
    }),
    option: (styles) => ({
      ...styles,
      alignItems: "center",
      backgroundColor: "transparent",
      color: theme.text_color,
      cursor: "pointer",
      display: "flex",
      fontSize: theme.fontSize_ui,
      paddingHorizontal: 0,

      "&:hover": {
        backgroundColor: theme.secondary_color_background,
      },
    }),
    menu: () => ({
      backgroundColor: theme.input_background_color,
      height: "100%",
      marginTop: "2px",
      maxHeight: "336px",
      width: "100%",
    }),
    menuList: (styles) => ({
      ...styles,
      maxHeight: "287px",
      height: "100%",
      width: "100%",
    }),
  };
};
