import Divider from "@/ui-lib/components/Divider";
import Modal from "@/ui-lib/components/Modal";
import TextInput from "@/ui-lib/components/TextInput";
import { DateRange } from "@/utils/dates";
import { useTheme } from "@emotion/react";
import {
  faFileExport,
  faInfoCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import { formatNumber } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { differenceInDays, differenceInSeconds } from "date-fns";
import prettyBytes from "pretty-bytes";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import copyText from "../../copyText";
import { Bucket, LifeCycle } from "../types";

interface Props {
  buckets: Bucket[];
  date: DateRange;
  onClose: () => void;
}

type TableData = {
  bucketID: string;
  lifecycleRules: LifeCycle[];
  storageBytes: number;
  storageClass: string;
  recommendationCount: number;
  totalObjects: number;
};

type BucketCSVData = {
  bucketID: string;
  lifecycleRules: number;
  storageBytes: number;
  storageClass: string;
  totalObjects: number;
  recommendationCount: number;
};

export default function GCPgcpStorageSubTable(props: Props): JSX.Element {
  const theme = useTheme();
  const columnHelper = createColumnHelper<TableData>();

  const [searchText, setSearchText] = useState("");

  const totalDays = differenceInDays(props.date[1], props.date[0]);
  const totalSeconds = differenceInSeconds(props.date[1], props.date[0]);

  const subTableTitle =
    props.buckets.length > 0
      ? `${props.buckets[0].projectId} / ${props.buckets[0].location}`
      : null;

  function formatRules(lifeCycleRule: LifeCycle[]): string | JSX.Element {
    if (!lifeCycleRule.length) {
      return copyText.gcpStorageSubTableNoRules;
    }

    return (
      <Box minWidth={220}>
        {lifeCycleRule.map((rules, index) => {
          const age = rules.condition.age ?? copyText.gcpStorageSubTableNone;
          const prefixes =
            rules.condition.matchesPrefix.length > 0
              ? rules.condition.matchesPrefix.join("")
              : copyText.gcpStorageSubTableNone;
          const suffixes =
            rules.condition.matchesSuffix.length > 0
              ? rules.condition.matchesSuffix.join("")
              : copyText.gcpStorageSubTableNone;
          const storages =
            rules.condition.matchesStorageClass.length > 0
              ? rules.condition.matchesStorageClass.join("")
              : copyText.gcpStorageSubTableNone;

          return (
            <Flex key={index} direction="column">
              <Flex justifyContent="space-between">
                <Text
                  color={theme.text_color_inverse}
                  marginRight={theme.space_md}
                >
                  {copyText.gcpStorageSubTableLifeCycleActionType}
                </Text>
                <Text
                  color={theme.text_color_inverse}
                  fontWeight={theme.fontWeight_bold}
                >
                  {rules.action.type}
                </Text>
              </Flex>
              <Divider direction="horizontal" margin={0} />
              <Flex justifyContent="space-between">
                <Text
                  color={theme.text_color_inverse}
                  marginRight={theme.space_md}
                >
                  {copyText.gcpStorageSubTableLifeCycleAge}
                </Text>
                <Text color={theme.text_color_inverse}>{`${age} days`}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text
                  color={theme.text_color_inverse}
                  marginRight={theme.space_md}
                >
                  {copyText.gcpStorageSubTableLifeCyclePrefixes}
                </Text>
                <Text color={theme.text_color_inverse}>{prefixes}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text
                  color={theme.text_color_inverse}
                  marginRight={theme.space_md}
                >
                  {copyText.gcpStorageSubTableLifeCycleSuffixes}
                </Text>
                <Text color={theme.text_color_inverse}>{suffixes}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text
                  color={theme.text_color_inverse}
                  marginRight={theme.space_md}
                >
                  {copyText.gcpStorageSubTableLifeCycleStorageClass}
                </Text>
                <Text color={theme.text_color_inverse}>{storages}</Text>
              </Flex>
            </Flex>
          );
        })}
      </Box>
    );
  }

  function formatLifecycleCells(lifeCycles: LifeCycle[]) {
    const rules =
      lifeCycles.length === 0
        ? copyText.gcpStorageSubTableNone
        : lifeCycles.length;
    if (rules !== "None") {
      return (
        <Tooltip content={formatRules(lifeCycles)} placement="right">
          <Flex justifyContent="center" alignItems="center">
            <Text marginRight={theme.space_sm}>{rules}</Text>
            <Icon icon={faInfoCircle} />
          </Flex>
        </Tooltip>
      );
    }

    return rules;
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor("totalObjects", {
        cell: ({ getValue }) => <>{formatNumber(getValue(), 0)}</>,
        header: copyText.gcpStorageSubTableTotalObj,
        meta: { align: "right" },
        size: 80,
      }),
      columnHelper.accessor("bucketID", {
        header: copyText.gcpStorageSubTableBucketID,
      }),
      columnHelper.accessor("storageClass", {
        header: copyText.gcpStorageSubTableClass,
        meta: { align: "left" },
        size: 100,
      }),
      columnHelper.accessor("storageBytes", {
        cell: ({ getValue }) => <>{prettyBytes(getValue())}</>,
        header: copyText.gcpStorageSubTableStorageGB,
        meta: { align: "right" },
        size: 80,
      }),
      columnHelper.accessor("lifecycleRules", {
        cell: ({ getValue }) => <>{formatLifecycleCells(getValue())}</>,
        header: copyText.gcpStorageSubTableLifeCycle,
        meta: { align: "left" },
        size: 90,
      }),
      columnHelper.accessor("recommendationCount", {
        cell: ({ getValue }) => (
          <>{getValue() === 0 ? copyText.gcpStorageSubTableNone : getValue()}</>
        ),
        header: copyText.gcpStorageSubTableRecommendationCount,
        meta: { align: "right" },
        size: 100,
      }),
    ],
    [props.buckets]
  );

  const data: TableData[] = useMemo(() => {
    const tableData = props.buckets.map((bucket) => {
      return {
        bucketID: bucket.bucketId,
        storageClass: bucket.storageClass,
        totalObjects: bucket.totalStoredObjectDays / totalDays,
        storageBytes: Math.floor(bucket.totalStoredByteSeconds / totalSeconds),
        egress: bucket.totalSentBytes,
        ingress: bucket.totalReceivedBytes,
        operations: bucket.totalRequestCount,
        lifecycleRules: bucket.lifecycleRules,
        recommendationCount: bucket.recommendationCount,
      };
    });
    return tableData;
  }, [props.buckets]);

  const filteredData = useMemo(() => {
    if (searchText.length === 0) return data;

    return data.filter((bucket) => {
      const str = searchText.toLowerCase();

      const bucketID = bucket.bucketID.toLowerCase();
      const storageClass = bucket.storageClass.toLowerCase();

      return bucketID.includes(str) || storageClass.includes(str);
    });
  }, [data, searchText]);

  return (
    <Modal
      isOpen
      showCloseButton
      closeOnClickOutside={false}
      onClose={props.onClose}
      minWidth={1100}
    >
      <Modal.Header>
        <Flex
          justifyContent="space-between"
          marginLeft={theme.space_sm}
          width="100%"
        >
          <Text fontSize={theme.h4_fontSize}>{subTableTitle}</Text>

          <Flex alignItems="center">
            <Box width={250} marginRight={theme.space_lg}>
              <TextInput
                disabled={!props.buckets.length}
                iconEnd={
                  <Icon color={theme.text_color_secondary} icon={faSearch} />
                }
                placeholder={copyText.searchInputPlaceholder}
                size="medium"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Box>

            <CSVLink
              data={flattenDataToCSV(filteredData)}
              filename={`${subTableTitle}-${formatDate(
                new Date(),
                "MM-dd-yyyy"
              )}`}
            >
              <Button
                iconStart={<Icon color="inherit" icon={faFileExport} />}
                primary
                size="small"
                marginRight={theme.space_md}
              >
                {copyText.exportButtonLabel}
              </Button>
            </CSVLink>
          </Flex>
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <Box minHeight={500}>
          <Table
            columns={columns}
            data={filteredData}
            initialState={{ sorting: [{ id: "storageBytes", desc: true }] }}
            showPagination
            sortable
          />
        </Box>
      </Modal.Body>
    </Modal>
  );
}

function flattenDataToCSV(data: TableData[]): BucketCSVData[] {
  if (!data.length) {
    return [];
  }

  return data.map((datum) => ({
    bucketID: datum.bucketID,
    storageClass: datum.storageClass,
    totalObjects: datum.totalObjects,
    storageBytes: datum.storageBytes,
    lifecycleRules: datum.lifecycleRules.length,
    recommendationCount: datum.recommendationCount,
  }));
}
