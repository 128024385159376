import { DataSource, Operator } from "@ternary/api-lib/analytics/enums";
import { QueryParamConfig, decodeJson, encodeJson } from "use-query-params";
import { ZodTypeAny, z } from "zod";

const filterDataSchema = z.object({
  auth_id: z.optional(z.string()),
  filters: z.optional(
    z.array(
      z.object({
        dataSource: z.nativeEnum(DataSource),
        name: z.string(),
        operator: z.nativeEnum(Operator),
        values: z.nullable(z.array(z.string())),
      })
    )
  ),
  sv_ids: z.optional(z.array(z.string())),
});

export type FilterData = {
  auth_id?: string;
  filters?: {
    dataSource: DataSource;
    name: string;
    operator: Operator;
    values: string[] | null;
  }[];
  sv_ids?: string[];
};

export const FilterDataJSONParam = {
  encode: encodeJson,
  decode: (
    input: string | (string | null)[] | null | undefined
  ): FilterData | null | undefined => {
    if (typeof input !== "string") return null;

    let result;

    try {
      result = JSON.parse(input);

      filterDataSchema.parse(result);
    } catch {
      return null;
    }

    return {
      ...result,
      filters: result.filters?.map((filter) => ({
        ...filter,
        values: filter.values ?? null,
      })),
    };
  },
};

type JSONParamStruct<StructType extends ZodTypeAny> = QueryParamConfig<
  z.infer<StructType> | null | undefined
>;

export function createStructParam<StructType extends ZodTypeAny>(
  struct: StructType
): JSONParamStruct<StructType> {
  return {
    encode: encodeJson,
    decode: (input) => {
      let result: z.infer<StructType>;

      try {
        result = decodeJson(input);

        struct.parse(result);
      } catch {
        return null;
      }

      return result;
    },
  };
}
