import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import UError from "unilib-error";
import { buildCubeQuery } from "../../../../api/analytics/utils";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { GCPKubernetesUsageSummaryEntity } from "../types";

type kubernetesUsageSummaryDatum = {
  CPUCoreUsageTime?: number | null;
  requestCPUCores?: number | null;
  requestCPUCost?: number | null;
  requestMemoryBytes?: number | null;
  requestMemoryCost?: number | null;
  cost?: number | null;
  totalCPUCores?: number | null;
  totalCPUCost?: number | null;
  totalMemoryBytes?: number | null;
  totalMemoryCost?: number | null;
  totalRequestCost?: number | null;
  usedCPUCores?: number | null;
  usedMemoryBytes?: number | null;
};

export interface Params {
  dataSource:
    | typeof DataSource.KUBERNETES_CLUSTER_USAGE
    | typeof DataSource.KUBERNETES_CONTAINER_USAGE
    | typeof DataSource.KUBERNETES_NODE_USAGE;
  dateRange: Date[];
  queryFilters?: QueryFilter[];
}

export default function useGetGCPKubernetesUsageSummary(
  params: Params,
  options?: UseQueryOptions<GCPKubernetesUsageSummaryEntity, UError>
): UseQueryResult<GCPKubernetesUsageSummaryEntity, UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["kubernetesUsageSummaries", params],
    queryFn: async () => {
      const measures =
        params.dataSource === DataSource.KUBERNETES_NODE_USAGE ||
        params.dataSource === DataSource.KUBERNETES_CLUSTER_USAGE
          ? [
              "cost",
              "CPUCoreUsageTime",
              "totalCPUCores",
              "totalCPUCost",
              "totalMemoryBytes",
              "totalMemoryCost",
              "usedMemoryBytes",
            ]
          : [
              "requestCPUCores",
              "requestCPUCost",
              "requestMemoryBytes",
              "requestMemoryCost",
              "totalRequestCost",
              "usedCPUCores",
              "usedMemoryBytes",
            ];

      const result = (await client.load(
        buildCubeQuery({
          dataSource: params.dataSource,
          dateRange: params.dateRange,
          measures,
          queryFilters: params.queryFilters,
        })
      )) as kubernetesUsageSummaryDatum[];

      const summary = result[0];

      // prettier-ignore
      return {
        totalBytes: summary.requestMemoryBytes ?? summary.totalMemoryBytes ?? 0,
        totalBytesCost: summary.requestMemoryCost ?? summary.totalMemoryCost ?? 0,
        totalCores: summary.requestCPUCores ?? summary.totalCPUCores ?? 0,
        totalCoresCost: summary.requestCPUCost ?? summary.totalCPUCost ?? 0,
        totalCost: summary.totalRequestCost ?? summary.cost ?? 0,
        usedBytes: summary.usedMemoryBytes ?? summary.usedMemoryBytes ?? 0,
        usedCores: summary.usedCPUCores ?? summary.CPUCoreUsageTime ?? 0,
      };
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
