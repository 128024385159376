import { useTheme } from "@emotion/react";
import {
  formatCurrency,
  formatPercentage,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import { AzureRateType } from "@ternary/api-lib/constants/enums";
import { AzureRateRecommendationEntity } from "@ternary/api-lib/core/types";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import React from "react";
import Meter from "../../../../components/Meter";
import copyText from "../../copyText";

type Props = {
  committedUseType: AzureRateType;
  isLoading: boolean;
  reservedInstanceRecommendations: AzureRateRecommendationEntity[];
  savingsPlanRecommendations: AzureRateRecommendationEntity[];
};

export default function AzureCommittedUseMeter(props: Props) {
  const theme = useTheme();

  const meterValues =
    props.committedUseType === AzureRateType.RI
      ? getRIMeterValues(props.reservedInstanceRecommendations)
      : getSPMeterValues(props.savingsPlanRecommendations);

  return (
    <Flex
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_1}
      padding={theme.space_md}
      paddingVertical={theme.space_sm}
      overflowX="auto"
    >
      <Box flex="1 0 175px">
        <Meter
          header={copyText.azureMeter_0_count}
          hideFooter
          loading={props.isLoading}
          value={String(meterValues.count)}
        />
      </Box>

      <Box
        borderRight={`1px solid ${theme.border_color}`}
        flex="0 0 auto"
        marginHorizontal={theme.space_lg}
      />

      <Box flex="1 0 175px">
        <Meter
          header={copyText.azureMeter_1_estMonthlySavings}
          hideFooter
          loading={props.isLoading}
          value={formatCurrency({ number: meterValues.estMonthlySavings })}
        />
      </Box>

      <Box
        borderRight={`1px solid ${theme.border_color}`}
        flex="0 0 auto"
        marginHorizontal={theme.space_lg}
      />

      <Box flex="1 0 175px">
        <Meter
          header={copyText.azureMeter_2_estTotalSavings}
          hideFooter
          loading={props.isLoading}
          value={formatCurrency({ number: meterValues.estTotalSavings })}
        />
      </Box>

      <Box
        borderRight={`1px solid ${theme.border_color}`}
        flex="0 0 auto"
        marginHorizontal={theme.space_lg}
      />

      <Box flex="1 0 175px">
        <Meter
          header={copyText.azureMeter_3_estSavingsPercentage}
          hideFooter
          loading={props.isLoading}
          value={formatPercentage(meterValues.estSavingsPercentage)}
        />
      </Box>
      <Box
        borderRight={`1px solid ${theme.border_color}`}
        flex="0 0 auto"
        marginHorizontal={theme.space_lg}
      />

      <Box flex="1 0 175px">
        <Meter
          header={
            props.committedUseType === AzureRateType.RI
              ? copyText.azureMeter_6_totalRICost
              : copyText.azureMeter_6_totalSPCost
          }
          hideFooter
          loading={props.isLoading}
          value={formatCurrency({ number: meterValues.totalCost })}
        />
      </Box>
    </Flex>
  );
}

function getRIMeterValues(ris: AzureRateRecommendationEntity[]) {
  const count = ris.length;
  let estMonthlySavings = 0;
  let estTotalSavings = 0;
  let estSavingsPercentage = 0;
  let totalCost = 0;

  ris.forEach((ri) => {
    estMonthlySavings += ri.estimatedTotalSavings / 12;
    estTotalSavings += ri.estimatedTotalSavings;
    totalCost += ri.totalCost;
  });

  ris.forEach((ri) => {
    const fracOfTotal = ri.totalCost / totalCost;
    estSavingsPercentage += ri.savingsPercentage * fracOfTotal;
  });

  estSavingsPercentage /= 100;

  return {
    count,
    estMonthlySavings,
    estSavingsPercentage,
    estTotalSavings,
    hourlyCommitmentToPurchase: 0,
    totalCost,
  };
}

function getSPMeterValues(sps: AzureRateRecommendationEntity[]) {
  const count = sps.length;
  let estMonthlySavings = 0;
  let estTotalSavings = 0;
  let estSavingsPercentage = 0;
  let hourlyCommitmentToPurchase = 0;
  let totalCost = 0;

  sps.forEach((sp) => {
    estMonthlySavings += sp.estimatedTotalSavings / 12;
    estTotalSavings += sp.estimatedTotalSavings;
    hourlyCommitmentToPurchase += sp.commitmentCost;
    totalCost += sp.totalCost;
  });

  sps.forEach((sp) => {
    const fracOfTotal = sp.totalCost / totalCost;
    estSavingsPercentage += sp.savingsPercentage * fracOfTotal;
  });

  estSavingsPercentage /= 100;

  return {
    count,
    estMonthlySavings,
    estSavingsPercentage,
    estTotalSavings,
    hourlyCommitmentToPurchase,
    monthlyPayment: 0,
    totalCost,
  };
}
