import { useTheme } from "@emotion/react";
import { UnitType } from "@ternary/api-lib/analytics/enums";
import TimeSeriesChartTooltip from "@ternary/api-lib/analytics/ui/TimeSeriesChartTooltip";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import { formatCurrencyRounded } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { Theme } from "@ternary/web-ui-lib/theme/default";
import { getDaysInMonth, isAfter, sub } from "date-fns";
import { keyBy } from "lodash";
import React, { useMemo } from "react";
import {
  Tooltip as ChartTooltip,
  Line,
  LineChart,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { DateHelper } from "../../../lib/dates";
import Select from "../../../ui-lib/components/Select";
import copyText from "../copyText";
import {
  CommitmentPeriod,
  NetCostByDate,
  NetCostByDateDimensional,
  RampPlanBreakpoint,
} from "../types";
import {
  OTHER_DIMENSION_NAME,
  getStartOfRampPlan,
  rollupDimensionalActualsBasedOnEnumeratedValues,
} from "../utils/rampPlans";

type RampPlanNonExportOffset = {
  [key: string]: number;
};

type RampPlanNonExportOffsetByMonth = {
  [month: string]: RampPlanNonExportOffset;
};

type RampPlan = {
  id: string;
  billingAccountIDs: string[];
  breakpoints: RampPlanBreakpoint[];
  commitments: CommitmentPeriod[];
  name: string;
  enumeratedValues: string[];
  key: string;
  nonExportOffsetByMonth: RampPlanNonExportOffsetByMonth;
  nonExportOffsetRecurring: RampPlanNonExportOffset;
};

interface Props {
  actualSpendDimensional: NetCostByDateDimensional[];
  compressedUI: boolean;
  rampPlan: RampPlan;
  rampPlanMonths: NetCostByDateDimensional[];
  selectedMonthForDimensionalChart: string;
  onInteraction: (
    interaction: RampPlanDimensionalSpendChart.Interaction
  ) => void;
}

export function RampPlanDimensionalSpendChart(props: Props): JSX.Element {
  const theme = useTheme();

  const now = new DateHelper();
  const otherDimensionCopyText =
    props.rampPlan.enumeratedValues.length === 0
      ? copyText.chartHeaderTotal
      : copyText.otherLabel;

  const reducedActualSpendDimensional = useMemo(
    () =>
      rollupDimensionalActualsBasedOnEnumeratedValues(
        props.actualSpendDimensional,
        props.rampPlan
      ),
    [props.actualSpendDimensional, props.rampPlan]
  );

  const indexOfThisMonthsRampPlan = props.rampPlanMonths.findIndex(
    (m) => m.date === props.selectedMonthForDimensionalChart
  );

  const rampPlanMonth = props.rampPlanMonths[indexOfThisMonthsRampPlan];
  const daysInThisMonth = getDaysInMonth(
    new Date(props.selectedMonthForDimensionalChart)
  );

  const projectionsKeyedByDimension: {
    [dimension: string]: number;
  } = rampPlanMonth
    ? rampPlanMonth.dimensions.reduce((accum, d) => {
        return { ...accum, [d.dimension]: d.netCost / daysInThisMonth };
      }, {})
    : {};

  const chartDataGroupedByDimension = useMemo(() => {
    return reducedActualSpendDimensional.reduce(
      (accum: { [dimension: string]: NetCostByDate[] }, entry) => {
        entry.dimensions.forEach((dimensionObject) => {
          const existing = accum[dimensionObject.dimension];
          if (!existing) {
            accum[dimensionObject.dimension] = [
              {
                date: entry.date,
                netCost: dimensionObject.netCost,
              },
            ];
          } else {
            accum[dimensionObject.dimension].push({
              date: entry.date,
              netCost: dimensionObject.netCost,
            });
          }
        });
        return accum;
      },
      {}
    );
  }, [[props.actualSpendDimensional]]);

  // memoize
  const monthOptions: { label: string; value: string }[] = [];

  const start = getStartOfRampPlan(props.rampPlan.commitments);
  let month = now.date;

  if (props.rampPlan && isAfter(month, new Date(start))) {
    while (isAfter(month, new Date(start))) {
      month = sub(month, { months: 1 });
      monthOptions.push({
        label: formatDate(month, "MM/yyyy"),
        value: formatDate(month, "yyyy-MM"),
      });
    }
  }

  return (
    <div>
      <Flex
        direction={props.compressedUI ? "column" : "row"}
        alignItems={props.compressedUI ? "stretch" : "center"}
        justifyContent="space-between"
        width="100%"
      >
        <Text
          as="h4"
          fontSize={theme.h4_fontSize}
          fontWeight={theme.h4_fontWeight}
        >
          {copyText.dimensionalChartTitle}
        </Text>
        <Box flexGrow={1} marginLeft={props.compressedUI ? 0 : theme.space_md}>
          <Select
            options={monthOptions}
            value={monthOptions.find(
              (option) =>
                option.value === props.selectedMonthForDimensionalChart
            )}
            placeholder={copyText.dimensionalChartSelectMonth}
            onChange={(option) =>
              option &&
              props.onInteraction({
                type: RampPlanDimensionalSpendChart.INTERACTION_MONTH_CHANGED,
                monthString: option.value,
              })
            }
          />
        </Box>
      </Flex>
      <Box
        borderTop={`2px solid ${theme.border_color}`}
        marginVertical={theme.space_xs}
        padding="1px"
        width="100%"
      />
      {Object.keys(chartDataGroupedByDimension)
        .sort((a, b) => {
          // force other to bottom
          if (a === OTHER_DIMENSION_NAME) {
            return 1;
          }
          if (b === OTHER_DIMENSION_NAME) {
            return -1;
          }
          // otherwise alphabetize
          if (a > b) {
            return 1;
          }
          return -1;
        })
        .map((key) => {
          return renderChart({
            dimension: key,
            chartData: chartDataGroupedByDimension[key],
            projection: projectionsKeyedByDimension[key],
            otherDimensionCopyText,
            theme,
          });
        })}
    </div>
  );
}

function renderChart(params: {
  dimension: string;
  chartData: NetCostByDate[];
  projection: number;
  otherDimensionCopyText: string;
  theme: Theme;
}) {
  const firstEntry = params.chartData[0];
  const lastEntry = params.chartData[params.chartData.length - 1];

  const spendKeyedByDate = keyBy(params.chartData, "date");
  return (
    <div key={params.dimension}>
      <Text bold marginBottom={`-${params.theme.space_sm}`}>
        {params.dimension === OTHER_DIMENSION_NAME
          ? params.otherDimensionCopyText
          : params.dimension}
        :
      </Text>
      <ResponsiveContainer width="100%" height={100}>
        <LineChart
          data={params.chartData}
          margin={{ left: -30, right: 35, top: 20, bottom: 20 }}
        >
          <ReferenceDot
            ifOverflow="extendDomain"
            fill={params.theme.ramp_plans_actuals}
            r={6}
            strokeWidth={0}
            x={firstEntry.date}
            y={firstEntry.netCost}
            label={(props) => (
              <DotLabel
                theme={params.theme}
                value={firstEntry.netCost}
                x={props.viewBox.x}
              />
            )}
          />
          <ReferenceDot
            ifOverflow="extendDomain"
            fill={params.theme.ramp_plans_actuals}
            r={6}
            strokeWidth={0}
            x={lastEntry.date}
            y={lastEntry.netCost}
            label={(props) => (
              <DotLabel
                theme={params.theme}
                value={lastEntry.netCost}
                x={props.viewBox.x}
              />
            )}
          />
          <ReferenceLine
            ifOverflow="extendDomain"
            y={params.projection}
            strokeWidth="2px"
            strokeDasharray="5 5"
            stroke={params.theme.ramp_plans_projected}
          />
          <Line
            dataKey="netCost"
            dot={false}
            isAnimationActive={false}
            stroke={params.theme.ramp_plans_actuals}
            strokeWidth="2px"
          />
          {
            // X and Y axis are both required to render reference dots. They are not visibile here.
          }
          <XAxis
            dataKey="date"
            stroke={params.theme.chart_axis_text}
            tickFormatter={() => ""}
            strokeWidth={0}
          />
          <YAxis
            dataKey="netCost"
            stroke={params.theme.chart_axis_text}
            tickFormatter={() => ""}
            strokeWidth={0}
          />
          <ChartTooltip
            content={(e) => {
              return (
                <TimeSeriesChartTooltip
                  customColors={[
                    params.theme.ramp_plans_projected,
                    params.theme.ramp_plans_actuals,
                  ]}
                  dateFormat={"MM/dd/yyyy"}
                  entry={{
                    ...spendKeyedByDate[e.label],
                    date: e.label,
                    [copyText.commitmentChartLabelActualSpend]:
                      spendKeyedByDate[e.label]?.netCost,
                    [copyText.commitmentChartLabelProjected]: params.projection,
                  }}
                  excludedGroupings={[]}
                  hideTotal
                  label={e.label}
                  reverseSortedGroupings={[
                    copyText.commitmentChartLabelActualSpend,
                    copyText.commitmentChartLabelProjected,
                  ]}
                  unitType={UnitType.CURRENCY}
                />
              );
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

function DotLabel(props: {
  theme: Theme;
  value: number;
  x: number;
}): JSX.Element {
  return (
    <text
      fill={props.theme.text_color}
      fontSize={props.theme.h6_fontSize}
      x={props.x - 10}
      y={70}
    >
      {formatCurrencyRounded({
        number: props.value,
      })}
    </text>
  );
}

RampPlanDimensionalSpendChart.INTERACTION_MONTH_CHANGED =
  `RampPlanDimensionalSpendChart.INTERACTION_MONTH_CHANGED` as const;
interface InteractionMonthChanged {
  type: typeof RampPlanDimensionalSpendChart.INTERACTION_MONTH_CHANGED;
  monthString: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace RampPlanDimensionalSpendChart {
  export type Interaction = InteractionMonthChanged;
}

export default RampPlanDimensionalSpendChart;
