import { UnitType } from "../enums";

const measures = [
  { name: "computeCreditsUsed", unit: UnitType.STANDARD },
  { name: "cloudServicesCreditsUsed", unit: UnitType.STANDARD },
  { name: "totalCreditsUsed", unit: UnitType.STANDARD },
  { name: "queryCount", unit: UnitType.STANDARD },
  { name: "queryElapsedTime", unit: UnitType.STANDARD },
];

const dimensions = [
  "accountName",
  "warehouseName",
  "warehouseID",
  "warehouseSize",
  "warehouseType",
  "tags",
];

export const snowflakeWarehouseUsageSchema = { dimensions, measures };
