import { useTheme } from "@emotion/react";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import {
  formatCurrency,
  formatNumber,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import prettyBytes from "pretty-bytes";
import React from "react";
import copyText from "../../copyText";
import { SearchParams } from "./GCPKubernetesContainer";

interface KubernetesUsageSummary {
  totalBytes: number;
  totalBytesCost: number;
  totalCores: number;
  totalCoresCost: number;
  totalCost: number;
  usedBytes: number;
  usedCores: number;
}

interface Props {
  dataSource:
    | typeof DataSource.KUBERNETES_CLUSTER_USAGE
    | typeof DataSource.KUBERNETES_CONTAINER_USAGE
    | typeof DataSource.KUBERNETES_NODE_USAGE;
  isLoading: boolean;
  queryParams?: SearchParams;
  usageSummary?: KubernetesUsageSummary;
}

const defaultUsageSummary = {
  totalBytes: 0,
  totalBytesCost: 0,
  totalCores: 0,
  totalCoresCost: 0,
  totalCost: 0,
  usedBytes: 0,
  usedCores: 0,
};

export default function GCPKubernetesMeters({
  usageSummary = defaultUsageSummary,
  ...props
}: Props): JSX.Element {
  const theme = useTheme();

  const totalCost = usageSummary.totalCost;

  const totalWaste = getWastedCost(usageSummary);

  const usageLabel = `${copyText.gkeMetersUsedLabel} / ${
    props.dataSource === DataSource.KUBERNETES_CLUSTER_USAGE
      ? copyText.gkeMetersTotalLabel
      : copyText.gkeMetersRequestedLabel
  }`;

  const usedCores = formatNumber(usageSummary.usedCores, 2);

  const allCores = formatNumber(usageSummary.totalCores, 2);

  const usedBytes = prettyBytes(usageSummary.usedBytes);

  const allBytes = prettyBytes(usageSummary.totalBytes);

  const isFiltered =
    props.queryParams?.cluster ||
    props.queryParams?.namespace ||
    props.queryParams?.project ||
    props.queryParams?.workload;

  return (
    <Flex>
      <Box width="25%">
        <Text color={theme.text_color_secondary}>
          {`${copyText.gkeMetersTotalCostLabel} ${
            isFiltered ? copyText.selectionsAppliedLabel : ""
          }`}
        </Text>
        {!usageSummary || props.isLoading ? (
          <EmptyPlaceholder
            height="50px"
            icon={faList}
            loading={!usageSummary || props.isLoading}
            skeletonVariant="bar"
          />
        ) : (
          <Text bold fontSize={theme.h3_fontSize}>
            {formatCurrency({
              number: totalCost,
            })}
          </Text>
        )}
      </Box>
      <Box
        borderRight={`1px solid ${theme.border_color}`}
        marginHorizontal={theme.space_lg}
      />
      <Box width="25%">
        <Text color={theme.text_color_secondary}>
          {`${copyText.gkeMetersTotalWasteLabel} ${
            isFiltered ? copyText.selectionsAppliedLabel : ""
          }`}
        </Text>
        {!usageSummary || props.isLoading ? (
          <EmptyPlaceholder
            height="50px"
            icon={faList}
            loading={!usageSummary || props.isLoading}
            skeletonVariant="bar"
          />
        ) : (
          <Text bold fontSize={theme.h3_fontSize}>
            {formatCurrency({
              number: totalWaste,
            })}
          </Text>
        )}
      </Box>
      <Box
        borderRight={`1px solid ${theme.border_color}`}
        marginHorizontal={theme.space_lg}
      />
      <Box width="25%">
        <Text color={theme.text_color_secondary}>
          {`${usageLabel} ${copyText.gkeMetersCoresLabel} ${
            isFiltered ? copyText.selectionsAppliedLabel : ""
          }`}
        </Text>
        {!usageSummary || props.isLoading ? (
          <EmptyPlaceholder
            height="50px"
            icon={faList}
            loading={!usageSummary || props.isLoading}
            skeletonVariant="bar"
          />
        ) : (
          <Text bold fontSize={theme.h3_fontSize}>
            {`${usedCores} / ${allCores}`}
          </Text>
        )}
      </Box>
      <Box
        borderRight={`1px solid ${theme.border_color}`}
        marginHorizontal={theme.space_lg}
      />
      <Box width="25%">
        <Text color={theme.text_color_secondary}>
          {`${usageLabel} ${copyText.gkeMetersBytesLabel} ${
            isFiltered ? copyText.selectionsAppliedLabel : ""
          }`}
        </Text>
        {!usageSummary || props.isLoading ? (
          <EmptyPlaceholder
            height="50px"
            icon={faList}
            loading={!usageSummary || props.isLoading}
            skeletonVariant="bar"
          />
        ) : (
          <Text bold fontSize={theme.h3_fontSize}>
            {`${usedBytes} / ${allBytes}`}
          </Text>
        )}
      </Box>
    </Flex>
  );
}

function getWastedCost(metrics?: KubernetesUsageSummary): number {
  if (!metrics) return 0;

  const wastedCost =
    metrics.totalCoresCost * (1 - metrics.usedCores / metrics.totalCores) +
    metrics.totalBytesCost * (1 - metrics.usedBytes / metrics.totalBytes);

  return isFinite(wastedCost) ? wastedCost : 0;
}
