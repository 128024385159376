import copyText from "../ui-lib/copyText";
import { DataSource } from "./enums";
import { awsComputeUtilizationSchema } from "./schemas/awsComputeUtilization";
import { awsComputeVisibilitySchema } from "./schemas/awsComputeVisibility";
import { awsKubernetesNodeCostSchema } from "./schemas/awsKubernetesNodeCost";
import { awsKubernetesNodeUsageSchema } from "./schemas/awsKubernetesNodeUsage";
import { azureComputeVisibilitySchema } from "./schemas/azureComputeVisibility";
import { azureSqlVisibilitySchema } from "./schemas/azureSqlVisibility";
import { azureStorageVisibilitySchema } from "./schemas/azureStorageVisibility";
import { bigQueryCapacitySchema } from "./schemas/bigQueryCapacity";
import { bigQueryCostSchema } from "./schemas/bigQueryCost";
import { bigQueryStorageSchema } from "./schemas/bigQueryStorage";
import { bigQueryTimelineSchema } from "./schemas/bigQueryTimeline";
import { bigQueryUsageSchema } from "./schemas/bigQueryUsage";
import { billingSchema } from "./schemas/billingSchema";
import { carbonFootprintSchema } from "./schemas/carbonFootprint";
import { detailedBillingSchema } from "./schemas/detailedBilling";
import { gcpCloudSqlCostSchema } from "./schemas/gcpCloudSqlCost";
import { gcpCloudSqlUsageSchema } from "./schemas/gcpCloudSqlUsage";
import { gcpComputeVisibilitySchema } from "./schemas/gcpComputeVisibility";
import { gcpKubernetesContainerUsageSchema } from "./schemas/gcpKubernetesContainerUsage";
import { gcpKubernetesNodeUsageSchema } from "./schemas/gcpKubernetesNodeUsage";
import { snowflakeWarehouseUsageSchema } from "./schemas/snowflakeWarehouseUsage";

// Considering Ternary fontFamily and 0.875rem font-size
export const AVG_PIXEL_WIDTH_OF_CHARACTER = 7;
export const DEFAULT_CHART_GROUPINGS_LIMIT = 25;
export const REPORT_PDF_CHART_HEIGHT = 300;
export const REPORT_PDF_CHART_WIDTH = 750;

export const COMPARISON_KEY = "Previous";
export const DEFAULT_X_AXIS_KEY = "timestamp";
export const FORECASTED_KEY = "Forecasted";
export const NOT_SHOWN_KEY = copyText.dimensionOtherNotShown;
export const PERCENT_DIFFERENCE_KEY = "percentDifference";
export const PERCENTAGE_Y_AXIS_ID = "PERCENTAGE_Y_AXIS_ID";
export const PREVIOUS_TIMESTAMP_KEY = "prevTimestamp";
export const RAW_DIFFERENCE_KEY = "rawDifference";

//
// Maps
//

/* prettier-ignore */
export const availableMeasuresMap = {
  [DataSource.AWS_COMPUTE_UTILIZATION]: awsComputeUtilizationSchema.measures,
  [DataSource.AWS_COMPUTE_VISIBILITY]: awsComputeVisibilitySchema.measures,
  [DataSource.AWS_KUBERNETES_NODE_COST]: awsKubernetesNodeCostSchema.measures,
  [DataSource.AWS_KUBERNETES_NODE_USAGE]: awsKubernetesNodeUsageSchema.measures,
  [DataSource.AZURE_COMPUTE_VISIBILITY]: azureComputeVisibilitySchema.measures,
  [DataSource.AZURE_SQL_VISIBILITY]: azureSqlVisibilitySchema.measures,
  [DataSource.AZURE_STORAGE_VISIBILITY]: azureStorageVisibilitySchema.measures,
  [DataSource.BIGQUERY_CAPACITY_COMMITTED]: bigQueryCapacitySchema.measures,
  [DataSource.BIGQUERY_COST]: bigQueryCostSchema.measures,
  [DataSource.BIGQUERY_STORAGE]: bigQueryStorageSchema.measures,
  [DataSource.BIGQUERY_TIMELINE]: bigQueryTimelineSchema.measures,
  [DataSource.BIGQUERY_USAGE]: bigQueryUsageSchema.measures,
  [DataSource.BILLING]: billingSchema.measures,
  [DataSource.CARBON_FOOTPRINT]: carbonFootprintSchema.measures,
  [DataSource.CLOUD_SQL_COST]: gcpCloudSqlCostSchema.measures,
  [DataSource.CLOUD_SQL_INSTANCE_USAGE_DAILY]: gcpCloudSqlUsageSchema.measures,
  [DataSource.CLOUD_SQL_INSTANCE_USAGE]: gcpCloudSqlUsageSchema.measures,
  [DataSource.DETAILED_BILLING]: detailedBillingSchema.measures,
  [DataSource.GCP_COMPUTE_VISIBILITY]: gcpComputeVisibilitySchema.measures,
  [DataSource.KUBERNETES_CONTAINER_USAGE]: gcpKubernetesContainerUsageSchema.measures,
  [DataSource.KUBERNETES_NODE_USAGE]: gcpKubernetesNodeUsageSchema.measures,
  [DataSource.SNOWFLAKE_WAREHOUSE_USAGE]: snowflakeWarehouseUsageSchema.measures,
};

/* prettier-ignore */
export const defaultDimensionsMap = {
  [DataSource.AWS_COMPUTE_UTILIZATION]: awsComputeUtilizationSchema.dimensions,
  [DataSource.AWS_COMPUTE_VISIBILITY]: awsComputeVisibilitySchema.dimensions,
  [DataSource.AWS_KUBERNETES_NODE_COST]: awsKubernetesNodeCostSchema.dimensions,
  [DataSource.AWS_KUBERNETES_NODE_USAGE]: awsKubernetesNodeUsageSchema.dimensions,
  [DataSource.AZURE_COMPUTE_VISIBILITY]: azureComputeVisibilitySchema.dimensions,
  [DataSource.AZURE_SQL_VISIBILITY]: azureSqlVisibilitySchema.dimensions,
  [DataSource.AZURE_STORAGE_VISIBILITY]: azureStorageVisibilitySchema.dimensions,
  [DataSource.BIGQUERY_COST]: bigQueryCostSchema.dimensions,
  [DataSource.BIGQUERY_STORAGE]: bigQueryStorageSchema.dimensions,
  [DataSource.BIGQUERY_USAGE]: bigQueryUsageSchema.dimensions,
  [DataSource.BILLING]: billingSchema.dimensions,
  [DataSource.CARBON_FOOTPRINT]: carbonFootprintSchema.dimensions,
  [DataSource.CLOUD_SQL_COST]: gcpCloudSqlCostSchema.dimensions,
  [DataSource.CLOUD_SQL_INSTANCE_USAGE_DAILY]: gcpCloudSqlUsageSchema.dimensions,
  [DataSource.CLOUD_SQL_INSTANCE_USAGE]: gcpCloudSqlUsageSchema.dimensions,
  [DataSource.DETAILED_BILLING]: detailedBillingSchema.dimensions,
  [DataSource.EXTERNAL_METRICS]: [],
  [DataSource.GCP_COMPUTE_VISIBILITY]: gcpComputeVisibilitySchema.dimensions,
  [DataSource.KUBERNETES_CONTAINER_USAGE]: gcpKubernetesContainerUsageSchema.dimensions,
  [DataSource.KUBERNETES_NODE_USAGE]: gcpKubernetesNodeUsageSchema.dimensions,
  [DataSource.SNOWFLAKE_WAREHOUSE_USAGE]: snowflakeWarehouseUsageSchema.dimensions,
};

export const dataSourcesWithMeasures = Object.keys(
  availableMeasuresMap
) as (keyof typeof availableMeasuresMap)[];
