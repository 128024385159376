import { useFeatureText } from "@/context/FeatureTextProvider";
import useRefFn from "@/hooks/useRefFn";
import Checkbox from "@/ui-lib/components/Checkbox";
import IconAzureA from "@/ui-lib/icons/IconAzureA";
import IconGCPCloud from "@/ui-lib/icons/IconGCPCloud";
import { useTheme } from "@emotion/react";
import { faAws } from "@fortawesome/free-brands-svg-icons";
import { faGear } from "@fortawesome/pro-light-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React, { useMemo } from "react";
import Switch from "../../../ui-lib/components/Switch";
import _copyText from "../copyText";

type TableData = {
  id: string;
  allowAwsRateRecs: boolean;
  allowAwsUsageRecs: boolean;
  allowAzureRateRecs: boolean;
  allowAzureUsageRecs: boolean;
  allowCloudConfiguration: boolean;
  allowGcpRateRecs: boolean;
  allowGcpUsageRecs: boolean;
  isSelected: boolean;
  name: string;
};

const columnHelper = createColumnHelper<TableData>();

interface Props {
  data: {
    id: string;
    allowAwsRateRecs: boolean;
    allowAwsUsageRecs: boolean;
    allowAzureRateRecs: boolean;
    allowAzureUsageRecs: boolean;
    allowCloudConfiguration: boolean;
    allowGcpRateRecs: boolean;
    allowGcpUsageRecs: boolean;
    isSelected: boolean;
    name: string;
  }[];
  isLoading: boolean;
  selectedSettingsIDs: string[] | null;
  onInteraction: (interaction: MspTenantSettingsTable.Interaction) => void;
}
export function MspTenantSettingsTable(props: Props) {
  const theme = useTheme();

  const { copyText } = useFeatureText(_copyText);

  const handleMultiSelectAll = useRefFn(() => {
    const selectedIDSet = new Set(props.selectedSettingsIDs);

    const visibleIDs = props.data.map((settings) => settings.id);

    const visibleSelectedIDs = visibleIDs.filter((id) => selectedIDSet.has(id));

    let selectedIDs: string[];
    if (visibleSelectedIDs.length > 0) {
      selectedIDs = [];
    } else {
      selectedIDs = visibleIDs;
    }

    props.onInteraction({
      type: MspTenantSettingsTable.INTERACTION_EDIT_MULTIPLE_IDS_CLICKED,
      settingsIDs: selectedIDs,
    });
  });

  const columns = useMemo(() => {
    return [
      columnHelper.group({
        id: "Tenants",
        header: () => {
          return (
            <Flex alignItems="center">
              <Text>{copyText.tableHeaderTenants}</Text>
              <Box
                marginLeft={theme.space_xxs}
                backgroundColor={theme.background_color}
                border={`1px solid ${theme.border_color}`}
                borderRadius={theme.borderRadius_3}
                paddingHorizontal={theme.space_xxs}
              >
                <Text>{props.data?.length ?? 0}</Text>
              </Box>
            </Flex>
          );
        },
        columns: [
          columnHelper.accessor("name", {
            header: (headerContext) => {
              const rows = headerContext.table.getRowModel().rows;

              const checkedCount = rows.reduce(
                (count, row) => (row.original.isSelected ? count + 1 : count),
                0
              );
              return (
                <Flex alignItems="center">
                  <Checkbox
                    checked={checkedCount > 0 && checkedCount === rows.length}
                    dashed={checkedCount > 0}
                    onChange={handleMultiSelectAll}
                  />
                  <Box marginLeft={theme.space_xs}>
                    <Text>{copyText.tableHeaderName}</Text>
                  </Box>
                </Flex>
              );
            },
            cell: ({ getValue, row }) => (
              <Flex alignItems="center">
                <Checkbox
                  checked={row.original.isSelected}
                  onChange={() =>
                    props.onInteraction({
                      type: MspTenantSettingsTable.INTERACTION_EDIT_SINGLE_IDS_CLICKED,
                      settingsID: row.original.id,
                    })
                  }
                />
                <Box marginLeft={theme.space_xs}>
                  <Text>{getValue()}</Text>
                </Box>
              </Flex>
            ),
          }),
        ],
      }),
      columnHelper.group({
        id: "Integrations",
        header: () => <Text>{copyText.tableHeaderIntegrations}</Text>,

        columns: [
          columnHelper.accessor("allowCloudConfiguration", {
            header: () => {
              return (
                <Flex alignItems="center">
                  <Box marginRight={theme.space_xs}>
                    <Icon icon={faGear} />
                  </Box>
                  <Text>{copyText.tableHeaderConfiguration}</Text>
                </Flex>
              );
            },
            cell: ({ row }) => {
              return (
                <Flex alignItems="center">
                  <Switch
                    checked={row.original.allowCloudConfiguration}
                    size="small"
                    onChange={(checked) =>
                      props.onInteraction({
                        type: MspTenantSettingsTable.INTERACTION_SWITCH_ON_CHANGE,
                        settingsID: row.original.id,
                        config: { allowCloudConfiguration: checked },
                      })
                    }
                  />
                  <Text marginLeft={theme.space_xs}>
                    {copyText.switchLabelAllowConfiguration}
                  </Text>
                </Flex>
              );
            },
            meta: {
              borderStyle: { borderRight: `1px solid ${theme.border_color}` },
            },
          }),
        ],
        meta: {
          borderStyle: { borderRight: `1px solid ${theme.border_color}` },
        },
      }),

      columnHelper.group({
        id: "Optimizations",
        header: () => <Text>{copyText.tableHeaderOptimizations}</Text>,
        columns: [
          columnHelper.accessor("allowAwsRateRecs", {
            header: () => {
              return (
                <Flex alignItems="center">
                  <Box marginRight={theme.space_xs}>
                    <Icon icon={faAws} size="lg" />
                  </Box>
                  <Text>{copyText.tableHeaderAws}</Text>
                </Flex>
              );
            },
            cell: ({ row }) => {
              return (
                <Flex
                  justifyContent="space-between"
                  flex={1}
                  paddingRight={theme.space_lg}
                >
                  <Flex alignItems="center">
                    <Switch
                      checked={row.original.allowAwsRateRecs}
                      size="small"
                      onChange={(checked) =>
                        props.onInteraction({
                          type: MspTenantSettingsTable.INTERACTION_SWITCH_ON_CHANGE,
                          settingsID: row.original.id,
                          config: { allowAwsRateRecs: checked },
                        })
                      }
                    />
                    <Text marginLeft={theme.space_xs}>
                      {copyText.switchLabelRate}
                    </Text>
                  </Flex>
                  <Flex alignItems="center">
                    <Switch
                      checked={row.original.allowAwsUsageRecs}
                      size="small"
                      onChange={(checked) =>
                        props.onInteraction({
                          type: MspTenantSettingsTable.INTERACTION_SWITCH_ON_CHANGE,
                          settingsID: row.original.id,
                          config: { allowAwsUsageRecs: checked },
                        })
                      }
                    />
                    <Text marginLeft={theme.space_xs}>
                      {copyText.switchLabelUsage}
                    </Text>
                  </Flex>
                </Flex>
              );
            },
            meta: {
              borderStyle: { borderRight: `1px solid ${theme.border_color}` },
            },
          }),
          columnHelper.accessor("allowAzureRateRecs", {
            header: () => {
              return (
                <Flex alignItems="center">
                  <IconAzureA size={16} />
                  <Text marginLeft={theme.space_xs}>
                    {copyText.tableHeaderAzure}
                  </Text>
                </Flex>
              );
            },
            cell: ({ row }) => {
              return (
                <Flex
                  justifyContent="space-between"
                  flex={1}
                  paddingRight={theme.space_lg}
                >
                  <Flex alignItems="center">
                    <Switch
                      checked={row.original.allowAzureRateRecs}
                      size="small"
                      onChange={(checked) =>
                        props.onInteraction({
                          type: MspTenantSettingsTable.INTERACTION_SWITCH_ON_CHANGE,
                          settingsID: row.original.id,
                          config: { allowAzureRateRecs: checked },
                        })
                      }
                    />
                    <Text marginLeft={theme.space_xs}>
                      {copyText.switchLabelRate}
                    </Text>
                  </Flex>
                  <Flex alignItems="center">
                    <Switch
                      checked={row.original.allowAzureUsageRecs}
                      size="small"
                      onChange={(checked) =>
                        props.onInteraction({
                          type: MspTenantSettingsTable.INTERACTION_SWITCH_ON_CHANGE,
                          settingsID: row.original.id,
                          config: { allowAzureUsageRecs: checked },
                        })
                      }
                    />
                    <Text marginLeft={theme.space_xs}>
                      {copyText.switchLabelUsage}
                    </Text>
                  </Flex>
                </Flex>
              );
            },
            meta: {
              borderStyle: { borderRight: `1px solid ${theme.border_color}` },
            },
          }),
          columnHelper.accessor("allowGcpRateRecs", {
            header: () => {
              return (
                <Flex alignItems="center">
                  <IconGCPCloud size={16} />
                  <Text marginLeft={theme.space_xs}>
                    {copyText.tableHeaderGcp}
                  </Text>
                </Flex>
              );
            },
            cell: ({ row }) => {
              return (
                <Flex
                  justifyContent="space-between"
                  flex={1}
                  paddingRight={theme.space_lg}
                >
                  <Flex alignItems="center">
                    <Switch
                      checked={row.original.allowGcpRateRecs}
                      size="small"
                      onChange={(checked) =>
                        props.onInteraction({
                          type: MspTenantSettingsTable.INTERACTION_SWITCH_ON_CHANGE,
                          settingsID: row.original.id,
                          config: { allowGcpRateRecs: checked },
                        })
                      }
                    />
                    <Text marginLeft={theme.space_xs}>
                      {copyText.switchLabelRate}
                    </Text>
                  </Flex>
                  <Flex alignItems="center">
                    <Switch
                      checked={row.original.allowGcpUsageRecs}
                      size="small"
                      onChange={(checked) =>
                        props.onInteraction({
                          type: MspTenantSettingsTable.INTERACTION_SWITCH_ON_CHANGE,
                          settingsID: row.original.id,
                          config: { allowGcpUsageRecs: checked },
                        })
                      }
                    />
                    <Text marginLeft={theme.space_xs}>
                      {copyText.switchLabelUsage}
                    </Text>
                  </Flex>
                </Flex>
              );
            },
          }),
        ],
        size: 450,
      }),
    ];
  }, [props.data]);

  return (
    <Table
      columns={columns}
      data={props.data}
      isLoading={props.isLoading}
      isRowHighlighted={(datum) => {
        return props.selectedSettingsIDs?.includes(datum.id) ?? false;
      }}
      showPagination
      sortable
      autoResetPageIndex={false}
      alternateRows
    />
  );
}

MspTenantSettingsTable.INTERACTION_SWITCH_ON_CHANGE =
  "MspTenantSettingsTable.INTERACTION_SWITCH_ON_CHANGE" as const;

MspTenantSettingsTable.INTERACTION_EDIT_MULTIPLE_IDS_CLICKED =
  "MspTenantSettingsTable.INTERACTION_EDIT_MULTIPLE_IDS_CLICKED" as const;

MspTenantSettingsTable.INTERACTION_EDIT_SINGLE_IDS_CLICKED =
  "MspTenantSettingsTable.INTERACTION_EDIT_SINGLE_IDS_CLICKED" as const;

interface InteractionSwitchOnChange {
  type: typeof MspTenantSettingsTable.INTERACTION_SWITCH_ON_CHANGE;
  settingsID: string;
  config: {
    allowAwsRateRecs?: boolean;
    allowAwsUsageRecs?: boolean;
    allowAzureRateRecs?: boolean;
    allowAzureUsageRecs?: boolean;
    allowCloudConfiguration?: boolean;
    allowGcpRateRecs?: boolean;
    allowGcpUsageRecs?: boolean;
  };
}
interface InteractionEditMultiSettingsClicked {
  type: typeof MspTenantSettingsTable.INTERACTION_EDIT_MULTIPLE_IDS_CLICKED;
  settingsIDs: string[];
}

type InteractionEditSingleTagsClicked = {
  type: typeof MspTenantSettingsTable.INTERACTION_EDIT_SINGLE_IDS_CLICKED;
  settingsID: string;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MspTenantSettingsTable {
  export type Interaction =
    | InteractionSwitchOnChange
    | InteractionEditSingleTagsClicked
    | InteractionEditMultiSettingsClicked;
}

export default MspTenantSettingsTable;
