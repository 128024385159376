import { UpdateOracleIntegrationParams } from "@/api/core/types";
import paths from "@/constants/paths";
import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import queryKeys from "@/features/admin/hooks/queryKeys";
import useCreateOracleIntegration from "@/features/admin/hooks/useCreateOracleIntegration";
import useUpdateOracleIntegration from "@/features/admin/hooks/useUpdateOracleIntegration";
import useValidateDataIntegration from "@/features/admin/hooks/useValidateDataIntegration";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import { useNavigateWithSearchParams } from "@/lib/react-router";
import { VerticalStepperForm } from "@/ui-lib/components/Stepper";
import { AlertType, postAlert } from "@/utils/alerts";
import { useTheme } from "@emotion/react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FormApi } from "@tanstack/react-form";
import { useQueryClient } from "@tanstack/react-query";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import { OracleIntegrationEntity } from "@ternary/api-lib/core/types/OracleIntegration";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { get, isEqual, reduce } from "lodash";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import copyText from "../../copyText";
import { useGetOracleIntegrationByID } from "../../hooks/useGetOracleIntegrationByID";
import { defaultValues as _defaultValues } from "./constants";
import { OracleIntegrationFormCustom } from "./OracleIntegrationFormCustom";
import { OracleIntegrationFormGeneral } from "./OracleIntegrationFormGeneral";
import { FormData } from "./types";

export function OracleIntegrationFormContainer() {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const location = useLocation();
  const navigate = useNavigateWithSearchParams();
  const queryClient = useQueryClient();
  const theme = useTheme();

  const { integrationID } = useParams();

  //
  // State
  //

  const oracleIntegrationLocation: OracleIntegrationEntity | undefined = get(
    location.state,
    "integration"
  );

  //
  // Queries
  //

  // If coming from a fresh session we need to fetch the integration from the server,
  // because it won't exist on location state.
  const {
    data: oracleIntegrationServer,
    isLoading: isLoadingOracleIntegration,
  } = useGetOracleIntegrationByID(integrationID ?? "", {
    enabled: !!integrationID && !oracleIntegrationLocation,
  });

  //
  // Mutations
  //

  const {
    isPending: isCreatingOracleIntegration,
    mutate: createOracleIntegration,
  } = useCreateOracleIntegration({
    onError: () => {
      postAlert({
        message: copyText.errorCreatingOracleIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (integrationID) => {
      validateDataIntegration({ integrationID });

      postAlert({
        message: copyText.successCreatingOracleIntegrationMessage,
        type: AlertType.SUCCESS,
      });

      navigate(paths._admin, { searchParams: { tab: "integrations" } });
    },
  });

  const {
    isPending: isUpdatingOracleIntegration,
    mutate: updateOracleIntegration,
  } = useUpdateOracleIntegration({
    onError: () => {
      postAlert({
        message: copyText.errorUpdatingOracleIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (integrationID) => {
      validateDataIntegration({ integrationID });

      postAlert({
        message: copyText.successUpdatingOracleIntegrationMessage,
        type: AlertType.SUCCESS,
      });

      navigate(paths._admin, { searchParams: { tab: "integrations" } });
    },
  });

  const { mutate: validateDataIntegration } = useValidateDataIntegration({
    onError: () => {
      postAlert({
        message: copyText.errorValidatingDataIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSettled: () => {
      return queryClient.invalidateQueries({
        queryKey: [queryKeys.dataIntegrations, authenticatedUser.tenant.id],
      });
    },
  });

  //
  // Computed Values
  //

  // If use integration on location state first, if we have it.
  const oracleIntegration =
    oracleIntegrationLocation ?? oracleIntegrationServer;
  const isMspSharedIntegration =
    !!oracleIntegration?.mspChildSharedPayerConfiguration;

  //
  // Interaction Handlers
  //

  const defaultValues = oracleIntegration
    ? getValuesFromIntegration(oracleIntegration)
    : _defaultValues;

  function handleSubmit({
    formApi,
    value,
  }: {
    formApi: FormApi<FormData>;
    value: FormData;
  }) {
    if (oracleIntegration) {
      const defaultValues = formApi.options.defaultValues;

      if (!defaultValues) return;

      const updatedIntegration = {
        ...defaultValues,
        ...value,
      };

      handleUpdateOracleIntegration(
        oracleIntegration.id,
        updatedIntegration,
        defaultValues
      );
    } else {
      handleCreateOracleIntegration(authenticatedUser.tenant.id, value);
    }
  }

  function handleCreateOracleIntegration(tenantID: string, value: FormData) {
    activityTracker.captureAction(actions.CLICK_ADMIN_CREATE_INTEGRATION, {
      type: CloudProviderType.OCI,
    });

    createOracleIntegration({
      tenantID: tenantID,
      name: value.name.trim(),
      tenancyOCID: value.tenancyOCID.trim(),
      userOCID: value.userOCID.trim(),
      region: value.region,
      storageBucket: value.storageBucket.trim(),
      storageNamespace: value.storageNamespace.trim(),
    });
  }

  function handleUpdateOracleIntegration(
    integrationID: string,
    integration: FormData,
    defaultValues: FormData
  ) {
    const changes = getChanges(integration, defaultValues);

    activityTracker.captureAction(actions.CLICK_ADMIN_UPDATE_INTEGRATION, {
      type: CloudProviderType.OCI,
    });

    updateOracleIntegration({
      integrationID,
      ...changes,
    });
  }

  const steps = [
    {
      component: (form) => (
        <OracleIntegrationFormGeneral
          form={form}
          isMspSharedIntegration={isMspSharedIntegration}
          showCertificate={!!oracleIntegration}
        />
      ),
      label: copyText.oracleFormTabLabelGeneral,
      requiredInputs: isMspSharedIntegration
        ? undefined
        : ["name", "tenancyOCID", "userOCID", "region"],
    },
    {
      component: (form) => <OracleIntegrationFormCustom form={form} />,
      label: copyText.oracleFormTabLabelCustom,
      isOptional: true,
    },
    {
      component: () => (
        <Box width="50%">
          <Text appearance="h4">{copyText.createIntegrationHeader}</Text>
          <Text marginBottom={theme.space_lg}>
            {copyText.integrationLastInstructions}
          </Text>
        </Box>
      ),
      label: copyText.submitButtonLabel,
    },
  ];

  return (
    <Flex direction="column" height="80vh">
      <Button
        iconStart={<Icon icon={faArrowLeft} />}
        secondary
        width={200}
        onClick={() =>
          navigate(paths._admin, { searchParams: { tab: "integrations" } })
        }
      >
        {copyText.backToIntegrationsButtonLabel}
      </Button>
      <Text appearance="h3" marginVertical={theme.space_md}>
        {oracleIntegration
          ? oracleIntegration.name
          : copyText.oracleFormNewOracleIntegrationHeader}
      </Text>
      <Flex height="100%" width="100%">
        {isLoadingOracleIntegration ? (
          <EmptyPlaceholder loading width="50%" />
        ) : (
          <VerticalStepperForm
            defaultValues={defaultValues}
            isProcessing={
              isCreatingOracleIntegration || isUpdatingOracleIntegration
            }
            steps={steps}
            onSubmit={handleSubmit}
          />
        )}
      </Flex>
    </Flex>
  );
}

function getChanges(values: FormData, defaultValues: FormData) {
  const changes: UpdateOracleIntegrationParams = reduce(
    defaultValues,
    (result, value, key) => {
      const currValue = get(values, key);
      if (!isEqual(value, currValue)) {
        result[key] = currValue ? currValue.trim() : currValue;
      }

      return result;
    },
    {}
  );

  return changes;
}

function getValuesFromIntegration(integration: OracleIntegrationEntity) {
  return {
    // General
    name: integration.name ?? "",
    tenancyOCID: integration.tenancyOCID ?? "",
    userOCID: integration.userOCID ?? "",
    region: integration.region ?? "",
    storageBucket: integration.storageBucket ?? "",
    storageNamespace: integration.storageNamespace ?? "",
  };
}
