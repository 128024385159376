import IconAzureVM from "@/ui-lib/icons/IconAzureVM";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import Box from "@ternary/web-ui-lib/components/Box";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import paths from "../../../constants/paths";
import useGatekeeper from "../../../hooks/useGatekeeper";
import useGetChangePercent from "../../../hooks/useGetChangePercent";
import IconAwsEc2 from "../../../ui-lib/icons/IconAwsEc2";
import IconGCPCloudRun from "../../../ui-lib/icons/IconGcpCloudRun";
import IconGCPComputeEngine from "../../../ui-lib/icons/IconGcpComputeEngine";
import copyText from "../copyText";
import useGetComputeSpendSummary from "../hooks/useGetComputeSpendSummary";
import useGetCostSpendSummaries from "../hooks/useGetCostSpendSummaries";
import NotConfiguredSectionBadge from "./NotConfiguredSectionBadge";
import ServiceSectionBadge from "./ServiceSectionBadge";

export default function ComputInsightsLandingPage() {
  const gatekeeper = useGatekeeper();

  //
  // Queries
  //

  const azureVMSpendSummaries = useGetComputeSpendSummary({
    dataSource: DataSource.AZURE_COMPUTE_VISIBILITY,
  });

  const {
    data: currentAzureVmMtdSpend,
    isLoading: isLoadingCurrentAzureVmMtdSpend,
  } = azureVMSpendSummaries[0];

  const { data: lastAzureVmMtdSpend, isLoading: isLoadingLastAzureVmMtdSpend } =
    azureVMSpendSummaries[2];

  const azureVmMtdSpendComparison = useGetChangePercent({
    biggerIsBetter: false,
    comparison: lastAzureVmMtdSpend?.totalCost ?? 0,
    value: currentAzureVmMtdSpend?.totalCost ?? 0,
  });

  const cloudRunSpendSummaries = useGetCostSpendSummaries({
    dataSource: DataSource.CLOUD_RUN,
  });

  const {
    data: currentCloudRunMtdSpend,
    isLoading: isLoadingCurrentCloudRunMtdSpend,
  } = cloudRunSpendSummaries[0];

  const {
    data: lastCloudRunMtdSpend,
    isLoading: isLoadingLastCloudRunMtdSpend,
  } = cloudRunSpendSummaries[2];

  const cloudRunMtdSpendComparison = useGetChangePercent({
    biggerIsBetter: false,
    comparison: lastCloudRunMtdSpend?.cost ?? 0,
    value: currentCloudRunMtdSpend?.cost ?? 0,
  });

  const gcpComputeSpendSummaries = useGetComputeSpendSummary({
    dataSource: DataSource.GCP_COMPUTE_VISIBILITY,
  });

  const { data: currentGcpMtdSpend, isLoading: isLoadingCurrentGcpMtdSpend } =
    gcpComputeSpendSummaries[0];

  const { data: lastGcpMtdSpend, isLoading: isLoadingLastGcpMtdSpend } =
    gcpComputeSpendSummaries[2];

  const gcpMtdSpendComparison = useGetChangePercent({
    biggerIsBetter: false,
    comparison: lastGcpMtdSpend?.totalCost ?? 0,
    value: currentGcpMtdSpend?.totalCost ?? 0,
  });

  const awsComputeSpendSummaries = useGetComputeSpendSummary({
    dataSource: DataSource.AWS_COMPUTE_VISIBILITY,
  });

  const { data: currentAwsMtdSpend, isLoading: isLoadingCurrentAwsMtdSpend } =
    awsComputeSpendSummaries[0];

  const { data: lastAwsMtdSpend, isLoading: isLoadingLastAwsMtdSpend } =
    awsComputeSpendSummaries[2];

  const awsMtdSpendComparison = useGetChangePercent({
    biggerIsBetter: false,
    comparison: lastAwsMtdSpend?.totalCost ?? 0,
    value: currentAwsMtdSpend?.totalCost ?? 0,
  });

  //
  // Render
  //

  const isLoadingAWSSpend =
    isLoadingCurrentAwsMtdSpend || isLoadingLastAwsMtdSpend;

  const isLoadingCloudRunSpend =
    isLoadingCurrentCloudRunMtdSpend || isLoadingLastCloudRunMtdSpend;

  const isLoadingGCPSpend =
    isLoadingCurrentGcpMtdSpend || isLoadingLastGcpMtdSpend;

  const isLoadingAzureSpend =
    isLoadingCurrentAzureVmMtdSpend || isLoadingLastAzureVmMtdSpend;

  return (
    <Box>
      {gatekeeper.hasAWSIntegration && (
        <>
          <Text appearance="h3">{copyText.awsServicesListTitle}</Text>
          <ServiceSectionBadge
            currentMTDSpend={currentAwsMtdSpend?.totalCost ?? 0}
            isLoading={isLoadingAWSSpend}
            mtdSpendComparison={awsMtdSpendComparison}
            path={paths._insightsComputeEC2}
            serviceIcon={<IconAwsEc2 size={50} />}
            serviceName={copyText.elasticComputeCloudTitle}
          />
        </>
      )}
      {gatekeeper.hasAzureIntegration && (
        <>
          <Text appearance="h3">{copyText.azureServicesListTitle}</Text>
          <ServiceSectionBadge
            currentMTDSpend={currentAzureVmMtdSpend?.totalCost ?? 0}
            isLoading={isLoadingAzureSpend}
            mtdSpendComparison={azureVmMtdSpendComparison}
            path={paths._insightsComputeAzureVM}
            serviceIcon={<IconAzureVM size={50} />}
            serviceName={copyText.azureVMsComputeCloudTitle}
          />
        </>
      )}

      {gatekeeper.hasGCPIntegration && (
        <>
          <Text appearance="h3">{copyText.gcpServicesListTitle}</Text>
          <ServiceSectionBadge
            currentMTDSpend={currentGcpMtdSpend?.totalCost ?? 0}
            isLoading={isLoadingGCPSpend}
            mtdSpendComparison={gcpMtdSpendComparison}
            path={paths._insightsComputeGCE}
            serviceIcon={<IconGCPComputeEngine size={50} />}
            serviceName={copyText.computeEngineTitle}
          />
          <ServiceSectionBadge
            currentMTDSpend={currentCloudRunMtdSpend?.cost ?? 0}
            isLoading={isLoadingCloudRunSpend}
            mtdSpendComparison={cloudRunMtdSpendComparison}
            path={paths._insightsComputeCloudRun}
            serviceIcon={<IconGCPCloudRun size={50} />}
            serviceName={copyText.cloudRunTitle}
          />
        </>
      )}
      {!gatekeeper.hasAWSIntegration && (
        <>
          <Text appearance="h3">
            {copyText.awsServicesListTitleNotConfigured}
          </Text>
          <NotConfiguredSectionBadge
            cloudProviderType={CloudProviderType.AWS}
            serviceIcon={<IconAwsEc2 size={50} />}
            serviceName={copyText.elasticComputeCloudTitle}
          />
        </>
      )}

      {!gatekeeper.hasAzureIntegration && (
        <>
          <Text appearance="h3">
            {copyText.azureServicesListTitleNotConfigured}
          </Text>
          <NotConfiguredSectionBadge
            cloudProviderType={CloudProviderType.AZURE}
            serviceIcon={<IconAzureVM size={50} />}
            serviceName={copyText.azureVMsComputeCloudTitle}
          />
        </>
      )}

      {!gatekeeper.hasGCPIntegration && (
        <>
          <Text appearance="h3">
            {copyText.gcpServicesListTitleNotConfigured}
          </Text>
          <NotConfiguredSectionBadge
            cloudProviderType={CloudProviderType.GCP}
            serviceIcon={<IconGCPComputeEngine size={50} />}
            serviceName={copyText.computeEngineTitle}
          />
        </>
      )}
    </Box>
  );
}
