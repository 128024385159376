import { createStructParam } from "@/lib/use-query-params";
import { createSortingUtils } from "@/utils/sort";
import { createColumnHelper } from "@tanstack/react-table";
import {
  formatCurrency,
  formatPercentage,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import { GCPComputeInstance } from "../types";

type TableData = {
  cpuUtilization: number;
  diskUtilization: number;
  instanceId: string;
  instanceName: string;
  instanceType: string;
  productMemoryBytes: number;
  productVCPU: number;
  provisionedDiskBytes: number;
  ramUtilization: number;
  totalCost: number;
};

type Props = {
  instances: GCPComputeInstance[];
  isLoadingInstances: boolean;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

const { numberSort, stringSort } = createSortingUtils<TableData>();

export default function GCPComputeInstanceTable(props: Props) {
  const columnHelper = createColumnHelper<TableData>();

  const [sortRule, setSortRule] = useQueryParam(
    "instance_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "totalCost",
    })
  );

  const tableData = useMemo(
    () => getTableData(props.instances),
    [props.instances]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("instanceName", {
        cell: ({ getValue }) => (
          <>{getValue() || copyText.gcpComputeTableNull}</>
        ),
        header: copyText.gcpComputeTableInstancesHeader_instanceName,
        meta: { align: "left" },
        size: 180,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("instanceId", {
        cell: ({ getValue }) => (
          <>{getValue() || copyText.gcpComputeTableNull}</>
        ),
        header: copyText.gcpComputeTableInstancesHeader_instanceId,
        meta: { align: "left" },
        size: 180,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("totalCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.gcpComputeTableInstancesHeader_totalCost,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("instanceType", {
        cell: ({ getValue }) => (
          <>{getValue() || copyText.gcpComputeTableNull}</>
        ),
        header: copyText.gcpComputeTableInstancesHeader_instanceType,
        meta: { align: "right" },
        size: 125,
        sortingFn: stringSort,
      }),
      columnHelper.accessor("productVCPU", {
        cell: ({ getValue }) => getValue(),
        header: copyText.gcpComputeTableInstancesHeader_productVCPU,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("cpuUtilization", {
        cell: ({ getValue }) => <>{formatPercentage(getValue())}</>,
        header: copyText.gcpComputeTableInstancesHeader_cpuUtilization,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("productMemoryBytes", {
        cell: ({ getValue }) => (
          <>{prettyBytes(getValue(), { binary: true })}</>
        ),
        header: copyText.gcpComputeTableInstancesHeader_productMemoryBytes,
        meta: { align: "right" },
        size: 125,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("ramUtilization", {
        cell: ({ getValue }) => <>{formatPercentage(getValue())}</>,
        header: copyText.gcpComputeTableInstancesHeader_ramUtilization,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("provisionedDiskBytes", {
        cell: ({ getValue }) => <>{prettyBytes(getValue())}</>,
        header: copyText.gcpComputeTableInstancesHeader_provisionedDiskBytes,
        meta: { align: "right" },
        size: 125,
        sortingFn: numberSort,
      }),
      columnHelper.accessor("diskUtilization", {
        cell: ({ getValue }) => <>{formatPercentage(getValue())}</>,
        header: copyText.gcpComputeTableInstancesHeader_diskUtilization,
        meta: { align: "right" },
        size: 100,
        sortingFn: numberSort,
      }),
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={tableData}
      initialState={{ sorting: [sortRule] }}
      isLoading={props.isLoadingInstances}
      showPagination
      sortable
      truncateRows
      onChangeSortBy={([sortRule]) => setSortRule(sortRule, "replaceIn")}
    />
  );
}

function getTableData(instances: GCPComputeInstance[]): TableData[] {
  return instances.map((instance) => ({
    cpuUtilization: instance.cpuUtilization,
    diskUtilization: instance.diskUtilization,
    instanceId: instance.instanceId,
    instanceName: instance.instanceName,
    instanceType: instance.instanceType,
    productMemoryBytes: instance.productMemoryBytes,
    productVCPU: instance.productVCPU,
    provisionedDiskBytes: instance.provisionedDiskBytes,
    ramUtilization: instance.ramUtilization,
    totalCost: instance.totalCost,
  }));
}
