import { getOrderedCategories } from "@/features/admin/components/GlobalPreferencesManagementContainer";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { sortBy } from "lodash";

type Option = {
  label: string;
  value: string;
};

export type Options = {
  label: string;
  options: Option[];
};

type Preference = {
  dataSource: DataSource;
  category: string;
  values: string[];
};

export function isGroupedOptions(option: Option | Options): option is Options {
  return option && (option as Options)?.options?.length > 0;
}

export function groupOptionsByPreferences(
  options: Option[],
  preferences: Preference[],
  dataSource: DataSource
): Option[] | Options[] {
  if (!preferences.some((label) => label.dataSource === dataSource)) {
    return options;
  }

  const dataSourcePreferences = preferences.filter(
    (preference) => preference.dataSource === dataSource
  );

  const allCategories = [
    ...getOrderedCategories(dataSourcePreferences),
    "OTHER",
  ];

  if (allCategories.at(-2) === "HIDDEN") {
    // if hidden is the last user-defined category, put it after other
    allCategories.splice(-2, 1);
    allCategories.push("HIDDEN");
  }

  const dict: Record<string, Option[] | undefined> = Object.fromEntries(
    allCategories.map((category) => [category, []])
  );

  options.forEach((option) => {
    const optionPreference = dataSourcePreferences.find((preference) =>
      preference.values.some((val) => val === option.value)
    );

    const optionCategoryName = optionPreference
      ? optionPreference.category
      : "OTHER";

    let optionCategory = dict[optionCategoryName];

    if (!optionCategory) {
      optionCategory = [];
    }

    optionCategory.push({
      value: option.value,
      label: option.value,
    });
  });

  const groupedOptions: Options[] = [];

  allCategories.forEach((category) => {
    const categoryOptions = dict[category];

    if (!categoryOptions || categoryOptions.length === 0) {
      return;
    }

    if (category === "HIDDEN") {
      return;
    }

    groupedOptions.push({
      label: category,
      options: categoryOptions,
    });
  });

  groupedOptions.forEach((optionGroup) => {
    optionGroup.options = sortBy(optionGroup.options, (option) =>
      option.label.toLowerCase()
    );
  });

  return groupedOptions;
}
