import { DataSource, Operator, TimeGranularity } from "../enums";
import { QueryFilter } from "../types";
import { AnalyticsApiClient } from "./AnalyticsApiClient";
import DataQuery from "./DataQuery";

type BudgetSpendDatum = {
  cost?: number;
  customNetCost?: number;
  netCost?: number;
  timestamp: string;
};

type BudgetScope = {
  key: string;
  values: string[];
};

type Budget = {
  measure: string;
  scopes?: BudgetScope[];
};

interface Params {
  budget: Budget;
  client: AnalyticsApiClient;
  dateRange: Date[];
  granularity?: TimeGranularity;
  tenantID: string;
}

export default async function getBudgetData(
  params: Params
): Promise<BudgetSpendDatum[]> {
  const result = await params.client.loadData<BudgetSpendDatum>(
    params.tenantID,
    new DataQuery({
      dataSource: DataSource.BILLING,
      dateRange: params.dateRange,
      granularity: params.granularity ?? TimeGranularity.DAY,
      measures: [params.budget.measure],
      preAggFilters: getBudgetFilters(params.budget.scopes ?? []),
    })
  );

  return result.response;
}

export function getBudgetFilters(scopes: BudgetScope[]): QueryFilter[] {
  return scopes.map((scope) => ({
    name: scope.key,
    operator: Operator.EQUALS,
    values: scope.values,
  }));
}
