import { buildCubeQuery } from "@/api/analytics/utils";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource, TimeGranularity } from "@ternary/api-lib/analytics/enums";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { CudUtilizationPercentEntity } from "../types";

type CudUtilizationPercentDatum = {
  utilizationPercent: number | null;
  timestamp: string;
};

interface Params {
  dateRange: Date[];
  granularity: TimeGranularity;
}

export default function useGetCudUtilizationPercentData(
  params: Params,
  options?: UseQueryOptions<CudUtilizationPercentEntity, UError>
): UseQueryResult<CudUtilizationPercentEntity, UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["cudUtilizationPercent", params],
    queryFn: async () => {
      const result = (await client.load(
        buildCubeQuery({
          dataSource: DataSource.GCP_COMBINED_CUD_UTILIZATION,
          dateRange: params.dateRange,
          granularity: params.granularity,
          measures: ["utilizationPercent"],
        })
      )) as CudUtilizationPercentDatum[];

      const summary = result[0];

      return {
        utilizationPercent: summary.utilizationPercent ?? 0,
      };
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
