import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import UError from "unilib-error";
import useBuildDataQuery from "../../../../api/analytics/utils/useDataQuery";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { AzureAKSMeasures, AzureAKSUsageDatum } from "../types";

export interface Params {
  dateRange: Date[];
  queryFilters?: QueryFilter[];
}

export default function useGetAzureAKSCostUsageSummary(
  params: Params,
  options?: UseQueryOptions<AzureAKSUsageDatum, UError>
): UseQueryResult<AzureAKSUsageDatum, UError> {
  const client = useAnalyticsApiClient();

  const [tenantID, query] = useBuildDataQuery({
    ...params,
    dataSource: DataSource.AZURE_KUBERNETES_NODE_USAGE,
    measures: [
      AzureAKSMeasures.instanceHours,
      AzureAKSMeasures.cost,
      AzureAKSMeasures.upperBoundCpuCoreHoursUsed,
      AzureAKSMeasures.estimatedCpuCoreHoursAvailable,
      AzureAKSMeasures.upperBoundRssByteHoursUsed,
      AzureAKSMeasures.estimatedRssByteHoursAvailable,
    ],
  });

  return useQuery({
    queryKey: ["azureAKSUsage", params],
    queryFn: async () => {
      const aksUsage = await client.loadData(tenantID, query);

      return aksUsage.response.map((datum) => {
        return {
          instanceHours: datum.instanceHours ?? 0,
          cost: datum.cost ?? 0,
          riAmortizedCost: datum.riAmortizedCost ?? 0,
          upperBoundCpuCoreHoursUsed: datum.upperBoundCpuCoreHoursUsed ?? 0,
          estimatedCpuCoreHoursAvailable:
            datum.estimatedCpuCoreHoursAvailable ?? 0,
          upperBoundRssByteHoursUsed: datum.upperBoundRssByteHoursUsed ?? 0,
          estimatedRssByteHoursAvailable:
            datum.estimatedRssByteHoursAvailable ?? 0,
          timestamp: datum.timestamp ?? "",
        } as AzureAKSUsageDatum;
      })[0];
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
