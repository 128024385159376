import { UnitType } from "../enums";

const measures = [
  { name: "cpuAndRamCost", unit: UnitType.CURRENCY },
  { name: "remainingCost", unit: UnitType.CURRENCY },
  { name: "storageCost", unit: UnitType.CURRENCY },
  { name: "cost", unit: UnitType.CURRENCY },
];

const dimensions = [
  "availability",
  "classification",
  "databaseType",
  "instanceType",
  "projectId",
  "region",
  "replica",
];

export const gcpCloudSqlCostSchema = { dimensions, measures };
