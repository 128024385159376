import Icon, { Props as IconProps } from "@ternary/web-ui-lib/components/Icon";
import React from "react";

export default function IconAzureA(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
        <g clip-path="url(#clip0_92_5032)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.3703 13.6799L11.3703 1.67989C11.3007 1.47291 11.1653 1.29438 10.9847 1.17159C10.8041 1.0488 10.5883 0.988513 10.3703 0.999896H5.63023C5.42058 0.999354 5.21604 1.0647 5.04557 1.18672C4.8751 1.30875 4.7473 1.48127 4.68021 1.67989L0.630226 13.6799C0.577707 13.8346 0.563881 13.9998 0.590004 14.1611C0.616127 14.3225 0.681396 14.4749 0.780068 14.6052C0.878739 14.7354 1.00784 14.8395 1.15604 14.9083C1.30425 14.9771 1.46705 15.0086 1.63023 14.9999H4.56022C4.76815 14.9984 4.97041 14.932 5.13889 14.8101C5.30737 14.6883 5.43369 14.5169 5.50022 14.3199L6.11021 12.5399L9.11021 14.8099C9.28454 14.9362 9.49501 15.0028 9.71024 14.9999H14.3903C14.5518 15.0052 14.7122 14.9712 14.8577 14.901C15.0033 14.8307 15.1296 14.7263 15.226 14.5965C15.3223 14.4668 15.3857 14.3156 15.4108 14.156C15.436 13.9963 15.4221 13.833 15.3703 13.6799ZM9.75022 14.3399C9.67754 14.3399 9.60699 14.3153 9.55021 14.2699L3.90024 10.0799L3.81022 10.0099H6.81022L6.89023 9.79988L7.89023 7.26988L10.1303 13.8999C10.1483 13.9555 10.1516 14.0148 10.14 14.072C10.1284 14.1293 10.1023 14.1826 10.0641 14.2269C10.0259 14.2711 9.97695 14.3047 9.92197 14.3245C9.867 14.3443 9.80784 14.3496 9.75022 14.3399ZM14.4202 14.3399H10.7003C10.775 14.1262 10.775 13.8935 10.7003 13.6799L6.65024 1.67989H10.3703C10.4409 1.68024 10.5096 1.70258 10.567 1.74379C10.6243 1.78501 10.6674 1.84308 10.6903 1.9099L14.7403 13.9099C14.7539 13.9597 14.7561 14.012 14.7465 14.0628C14.737 14.1136 14.716 14.1615 14.6852 14.203C14.6543 14.2444 14.6145 14.2783 14.5686 14.302C14.5227 14.3257 14.4719 14.3387 14.4202 14.3399Z"
            fill="#646C76"
          />
        </g>
        <defs>
          <clipPath id="clip0_92_5032">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
}
