import { DateHelper } from "@/lib/dates";
import { createStructParam } from "@/lib/use-query-params";
import DateRangeControls from "@/ui-lib/components/DateRangeControls/DateRangeControls";
import Dropdown from "@/ui-lib/components/Dropdown";
import Grid from "@/ui-lib/components/Grid";
import { DateRange } from "@/utils/dates";
import { useDebounce } from "@/utils/timers";
import { useTheme } from "@emotion/react";
import { faChartArea, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import {
  DataSource,
  DurationType,
  TimeGranularity,
  UnitType,
} from "@ternary/api-lib/analytics/enums";
import { RawData } from "@ternary/api-lib/analytics/types";
import AreaChart from "@ternary/api-lib/analytics/ui/AreaChart";
import StackedBarChart from "@ternary/api-lib/analytics/ui/StackedBarChart";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { differenceInHours, endOfDay } from "date-fns";
import React, { useMemo, useState } from "react";
import {
  DateParam,
  DecodedValueMap,
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { z } from "zod";
import useGetRawData from "../../../../api/analytics/useGetRawData";
import useAvailableGlobalDate from "../../../../hooks/useAvailableGlobalDate";
import copyText from "../../copyText";
import useGetAWSStorageEBS from "../hooks/useGetAWSStorageEBS";
import { EBStoreGroup, EBStoreGroupFilters } from "../types";
import EBStoreGroupTable from "./AWSStorageEBSGroupTable";
import AWSStorageEBSGroupTableControls from "./AWSStorageEBSGroupTableControls";
import AWSStorageEBSSnapshotsTable from "./AWSStorageEBSSnapshotsTable";
import AWSStorageEBSVolumesTable from "./AWSStorageEBSVolumesTable";

const EBSCostChartOption = {
  ACCOUNT_ID: "ACCOUNT_ID",
  REGION: "REGION",
  STORAGE_MEDIA: "STORAGE_MEDIA",
  USAGE_ACCOUNT: "USAGE_ACCOUNT",
} as const;

type EBSCostChartOption =
  (typeof EBSCostChartOption)[keyof typeof EBSCostChartOption];

const EBSUsageChartOption = {
  IOPS: "IOPS",
  READ_WRITE: "READ_WRITE",
  SNAPSHOT_STORAGE: "SNAPSHOT_STORAGE",
  STORAGE: "STORAGE",
  THROUGHPUT: "THROUGHPUT",
} as const;

type EBSUsageChartOption =
  (typeof EBSUsageChartOption)[keyof typeof EBSUsageChartOption];

type Interaction =
  | EBStoreGroupTable.Interaction
  | AWSStorageEBSGroupTableControls.Interaction;

type QueryParams = DecodedValueMap<typeof queryParamConfigMap>;

type QueryParamState = {
  dateRange: DateRange;
  dateRangeGranularity: TimeGranularity;
  duration: DurationType;
  ebStoreGroupFilters: z.infer<typeof ebStoreGroupFiltersStruct>;
  selectedCostChartOption: EBSCostChartOption;
  selectedSnapshotGroupID: string | null;
  selectedUsageChartOption: EBSUsageChartOption;
  selectedVolumeGroupID: string | null;
};

const costChartOptionEnum = createEnumParam(Object.values(EBSCostChartOption));
const durationEnum = createEnumParam(Object.values(DurationType));
const usageChartOptionEnum = createEnumParam(
  Object.values(EBSUsageChartOption)
);

const ebStoreGroupFiltersDefault = {
  billPayerAccountId: null,
  region: null,
};

const ebStoreGroupFiltersStruct = z.object({
  billPayerAccountId: z.nullable(z.string()),
  region: z.nullable(z.string()),
});

const queryParamConfigMap = {
  date_range_end: DateParam,
  date_range_start: DateParam,
  duration: withDefault(durationEnum, DurationType.LAST_THIRTY_DAYS),
  ebstore_group_filters: createStructParam(ebStoreGroupFiltersStruct),
  selected_cost_chart_option: withDefault(
    costChartOptionEnum,
    EBSCostChartOption.ACCOUNT_ID
  ),
  selected_snapshot_group_id: StringParam,
  selected_usage_chart_option: withDefault(
    usageChartOptionEnum,
    EBSUsageChartOption.STORAGE
  ),
  selected_volume_group_id: StringParam,
};

export default function AWSStorageEBSVisibilityContainer() {
  const globalDate = useAvailableGlobalDate();
  const theme = useTheme();

  //
  // STATE
  //

  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);

  const queryParamState = getQueryParamState(queryParams);

  const [searchText, setSearchText] = useState("");

  const debouncedSearchText = useDebounce(searchText);

  //
  // QUERIES
  //

  const dateRange = globalDate.date ?? queryParamState.dateRange;

  const usageMeasures = getUsageMeasuresFromOption(
    queryParamState.selectedUsageChartOption
  );

  const { data: costChartData = [], isFetching: isLoadingCostChartData } =
    useGetRawData({
      dataSource: DataSource.AWS_EBS_VISIBILITY,
      dateRange,
      dimensions: getCostDimensionsFromOptions(
        queryParamState.selectedCostChartOption
      ),
      granularity: queryParamState.dateRangeGranularity,
      measures: ["cost"],
    });

  const { data: usageChartData = [], isFetching: isLoadingUsageChartData } =
    useGetRawData({
      dataSource: DataSource.AWS_EBS_VISIBILITY,
      dateRange,
      dimensions: ["region"],
      granularity: queryParamState.dateRangeGranularity,
      measures: usageMeasures,
    });

  const { data: ebStoreGroup, isFetching: isLoadingEBStoreGroup } =
    useGetAWSStorageEBS({ dateRange });

  //
  // MODIFIED QUERY DATA
  //

  const filteredEBSGroups = useMemo(() => {
    return getFilteredEBSGroups({
      allEBStoreGroups: ebStoreGroup ?? [],
      ebStoreGroupFilters: queryParamState.ebStoreGroupFilters,
      searchText: debouncedSearchText,
    });
  }, [queryParamState.ebStoreGroupFilters, ebStoreGroup, debouncedSearchText]);

  const csvData = useMemo(
    () => getCSVData(filteredEBSGroups),
    [filteredEBSGroups]
  );

  const selectedGroupVolumes = useMemo(() => {
    if (queryParamState.selectedVolumeGroupID === null || !ebStoreGroup) {
      return [];
    }

    const selectedGroup = ebStoreGroup.find(
      (group) => group.groupID === queryParamState.selectedVolumeGroupID
    );

    return selectedGroup?.volumes ?? [];
  }, [ebStoreGroup, queryParamState.selectedVolumeGroupID]);

  const selectedGroupSnapshot = useMemo(() => {
    if (queryParamState.selectedSnapshotGroupID === null || !ebStoreGroup) {
      return [];
    }

    const selectedGroup = ebStoreGroup.find(
      (group) => group.groupID === queryParamState.selectedSnapshotGroupID
    );

    return selectedGroup?.snapshots ?? [];
  }, [ebStoreGroup, queryParamState.selectedSnapshotGroupID]);

  const isEmptyUsageChartData = checkEmptyData(
    usageChartData,
    queryParamState.selectedUsageChartOption
  );

  //
  // INTERACTIONS
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case EBStoreGroupTable.INTERACTION_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.ebStoreGroupFilters };

        nextFilters[interaction.filterKey] = interaction.filterValue;

        setQueryParams({ ebstore_group_filters: nextFilters });
        break;
      }
      case EBStoreGroupTable.INTERACTION_VIEW_VOLUMES_CLICKED: {
        setQueryParams({
          selected_volume_group_id: interaction.selectedVolumeGroupID,
        });
        break;
      }
      case EBStoreGroupTable.INTERACTION_VIEW_SNAPSHOTS_CLICKED: {
        setQueryParams({
          selected_snapshot_group_id: interaction.selectedSnapshotGroupID,
        });
        break;
      }
      case AWSStorageEBSGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.ebStoreGroupFilters };

        nextFilters[interaction.filterKey] = null;

        if (Object.values(nextFilters).every((value) => value === null)) {
          setQueryParams({ ebstore_group_filters: null });
        } else {
          setQueryParams({ ebstore_group_filters: nextFilters });
        }
        break;
      }
      case AWSStorageEBSGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED: {
        setSearchText(interaction.searchText);
        break;
      }
    }
  }

  return (
    <Box>
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        justifyContent="flex-end"
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <DateRangeControls
          dateRange={dateRange}
          durationType={queryParamState.duration}
          hiddenOptions={[
            DurationType.LAST_NINETY_DAYS,
            DurationType.QUARTER_TO_DATE,
            DurationType.YEAR_TO_DATE,
          ]}
          maxDate={new DateHelper().date}
          onChangeDateRange={(duration, newDateRange) => {
            setQueryParams({
              duration,
              ...(newDateRange && newDateRange[0] && newDateRange[1]
                ? {
                    date_range_start: newDateRange[0],
                    date_range_end: newDateRange[1],
                  }
                : {
                    date_range_start: null,
                    date_range_end: null,
                  }),
            });
          }}
        />
      </Flex>

      <Grid
        gridColumnGap={theme.space_lg}
        gridTemplateColumns={`repeat(2, calc(50% - (${theme.space_lg} / 2) ))`}
      >
        <Flex
          backgroundColor={theme.panel_backgroundColor}
          borderRadius={theme.borderRadius_2}
          direction="column"
          height={500}
          padding={theme.space_md}
        >
          <Flex justifyContent="space-between" paddingBottom={theme.space_md}>
            <Text fontSize={theme.h3_fontSize}>
              {copyText.s3OptimizationsChartTitleCost}
            </Text>

            {/* COST DROPDOWN */}
            <Dropdown
              options={costChartOptions.map((option) => ({
                ...option,
                onClick: () =>
                  setQueryParams({ selected_cost_chart_option: option.value }),
              }))}
              placement="bottom-end"
              selectedOption={
                costChartOptions.find(
                  (option) =>
                    option.value === queryParamState.selectedCostChartOption
                ) ?? costChartOptions[0]
              }
            >
              <Button
                iconEnd={<Icon icon={faChevronDown} />}
                secondary
                size="small"
                width={140}
              >
                {getOptionLabel(queryParamState.selectedCostChartOption)}
              </Button>
            </Dropdown>
          </Flex>

          {/* COST CHART */}
          <Box flex="1 0 0">
            <StackedBarChart
              data={costChartData}
              dimensions={getCostDimensionsFromOptions(
                queryParamState.selectedCostChartOption
              ).map((dimension) => ({ name: dimension }))}
              disableDrilldown
              isLoading={isLoadingCostChartData}
              measures={[{ name: "cost", unit: UnitType.CURRENCY }]}
              showTooltip
              showLegend
              timeSeriesGranularity={queryParamState.dateRangeGranularity}
              xAxisKey="timestamp"
            />
          </Box>
        </Flex>

        <Flex
          backgroundColor={theme.panel_backgroundColor}
          borderRadius={theme.borderRadius_2}
          direction="column"
          height={500}
          padding={theme.space_md}
        >
          <Flex justifyContent="space-between" paddingBottom={theme.space_md}>
            <Text fontSize={theme.h3_fontSize}>
              {copyText.s3OptimizationsChartTitleUsage}
            </Text>

            {/* USAGE DROPDOWN */}
            <Dropdown
              options={usageChartOptions.map((option) => ({
                ...option,
                onClick: () =>
                  setQueryParams({ selected_usage_chart_option: option.value }),
              }))}
              placement="bottom-end"
              selectedOption={
                usageChartOptions.find(
                  (option) =>
                    option.value === queryParamState.selectedUsageChartOption
                ) ?? usageChartOptions[0]
              }
            >
              <Button
                iconEnd={<Icon icon={faChevronDown} />}
                secondary
                size="small"
                width={140}
              >
                {getOptionLabel(queryParamState.selectedUsageChartOption)}
              </Button>
            </Dropdown>
          </Flex>

          {/* USAGE CHART */}
          <Box flex="1 0 0">
            {isEmptyUsageChartData && !isLoadingUsageChartData ? (
              <EmptyPlaceholder
                height="25rem"
                icon={faChartArea}
                loading={false}
                skeletonVariant="cartesian"
                text={copyText.noDataPlaceholderMessage}
              />
            ) : (
              <AreaChart
                data={usageChartData}
                dimensions={[{ name: "region" }]}
                hideTotal
                isLoading={isLoadingUsageChartData}
                measures={usageMeasures.map(getMeasureWithUnit)}
                showLegend
                showTooltip
                stacked
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                xAxisKey="timestamp"
              />
            )}
          </Box>
        </Flex>
      </Grid>

      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginVertical={theme.space_lg}
        padding={theme.space_md}
      >
        <AWSStorageEBSGroupTableControls
          ebStoreGroupFilters={queryParamState.ebStoreGroupFilters}
          csvData={csvData}
          debouncedSearchText={debouncedSearchText}
          searchText={searchText}
          onInteraction={handleInteraction}
        />
      </Box>

      <EBStoreGroupTable
        storeGroups={filteredEBSGroups}
        isLoadingEBStoreGroups={isLoadingEBStoreGroup}
        onInteraction={handleInteraction}
      />
      {queryParamState.selectedVolumeGroupID !== null && (
        <AWSStorageEBSVolumesTable
          date={dateRange}
          isLoading={isLoadingEBStoreGroup}
          volumes={selectedGroupVolumes}
          onClose={() => setQueryParams({ selected_volume_group_id: null })}
        />
      )}
      {queryParamState.selectedSnapshotGroupID !== null && (
        <AWSStorageEBSSnapshotsTable
          date={dateRange}
          isLoading={isLoadingEBStoreGroup}
          snapshots={selectedGroupSnapshot}
          onClose={() => setQueryParams({ selected_snapshot_group_id: null })}
        />
      )}
    </Box>
  );
}

// COST OPTIONS
const costChartOptions = [
  EBSCostChartOption.ACCOUNT_ID,
  EBSCostChartOption.USAGE_ACCOUNT,
  EBSCostChartOption.STORAGE_MEDIA,
  EBSCostChartOption.REGION,
].map((costOption) => ({
  label: getOptionLabel(costOption),
  value: costOption,
}));

// USAGE OPTIONS
const usageChartOptions = [
  EBSUsageChartOption.STORAGE,
  EBSUsageChartOption.IOPS,
  EBSUsageChartOption.THROUGHPUT,
  EBSUsageChartOption.READ_WRITE,
  EBSUsageChartOption.SNAPSHOT_STORAGE,
].map((usageOption) => ({
  label: getOptionLabel(usageOption),
  value: usageOption,
}));

function getOptionLabel(option: EBSCostChartOption | EBSUsageChartOption) {
  return copyText[`ebsChartOptionLabel_${option}`];
}

const measuresWithUnit: { name: string; unit: UnitType }[] = [
  { name: "bytesDownloaded", unit: UnitType.BYTES },
  { name: "bytesUploaded", unit: UnitType.BYTES },
  { name: "creditTotal", unit: UnitType.STANDARD },
  { name: "maxThroughputVolumeBytes", unit: UnitType.BYTES },
  { name: "volumeReadOps", unit: UnitType.STANDARD },
  { name: "volumeWriteOps", unit: UnitType.STANDARD },
  { name: "networkCost", unit: UnitType.CURRENCY },
  { name: "numberOfObjects", unit: UnitType.STANDARD },
  { name: "operationsCost", unit: UnitType.CURRENCY },
  { name: "requestCount", unit: UnitType.STANDARD },
  { name: "snapshotUsageBytes", unit: UnitType.BYTES },
  { name: "storageCost", unit: UnitType.CURRENCY },
  { name: "storageUsageBytes", unit: UnitType.BYTES },
  { name: "cost", unit: UnitType.CURRENCY },
  { name: "volumeReadBytes", unit: UnitType.BYTES },
  { name: "volumeWriteBytes", unit: UnitType.BYTES },
];

function getMeasureWithUnit(measure: string) {
  return (
    measuresWithUnit.find((other) => other.name === measure) ?? {
      name: measure,
      unit: UnitType.STANDARD,
    }
  );
}

function getUsageMeasuresFromOption(option: EBSUsageChartOption): string[] {
  switch (option) {
    case EBSUsageChartOption.STORAGE:
      return ["storageUsageBytes"];
    case EBSUsageChartOption.THROUGHPUT:
      return ["maxThroughputVolumeBytes"];
    case EBSUsageChartOption.READ_WRITE:
      return ["volumeReadBytes", "volumeWriteBytes"];
    case EBSUsageChartOption.IOPS:
      return ["volumeReadOps", "volumeWriteOps"];
    case EBSUsageChartOption.SNAPSHOT_STORAGE:
      return ["snapshotUsageBytes"];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getCostDimensionsFromOptions(option: EBSCostChartOption): string[] {
  switch (option) {
    case EBSCostChartOption.ACCOUNT_ID:
      return ["billPayerAccountId"];
    case EBSCostChartOption.USAGE_ACCOUNT:
      return ["lineItemUsageAccountId"];
    case EBSCostChartOption.REGION:
      return ["region"];
    case EBSCostChartOption.STORAGE_MEDIA:
      return ["storageMedia"];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function checkEmptyData(
  usageData: RawData[],
  selectedUsageChartOption: EBSUsageChartOption
): boolean {
  let check = 0;
  usageData.forEach((usage) => {
    switch (selectedUsageChartOption) {
      case EBSUsageChartOption.THROUGHPUT:
        if (
          usage.maxThroughputVolumeBytes &&
          typeof usage.maxThroughputVolumeBytes === "number"
        ) {
          check += usage.maxThroughputVolumeBytes;
        }
        break;
      case EBSUsageChartOption.READ_WRITE:
        if (
          usage.volumeReadBytes &&
          typeof usage.volumeReadBytes === "number" &&
          usage.volumeWriteBytes &&
          typeof usage.volumeWriteBytes === "number"
        ) {
          check += usage.volumeReadBytes;
          check += usage.volumeWriteBytes;
        }
        break;
      case EBSUsageChartOption.IOPS:
        if (
          usage.volumeReadOps &&
          typeof usage.volumeReadOps === "number" &&
          usage.volumeWriteOps &&
          typeof usage.volumeWriteOps === "number"
        ) {
          check += usage.volumeReadOps;
          check += usage.volumeWriteOps;
        }
        break;

      default:
        check = 1;
        break;
    }
  });

  if (check > 0) {
    return false;
  }
  return true;
}

const csvAccessors = [
  "billPayerAccountId",
  "operationsCost",
  "region",
  "snapshotCost",
  "snapshotCount",
  "snapshotUsageBytes",
  "storageCost",
  "storageUsageBytes",
  "totalCost",
  "volumeCount",
] as const;

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

function getCSVData(groups: EBStoreGroup[]): CSVData {
  if (!groups.length) {
    return { headers: [], rows: [] };
  }

  const rows = groups.map((group) => ({
    billPayerAccountId: group.billPayerAccountId,
    operationsCost: group.operationsCost,
    region: group.region,
    snapshotCost: group.snapshotCost,
    snapshotCount: group.snapshots.length,
    snapshotUsageBytes: group.snapshotUsageBytes,
    storageCost: group.storageCost,
    storageUsageBytes: group.storageUsageBytes,
    totalCost: group.totalCost,
    volumeCount: group.volumes.length,
  }));

  const headers = csvAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `ebsTableGroupHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}

type GetFilteredBucketGroupsParams = {
  allEBStoreGroups: EBStoreGroup[];
  ebStoreGroupFilters: EBStoreGroupFilters;
  searchText: string | null;
};

function getFilteredEBSGroups(
  params: GetFilteredBucketGroupsParams
): EBStoreGroup[] {
  const searchText = (params.searchText ?? "").toLowerCase().trim();

  return params.allEBStoreGroups.filter((storeGroup) => {
    if (!bucketGroupPassesFilters(storeGroup, params.ebStoreGroupFilters)) {
      return false;
    }

    if (!bucketGroupHasSearchText(storeGroup, searchText)) {
      return false;
    }

    return true;
  });
}

function getQueryParamState(queryParams: QueryParams): QueryParamState {
  const dateRange =
    queryParams.date_range_start && queryParams.date_range_end
      ? [queryParams.date_range_start, queryParams.date_range_end]
      : getCubeDateRangeFromDurationType(queryParams.duration);

  const dateRangeDurationInHours =
    dateRange[0] && dateRange[1]
      ? differenceInHours(endOfDay(dateRange[1]), dateRange[0])
      : 0;

  const isSmallDateRange =
    dateRangeDurationInHours > 0 && dateRangeDurationInHours <= 48;

  const dateRangeGranularity = isSmallDateRange
    ? TimeGranularity.HOUR
    : TimeGranularity.DAY;

  return {
    dateRange,
    dateRangeGranularity,
    duration: queryParams.duration,
    ebStoreGroupFilters:
      queryParams.ebstore_group_filters ?? ebStoreGroupFiltersDefault,
    selectedCostChartOption: queryParams.selected_cost_chart_option,
    selectedVolumeGroupID: queryParams.selected_volume_group_id ?? null,
    selectedSnapshotGroupID: queryParams.selected_snapshot_group_id ?? null,
    selectedUsageChartOption: queryParams.selected_usage_chart_option,
  };
}

function nullOrIsSame(filter: string | null, b: string) {
  if (filter === null) return true;
  return filter.toLowerCase().trim() === b.toLowerCase().trim();
}

function bucketGroupPassesFilters(
  bucketGroup: EBStoreGroup,
  filters: EBStoreGroupFilters
) {
  if (
    !nullOrIsSame(filters.billPayerAccountId, bucketGroup.billPayerAccountId)
  ) {
    return false;
  }
  if (!nullOrIsSame(filters.region, bucketGroup.region)) {
    return false;
  }

  return true;
}

function bucketGroupHasSearchText(
  bucketGroup: EBStoreGroup,
  searchText: string
) {
  if (searchText === "") return true;

  if (
    bucketGroup.billPayerAccountId.toLowerCase().trim().includes(searchText)
  ) {
    return true;
  }
  if (bucketGroup.region.toLowerCase().trim().includes(searchText)) {
    return true;
  }

  return false;
}
