import { createStructParam } from "@/lib/use-query-params";
import { useTheme } from "@emotion/react";
import { createColumnHelper } from "@tanstack/react-table";
import useRefFn from "@ternary/api-lib/analytics/ui/hooks/useRefFn";
import { formatCurrency } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useMemo } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import {
  AWSDatabaseMemoryDBDatum,
  awsDatabaseMemoryDBDimensions,
  awsDatabaseMemoryDBMeasures,
} from "../types";

type Props = {
  data: AWSDatabaseMemoryDBDatum[];
  isLoading: boolean;
  onInteraction: (interaction: AWSDatabaseMemoryDBTable.Interaction) => void;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

const columnHelper = createColumnHelper<AWSDatabaseMemoryDBDatum>();

function AWSDatabaseMemoryDBTable(props: Props) {
  const theme = useTheme();

  const [sortRule, setSortRule] = useQueryParam(
    "group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "nodeCost",
    })
  );

  const onInteraction = useRefFn(props.onInteraction);

  const columns = useMemo(
    () => [
      columnHelper.accessor(awsDatabaseMemoryDBDimensions.clusterId, {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            truncate
            cursor="pointer"
            onClick={() =>
              onInteraction({
                type: AWSDatabaseMemoryDBTable.INTERACTION_FILTER_CLICKED,
                filterKey: awsDatabaseMemoryDBDimensions.clusterId,
                filterValue: getValue(),
              })
            }
          >
            <Tooltip content={getValue() || copyText.awsDatabaseTableNull}>
              {getValue() || copyText.awsDatabaseTableNull}
            </Tooltip>
          </Text>
        ),
        header: copyText.awsDatabaseMemoryDBTableHeader_clusterId,
        meta: { align: "left" },
        sortDescFirst: true,
        size: 100,
      }),
      columnHelper.accessor(awsDatabaseMemoryDBDimensions.usageAccountId, {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            truncate
            cursor="pointer"
            onClick={() =>
              onInteraction({
                type: AWSDatabaseMemoryDBTable.INTERACTION_FILTER_CLICKED,
                filterKey: awsDatabaseMemoryDBDimensions.usageAccountId,
                filterValue: getValue(),
              })
            }
          >
            <Tooltip content={getValue() || copyText.awsDatabaseTableNull}>
              {getValue() || copyText.awsDatabaseTableNull}
            </Tooltip>
          </Text>
        ),
        header: () => (
          <Tooltip
            content={
              <Box width={200}>
                <Text color={theme.text_color_inverse}>
                  {copyText.awsIAMListPermissionTooltipMessage}
                </Text>
              </Box>
            }
          >
            {copyText.awsDatabaseMemoryDBTableHeader_usageAccountId}
          </Tooltip>
        ),
        meta: { align: "center" },
        sortDescFirst: true,
        size: 100,
      }),
      columnHelper.accessor(awsDatabaseMemoryDBDimensions.region, {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            truncate
            cursor="pointer"
            onClick={() =>
              onInteraction({
                type: AWSDatabaseMemoryDBTable.INTERACTION_FILTER_CLICKED,
                filterKey: awsDatabaseMemoryDBDimensions.region,
                filterValue: getValue(),
              })
            }
          >
            <Tooltip content={getValue() || copyText.awsDatabaseTableNull}>
              {getValue() || copyText.awsDatabaseTableNull}
            </Tooltip>
          </Text>
        ),
        header: copyText.awsDatabaseMemoryDBTableHeader_region,
        meta: { align: "center" },
        sortDescFirst: true,
        size: 100,
      }),
      columnHelper.accessor(awsDatabaseMemoryDBMeasures.nodeCost, {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.awsDatabaseMemoryDBTableHeader_nodeCost,
        meta: { align: "right" },
        sortDescFirst: true,
        size: 100,
      }),
      columnHelper.accessor(awsDatabaseMemoryDBMeasures.dataWriteCost, {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.awsDatabaseMemoryDBTableHeader_dataWriteCost,
        meta: { align: "right" },
        sortDescFirst: true,
        size: 100,
      }),
    ],
    []
  );

  return (
    <Table
      columns={columns}
      compact
      data={props.data}
      initialState={{ sorting: [sortRule] }}
      isLoading={props.isLoading}
      showPagination
      sortable
      onChangeSortBy={([sortRule]) => setSortRule(sortRule)}
    />
  );
}

AWSDatabaseMemoryDBTable.INTERACTION_FILTER_CLICKED =
  `AWSDatabaseMemoryDBTable.INTERACTION_FILTER_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof AWSDatabaseMemoryDBTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof AWSDatabaseMemoryDBDatum;
  filterValue: AWSDatabaseMemoryDBDatum[keyof AWSDatabaseMemoryDBDatum];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AWSDatabaseMemoryDBTable {
  export type Interaction = InteractionFilterClicked;
}

export default AWSDatabaseMemoryDBTable;
