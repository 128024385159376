import useGetPaginatedRawData from "@/api/analytics/hooks/useGetPaginatedRawData";
import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import { useFilterStore } from "@/context/FilterStoreProvider";
import { SortRule } from "@/types";
import AsyncCSVDownloader from "@/ui-lib/components/AsyncCSVDownloader";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import TextInput from "@/ui-lib/components/TextInput";
import { useDebounce } from "@/utils/timers";
import { useTheme } from "@emotion/react";
import {
  faChevronDown,
  faChevronRight,
  faExclamationTriangle,
  faFileExport,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ChartType,
  DataSource,
  DurationType,
  Operator,
  TimeGranularity,
  UnitType,
} from "@ternary/api-lib/analytics/enums";
import { Order, QueryFilter, RawData } from "@ternary/api-lib/analytics/types";
import AreaChart from "@ternary/api-lib/analytics/ui/AreaChart";
import StackedBarChart from "@ternary/api-lib/analytics/ui/StackedBarChart";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import { getDataSourceFilters } from "@ternary/api-lib/analytics/utils/ReportUtils";
import { actions } from "@ternary/api-lib/telemetry";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import prettyBytes from "pretty-bytes";
import React, { useMemo, useState } from "react";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import useGetProjects from "../../../../api/analytics/useGetProjects";
import useGetRawData from "../../../../api/analytics/useGetRawData";
import InsightsSelector from "../../../../components/InsightsSelector";
import { DateHelper } from "../../../../lib/dates";
import Breadcrumb, { Item } from "../../../../ui-lib/components/Breadcrumb";
import Dropdown from "../../../../ui-lib/components/Dropdown";
import { DateRange } from "../../../../utils/dates";
import getMergeState from "../../../../utils/getMergeState";
import copyText from "../../copyText";
import useGetGCPKubernetesResourcesFromRawData from "../hooks/useGetGCPKubernetesResourcesFromRawData";
import useGetGCPKubernetesUsageSummary from "../hooks/useGetGCPKubernetesUsageSummary";
import {
  GCPKubernetesResourceEntity,
  GCPKubernetesResourceType,
  GCPKubernetesUsageType,
} from "../types";
import GCPKubernetesResourceTable from "./GCPKubernetesResourceTable";
import KubernetesUsageMeters from "./GCPKubernetesUsageMeters";

export interface SearchParams {
  cluster: string;
  initialGrouping: string;
  namespace: string;
  project: string;
  resource_type: string;
  workload: string;
}

export enum DayCount {
  SEVEN_DAYS = "SEVEN_DAYS",
  FOURTEEN_DAYS = "FOURTEEN_DAYS",
  THIRTY_DAYS = "THIRTY_DAYS",
}

const DEBOUNCE_SEARCH_MS = 600;

type Interaction = GCPKubernetesResourceTable.Interaction;

interface State {
  csvHeaders: CSVHeader[];
  dayCount: DayCount;
  searchText: string;
  sortRule: SortRule | null;
  usageType: GCPKubernetesUsageType;
}

const initialState: State = {
  csvHeaders: [],
  dayCount: DayCount.SEVEN_DAYS,
  searchText: "",
  sortRule: null,
  usageType: GCPKubernetesUsageType.CPU,
};

export default function GCPKubernetesContainer(): JSX.Element {
  const activityTracker = useActivityTracker();
  const theme = useTheme();

  //
  // Search Params
  //

  const [searchParams, setSearchParams] = useQueryParams({
    cluster: withDefault(StringParam, ""),
    display: withDefault(StringParam, ""),
    id: withDefault(StringParam, ""),
    initialGrouping: withDefault(
      StringParam,
      GCPKubernetesResourceType.CLUSTER
    ),
    namespace: withDefault(StringParam, ""),
    project: withDefault(StringParam, ""),
    resource_type: withDefault(StringParam, GCPKubernetesResourceType.CLUSTER),
    workload: withDefault(StringParam, ""),
  });

  //
  // State
  //

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  const now = useMemo(() => new DateHelper(), []);

  const filterStore = useFilterStore();

  const dataSource = getDataSource(searchParams);

  const debouncedSearchText = useDebounce(
    state.searchText.toLowerCase().trim(),
    DEBOUNCE_SEARCH_MS
  );

  const globalFilters = getDataSourceFilters(
    filterStore.queryFilters,
    dataSource
  );

  //
  // Queries
  //

  const kubernetesFilters = getKubernetesFilters(searchParams);
  const initialGrouping = getInitialGrouping(searchParams.initialGrouping);
  const resourceType = getResourceType(searchParams.resource_type);

  const { data: usageSummary, isFetching: isLoadingUsageSummary } =
    useGetGCPKubernetesUsageSummary({
      dataSource: dataSource,
      dateRange: getDateRange(state.dayCount),
      queryFilters: [...globalFilters, ...kubernetesFilters],
    });

  const costDimensions = getCostDimensions(dataSource, searchParams);

  const costMeasures = getCostMeasures(dataSource, searchParams);

  const { data: costData = [], isFetching: isLoadingCostData } = useGetRawData({
    dataSource: dataSource,
    dateRange: getDateRange(state.dayCount),
    dimensions: costDimensions,
    granularity: TimeGranularity.DAY,
    measures: costMeasures,
    queryFilters: [...globalFilters, ...kubernetesFilters],
  });

  const usageMeasures = getUsageMeasures(dataSource, state.usageType);

  const { data: usageData = [], isFetching: isLoadingUsageData } =
    useGetRawData({
      dataSource: dataSource,
      dateRange: getDateRange(state.dayCount),
      dimensions: [],
      granularity: TimeGranularity.DAY,
      measures: usageMeasures,
      queryFilters: [...globalFilters, ...kubernetesFilters],
    });

  const listDimensions = getListDimensions(dataSource, searchParams);

  const listMeasures = getListMeasures(dataSource);

  const order = getServerSideListOrder({
    dimensions: listDimensions,
    measures: listMeasures,
    resourceType,
    sortRule: state.sortRule,
  });

  const { data: resourceListResult, isLoading: isLoadingResourceList } =
    useGetRawData({
      dataSource: dataSource,
      dateRange: getDateRange(state.dayCount),
      dimensions: listDimensions,
      limit: GCPKubernetesResourceTable.TABLE_ROW_LIMIT,
      measures: listMeasures,
      order: order,
      queryFilters: [...globalFilters, ...kubernetesFilters],
    });

  const resourceList = useGetGCPKubernetesResourcesFromRawData(
    resourceListResult,
    {
      dayCount: getDayCount(state.dayCount),
      resourceType: searchParams.resource_type,
    }
  );

  const debouncedSearchFilters: QueryFilter[] =
    debouncedSearchText.length > 0
      ? [
          {
            or: listDimensions.map((dimension) => {
              return {
                name: dimension,
                operator: Operator.CONTAINS,
                values: [debouncedSearchText],
              };
            }),
          },
        ]
      : [];

  const resourceListAtRowLimit =
    (resourceListResult?.length ?? 0) >=
    GCPKubernetesResourceTable.TABLE_ROW_LIMIT;

  const serverSideSearchEnabled =
    resourceListAtRowLimit && (!!order || debouncedSearchFilters.length > 0);

  const orderQueryKey: string[] = order ? Object.entries(order)[0] : [];

  const {
    data: resourceListSearchResult,
    isLoading: isLoadingResourceListSearch,
  } = useGetRawData(
    {
      dataSource: dataSource,
      dateRange: getDateRange(state.dayCount),
      dimensions: listDimensions,
      limit: GCPKubernetesResourceTable.TABLE_ROW_LIMIT,
      measures: listMeasures,
      order: order,
      queryFilters: [
        ...debouncedSearchFilters,
        ...globalFilters,
        ...kubernetesFilters,
      ],
      queryKey: ["search", debouncedSearchText, ...orderQueryKey],
    },
    { enabled: serverSideSearchEnabled }
  );

  const isLoadingSearch =
    isLoadingResourceListSearch && serverSideSearchEnabled;

  const resourceListSearch = useGetGCPKubernetesResourcesFromRawData(
    resourceListSearchResult,
    {
      dayCount: getDayCount(state.dayCount),
      resourceType: searchParams.resource_type,
    }
  );

  const resourceListSearchAtDatasetLimit =
    (resourceListSearchResult?.length ?? 0) >=
    GCPKubernetesResourceTable.TABLE_ROW_LIMIT;

  const filteredResourceList = useMemo(() => {
    if (debouncedSearchFilters.length === 0) {
      // no text filter
      return resourceList;
    }

    if (!serverSideSearchEnabled) {
      // all data is in memory
      return filterDataBasedOnSearchText(resourceList, debouncedSearchText);
    }

    if (isLoadingSearch) {
      // showing in-memory results while waiting for analytics response
      return filterDataBasedOnSearchText(resourceList, debouncedSearchText);
    }

    // show server-side results
    return resourceListSearch;
  }, [
    serverSideSearchEnabled,
    debouncedSearchText,
    isLoadingSearch,
    resourceListSearch,
    resourceList,
  ]);

  const {
    data: rawCSVData,
    isFetching: isLoadingCSVData,
    refetch: fetchCSVData,
  } = useGetPaginatedRawData(
    {
      dataSource: dataSource,
      dateRange: getDateRange(state.dayCount),
      dimensions: listDimensions,
      measures: listMeasures,
      order: order,
      queryFilters: [
        ...debouncedSearchFilters,
        ...globalFilters,
        ...kubernetesFilters,
      ],
    },
    {
      enabled: false,
    }
  );

  const csvResources = useGetGCPKubernetesResourcesFromRawData(rawCSVData, {
    dayCount: getDayCount(state.dayCount),
    resourceType: searchParams.resource_type,
  });

  const { data: projects = [] } = useGetProjects({
    dataSource: dataSource,
    dateRange: getDateRange(state.dayCount),
  });

  const currentCSVHeaders = useMemo(
    () =>
      getCSVHeaders({
        initialGrouping,
        resourceType,
        dataSource,
        selectedProjectID: searchParams.project,
      }),
    [initialGrouping, resourceType, dataSource, searchParams.project]
  );

  const csvData = useMemo(() => {
    const { csvHeaders } = state;
    if (!csvHeaders.length) return [];

    return csvResources.map((datum) =>
      csvHeaders.reduce((accum, header) => {
        let value = datum[header.key];

        if (header.key === "waste") {
          value = isNaN(Number(value)) ? "" : value;
        }

        return { ...accum, [header.key]: value };
      }, {} as RawData)
    );
  }, [csvResources, resourceList, resourceListAtRowLimit, state.csvHeaders]);

  function getDateRange(dayCount: DayCount): DateRange {
    switch (dayCount) {
      case DayCount.SEVEN_DAYS:
        return [now.nDaysAgo(7), now.nDaysAgo(1)];
      case DayCount.FOURTEEN_DAYS:
        return [now.nDaysAgo(14), now.nDaysAgo(1)];
      case DayCount.THIRTY_DAYS:
        return [now.nDaysAgo(30), now.nDaysAgo(1)];
    }
  }

  //
  // Interaction Handlers
  //

  function handleChangeDayCount(value: string): void {
    mergeState({ dayCount: value as DayCount });
  }

  function handleChangeProject(value: string): void {
    setSearchParams({
      cluster: "",
      namespace: "",
      project: value,
      resource_type: initialGrouping,
      workload: "",
    });
  }

  function handleChangeInitialGrouping(value: string): void {
    if (value in GCPKubernetesResourceType) {
      setSearchParams({
        cluster: "",
        initialGrouping: value,
        namespace: "",
        project: searchParams.project,
        resource_type: value,
        workload: "",
      });
      return;
    }

    setSearchParams({
      cluster: "",
      initialGrouping: initialGrouping,
      namespace: "",
      project: searchParams.project,
      resource_type: initialGrouping,
      workload: "",
    });
  }

  function handleClickDownloadCSV() {
    mergeState({ csvHeaders: currentCSVHeaders });
    fetchCSVData();
  }

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case GCPKubernetesResourceTable.INTERACTION_CHANGE_SORT_BY: {
        mergeState({ sortRule: interaction.sortRule });
        return;
      }
      case GCPKubernetesResourceTable.INTERACTION_DRILL_DOWN_CLICKED: {
        activityTracker.captureAction(
          actions.CLICK_INSIGHTS_DRILL_DOWN_KUBERNETES
        );
        if (dataSource === DataSource.KUBERNETES_CONTAINER_USAGE) {
          if (initialGrouping === GCPKubernetesResourceType.NAMESPACE) {
            if (
              searchParams.resource_type === GCPKubernetesResourceType.CLUSTER
            ) {
              setSearchParams({
                cluster: interaction.name,
                ...(interaction.projectID
                  ? { project: interaction.projectID }
                  : {}),
                resource_type: GCPKubernetesResourceType.WORKLOAD,
              });
              return;
            } else {
              setSearchParams({
                namespace: interaction.name,
                ...(interaction.projectID
                  ? { project: interaction.projectID }
                  : {}),
                resource_type: GCPKubernetesResourceType.CLUSTER,
              });
              return;
            }
          } else {
            activityTracker.captureAction(
              actions.CLICK_INSIGHTS_DRILL_DOWN_KUBERNETES
            );
            setSearchParams({
              namespace: interaction.name,
              resource_type: GCPKubernetesResourceType.WORKLOAD,
            });

            return;
          }
        } else {
          setSearchParams({
            cluster: interaction.name,
            project: interaction.projectID,
            resource_type: GCPKubernetesResourceType.NAMESPACE,
          });
          return;
        }
      }
      case GCPKubernetesResourceTable.INTERACTION_VIEW_RESOURCE_CLICKED: {
        if (dataSource === DataSource.KUBERNETES_CONTAINER_USAGE) {
          if (initialGrouping === GCPKubernetesResourceType.NAMESPACE) {
            if (
              searchParams.resource_type === GCPKubernetesResourceType.CLUSTER
            ) {
              setSearchParams({
                cluster: interaction.name,
                project: interaction.projectID,
              });
              return;
            } else if (
              searchParams.resource_type === GCPKubernetesResourceType.WORKLOAD
            ) {
              setSearchParams({ workload: interaction.name });
              return;
            } else {
              setSearchParams({
                namespace: interaction.name,
                project: interaction.projectID,
              });
            }
          } else {
            if (
              searchParams.resource_type === GCPKubernetesResourceType.WORKLOAD
            ) {
              setSearchParams({ workload: interaction.name });
              return;
            } else {
              setSearchParams({
                namespace: interaction.name,
              });
            }
          }
        } else {
          setSearchParams({
            cluster: interaction.name,
            namespace: searchParams.namespace,
            project: interaction.projectID,
          });
          return;
        }
      }
    }
  }

  //
  // Render
  //

  const breadcrumbItems: Item[] = [
    {
      clickable: true,
      label: copyText.gkeUsageResourceLabelClusters,
      onClick: () =>
        setSearchParams({
          cluster: "",
          workload: "",
          resource_type: GCPKubernetesResourceType.CLUSTER,
        }),
    },
    {
      label: searchParams.project
        ? searchParams.cluster
        : `${searchParams.cluster} (${searchParams.project})`,
    },
    {
      clickable: true,
      label: copyText.gkeUsageResourceLabelNamespaces,
      onClick: () =>
        setSearchParams({
          namespace: "",
          workload: "",
          resource_type: GCPKubernetesResourceType.NAMESPACE,
        }),
    },
    { label: searchParams.namespace },
    {
      clickable: true,
      label: copyText.gkeUsageResourceLabelWorkloads,
      onClick: () => setSearchParams({ workload: "" }),
    },
    { label: searchParams.workload },
    {
      label: searchParams.project
        ? searchParams.namespace
        : `${searchParams.namespace} (${searchParams.project})`,
    },
  ];

  let items: Item[] = [];

  if (initialGrouping === GCPKubernetesResourceType.CLUSTER) {
    if (searchParams.cluster) {
      items = [...breadcrumbItems.slice(1, 2)];
    }
    if (resourceType === GCPKubernetesResourceType.NAMESPACE) {
      items = [
        ...breadcrumbItems.slice(1, 2),
        { label: copyText.gkeUsageResourceLabelNamespaces },
      ];
    }

    if (searchParams.namespace) {
      items = breadcrumbItems.slice(1, 4);
    }

    if (resourceType === GCPKubernetesResourceType.WORKLOAD) {
      items = [
        ...breadcrumbItems.slice(1, 4),
        { label: copyText.gkeUsageResourceLabelWorkloads },
      ];
    }

    if (searchParams.workload) {
      items = [...breadcrumbItems.slice(1, 6)];
    }
  } else if (initialGrouping === GCPKubernetesResourceType.NAMESPACE) {
    if (searchParams.namespace) {
      items = [...breadcrumbItems.slice(3, 4)];
    }

    if (searchParams.resource_type === GCPKubernetesResourceType.CLUSTER) {
      items = [
        ...breadcrumbItems.slice(3, 4),
        { label: copyText.gkeUsageResourceLabelClusters },
      ];
    }

    if (searchParams.cluster) {
      items = [
        ...breadcrumbItems.slice(3, 4),
        ...breadcrumbItems.slice(0, 1),
        { label: searchParams.cluster },
      ];
    }

    if (resourceType === GCPKubernetesResourceType.WORKLOAD) {
      items = [
        ...breadcrumbItems.slice(3, 4),
        ...breadcrumbItems.slice(0, 1),
        { label: searchParams.cluster },
        { label: copyText.gkeUsageResourceLabelWorkloads },
      ];
    }

    if (searchParams.workload) {
      items = [
        ...breadcrumbItems.slice(3, 4),
        ...breadcrumbItems.slice(0, 1),
        { label: searchParams.cluster },
        ...breadcrumbItems.slice(4, 5),
        { label: searchParams.workload },
      ];
    }
  }

  const projectOptions = [
    {
      label: copyText.gkeProjectDropdownAllProjectsLabel,
      value: "",
      onClick: handleChangeProject,
    },
    ...projects.map((project) => ({
      label: project.name,
      value: project.name,
      onClick: handleChangeProject,
    })),
  ];

  let sortOptions: {
    label: string;
    value: string;
    onClick: (value: string) => void;
  }[] = [
    {
      label: copyText.gkeUsageResourceLabelClusters,
      value: GCPKubernetesResourceType.CLUSTER,
      onClick: handleChangeInitialGrouping,
    },
    {
      label: copyText.gkeUsageResourceLabelNamespaces,
      value: GCPKubernetesResourceType.NAMESPACE,
      onClick: handleChangeInitialGrouping,
    },
  ];

  if (
    initialGrouping === GCPKubernetesResourceType.CLUSTER &&
    searchParams.cluster
  ) {
    sortOptions = [
      ...sortOptions,
      {
        label: copyText.gkeUsageResourceLabelAllClusters,
        value: "all clusters",
        onClick: handleChangeInitialGrouping,
      },
    ];
  }

  if (
    initialGrouping === GCPKubernetesResourceType.NAMESPACE &&
    searchParams.namespace
  ) {
    sortOptions = [
      ...sortOptions,
      {
        label: copyText.gkeUsageResourceLabelAllNamespaces,
        value: "all namespaces",
        onClick: handleChangeInitialGrouping,
      },
    ];
  }

  const dayCountOptions = [
    {
      label: copyText.gkeDayCountLabel_SEVEN_DAYS,
      value: DayCount.SEVEN_DAYS,
      onClick: handleChangeDayCount,
    },
    {
      label: copyText.gkeDayCountLabel_FOURTEEN_DAYS,
      value: DayCount.FOURTEEN_DAYS,
      onClick: handleChangeDayCount,
    },
    {
      label: copyText.gkeDayCountLabel_THIRTY_DAYS,
      value: DayCount.THIRTY_DAYS,
      onClick: handleChangeDayCount,
    },
  ];

  const formatUsageValue =
    state.usageType === GCPKubernetesUsageType.MEMORY
      ? byteFormatter
      : undefined;

  const readableUsageKeys = {
    requestCPUCores: copyText.gkeUsageRequestedCoresLabel,
    usedCPUCores: copyText.gkeUsageUsedCoresLabel,
    requestMemoryBytes: copyText.gkeUsageRequestedBytesLabel,
    usedMemoryBytes: copyText.gkeUsageUsedBytesLabel,
    CPUCoreUsageTime: copyText.gkeUsageUsedCoresLabel,
    totalCPUCores: copyText.gkeUsageTotalCoresLabel,
    totalMemoryBytes: copyText.gkeUsageTotalBytesLabel,
  };

  const showRowTruncationMessage =
    serverSideSearchEnabled &&
    !isLoadingSearch &&
    resourceListSearchAtDatasetLimit;

  const costChartReportSnapshot = {
    chartType: ChartType.STACKED_BAR,
    dateRange: getDateRange(state.dayCount),
    dataSource: dataSource,
    dimensions: costDimensions,
    durationType: DurationType.CUSTOM,
    isFiscalMode: false,
    fiscalPeriodMap: null,
    granularity: TimeGranularity.DAY,
    measures: costMeasures,
    queryFilters: [...globalFilters, ...kubernetesFilters],
    name: copyText.gkeCostReportSnapshotName,
    xAxisKey: "timestamp",
  };

  const usageChartReportSnapshot = {
    chartType: ChartType.AREA,
    dateRange: getDateRange(state.dayCount),
    dataSource: dataSource,
    dimensions: [],
    durationType: DurationType.CUSTOM,
    isFiscalMode: false,
    fiscalPeriodMap: null,
    granularity: TimeGranularity.DAY,
    measures: usageMeasures,
    queryFilters: [...globalFilters, ...kubernetesFilters],
    name: copyText.gkeUsageReportSnapshotName,
    xAxisKey: "timestamp",
  };

  return (
    <Box paddingTop={theme.space_md}>
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        justifyContent="space-between"
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <Flex>
          <Dropdown
            options={projectOptions}
            placement="bottom-start"
            selectedOption={projectOptions.find(
              (option) => option.value === searchParams.project
            )}
          >
            <Button
              iconEnd={<FontAwesomeIcon icon={faChevronDown} />}
              marginRight={theme.space_xs}
              secondary
              size="small"
            >
              {searchParams.project && searchParams.project.length > 0
                ? searchParams.project
                : copyText.gkeProjectDropdownAllProjectsLabel}
            </Button>
          </Dropdown>
          <Flex alignItems="center" marginRight={theme.space_xs}>
            <FontAwesomeIcon
              color={theme.text_color_secondary}
              icon={faChevronRight}
              size="xs"
            />
          </Flex>

          <Dropdown
            options={sortOptions}
            placement="bottom-start"
            selectedOption={sortOptions.find(
              (option) => option.value === searchParams.initialGrouping
            )}
          >
            <Button
              iconEnd={<FontAwesomeIcon icon={faChevronDown} />}
              marginRight={theme.space_xs}
              secondary
              size="small"
            >
              {searchParams.initialGrouping ===
              GCPKubernetesResourceType.CLUSTER
                ? copyText.gkeUsageResourceLabelClusters
                : copyText.gkeUsageResourceLabelNamespaces}
            </Button>
          </Dropdown>
          <Flex alignItems="center" marginRight={theme.space_xs}>
            {searchParams.namespace || searchParams.cluster ? (
              <FontAwesomeIcon
                color={theme.text_color_secondary}
                icon={faChevronRight}
                size="xs"
              />
            ) : null}
          </Flex>
          <Breadcrumb items={items} />
        </Flex>
        <Dropdown
          defaultSelectedOption={dayCountOptions.find(
            (option) => option.value === state.dayCount
          )}
          options={dayCountOptions}
          placement="bottom-end"
        >
          <Button
            iconEnd={<FontAwesomeIcon icon={faChevronDown} />}
            secondary
            size="small"
            width={100}
          >
            {copyText[`kubernetesDayCountLabel_${state.dayCount}`]}
          </Button>
        </Dropdown>
      </Flex>

      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <KubernetesUsageMeters
          dataSource={dataSource}
          isLoading={isLoadingUsageSummary}
          queryParams={searchParams}
          usageSummary={usageSummary}
        />
      </Box>

      <Flex height={500} marginBottom={theme.space_lg}>
        <Box width="50%" marginRight={theme.space_lg}>
          <InsightsSelector
            resourceName={copyText.gkeCostReportSnapshotName}
            reportSnapshot={costChartReportSnapshot}
          >
            <Box
              backgroundColor={theme.panel_backgroundColor}
              borderRadius={theme.borderRadius_2}
              flex={1}
              height="100%"
              padding={theme.space_md}
              width="100%"
            >
              <Flex justifyContent="space-between">
                <Text fontSize={theme.h3_fontSize}>
                  {copyText.gkeCostChartTitle}
                </Text>
              </Flex>
              <Box height={450} paddingVertical={theme.space_md}>
                <StackedBarChart
                  data={costData}
                  dimensions={costDimensions.map((dimension) => {
                    return { name: dimension };
                  })}
                  isLoading={isLoadingCostData}
                  measures={costMeasures.map((measure) => {
                    return { name: measure, unit: UnitType.CURRENCY };
                  })}
                  showLegend
                  showTooltip
                  timeSeriesGranularity={TimeGranularity.DAY}
                  xAxisKey="timestamp"
                />
              </Box>
            </Box>
          </InsightsSelector>
        </Box>
        <Box width="50%">
          <InsightsSelector
            resourceName={copyText.gkeUsageReportSnapshotName}
            reportSnapshot={usageChartReportSnapshot}
          >
            <Box
              backgroundColor={theme.panel_backgroundColor}
              borderRadius={theme.borderRadius_2}
              flex={1}
              height="100%"
              padding={theme.space_md}
              width="100%"
            >
              <Flex justifyContent="space-between">
                <Text fontSize={theme.h3_fontSize}>
                  {copyText.gkeUsageChartTitle}
                </Text>
                <Box>
                  <Button
                    marginRight={theme.space_md}
                    primary={state.usageType === GCPKubernetesUsageType.CPU}
                    secondary={
                      state.usageType === GCPKubernetesUsageType.MEMORY
                    }
                    size="small"
                    width={120}
                    onClick={() =>
                      mergeState({ usageType: GCPKubernetesUsageType.CPU })
                    }
                  >
                    {copyText.gkeUsageTypeLabelCPU}
                  </Button>
                  <Button
                    primary={state.usageType === GCPKubernetesUsageType.MEMORY}
                    secondary={state.usageType === GCPKubernetesUsageType.CPU}
                    size="small"
                    width={120}
                    onClick={() =>
                      mergeState({ usageType: GCPKubernetesUsageType.MEMORY })
                    }
                  >
                    {copyText.gkeUsageTypeLabelMemory}
                  </Button>
                </Box>
              </Flex>
              <Box height={450} paddingVertical={theme.space_md}>
                <AreaChart
                  data={usageData}
                  hideTotal
                  isLoading={isLoadingUsageData}
                  measures={usageMeasures.map((measure) => {
                    return {
                      name: measure,
                      unit:
                        measure === "requestMemoryBytes" ||
                        measure === "totalMemoryBytes" ||
                        measure === "usedMemoryBytes"
                          ? UnitType.BYTES
                          : UnitType.STANDARD,
                    };
                  })}
                  mergeMeasures
                  readableKeys={readableUsageKeys}
                  showLegend
                  showTooltip
                  timeSeriesGranularity={TimeGranularity.DAY}
                  tooltipFormatter={formatUsageValue}
                  xAxisKey="timestamp"
                  yAxisFormatter={formatUsageValue}
                />
              </Box>
            </Box>
          </InsightsSelector>
        </Box>
      </Flex>
      <Box>
        <Flex
          alignItems="center"
          backgroundColor={theme.panel_backgroundColor}
          borderRadius={theme.borderRadius_2}
          justifyContent="space-between"
          marginBottom={theme.space_md}
          padding={theme.space_md}
          width="100%"
        >
          <Flex alignItems={"baseline"}>
            <Text fontSize={theme.h2_fontSize}>
              {getTableName(resourceType)}
            </Text>
            <Text marginHorizontal={theme.space_sm}>
              {getTableDetail(searchParams)}
            </Text>
          </Flex>

          <Flex alignItems="center" justifyContent="flex-end">
            <Box width="20rem">
              <TextInput
                placeholder={copyText.textInputLabel}
                size="medium"
                value={state.searchText}
                onChange={(e) => mergeState({ searchText: e.target.value })}
                iconEnd={
                  isLoadingSearch ? (
                    <LoadingSpinner
                      color={theme.feedback_neutral_outline}
                      size="xs"
                    />
                  ) : undefined
                }
              />
            </Box>
            <AsyncCSVDownloader
              data={csvData}
              fileName={`ternary-data-${formatDate(new Date(), "MM-dd-yyyy")}`}
              headers={state.csvHeaders}
              isLoading={isLoadingCSVData}
              onClick={handleClickDownloadCSV}
            >
              <Button
                disabled={isLoadingCSVData}
                iconStart={
                  isLoadingCSVData ? (
                    <LoadingSpinner
                      color={theme.feedback_neutral_outline}
                      size="xs"
                    />
                  ) : (
                    <Icon color="inherit" icon={faFileExport} />
                  )
                }
                marginLeft={theme.space_lg}
                secondary
                size="small"
              >
                {copyText.exportButtonLabel}
              </Button>
            </AsyncCSVDownloader>
          </Flex>
        </Flex>

        <GCPKubernetesResourceTable
          dataSource={dataSource}
          dayCount={state.dayCount}
          initialGrouping={initialGrouping}
          initialSortBy={state.sortRule}
          isLoading={isLoadingResourceList}
          isUpdating={isLoadingSearch}
          resources={filteredResourceList}
          resourceType={resourceType}
          selectedProjectID={searchParams.project}
          selectedResourceID={getSelectedListItem(searchParams)}
          onInteraction={handleInteraction}
        />

        {showRowTruncationMessage && (
          <Flex
            alignItems="center"
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            marginBottom={theme.space_xxl}
            marginTop={theme.space_xl}
            padding={theme.space_md}
            width="100%"
          >
            <Icon
              color={theme.secondary_color}
              icon={faExclamationTriangle}
              size="sm"
            />
            <Text marginRight={theme.space_xs} marginLeft={theme.space_sm}>
              {copyText.rowLimitReached}
            </Text>
          </Flex>
        )}
      </Box>
    </Box>
  );
}

export function filterDataBasedOnSearchText(
  data: GCPKubernetesResourceEntity[],
  searchText: string
) {
  return data.filter((datum) => {
    const found = Object.values(datum).find((value) => {
      if (
        (typeof value === "string" &&
          value.toLowerCase().includes(searchText)) ||
        String(value).toLowerCase().includes(searchText)
      ) {
        return true;
      }
    });

    return found !== undefined;
  });
}

function getInitialGrouping(resourceType: string): GCPKubernetesResourceType {
  switch (resourceType) {
    case "NAMESPACE":
      return GCPKubernetesResourceType.NAMESPACE;
    case "WORKLOAD":
      return GCPKubernetesResourceType.WORKLOAD;
    case "CLUSTER":
      return GCPKubernetesResourceType.CLUSTER;
    default:
      return GCPKubernetesResourceType.CLUSTER;
  }
}

function getResourceType(resourceType: string): GCPKubernetesResourceType {
  switch (resourceType) {
    case "NAMESPACE":
      return GCPKubernetesResourceType.NAMESPACE;
    case "WORKLOAD":
      return GCPKubernetesResourceType.WORKLOAD;
    case "CLUSTER":
      return GCPKubernetesResourceType.CLUSTER;
    default:
      return GCPKubernetesResourceType.CLUSTER;
  }
}

function getTableName(resourceType: GCPKubernetesResourceType): string {
  switch (resourceType) {
    case GCPKubernetesResourceType.CLUSTER:
      return copyText.gkeUsageResourceLabelClusters;
    case GCPKubernetesResourceType.NAMESPACE:
      return copyText.gkeUsageResourceLabelNamespaces;
    case GCPKubernetesResourceType.WORKLOAD:
      return copyText.gkeUsageResourceLabelWorkloads;
    default:
      return copyText.gkeUsageResourceLabelClusters;
  }
}

function getTableDetail(searchParams): string {
  const {
    initialGrouping,
    resource_type,
    cluster,
    namespace,
    workload,
    project,
  } = searchParams;

  if (initialGrouping === GCPKubernetesResourceType.CLUSTER) {
    if (resource_type === GCPKubernetesResourceType.CLUSTER) {
      if (!cluster) {
        if (project) {
          return copyText.gkeAllClustersInSelectedProjectLabel.replace(
            "%project%",
            project
          );
        }
        return copyText.gkeAllClustersLabel;
      } else {
        return copyText.gkeSelectedClusterInSelectedProjectLabel
          .replace("%cluster%", cluster)
          .replace("%project%", project);
      }
    }
    if (resource_type === GCPKubernetesResourceType.NAMESPACE) {
      if (!namespace) {
        return copyText.gkeAllNamespacesInSelectedClusterAndProjectLabel
          .replace("%cluster%", cluster)
          .replace("%project%", project);
      } else {
        return copyText.gkeSelectedNamespaceInSelectedClusterAndProjectLabel
          .replace("%namespace%", namespace)
          .replace("%cluster%", cluster)
          .replace("%project%", project);
      }
    }
    if (resource_type === GCPKubernetesResourceType.WORKLOAD) {
      if (!workload) {
        return copyText.gkeAllWorkloadsInSelectedNamespaceClusterAndProjectLabel
          .replace("%namespace%", namespace)
          .replace("%cluster%", cluster)
          .replace("%project%", project);
      } else {
        return copyText.gkeSelectedWorkloadInSelectedNamespaceClusterAndProjectLabel
          .replace("%namespace%", namespace)
          .replace("%cluster%", cluster)
          .replace("%project%", project)
          .replace("%workload%", workload);
      }
    }
  }

  if (initialGrouping === GCPKubernetesResourceType.NAMESPACE) {
    if (resource_type === GCPKubernetesResourceType.NAMESPACE) {
      if (!namespace) {
        if (project) {
          return copyText.gkeAllNamespacesInSelectedProjectLabel.replace(
            "%project%",
            project
          );
        }
        return copyText.gkeAllNamespacesLabel;
      } else {
        if (project) {
          return copyText.gkeSelectedNamespaceInSelectedProjectLabel
            .replace("%namespace%", namespace)
            .replace("%project%", project);
        } else {
          return copyText.gkeSelectedNamespaceLabel.replace(
            "%namespace%",
            namespace
          );
        }
      }
    }
    if (resource_type === GCPKubernetesResourceType.WORKLOAD) {
      if (!workload) {
        return copyText.gkeAllWorkloadsInSelectedNamespaceClusterAndProjectLabel
          .replace("%cluster%", cluster)
          .replace("%namespace%", namespace)
          .replace("%project%", project);
      } else {
        return copyText.gkeSelectedWorkloadInSelectedNamespaceClusterAndProjectLabel
          .replace("%cluster%", cluster)
          .replace("%namespace%", namespace)
          .replace("%project%", project)
          .replace("%workload%", workload);
      }
    }
    if (resource_type === GCPKubernetesResourceType.CLUSTER) {
      if (!cluster) {
        if (project) {
          return copyText.gkeAllClustersInSelectedNamespaceAndProjectLabel
            .replace("%namespace%", namespace)
            .replace("%project%", project);
        } else {
          return copyText.gkeAllClustersInSelectedNamespaceLabel.replace(
            "%namespace%",
            namespace
          );
        }
      } else {
        return copyText.gkeSelectedClusterInSelectedNamespaceAndProjectLabel
          .replace("%cluster%", cluster)
          .replace("%namespace%", namespace)
          .replace("%project%", project);
      }
    }
  }
  return "";
}

function getDataSource(
  searchParams: SearchParams
):
  | typeof DataSource.KUBERNETES_CONTAINER_USAGE
  | typeof DataSource.KUBERNETES_CLUSTER_USAGE {
  if (searchParams.initialGrouping === GCPKubernetesResourceType.NAMESPACE) {
    return DataSource.KUBERNETES_CONTAINER_USAGE;
  } else {
    if (searchParams.resource_type === GCPKubernetesResourceType.CLUSTER) {
      return DataSource.KUBERNETES_CLUSTER_USAGE;
    } else {
      return DataSource.KUBERNETES_CONTAINER_USAGE;
    }
  }
}

function getKubernetesFilters(queryParams: SearchParams): QueryFilter[] {
  let queryFilters: QueryFilter[] = [];

  const { cluster, project, namespace, workload } = queryParams;

  if (cluster) {
    queryFilters = [
      {
        name: "clusterName",
        operator: Operator.EQUALS,
        values: [cluster],
      },
    ];
  }

  if (project) {
    queryFilters = [
      ...queryFilters,
      {
        name: "projectId",
        operator: Operator.EQUALS,
        values: [project],
      },
    ];
  }

  if (namespace) {
    queryFilters = [
      ...queryFilters,
      {
        name: "namespace",
        operator: Operator.EQUALS,
        values: [namespace],
      },
    ];
  }

  if (workload) {
    queryFilters = [
      ...queryFilters,
      {
        name: "workload",
        operator: Operator.EQUALS,
        values: [workload],
      },
    ];
  }

  return queryFilters;
}

function getCostDimensions(
  dataSource:
    | typeof DataSource.KUBERNETES_CLUSTER_USAGE
    | typeof DataSource.KUBERNETES_CONTAINER_USAGE
    | typeof DataSource.KUBERNETES_NODE_USAGE,
  searchParams: SearchParams
): string[] {
  let dimensions: string[] = [];

  if (dataSource === DataSource.KUBERNETES_CLUSTER_USAGE) {
    // cluster
    dimensions = ["clusterName", "projectId"];
  } else if (dataSource === DataSource.KUBERNETES_NODE_USAGE) {
    // node
    dimensions = ["clusterName", "projectId"];
  } else if (dataSource === DataSource.KUBERNETES_CONTAINER_USAGE) {
    // container
    dimensions = ["namespace"];

    if (searchParams.resource_type === GCPKubernetesResourceType.CLUSTER) {
      dimensions = ["clusterName"];
    }
    if (searchParams.resource_type === GCPKubernetesResourceType.WORKLOAD) {
      dimensions = ["workload"];
    }

    if (searchParams.workload) {
      dimensions = [];
    } else if (
      searchParams.namespace &&
      searchParams.resource_type === GCPKubernetesResourceType.NAMESPACE &&
      searchParams.project
    ) {
      dimensions = [];
    }
  }
  return dimensions;
}

function getCostMeasures(
  dataSource:
    | typeof DataSource.KUBERNETES_CLUSTER_USAGE
    | typeof DataSource.KUBERNETES_CONTAINER_USAGE
    | typeof DataSource.KUBERNETES_NODE_USAGE,
  searchParams: SearchParams
): string[] {
  let measures: string[] = [];

  if (dataSource === DataSource.KUBERNETES_CLUSTER_USAGE) {
    // cluster
    measures = ["cost"];

    if (searchParams.cluster) {
      measures = ["totalCPUCost", "totalMemoryCost"];
    }
  } else if (dataSource === DataSource.KUBERNETES_NODE_USAGE) {
    // node
    measures = ["cost"];

    if (searchParams.cluster) {
      measures = ["totalCPUCost", "totalMemoryCost"];
    }
  } else if (dataSource === DataSource.KUBERNETES_CONTAINER_USAGE) {
    // container
    measures = ["totalRequestCost"];

    if (searchParams.workload) {
      measures = ["requestMemoryCost", "requestCPUCost"];
    } else if (
      searchParams.namespace &&
      searchParams.resource_type === GCPKubernetesResourceType.NAMESPACE &&
      searchParams.project
    ) {
      measures = ["requestMemoryCost", "requestCPUCost"];
    }
  }
  return measures;
}

function getUsageMeasures(dataSource, usageType) {
  let measures: string[] = [];

  if (dataSource === DataSource.KUBERNETES_CONTAINER_USAGE) {
    if (usageType === GCPKubernetesUsageType.CPU) {
      measures = ["requestCPUCores", "usedCPUCores"];
    } else {
      measures = ["requestMemoryBytes", "usedMemoryBytes"];
    }
  } else {
    if (usageType === GCPKubernetesUsageType.CPU) {
      measures = ["totalCPUCores", "CPUCoreUsageTime"];
    } else {
      measures = ["totalMemoryBytes", "usedMemoryBytes"];
    }
  }

  return measures;
}

function getListDimensions(
  dataSource:
    | typeof DataSource.KUBERNETES_CLUSTER_USAGE
    | typeof DataSource.KUBERNETES_CONTAINER_USAGE
    | typeof DataSource.KUBERNETES_NODE_USAGE,
  searchParams: SearchParams
) {
  let dimensions: string[] = [];

  if (dataSource === DataSource.KUBERNETES_CONTAINER_USAGE) {
    dimensions = ["namespace"];
    if (searchParams.project) {
      dimensions = [...dimensions, "projectId"];
    }

    if (searchParams.resource_type === GCPKubernetesResourceType.CLUSTER) {
      dimensions = ["namespace", "clusterName", "projectId"];
    }

    if (searchParams.resource_type === GCPKubernetesResourceType.WORKLOAD) {
      dimensions = [...dimensions, "clusterName", "workload"];
    }
  } else {
    dimensions = ["clusterName", "projectId"];
  }
  return dimensions;
}

function getListMeasures(
  dataSource:
    | typeof DataSource.KUBERNETES_CLUSTER_USAGE
    | typeof DataSource.KUBERNETES_CONTAINER_USAGE
    | typeof DataSource.KUBERNETES_NODE_USAGE
) {
  let measures: string[] = [];

  if (dataSource === DataSource.KUBERNETES_CONTAINER_USAGE) {
    measures = [
      "requestCPUCores",
      "requestCPUCost",
      "requestMemoryBytes",
      "requestMemoryCost",
      "usedCPUCores",
      "usedMemoryBytes",
    ];
  } else {
    measures = [
      "CPUCoreUsageTime",
      "totalCPUCores",
      "totalCPUCost",
      "totalMemoryBytes",
      "totalMemoryCost",
      "usedMemoryBytes",
    ];
  }
  return measures;
}

function getServerSideListOrder(params: {
  sortRule: SortRule | null;
  resourceType: GCPKubernetesResourceType;
  measures: string[];
  dimensions: string[];
}): Order | undefined {
  const { sortRule, resourceType, measures, dimensions } = params;

  if (!sortRule) return;

  if (sortRule.id === "name") {
    const dimension =
      resourceType === GCPKubernetesResourceType.CLUSTER
        ? "clusterName"
        : resourceType === GCPKubernetesResourceType.NAMESPACE
          ? "namespace"
          : "workload";

    return {
      [dimension]: sortRule.desc ? "desc" : "asc",
    };
  }

  const find = (...keys: string[]) =>
    keys.find((key) => [...measures, ...dimensions].includes(key));

  const tableIDKeyMap: { [id: string]: string | undefined } = {
    cpuUtilization: find("requestCPUCores", "totalCPUCores"),
    memoryUtilization: find("requestMemoryBytes", "totalMemoryBytes"),
    projectID: "projectId",
    totalBytes: find("requestMemoryBytes", "totalMemoryBytes"),
    totalBytesCost: find("requestMemoryCost", "totalMemoryCost"),
    totalCores: find("requestCPUCores", "totalCPUCores"),
    totalCoresCost: find("requestCPUCost", "totalCPUCost"),
    usedOverRequestedBytes: find("requestMemoryBytes", "totalMemoryBytes"),
    usedOverRequestedCores: find("requestCPUCores", "totalCPUCores"),
    waste: find("requestCPUCost", "totalCPUCost"),
  };

  const tableIDQueryKey = tableIDKeyMap[sortRule.id];

  if (!tableIDQueryKey) return;

  return { [tableIDQueryKey]: sortRule.desc ? "desc" : "asc" };
}

function getSelectedListItem(searchParams: SearchParams): string {
  return `${searchParams.workload ?? ""}/${searchParams.namespace ?? ""}/${
    searchParams.cluster ?? ""
  }/${searchParams.project ?? ""}`;
}

function byteFormatter(value: unknown): string {
  if (typeof value !== "number" || !Number.isFinite(value)) {
    return "0";
  }

  return prettyBytes(value);
}

function getDayCount(dayCountString: string): number {
  switch (dayCountString) {
    case DayCount.SEVEN_DAYS: {
      return 7;
    }
    case DayCount.FOURTEEN_DAYS: {
      return 14;
    }
    case DayCount.THIRTY_DAYS: {
      return 30;
    }
    default:
      return 1;
  }
}

type CSVHeader = {
  label: string;
  key: keyof GCPKubernetesResourceEntity;
};

function getCSVHeaders(params: {
  initialGrouping: GCPKubernetesResourceType;
  resourceType: GCPKubernetesResourceType;
  dataSource: DataSource;
  selectedProjectID: string;
}) {
  const removeHeaders: (keyof GCPKubernetesResourceEntity)[] = [];

  if (params.resourceType === params.initialGrouping) {
    removeHeaders.push("usedCores", "usedBytes");
  }

  if (
    !(
      params.resourceType === GCPKubernetesResourceType.CLUSTER ||
      params.dataSource === DataSource.KUBERNETES_NODE_USAGE
    ) ||
    params.selectedProjectID !== ""
  )
    removeHeaders.push("projectID");

  const allCSVHeaders: CSVHeader[] = [
    {
      label: copyText.gkeResourceListHeaderName,
      key: "name",
    },
    {
      label: copyText.gkeResourceListHeaderProjectID,
      key: "projectID",
    },
    {
      label: copyText.gkeResourceListHeaderCPUCost,
      key: "totalCoresCost",
    },
    {
      label: copyText.gkeResourceListHeaderCPUUtilization,
      key: "cpuUtilization",
    },
    {
      label: copyText.gkeResourceListHeaderUsedCores,
      key: "usedCores",
    },
    {
      label:
        params.resourceType === GCPKubernetesResourceType.CLUSTER
          ? copyText.gkeResourceListHeaderTotalCores
          : copyText.gkeResourceListHeaderTotalRequestedCores,
      key: "totalCores",
    },
    {
      label: copyText.gkeResourceListHeaderMemoryCost,
      key: "totalBytesCost",
    },
    {
      label: copyText.gkeResourceListHeaderMemoryUtilization,
      key: "memoryUtilization",
    },
    {
      label: copyText.gkeResourceListHeaderUsedBytes,
      key: "usedBytes",
    },
    {
      label: copyText.gkeResourceListHeaderTotalBytes,
      key: "totalBytes",
    },
    {
      label: copyText.gkeResourceListHeaderWaste,
      key: "waste",
    },
  ];

  return allCSVHeaders.filter(({ key }) => !removeHeaders.includes(key));
}
