import Meter, { getChangePercent } from "@/components/Meter";
import { useTheme } from "@emotion/react";
import {
  formatCurrencyRounded,
  formatNumber,
  formatNumberRounded,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import React from "react";
import copyText from "../../copyText";

interface Props {
  buckets: number;
  isLoadingBucketCount: boolean;
  isLoadingSpendSummaries: boolean;
  isLoadingRecommendations: boolean;
  lastMonthSpend: number;
  lastMTDSpend: number;
  mtdSpend: number;
  recommendations: Recommendation;
}

type Recommendation = {
  count: number;
  value: number;
};

export default function GCPgcpStorageUsageMeters(props: Props): JSX.Element {
  const theme = useTheme();

  const totalCurrentRecommendations = getChangePercent({
    biggerIsBetter: false,
    value:
      props.recommendations.value !== null ? props.recommendations.value : 0,
    comparison: props.lastMTDSpend ?? 0,
    theme,
  });

  const mtdSpendComparison = getChangePercent({
    biggerIsBetter: false,
    value: props.mtdSpend ?? 0,
    comparison: props.lastMTDSpend ?? 0,
    theme,
  });

  return (
    <Flex
      alignItems="center"
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_2}
      height={160}
      justifyContent="space-between"
      padding={theme.space_sm}
      paddingLeft={theme.space_jumbo}
      paddingRight={theme.space_jumbo}
    >
      <Meter
        header={copyText.gcpStorageUsageMetersBucketLabel}
        loading={props.isLoadingBucketCount}
        value={formatNumber(props.buckets)}
        width="25%"
      />

      <Box
        backgroundColor={theme.secondary_color_background}
        height="100%"
        paddingLeft="2px"
      />
      <Meter
        footerColor={totalCurrentRecommendations.color}
        footerIcon={totalCurrentRecommendations.icon}
        footerTitle={copyText.gcpStorageUsageMeterRecommendationsCaption}
        header={copyText.gcpStorageUsageMeterRecommendationsLabel}
        loading={props.isLoadingRecommendations}
        value={formatNumberRounded(props.recommendations.count || 0)}
        width="25%"
      />
      <Box
        backgroundColor={theme.secondary_color_background}
        height="100%"
        paddingLeft="2px"
      />
      <Meter
        footerColor={mtdSpendComparison.color}
        footerIcon={mtdSpendComparison.icon}
        footerTitle={copyText.gcpStorageUsageMeterMTDCaption}
        footerValue={mtdSpendComparison.textValue}
        header={copyText.gcpStorageUsageMeterMTDLabel}
        loading={props.isLoadingSpendSummaries}
        value={formatCurrencyRounded({
          number: props.mtdSpend || 0,
        })}
        width="25%"
      />
    </Flex>
  );
}
