import { LabelMapsEntity } from "@/api/core/types";
import { QueryClient } from "@tanstack/react-query";
import CubeQuery, {
  Properties,
} from "@ternary/api-lib/analytics/api/CubeQuery";
import { RawData } from "@ternary/api-lib/analytics/types";
import registry from "unilib-registry/instance";
import AnalyticsApiClient from "../AnalyticsApiClient";
import { Params } from "../hooks/useGetPaginatedRawData";

export type CubeQueryBuilderProps = Omit<Properties, "labelMap">;

export function buildCubeQuery(props: CubeQueryBuilderProps): CubeQuery {
  const queryClient = registry.get<QueryClient>("ReactQueryClient");

  const labelMaps = queryClient.getQueryData<LabelMapsEntity>(["labelMaps"]);

  if (!labelMaps) {
    throw new Error("ERROR_MAPS_UNDEFINED");
  }

  const labelMap = labelMaps[props.dataSource] ?? {};

  return new CubeQuery({
    dataSource: props.dataSource,
    dateRange: props.dateRange,
    dimensions: props.dimensions,
    durationType: props.durationType,
    fiscalPeriodMap: props.fiscalPeriodMap,
    granularity: props.granularity,
    isComparisonMode: props.isComparisonMode,
    isFiscalMode: props.isFiscalMode,
    labelMap,
    limit: props.limit,
    measures: props.measures,
    offset: props.offset,
    order: props.order,
    queryFilters: props.queryFilters,
  });
}

// NOTE: This results in a limit of 600k rows.
const MAX_CONCURRENT_QUERY_COUNT = 6;
const QUERY_COUNT_INCREMENT = 2;

export async function getPaginatedResults(params: {
  client: AnalyticsApiClient;
  numberOfQueries: number;
  offset: number;
  previousResults: RawData[];
  queryParams: Params;
}): Promise<RawData[]> {
  const { client, numberOfQueries, previousResults, queryParams } = params;

  let offset = params.offset;

  const queryPromises = Array.from({ length: numberOfQueries }).map(
    async () => {
      const queryPromise = params.client.load(
        buildCubeQuery({ ...queryParams, offset })
      );

      offset += 50000;

      return queryPromise;
    }
  );

  const results = await Promise.all(queryPromises);

  const currentDataset = [...previousResults, ...results].flat();

  if (
    currentDataset.length < offset ||
    numberOfQueries >= MAX_CONCURRENT_QUERY_COUNT
  ) {
    return currentDataset;
  }

  return await getPaginatedResults({
    client,
    offset,
    queryParams,
    previousResults: currentDataset,
    numberOfQueries: numberOfQueries + QUERY_COUNT_INCREMENT,
  });
}
