import useGetCUDFamilies from "@/api/analytics/hooks/useGetCUDFamilies";
import useGetCUDRegions from "@/api/analytics/hooks/useGetCUDRegions";
import useGetCUDUsageData from "@/api/analytics/hooks/useGetCUDUsageData";
import NotConfiguredPlaceholder from "@/components/NotConfiguredPlaceholder";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import useGatekeeper from "@/hooks/useGatekeeper";
import getMergeState from "@/utils/getMergeState";
import { isGCPIntegration } from "@/utils/typeGuards";
import { useTheme } from "@emotion/react";
import { Operator } from "@ternary/api-lib/analytics/enums";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import React, { useEffect, useState } from "react";
import useGetDataIntegrationsByTenantID from "../../../admin/hooks/useGetDataIntegrationsByTenantID";
import useGetCUDLegacyInventoryData from "../hooks/useGetCUDLegacyInventoryData";
import useGetGcpCustomPricing from "../hooks/useGetGcpCustomPricing";
import CUDOptimizerSection from "./CUDOptimizerSection";
import CUDPartiallyConfiguredWarning from "./CUDPartiallyConfiguredWarning";

interface State {
  selectedBillingAccountID: string | null;
  selectedCloudID: string | null;
  selectedFamily: string | null;
  selectedProjectID: string | null;
  selectedRegion: string | null;
  selectedSUDToggleCPU: boolean;
  selectedSUDToggleMemory: boolean;
}

const initialState: State = {
  selectedBillingAccountID: null,
  selectedCloudID: null,
  selectedFamily: null,
  selectedProjectID: null,
  selectedRegion: null,
  selectedSUDToggleCPU: true,
  selectedSUDToggleMemory: true,
};

const defaultFamilies = [];
const defaultRegions = [];

export default function CUDOptimizerContainer(): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const { data: integrations = [], isLoading: isLoadingClouds } =
    useGetDataIntegrationsByTenantID(authenticatedUser.tenant.id);

  // NOTE: CUDs only supports GCP Cloud configurations.
  const gcpIntegrations = integrations.filter(isGCPIntegration);

  const selectedCloud = gcpIntegrations.find((integration) => {
    return integration.id === state.selectedCloudID;
  });

  const cudSharingEnabled =
    selectedCloud !== undefined && selectedCloud.config.cudSharingEnabled;

  const { data: regions = defaultRegions, isLoading: _isLoadingRegions } =
    useGetCUDRegions(
      { cloudID: state.selectedCloudID ?? "" },
      { enabled: !!state.selectedCloudID }
    );

  const isLoadingRegions = state.selectedCloudID ? _isLoadingRegions : false;

  const enableFamiliesQuery = !!state.selectedCloudID && !!state.selectedRegion;

  const { data: families = defaultFamilies, isLoading: _isLoadingFamilies } =
    useGetCUDFamilies(
      {
        cloudID: state.selectedCloudID ?? "",
        region: state.selectedRegion ?? "",
      },
      { enabled: !!state.selectedCloudID && !!state.selectedRegion }
    );

  const isLoadingFamilies = enableFamiliesQuery ? _isLoadingFamilies : false;

  const { data: cudInventoryData = [], isLoading: isLoadingCudInventoryData } =
    useGetCUDLegacyInventoryData({
      queryFilters: [
        {
          name: "region",
          operator: Operator.EQUALS,
          values: [state.selectedRegion ?? ""],
        },
        {
          name: "family",
          operator: Operator.EQUALS,
          values: [state.selectedFamily ?? ""],
        },
      ],
    });

  const { data: customPricing, isFetching: isLoadingCustomPricing } =
    useGetGcpCustomPricing(
      {
        queryFilters: [
          {
            name: "billingAccountID",
            operator: Operator.EQUALS,
            values: [state.selectedBillingAccountID ?? ""],
          },
          {
            name: "region",
            operator: Operator.EQUALS,
            values: [state.selectedRegion ?? ""],
          },
          {
            name: "family",
            operator: Operator.EQUALS,
            values: [state.selectedFamily ?? ""],
          },
        ],
      },
      {
        enabled:
          gatekeeper.canApplyCustomPricing &&
          !!state.selectedBillingAccountID &&
          !!state.selectedFamily &&
          !!state.selectedRegion,
      }
    );

  const hasRequiredFilters =
    !!state.selectedCloudID && !!state.selectedRegion && !!state.selectedFamily;

  const enableCUDUsageQuery = cudSharingEnabled
    ? hasRequiredFilters && isLoadingCudInventoryData
    : hasRequiredFilters;

  const cudUsageData = useGetCUDUsageData(
    {
      cloudID: state.selectedCloudID ?? "",
      family: state.selectedFamily ?? "",
      region: state.selectedRegion ?? "",
    },
    { enabled: enableCUDUsageQuery }
  );

  const [
    { data: timeSeriesUsageCPU = [] },
    { data: timeSeriesUsageMemory = [] },
  ] = cudUsageData;

  const _isLoadingCUDUsageData = cudUsageData.some((data) => data.isLoading);

  const isLoadingCUDUsageData = enableCUDUsageQuery
    ? _isLoadingCUDUsageData
    : false;

  // Side Effects

  useEffect(() => {
    if (!state.selectedCloudID && gcpIntegrations.length > 0) {
      mergeState({
        selectedBillingAccountID: gcpIntegrations[0].config.billingAccountID,
        selectedCloudID: gcpIntegrations[0].id,
      });
    }
  }, [gcpIntegrations, state.selectedCloudID]);

  useEffect(() => {
    if (regions.length === 1) {
      mergeState({ selectedRegion: regions[0].name });
    }
  }, [regions]);

  useEffect(() => {
    if (families.length === 1) {
      mergeState({ selectedFamily: families[0].name });
    }
  }, [families]);

  if (!gatekeeper.isConfiguredCUDs) {
    return <NotConfiguredPlaceholder />;
  }

  return (
    <>
      {!gatekeeper.isCommittedUseConfiguredOnAllGCPClouds && (
        <Box marginBottom={theme.space_lg}>
          <CUDPartiallyConfiguredWarning />
        </Box>
      )}
      <CUDOptimizerSection
        gcpIntegrations={gcpIntegrations}
        cpuUsage={timeSeriesUsageCPU}
        cudInventory={cudInventoryData}
        customPricing={customPricing}
        families={families}
        isDisabledCloudFilter={false}
        isDisabledFamilyFilter={state.selectedRegion === null}
        isDisabledProjectFilter={
          state.selectedFamily === null || cudSharingEnabled
        }
        isDisabledRegionFilter={state.selectedCloudID === null}
        isEnabledCUDSharing={cudSharingEnabled}
        isLoadingCloudFilter={isLoadingClouds}
        isLoadingCustomPricing={isLoadingCustomPricing}
        isLoadingFamilies={isLoadingFamilies}
        isLoadingRegions={isLoadingRegions}
        isLoadingUsageData={isLoadingCUDUsageData}
        memoryUsage={timeSeriesUsageMemory}
        regions={regions}
        selectedCloudID={state.selectedCloudID}
        selectedFamily={state.selectedFamily}
        selectedRegion={state.selectedRegion}
        selectedSUDToggleCPU={state.selectedSUDToggleCPU}
        selectedSUDToggleMemory={state.selectedSUDToggleMemory}
        onChangeCloud={(selection: string) => {
          mergeState({
            selectedCloudID: selection,
            selectedFamily: null,
            selectedProjectID: null,
            selectedRegion: null,
          });
        }}
        onChangeFamily={(selection: string) => {
          mergeState({ selectedFamily: selection, selectedProjectID: null });
        }}
        onChangeRegion={(selection: string) => {
          mergeState({
            selectedFamily: null,
            selectedProjectID: null,
            selectedRegion: selection,
          });
        }}
        onChangeSUDToggleCPU={(selection: boolean) => {
          mergeState({
            selectedSUDToggleCPU: selection,
          });
        }}
        onChangeSUDToggleMemory={(selection: boolean) => {
          mergeState({
            selectedSUDToggleMemory: selection,
          });
        }}
      />
    </>
  );
}
