import { ProviderType } from "@ternary/api-lib/analytics/enums";

export function sortProviderType(providers: string[]): string[] {
  return providers.sort((a, b) => {
    if (a === ProviderType.FOCUS) return -1;
    if (b === ProviderType.FOCUS) return 1;
    if (a === ProviderType.TERNARY) return -1;
    if (b === ProviderType.TERNARY) return 1;

    return a.localeCompare(b);
  });
}
