type User = {
  firstName: string;
  lastName: string;
};

export function getFullName(user?: User | null): string {
  if (!user?.firstName?.trim() || !user?.lastName?.trim()) return "";
  return `${user.firstName} ${user.lastName}`;
}

export function maskEmail(email: string) {
  const [username, domain] = email.split("@");

  const maskedUsername = username[0] + username.slice(1).replace(/./g, "*");

  return `${maskedUsername}@${domain}`;
}

export default {
  getFullName,
  maskEmail,
};
