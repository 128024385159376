import { createColumnHelper } from "@tanstack/react-table";
import {
  formatCurrency,
  formatPercentage,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import { AWSRateAccountScope } from "@ternary/api-lib/constants/enums";
import { AWSRateSavingsPlanRecommendationEntity } from "@ternary/api-lib/core/types";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import { groupBy } from "lodash";
import React, { useMemo } from "react";
import useRefFn from "../../../../hooks/useRefFn";
import copyText from "../../copyText";
import { AWSAccountLevel, AWSCommittedUseSavingsPlanType } from "../types";

type TableData = {
  id: string;
  currentAverageHourlyOnDemandSpend: number;
  estimatedAverageUtilizationPercentage: number;
  estimatedMonthlySavingsAmount: number;
  estimatedOnDemandCost: number;
  estimatedROI: number;
  estimatedSavingsPercentage: number;
  estimatedSavingsPlanCost: number;
  groupingID: string;
  hourlyCommitmentToPurchase: number;
  linkedAccountID: string | null;
  offeringID: string;
  sourceRecommendation: AWSRateSavingsPlanRecommendationEntity;
  type: string;
  uniqCount: number;
};

type Props = {
  buttonIcon: JSX.Element;
  isLoading: boolean;
  accountScope: AWSRateAccountScope;
  savingsPlanRecommendations: AWSRateSavingsPlanRecommendationEntity[];
  onSelectRecommendation: (rec: AWSRateSavingsPlanRecommendationEntity) => void;
};

export default function AWSSavingsPlanRecommendationTable(props: Props) {
  const data = useMemo(
    () => getTableData(props.savingsPlanRecommendations),
    [props.savingsPlanRecommendations]
  );

  const onSelectRecommendation = useRefFn(props.onSelectRecommendation);

  const columnHelper = createColumnHelper<TableData>();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "select",
        cell: ({ row }) => (
          <Button
            iconStart={props.buttonIcon}
            primary
            size="tiny"
            onClick={() =>
              onSelectRecommendation(row.original.sourceRecommendation)
            }
          />
        ),
        size: 48,
      }),
      columnHelper.accessor("estimatedMonthlySavingsAmount", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.awsSPTableHeader_estimatedMonthlySavingsAmount,
        meta: { align: "right" },
        size: 210,
      }),
      columnHelper.accessor("estimatedSavingsPercentage", {
        cell: ({ getValue }) => <>{formatPercentage(getValue())}</>,
        header: copyText.awsSPTableHeader_estimatedMonthlySavingsPercentage,
        meta: { align: "right" },
        size: 210,
      }),
      columnHelper.accessor("hourlyCommitmentToPurchase", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.awsSPTableHeader_hourlyCommitmentToPurchase,
        meta: { align: "right" },
        size: 230,
      }),
      columnHelper.accessor("estimatedSavingsPlanCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.awsSPTableHeader_estimatedSavingsPlanCost,
        meta: { align: "right" },
        size: 210,
      }),
      columnHelper.accessor("estimatedOnDemandCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.awsSPTableHeader_estimatedOnDemandCost,
        meta: { align: "right" },
        size: 210,
      }),
      columnHelper.accessor("currentAverageHourlyOnDemandSpend", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.awsSPTableHeader_currentAverageHourlyOnDemandSpend,
        meta: { align: "right" },
        size: 260,
      }),
      columnHelper.accessor("estimatedAverageUtilizationPercentage", {
        cell: ({ getValue }) => <>{formatPercentage(getValue())}</>,
        header: copyText.awsSPTableHeader_estimatedAverageUtilization,
        meta: { align: "right" },
        size: 210,
      }),
      columnHelper.accessor("estimatedROI", {
        cell: ({ getValue }) => <>{formatPercentage(getValue() / 100)}</>,
        header: copyText.awsSPTableHeader_estimatedROI,
        meta: { align: "right" },
        size: 160,
      }),
      columnHelper.accessor("type", {
        cell: ({ getValue }) => <>{getTypeCopyText(getValue())}</>,
        header: copyText.awsSPTableHeader_type,
        meta: { align: "left" },
        size: 210,
      }),
      columnHelper.accessor("offeringID", {
        cell: ({ getValue }) => <>{getValue()}</>,
        header: copyText.awsSPTableHeader_offeringID,
        meta: { align: "left" },
        size: 210,
      }),
      ...(props.accountScope === AWSAccountLevel.LINKED
        ? [
            columnHelper.accessor("linkedAccountID", {
              cell: ({ getValue }) => <>{getValue()}</>,
              header: copyText.awsSPTableHeader_10_linkedAccountID,
              size: 160,
            }),
          ]
        : []),
    ],
    [props.accountScope]
  );

  return (
    <Table
      columns={columns}
      data={data}
      initialState={{
        sorting: [{ id: "estimatedMonthlySavingsAmount", desc: true }],
      }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

function getTypeCopyText(type: string) {
  switch (type) {
    case AWSCommittedUseSavingsPlanType.COMPUTE_SP:
    case AWSCommittedUseSavingsPlanType.EC2_INSTANCE_SP:
    case AWSCommittedUseSavingsPlanType.SAGEMAKER_SP: {
      const key: keyof typeof copyText = `awsSPTableValueType_${type}`;
      return copyText[key];
    }
    default:
      return type;
  }
}

function getGroupingID(rec: AWSRateSavingsPlanRecommendationEntity) {
  const DELIMITER = ",";

  return [rec.offeringID, rec.accountID]
    .map((value) => value !== null && value.toUpperCase())
    .join(DELIMITER);
}

function getTableData(
  savingsPlanRecommendations: AWSRateSavingsPlanRecommendationEntity[]
) {
  const recsGroupedByRow = groupBy(savingsPlanRecommendations, getGroupingID);

  const data: TableData[] = [];

  Object.values(recsGroupedByRow).forEach((recGroup) => {
    const rec = recGroup[0];

    data.push({
      id: rec.id,
      currentAverageHourlyOnDemandSpend: rec.currentAverageHourlyOnDemandSpend,
      estimatedAverageUtilizationPercentage:
        rec.estimatedAverageUtilization / 100,
      estimatedMonthlySavingsAmount: rec.estimatedMonthlySavingsAmount,
      estimatedOnDemandCost: rec.estimatedOnDemandCost,
      estimatedROI: rec.estimatedROI,
      estimatedSavingsPercentage: rec.estimatedSavingsPercentage / 100,
      estimatedSavingsPlanCost: rec.estimatedSavingsPlanCost,
      groupingID: getGroupingID(rec),
      hourlyCommitmentToPurchase: rec.hourlyCommitmentToPurchase,
      linkedAccountID: rec.accountID,
      offeringID: rec.offeringID,
      type: rec.savingsPlanType,
      sourceRecommendation: rec,
      uniqCount: recGroup.length,
    });
  });

  return data;
}
