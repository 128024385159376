import { DataSource, DurationType } from "@ternary/api-lib/analytics/enums";
import { QueryFilter } from "@ternary/api-lib/analytics/types";

export type Input<T = unknown> = {
  value: T;
  hasChanged?: boolean;
  isValid: boolean;
};

export const RECOMMENDATION_KIND_ANOMALY = "anomaly";
export const RECOMMENDATION_KIND_DEFAULT = "recommendation";

export type SortRule = {
  id: string;
  desc: boolean;
};

export type ReportConfig = {
  dataSource: DataSource;
  dimensions: string[];
  durationType: DurationType;
  endDate: string;
  filters: QueryFilter[];
  measures: string[];
  startDate: string;
};
