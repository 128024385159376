import { UnitType } from "../enums";

const measures = [
  { name: "instanceCountWithCPUMetrics", unit: UnitType.STANDARD },
  { name: "instanceCountWithDiskMetrics", unit: UnitType.STANDARD },
  { name: "instanceCountWithGpuMetrics", unit: UnitType.STANDARD },
  { name: "instanceCountWithMemoryMetrics", unit: UnitType.STANDARD },
  { name: "instanceCountWithNetworkInMetrics", unit: UnitType.STANDARD },
  { name: "instanceCountWithNetworkOutMetrics", unit: UnitType.STANDARD },
  { name: "maxOfAverageCPUUtilization", unit: UnitType.PERCENTAGE },
  { name: "maxOfAverageDiskUtilization", unit: UnitType.PERCENTAGE },
  { name: "maxOfAverageGPUUtilization", unit: UnitType.PERCENTAGE },
  { name: "maxOfAverageMemoryUtilization", unit: UnitType.PERCENTAGE },
  { name: "minOfAverageCPUUtilization", unit: UnitType.PERCENTAGE },
  { name: "minOfAverageDiskUtilization", unit: UnitType.PERCENTAGE },
  { name: "minOfAverageGPUUtilization", unit: UnitType.PERCENTAGE },
  { name: "minOfAverageMemoryUtilization", unit: UnitType.PERCENTAGE },
  { name: "p50OfMaxCpuUtilization", unit: UnitType.PERCENTAGE },
  { name: "p50OfMaxDiskUtilization", unit: UnitType.PERCENTAGE },
  { name: "p50OfMaxGpuUtilization", unit: UnitType.PERCENTAGE },
  { name: "p50OfMaxMemoryUtilization", unit: UnitType.PERCENTAGE },
  { name: "p95OfMaxCpuUtilization", unit: UnitType.PERCENTAGE },
  { name: "p95OfMaxDiskUtilization", unit: UnitType.PERCENTAGE },
  { name: "p95OfMaxGpuUtilization", unit: UnitType.PERCENTAGE },
  { name: "p95OfMaxMemoryUtilization", unit: UnitType.PERCENTAGE },
  { name: "p99OfMaxCpuUtilization", unit: UnitType.PERCENTAGE },
  { name: "p99OfMaxDiskUtilization", unit: UnitType.PERCENTAGE },
  { name: "p99OfMaxGpuUtilization", unit: UnitType.PERCENTAGE },
  { name: "p99OfMaxMemoryUtilization", unit: UnitType.PERCENTAGE },
  { name: "sumUsageCost", unit: UnitType.CURRENCY },
  { name: "sumUsageHours", unit: UnitType.STANDARD },
];

const dimensions = [
  "usageAccountId",
  "productInstanceTypeFamily",
  "productInstanceType",
  "productInstanceFamily",
  "vcpuPerInstance",
  "gpuPerInstance",
  "memoryPerInstance",
  "productRegion",
];

export const awsComputeUtilizationSchema = { dimensions, measures };
