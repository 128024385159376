import { buildCubeQuery } from "@/api/analytics/utils";
import { validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import {
  SnowflakeWarehouseDatum,
  SnowflakeWarehouseSummaryStruct,
} from "../types";

export interface Params {
  dateRange: Date[];
}

export default function useGetSnowflakeWarehouseSummary(
  params: Params,
  options?: UseQueryOptions<SnowflakeWarehouseDatum[], UError>
): UseQueryResult<SnowflakeWarehouseDatum[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["snowflakeWarehouseSummary", params],
    queryFn: async () => {
      const measures = [
        "computeCreditsUsed",
        "cloudServicesCreditsUsed",
        "totalCreditsUsed",
        "queryCount",
        "queryElapsedTime",
      ];

      const dimensions = [
        "accountName",
        "warehouseName",
        "warehouseSize",
        "warehouseID",
        "warehouseType",
        "tags",
      ];

      const result = await client.load(
        buildCubeQuery({
          dataSource: DataSource.SNOWFLAKE_WAREHOUSE_USAGE,
          dateRange: params.dateRange,
          dimensions,
          measures,
        })
      );

      const data = result.map((datum): SnowflakeWarehouseDatum => {
        const [error, validData] = validate(
          datum,
          SnowflakeWarehouseSummaryStruct
        );
        if (error) {
          throw new UError("INVALID_WAREHOUSE_COST_DATA", {
            context: { error, result: datum },
          });
        }

        return {
          accountName: validData.accountName ?? "null",
          cloudServicesCreditsUsed: validData.cloudServicesCreditsUsed ?? 0,
          computeCreditsUsed: validData.computeCreditsUsed ?? 0,
          queryCount: validData.queryCount ?? 0,
          queryElapsedTime: validData.queryElapsedTime ?? 0,
          tags: validData.tags ?? "null",
          totalCreditsUsed: validData.totalCreditsUsed ?? 0,
          warehouseID: validData.warehouseID,
          warehouseName: validData.warehouseName ?? "null",
          warehouseSize: validData.warehouseSize ?? "null",
          warehouseType: validData.warehouseType ?? "null",
        };
      });

      return data;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
