import React from "react";
import { formatDate } from "../analytics/utils/DateUtils";
import { formatCurrency } from "../analytics/utils/NumberFormatUtils";
import Box from "../ui-lib/components/Box";
import Divider from "../ui-lib/components/Divider";
import Flex from "../ui-lib/components/Flex";
import Text from "../ui-lib/components/Text";
import { defaultTheme } from "../ui-lib/theme/default";
import palette from "../ui-lib/theme/palette";
import ThemeProvider from "../ui-lib/theme/ThemeProvider";
import copyText from "./MspBillingStatementInvoiceTemplate.copyText";

const defaultCostMeasure = "billedCost";
const itemLineHeight = "20px";
const totalItemPaddingVertical = "5px";

type BillingInfo = {
  addressLine1: string;
  addressLine2: string | null;
  city: string;
  paymentInstructions: string | null;
  paymentTerm: string | null;
  state: string;
  zipCode: string;
};

type BillingStatement = {
  createdAt: string;
  endDate?: string | null;
  invoiceMonth?: string | null;
  invoiceNumber?: string | null;
  primaryGrouping: string;
  secondaryGrouping?: string | null;
  startDate?: string | null;
};

interface Props {
  companyBillingInfo?: BillingInfo;
  companyTenant?: { logoImageSquareURL?: string | null; name: string };
  customerBillingInfo?: BillingInfo & { customerDetails?: string | null };
  customerTenant?: { name: string };
  csv?: string;
  isServer?: boolean;
  statement?: BillingStatement;
}

export function MspBillingStatementInvoiceTemplate(props: Props) {
  const table =
    props.csv && props.statement
      ? csvToHtmlTable(props.csv, props.statement)
      : placeholderTable;

  function getInvoiceDateText() {
    if (!props.statement) {
      return "MM-DD-YYYY - MM-DD-YYYY";
    }

    if (props.statement.invoiceMonth) {
      return props.statement.invoiceMonth;
    }

    if (props.statement.endDate && props.statement.startDate) {
      return `${formatDate(new Date(props.statement.startDate), "MM-dd-yyyy")} - ${formatDate(new Date(props.statement.endDate), "MM-dd-yyyy")}`;
    }
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Flex
        backgroundColor={palette.white}
        direction="column"
        height={props.isServer ? undefined : 700}
        justifyContent="space-between"
        overflowY="auto"
        padding={props.isServer ? undefined : "50px"}
        width={props.isServer ? undefined : 540}
      >
        <Box>
          <Flex
            justifyContent="space-between"
            marginBottom={defaultTheme.space_lg}
          >
            {props.companyTenant?.logoImageSquareURL ? (
              <img
                src={props.companyTenant.logoImageSquareURL}
                height={80}
                width={80}
              />
            ) : (
              <Flex
                alignItems="center"
                backgroundColor={palette.grey[10]}
                height={80}
                padding={defaultTheme.space_xs}
                width={80}
              >
                <Text
                  align="center"
                  color="black"
                  fontSize={defaultTheme.fontSize_small}
                  lineHeight="14px"
                >
                  {copyText.companyLogoPlaceholder}
                </Text>
              </Flex>
            )}
            <Box>
              <Text align="right" bold color="black" lineHeight="18px">
                {props.companyTenant
                  ? props.companyTenant.name
                  : `[${copyText.companyNamePlaceholder}]`}
              </Text>
              {props.companyBillingInfo ? (
                <Box>
                  <Text align="right" color="black" lineHeight="18px">
                    {props.companyBillingInfo.addressLine1}
                  </Text>
                  {props.companyBillingInfo.addressLine2 && (
                    <Text align="right" color="black" lineHeight="18px">
                      {props.companyBillingInfo.addressLine2}
                    </Text>
                  )}
                  <Text align="right" color="black" lineHeight="18px">
                    {`${props.companyBillingInfo.city}, ${props.companyBillingInfo.state} ${props.companyBillingInfo.zipCode}`}
                  </Text>
                </Box>
              ) : (
                <Text
                  align="right"
                  color="black"
                  lineHeight="18px"
                >{`[${copyText.companyAddressPlaceholder}]`}</Text>
              )}
            </Box>
          </Flex>
          <Box marginBottom={defaultTheme.space_lg}>
            <Text appearance="h2" color="black" lineHeight="40px">
              {copyText.header}
            </Text>
            <Text color="black" lineHeight="18px">
              {copyText.dateLabel}:{" "}
              {props.statement
                ? formatDate(new Date(props.statement.createdAt), "MM-dd-yyyy")
                : "MM-DD-YYYY"}
            </Text>
            <Text color="black" lineHeight="18px">
              {copyText.numberLabel}: {props.statement?.invoiceNumber ?? ""}
            </Text>
            <Text color="black" lineHeight="18px">
              {copyText.periodLabel}: {getInvoiceDateText()}
            </Text>
            <Text color="black" lineHeight="18px">
              {copyText.paymentTermLabel}:{" "}
              {props.customerBillingInfo?.paymentTerm
                ? props.customerBillingInfo?.paymentTerm
                : props.companyBillingInfo?.paymentTerm
                  ? props.companyBillingInfo?.paymentTerm
                  : `[${copyText.paymentTermPlaceholder}]`}
            </Text>
          </Box>
          <Box marginBottom={defaultTheme.space_lg}>
            <Text bold color="black" lineHeight="18px">
              {copyText.billToLabel}
            </Text>
            <Text color="black" lineHeight="18px">
              {props.customerTenant
                ? props.customerTenant.name
                : `[${copyText.customerNamePlaceholder}]`}
            </Text>
            {props.customerBillingInfo ? (
              <Box>
                <Text color="black" lineHeight="18px">
                  {props.customerBillingInfo.addressLine1}
                </Text>
                {props.customerBillingInfo.addressLine2 && (
                  <Text color="black" lineHeight="18px">
                    {props.customerBillingInfo.addressLine2}
                  </Text>
                )}
                <Text color="black" lineHeight="18px">
                  {`${props.customerBillingInfo.city}, ${props.customerBillingInfo.state} ${props.customerBillingInfo.zipCode}`}
                </Text>
              </Box>
            ) : (
              <Text
                color="black"
                lineHeight="18px"
              >{`[${copyText.customerAddressPlaceholder}]`}</Text>
            )}
            <Text color="black" lineHeight="18px">
              {props.customerBillingInfo?.customerDetails
                ? props.customerBillingInfo?.customerDetails
                : props.isServer
                  ? null
                  : `[${copyText.customerDetailsPlaceholder}]`}
            </Text>
          </Box>
          {table}
        </Box>
        <Box marginTop={defaultTheme.space_md} width="100%">
          <Text bold color="black" fontSize={defaultTheme.fontSize_base}>
            {copyText.paymentInstructionsHeader}
          </Text>
          <Divider
            border="2px"
            color={palette.grey[10]}
            margin={defaultTheme.space_xs}
          />
          <Text color="black">
            {props.customerBillingInfo?.paymentInstructions
              ? props.customerBillingInfo?.paymentInstructions
              : props.companyBillingInfo?.paymentInstructions
                ? props.companyBillingInfo?.paymentInstructions
                : `[${copyText.paymentInstructionsPlaceholder}]`}
          </Text>
        </Box>
      </Flex>
    </ThemeProvider>
  );
}

function csvToHtmlTable(rawCsv: string, statement: BillingStatement) {
  const csv = rawCsv.replaceAll('"', "");

  const rows = csv.trim().split("\n");
  const headers = rows[0].split(",");

  // Filter rows that have a null value for cost.
  const data = rows
    .slice(1)
    .reduce((accum: { [key: string]: string }[], row) => {
      const values = row.split(",");

      if (values.includes("null")) return accum;

      const object = values.reduce(
        (accum: { [key: string]: string }, value, i) => ({
          ...accum,
          [headers[i]]: value,
        }),
        {}
      );

      return [...accum, object];
    }, []);

  const dataGroupedByPrimaryGrouping: {
    [key: string]: { subtotal: number; items: { [key: string]: string }[] };
  } = {};

  for (const object of data) {
    const primaryGroupingValue = object[statement.primaryGrouping];
    const billedCost = Number(object[defaultCostMeasure]);

    if (!dataGroupedByPrimaryGrouping[primaryGroupingValue]) {
      dataGroupedByPrimaryGrouping[primaryGroupingValue] = {
        items: [object],
        subtotal: billedCost,
      };
    } else {
      dataGroupedByPrimaryGrouping[primaryGroupingValue].items.push(object);

      dataGroupedByPrimaryGrouping[primaryGroupingValue] = {
        items: dataGroupedByPrimaryGrouping[primaryGroupingValue].items,
        subtotal: (dataGroupedByPrimaryGrouping[
          primaryGroupingValue
        ].subtotal += billedCost),
      };
    }
  }

  const secondaryGrouping = statement.secondaryGrouping;

  const total = Object.values(dataGroupedByPrimaryGrouping).reduce(
    (accum, object) => (accum += object.subtotal),
    0
  );

  return (
    <Box>
      {Object.keys(dataGroupedByPrimaryGrouping).map((key, index) => {
        const { items, subtotal } = dataGroupedByPrimaryGrouping[key];

        return (
          <Box key={index}>
            <Flex
              backgroundColor={secondaryGrouping ? "#ECEEF1" : undefined}
              borderRadius={defaultTheme.borderRadius_1}
              justifyContent="space-between"
              marginVertical={defaultTheme.space_xxs}
              paddingHorizontal={defaultTheme.space_xs}
              paddingVertical={
                secondaryGrouping ? totalItemPaddingVertical : undefined
              }
            >
              <Text lineHeight={itemLineHeight}>{key}</Text>
              <Text bold={!!secondaryGrouping} lineHeight={itemLineHeight}>
                {formatCurrency({ number: subtotal })}
              </Text>
            </Flex>
            {secondaryGrouping && (
              <Flex direction="column" gap="6px">
                {items.map((item, index) => {
                  const billedCost = Number(item[defaultCostMeasure]);

                  return (
                    <Flex
                      key={index}
                      justifyContent="space-between"
                      paddingHorizontal={defaultTheme.space_xs}
                    >
                      <Text lineHeight={itemLineHeight}>
                        {item[secondaryGrouping]}
                      </Text>
                      <Text lineHeight={itemLineHeight}>
                        {formatCurrency({ number: billedCost })}
                      </Text>
                    </Flex>
                  );
                })}
              </Flex>
            )}
          </Box>
        );
      })}
      <Flex
        backgroundColor={"#ECEEF1"}
        borderRadius={defaultTheme.borderRadius_1}
        justifyContent="space-between"
        marginVertical={defaultTheme.space_xxs}
        paddingHorizontal={defaultTheme.space_xs}
        paddingVertical={totalItemPaddingVertical}
      >
        <Text bold lineHeight={itemLineHeight}>
          {copyText.totalLabel}
        </Text>
        <Text bold lineHeight={itemLineHeight}>
          {formatCurrency({ number: total })}
        </Text>
      </Flex>
    </Box>
  );
}

const placeholderTable = (
  <Box>
    <Flex
      justifyContent="space-between"
      marginVertical={defaultTheme.space_xxs}
      paddingHorizontal={defaultTheme.space_xs}
    >
      <Text lineHeight={itemLineHeight}>{"AWS"}</Text>
      <Text lineHeight={itemLineHeight}>{"$##,###.##"}</Text>
    </Flex>
    <Flex
      justifyContent="space-between"
      marginVertical={defaultTheme.space_xxs}
      paddingHorizontal={defaultTheme.space_xs}
    >
      <Text lineHeight={itemLineHeight}>{"Azure"}</Text>
      <Text lineHeight={itemLineHeight}>{"$##,###.##"}</Text>
    </Flex>
    <Flex
      justifyContent="space-between"
      marginVertical={defaultTheme.space_xxs}
      paddingHorizontal={defaultTheme.space_xs}
    >
      <Text lineHeight={itemLineHeight}>{"GCP"}</Text>
      <Text lineHeight={itemLineHeight}>{"$##,###.##"}</Text>
    </Flex>
    <Flex
      backgroundColor={"#ECEEF1"}
      borderRadius={defaultTheme.borderRadius_1}
      justifyContent="space-between"
      marginVertical={defaultTheme.space_xxs}
      paddingHorizontal={defaultTheme.space_xs}
      paddingVertical={totalItemPaddingVertical}
    >
      <Text bold lineHeight={itemLineHeight}>
        {copyText.totalLabel}
      </Text>
      <Text bold lineHeight={itemLineHeight}>
        {"$##,###.##"}
      </Text>
    </Flex>
  </Box>
);
