import { UpdateMspTenantsSettingsParams } from "@/api/core/types";
import { SideDrawer } from "@/components/SideDrawer";
import paths from "@/constants/paths";
import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import useGetTenantsByParentTenantID from "@/features/global-admin/hooks/useGetTenantsByParentTenantID";
import useGatekeeper from "@/hooks/useGatekeeper";
import BatchModal from "@/ui-lib/components/BatchModal";
import ConfirmationModal from "@/ui-lib/components/ConfirmationModal";
import { AlertType, postAlert } from "@/utils/alerts";
import { useDebounce } from "@/utils/timers";
import { useTheme } from "@emotion/react";
import { useQueryClient } from "@tanstack/react-query";
import { MspTenantSettingsEntity } from "@ternary/api-lib/core/types/MspTenantSettings";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import { keyBy } from "lodash";
import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import { useMspStore } from "../../../lib/zustand";
import getMergeState from "../../../utils/getMergeState";
import _copyText from "../copyText";
import queryKeys from "../hooks/queryKeys";
import useGetMspTenantSettingsByTenantID from "../hooks/useGetMspTenantSettingsByTenantID";
import { useUpdateMspTenantSettings } from "../hooks/useUpdateMspTenantSettings";
import { useUpdateMspTenantsSettings } from "../hooks/useUpdateMspTenantsSettings";
import MspTenantSettingsForm from "./MspTenantSettingsForm";
import MspTenantSettingsTable from "./MspTenantSettingsTable";
import MspTenantSettingsTableControls from "./MspTenantSettingsTableControls";
import { useFeatureText } from "@/context/FeatureTextProvider";

const MODAL_CANCEL = "MODAL_CANCEL";
const MODAL_CUSTOM_SETTING_FORM = "MODAL_CUSTOM_SETTING_FORM";
const MODAL_SETTING_FORM = "MODAL_SETTING_FORM";
const MODAL_UPDATE = "MODAL_UPDATE";
const MODAL_UPDATE_CHANGES = "MODAL_UPDATE_CHANGES";
const MODAL_UPDATE_CUSTOM = "MODAL_UPDATE_CUSTOM";

type MspTenant = MspTenantSettingsEntity & {
  name: string;
  isSelected: boolean;
};

type TenantSettings = {
  settingsID: string;
  config: {
    allowAwsRateRecs?: boolean;
    allowAwsUsageRecs?: boolean;
    allowAzureRateRecs?: boolean;
    allowAzureUsageRecs?: boolean;
    allowCloudConfiguration?: boolean;
    allowGcpRateRecs?: boolean;
    allowGcpUsageRecs?: boolean;
  };
};

type Interaction =
  | MspTenantSettingsForm.Interaction
  | MspTenantSettingsTable.Interaction
  | MspTenantSettingsTableControls.Interaction;

interface State {
  customSettings: TenantSettings | null;
  modalKey: string;
  isBatchModalOpen: boolean;
  searchText: string | null;
  selectedTenantSettings: TenantSettings[] | null;
  selectedSettingsIDs: string[] | null;
}

const initialState: State = {
  customSettings: null,
  modalKey: "",
  isBatchModalOpen: true,
  searchText: null,
  selectedTenantSettings: null,
  selectedSettingsIDs: null,
};

export function MspTenantSettingsContainer() {
  const activityTracker = useActivityTracker();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const gatekeeper = useGatekeeper();
  const location = useLocation();
  const [searchParamState, setSearchParamsState] = useQueryParams({
    tab: withDefault(StringParam, "settings"),
    custom: StringParam,
  });

  const { copyText } = useFeatureText(_copyText);

  //
  // State
  //

  const mspStore = useMspStore();

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  const debouncedSearchText = useDebounce(state.searchText);

  useMemo(() => {
    if (!searchParamState.custom) {
      return;
    }
    mergeState({ modalKey: MODAL_CUSTOM_SETTING_FORM });
  }, [searchParamState.custom]);

  //
  // Queries
  //
  const isMspAdminLocation = location.pathname.startsWith(paths._mspMgmt);
  const { data: customSettings, isLoading: isLoadingSettings } =
    useGetMspTenantSettingsByTenantID(
      mspStore.selectedParentTenantID as string,
      { enabled: !!mspStore.selectedParentTenantID }
    );

  const {
    data: _mspTenants = [],
    isLoading: _isLoadingMspTenants,
    refetch: _refetchMspTenants,
  } = useGetTenantsByParentTenantID(mspStore.selectedParentTenantID as string, {
    enabled:
      gatekeeper.canReadTenantsPartner &&
      !!mspStore.selectedParentTenantID &&
      isMspAdminLocation,
  });

  const getMspTenantsSetting: MspTenant[] = useMemo(() => {
    if (!customSettings) {
      return [];
    }

    return _mspTenants.map((tenant) => {
      return {
        ...tenant.mspSettings,
        name: tenant.name,
        isSelected: false,
      };
    }) as MspTenant[];
  }, [_mspTenants, customSettings]);

  const mspTenantsSettings = getTenantSettingsFromState(
    getMspTenantsSetting,
    state.selectedTenantSettings,
    state.selectedSettingsIDs
  );

  const filteredMspTenantSettings = useMemo(() => {
    return getFilteredMspTenantSettings({
      mspTenantsSettings: mspTenantsSettings ?? [],
      searchText: debouncedSearchText,
    });
  }, [debouncedSearchText, mspTenantsSettings]);

  //
  // Mutations
  //

  const {
    isPending: isUpdatingMspTenantSettings,
    mutate: updateMspTenantSettings,
  } = useUpdateMspTenantSettings({
    onError: () => {
      postAlert({
        message: copyText.errorUpdatingMspTenantSettingsMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      if (mspStore.selectedParentTenantID) {
        queryClient.invalidateQueries({
          queryKey: queryKeys.mspTenantSettings(
            mspStore.selectedParentTenantID
          ),
        });
      }

      _refetchMspTenants();
      mergeState({ modalKey: "", customSettings: null });

      postAlert({
        message: copyText.successUpdatingMspTenantSettingsMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  const {
    isPending: isUpdatingMspTenantsSettings,
    mutate: updateMspTenantsSettings,
  } = useUpdateMspTenantsSettings({
    onError: () => {
      postAlert({
        message: copyText.errorUpdatingMspTenantSettingsMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      if (mspStore.selectedParentTenantID) {
        queryClient.invalidateQueries({
          queryKey: queryKeys.mspTenantSettings(
            mspStore.selectedParentTenantID
          ),
        });
      }

      _refetchMspTenants();
      mergeState({
        modalKey: "",
        selectedTenantSettings: null,
        selectedSettingsIDs: null,
      });

      postAlert({
        message: copyText.successUpdatingMspTenantSettingsMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  //
  // Interaction Handlers
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case MspTenantSettingsTable.INTERACTION_EDIT_MULTIPLE_IDS_CLICKED: {
        mergeState({ selectedSettingsIDs: interaction.settingsIDs });
        return;
      }
      case MspTenantSettingsTable.INTERACTION_EDIT_SINGLE_IDS_CLICKED: {
        const settingsID = interaction.settingsID;
        setState((prevState) => {
          let updatedSelectedSettings: string[] | null = null;
          if (prevState.selectedSettingsIDs) {
            updatedSelectedSettings = !prevState.selectedSettingsIDs.includes(
              settingsID
            )
              ? [...prevState.selectedSettingsIDs, settingsID]
              : prevState.selectedSettingsIDs.filter((id) => id !== settingsID);
          } else {
            updatedSelectedSettings = [settingsID];
          }
          return {
            ...prevState,
            selectedSettingsIDs: updatedSelectedSettings,
          };
        });
        return;
      }
      case MspTenantSettingsTable.INTERACTION_SWITCH_ON_CHANGE: {
        const stateSettings = state.selectedTenantSettings ?? [];
        const stateKeyedByTenantID = keyBy(stateSettings, "settingsID");
        const prevStateTenantSettings =
          stateKeyedByTenantID[interaction.settingsID];

        const updatedState = stateSettings.reduce(
          (accum: TenantSettings[], settings) => {
            if (settings.settingsID === interaction.settingsID) {
              return [
                ...accum,
                {
                  ...settings,
                  config: { ...settings.config, ...interaction.config },
                },
              ];
            }

            return [...accum, settings];
          },
          []
        );

        let newTenantSettings = !prevStateTenantSettings
          ? [
              ...updatedState,
              {
                settingsID: interaction.settingsID,
                config: { ...interaction.config },
              },
            ]
          : updatedState;

        const tenantsKeyedByTenantID = keyBy(getMspTenantsSetting, "id");
        const prevTenantSettings =
          tenantsKeyedByTenantID[interaction.settingsID];

        if (prevTenantSettings) {
          const mspCustomSettings = {
            allowAwsRateRecs: prevTenantSettings.allowAwsRateRecs,
            allowAwsUsageRecs: prevTenantSettings.allowAwsUsageRecs,
            allowAzureRateRecs: prevTenantSettings.allowAzureRateRecs,
            allowAzureUsageRecs: prevTenantSettings.allowAzureUsageRecs,
            allowCloudConfiguration: prevTenantSettings.allowCloudConfiguration,
            allowGcpRateRecs: prevTenantSettings.allowGcpRateRecs,
            allowGcpUsageRecs: prevTenantSettings.allowGcpUsageRecs,
          };

          const filteredSettings = newTenantSettings.filter((settings) => {
            if (settings.settingsID !== prevTenantSettings.id) {
              return true;
            }
            let matchesCustom = true;
            Object.keys(settings.config).map((key) => {
              if (mspCustomSettings[key] !== settings.config[key]) {
                matchesCustom = false;
              }
            });
            return !matchesCustom;
          });
          newTenantSettings = filteredSettings;
        }

        mergeState({
          selectedTenantSettings: newTenantSettings,
        });

        return;
      }
      case MspTenantSettingsTableControls.INTERACTION_SEARCH_TEXT_UPDATED: {
        mergeState({ searchText: interaction.searchText });
        return;
      }
      case MspTenantSettingsTableControls.INTERACTION_CUSTOM_SETTINGS_BUTTON_CLICKED: {
        mergeState({ modalKey: MODAL_CUSTOM_SETTING_FORM });
        return;
      }
      case MspTenantSettingsTableControls.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        mergeState({ modalKey: MODAL_UPDATE_CHANGES });
        return;
      }
      case MspTenantSettingsTableControls.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({ modalKey: MODAL_CANCEL });
        return;
      }
      case MspTenantSettingsForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        if (interaction.settingsID) {
          mergeState({
            modalKey: MODAL_UPDATE_CUSTOM,
            customSettings: {
              settingsID: interaction.settingsID,
              config: interaction.config,
            },
          });
        } else {
          if (state.selectedSettingsIDs) {
            const newSelectedSettings = state.selectedSettingsIDs.map((id) => ({
              settingsID: id,
              config: interaction.config,
            }));
            mergeState({
              modalKey: MODAL_UPDATE,
              selectedTenantSettings: newSelectedSettings,
            });
          }
        }
        return;
      }
    }
  }

  //  To Do After Table
  function handleUpdateMspTenantsSettings() {
    if (!state.selectedTenantSettings) return;

    const params = state.selectedTenantSettings.reduce((accum, settings) => {
      if (!accum.paramsArray) {
        accum.paramsArray = [
          {
            settingsID: settings.settingsID,
            ...settings.config,
          },
        ];
      } else {
        accum.paramsArray = [
          ...accum.paramsArray,
          {
            settingsID: settings.settingsID,
            ...settings.config,
          },
        ];
      }
      return accum;
    }, {} as UpdateMspTenantsSettingsParams);
    activityTracker.captureAction(actions.CLICK_MSP_TENANT_SETTINGS_UPDATE);
    updateMspTenantsSettings(params);
  }

  function handleUpdateMspTenantSettings() {
    if (!state.customSettings) return;
    activityTracker.captureAction(actions.CLICK_MSP_CUSTOM_SETTINGS_UPDATE);
    updateMspTenantSettings({
      settingsID: state.customSettings.settingsID,
      ...state.customSettings.config,
    });
  }

  function renderModal(): JSX.Element | null {
    switch (state.modalKey) {
      case MODAL_UPDATE: {
        return (
          <ConfirmationModal
            title={copyText.updateMspTenantsSettingModalTitle}
            message={copyText.updateMspTenantsSettingModalMessage}
            onCancel={() => mergeState({ modalKey: "" })}
            onConfirm={() => handleUpdateMspTenantsSettings()}
          />
        );
      }
      case MODAL_UPDATE_CHANGES: {
        return (
          <ConfirmationModal
            title={copyText.updateMspTenantsSettingModalTitle}
            message={copyText.updateChangesMspTenantsSettingModalMessage}
            onCancel={() => mergeState({ modalKey: "" })}
            onConfirm={() => handleUpdateMspTenantsSettings()}
          />
        );
      }
      case MODAL_UPDATE_CUSTOM: {
        return (
          <ConfirmationModal
            title={copyText.updateMspTenantsSettingModalTitle}
            message={copyText.updateMspTenantSettingModalMessage}
            onCancel={() => mergeState({ modalKey: MODAL_SETTING_FORM })}
            onConfirm={() => handleUpdateMspTenantSettings()}
          />
        );
      }
      case MODAL_CANCEL: {
        return (
          <ConfirmationModal
            title={copyText.updateMspTenantsSettingModalTitle}
            message={copyText.cancelMspTenantsSettingModalMessage}
            onCancel={() => mergeState({ modalKey: "" })}
            onConfirm={() =>
              mergeState({ modalKey: "", selectedTenantSettings: null })
            }
          />
        );
      }
      case MODAL_CUSTOM_SETTING_FORM: {
        if (!customSettings) {
          return null;
        }
        return (
          <SideDrawer
            isOpen
            title={copyText.settingsSideDrawerTitleCustomSetting}
            titleCaption={copyText.settingsSideDrawerTitleCaptionCustomSetting}
            onClose={() => mergeState({ modalKey: "" })}
            content={() => (
              <MspTenantSettingsForm
                settings={customSettings}
                isProcessing={isUpdatingMspTenantsSettings}
                onCancel={() => {
                  mergeState({ modalKey: "" });
                  setSearchParamsState({ custom: null });
                }}
                onInteraction={handleInteraction}
              />
            )}
          />
        );
      }
      case MODAL_SETTING_FORM: {
        if (!state.selectedSettingsIDs) {
          return null;
        }
        return (
          <SideDrawer
            isOpen
            title={copyText.settingsSideDrawerTitleDefaultSetting}
            titleCaption={copyText.settingsSideDrawerTitleCaptionDefaultSetting}
            onClose={() => mergeState({ modalKey: "" })}
            content={() => (
              <MspTenantSettingsForm
                isProcessing={isUpdatingMspTenantsSettings}
                onCancel={() => mergeState({ modalKey: "" })}
                onInteraction={handleInteraction}
              />
            )}
          />
        );
      }

      default:
        return null;
    }
  }

  const handleSelectAllTenants = () => {
    const tenantIDs = filteredMspTenantSettings.map((tenant) => tenant.id);
    mergeState({ selectedSettingsIDs: tenantIDs });
  };

  //
  // Render
  //

  const isBatchModalOpen =
    !!state.selectedSettingsIDs?.length &&
    state.modalKey.length === 0 &&
    searchParamState.tab === "settings";

  if (!customSettings || isLoadingSettings) {
    return <EmptyPlaceholder loading />;
  }
  return (
    <>
      {renderModal()}
      <MspTenantSettingsTableControls
        canSubmit={!state.selectedTenantSettings?.length}
        isLoading={!customSettings || isLoadingSettings}
        searchTenant={state.searchText}
        onInteraction={handleInteraction}
      />
      <Box marginTop={theme.space_sm} width="100%">
        <MspTenantSettingsTable
          data={filteredMspTenantSettings}
          isLoading={
            _isLoadingMspTenants ||
            isUpdatingMspTenantsSettings ||
            isUpdatingMspTenantSettings
          }
          selectedSettingsIDs={state.selectedSettingsIDs}
          onInteraction={handleInteraction}
        />
        <BatchModal
          allSelected={
            state.selectedSettingsIDs?.length ===
            filteredMspTenantSettings.length
          }
          isOpen={isBatchModalOpen}
          title={copyText.mspTenantSettingBatchModalTitle.replace(
            "%amount%",
            `${state.selectedSettingsIDs?.length}`
          )}
          interactionLabel={copyText.mspTenantSettingBatchModalLabel}
          onSelectAll={handleSelectAllTenants}
          onClose={() => mergeState({ selectedSettingsIDs: null })}
          onInteraction={() => mergeState({ modalKey: MODAL_SETTING_FORM })}
        />
      </Box>
    </>
  );
}

function getTenantSettingsFromState(
  tenantSettings: MspTenant[],
  stateSettings: TenantSettings[] | null,
  selectedTenantIDs: string[] | null
): MspTenant[] {
  if (!tenantSettings) {
    return [];
  }

  if (!stateSettings && !selectedTenantIDs) {
    return tenantSettings;
  }

  const settingsKeyedBySettingsID = keyBy(stateSettings, "settingsID");

  return tenantSettings.reduce((accum: MspTenant[], setting) => {
    const stateSettings = settingsKeyedBySettingsID[setting.id];
    const isSelected = selectedTenantIDs?.includes(setting.id);
    if (!stateSettings)
      return [...accum, { ...setting, isSelected: isSelected ?? false }];

    return [
      ...accum,
      { ...setting, ...stateSettings.config, isSelected: isSelected ?? false },
    ];
  }, []);
}

type GetFilteredMspTenantsParams = {
  mspTenantsSettings: MspTenant[];
  searchText: string | null;
};

function getFilteredMspTenantSettings(params: GetFilteredMspTenantsParams) {
  const searchText = (params.searchText ?? "").toLowerCase().trim();

  return params.mspTenantsSettings.filter((mspTenantsSettings) => {
    if (!isSearchTextInSharedIntegrations(mspTenantsSettings, searchText)) {
      return false;
    }

    return true;
  });
}

function isSearchTextInSharedIntegrations(
  mspTenantsSettings: MspTenant,
  searchText: string
): boolean {
  if (searchText === "") return true;

  const values = [mspTenantsSettings.name].map((value) =>
    value === "" || value === null ? "null" : value
  );

  return values.some((value) =>
    value.toLowerCase().trim().includes(searchText)
  );
}

export default MspTenantSettingsContainer;
