import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import useGatekeeper from "@/hooks/useGatekeeper";
import Form, { FormField } from "@/ui-lib/components/Form";
import SelectCheckbox, { Option } from "@/ui-lib/components/SelectCheckbox";
import Switch from "@/ui-lib/components/Switch";
import TextInput from "@/ui-lib/components/TextInput";
import IconTimes from "@/ui-lib/icons/IconTimes";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import {
  faInfoCircle,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Operator } from "@ternary/api-lib/analytics/enums";
import {
  ScopedViewType,
  UserConfigStatus,
} from "@ternary/api-lib/constants/enums";
import { ScopedViewFilter, UserConfig } from "@ternary/api-lib/core/types";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { keyBy, uniq } from "lodash";
import React, { useState } from "react";
import { getFullName } from "../../../utils/UserUtils";
import copyText from "../copyText";

const LIST_AVAILABLE = "LIST_AVAILABLE";
const LIST_ENABLED = "LIST_ENABLED";
const LIST_ENFORCED = "LIST_ENFORCED";

enum IncludeType {
  FILTERS = "FILTERS",
  SCOPED_VIEWS = "SCOPED_VIEWS",
  SCOPED_VIEWS_AND_FILTERS = "SCOPED_VIEWS_AND_FILTERS",
}

type ScopedView = {
  createdBy: string;
  filters: ScopedViewFilter[];
  id: string;
  name: string;
  type: ScopedViewType;
  userConfigs: UserConfig[];
};

type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

export interface Props {
  filters: ScopedViewFilter[];
  isProcessing: boolean;
  scopedViews: ScopedView[];
  users: User[];
  onInteraction: (interaction: ScopedViewSaveForm.Interaction) => void;
}

export interface State {
  applyToNewUserStatusInput: {
    value: UserConfigStatus | null;
    isValid: boolean;
  };
  includeInput: { value: IncludeType; isValid: boolean };
  listKey: string;
  nameInput: { value: string; isValid: boolean };
  typeInput: { value: ScopedViewType; isValid: boolean };
  userConfigsInput: { value: UserConfig[]; isValid: boolean };
}

const initialState = {
  applyToNewUserStatusInput: { value: null, isValid: true },
  includeInput: { value: IncludeType.FILTERS, isValid: true },
  listKey: LIST_AVAILABLE,
  nameInput: { value: "", isValid: false },
  typeInput: { value: ScopedViewType.PRIVATE, isValid: true },
  userConfigsInput: { value: [], isValid: true },
};

export function ScopedViewSaveForm(props: Props): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const activityTracker = useActivityTracker();
  const theme = useTheme();

  const gatekeeper = useGatekeeper();

  //
  // State
  //

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  const usersKeyedByID = keyBy(props.users, "id");

  //
  // Interaction Handlers
  //

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target;

    const isValid = value.trim().length > 0;

    mergeState({ [`${name}Input`]: { value, isValid } });
  }

  function handleEnabledByDefaultChange(checked: boolean): void {
    setState((currentState) => ({
      ...currentState,
      userConfigsInput: {
        value:
          currentState.userConfigsInput.value.length === 0
            ? [
                {
                  userID: authenticatedUser.id,
                  status: UserConfigStatus.ENABLED,
                },
              ]
            : currentState.userConfigsInput.value.map((userConfig) =>
                userConfig.userID === authenticatedUser.id
                  ? {
                      ...userConfig,
                      status: checked
                        ? UserConfigStatus.ENABLED
                        : UserConfigStatus.DISABLED,
                    }
                  : userConfig
              ),
        isValid: true,
      },
    }));
  }

  function handleClose(): void {
    mergeState({
      ...initialState,
      userConfigsInput: {
        value: [
          { userID: authenticatedUser.id, status: UserConfigStatus.DISABLED },
        ],
        isValid: true,
      },
    });
    props.onInteraction({
      type: ScopedViewSaveForm.INTERACTION_CANCEL_BUTTON_CLICKED,
    });
  }

  function handleChangeAvailableUserID(userIDs: string[]): void {
    setState((prevState) => ({
      ...prevState,
      listKey: LIST_AVAILABLE,
      userConfigsInput: {
        value: [
          ...prevState.userConfigsInput.value.filter(
            (config) => config.status !== UserConfigStatus.DISABLED
          ),
          ...userIDs.map((userID) => ({
            userID,
            status: UserConfigStatus.DISABLED,
          })),
        ].sort((a, b) =>
          getFullName(usersKeyedByID[a.userID]).toLowerCase() >
          getFullName(usersKeyedByID[b.userID]).toLowerCase()
            ? 1
            : -1
        ),
        isValid: true,
      },
    }));
  }

  function handleChangeEnabledByDefaultUserID(userIDs: string[]): void {
    setState((prevState) => ({
      ...prevState,
      listKey: LIST_ENABLED,
      userConfigsInput: {
        value: [
          ...prevState.userConfigsInput.value.filter(
            (config) => config.status !== UserConfigStatus.ENABLED
          ),
          ...userIDs.map((userID) => ({
            userID,
            status: UserConfigStatus.ENABLED,
          })),
        ].sort((a, b) =>
          getFullName(usersKeyedByID[a.userID]).toLowerCase() >
          getFullName(usersKeyedByID[b.userID]).toLowerCase()
            ? 1
            : -1
        ),
        isValid: true,
      },
    }));
  }

  function handleChangeEnforcedUserID(userIDs: string[]): void {
    setState((prevState) => ({
      ...prevState,
      listKey: LIST_ENFORCED,
      userConfigsInput: {
        value: [
          ...prevState.userConfigsInput.value.filter(
            (config) => config.status !== UserConfigStatus.ENFORCED
          ),
          ...userIDs
            .map((userID) => ({
              userID,
              status: UserConfigStatus.ENFORCED,
            }))
            .sort((a, b) =>
              getFullName(usersKeyedByID[a.userID]).toLowerCase() >
              getFullName(usersKeyedByID[b.userID]).toLowerCase()
                ? 1
                : -1
            ),
        ],
        isValid: true,
      },
    }));
  }

  function handleDeleteUserID(userID: string): void {
    setState((currentState) => {
      const newIDs = currentState.userConfigsInput.value.filter(
        (config) => config.userID !== userID
      );

      return {
        ...currentState,
        userConfigsInput: {
          value: newIDs,
          isValid: true,
        },
      };
    });
  }

  function handleToggleApplyToNewUsers(listKey: string): void {
    switch (listKey) {
      case LIST_AVAILABLE: {
        const applyToNewUserStatus =
          state.applyToNewUserStatusInput.value === UserConfigStatus.DISABLED
            ? null
            : UserConfigStatus.DISABLED;

        mergeState({
          applyToNewUserStatusInput: {
            value: applyToNewUserStatus,
            isValid: true,
          },
          listKey,
        });
        break;
      }
      case LIST_ENABLED: {
        const applyToNewUserStatus =
          state.applyToNewUserStatusInput.value === UserConfigStatus.ENABLED
            ? null
            : UserConfigStatus.ENABLED;

        mergeState({
          applyToNewUserStatusInput: {
            value: applyToNewUserStatus,
            isValid: true,
          },
          listKey,
        });
        break;
      }
      case LIST_ENFORCED: {
        const applyToNewUserStatus =
          state.applyToNewUserStatusInput.value === UserConfigStatus.ENFORCED
            ? null
            : UserConfigStatus.ENFORCED;

        mergeState({
          applyToNewUserStatusInput: {
            value: applyToNewUserStatus,
            isValid: true,
          },
          listKey,
        });
        break;
      }
    }
  }

  function handleChangeAllUserConfigs(): void {
    switch (state.listKey) {
      case LIST_AVAILABLE: {
        const userConfigs =
          availableLength === props.users.length
            ? []
            : props.users.map((user) => ({
                userID: user.id,
                status: UserConfigStatus.DISABLED,
              }));

        mergeState({
          userConfigsInput: {
            value: userConfigs,
            isValid: true,
          },
        });
        break;
      }
      case LIST_ENABLED: {
        const userConfigs =
          enabledLength === props.users.length
            ? []
            : props.users.map((user) => ({
                userID: user.id,
                status: UserConfigStatus.ENABLED,
              }));

        mergeState({
          userConfigsInput: {
            value: userConfigs,
            isValid: true,
          },
        });
        break;
      }
      case LIST_ENFORCED: {
        const userConfigs =
          enforcedLength === props.users.length - 1
            ? []
            : props.users.reduce((accum: UserConfig[], user) => {
                if (
                  user.id === authenticatedUser.id &&
                  state.typeInput.value === ScopedViewType.ADMIN
                )
                  return accum;

                return [
                  ...accum,
                  {
                    userID: user.id,
                    status: UserConfigStatus.ENFORCED,
                  },
                ];
              }, []);

        mergeState({
          userConfigsInput: {
            value: userConfigs,
            isValid: true,
          },
        });
        break;
      }
    }
  }

  //
  // Submission Handlers
  //

  function handleSubmit(event: React.MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();
    props.onInteraction({
      type: ScopedViewSaveForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      applyToNewUserStatus: state.applyToNewUserStatusInput.value,
      filters: submissionFilters,
      includeType: state.includeInput.value,
      name: state.nameInput.value.trim(),
      scopedViewType: state.typeInput.value,
      userConfigs: state.userConfigsInput.value,
    });
    mergeState(initialState);
  }

  //
  // Render
  //

  function renderUserIDs(listKey: string): (JSX.Element | undefined)[] {
    let userConfigs: UserConfig[] = [];

    switch (listKey) {
      case LIST_AVAILABLE: {
        userConfigs = state.userConfigsInput.value.filter(
          (userConfig) => userConfig.status === UserConfigStatus.DISABLED
        );
        break;
      }
      case LIST_ENABLED: {
        userConfigs = state.userConfigsInput.value.filter(
          (userConfig) => userConfig.status === UserConfigStatus.ENABLED
        );
        break;
      }
      case LIST_ENFORCED: {
        userConfigs = state.userConfigsInput.value.filter(
          (userConfig) => userConfig.status === UserConfigStatus.ENFORCED
        );
        break;
      }
    }

    if (userConfigs.length === 0) return [];

    return userConfigs.map((userConfig) => {
      const user = usersKeyedByID[userConfig.userID];

      if (!user) {
        activityTracker.captureMessage(
          `Data integrity issue detected for userID: ${userConfig.userID}`
        );
        return;
      }

      return (
        <Box
          key={userConfig.userID}
          display="table-row"
          marginBottom={theme.space_xs}
          overflow="scroll"
        >
          <Box display="table-cell" paddingRight={theme.space_lg}>
            <Text fontSize={theme.fontSize_base}>
              {getFullName(user).length > 0 ? getFullName(user) : user.email}
            </Text>
          </Box>
          <Flex display="table-cell" justifyContent="space-between" width={250}>
            <Box paddingRight={theme.space_sm}>
              <Text fontSize={theme.fontSize_base}>{user.email}</Text>
            </Box>
            <Button
              iconStart={<IconTimes />}
              size="tiny"
              type="button"
              onClick={() => handleDeleteUserID(userConfig.userID)}
            />
          </Flex>
        </Box>
      );
    });
  }

  const availableUsersOptions = props.users.reduce(
    (accum: Option[], user) =>
      state.userConfigsInput.value.some(
        (userConfig) =>
          user.id === userConfig.userID &&
          userConfig.status !== UserConfigStatus.DISABLED
      )
        ? accum
        : [
            ...accum,
            {
              label: `${getFullName(user)} (${user.email})`,
              value: user.id,
            },
          ],
    []
  );

  const enabledUsersOptions = props.users.reduce(
    (accum: Option[], user) =>
      state.userConfigsInput.value.some(
        (userConfig) =>
          user.id === userConfig.userID &&
          userConfig.status !== UserConfigStatus.ENABLED
      )
        ? accum
        : [
            ...accum,
            {
              label: `${getFullName(user)} (${user.email})`,
              value: user.id,
            },
          ],
    []
  );

  const enforcedUsersOptions = props.users.reduce(
    (accum: Option[], user) =>
      state.userConfigsInput.value.some(
        (userConfig) =>
          user.id === userConfig.userID &&
          userConfig.status !== UserConfigStatus.ENFORCED
      ) || user.id === authenticatedUser.id
        ? accum
        : [
            ...accum,
            {
              label: `${getFullName(user)} (${user.email})`,
              value: user.id,
            },
          ],
    []
  );

  function getSubmissionFilters(): ScopedViewFilter[] {
    switch (state.includeInput.value) {
      case IncludeType.FILTERS:
        return props.filters;
      case IncludeType.SCOPED_VIEWS:
        return props.scopedViews.reduce(
          (previousValue: ScopedViewFilter[], currentValue: ScopedView) => {
            return [...previousValue, ...currentValue.filters];
          },
          []
        );
      case IncludeType.SCOPED_VIEWS_AND_FILTERS:
        return props.scopedViews
          .reduce(
            (previousValue: ScopedViewFilter[], currentValue: ScopedView) => {
              return [...previousValue, ...currentValue.filters];
            },
            []
          )
          .concat(props.filters);
      default:
        return [];
    }
  }

  function renderChangeAllUsersButton() {
    let text = copyText.addAllUsersLabel;
    switch (state.listKey) {
      case LIST_AVAILABLE: {
        if (availableLength === props.users.length) {
          text = copyText.removeAllUsersLabel;
        } else {
          text = copyText.addAllUsersLabel;
        }
        break;
      }
      case LIST_ENABLED: {
        if (enabledLength === props.users.length) {
          text = copyText.removeAllUsersLabel;
        } else {
          text = copyText.addAllUsersLabel;
        }
        break;
      }
      case LIST_ENFORCED: {
        if (enforcedLength === props.users.length - 1) {
          text = copyText.removeAllUsersLabel;
        } else {
          text = copyText.addAllUsersLabel;
        }
        break;
      }
    }

    return (
      <Button
        iconStart={
          <Icon icon={text === copyText.addAllUsersLabel ? faPlus : faMinus} />
        }
        size="small"
        type="button"
        width={160}
        onClick={handleChangeAllUserConfigs}
      >
        {text}
      </Button>
    );
  }

  const availableLength = state.userConfigsInput.value.filter(
    (config) => config.status === UserConfigStatus.DISABLED
  ).length;
  const enabledLength = state.userConfigsInput.value.filter(
    (config) => config.status === UserConfigStatus.ENABLED
  ).length;
  const enforcedLength = state.userConfigsInput.value.filter(
    (config) => config.status === UserConfigStatus.ENFORCED
  ).length;

  const filterList = getStringifiedFilters(props.filters);
  const scopedViewList = props.scopedViews.map((scopedView) => scopedView.name);
  const combinedList = filterList.concat(scopedViewList);
  const submissionFilters = dedupeScopedViewFilters(getSubmissionFilters());

  //
  // Validations
  //

  const canSubmit = Object.values(state).every(
    (input) => input.isValid || input.isValid === undefined
  );

  const isValidScopedView =
    (state.includeInput.value === IncludeType.FILTERS &&
      filterList.length > 0) ||
    (state.includeInput.value === IncludeType.SCOPED_VIEWS &&
      scopedViewList.length > 0) ||
    (state.includeInput.value === IncludeType.SCOPED_VIEWS_AND_FILTERS &&
      submissionFilters.length > 0);

  const isValidFilters =
    state.includeInput.value !== IncludeType.SCOPED_VIEWS
      ? !props.filters.some((filter) =>
          Object.values(filter).some((value) => value && value.length === 0)
        )
      : true;

  return (
    <Form>
      <Flex justifyContent="space-between">
        {/* Name */}
        <Box minWidth="400px">
          <FormField
            name="name"
            input={TextInput}
            label={copyText.nameInputLabel}
            value={state.nameInput.value}
            onChange={handleChange}
          />
        </Box>
        {/* On by default */}
        {state.typeInput.value === ScopedViewType.ADMIN ? null : (
          <FormField label={copyText.enabledByDefaultLabel}>
            <Flex justifyContent="flex-end">
              <Switch
                name="enabledByDefault"
                checked={state.userConfigsInput.value.some(
                  (userConfig) =>
                    userConfig.userID === authenticatedUser.id &&
                    userConfig.status === UserConfigStatus.ENABLED
                )}
                onChange={handleEnabledByDefaultChange}
              />
            </Flex>
          </FormField>
        )}
      </Flex>
      {/* Include */}
      <FormField label={copyText.includeInputLabel}>
        <Flex direction="column">
          <Flex direction="column">
            <Flex>
              <input
                name="include"
                checked={state.includeInput.value === IncludeType.FILTERS}
                type="radio"
                value={IncludeType.FILTERS}
                onChange={handleChange}
              />
              <Text bold marginLeft={theme.space_xxs}>
                {`${copyText.includeFiltersOnlyLabel} (${filterList.length})`}
              </Text>
            </Flex>
            <ul style={{ color: theme.text_color }}>
              {filterList.map((filter, index) => {
                if (index < 4) {
                  return (
                    <li key={filter + index + "f"}>
                      <Text>{filter}</Text>
                    </li>
                  );
                }
              })}
              {filterList.length > 4 ? (
                <li>
                  <Text>
                    {`${filterList.length - 4} ${copyText.moreListItemsLabel}`}
                  </Text>
                </li>
              ) : null}
            </ul>
          </Flex>
          <Flex direction="column">
            <Flex>
              <input
                name="include"
                checked={state.includeInput.value === IncludeType.SCOPED_VIEWS}
                type="radio"
                value={IncludeType.SCOPED_VIEWS}
                onChange={handleChange}
              />
              <Text bold marginLeft={theme.space_xxs}>
                {`${copyText.includeScopedViewsOnlyLabel} (${scopedViewList.length})`}
              </Text>
            </Flex>
            <ul style={{ color: theme.text_color }}>
              {scopedViewList.map((scopedView, index) => {
                if (index < 4) {
                  return (
                    <li key={scopedView + index + "s"}>
                      <Text>{scopedView}</Text>
                    </li>
                  );
                }
              })}
              {scopedViewList.length > 4 ? (
                <li>
                  <Text>
                    {`${scopedViewList.length - 4} ${
                      copyText.moreListItemsLabel
                    }`}
                  </Text>
                </li>
              ) : null}
            </ul>
          </Flex>
          <Flex direction="column">
            <Flex>
              <input
                name="include"
                checked={
                  state.includeInput.value ===
                  IncludeType.SCOPED_VIEWS_AND_FILTERS
                }
                type="radio"
                value={IncludeType.SCOPED_VIEWS_AND_FILTERS}
                onChange={handleChange}
              />
              <Text bold marginLeft={theme.space_xxs}>
                {`${copyText.includeScopedViewsAndFiltersLabel}  (${combinedList.length})`}
              </Text>
            </Flex>
            <Flex>
              <ul style={{ color: theme.text_color }}>
                {scopedViewList.map((scopedView, index) => {
                  if (index < 4) {
                    return (
                      <li key={scopedView + index + "c"}>
                        <Text>{scopedView}</Text>
                      </li>
                    );
                  }
                })}
                {scopedViewList.length > 4 ? (
                  <li>
                    <Text>
                      {`${scopedViewList.length - 4} ${
                        copyText.moreListItemsLabel
                      }`}
                    </Text>
                  </li>
                ) : null}
              </ul>
              <ul>
                {filterList.map((filter, index) => {
                  if (index < 4) {
                    return (
                      <li key={filter + index + "c"}>
                        <Text>{filter}</Text>
                      </li>
                    );
                  }
                })}
                {filterList.length > 4 ? (
                  <li>
                    <Text>
                      {`${filterList.length - 4} ${
                        copyText.moreListItemsLabel
                      }`}
                    </Text>
                  </li>
                ) : null}
              </ul>
            </Flex>
          </Flex>
        </Flex>
      </FormField>
      {/* Users */}
      {state.typeInput.value === ScopedViewType.ADMIN && (
        <Box>
          <Text appearance="h4" marginBottom={theme.space_md}>
            {copyText.usersHeader}
          </Text>
          <Text appearance="h5">
            {`${copyText.availableForHeader} (${availableLength})`}
          </Text>
          <Flex alignItems="center" marginBottom={theme.space_md}>
            <Box width={360}>
              <SelectCheckbox
                hideValues
                options={availableUsersOptions}
                placeholder={copyText.selectUsersPlaceholder}
                selectedValues={state.userConfigsInput.value
                  .filter(
                    (userConfig) =>
                      userConfig.status === UserConfigStatus.DISABLED
                  )
                  .map((userConfig) => userConfig.userID)}
                onChange={handleChangeAvailableUserID}
              />
            </Box>
            <Flex alignItems="center" marginLeft={theme.space_md}>
              <Switch
                checked={
                  state.applyToNewUserStatusInput.value ===
                  UserConfigStatus.DISABLED
                }
                onChange={() => handleToggleApplyToNewUsers(LIST_AVAILABLE)}
              />
              <Text marginLeft={theme.space_sm} marginVertical={0}>
                {copyText.switchApplyToNewUsersLabel}
              </Text>
            </Flex>
          </Flex>
          <Text appearance="h5">
            {`${copyText.enforcedForHeader} (${enforcedLength})`}
          </Text>
          <Flex alignItems="center" marginBottom={theme.space_md}>
            <Box width={360}>
              <SelectCheckbox
                hideValues
                options={enforcedUsersOptions}
                placeholder={copyText.selectUsersPlaceholder}
                selectedValues={state.userConfigsInput.value
                  .filter(
                    (userConfig) =>
                      userConfig.status === UserConfigStatus.ENFORCED
                  )
                  .map((userConfig) => userConfig.userID)}
                onChange={handleChangeEnforcedUserID}
              />
            </Box>
            <Flex alignItems="center" marginLeft={theme.space_md}>
              <Switch
                checked={
                  state.applyToNewUserStatusInput.value ===
                  UserConfigStatus.ENFORCED
                }
                onChange={() => handleToggleApplyToNewUsers(LIST_ENFORCED)}
              />
              <Text marginHorizontal={theme.space_sm} marginVertical={0}>
                {copyText.switchApplyToNewUsersLabel}
              </Text>
            </Flex>
            <Tooltip
              content={copyText.enforcedForTooltip}
              icon={faInfoCircle}
            />
          </Flex>
          <Text appearance="h5">
            {`${copyText.enabledByDefaultForHeader} (${enabledLength})`}
          </Text>
          <Flex marginBottom={theme.space_md}>
            <Box width={360}>
              <SelectCheckbox
                hideValues
                options={enabledUsersOptions}
                placeholder={copyText.selectUsersPlaceholder}
                selectedValues={state.userConfigsInput.value
                  .filter(
                    (userConfig) =>
                      userConfig.status === UserConfigStatus.ENABLED
                  )
                  .map((userConfig) => userConfig.userID)}
                onChange={handleChangeEnabledByDefaultUserID}
              />
            </Box>
            <Flex alignItems="center" marginLeft={theme.space_md}>
              <Switch
                checked={
                  state.applyToNewUserStatusInput.value ===
                  UserConfigStatus.ENABLED
                }
                onChange={() => handleToggleApplyToNewUsers(LIST_ENABLED)}
              />
              <Text marginLeft={theme.space_sm} marginVertical={0}>
                {copyText.switchApplyToNewUsersLabel}
              </Text>
            </Flex>
          </Flex>
          <Box display="table" width="100%" marginBottom={theme.space_xxl}>
            <Box display="table-row">
              <Box display="table-header-group">
                <Flex width={410}>
                  <Text
                    bold
                    color={
                      state.listKey === LIST_AVAILABLE
                        ? theme.primary_color_text
                        : theme.text_color_secondary
                    }
                    cursor="pointer"
                    onClick={() => mergeState({ listKey: LIST_AVAILABLE })}
                  >
                    {`${copyText.availableForButtonLabel} (${availableLength}) /`}
                  </Text>
                  <Text
                    bold
                    color={
                      state.listKey === LIST_ENFORCED
                        ? theme.primary_color_text
                        : theme.text_color_secondary
                    }
                    cursor="pointer"
                    marginLeft={theme.space_xxs}
                    onClick={() => mergeState({ listKey: LIST_ENFORCED })}
                  >
                    {`${copyText.enforcedForButtonLabel} (${enforcedLength}) /`}
                  </Text>
                  <Text
                    bold
                    color={
                      state.listKey === LIST_ENABLED
                        ? theme.primary_color_text
                        : theme.text_color_secondary
                    }
                    cursor="pointer"
                    marginLeft={theme.space_xxs}
                    onClick={() => mergeState({ listKey: LIST_ENABLED })}
                  >
                    {`${copyText.enabledByDefaultForButtonLabel} (${enabledLength})`}
                  </Text>
                </Flex>
              </Box>
              <Box display="table-cell">
                <Flex width="100%" justifyContent={"flex-end"}>
                  {renderChangeAllUsersButton()}
                </Flex>
              </Box>
            </Box>
            <Box display="table-row">
              <Box display="table-cell" paddingRight={theme.space_lg}>
                <Text bold>{copyText.tableHeaderName}</Text>
              </Box>
              <Box display="table-cell">
                <Text bold>{copyText.tableHeaderEmail}</Text>
              </Box>
            </Box>
            {renderUserIDs(state.listKey)}
          </Box>
        </Box>
      )}
      {/* Type */}
      {gatekeeper.canCreateAdminScopedView && (
        <FormField label={copyText.typeInputLabel}>
          <Flex>
            <Flex marginRight={theme.space_xxl}>
              <Flex>
                <input
                  name="type"
                  checked={state.typeInput.value === ScopedViewType.PRIVATE}
                  type="radio"
                  value={ScopedViewType.PRIVATE}
                  onChange={(val) => {
                    mergeState({
                      userConfigsInput: {
                        value: [
                          {
                            userID: authenticatedUser.id,
                            status: UserConfigStatus.DISABLED,
                          },
                        ],
                        isValid: true,
                      },
                    });
                    handleChange(val);
                  }}
                />
                <Text marginLeft={theme.space_xxs}>
                  {copyText.typePersonalLabel}
                </Text>
              </Flex>
            </Flex>
            <Flex>
              <Flex>
                <input
                  name="type"
                  checked={state.typeInput.value === ScopedViewType.ADMIN}
                  type="radio"
                  value={ScopedViewType.ADMIN}
                  onChange={(val) => {
                    mergeState({
                      userConfigsInput: {
                        value: initialState.userConfigsInput.value,
                        isValid: true,
                      },
                    });
                    handleChange(val);
                  }}
                />
                <Text marginLeft={theme.space_xxs}>
                  {copyText.typeOrgLabel}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </FormField>
      )}
      <Flex justifyContent="end">
        <Button
          marginRight={theme.space_xs}
          secondary
          type="reset"
          width={100}
          onClick={handleClose}
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={
            !canSubmit ||
            !isValidScopedView ||
            !isValidFilters ||
            props.isProcessing
          }
          primary
          width={100}
          onClick={handleSubmit}
        >
          {copyText.submitButtonLabel}
        </Button>
      </Flex>
    </Form>
  );
}

function getStringifiedFilters(filters: ScopedViewFilter[]) {
  return filters.map((filter) => {
    return `${filter.name} ${getOperatorText(filter.operator)} ${
      filter.values?.join(", ") || ""
    }`;
  });
}

function getOperatorText(operator?: Operator): string {
  switch (operator) {
    case Operator.CONTAINS:
      return "∋";
    case Operator.EQUALS:
      return "=";
    case Operator.NOT_CONTAINS:
      return "∌";
    case Operator.NOT_EQUALS:
      return "≠";
    case Operator.NOT_SET:
      return copyText.operatorOptionNotSetLabel;
    case Operator.SET:
      return copyText.operatorOptionSetLabel;
    default:
      return "=";
  }
}

function dedupeScopedViewFilters(scopeViewFilters: ScopedViewFilter[]) {
  return scopeViewFilters.reduce(
    (accum: ScopedViewFilter[], filter: ScopedViewFilter) => {
      const accumIndex = accum.findIndex(
        (accumFilter) =>
          accumFilter.name === filter.name &&
          accumFilter.dataSource === filter.dataSource
      );
      if (accumIndex >= 0) {
        const combindedValues = [
          ...(accum[accumIndex]?.values ?? []),
          ...(filter?.values ?? []),
        ];
        const removedDupValues = uniq(combindedValues);
        accum[accumIndex] = {
          ...accum[accumIndex],
          values: removedDupValues,
        };
        return accum;
      }
      return [...accum, filter];
    },
    []
  );
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ScopedViewSaveForm {
  export const INTERACTION_CANCEL_BUTTON_CLICKED = `ScopedViewSaveForm.INTERACTION_CANCEL_BUTTON_CLICKED`;
  export const INTERACTION_SUBMIT_BUTTON_CLICKED = `ScopedViewSaveForm.INTERACTION_SUBMIT_BUTTON_CLICKED`;

  interface InteractionCancelButtonClicked {
    type: typeof INTERACTION_CANCEL_BUTTON_CLICKED;
  }

  // NOTE: We need includeType to determine what state to set after
  // successful Scoped View creation.
  interface InteractionSubmitButtonClickedCreate {
    type: typeof INTERACTION_SUBMIT_BUTTON_CLICKED;
    applyToNewUserStatus: UserConfigStatus | null;
    filters: ScopedViewFilter[];
    includeType: IncludeType;
    name: string;
    scopedViewType: ScopedViewType;
    userConfigs: UserConfig[];
  }

  export type Interaction =
    | InteractionCancelButtonClicked
    | InteractionSubmitButtonClickedCreate;
}

export default ScopedViewSaveForm;
