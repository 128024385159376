import { DurationType } from "@ternary/api-lib/analytics/enums";
import React, {
  ReactElement,
  createContext,
  useContext,
  useState,
} from "react";

interface State {
  dateRange: Date[] | null;
  durationType: DurationType | null;
}

const initialState: State = {
  dateRange: null,
  durationType: null,
};

interface Store {
  dateRange: Date[] | null;
  durationType: DurationType | null;
  reset: () => void;
  set: (state: Partial<State>) => void;
}

const context = createContext<Store | undefined>(undefined);

export default function GlobalDateStoreProvider({
  children,
}: {
  children: ReactElement | ReactElement[];
}): JSX.Element {
  const [state, setState] = useState<State>(initialState);

  const reset = () => setState(initialState);

  const set = (state: Partial<State>) =>
    setState((currentState) => ({ ...currentState, ...state }));

  return (
    <context.Provider value={{ ...state, reset, set }}>
      {children}
    </context.Provider>
  );
}

export function useGlobalDateStore(): Store {
  const store = useContext(context);

  if (!store) {
    throw new Error(
      "globalDateConsumer must be wrapped by GlobalDateStateProvider"
    );
  }

  return store;
}
