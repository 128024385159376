import Meter from "@/components/Meter";
import { useTheme } from "@emotion/react";
import {
  formatCurrency,
  formatCurrencyRounded,
  formatNumber,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import { GcpCommitmentDurationType } from "@ternary/api-lib/constants/enums";
import { CudRecommendationEntity } from "@ternary/api-lib/core/types";
import Divider from "@ternary/api-lib/ui-lib/components/Divider";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import copyText from "../../copyText";

interface Props {
  amountSaved: number;
  commitmentLength: GcpCommitmentDurationType;
  isLoadingInventoryData: boolean;
  isLoadingRecommendations: boolean;
  isLoadingUtilizationData: boolean;
  recommendationData: CudRecommendationEntity[];
  showTooltip: boolean;
  totalCost: number;
  utilizationPercent: number;
}

export default function CudSpendManagerMeters(props: Props): JSX.Element {
  const theme = useTheme();

  const estimatedMonthlySavings = props.recommendationData.reduce(
    (a, b) => a + b.estimatedMonthlySavings,
    0
  );

  const recommendationDefaultMultiplyer =
    props.commitmentLength === GcpCommitmentDurationType.TWELVE_MONTH ? 12 : 36;

  const estimatedTotalSavings =
    estimatedMonthlySavings * recommendationDefaultMultiplyer;

  return (
    <Flex>
      {/* Existing Commitments */}
      <Flex flex={1} justifyContent="space-between" direction="column">
        <Flex>
          <Meter
            hideFooter
            header={copyText.cudVisSpendMetersMonthyCostLabel}
            loading={props.isLoadingInventoryData}
            value={formatCurrencyRounded({
              number: props.totalCost,
            })}
            width="31%"
          />
          <Divider direction="vertical" />
          <Meter
            hideFooter
            header={copyText.cudVisSpendMetersUtilizationLabel}
            loading={props.isLoadingUtilizationData}
            value={
              <Flex alignItems="baseline" direction="column">
                <Flex alignItems="flex-end" marginBottom={theme.space_md}>
                  <Text bold fontSize={theme.h2_fontSize}>
                    {`${formatNumber(props.utilizationPercent, 2)} %`}
                  </Text>
                </Flex>
              </Flex>
            }
            width="31%"
          />
          <Divider direction="vertical" />
          <Meter
            hideFooter
            header={copyText.cudVisSpendMetersMonthlySavingsLabel}
            loading={props.isLoadingInventoryData}
            value={formatCurrencyRounded({
              number: props.amountSaved,
            })}
          />
        </Flex>
        <Flex justifyContent="center">
          <Text bold fontSize={theme.fontSize_base}>
            {copyText.cudVisSpendMetersExistingCommitmentsHeader}
          </Text>
        </Flex>
      </Flex>
      <Divider border={theme.space_xxs} minHeight="100%" direction="vertical" />
      {/* Recommendations */}
      <Flex direction="column" flex={1}>
        <Flex height="100%">
          <Meter
            hideFooter
            header={copyText.cudVisSpendMetersTotalRecsLabel}
            loading={props.isLoadingRecommendations}
            value={`${props.recommendationData.length}`}
            width="31%"
          />
          <Divider direction="vertical" />
          <Meter
            hideFooter
            header={copyText.cudVisSpendMetersRecsMonthlySavingsLabel}
            loading={props.isLoadingRecommendations}
            value={formatCurrency({
              number: estimatedMonthlySavings,
            })}
            width="31%"
          />
          <Divider direction="vertical" />
          <Meter
            hideFooter
            header={copyText.cudVisSpendMetersRecsTotalSavingsLabel}
            loading={props.isLoadingRecommendations}
            value={formatCurrency({
              number: estimatedTotalSavings,
            })}
          />
        </Flex>
        <Flex justifyContent="center">
          <Text bold fontSize={theme.fontSize_base}>
            {copyText.cudVisSpendMetersRecommendationsHeader}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
