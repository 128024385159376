import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faChartBar, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { SimpleChartTooltip } from "@ternary/api-lib/analytics/ui/SimpleChartTooltip";
import { formatTimestamp } from "@ternary/api-lib/analytics/utils/ChartFormatUtils";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import { formatCurrencyRounded } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { Month } from "date-fns";
import React, { useMemo } from "react";
import {
  Bar,
  Tooltip as ChartTooltip,
  ComposedChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { BudgetSpendEntity } from "../../../api/analytics/types";
import timing from "../../../constants/timing";
import { DateHelper } from "../../../lib/dates";
import { getMonthNameForMonthNumber } from "../../../utils/dates";
import copyText from "../copyText";
import { Budget } from "../types";
import { createGetBudgetAmountAtMonth } from "../utils";

const StyledDiv = styled.div`
  width: 100%;
  height: 90%;
`;

interface Props {
  budget: Budget | null;
  dailySpend: BudgetSpendEntity[];
  height: number;
  loading: boolean;
}

type ChartDatum = {
  budgetAmount: number | null;
  cost: number;
  costOverage: number;
  monthNumber: number;
};

function BudgetsHistoricalMonthlyGraph(props: Props): JSX.Element {
  const theme = useTheme();

  const data = useMemo((): ChartDatum[] => {
    const now = new DateHelper();
    const currentMonth = formatDate(now.date, "yyyy-MM");
    const getBudgetAmountAtMonth = createGetBudgetAmountAtMonth(props.budget);
    const spendKeyedByMonthNumber = props.dailySpend.reduce(
      (accum, spend) => {
        const monthNumber = formatTimestamp(spend.timestamp, "yyyy-MM");
        if (monthNumber === currentMonth) return accum;

        const applicableSpend = spend[props.budget?.measure ?? ""];

        if (accum[monthNumber]) {
          accum[monthNumber] += applicableSpend;
        } else {
          accum[monthNumber] = applicableSpend;
        }
        return accum;
      },
      {} as Record<string, number>
    );

    return Object.keys(spendKeyedByMonthNumber)
      .sort((a, b) => {
        const monthA = new Date(a);
        const monthB = new Date(b);

        if (monthA < monthB) return -1;
        if (monthB < monthA) return 1;
        return 0;
      })
      .map((monthNumber): ChartDatum => {
        const budgetAmount = getBudgetAmountAtMonth(monthNumber);

        if (budgetAmount === null) {
          return {
            budgetAmount,
            cost: spendKeyedByMonthNumber[monthNumber],
            costOverage: 0,
            monthNumber: Number(monthNumber.slice(-2)),
          };
        }

        return {
          budgetAmount,
          cost:
            spendKeyedByMonthNumber[monthNumber] > budgetAmount
              ? budgetAmount
              : spendKeyedByMonthNumber[monthNumber],
          costOverage:
            spendKeyedByMonthNumber[monthNumber] > budgetAmount
              ? spendKeyedByMonthNumber[monthNumber] - budgetAmount
              : 0,
          monthNumber: Number(monthNumber.slice(-2)),
        };
      });
  }, [props.dailySpend, props.budget]);

  const costKeyedByMonthNumber = useMemo(
    () =>
      data.reduce((accum, datum) => {
        return {
          ...accum,
          [datum.monthNumber]: datum.cost + datum.costOverage,
        };
      }, {}),
    [data]
  );

  const shouldRenderChart = data.length > 0 && props.budget && !props.loading;

  return (
    <StyledDiv>
      <Flex
        alignItems="center"
        marginBottom={theme.space_xs}
        marginLeft={theme.space_md}
      >
        <Text appearance="h4">
          {copyText.budgetsHistoricalMonthlyGraphTitle}
        </Text>
        <Tooltip
          content={copyText.budgetsHistoricalMonthlyGraphTooltip}
          icon={faInfoCircle}
          width={"12rem"}
        />
      </Flex>
      {!shouldRenderChart && (
        <EmptyPlaceholder
          icon={faChartBar}
          loading={props.loading}
          small
          skeletonVariant="cartesian"
        />
      )}
      {shouldRenderChart && (
        <ResponsiveContainer width="95%" height={props.height}>
          <ComposedChart
            data={data}
            margin={{
              bottom: 0,
              left: 10,
              right: 0,
              top: 0,
            }}
          >
            <XAxis
              dataKey="monthNumber"
              stroke={theme.chart_axis_text}
              tick={{ fontSize: 12 }}
              tickSize={8}
              tickFormatter={(value) =>
                getMonthNameForMonthNumber(Number(value) as Month)
              }
            />
            <YAxis
              padding={{ top: 20 }}
              stroke={theme.chart_axis_text}
              tick={{ fontSize: 12 }}
              tickFormatter={(number) =>
                formatCurrencyRounded({
                  number,
                })
              }
              tickSize={8}
            />
            <ChartTooltip
              content={(e) => (
                <SimpleChartTooltip
                  entry={costKeyedByMonthNumber[e.label]}
                  label={getMonthNameForMonthNumber(Number(e.label) as Month)}
                  reverseSortedGroupings={["cost", "costOverage"]}
                />
              )}
              cursor={{ strokeWidth: 0, fill: theme.bar_chart_cursor_fill }}
            />
            <Bar
              animationDuration={timing.chartAnimationDuration}
              barSize={20}
              dataKey="cost"
              fill={theme.budgets_chart_fill_underspend}
              stackId="c"
            />
            <Bar
              animationDuration={timing.chartAnimationDuration}
              barSize={20}
              dataKey="costOverage"
              fill={theme.budgets_chart_fill_overage_historical}
              stackId="c"
            />
            <Line
              activeDot={false}
              dataKey="budgetAmount"
              dot={false}
              stroke={theme.budgets_chart_reference_line}
              strokeWidth={3}
              type="step"
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </StyledDiv>
  );
}

export default React.memo(
  function MemoBudgetsHistoricalMonthlyGraph(props: Props) {
    return <BudgetsHistoricalMonthlyGraph {...props} />;
  },
  (prevProps, newProps) => {
    const areEqual = Object.keys(prevProps).reduce((accum, key) => {
      return prevProps[key] === newProps[key] && accum;
    }, true);

    // if this comparison function returns true, then the update is skipped
    return areEqual;
  }
);
