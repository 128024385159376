import paths from "@/constants/paths";
import { useTheme } from "@emotion/react";
import { faList, faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { ActivityType } from "@ternary/api-lib/constants/enums";
import { ReportEntity } from "@ternary/api-lib/core/types/Report";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDistance } from "date-fns";
import { keyBy, noop } from "lodash";
import React, { PropsWithChildren, useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Avatar from "../../../ui-lib/components/Avatar";
import MarkdownWrapper from "../../../ui-lib/components/MarkdownWrapper";
import { getFullName } from "../../../utils/UserUtils";
import ReportChart from "../../reporting-engine/components/ReportChart";
import useGetReportData, {
  defaultReportDataResult,
} from "../../reporting-engine/hooks/useGetReportData";
import copyText from "../copyText";
import {
  AssigneeChangeActivity,
  CaseActivity,
  CommentActivity,
  StatusChangeActivity,
  User,
} from "../types";

const activityTypeCopyText = {
  [ActivityType.ASSIGNEE_CHANGE]: copyText.activityAssigneeChangeText,
  [ActivityType.COMMENT]: copyText.activityAssigneeCommentText,
  [ActivityType.STATUS_CHANGE]: copyText.activityAssigneeStatusChangeText,
};

interface Props {
  activity: CaseActivity[];
  isLoading: boolean;
  reports: ReportEntity[];
  users: User[];
}

export default function CaseActivityList(props: Props): JSX.Element {
  const theme = useTheme();

  const endListRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    endListRef.current?.scrollIntoView({
      behavior: "auto",
      block: "nearest",
      inline: "start",
    });
  }, [props.activity]);

  if (props.isLoading || props.activity.length === 0) {
    return (
      <EmptyPlaceholder
        height={600}
        icon={faList}
        loading={props.isLoading}
        text={copyText.noActivityPlaceholderText}
      />
    );
  }

  const usersKeyedByID = keyBy(props.users, "id");

  return (
    <Flex
      direction="column"
      flex="1 1 300px"
      marginTop={theme.space_md}
      height={700}
      overflowY="auto"
      scrollable
    >
      {props.activity.map((activity) => {
        const createdByUser = usersKeyedByID[activity.userID];

        const distance = formatDistance(
          new Date(activity.createdAt),
          new Date()
        );

        return (
          <Flex key={activity.id} marginBottom={theme.space_lg}>
            <Box marginRight={theme.space_md}>
              <Avatar
                src={createdByUser ? createdByUser.avatarSrc : ""}
                height="36px"
                width="36px"
              />
            </Box>
            <Box width="100%">
              <Flex>
                <Box marginBottom={theme.space_sm}>
                  <MarkdownWrapper disableHTML>
                    {copyText.activityListStatusDetailsText
                      .replace("%username%", getFullName(createdByUser))
                      .replace("%verb%", activityTypeCopyText[activity.type])}
                  </MarkdownWrapper>
                </Box>
                <Text
                  color={theme.text_color_caption}
                  marginLeft={theme.space_sm}
                >
                  {copyText.timeDistanceCaption.replace("%distance%", distance)}
                </Text>
              </Flex>
              {(() => {
                switch (activity.type) {
                  case ActivityType.ASSIGNEE_CHANGE: {
                    return (
                      <AssigneeChange activity={activity} users={props.users} />
                    );
                  }
                  case ActivityType.COMMENT: {
                    return (
                      <Comment activity={activity} reports={props.reports} />
                    );
                  }
                  case ActivityType.STATUS_CHANGE: {
                    return <StatusChange activity={activity} />;
                  }
                }
              })()}
            </Box>
          </Flex>
        );
      })}
      <div ref={endListRef} />
    </Flex>
  );
}

type AssigneeProps = {
  activity: AssigneeChangeActivity;
  users: User[];
};

function AssigneeChange(props: AssigneeProps) {
  const theme = useTheme();

  const usersKeyedByID = keyBy(props.users, "id");

  const prevAssignees = props.activity.prevAssigneeIDs
    .map((userID) => usersKeyedByID[userID])
    .filter((assignee) => !!assignee);

  const newAssignees = props.activity.newAssigneeIDs
    .map((userID) => usersKeyedByID[userID])
    .filter((assignee) => !!assignee);

  const extraPrevAssignees = prevAssignees.slice(1, prevAssignees.length);
  const extraNewAssignees = newAssignees.slice(1, newAssignees.length);

  function renderAssigneeBadge(user: User) {
    return (
      <Flex alignItems="center">
        <Avatar src={user.avatarSrc} />
        <Text marginLeft={theme.space_sm}>{getFullName(user)}</Text>
      </Flex>
    );
  }

  function assigneeTooltipMap(assignees: User[]) {
    return (
      <Flex direction="column" alignItems="flex-start">
        {assignees.map((assignee) => (
          <Text key={assignee.id} color={theme.text_color_inverse}>
            {getFullName(assignee)}
          </Text>
        ))}
      </Flex>
    );
  }

  return (
    <Flex alignItems="center">
      {prevAssignees.length > 0 ? (
        <>
          <Box marginRight={theme.space_xxs}>
            {renderAssigneeBadge(prevAssignees[0])}
          </Box>
          {extraPrevAssignees.length > 0 && (
            <Tooltip content={assigneeTooltipMap(extraPrevAssignees)}>
              <Text>
                {copyText.extraAssigneesCaption.replace(
                  "%count%",
                  String(extraPrevAssignees.length)
                )}
              </Text>
            </Tooltip>
          )}
        </>
      ) : (
        <Text>{copyText.unnassignedActivityCaption}</Text>
      )}

      <Box marginHorizontal={theme.space_sm}>
        <Icon color={theme.text_color} icon={faLongArrowAltRight} />
      </Box>

      {newAssignees.length > 0 ? (
        <>
          <Box marginRight={theme.space_xxs}>
            {renderAssigneeBadge(newAssignees[0])}
          </Box>
          {extraNewAssignees.length > 0 && (
            <Tooltip content={assigneeTooltipMap(extraNewAssignees)}>
              <Text>
                {copyText.extraAssigneesCaption.replace(
                  "%count%",
                  String(extraNewAssignees.length)
                )}
              </Text>
            </Tooltip>
          )}
        </>
      ) : (
        <Text>{copyText.unnassignedActivityCaption}</Text>
      )}
    </Flex>
  );
}

type CommentProps = {
  activity: CommentActivity;
  reports: ReportEntity[];
};

function Comment(props: CommentProps): JSX.Element {
  const theme = useTheme();

  const reportsKeyedByID = keyBy(props.reports, "id");
  const report = reportsKeyedByID[props.activity.reportID ?? ""];

  return (
    <Flex direction="column">
      <Box marginBottom={theme.space_sm}>
        <MarkdownWrapper>{props.activity.text}</MarkdownWrapper>
      </Box>
      {report && props.activity.reportID && (
        <ReportCommentContainer report={report} />
      )}
    </Flex>
  );
}

function ReportCommentContainer(props: { report: ReportEntity }) {
  const theme = useTheme();

  const {
    data: reportDataResult = defaultReportDataResult,
    isFetching: isLoadingReportData,
  } = useGetReportData(props.report);

  const { main: reportDataMain } = reportDataResult;

  return (
    <Box
      height={300}
      marginVertical={theme.space_md}
      padding={0}
      width={"100%"}
    >
      <Link
        to={{
          pathname: paths._reportBuilder.replace(":reportID", props.report.id),
        }}
      >
        <Text appearance="link" fontSize={theme.h4_fontSize}>
          {props.report.name}
        </Text>
      </Link>
      <ReportChart
        data={reportDataMain.result.data}
        isLoading={isLoadingReportData}
        report={props.report}
        onInteraction={noop}
      />
    </Box>
  );
}

type StatusChangeProps = {
  activity: StatusChangeActivity;
};

function StatusChange(props: StatusChangeProps) {
  const theme = useTheme();

  function StatusLabel(props: PropsWithChildren<unknown>) {
    return (
      <Flex
        alignItems="center"
        backgroundColor={theme.secondary_color_background}
        borderRadius={theme.borderRadius_2}
        marginBottom={theme.space_xxs}
        paddingHorizontal={theme.space_sm}
        paddingRight={theme.space_sm}
        paddingVertical={theme.space_xxs}
      >
        <Text>{props.children}</Text>
      </Flex>
    );
  }

  return (
    <Flex alignItems="center">
      <StatusLabel>{props.activity.prevStatus}</StatusLabel>
      <Box marginHorizontal={theme.space_sm}>
        <Icon color={theme.text_color} icon={faLongArrowAltRight} />
      </Box>
      <StatusLabel>{props.activity.newStatus}</StatusLabel>
    </Flex>
  );
}
