import { useQuery } from "@tanstack/react-query";
import DataQuery from "@ternary/api-lib/analytics/api/DataQuery";
import { DataSource, Operator } from "@ternary/api-lib/analytics/enums";
import UError from "unilib-error";
import { ANALYTICS_QUERY_GC_TIME } from "../../../../constants";
import { useAnalyticsApiClient } from "../../../../context/AnalyticsQueryLoaderProvider";
import useAuthenticatedUser from "../../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import {
  AWSOpenSearchInstance,
  awsOpenSearchDimensions,
  awsOpenSearchMeasures,
} from "../types";

export interface Params {
  dateRange: Date[];
}

export type AWSOpenSearchInstanceEntity = {
  domainId: string;
  instanceCost: number;
  instanceMemory: string;
  instanceType: string;
  instanceVcpu: string;
  nodeUsage: number;
  usageAccountId: string;
};

export default function useGetAWSOpenSearchInstances(
  params: Params,
  options?: UseQueryOptions<AWSOpenSearchInstanceEntity[], UError>
): UseQueryResult<AWSOpenSearchInstanceEntity[], UError> {
  const authenticatedUser = useAuthenticatedUser();
  const client = useAnalyticsApiClient();
  const gatekeeper = useGatekeeper();

  const tenantID = authenticatedUser.tenant.fsDocID;

  return useQuery({
    queryKey: ["awsOpenSearceInstances", params],
    queryFn: async () => {
      const measures = [
        awsOpenSearchMeasures.nodeUsage,
        awsOpenSearchMeasures.instanceCost,
      ];

      const dimensions = [
        awsOpenSearchDimensions.domainId,
        awsOpenSearchDimensions.instanceMemory,
        awsOpenSearchDimensions.instanceType,
        awsOpenSearchDimensions.instanceVcpu,
        awsOpenSearchDimensions.usageAccountId,
      ];

      const query = new DataQuery({
        dataSource: DataSource.AWS_OPEN_SEARCH_VISIBILITY,
        dateRange: params.dateRange,
        dimensions,
        measures,
        preAggFilters: [
          {
            name: awsOpenSearchDimensions.instanceType,
            operator: Operator.SET,
            values: null,
          },
        ],
      });
      const result = await client.loadData<AWSOpenSearchInstance>(
        tenantID,
        query
      );

      return result.response.map((summary) => ({
        domainId: summary.domainId ?? "",
        instanceCost: summary.instanceCost ?? 0,
        instanceMemory: summary.instanceMemory ?? "0",
        instanceType: summary.instanceType ?? "",
        instanceVcpu: summary.instanceVcpu ?? 0,
        nodeUsage: summary.nodeUsage ?? 0,
        usageAccountId: summary.usageAccountId ?? "",
      }));
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    enabled: gatekeeper.hasAWSIntegration,
    ...options,
  });
}
