import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useEffect, useMemo } from "react";
import { LegendProps } from "recharts";
import Box from "../../ui-lib/components/Box";
import Flex from "../../ui-lib/components/Flex";
import Text from "../../ui-lib/components/Text";
import { Tooltip } from "../../ui-lib/components/Tooltip";
import copyText from "../../ui-lib/copyText";
import { AVG_PIXEL_WIDTH_OF_CHARACTER } from "../constants";
import { getIsDashedMeasure } from "../utils/ChartStyleUtils";
import ChartDataManager from "./ChartDataManager";

const MORE_ITEMS_LEGEND_WIDTH = 60;
const SINGLE_ROW_SIZE = 33;
const TEXT_ITEM_PADDING = 10;
const WIDTH_OF_COLOR_BOX = 12;

type Props = {
  align?: "center" | "left";
  dataManager: ChartDataManager;
  isServer?: boolean;
  maxKeys?: number;
  readableKeys?: { [key: string]: string };
  onInteraction: (interaction: LegendSimple.Interaction) => void;
  isPieChart?: boolean;
} & LegendProps;

type LegendEntry = {
  color: string;
  chartKey: string;
  measure?: string;
  text: string;
};

function LegendItem(props: {
  color: string;
  text: string;
  showInverseColor?: boolean;
  onClick: () => void;
}) {
  const theme = useTheme();

  return (
    <StyledFlex
      alignItems="center"
      paddingHorizontal={theme.space_xs}
      paddingVertical={theme.space_xxs}
      showInverseColor={props.showInverseColor}
      onClick={() => props.onClick()}
    >
      <Box
        background={props.color}
        borderRadius="3px"
        height={theme.fontSize_small}
        marginRight={theme.space_xxs}
        width={WIDTH_OF_COLOR_BOX}
      />

      <Text
        fontSize={theme.fontSize_small}
        color={
          props.showInverseColor ? theme.text_color_inverse : theme.text_color
        }
      >
        {props.text}
      </Text>
    </StyledFlex>
  );
}

function LegendSimple(props: Props) {
  const theme = useTheme();
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = React.useState(0);

  const entries: LegendEntry[] = useMemo(() => {
    const data: LegendEntry[] = [];
    if (props.payload) {
      [...props.payload]
        .reverse()
        .forEach(({ value, color = theme.background_color_disabled }) => {
          if (typeof value !== "string") return;

          const measure = props.dataManager.getMeasure(value)?.name;

          if (!measure && !props.isPieChart) return;

          data.push({
            color,
            chartKey: value,
            measure,
            text: props.isPieChart
              ? value
              : getEntryText(
                  props.dataManager,
                  value,
                  props.readableKeys ?? {}
                ),
          });
        });
    }
    return data;
  }, [props.payload, props.dataManager, props.readableKeys, props.isPieChart]);

  const maxKeys = useMemo(() => {
    if (props.maxKeys) return props.maxKeys;
    if (width > 0 && !props.isServer) {
      const availableWidth = width - MORE_ITEMS_LEGEND_WIDTH;

      let numberOfItems = 0;
      let rest = availableWidth;

      for (const entry of entries) {
        const currentSize =
          entry.text.length * AVG_PIXEL_WIDTH_OF_CHARACTER +
          TEXT_ITEM_PADDING +
          WIDTH_OF_COLOR_BOX;

        if (rest - currentSize < 0) {
          break;
        }

        numberOfItems += 1;
        rest -= currentSize;
      }

      return Math.max(1, numberOfItems);
    }

    return entries.length;
  }, [width, props.maxKeys, entries.length]);

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.clientWidth);
    }
  }, []);

  const align = props.align ?? "center";
  const visibleEntries = entries.slice(0, maxKeys);
  const hiddenEntries = entries.slice(maxKeys);

  const scrollWrapper = (content: JSX.Element) =>
    props.isServer ? (
      <Box overflowY="visible">{content}</Box>
    ) : (
      <Flex
        justifyContent="center"
        maxHeight={SINGLE_ROW_SIZE}
        position="relative"
        width="100%"
      >
        <Box maxHeight="100%" width="100%" overflowX="auto">
          {content}
        </Box>
      </Flex>
    );

  function getExcludedKeysAfterToggle(chartKey: string) {
    return props.dataManager.isExcluded(chartKey)
      ? props.dataManager.excludedChartKeys.filter((key) => key !== chartKey)
      : [...props.dataManager.excludedChartKeys, chartKey];
  }

  return scrollWrapper(
    <div ref={containerRef}>
      <Flex
        flexWrap={props.isServer ? "wrap" : "nowrap"}
        justifyContent={align === "left" ? "flex-start" : "center"}
        marginTop={theme.space_xs}
      >
        {visibleEntries.map((entry) => {
          const color = getIsDashedMeasure(entry.measure)
            ? `repeating-linear-gradient(45deg,${entry.color}, ${entry.color} 5px, white 5px, white 10px)`
            : entry.color;

          return (
            <LegendItem
              key={entry.chartKey}
              color={color}
              text={entry.text}
              onClick={() =>
                props.onInteraction({
                  type: LegendSimple.INTERACTION_CHART_EXCLUSION_CHANGED,
                  excludedChartKeys: getExcludedKeysAfterToggle(entry.chartKey),
                })
              }
            />
          );
        })}

        {!!hiddenEntries.length && (
          <Tooltip
            content={
              <Flex direction="column" overflowY="auto" maxHeight={"500px"}>
                {hiddenEntries.map((entry) => {
                  const color = getIsDashedMeasure(entry.measure)
                    ? `repeating-linear-gradient(45deg,${entry.color}, ${entry.color} 5px, white 5px, white 10px)`
                    : entry.color;
                  return (
                    <LegendItem
                      key={entry.chartKey}
                      color={color}
                      text={entry.text}
                      showInverseColor
                      onClick={() =>
                        props.onInteraction({
                          type: LegendSimple.INTERACTION_CHART_EXCLUSION_CHANGED,
                          excludedChartKeys: getExcludedKeysAfterToggle(
                            entry.chartKey
                          ),
                        })
                      }
                    />
                  );
                })}
              </Flex>
            }
            placement="top"
          >
            <Flex
              alignItems="center"
              paddingHorizontal={theme.space_xs}
              paddingVertical={theme.space_xxs}
            >
              <Box height={theme.fontSize_small} />

              <Text fontSize={theme.fontSize_small}>
                {copyText.numRowsHidden.replace(
                  "%COUNT%",
                  String(hiddenEntries.length)
                )}
              </Text>
            </Flex>
          </Tooltip>
        )}
      </Flex>
    </div>
  );
}

LegendSimple.INTERACTION_CHART_EXCLUSION_CHANGED =
  `LegendSimple.INTERACTION_CHART_EXCLUSION_CHANGED` as const;

interface InteractionChartExclusionChanged {
  type: typeof LegendSimple.INTERACTION_CHART_EXCLUSION_CHANGED;
  excludedChartKeys: string[];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace LegendSimple {
  export type Interaction = InteractionChartExclusionChanged;
}

export default LegendSimple;

const StyledFlex = styled(Flex)<{ showInverseColor?: boolean }>(
  ({ theme, showInverseColor }) => ({
    borderRadius: theme.borderRadius_1,
    "&:hover": {
      backgroundColor: showInverseColor
        ? theme.background_color_disabled_inverse
        : theme.background_color_disabled,
      cursor: "pointer",
    },
  })
);

function getEntryText(
  dataManager: ChartDataManager,
  chartKey: string,
  readableKeys: { [key: string]: string }
): string {
  const dimensionValues = dataManager.getDimensionValuesFromChartKey(chartKey);
  const measure = dataManager.getMeasure(chartKey);

  const readable = (key: string) => {
    if (key in readableKeys) return readableKeys[key];
    return key;
  };

  if (!measure) return "---";

  if (dimensionValues.length === 0) {
    return readable(measure.name);
  }

  let grouping = "";

  if (dimensionValues.length === 1) {
    grouping = dimensionValues[0].value;
  } else {
    grouping = dimensionValues
      .map(({ dimension, value }) => `${readable(dimension.name)}: ${value}`)
      .join(" / ");
  }

  if (dataManager.measures.length === 1) {
    return grouping;
  }

  return `${readable(measure.name)} - ${grouping}`;
}
