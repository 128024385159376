import { UnitType } from "../enums";

const measures = [
  { name: "cost", unit: UnitType.CURRENCY },
  { name: "diskReadBytes", unit: UnitType.BYTES },
  { name: "diskWriteBytes", unit: UnitType.BYTES },
  { name: "provisionedDiskBytes", unit: UnitType.BYTES },
  { name: "productMemoryBytes", unit: UnitType.BYTES },
  { name: "productMemoryBytesChart", unit: UnitType.BYTES },
  { name: "receivedBytes", unit: UnitType.BYTES },
  { name: "sentBytes", unit: UnitType.BYTES },
  { name: "receivedBytes", unit: UnitType.BYTES },
  { name: "productVCPU", unit: UnitType.BYTES },
  { name: "productVCPUChart", unit: UnitType.BYTES },
];

const dimensions = [
  "category",
  "family",
  "operatingSystem",
  "projectId",
  "region",
  "storageClass",
  "storageType",
];

export const gcpComputeVisibilitySchema = { dimensions, measures };
