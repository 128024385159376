import { UnitType } from "../enums";

const measures = [
  { name: "onDemandCost", unit: UnitType.CURRENCY },
  { name: "totalSlotS", unit: UnitType.STANDARD },
  { name: "avgJobDuration", unit: UnitType.STANDARD },
  { name: "jobCount", unit: UnitType.STANDARD },
];

const dimensions = [
  "timestamp",
  "userEmail",
  "projectId",
  "datasetProjectId",
  "datasetId",
  "tableId",
  "jobType",
  "reservationId",
  "region",
  "statementType",
  "priority",
];

export const bigQueryUsageSchema = { dimensions, measures };
