import { useTheme } from "@emotion/react";
import { faAngleRight, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { Row } from "@tanstack/react-table";
import React, { ReactNode } from "react";
import Button from "../../../ui-lib/components/Button";
import Flex from "../../../ui-lib/components/Flex";
import Icon from "../../../ui-lib/components/Icon";

interface Props<TData extends Record<string, unknown>> {
  children: ReactNode;
  row: Row<TData>;
}

export function ExpandableCell<TData extends Record<string, unknown>>(
  props: Props<TData>
) {
  const theme = useTheme();

  const isFirstDepth = props.row.depth === 0;

  // Shift the content to the right to align non expandable rows
  const marginLeft =
    isFirstDepth && !props.row.getCanExpand() ? theme.space_lg : 0;

  return (
    <Flex
      alignItems="center"
      gap={theme.space_xs}
      marginLeft={marginLeft}
      paddingLeft={`${props.row.depth * 3}rem`}
    >
      {props.row.getCanExpand() ? (
        <Button
          iconStart={
            props.row.getIsExpanded() ? (
              <Icon icon={faAngleUp} />
            ) : (
              <Icon icon={faAngleRight} />
            )
          }
          onClick={props.row.getToggleExpandedHandler()}
          size="tiny"
        />
      ) : (
        <div />
      )}
      {props.children}
    </Flex>
  );
}
