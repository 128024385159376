import { buildCubeQuery } from "@/api/analytics/utils";
import { validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import {
  SnowflakeDatabaseUsageDatum,
  SnowflakeDatabaseUsageStruct,
} from "../types";

export interface Params {
  dateRange: Date[];
}

export default function useGetSnowflakeDatabaseUsage(
  params: Params,
  options?: UseQueryOptions<SnowflakeDatabaseUsageDatum[], UError>
): UseQueryResult<SnowflakeDatabaseUsageDatum[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["snowflakeDatabaseUsage"],
    queryFn: async () => {
      const dimensions = [
        "accountName",
        "comment",
        "databaseID",
        "databaseName",
        "databaseOwner",
        "deletionTime",
        "lastAlteredTime",
        "retentionDays",
      ];

      const measures = [
        "averagePerDatabaseDailyDatabaseBytes",
        "averagePerDatabaseDailyFailsafeBytes",
      ];

      const result = await client.load(
        buildCubeQuery({
          dataSource: DataSource.SNOWFLAKE_DATABASE_USAGE,
          dateRange: params.dateRange,
          dimensions,
          measures,
        })
      );

      const data = result.map((datum): SnowflakeDatabaseUsageDatum => {
        const [error, validData] = validate(
          datum,
          SnowflakeDatabaseUsageStruct
        );
        if (error) {
          throw new UError("INVALID_DATABASE_USAGE_DATA", {
            context: { error, result: datum },
          });
        }

        return {
          accountName: validData.accountName ?? "",
          averagePerDatabaseDailyDatabaseBytes:
            validData.averagePerDatabaseDailyDatabaseBytes ?? 0,
          averagePerDatabaseDailyFailsafeBytes:
            validData.averagePerDatabaseDailyFailsafeBytes ?? 0,
          comment: validData.comment ?? "",
          databaseID: validData.databaseID ?? 0,
          databaseName: validData.databaseName ?? "",
          databaseOwner: validData.databaseOwner ?? "",
          deletionTime: validData.deletionTime ?? "",
          lastAlteredTime: validData.lastAlteredTime ?? "",
          retentionDays: validData.retentionDays ?? 0,
        };
      });

      return data;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
