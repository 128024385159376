import { UnitType } from "../enums";
import { awsComputeUtilizationSchema } from "./awsComputeUtilization";

const measures = [
  ...awsComputeUtilizationSchema.measures,
  { name: "totalCost", unit: UnitType.CURRENCY },
  { name: "productVCPU", unit: UnitType.STANDARD },
  { name: "productVCPUChart", unit: UnitType.STANDARD },
  { name: "productMemoryBytes", unit: UnitType.BYTES },
  { name: "productMemoryBytesChart", unit: UnitType.BYTES },
  { name: "networkInBytes", unit: UnitType.BYTES },
  { name: "networkOutBytes", unit: UnitType.BYTES },
  { name: "diskReadBytes", unit: UnitType.BYTES },
  { name: "diskWriteBytes", unit: UnitType.BYTES },
];

const dimensions = ["billPayerAccountId", "family", "instanceType", "region"];

export const awsComputeVisibilitySchema = { dimensions, measures };
