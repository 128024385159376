import { buildCubeQuery } from "@/api/analytics/utils";
import {
  Infer,
  nullable,
  number,
  string,
  validateAll,
} from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { groupBy } from "lodash";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { AWSKubernetesInstanceGroupEntity } from "../types";

const usageStruct = {
  accountId: nullable(string()),
  clusterName: nullable(string()),
  cpuTotal: nullable(number()),
  cpuUsed: nullable(number()),
  memoryTotal: nullable(number()),
  memoryUsed: nullable(number()),
};

const costStruct = {
  accountId: nullable(string()),
  clusterName: nullable(string()),
  nonSavingsPlanCoveredCost: nullable(number()),
  savingsPlanCoveredCost: nullable(number()),
  cost: nullable(number()),
};

export interface Params {
  dateRange: Date[];
  queryFilters: QueryFilter[];
}

export default function useGetAWSKubernetesInstanceGroups(
  params: Params,
  options?: UseQueryOptions<AWSKubernetesInstanceGroupEntity[], UError>
): UseQueryResult<AWSKubernetesInstanceGroupEntity[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["awsKubernetesInstanceGroups", params],
    queryFn: async () => {
      const dimensions = ["accountId", "clusterName"];

      const [costResult, usageResult] = await Promise.all([
        client.load(
          buildCubeQuery({
            ...params,
            dataSource: DataSource.AWS_KUBERNETES_NODE_COST,
            dimensions,
            measures: [
              "nonSavingsPlanCoveredCost",
              "savingsPlanCoveredCost",
              "cost",
            ],
          })
        ),
        client.load(
          buildCubeQuery({
            ...params,
            dataSource: DataSource.AWS_KUBERNETES_INSTANCES,
            dimensions,
            measures: ["cpuTotal", "cpuUsed", "memoryTotal", "memoryUsed"],
          })
        ),
      ]);

      const [costError, costData] = validateAll(costResult, costStruct);
      const [usageError, usageData] = validateAll(usageResult, usageStruct);

      if (costError || usageError) {
        throw new UError("INVALID_KUBERNETES_INSTANCE_DATA", {
          context: {
            error: [costError, usageError],
            result: [costData, usageData],
          },
        });
      }

      return mergeCostUsageData([
        ...fillEmptyValues(costData),
        ...fillEmptyValues(usageData),
      ]);
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}

type ValidatedData = Partial<
  Infer<typeof usageStruct> & Infer<typeof costStruct>
>;

function fillEmptyValues(
  data: ValidatedData[]
): AWSKubernetesInstanceGroupEntity[] {
  return data.map((datum) => ({
    accountID: datum.accountId ?? "null",
    coveredCost: datum.savingsPlanCoveredCost ?? 0,
    cpuTotal: datum.cpuTotal ?? 0,
    cpuUsed: datum.cpuUsed ?? 0,
    memoryTotal: datum.memoryTotal ?? 0,
    memoryUsed: datum.memoryUsed ?? 0,
    name: datum.clusterName ?? "null",
    onDemandCost: datum.nonSavingsPlanCoveredCost ?? 0,
    totalCost: datum.cost ?? 0,
    totalWaste: datum.cost ? datum.cost * 0.7 : 0, // TODO: Replace
  }));
}

function mergeCostUsageData(
  data: AWSKubernetesInstanceGroupEntity[]
): AWSKubernetesInstanceGroupEntity[] {
  const dataGroupedByAccountIDAndName = groupBy(
    data,
    (datum) => `${datum.accountID} / ${datum.name}`
  );

  const mergedData: AWSKubernetesInstanceGroupEntity[] = [];

  Object.values(dataGroupedByAccountIDAndName).forEach((group) => {
    mergedData.push(
      group.reduce((groupTotal, current) => ({
        accountID: groupTotal.accountID,
        coveredCost: groupTotal.coveredCost + current.coveredCost,
        cpuTotal: groupTotal.cpuTotal + current.cpuTotal,
        cpuUsed: groupTotal.cpuUsed + current.cpuUsed,
        memoryTotal: groupTotal.memoryTotal + current.memoryTotal,
        memoryUsed: groupTotal.memoryUsed + current.memoryUsed,
        name: groupTotal.name,
        onDemandCost: groupTotal.onDemandCost + current.onDemandCost,
        totalCost: groupTotal.totalCost + current.totalCost,
      }))
    );
  });

  return mergedData;
}
