import { isPlainObject } from "lodash";
import UError from "unilib-error";
import z from "zod";
import { Failure, Message } from "../Message";
import { MspBillingInfoEntity } from "../types/MspBillingInfo";

//
// Queries
//

export class FindMspBillingInfoQuery extends Message {}

const findMspBillingInfoByIDQuerySchema = z.object({
  billingInfoID: z.string().uuid(),
});

export class FindMspBillingInfoByIDQuery extends FindMspBillingInfoQuery {
  public readonly data;
  public readonly name = "FindMspBillingInfoByIDQuery";

  constructor(inputs: unknown) {
    super();

    this.data = this._parse(inputs, findMspBillingInfoByIDQuerySchema);
  }
}

const findMspBillingInfoByTenantIDQuerySchema = z.object({
  tenantID: z.string().uuid(),
});

export class FindMspBillingInfoByTenantIDQuery extends FindMspBillingInfoQuery {
  public readonly data;
  public readonly name = "FindMspBillingInfoByTenantIDQuery";

  constructor(inputs: unknown) {
    super();

    this.data = this._parse(inputs, findMspBillingInfoByTenantIDQuerySchema);
  }
}

//
// Result
//

export class FindMspBillingInfoQueryResult {
  public readonly billingInfo: MspBillingInfoEntity;

  public constructor({ billingInfo }: { billingInfo: MspBillingInfoEntity }) {
    if (!isPlainObject(billingInfo)) {
      throw new UError(
        `${this.constructor.name}.constructor/INVALID_BILLING_INFORMATION`,
        { context: { billingInfo } }
      );
    }

    this.billingInfo = billingInfo;
  }
}

//
// Failure
//

export class FindMspBillingInfoQueryFailure extends Failure {
  public constructor({ reason }: { reason: string }) {
    super({ reason });
  }

  public static readonly REASON_BILLING_INFORMATION_NOT_FOUND = `${FindMspBillingInfoQueryFailure.name}/BILLING_INFORMATION_NOT_FOUND`;
  public static readonly REASON_PERMISSION_DENIED = `${FindMspBillingInfoQueryFailure.name}/PERMISSION_DENIED`;
  public static readonly REASON_UNEXPECTED = `${FindMspBillingInfoQueryFailure.name}/UNEXPECTED`;
}
