import commonCopyText from "../../common.copyText";

export default {
  ...commonCopyText,
  chartTitleTotalSpend: "Total Spend",
  dropdownLabelGroupings: "Groupings:",
  dropdownLabelLookAheadRange: "Lookahead Range",
  dropdownLabelLookbackRange: "Lookback Range",
  dropdownLabelGranularity: "Granularity",
  dropdownLabelMeasure: "Measure:",
  dropdownLabelVendor: "Vendor:",
  forecastingChartOption_CURRENT_MONTH: "Current Month",
  forecastingChartOption_LOOKBACK: "Lookback Range",
  forecastingGranularityOption_DAY: "Day",
  forecastingGranularityOption_default: "Default",
  forecastingGranularityOption_MONTH: "Month",
  forecastingGranularityOption_WEEK: "Week",
  forecastingPageTitle: "Forecasting",
  groupingsDropdownPlaceholder: "Select a grouping...",
  lookAheadRangeLabel_default: "Default",
  lookAheadRangeLabel_END_OF_MONTH: "Current month",
  lookAheadRangeLabel_D_07: "7 days",
  lookAheadRangeLabel_D_14: "14 days",
  lookAheadRangeLabel_M_01: "1 month",
  lookAheadRangeLabel_M_02: "2 months",
  lookAheadRangeLabel_M_03: "3 months",
  lookAheadRangeLabel_M_06: "6 months",
  lookAheadRangeLabel_M_12: "12 months",
  lookAheadRangeLabel_M_18: "18 months",
  lookAheadRangeLabel_M_24: "24 months",
  lookAheadRangeLabel_W_01: "1 week",
  lookAheadRangeLabel_W_02: "2 weeks",
  lookAheadRangeLabel_W_03: "3 weeks",
  lookAheadRangeLabel_W_04: "4 weeks",
  lookAheadRangeLabel_W_08: "8 weeks",
  lookAheadRangeLabel_W_12: "12 weeks",
  lookAheadRangeLabel_W_24: "24 weeks",
  lookbackRangeLabel_default: "Default",
  lookBackRangeLabel_NINETY_DAYS: "90 days",
  lookBackRangeLabel_ONE_HUNDRED_EIGHTY_DAYS: "180 days",
  lookBackRangeLabel_ONE_HUNDRED_FIFTY_DAYS: "150 days",
  lookBackRangeLabel_ONE_HUNDRED_TWENTY_DAYS: "120 days",
  lookBackRangeLabel_SIX_MONTHS: "6 months",
  lookBackRangeLabel_SIXTY_DAYS: "60 days",
  lookBackRangeLabel_THIRTY_DAYS: "30 days",
  lookBackRangeLabel_TWELVE_MONTHS: "12 months",
  lookBackRangeLabel_TWENTY_FOUR_MONTHS: "24 months",
  lookbackToggleToolTipMessage: "show lookback period cost",
  measureLabel_amortizedCost: "Amortized Cost",
  measureLabel_cost: "Cost",
  measureLabel_customNetCost: "Custom Net Cost",
  measureLabel_indirectCost: "Indirect Cost",
  measureLabel_netAmortizedCost: "Net Amortized Cost",
  measureLabel_netCost: "Net Cost",
  meterLabelCurrentMtdSpend: "Current MTD Spend",
  meterLabelEstimatedDeltaSpend: "Estimated Delta Spend",
  meterLabelEstimatedMonthlyTotalSpend: "Estimated Monthly Total Spend",
  meterLabelPrevious3MonthAverageSpend: "Previous 3 Mo. Avg Spend",
  meterLabelPreviousMonthTotal: "Previous Month Total",
  meterLabelPreviousMtdSpend: "Previous MTD Spend",
  tableHeaderCurrentSpend: "Current Month Spend",
  tableHeaderEstimatedDeltaSpend: "Estimated Delta Spend",
  tableHeaderEstimatedTotalSpend: "Estimated Total Spend",
  tableHeaderPrevMonthTotal: "Previous Month Total",
  tableHeaderServiceDescription: "Service Description",
  vendorLabel_ALL: "All",
  vendorLabel_AWS: "AWS",
  vendorLabel_AZURE: "Azure",
  vendorLabel_GCP: "GCP",
};
