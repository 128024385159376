import { TimeGranularity } from "@ternary/api-lib/analytics/enums";

const units = {
  dollars: "$",
  gigSeconds: "GB",
  terabytes: "TB",
  vcoreSeconds: "cores",
  slots: "slots",
};

interface convertedUnit {
  quantity: number;
  unit: string;
}

export const convertResource = (params: {
  quantity: number;
  unit: string;
  timeGranularity?: TimeGranularity;
}): convertedUnit => {
  const output: convertedUnit = {
    quantity: 0,
    unit: "",
  };

  switch (params.unit) {
    case "byte-seconds":
      output.quantity = params.quantity * 0.000000001;
      output.unit = units.gigSeconds;
      break;
    case "dollars":
      output.quantity = params.quantity;
      output.unit = units.dollars;
      break;
    case "slots-ms":
      if (!params.timeGranularity) return output;

      output.quantity =
        params.quantity / getDenonminatorForSlots(params.timeGranularity);
      output.unit = "slots";
      break;
    case "vcore-seconds":
      output.quantity = params.quantity;
      output.unit = units.vcoreSeconds;
      break;
  }

  return output;
};

function getDenonminatorForSlots(timeGranularity: TimeGranularity) {
  switch (timeGranularity) {
    case TimeGranularity.SECOND:
      return 1_000;
    case TimeGranularity.MINUTE:
      return 60_000;
    case TimeGranularity.HOUR:
      return 3_600_000;
    case TimeGranularity.DAY:
      return 86_400_000;
    case TimeGranularity.WEEK:
      return 604_800_000;
    case TimeGranularity.MONTH:
      return 2_628_000_000;
    default:
      return 1;
  }
}
