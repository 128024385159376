import copyText from "../../copyText";
import { FormData } from "./types";

export const defaultValues: FormData = {
  // General
  name: "",
  roleARN: "",

  // Cost And Usage Report
  configuredCURs: [],
  nameCURs: "",
  regionCURs: null,
  reportPathPrefixCURs: "",
  s3BucketNameCURs: "",
};

export const ALIBABA_BUCKET_NAME_REGEX =
  /(?!^(\d{1,3}\.){3}\d{1,3}$)(^[a-z0-9]([a-z0-9-]*(\.[a-z0-9])?)*$)/;

export const ALIBABA_ROLE_ARN_REGEX = /acs:ram::[0-9]+:role\/.*/;
export const ALIBABA_REPORT_PATH_REGEX = /([^./]$)+/;
export const alibabaRegions = [
  "ap-northeast-1",
  "ap-northeast-2",
  "ap-south-1",
  "ap-southeast-1",
  "ap-southeast-2",
  "ca-central-1",
  "eu-central-1",
  "eu-north-1",
  "eu-west-1",
  "eu-west-2",
  "eu-west-3",
  "sa-east-1",
  "us-east-1",
  "us-east-2",
  "us-gov-east-1",
  "us-gov-west-1",
  "us-west-1",
  "us-west-2",
];

export const validatesBucketName = (value: string) =>
  !ALIBABA_BUCKET_NAME_REGEX.test(value)
    ? copyText.alibabaFormBucketNameValidationErrorCaption
    : undefined;

export const validatesReportPath = (value: string) =>
  !ALIBABA_BUCKET_NAME_REGEX.test(value)
    ? copyText.alibabaFormReportPathValidationErrorCaption
    : undefined;
