import SelectCheckbox from "@/ui-lib/components/SelectCheckbox";
import { useTheme } from "@emotion/react";
import {
  faAngleLeft,
  faCog,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { Theme } from "@ternary/web-ui-lib/theme/default";
import {
  add,
  differenceInCalendarMonths,
  getYear,
  isBefore,
  startOfMonth,
  sub,
} from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { noop } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { DateHelper } from "../../../lib/dates";
import { DatePicker } from "../../../ui-lib/components/DateRangeControls/DatePicker";
import Divider from "../../../ui-lib/components/Divider";
import Form, { FormField } from "../../../ui-lib/components/Form";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import Modal from "../../../ui-lib/components/Modal";
import NumberInput from "../../../ui-lib/components/NumberInput";
import Select from "../../../ui-lib/components/Select";
import Switch from "../../../ui-lib/components/Switch";
import TextInput from "../../../ui-lib/components/TextInput";
import { getUTCMonthString } from "../../../utils/dates";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";
import { CommitmentPeriod, NetCostByDate, RampPlanBreakpoint } from "../types";
import {
  getAbsoluteAmount,
  getComparisonStringForRampPlans,
  getDeltaBetweenRampPlans,
  getEndOfRampPlan,
  getLatestVersion,
  getPercentChange,
  getStartOfRampPlan,
} from "../utils/rampPlans";
import RampPlanChartSection from "./RampPlanChartSection";
import RampPlanDimensionalSpendChart from "./RampPlanDimensionalSpendChart";

const DEFAULT_DIMENSION_COUNT = 3;
const DIMENSION_VALUE_OPTIONS: Option[] = [
  {
    label: copyText.configurationSectionCategoryOptionAnalytics,
    value: "Analytics",
  },
  { label: "Anthos", value: "Anthos" },
  {
    label: copyText.configurationSectionCategoryOptionAnthos,
    value: "Cloud AI and Industry Solutions",
  },
  {
    label: copyText.configurationSectionCategoryCompute,
    value: "Compute",
  },
  {
    label: copyText.configurationSectionCategoryDatabases,
    value: "Databases",
  },
  {
    label: copyText.configurationSectionCategoryGCPSecurity,
    value: "GCP Security",
  },
  {
    label: copyText.configurationSectionCategoryMarketplaceServices,
    value: "Marketplace Services",
  },
  {
    label: copyText.configurationSectionCategoryNetwork,
    value: "Network",
  },
  {
    label: copyText.configurationSectionCategoryOpsTools,
    value: "Ops Tools",
  },
  {
    label: copyText.configurationSectionCategoryPlaces,
    value: "Places",
  },
  {
    label: copyText.configurationSectionCategoryRoutes,
    value: "Routes",
  },
  {
    label: copyText.configurationSectionCategorySearchAPIs,
    value: "Search APIs",
  },
  {
    label: copyText.configurationSectionCategoryServerless,
    value: "Serverless",
  },
  {
    label: copyText.configurationSectionCategoryStorage,
    value: "Storage",
  },
];

type Option = {
  label: string;
  value: string;
};

interface BillingAccount {
  billingAccountID: string;
  cloudName: string;
}

interface BreakpointState {
  id?: string;
  defunct?: boolean;
  month: string;
  enumeratedValuesSpend: {
    [key: string]: { percent: number; currency: number };
  };
  otherSpend: { percent: number; currency: number };
  reason: string | null;
}

const BreakpointType = {
  ACTUALS: "ACTUALS",
  PERCENT: "PERCENT",
} as const;

type BreakpointType = (typeof BreakpointType)[keyof typeof BreakpointType];

type HistoricalCostData = {
  [month: string]: {
    [dimension: string]: { customNetCost: number; netCost: number };
  };
};

type RampPlanNonExportOffset = {
  [key: string]: number;
};

type RampPlanNonExportOffsetByMonth = {
  [month: string]: RampPlanNonExportOffset;
};

type RampPlan = {
  id: string;
  billingAccountIDs: string[];
  breakpoints: RampPlanBreakpoint[];
  commitments: CommitmentPeriod[];
  name: string;
  enumeratedValues: string[];
  key: string;
  nonExportOffsetByMonth: RampPlanNonExportOffsetByMonth;
  nonExportOffsetRecurring: RampPlanNonExportOffset;
};

interface Props {
  actualSpend: NetCostByDate[];
  billingAccounts: BillingAccount[];
  existingRampPlan: RampPlan | undefined;
  fetchHistoricalCostData: boolean;
  historicalCostData?: HistoricalCostData;
  isCopy: boolean;
  isLoadingBillingAccounts: boolean;
  isLoadingHistoricalCostData: boolean;
  loadingActuals: boolean;
  loadingMutation: boolean;
  selectedMonthForDimensionalChart: string;
  onInteraction: (
    interaction:
      | RampPlanConfigurationSection.Interaction
      | RampPlanDimensionalSpendChart.Interaction
  ) => void;
}

interface State {
  breakpoints: BreakpointState[];
  breakpointType: BreakpointType;
  commitments: CommitmentPeriod[];
  dimensionValues: string[];
  name: string;
  focusedElementHash: string | undefined;
  isDimensional: boolean;
  isOpenNonExportOffsetModal: boolean;
  nonExportOffsetByMonth: {
    amount: number;
    month: string;
    reason: string;
  }[];
  nonExportOffsetRecurring: {
    amount: number;
    reason: string;
  }[];
  selectedBillingAccountIDs: string[] | undefined;
}

const startOfThisMonth = new Date();
startOfThisMonth.setDate(0);

export function RampPlanConfigurationSection(props: Props): JSX.Element {
  const now = new DateHelper();

  const emptyStart = now.date.toISOString();
  const emptyEnd = sub(add(now.date, { years: 1 }), { days: 1 }).toISOString();

  const initialState: State = {
    breakpoints: [
      {
        month: formatDate(startOfMonth(new Date(emptyStart)), "yyyy-MM"),
        enumeratedValuesSpend: {},
        otherSpend: { percent: 0, currency: 0 },
        reason: null,
      },
      {
        month: formatDate(startOfMonth(new Date(emptyEnd)), "yyyy-MM"),
        enumeratedValuesSpend: {},
        otherSpend: { percent: 0, currency: 0 },
        reason: null,
      },
    ],
    breakpointType: BreakpointType.ACTUALS,
    commitments: [
      {
        _id: uuidv4(),
        amount: 0,
        end: emptyEnd,
        start: emptyStart,
      },
    ],
    dimensionValues: [],
    name: "",
    focusedElementHash: undefined,
    isDimensional: false,
    isOpenNonExportOffsetModal: false,
    nonExportOffsetByMonth: [],
    nonExportOffsetRecurring: [],
    selectedBillingAccountIDs: undefined,
  };

  const theme = useTheme();
  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  // Merge existing ramp plan into state
  useEffect(() => {
    if (props.existingRampPlan === undefined) {
      mergeState(initialState);
      return;
    }

    mergeState({
      selectedBillingAccountIDs: props.existingRampPlan.billingAccountIDs,
      breakpoints: props.existingRampPlan.breakpoints
        .sort((a, b) => {
          if (isBefore(new Date(a.month), new Date(b.month))) {
            return -1;
          }
          return 1;
        })
        .map((currentBreakpoint, index) => {
          const previousBreakpoint = props.existingRampPlan?.breakpoints[
            index - 1
          ]
            ? getLatestVersion(props.existingRampPlan.breakpoints[index - 1])
            : undefined;

          const latestVersion = getLatestVersion(currentBreakpoint);

          const percentOtherSpend = previousBreakpoint
            ? getPercentChange(
                previousBreakpoint.otherSpend,
                latestVersion.otherSpend
              )
            : 0;

          const enumeratedValuesSpend = Object.entries(
            latestVersion.enumeratedValuesSpend
          ).reduce(
            (accum, [key, value]) => ({
              ...accum,
              [key]: {
                currency: value,
                percent: previousBreakpoint
                  ? getPercentChange(
                      previousBreakpoint.enumeratedValuesSpend[key],
                      value
                    )
                  : 0,
              },
            }),
            {}
          );

          return {
            id: currentBreakpoint.id,
            month: currentBreakpoint.month,
            enumeratedValuesSpend: enumeratedValuesSpend,
            otherSpend: {
              currency: latestVersion.otherSpend,
              percent: percentOtherSpend,
            },
            reason: latestVersion.reason,
          };
        }),
      commitments: props.existingRampPlan.commitments,
      dimensionValues: props.existingRampPlan.enumeratedValues,
      isDimensional: props.existingRampPlan.enumeratedValues.length > 0,
      name: props.isCopy
        ? `${props.existingRampPlan.name} (${copyText.saveAsCopyRampPlanTitle})`
        : props.existingRampPlan.name,
      nonExportOffsetByMonth: !props.existingRampPlan
        ? []
        : Object.keys(props.existingRampPlan.nonExportOffsetByMonth).reduce(
            (
              accum: {
                amount: number;
                month: string;
                reason: string;
              }[],
              monthKey
            ) => {
              if (!props.existingRampPlan) return accum;

              Object.keys(
                props.existingRampPlan.nonExportOffsetByMonth[monthKey]
              ).forEach((reasonKey) => {
                if (!props.existingRampPlan) return;
                accum.push({
                  reason: reasonKey,
                  month: monthKey,
                  amount:
                    props.existingRampPlan.nonExportOffsetByMonth[monthKey][
                      reasonKey
                    ],
                });
              });
              return accum;
            },
            []
          ),
      nonExportOffsetRecurring: Object.keys(
        props.existingRampPlan.nonExportOffsetRecurring
      ).reduce((accum: { amount: number; reason: string }[], reasonKey) => {
        if (!props.existingRampPlan) return accum;

        accum.push({
          reason: reasonKey,
          amount: props.existingRampPlan.nonExportOffsetRecurring[reasonKey],
        });
        return accum;
      }, []),
    });
  }, [
    props.existingRampPlan &&
      getComparisonStringForRampPlans([props.existingRampPlan]),
  ]);

  useEffect(() => {
    if (
      Array.isArray(props.billingAccounts) &&
      props.billingAccounts.length > 0 &&
      !props.existingRampPlan
    ) {
      mergeState({
        selectedBillingAccountIDs: [props.billingAccounts[0].billingAccountID],
      });
    }
  }, [props.billingAccounts]);

  useEffect(() => {
    if (state.selectedBillingAccountIDs && state.commitments.length > 0) {
      props.onInteraction({
        type: RampPlanConfigurationSection.INTERACTION_BILLING_ACCOUNT_ID_CHANGED,
        billingAccountIDs: state.selectedBillingAccountIDs,
      });

      props.onInteraction({
        type: RampPlanConfigurationSection.INTERACTION_START_DATE_CHANGED,
        startDate: getStartOfRampPlan(state.commitments),
      });
    }
  }, [state.selectedBillingAccountIDs, getStartOfRampPlan(state.commitments)]);

  useEffect(() => {
    if (props.isLoadingHistoricalCostData || !props.fetchHistoricalCostData)
      return;

    setState((currentState) => {
      // Remove all breakpoints with dates in the past
      const filteredBreakpoints = currentState.breakpoints.filter(
        (breakpoint) => new Date(breakpoint.month) > new Date()
      );

      let startDate = new Date(getStartOfRampPlan(state.commitments));

      const newBreakpoints: BreakpointState[] = [];

      // Create new breakpoints for every month from the start of the ramp plan to the current month
      while (startDate.getTime() < startOfThisMonth.getTime()) {
        const formattedStartDate = formatDate(startDate, "yyyy-MM");

        let enumeratedValuesSpend = {};

        let otherSpend =
          props.historicalCostData?.[formattedStartDate]?.totals.netCost ?? 0;

        const previousBreakpoint = newBreakpoints[newBreakpoints.length - 1];

        if (state.dimensionValues) {
          enumeratedValuesSpend = currentState.dimensionValues.reduce(
            (accum, dimension) => {
              if (props.historicalCostData?.[formattedStartDate]) {
                const dimensionalSpend =
                  props.historicalCostData[formattedStartDate][dimension];

                accum[dimension] = {
                  currency: dimensionalSpend?.netCost ?? 0,
                  percent: previousBreakpoint
                    ? getPercentChange(
                        previousBreakpoint.enumeratedValuesSpend[dimension]
                          .currency,
                        dimensionalSpend?.netCost ?? 0
                      )
                    : 0,
                };

                otherSpend -= dimensionalSpend?.netCost ?? 0;
              } else {
                accum[dimension] = {
                  currency: 0,
                  percent: 0,
                };
              }

              return accum;
            },
            {}
          );
        }

        newBreakpoints.push({
          month: formattedStartDate,
          enumeratedValuesSpend,
          otherSpend: {
            currency: otherSpend,
            percent: previousBreakpoint
              ? getPercentChange(
                  previousBreakpoint.otherSpend.currency,
                  otherSpend
                )
              : 0,
          },
          reason: "",
        });

        startDate = add(new Date(startDate), { months: 1 });
      }

      return {
        ...currentState,
        breakpoints: newBreakpoints.concat(filteredBreakpoints),
      };
    });
  }, [
    props.historicalCostData,
    state.dimensionValues,
    state.selectedBillingAccountIDs,
  ]);

  function handleToggleDimensionality() {
    setState((currentState) => {
      const isDimensional = !currentState.isDimensional;

      const dimensionValues = isDimensional
        ? new Array(DEFAULT_DIMENSION_COUNT).fill("")
        : [];

      const newBreakpoints = state.breakpoints.map((breakpoint) => {
        return {
          ...breakpoint,
          enumeratedValuesSpend: {},
        };
      });

      return {
        ...currentState,
        isDimensional,
        dimensionValues,
        breakpoints: newBreakpoints,
      };
    });
  }

  function handleTogglePopulateHistoricalData(checked: boolean) {
    props.onInteraction({
      type: RampPlanConfigurationSection.INTERACTION_POPULATE_HISTORICAL_SPEND_DATA_TOGGLED,
      billingAccountIDs: state.selectedBillingAccountIDs ?? [],
      checked,
      startDate: getStartOfRampPlan(state.commitments),
    });
  }

  function handleChangeBreakpointType(value: BreakpointType) {
    mergeState({ breakpointType: value });
  }

  function handleCloseConfiguration(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.preventDefault();

    props.onInteraction({
      type: RampPlanConfigurationSection.INTERACTION_BACK_BUTTON_CLICKED,
    });
  }

  function handleSubmitCreate(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.preventDefault();

    const rampPlan = getRampPlanEntityFromState();

    props.onInteraction({
      type: RampPlanConfigurationSection.INTERACTION_CREATE_SUBMIT_BUTTON_CLICKED,
      rampPlan,
    });
  }

  function handleSubmitUpdate(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.preventDefault();
    if (!props.existingRampPlan) return;

    const updatedRampPlan = getRampPlanEntityFromState();

    props.onInteraction({
      type: RampPlanConfigurationSection.INTERACTION_UPDATE_SUBMIT_BUTTON_CLICKED,
      updatedRampPlan,
      existingRampPlan: props.existingRampPlan,
    });
  }

  function getRampPlanEntityFromState(): RampPlan {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const breakpoints: any[] = state.breakpoints.map((breakpoint) => {
      return {
        id: breakpoint.id,
        month: breakpoint.month,
        versions: [
          {
            id: undefined,
            enumeratedValuesSpend: Object.entries(
              breakpoint.enumeratedValuesSpend
            ).reduce(
              (accum, [key, value]) => ({ ...accum, [key]: value.currency }),
              {}
            ),
            otherSpend: breakpoint.otherSpend.currency,
            month: undefined,
            createdAt: undefined,
            createdBy: undefined,
            ...(breakpoint.reason ? { reason: breakpoint.reason } : {}),
            updatedAt: undefined,
            updatedBy: undefined,
          },
        ],
      };
    });

    const { nonExportOffsetByMonth, nonExportOffsetRecurring } =
      getNonExportOffsetsFromState();

    const rampPlan: RampPlan = {
      id: props.existingRampPlan ? props.existingRampPlan.id : "",
      billingAccountIDs: state.selectedBillingAccountIDs || [],
      breakpoints,
      commitments: state.commitments,
      enumeratedValues: state.dimensionValues,
      key: state.isDimensional ? "ternary.app/category" : "",
      name: state.name,
      nonExportOffsetByMonth,
      nonExportOffsetRecurring,
    };

    return rampPlan;
  }

  function getNonExportOffsetsFromState() {
    const nonExportOffsetByMonth = state.nonExportOffsetByMonth.reduce(
      (accum, row) => {
        if (accum[row.month]) {
          accum[row.month][row.reason] = row.amount;
        } else {
          accum[row.month] = { [row.reason]: row.amount };
        }

        return accum;
      },
      {}
    );

    const nonExportOffsetRecurring = state.nonExportOffsetRecurring.reduce(
      (accum, row) => {
        accum[row.reason] = row.amount;
        return accum;
      },
      {}
    );

    return { nonExportOffsetByMonth, nonExportOffsetRecurring };
  }

  const billingAccountIdOptions: Option[] = useMemo(() => {
    if (props.billingAccounts.length === 0) return [];

    return props.billingAccounts.map((id) => {
      return {
        label: `[${id.cloudName}] ${id.billingAccountID} `,
        value: id.billingAccountID.toString(),
      };
    });
  }, [props.billingAccounts]);

  function handleToggleNonExportOffsetModal(e) {
    e.preventDefault();
    setState((st) => ({
      ...st,
      isOpenNonExportOffsetModal: !st.isOpenNonExportOffsetModal,
    }));
  }

  let canSubmit = true;

  if (props.existingRampPlan) {
    const { hasAnythingChanged } = getDeltaBetweenRampPlans(
      props.existingRampPlan,
      getRampPlanEntityFromState()
    );

    canSubmit = hasAnythingChanged && state.name.trim() !== "";
  } else {
    canSubmit = state.name.trim() !== "";
  }

  const totalOffsetsLength =
    state.nonExportOffsetByMonth.length + state.nonExportOffsetRecurring.length;

  const breakpointOptions = [
    {
      label: copyText.configurationSectionOptionActuals,
      value: BreakpointType.ACTUALS,
    },
    {
      label: copyText.configurationSectionOptionPercent,
      value: BreakpointType.PERCENT,
    },
  ];

  return (
    <Flex
      height="100%"
      justifyContent="center"
      width="100%"
      marginHorizontal={theme.space_sm}
    >
      <Modal
        isOpen={state.isOpenNonExportOffsetModal}
        showCloseButton
        onClose={handleToggleNonExportOffsetModal}
      >
        <Modal.Header>
          <Box width="50rem">
            <Text
              as="h4"
              fontSize={theme.h4_fontSize}
              fontWeight={theme.h4_fontWeight}
            >
              {copyText.configurationSectionOffsetModalTitle}
            </Text>
          </Box>
        </Modal.Header>
        <Modal.Body>
          <Box width="50rem">
            <Text marginBottom={theme.space_lg}>
              {copyText.configurationSectionOffsetModalInstructions}
            </Text>
            <Flex>
              <Box width="45%">
                <Text
                  as="h4"
                  fontSize={theme.h4_fontSize}
                  fontWeight={theme.h4_fontWeight}
                >
                  {copyText.configurationSectionOffsetModalReucurring}
                  {renderOffsetControlsRecurring()}
                </Text>
              </Box>
              <Box width="55%">
                <Text
                  as="h4"
                  fontSize={theme.h4_fontSize}
                  fontWeight={theme.h4_fontWeight}
                >
                  {copyText.configurationSectionOffsetModalMonthly}
                  {renderOffsetControlsByMonth()}
                </Text>
              </Box>
            </Flex>
          </Box>
        </Modal.Body>
      </Modal>
      <Box width={"7rem"}>
        <Button
          iconStart={<FontAwesomeIcon icon={faAngleLeft} />}
          size="tiny"
          onClick={handleCloseConfiguration}
        >
          {copyText.backButtonLabel}
        </Button>
      </Box>
      <Box maxWidth="75rem" width={`calc(100% - ${"4rem"})`} marginRight="4rem">
        <Form
          width="100%"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Flex width="95%" justifyContent="space-between">
            <Text
              as="h1"
              fontSize={theme.h1_fontSize}
              fontWeight={theme.h1_fontWeight}
              lineHeight="unset"
              marginBottom={theme.space_lg}
            >
              {renderTitleText(props.isCopy, props.existingRampPlan)}
            </Text>
          </Flex>
          {renderHeader(copyText.configurationSectionHeadingGeneral, theme)}
          <Box marginRight={theme.space_sm} width={"20rem"}>
            <FormField label={copyText.configurationSectionLabelBillingAccount}>
              <SelectCheckbox
                isLoading={props.isLoadingBillingAccounts}
                options={billingAccountIdOptions}
                selectedValues={state.selectedBillingAccountIDs ?? []}
                onChange={(option) => {
                  if (!option) return;

                  mergeState({
                    selectedBillingAccountIDs: [
                      ...(state.selectedBillingAccountIDs ? [...option] : []),
                    ],
                  });
                }}
              />
            </FormField>
          </Box>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            paddingBottom={`-${theme.space_md}`}
          >
            <Box marginRight={theme.space_sm} width={"14rem"}>
              <FormField label={copyText.configurationSectionLabelName}>
                <TextInput
                  placeholder={copyText.configurationSectionPlaceholderName}
                  size="small"
                  value={state.name}
                  onChange={(e) => mergeState({ name: e.target.value })}
                />
              </FormField>
            </Box>
            <Button
              iconStart={<FontAwesomeIcon icon={faCog} size="sm" />}
              marginRight={theme.space_sm}
              primary={totalOffsetsLength > 0}
              size="small"
              type="button"
              onClick={handleToggleNonExportOffsetModal}
            >
              {copyText.configurationSectionOffsetsButton}
              {totalOffsetsLength > 0 ? ` (${totalOffsetsLength})` : ""}
            </Button>

            <Flex alignItems="center">
              <Text
                align="right"
                marginRight={theme.space_sm}
                fontWeight={theme.fontWeight_bold}
                fontSize={theme.h6_fontSize}
              >
                {copyText.configurationSectionOffsetsMultiDimensional}
              </Text>
              <Switch
                checked={state.isDimensional}
                onChange={handleToggleDimensionality}
              />
            </Flex>
          </Flex>
          {state.isDimensional && renderDimensionControls()}
          <Divider direction="horizontal" />
          {renderHeader(copyText.configurationSectionHeadingCommitments, theme)}
          {renderCommitmentPeriodControls(theme)}
          <Divider direction="horizontal" />
          {renderHeader(copyText.configurationSectionHeadingBreakpoints, theme)}
          <Text marginBottom={theme.space_sm}>
            {copyText.configurationSectionExplanationBreakpoints}
          </Text>
          <Flex alignItems="center">
            <Box
              width="150px"
              marginVertical={theme.space_md}
              marginRight={theme.space_md}
            >
              <Select
                menuPlacement="bottom"
                options={breakpointOptions}
                value={breakpointOptions.find(
                  (option) => option.value === state.breakpointType
                )}
                onChange={(option) =>
                  option && handleChangeBreakpointType(option.value)
                }
              />
            </Box>
            {/* only allow this option during creation */}
            {!props.existingRampPlan && (
              <Box width="300px" marginVertical={theme.space_md}>
                <Flex alignItems="center">
                  <Text bold marginRight={theme.space_sm}>
                    {copyText.configurationSectionPopulateHistoricalDataLabel}
                  </Text>
                  <Box marginRight={theme.space_sm}>
                    <Switch
                      checked={props.fetchHistoricalCostData}
                      disabled={
                        !!props.existingRampPlan ||
                        props.isLoadingBillingAccounts ||
                        props.isLoadingHistoricalCostData
                      }
                      onChange={(checked) =>
                        handleTogglePopulateHistoricalData(checked)
                      }
                    />
                  </Box>
                  {(props.isLoadingBillingAccounts ||
                    props.isLoadingHistoricalCostData) && <LoadingSpinner />}
                </Flex>
              </Box>
            )}
          </Flex>
          {renderBreakpointControls()}
          <Box padding={theme.space_xs} />
          <RampPlanChartSection
            actualSpend={props.actualSpend}
            actualSpendDimensional={[]}
            hideDimensionalAnalysis={true}
            loading={props.loadingActuals}
            hideTopMeter={true}
            rampPlan={{
              id: uuidv4(),
              breakpoints: state.breakpoints.map((breakpointState) => ({
                id: breakpointState.id || uuidv4(),
                defunct: breakpointState.defunct ?? false,
                month: breakpointState.month,
                versions: [
                  {
                    id: uuidv4(),
                    enumeratedValuesSpend: Object.entries(
                      breakpointState.enumeratedValuesSpend
                    ).reduce(
                      (accum, [key, value]) => ({
                        ...accum,
                        [key]: value.currency,
                      }),
                      {}
                    ),
                    month: breakpointState.month,
                    otherSpend: breakpointState.otherSpend.currency,
                    createdAt: "",
                    createdBy: "",
                    reason: breakpointState.reason,
                    updatedAt: "",
                    updatedBy: "",
                  },
                ],
              })),
              commitments: state.commitments,
              billingAccountIDs: state.selectedBillingAccountIDs ?? [],
              enumeratedValues: state.dimensionValues,
              key: "",
              name: state.name,
              nonExportOffsetByMonth:
                getNonExportOffsetsFromState().nonExportOffsetByMonth,
              nonExportOffsetRecurring:
                getNonExportOffsetsFromState().nonExportOffsetRecurring,
            }}
            selectedMonthForDimensionalChart={
              props.selectedMonthForDimensionalChart
            }
            onInteraction={props.onInteraction}
          />
          <Flex justifyContent="flex-end" marginTop={theme.space_xl}>
            <Button
              disabled={
                props.loadingMutation ||
                props.isLoadingHistoricalCostData ||
                !canSubmit
              }
              primary
              size="large"
              width={100}
              onClick={
                props.existingRampPlan && !props.isCopy
                  ? handleSubmitUpdate
                  : handleSubmitCreate
              }
            >
              {props.loadingMutation
                ? copyText.loadingLabel
                : copyText.submitButtonLabel}
            </Button>
          </Flex>
        </Form>
      </Box>
    </Flex>
  );

  function renderDimensionControls() {
    function handleSelectCategoryCount(countOption) {
      const selectedCount = Number(countOption.value);

      let newValues: string[] = [];
      let oldValues: string[] = [];
      if (selectedCount > state.dimensionValues.length) {
        newValues = [
          ...state.dimensionValues,
          ...new Array(selectedCount - state.dimensionValues.length).fill(""),
        ];
      } else {
        oldValues = state.dimensionValues.slice(selectedCount);
        newValues = state.dimensionValues.slice(0, selectedCount);
      }

      let updatedBreakpoints = [...state.breakpoints];
      if (oldValues.length > 0) {
        updatedBreakpoints = updatedBreakpoints.map((breakpoint) => {
          oldValues.forEach((v) => delete breakpoint.enumeratedValuesSpend[v]);
          return breakpoint;
        });
      }

      mergeState({
        dimensionValues: newValues,
        breakpoints: updatedBreakpoints,
      });
    }

    function handleSelectCategory(categoryOption: Option, index: number) {
      const newArr = [...state.dimensionValues];

      if (!categoryOption || typeof categoryOption.value !== "string") return;

      newArr[index] = categoryOption.value;

      const previous = state.dimensionValues[index];

      const updatedBreakpoints = state.breakpoints.map((breakpoint) => {
        if (!categoryOption || typeof categoryOption.value !== "string") {
          throw new Error("INVALID_SELECT_OPTION");
        }

        const previousAmount = breakpoint[previous];

        const newEnumeratedValuesSpend = Object.entries(
          breakpoint.enumeratedValuesSpend
        ).reduce((accum, [key, value]) => {
          if (!newArr.includes(key)) {
            return accum;
          }
          return {
            ...accum,
            [key]: value,
          };
        }, {});

        const newBreakpoint = {
          ...breakpoint,
          enumeratedValuesSpend: {
            ...newEnumeratedValuesSpend,
            [categoryOption.value]: {
              currency: previousAmount ?? 0,
              percent: 0,
            },
          },
        };
        delete newBreakpoint[previous];

        return newBreakpoint;
      });

      mergeState({ breakpoints: updatedBreakpoints, dimensionValues: newArr });
    }

    const availableOptions = DIMENSION_VALUE_OPTIONS.filter((option) => {
      if (!option || typeof option.value !== "string") return false;

      return !state.dimensionValues.includes(option.value);
    });

    return (
      <>
        <Flex alignItems="flex-end" flexWrap="wrap">
          <Box width="5rem">
            <FormField
              label={copyText.configurationSectionLabelCount}
              marginBottom="0px"
            >
              <Select
                compact
                options={["1", "2", "3", "4", "5", "6", "7"].map((value) => ({
                  label: value,
                  value,
                }))}
                value={[
                  {
                    label: state.dimensionValues.length.toString(),
                    value: state.dimensionValues.length.toString(),
                  },
                ]}
                onChange={handleSelectCategoryCount}
              />
            </FormField>
          </Box>
          {state.dimensionValues.map((dimensionValue, i) => {
            return (
              <Box key={i} marginLeft={theme.space_xs} width="7rem">
                <Select
                  compact
                  searchable
                  options={availableOptions}
                  placeholder={`${
                    copyText.configurationSectionPlaceholderDimension
                  } ${i + 1}`}
                  value={[{ label: dimensionValue, value: dimensionValue }]}
                  onChange={(option) => {
                    if (!option) return;

                    handleSelectCategory(option, i);
                  }}
                />
              </Box>
            );
          })}
          <Box marginLeft={theme.space_xs} width="6rem">
            <Select
              compact
              options={[
                {
                  label: copyText.otherLabel,
                  value: copyText.otherLabel,
                },
              ]}
              value={[
                {
                  label: copyText.otherLabel,
                  value: copyText.otherLabel,
                },
              ]}
              onChange={noop}
            />
          </Box>
        </Flex>
      </>
    );
  }

  function renderBreakpointControls() {
    function handleUpdateBreakpoint(updated: BreakpointState, breakpointIndex) {
      setState((currentState) => {
        const updatedBreakpoints = [
          ...currentState.breakpoints.slice(0, breakpointIndex),
          updated,
          ...currentState.breakpoints.slice(breakpointIndex + 1),
        ];

        const newBreakpoints = updatedBreakpoints.reduce(
          (accum: BreakpointState[], currentBreakpoint, index) => {
            // skip the breakpoint that was actually updated
            if (breakpointIndex === index) {
              return [...accum, currentBreakpoint];
            }

            const previousBreakpoint = accum[index - 1]
              ? accum[index - 1]
              : undefined;

            if (!previousBreakpoint?.otherSpend.currency) {
              return [
                ...accum,
                {
                  ...currentBreakpoint,
                  otherSpend: {
                    currency: currentBreakpoint.otherSpend.currency,
                    percent: 0,
                  },
                },
              ];
            }

            let newOtherSpendCurrency = currentBreakpoint.otherSpend.currency;
            // keep the percent of the current row and update dollar amount
            if (currentState.breakpointType === BreakpointType.PERCENT) {
              newOtherSpendCurrency = previousBreakpoint
                ? getAbsoluteAmount(
                    previousBreakpoint.otherSpend.currency,
                    currentBreakpoint.otherSpend.percent
                  )
                : 0;
            }

            const newEnumeratedValuesSpend = Object.entries(
              currentBreakpoint.enumeratedValuesSpend
            ).reduce((accum, [key, value]) => {
              let newCurrency = value.currency;

              if (currentState.breakpointType === BreakpointType.PERCENT) {
                newCurrency = previousBreakpoint
                  ? getAbsoluteAmount(
                      previousBreakpoint.enumeratedValuesSpend[key].currency,
                      value.percent
                    )
                  : 0;
              }

              return {
                ...accum,
                [key]: {
                  currency: newCurrency,
                  percent: value.percent,
                },
              };
            }, {});

            return [
              ...accum,
              {
                ...currentBreakpoint,
                enumeratedValuesSpend: newEnumeratedValuesSpend,
                otherSpend: {
                  currency: newOtherSpendCurrency,
                  percent: currentBreakpoint.otherSpend.percent,
                },
              },
            ];
          },
          []
        );
        return {
          ...currentState,
          breakpoints: newBreakpoints,
        };
      });
    }

    function handleDeleteBreakpoint(index: number) {
      setState((currentState) => {
        const updatedBreakpoints = state.breakpoints.filter(
          (_, i) => i !== index
        );

        const newBreakpoints = updatedBreakpoints.reduce(
          (accum: BreakpointState[], currentBreakpoint, index) => {
            const previousBreakpoint = accum[index - 1]
              ? accum[index - 1]
              : undefined;

            if (!previousBreakpoint?.otherSpend.currency) {
              return [
                ...accum,
                {
                  ...currentBreakpoint,
                  otherSpend: {
                    currency: currentBreakpoint.otherSpend.currency,
                    percent: 0,
                  },
                },
              ];
            }

            let newOtherSpendCurrency = currentBreakpoint.otherSpend.currency;

            // keep the percent of the current row and update dollar amount
            if (currentState.breakpointType === BreakpointType.PERCENT) {
              newOtherSpendCurrency = previousBreakpoint
                ? getAbsoluteAmount(
                    previousBreakpoint.otherSpend.currency,
                    currentBreakpoint.otherSpend.percent
                  )
                : 0;
            }

            const newEnumeratedValuesSpend = Object.entries(
              currentBreakpoint.enumeratedValuesSpend
            ).reduce((accum, [key, value]) => {
              let newCurrency = value.currency;

              if (currentState.breakpointType === BreakpointType.PERCENT) {
                newCurrency = previousBreakpoint
                  ? getAbsoluteAmount(
                      previousBreakpoint.enumeratedValuesSpend[key].currency,
                      value.percent
                    )
                  : 0;
              }

              return {
                ...accum,
                [key]: {
                  currency: newCurrency,
                  percent: value.percent,
                },
              };
            }, {});

            return [
              ...accum,
              {
                ...currentBreakpoint,
                enumeratedValuesSpend: newEnumeratedValuesSpend,
                otherSpend: {
                  currency: newOtherSpendCurrency,
                  percent: currentBreakpoint.otherSpend.percent,
                },
              },
            ];
          },
          []
        );
        return {
          ...currentState,
          breakpoints: newBreakpoints,
        };
      });
    }

    function handlePrependBreakpoint(index: number) {
      setState((currentState) => {
        const beforeMonth = currentState.breakpoints[index - 1].month;
        const afterMonth = currentState.breakpoints[index].month;

        const differenceInMonths = differenceInCalendarMonths(
          new Date(beforeMonth),
          new Date(afterMonth)
        );

        const [afterMonthYear, afterMonthMonth] = afterMonth
          .split("-")
          .map(Number);

        const newMonth = formatDate(
          sub(new Date(afterMonthYear, afterMonthMonth).setDate(0), {
            months: Math.abs(Math.round(differenceInMonths / 2)),
          }),
          "yyyy-MM"
        );

        const newBreakpoint = {
          ...currentState.breakpoints[index],
          enumeratedValuesSpend: Object.entries(
            currentState.breakpoints[index - 1].enumeratedValuesSpend
          ).reduce(
            (accum, [key, value]) => ({
              ...accum,
              [key]: { currency: value.currency, percent: 0 },
            }),
            {}
          ),
          month: newMonth,
          otherSpend: {
            percent: 0,
            currency: currentState.breakpoints[index - 1].otherSpend.currency,
          },
          reason: "",
        };
        delete newBreakpoint.id;

        const newBreakpoints = [
          ...currentState.breakpoints,
          newBreakpoint,
        ].sort((a, b) => {
          if (isBefore(new Date(b.month), new Date(a.month))) {
            return 1;
          }
          return -1;
        });

        return {
          ...currentState,
          breakpoints: newBreakpoints,
        };
      });
    }

    return (
      <table>
        <thead>
          <tr>
            <th align="left">
              <Text>{copyText.configurationSectionLabelMonth}</Text>
            </th>
            <th align="left">
              <Text>{copyText.configurationSectionLabelReason}</Text>
            </th>
            {!state.isDimensional ? (
              <th align="left">
                <Text>
                  {state.breakpointType === BreakpointType.ACTUALS
                    ? copyText.configurationSectionLabelTotalSpend
                    : copyText.configurationSectionLabelPercentGrowth}
                </Text>
              </th>
            ) : (
              <>
                {state.dimensionValues.map((dv) => {
                  if (dv === "") return;

                  return (
                    <th align="left" key={Math.random()}>
                      <Text>{dv}:</Text>
                    </th>
                  );
                })}
                <th align="left">
                  <Text>{copyText.otherLabel}:</Text>
                </th>
              </>
            )}
            <th align="left">
              {(state.isDimensional ||
                state.breakpointType === BreakpointType.PERCENT) && (
                <Text>{copyText.configurationSectionLabelPercentRowTotal}</Text>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {state.breakpoints.map((currentBreakpoint, i) => {
            const isFirst = i === 0;
            const isLast = i === state.breakpoints.length - 1;
            let previousMonthString: string | undefined;
            let nextMonthString: string | undefined;
            if (!isFirst) {
              previousMonthString = state.breakpoints[i - 1].month;
            }
            if (!isLast) {
              nextMonthString = state.breakpoints[i + 1].month;
            }

            const currentRowTotal = Object.values(
              currentBreakpoint.enumeratedValuesSpend
            ).reduce(
              (accum, value) => accum + value.currency,
              currentBreakpoint.otherSpend.currency
            );

            const isHistoricalBreakpoint =
              !props.existingRampPlan &&
              props.fetchHistoricalCostData &&
              new Date(currentBreakpoint.month).getTime() <
                startOfThisMonth.getTime();

            return [
              i !== 0 && (
                <tr key={0}>
                  {new Array(state.dimensionValues.length + 3)
                    .fill("")
                    .map(() => (
                      <td key={Math.random()} />
                    ))}
                  <td />
                  <td />
                  <td>
                    {renderAddButton(
                      () => handlePrependBreakpoint(i),
                      theme.size_tiny,
                      i,
                      state.breakpoints
                    )}
                  </td>
                </tr>
              ),
              renderBreakpointRow({
                breakpoint: currentBreakpoint,
                breakpointIndex: i,
                breakpointType: state.breakpointType,
                dimensionValues: state.dimensionValues,
                isHistoricalBreakpoint: isHistoricalBreakpoint,
                isDimensional: state.isDimensional,
                isFirst,
                isLast,
                nextMonthString,
                previousBreakpoint: state.breakpoints[i - 1],
                previousMonthString,
                rowTotal: Math.round(currentRowTotal),
                theme,
                onDelete: () => handleDeleteBreakpoint(i),
                onUpdate: (updated: BreakpointState) =>
                  handleUpdateBreakpoint(updated, i),
              }),
            ];
          })}
        </tbody>
      </table>
    );
  }

  function renderCommitmentPeriodControls(theme: Theme) {
    function handleUpdate(updated: CommitmentPeriod) {
      const updatedStartDate = new Date(updated.start);
      const updatedEndDate = new Date(updated.end);

      if (!isBefore(updatedStartDate, updatedEndDate)) {
        return;
      }

      const newCommitments = [...state.commitments];
      const index = newCommitments.findIndex((c) => c._id === updated._id);
      newCommitments[index] = updated;

      // update adjacent commitments if present
      const didChangeStart = state.commitments[index].start !== updated.start;
      const didChangeEnd = state.commitments[index].end !== updated.end;

      if (didChangeStart && index > 0) {
        newCommitments[index - 1].end = sub(updatedStartDate, {
          days: 1,
        }).toISOString();
      }
      if (didChangeEnd && index + 1 < newCommitments.length) {
        newCommitments[index + 1].start = add(updatedEndDate, {
          days: 1,
        }).toISOString();
      }

      // consider impact on full ramp plan
      const oldStart = getStartOfRampPlan(state.commitments);
      const newStart = getStartOfRampPlan(newCommitments);
      const newEnd = getEndOfRampPlan(newCommitments);

      // set older start date in the parent to get updated actuals
      if (isBefore(new Date(newStart), new Date(oldStart))) {
        props.onInteraction({
          type: RampPlanConfigurationSection.INTERACTION_START_DATE_CHANGED,
          startDate: newStart,
        });
      }

      const updatedBreakpoints = [...state.breakpoints];

      updatedBreakpoints[0] = {
        ...state.breakpoints[0],
        month: getUTCMonthString(newStart),
      };

      updatedBreakpoints[updatedBreakpoints.length - 1] = {
        ...state.breakpoints[updatedBreakpoints.length - 1],
        month: getUTCMonthString(newEnd),
      };

      mergeState({
        commitments: newCommitments,
        breakpoints: updatedBreakpoints,
      });
    }

    function handleAppend() {
      const newCommitments = [...state.commitments];
      const previous = newCommitments[newCommitments.length - 1];

      const newStart = add(new Date(previous.end), { days: 1 });
      const newEnd = sub(add(newStart, { months: 12 }), {
        days: 1,
      }).toISOString();

      newCommitments.push({
        _id: uuidv4(),
        amount: 0,
        end: newEnd,
        start: newStart.toISOString(),
      });

      const updatedBreakpoints = state.breakpoints;
      updatedBreakpoints[updatedBreakpoints.length - 1] = {
        ...updatedBreakpoints[updatedBreakpoints.length - 1],
        month: formatDate(new Date(newEnd), "yyyy-MM"),
      };

      mergeState({
        breakpoints: updatedBreakpoints,
        commitments: newCommitments,
      });
    }

    function handleDelete(id) {
      const index = state.commitments.findIndex((c) => c._id === id);
      const isEnd = index === state.commitments.length - 1;
      const isStart = index === 0;

      const updatedBreakpoints = [...state.breakpoints];
      if (isStart) {
        updatedBreakpoints[0].month = getUTCMonthString(
          state.commitments[1].start
        );
      }
      if (isEnd) {
        updatedBreakpoints[updatedBreakpoints.length - 1].month =
          getUTCMonthString(
            state.commitments[state.commitments.length - 2].end
          );
      }

      mergeState({
        commitments: state.commitments.filter((c) => c._id !== id),
        ...(isEnd || isStart ? { breakpoints: updatedBreakpoints } : {}),
      });
    }

    return (
      <table>
        <thead>
          <tr>
            <th align="left">{copyText.configurationSectionLabelStartDate}</th>
            <th align="left">{copyText.configurationSectionLabelEndDate}</th>
            <th align="left">{copyText.configurationSectionLabelAmount}</th>
          </tr>
        </thead>
        <tbody>
          {state.commitments.map((commitment, i) => {
            let maxDate: Date | undefined = undefined;
            let minDate: Date | undefined = undefined;

            // Must leave at least a day for the commitment periods around it
            if (i > 0) {
              minDate = add(new Date(state.commitments[i - 1].start), {
                days: 1,
              });
            }
            if (i !== state.commitments.length - 1) {
              maxDate = sub(new Date(state.commitments[i + 1].end), {
                days: 1,
              });
            }

            return renderCommitmentRow({
              allowedDateRange: [minDate, maxDate],
              canDelete: state.commitments.length !== 1,
              commitment,
              theme,
              onDelete: handleDelete,
              onUpdate: handleUpdate,
            });
          })}
          {
            <tr>
              <td />
              <td />
              <td />
              <td>{renderAddButton(handleAppend, "1.5rem")}</td>
            </tr>
          }
        </tbody>
      </table>
    );
  }

  function renderOffsetControlsRecurring() {
    function handleAppend() {
      setState((st) => ({
        ...st,
        nonExportOffsetRecurring: [
          ...st.nonExportOffsetRecurring,
          {
            reason: copyText.configurationSectionPlaceholderEnterReason,
            amount: 0,
          },
        ],
      }));
    }

    function handleDelete(index: number) {
      setState((st) => ({
        ...st,
        nonExportOffsetRecurring: st.nonExportOffsetRecurring.filter(
          (_, i) => i !== index
        ),
      }));
    }

    function handleChangeReason(index: number, text: string) {
      const alreadyExists = state.nonExportOffsetRecurring.find(
        (offsetEntity) => offsetEntity.reason === text
      );
      if (alreadyExists) return;

      setState((st) => {
        const newList = [...st.nonExportOffsetRecurring];
        newList[index].reason = text;

        return {
          ...st,
          nonExportOffsetRecurring: newList,
        };
      });
    }

    function handleChangeAmount(index: number, amount: number) {
      setState((st) => {
        const newList = [...st.nonExportOffsetRecurring];
        newList[index].amount = amount;

        return {
          ...st,
          nonExportOffsetRecurring: newList,
        };
      });
    }

    return (
      <Box width={"85%"} marginBottom={theme.space_xl}>
        <table width="100%">
          <thead>
            {state.nonExportOffsetRecurring.length > 0 && (
              <tr>
                <th align="left">{copyText.configurationSectionLabelReason}</th>
                <th align="left">{copyText.configurationSectionLabelAmount}</th>
                <th />
              </tr>
            )}
          </thead>
          <tbody>
            {state.nonExportOffsetRecurring.map((row, index) => (
              <tr key={index}>
                <td>
                  <TextInput
                    size="small"
                    value={row.reason}
                    onChange={(e) => handleChangeReason(index, e.target.value)}
                  />
                </td>
                <td>
                  <NumberInput
                    formatter="currency-rounded"
                    size="small"
                    value={row.amount}
                    onChange={(value) => handleChangeAmount(index, value)}
                  />
                </td>

                <td>
                  <Button
                    size="tiny"
                    type="button"
                    secondary
                    onClick={(e) => {
                      e.preventDefault();
                      handleDelete(index);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </td>
              </tr>
            ))}
            <tr>
              <td />
              <td />
              <td>
                <Box marginTop={theme.space_md}>
                  {renderAddButton(handleAppend)}
                </Box>
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    );
  }

  function renderOffsetControlsByMonth(): JSX.Element {
    function handleAppend() {
      setState((st) => ({
        ...st,
        nonExportOffsetByMonth: [
          ...st.nonExportOffsetByMonth,
          {
            reason: copyText.configurationSectionPlaceholderEnterReason,
            amount: 0,
            month: `${getYear(new Date())}-01`,
          },
        ],
      }));
    }

    function handleDelete(index: number) {
      setState((st) => ({
        ...st,
        nonExportOffsetByMonth: st.nonExportOffsetByMonth.filter(
          (_, i) => i !== index
        ),
      }));
    }

    function handleChangeReason(index: number, text: string) {
      const month = state.nonExportOffsetByMonth[index].month;
      const alreadyExists = state.nonExportOffsetByMonth.find(
        (offsetEntity) =>
          offsetEntity.reason === text && offsetEntity.month === month
      );
      if (alreadyExists) return;

      setState((st) => {
        const newList = [...st.nonExportOffsetByMonth];
        newList[index].reason = text;

        return {
          ...st,
          nonExportOffsetByMonth: newList,
        };
      });
    }

    function handleChangeAmount(index: number, amount: number) {
      setState((st) => {
        const newList = [...st.nonExportOffsetByMonth];
        newList[index].amount = amount;

        return {
          ...st,
          nonExportOffsetByMonth: newList,
        };
      });
    }

    function handleChangeDate(index: number, d: Date) {
      setState((st) => {
        const newList = [...st.nonExportOffsetByMonth];
        newList[index].month = getUTCMonthString(d.toISOString());

        return {
          ...st,
          nonExportOffsetByMonth: newList,
        };
      });
    }

    return (
      <Box width={"100%"} marginBottom={theme.space_xl}>
        <table width="100%">
          <thead>
            {state.nonExportOffsetByMonth.length > 0 && (
              <tr>
                {
                  <th align="left">
                    {copyText.configurationSectionLabelMonth}
                  </th>
                }
                <th align="left">{copyText.configurationSectionLabelReason}</th>
                <th align="left">{copyText.configurationSectionLabelAmount}</th>
                <th />
              </tr>
            )}
          </thead>
          <tbody>
            {state.nonExportOffsetByMonth.map((row, index) => (
              <tr key={index}>
                <td>
                  <DatePicker
                    dateFormat={"MM/yyyy"}
                    selected={toZonedTime(new Date(row.month), "UTC")}
                    showMonthYearPicker
                    shouldCloseOnSelect={false}
                    size="small"
                    onChange={(d) => {
                      if (d instanceof Date) {
                        handleChangeDate(index, d);
                      }
                    }}
                  />
                </td>
                <td>
                  <TextInput
                    size="small"
                    value={row.reason}
                    onChange={(e) => handleChangeReason(index, e.target.value)}
                  />
                </td>
                <td>
                  <NumberInput
                    formatter="currency-rounded"
                    size="small"
                    value={row.amount}
                    onChange={(value) => handleChangeAmount(index, value)}
                  />
                </td>

                <td>
                  <Button
                    size="tiny"
                    type="button"
                    secondary
                    onClick={(e) => {
                      e.preventDefault();
                      handleDelete(index);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </td>
              </tr>
            ))}
            <tr>
              <td />
              <td />
              <td />
              <td>
                <Box marginTop={theme.space_md}>
                  {renderAddButton(handleAppend)}
                </Box>
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    );
  }
}

function renderHeader(text: string, theme: Theme) {
  return (
    <Text
      as="h4"
      fontSize={theme.h4_fontSize}
      fontWeight={theme.h4_fontWeight}
      marginBottom={theme.space_md}
    >
      {text}:
    </Text>
  );
}

function renderTitleText(
  isCopy: boolean,
  isExistingPlan: RampPlan | undefined
): string {
  if (isCopy) {
    return copyText.configurationSectionTitleCreateCopy;
  }

  if (isExistingPlan) {
    return copyText.configurationSectionTitleEdit;
  } else {
    return copyText.configurationSectionTitleCreate;
  }
}

function renderCommitmentRow(params: {
  allowedDateRange: [Date | undefined, Date | undefined];
  canDelete: boolean;
  commitment: CommitmentPeriod;
  theme: Theme;
  onDelete: (id: string) => void;
  onUpdate: (commitment: CommitmentPeriod) => void;
}) {
  return (
    <tr key={params.commitment._id}>
      <td>
        <Box marginRight={params.theme.space_xs}>
          <DatePicker
            dateFormat={"MM/dd/yyyy"}
            selected={new Date(params.commitment.start)}
            maxDate={params.allowedDateRange[1]}
            minDate={params.allowedDateRange[0]}
            size="small"
            onChange={(d) =>
              d instanceof Date &&
              params.onUpdate({ ...params.commitment, start: d.toISOString() })
            }
          />
        </Box>
      </td>
      <td>
        <Box marginRight={params.theme.space_xs}>
          <DatePicker
            dateFormat={"MM/dd/yyyy"}
            selected={new Date(params.commitment.end)}
            maxDate={params.allowedDateRange[1]}
            minDate={params.allowedDateRange[0]}
            size="small"
            onChange={(d) =>
              d instanceof Date &&
              params.onUpdate({ ...params.commitment, end: d.toISOString() })
            }
          />
        </Box>
      </td>
      <td>
        <Box marginRight={params.theme.space_xs}>
          <NumberInput
            formatter="currency-rounded"
            size="small"
            value={params.commitment.amount}
            onChange={(value) =>
              params.onUpdate({
                ...params.commitment,
                amount: value,
              })
            }
          />
        </Box>
      </td>
      <td>
        {params.canDelete && (
          <Button
            size="tiny"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              params.onDelete(params.commitment._id);
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        )}
      </td>
    </tr>
  );
}

function renderBreakpointRow(params: {
  breakpoint: BreakpointState;
  breakpointIndex: number;
  dimensionValues: string[];
  isHistoricalBreakpoint: boolean;
  isDimensional: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  nextMonthString?: string;
  previousBreakpoint?: BreakpointState;
  previousMonthString?: string;
  breakpointType: BreakpointType;
  rowTotal: number;
  theme: Theme;
  onDelete: () => void;
  onUpdate: (updated: BreakpointState) => void;
}) {
  const minDate = params.previousMonthString
    ? add(new Date(getUTCMonthString(params.previousMonthString)), {
        months: 1,
      })
    : undefined;

  const maxDate = params.nextMonthString
    ? sub(new Date(getUTCMonthString(params.nextMonthString)), { months: 1 })
    : undefined;

  function handleChangeDate(d) {
    params.onUpdate({
      ...params.breakpoint,
      month: formatDate(d, "yyyy-MM"),
    });
  }

  return (
    <tr key={`${params.breakpoint.month} - ${params.breakpointIndex}`}>
      <td>
        <Box marginRight={params.theme.space_xs}>
          <DatePicker
            disabled={
              params.isFirst || params.isLast || params.isHistoricalBreakpoint
            }
            dateFormat={"MM/yyyy"}
            selected={toZonedTime(new Date(params.breakpoint.month), "UTC")}
            maxDate={maxDate}
            minDate={minDate}
            showMonthYearPicker
            size="small"
            onChange={handleChangeDate}
          />
        </Box>
      </td>
      <td>
        <Flex marginRight={params.theme.space_xs}>
          <TextInput
            disabled={params.isHistoricalBreakpoint}
            size="small"
            value={params.breakpoint.reason ?? ""}
            onChange={(event) => {
              params.onUpdate({
                ...params.breakpoint,
                reason: event.target.value,
              });
            }}
          />
        </Flex>
      </td>
      {params.isDimensional &&
        params.dimensionValues.map((dimensionValue) => {
          if (dimensionValue.length < 1) return;
          const renderCurrency =
            params.breakpointIndex === 0 ||
            params.breakpointType === BreakpointType.ACTUALS ||
            params.previousBreakpoint?.enumeratedValuesSpend[dimensionValue]
              .currency === 0;

          return (
            <td key={dimensionValue}>
              <Box marginRight={params.theme.space_xs}>
                {renderCurrency ? (
                  <NumberInput
                    disabled={params.isHistoricalBreakpoint}
                    formatter="currency-rounded"
                    size="small"
                    value={
                      params.breakpoint.enumeratedValuesSpend[dimensionValue]
                        .currency
                    }
                    onChange={(value) => {
                      const calculatedPercentage = params.previousBreakpoint
                        ? getPercentChange(
                            params.previousBreakpoint.enumeratedValuesSpend[
                              dimensionValue
                            ].currency,
                            value
                          )
                        : 0;

                      params.onUpdate({
                        ...params.breakpoint,
                        enumeratedValuesSpend: {
                          ...params.breakpoint.enumeratedValuesSpend,
                          [dimensionValue]: {
                            currency: value,
                            percent: calculatedPercentage,
                          },
                        },
                      });
                    }}
                  />
                ) : (
                  <NumberInput
                    disabled={params.isHistoricalBreakpoint}
                    formatter="percent-divided"
                    size="small"
                    value={
                      params.breakpoint.enumeratedValuesSpend[dimensionValue]
                        .percent
                    }
                    onChange={(value) => {
                      const calculatedCurrency = params.previousBreakpoint
                        ? getAbsoluteAmount(
                            params.previousBreakpoint.enumeratedValuesSpend[
                              dimensionValue
                            ].currency,
                            value
                          )
                        : 0;

                      params.onUpdate({
                        ...params.breakpoint,
                        enumeratedValuesSpend: {
                          ...params.breakpoint.enumeratedValuesSpend,
                          [dimensionValue]: {
                            currency: calculatedCurrency,
                            percent: value,
                          },
                        },
                      });
                    }}
                  />
                )}
              </Box>
            </td>
          );
        })}
      <td>
        {/* Other Spend */}
        <Box marginRight={params.theme.space_xs}>
          {params.breakpointIndex === 0 ||
          params.breakpointType === BreakpointType.ACTUALS ||
          params.previousBreakpoint?.otherSpend.currency === 0 ? (
            <NumberInput
              disabled={params.isHistoricalBreakpoint}
              formatter="currency-rounded"
              size="small"
              value={params.breakpoint.otherSpend.currency}
              onChange={(value) => {
                const calculatedPercentage = params.previousBreakpoint
                  ? getPercentChange(
                      params.previousBreakpoint.otherSpend.currency,
                      value
                    )
                  : 0;

                params.onUpdate({
                  ...params.breakpoint,
                  otherSpend: {
                    currency: value,
                    percent: calculatedPercentage,
                  },
                });
              }}
            />
          ) : (
            <NumberInput
              disabled={params.isHistoricalBreakpoint}
              formatter="percent-divided"
              size="small"
              value={params.breakpoint.otherSpend.percent}
              onChange={(value) => {
                const calculatedCurrency = params.previousBreakpoint
                  ? getAbsoluteAmount(
                      params.previousBreakpoint.otherSpend.currency,
                      value
                    )
                  : 0;

                params.onUpdate({
                  ...params.breakpoint,
                  otherSpend: { currency: calculatedCurrency, percent: value },
                });
              }}
            />
          )}
        </Box>
      </td>
      <td>
        {(params.isDimensional ||
          params.breakpointType === BreakpointType.PERCENT) && (
          <Flex alignItems="center" justifyContent="center">
            <Text>{params.rowTotal}</Text>
          </Flex>
        )}
      </td>
      {!params.isFirst && !params.isLast && !params.isHistoricalBreakpoint && (
        <td>
          <Button
            size="tiny"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              params.onDelete();
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </td>
      )}
    </tr>
  );
}

function renderAddButton(
  onClick: () => void,
  maxHeight?: string,
  index?: number,
  breakpoints?: BreakpointState[]
) {
  let isConsecutiveMonth = false;

  if (breakpoints && index) {
    const beforeMonth = breakpoints[index - 1].month;
    const afterMonth = breakpoints[index].month;

    const differenceInMonths = differenceInCalendarMonths(
      new Date(beforeMonth),
      new Date(afterMonth)
    );

    isConsecutiveMonth = Math.abs(differenceInMonths) <= 1;
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="flex-end"
      maxHeight={maxHeight ?? "0.25rem"}
      overflowY="visible"
    >
      <Tooltip
        hide={!isConsecutiveMonth}
        content={copyText.configurationSectionBreakpointsTooltip}
      >
        <Box height="2rem">
          <Button
            disabled={isConsecutiveMonth}
            primary
            size="tiny"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              onClick();
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Box>
      </Tooltip>
    </Flex>
  );
}

RampPlanConfigurationSection.INTERACTION_BACK_BUTTON_CLICKED =
  `RampPlanConfigurationSection.INTERACTION_BACK_BUTTON_CLICKED` as const;
RampPlanConfigurationSection.INTERACTION_BILLING_ACCOUNT_ID_CHANGED =
  `RampPlanConfigurationSection.INTERACTION_BILLING_ACCOUNT_ID_CHANGED` as const;
RampPlanConfigurationSection.INTERACTION_CREATE_SUBMIT_BUTTON_CLICKED =
  `RampPlanConfigurationSection.INTERACTION_CREATE_SUBMIT_BUTTON_CLICKED` as const;
RampPlanConfigurationSection.INTERACTION_POPULATE_HISTORICAL_SPEND_DATA_TOGGLED =
  `RampPlanConfigurationSection.INTERACTION_POPULATE_HISTORICAL_SPEND_DATA_TOGGLED` as const;
RampPlanConfigurationSection.INTERACTION_START_DATE_CHANGED =
  `RampPlanConfigurationSection.INTERACTION_START_DATE_CHANGED` as const;
RampPlanConfigurationSection.INTERACTION_UPDATE_SUBMIT_BUTTON_CLICKED =
  `RampPlanConfigurationSection.INTERACTION_UPDATE_SUBMIT_BUTTON_CLICKED` as const;

interface InteractionBackButtonClicked {
  type: typeof RampPlanConfigurationSection.INTERACTION_BACK_BUTTON_CLICKED;
}

interface InteractionBillingAccountIDChanged {
  type: typeof RampPlanConfigurationSection.INTERACTION_BILLING_ACCOUNT_ID_CHANGED;
  billingAccountIDs: string[];
}

interface InteractionCreateSubmitButtonClicked {
  type: typeof RampPlanConfigurationSection.INTERACTION_CREATE_SUBMIT_BUTTON_CLICKED;
  rampPlan: RampPlan;
}

interface InteractionPopulateHistoricalSpendDataToggled {
  type: typeof RampPlanConfigurationSection.INTERACTION_POPULATE_HISTORICAL_SPEND_DATA_TOGGLED;
  billingAccountIDs: string[];
  checked: boolean;
  startDate: string;
}

interface InteractionStartDateChanged {
  type: typeof RampPlanConfigurationSection.INTERACTION_START_DATE_CHANGED;
  startDate: string;
}

interface InteractionUpdateSubmitButtonClicked {
  type: typeof RampPlanConfigurationSection.INTERACTION_UPDATE_SUBMIT_BUTTON_CLICKED;
  existingRampPlan: RampPlan;
  updatedRampPlan: RampPlan;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace RampPlanConfigurationSection {
  export type Interaction =
    | InteractionBackButtonClicked
    | InteractionBillingAccountIDChanged
    | InteractionCreateSubmitButtonClicked
    | InteractionPopulateHistoricalSpendDataToggled
    | InteractionStartDateChanged
    | InteractionUpdateSubmitButtonClicked;
}

export default RampPlanConfigurationSection;
