import { UnitType } from "../enums";

const measures = [
  { name: "maxCpuAverageUsedCores", unit: UnitType.KILOGRAMS },
  { name: "maxCpuReservedCores", unit: UnitType.BYTES },
  { name: "maxDiskSizeBytes", unit: UnitType.BYTES },
  { name: "maxDiskUsedBytes", unit: UnitType.BYTES },
  { name: "maxRamAverageUsedBytes", unit: UnitType.BYTES },
  { name: "maxRamUsedBytesWithBuffer", unit: UnitType.BYTES },
  { name: "networkSentBytes", unit: UnitType.BYTES },
  { name: "ramReservedBytes", unit: UnitType.BYTES },
  { name: "totalCpuReservedCores", unit: UnitType.STANDARD },
  { name: "totalCpuUsedCoreHours", unit: UnitType.STANDARD },
  { name: "totalDiskSizeBytes", unit: UnitType.BYTES },
  { name: "totalDiskUsedBytes", unit: UnitType.BYTES },
  { name: "totalNetworkSentBytes", unit: UnitType.BYTES },
  { name: "totalRamReservedBytes", unit: UnitType.BYTES },
  { name: "totalRamUsedBytes", unit: UnitType.BYTES },
  { name: "totalRamUsedBytesWithBuffer", unit: UnitType.BYTES },
];

const dimensions = [
  "availability",
  "databaseId",
  "databaseType",
  "projectId",
  "region",
];

export const gcpCloudSqlUsageSchema = { dimensions, measures };
