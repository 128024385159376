import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import { useTheme } from "@emotion/react";
import { faList } from "@fortawesome/free-solid-svg-icons";
import {
  formatCurrencyRounded,
  formatPercentage,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import copyText from "../copyText";

export const currentStatuses = ["new", "accepted"];
export const pastStatuses = ["implemented", "inaccurate", "asDesigned"];

type Recommendation = {
  applicable: boolean;
  estimateValue: number;
  state: string;
  snoozeUntil: string | null;
};

interface Props {
  isLoading: boolean;
  recommendations: Recommendation[];
}

// TODO: make constants - for use here and in the mock data
export default function RecommendationsBreakdownMeter(
  props: Props
): JSX.Element {
  const activityTracker = useActivityTracker();
  const theme = useTheme();

  const savingsKeyedByStatus = props.recommendations.reduce(
    (
      accum: {
        active?: number;
        snoozed?: number;
        implemented?: number;
        inaccurate?: number;
        asDesigned?: number;
      },
      recommendation
    ) => {
      const amount = recommendation.estimateValue;

      if (!recommendation.applicable) return accum;

      if (currentStatuses.includes(recommendation.state)) {
        // Current Recs - split by snoozed vs active
        if (recommendation.snoozeUntil) {
          if (accum.snoozed) {
            accum.snoozed += amount;
          } else {
            accum.snoozed = amount;
          }
        } else {
          if (accum.active) {
            accum.active += amount;
          } else {
            accum.active = amount;
          }
        }
      }
      // Past Recs - split by state
      else {
        if (pastStatuses.includes(recommendation.state)) {
          if (accum[recommendation.state]) {
            accum[recommendation.state] += amount;
          } else {
            accum[recommendation.state] = amount;
          }
        } else {
          activityTracker.captureMessage(
            `Unexpected recommendaton state: ${recommendation.state}`
          );
        }
      }

      return accum;
    },
    {}
  );

  if (props.isLoading) {
    return (
      <Box marginRight="1rem" width="40%">
        <EmptyPlaceholder
          height="130px"
          icon={faList}
          loading={props.isLoading}
          small
          skeletonVariant="meter"
        />
      </Box>
    );
  }

  // totals
  const totalCurrent =
    (savingsKeyedByStatus.active || 0) + (savingsKeyedByStatus.snoozed || 0);
  const totalPast =
    (savingsKeyedByStatus.implemented || 0) +
    (savingsKeyedByStatus.inaccurate || 0) +
    (savingsKeyedByStatus.asDesigned || 0);

  // current ratios
  let activeRatio =
    totalCurrent > 0 ? (savingsKeyedByStatus.active || 0) / totalCurrent : 0;
  let snoozedRatio =
    totalCurrent > 0 ? (savingsKeyedByStatus.snoozed || 0) / totalCurrent : 0;

  // past ratios
  let implementedRatio =
    totalPast > 0 ? (savingsKeyedByStatus.implemented || 0) / totalPast : 0;
  let inaccurateRatio =
    totalPast > 0 ? (savingsKeyedByStatus.inaccurate || 0) / totalPast : 0;
  let asDesignedRatio =
    totalPast > 0 ? (savingsKeyedByStatus.asDesigned || 0) / totalPast : 0;

  // Fill in empty space if all are zeros
  if (activeRatio === 0 && snoozedRatio === 0) {
    activeRatio = 0.5;
    snoozedRatio = 0.5;
  }

  if (implementedRatio === 0 && inaccurateRatio === 0) {
    implementedRatio = 0.3333;
    inaccurateRatio = 0.3333;
    asDesignedRatio = 0.3333;
  }

  return (
    <Flex
      direction="column"
      height="100%"
      justifyContent="space-between"
      marginRight="1rem"
      width="40%"
    >
      <div>
        <Flex alignItems="flex-end">
          <Text
            fontSize={theme.h5_fontSize}
            fontWeight={theme.h4_fontWeight}
            color={theme.text_color}
            marginBottom={"0"}
          >
            {formatCurrencyRounded({ number: totalCurrent })}
          </Text>
          <Text
            color={theme.text_color_secondary}
            fontSize={theme.h6_fontSize}
            fontWeight={theme.h6_fontWeight}
            marginLeft={theme.space_xxs}
          >
            {copyText.recommendationsBreakdownMeterTitleCurrent}
          </Text>
        </Flex>
        <Flex justifyContent="center" width="100%">
          <Box
            backgroundColor={theme.recommendations_meter_color_active}
            borderBottomLeftRadius={theme.borderRadius_2}
            borderTopLeftRadius={theme.borderRadius_2}
            width={formatPercentage(activeRatio)}
            padding={theme.space_xxs}
            transition="width 0.5s"
          />
          <Box
            backgroundColor={theme.recommendations_meter_color_snoozed}
            borderBottomRightRadius={theme.borderRadius_2}
            borderTopRightRadius={theme.borderRadius_2}
            width={formatPercentage(snoozedRatio)}
            padding={theme.space_xxs}
            transition="width 0.5s"
          />
        </Flex>
        <Flex
          alignItems="center"
          marginTop={theme.space_sm}
          justifyContent="space-around"
          width="100%"
        >
          <Tooltip
            content={copyText.recommendationsBreakdownMeterActiveTooltip}
          >
            {renderLegendItem({
              color: theme.recommendations_meter_color_active,
              text: `${formatCurrencyRounded({
                number: savingsKeyedByStatus.active || 0,
              })} ${copyText.recommendationsBreakdownMeterTitleTypeActive}`,
            })}
          </Tooltip>
          {renderLegendItem({
            color: theme.recommendations_meter_color_snoozed,
            text: `${formatCurrencyRounded({
              number: savingsKeyedByStatus.snoozed || 0,
            })} ${copyText.recommendationsBreakdownMeterTitleTypeSnoozed}`,
          })}
        </Flex>
      </div>
      <div>
        <Flex marginTop={theme.space_sm} alignItems="flex-end">
          <Text
            fontSize={theme.h5_fontSize}
            fontWeight={theme.h4_fontWeight}
            color={theme.text_color}
            marginBottom={"0"}
          >
            {formatCurrencyRounded({ number: totalPast })}
          </Text>
          <Text
            color={theme.text_color_secondary}
            fontSize={theme.h6_fontSize}
            fontWeight={theme.h6_fontWeight}
            marginLeft={theme.space_xxs}
          >
            {copyText.recommendationsBreakdownMeterTitlePast}
          </Text>
        </Flex>
        <Flex justifyContent="center" width="100%">
          <Box
            backgroundColor={theme.recommendations_meter_color_active}
            borderBottomLeftRadius={theme.borderRadius_2}
            borderTopLeftRadius={theme.borderRadius_2}
            width={formatPercentage(implementedRatio)}
            padding={theme.space_xxs}
            transition="width 0.5s"
          />
          <Box
            backgroundColor={theme.recommendations_meter_color_snoozed}
            width={formatPercentage(inaccurateRatio)}
            padding={theme.space_xxs}
            transition="width 0.5s"
          />
          <Box
            backgroundColor={theme.recommendations_meter_color_as_designed}
            borderBottomRightRadius={theme.borderRadius_2}
            borderTopRightRadius={theme.borderRadius_2}
            width={formatPercentage(asDesignedRatio)}
            padding={theme.space_xxs}
            transition="width 0.5s"
          />
        </Flex>
        <Flex
          alignItems="center"
          marginTop={theme.space_sm}
          justifyContent="space-around"
          width="100%"
        >
          {renderLegendItem({
            color: theme.recommendations_meter_color_active,
            text: `${formatCurrencyRounded({
              number: savingsKeyedByStatus.implemented || 0,
            })} ${copyText.recommendationsBreakdownMeterTitleTypeImplemented}`,
          })}
          {renderLegendItem({
            color: theme.recommendations_meter_color_snoozed,
            text: `${formatCurrencyRounded({
              number: savingsKeyedByStatus.inaccurate || 0,
            })} ${copyText.recommendationsBreakdownMeterTitleTypeInaccurate}`,
          })}
          {renderLegendItem({
            color: theme.recommendations_meter_color_as_designed,
            text: `${formatCurrencyRounded({
              number: savingsKeyedByStatus.asDesigned || 0,
            })} ${copyText.recommendationsBreakdownMeterTitleTypeAsDesigned}`,
          })}
        </Flex>
      </div>
    </Flex>
  );

  function renderLegendItem(params: {
    color: string;
    text: string;
  }): JSX.Element {
    return (
      <Flex marginTop={theme.space_xxs}>
        <Box
          backgroundColor={params.color}
          borderRadius={theme.borderRadius_1}
          height={theme.space_md}
          marginRight={theme.space_xxs}
          width={theme.space_xs}
        />
        <Text
          color={theme.text_color_secondary}
          fontSize={theme.h6_fontSize}
          fontWeight={theme.h6_fontWeight}
          marginRight={theme.space_lg}
        >
          {params.text}
        </Text>
      </Flex>
    );
  }
}
