import useGatekeeper from "@/hooks/useGatekeeper";
import Dropdown from "@/ui-lib/components/Dropdown";
import LockedWrapper from "@/ui-lib/components/LockedWrapper";
import { useTheme } from "@emotion/react";
import {
  faCancel,
  faCloud,
  faEdit,
  faLock,
  faPencil,
  faStar as faStarSolid,
} from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import {
  DashboardScope,
  DashboardType,
} from "@ternary/api-lib/constants/enums";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Table, {
  ActionMenuButton,
} from "@ternary/api-lib/ui-lib/components/Table/Table";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import React, { useMemo, useState } from "react";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useRefFn from "../../../hooks/useRefFn";
import Checkbox from "../../../ui-lib/components/Checkbox";
import { FavoriteButton } from "../../../ui-lib/components/FavoriteButton";
import TableTags from "../../../ui-lib/components/TableTags";
import copyText from "../copyText";

type Dashboard = {
  id: string;
  createdAt: string;
  createdByID: string;
  createdByEmail: string | null;
  favoritedUserIDs: string[];
  isDistributed?: boolean | null;
  name: string;
  scope: string;
  tags: string[];
  type: DashboardType;
  updatedAt: string | null;
};

interface Props {
  dashboards: Dashboard[];
  existingTags: string[];
  highlightedTags: string[];
  isInternal?: boolean;
  isLoading: boolean;
  preferredDashboardID: string | null;
  selectedDashboardIDs: string[];
  showMultiSelect: boolean;
  onInteraction: (interaction: DashboardTable.Interaction) => void;
}

type TableData = {
  id: string;
  preferred: boolean;
  createdAt: string;
  createdBy: string;
  createdByID: string;
  isDistributed?: boolean | null;
  isFavorited: boolean;
  isGlobal: boolean;
  isMultiSelected: boolean;
  name: string;
  selectedCount: number;
  shared: string;
  tags: string[];
  tagsString: string | null;
  timeLastModified: string;
  type: DashboardType;
};

const columnHelper = createColumnHelper<TableData>();

export function DashboardTable(props: Props): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  const [filteredFavorites, setFilteredFavorites] = useState<boolean>(false);

  if (!gatekeeper.canListDashboards) {
    return (
      <Flex alignItems="center" justifyContent="center" minHeight="50vh">
        <EmptyPlaceholder
          icon={faLock}
          loading={false}
          text={copyText.emptyPlaceholderInsufficientPermission}
        />
      </Flex>
    );
  }

  function handleClickRow(dashboardID: string): void {
    props.onInteraction({
      type: DashboardTable.INTERACTION_ROW_CLICKED,
      dashboardID,
    });
  }

  const handleClickTag = useRefFn((tag: string) => {
    props.onInteraction({
      type: DashboardTable.INTERACTION_TAG_CLICKED,
      tag,
    });
  });

  const handleClickFavorite = useRefFn((dashboardID: string) => {
    props.onInteraction({
      type: DashboardTable.INTERACTION_FAVORITE_DASHBOARD_CLICKED,
      dashboardID,
    });
  });

  function handleFilterFavorites() {
    props.onInteraction({
      type: DashboardTable.INTERACTION_FILTER_FAVORITES_CLICKED,
    });
  }

  const handleMultiSelectAll = useRefFn(() => {
    const selectedIDSet = new Set(props.selectedDashboardIDs);

    const visableIDs = props.dashboards
      .filter((dashboard) => dashboard.scope !== DashboardScope.GLOBAL)
      .map((dashboards) => dashboards.id);

    const visableSelectedIDs = visableIDs.filter((id) => selectedIDSet.has(id));

    let selectedIDs: string[];
    if (visableSelectedIDs.length > 0) {
      selectedIDs = [];
    } else {
      selectedIDs = visableIDs;
    }

    props.onInteraction({
      type: DashboardTable.INTERACTION_EDIT_MUTLIPLE_TAGS_CLICKED,
      dashboardIDs: selectedIDs,
    });
  });

  const handleMultiSelectOne = useRefFn((id: string, checked: boolean) => {
    const selectedIDSet = new Set(props.selectedDashboardIDs);

    if (checked) {
      selectedIDSet.add(id);
    } else {
      selectedIDSet.delete(id);
    }

    props.onInteraction({
      type: DashboardTable.INTERACTION_EDIT_MUTLIPLE_TAGS_CLICKED,
      dashboardIDs: props.dashboards
        .filter((dashboard) => selectedIDSet.has(dashboard.id))
        .map((dashboard) => dashboard.id),
    });
  });

  const handleSelectSingle = useRefFn((id: string) => {
    props.onInteraction({
      type: DashboardTable.INTERACTION_EDIT_SINGLE_TAGS_CLICKED,
      dashboardID: id,
    });
  });

  const columns = useMemo(() => {
    return [
      columnHelper.display({
        id: "favorite",
        cell: ({ row }) => {
          return (
            <Flex alignItems="center" justifyContent="center">
              <FavoriteButton
                iconOnly
                favorited={row.original.isFavorited}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickFavorite(row.original.id);
                }}
              />
            </Flex>
          );
        },
        header: () => (
          <Icon
            backgroundColorOnHover={theme.report_favorite_color}
            clickable
            color={filteredFavorites ? theme.report_favorite_color : undefined}
            icon={faStarSolid}
            size="xl"
            onClick={() => {
              setFilteredFavorites(!filteredFavorites);
              handleFilterFavorites();
            }}
          />
        ),
        meta: { align: "center", disableClick: true },
        size: 40,
      }),
      columnHelper.accessor("name", {
        cell: ({ row }) => {
          if (row.original.id === props.preferredDashboardID) {
            return (
              <>
                {row.original.name}
                <Tooltip content={copyText.homepageDashboardTooltip}>
                  <Box
                    padding={theme.space_xxs}
                    marginLeft={theme.space_xs}
                    display="inline-block"
                  >
                    <Icon
                      color={theme.primary_color_background}
                      icon={faCloud}
                      size="sm"
                    />
                  </Box>
                </Tooltip>
              </>
            );
          }
          return row.original.name;
        },
        header: copyText.tableHeaderName,
        size: 200,
      }),
      columnHelper.accessor("createdBy", {
        header: copyText.tableHeaderCreatedBy,
        size: 200,
      }),
      columnHelper.accessor("createdAt", {
        cell: ({ getValue }) => formatDate(new Date(getValue()), "MM/dd/yyyy"),
        header: copyText.tableHeaderCreatedAt,
        size: 100,
      }),
      columnHelper.accessor("timeLastModified", {
        cell: ({ getValue }) => {
          const value = getValue();

          return formatDate(new Date(value ? value : 0), "MM/dd/yyyy hh:mm a");
        },
        header: copyText.tableHeaderTimeLastModified,
        size: 130,
      }),
      columnHelper.accessor("shared", {
        header: copyText.tableHeaderVisibility,
        meta: { align: "left" },
        size: 100,
      }),
      columnHelper.accessor("tags", {
        cell: ({ getValue }) => {
          const value = getValue();

          if (value.length === 0) return "--";

          return (
            <TableTags
              highlightedTags={props.highlightedTags}
              tags={value}
              onClickTag={handleClickTag}
            />
          );
        },
        header: copyText.tableHeaderTags,
        size: 300,
        sortingFn: (a, b) => {
          const aTags = a.original.tagsString;
          const bTags = b.original.tagsString;

          if (aTags === null || bTags === null) {
            return aTags === bTags ? 0 : bTags === null ? -1 : 1;
          }

          return aTags < bTags ? -1 : aTags > bTags ? 1 : 0;
        },
      }),
      columnHelper.display({
        id: "editButton",
        cell: ({ row }) => {
          let disabled = false;

          switch (row.original.type) {
            case DashboardType.DEFAULT:
              disabled = !gatekeeper.canUpdateDashboard(row.original.id);
              break;
            case DashboardType.SYSTEM_COPY:
              disabled = true;
              break;
            case DashboardType.SYSTEM:
              disabled = !gatekeeper.canUpdateSystemDashboard;
          }

          return props.showMultiSelect ? (
            <Box onClick={(e) => e.stopPropagation()}>
              <Checkbox
                checked={row.original.isMultiSelected}
                disabled={disabled}
                onChange={(e) => {
                  e.preventDefault();

                  if (disabled) return;

                  handleMultiSelectOne(
                    row.original.id,
                    e.currentTarget.checked
                  );
                }}
              />
            </Box>
          ) : (
            <Button
              disabled={disabled}
              iconStart={<Icon icon={faPencil} />}
              secondary
              size="tiny"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleSelectSingle(row.original.id);
              }}
            />
          );
        },
        header: (headerContext) => {
          const rows = headerContext.table.getRowModel().rows;

          const checkedCount = rows.reduce(
            (count, row) => (row.original.isMultiSelected ? count + 1 : count),
            0
          );

          return props.showMultiSelect ? (
            <Checkbox
              checked={checkedCount > 0 && checkedCount === rows.length}
              dashed={checkedCount > 0}
              onChange={handleMultiSelectAll}
            />
          ) : (
            <Flex
              alignItems="center"
              height={theme.fontSize_base}
              justifyContent="space-between"
              width="100%"
            >
              <Tooltip content={copyText.tableHeaderEditTags}>
                <Button
                  iconStart={<Icon icon={faEdit} />}
                  size="tiny"
                  onClick={(e) => {
                    e.preventDefault();
                    props.onInteraction({
                      type: DashboardTable.INTERACTION_DISPLAY_MULTI_SELECT_CLICKED,
                      display: !props.showMultiSelect,
                    });
                  }}
                />
              </Tooltip>
            </Flex>
          );
        },
        enableSorting: false,
        meta: { disableClick: true },
        size: 50,
      }),
      ...(props.showMultiSelect
        ? [
            columnHelper.display({
              id: "multiSelectToggle",
              header: (headerContext) => {
                if (!props.showMultiSelect) return null;

                const rows = headerContext.table.getRowModel().rows;

                const selectedCount = rows[0]?.original.selectedCount ?? 0;

                if (selectedCount > 0) {
                  return (
                    <Flex
                      alignItems="center"
                      height={theme.fontSize_base}
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Tooltip
                        content={copyText.dashboardListEditNTagsToolTipMessage.replace(
                          "%NUMBER%",
                          selectedCount.toString()
                        )}
                      >
                        <Button
                          iconStart={<Icon icon={faEdit} />}
                          primary
                          size="tiny"
                          onClick={(e) => {
                            e.preventDefault();

                            props.onInteraction({
                              type: DashboardTable.INTERACTION_EDIT_MULTI_SELECT_CLICKED,
                            });
                          }}
                        />
                      </Tooltip>
                    </Flex>
                  );
                }

                return (
                  <Flex
                    alignItems="center"
                    height={theme.fontSize_base}
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Tooltip
                      content={copyText.dashboardListCancelEditToolTipMessage}
                    >
                      <Button
                        iconStart={<Icon icon={faCancel} />}
                        secondary
                        size="tiny"
                        onClick={(e) => {
                          e.preventDefault();

                          props.onInteraction({
                            type: DashboardTable.INTERACTION_DISPLAY_MULTI_SELECT_CLICKED,
                            display: false,
                          });
                        }}
                      />
                    </Tooltip>
                  </Flex>
                );
              },
              size: 35,
            }),
          ]
        : []),
      columnHelper.display({
        id: "actionMenu",
        cell: ({ row }) => (
          <DashboardDropdown
            createdByID={row.original.createdByID}
            dashboardID={row.original.id}
            disabled={
              !gatekeeper.canUpdateDashboard(row.original.createdByID) &&
              !gatekeeper.getCanDeleteSpecificDashboard(
                row.original.createdByID
              )
            }
            isGlobal={row.original.isGlobal}
            onInteraction={props.onInteraction}
          />
        ),
        meta: { align: "center" },
        size: 40,
      }),
      ...(props.isInternal
        ? [
            columnHelper.display({
              id: "internalButton",
              cell: ({ row }) => {
                const hasPermission = row.original.isDistributed
                  ? gatekeeper.canRemoveSystemDashboard
                  : gatekeeper.canDistributeSystemDashboard;

                return (
                  <Button
                    disabled={!hasPermission}
                    secondary
                    size="small"
                    width={160}
                    onClick={(event) => {
                      event.stopPropagation();

                      if (row.original.isDistributed) {
                        props.onInteraction({
                          type: DashboardTable.INTERACTION_REMOVE_DASHBOARD_BUTTON_CLICKED,
                          dashboardID: row.original.id,
                        });
                      } else {
                        props.onInteraction({
                          type: DashboardTable.INTERACTION_DISTRIBUTE_DASHBOARD_BUTTON_CLICKED,
                          dashboardID: row.original.id,
                        });
                      }
                    }}
                  >
                    {row.original.isDistributed
                      ? copyText.actionMenuItemRemoveSystemDashboard
                      : copyText.actionMenuItemDistributeSystemDashboard}
                  </Button>
                );
              },
              meta: { align: "center" },
              size: 160,
            }),
          ]
        : []),
    ];
  }, [
    filteredFavorites,
    props.dashboards,
    props.preferredDashboardID,
    props.highlightedTags,
    props.selectedDashboardIDs,
    props.showMultiSelect,
  ]);

  const data = useMemo(() => {
    return props.dashboards
      .map((dashboard) => {
        const sortedTags = [...dashboard.tags].sort((a, b) => {
          if (a.toLowerCase() < b.toLowerCase()) {
            return -1;
          }
          if (a.toLowerCase() > b.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        return {
          id: dashboard.id,
          createdAt: dashboard.createdAt,
          createdBy: dashboard.createdByEmail ?? "--",
          createdByID: dashboard.createdByID,
          isDistributed: dashboard.isDistributed,
          isGlobal: dashboard.scope === DashboardScope.GLOBAL,
          isMultiSelected: props.selectedDashboardIDs.includes(dashboard.id),
          preferred: dashboard.id === props.preferredDashboardID,
          isFavorited: dashboard.favoritedUserIDs.includes(
            authenticatedUser.id
          ),
          name: dashboard.name,
          selectedCount: props.selectedDashboardIDs.length,
          shared: getScopeText(dashboard.scope),
          tags: sortedTags,
          tagsString: sortedTags.length
            ? sortedTags.join("-").toLowerCase()
            : null,
          timeLastModified: dashboard.updatedAt ?? dashboard.createdAt,
          type: dashboard.type,
        };
      })
      .sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : 0
      )
      .sort((a, b) => {
        //Sort for preferredDashboard
        if (a.preferred === b.preferred) return 0;
        if (a.preferred) return -1;
        return 1;
      });
  }, [
    props.dashboards,
    props.preferredDashboardID,
    props.selectedDashboardIDs,
  ]);

  return (
    <Table
      clickableRows
      columns={columns}
      data={data}
      isLoading={props.isLoading}
      rowHeight="70px"
      showPagination
      sortable
      onClick={(row) => handleClickRow(row.id)}
    />
  );

  interface DashboardDropdownProps {
    createdByID: string;
    dashboardID: string;
    disabled: boolean;
    isGlobal: boolean;
    onInteraction: (interaction: DashboardTable.Interaction) => void;
  }

  function DashboardDropdown(
    dropdownProps: DashboardDropdownProps
  ): JSX.Element {
    const options = [
      {
        label: copyText.actionMenuItemViewDashboard,
        onClick: () =>
          dropdownProps.onInteraction({
            type: DashboardTable.INTERACTION_VIEW_BUTTON_CLICKED,
            dashboardID: dropdownProps.dashboardID,
          }),
      },
      {
        label: copyText.actionMenuItemEditDashboard,
        locked: dropdownProps.isGlobal
          ? !gatekeeper.canUpdateSystemDashboard
          : !gatekeeper.canUpdateDashboard(dropdownProps.createdByID),
        onClick: () =>
          dropdownProps.onInteraction({
            type: DashboardTable.INTERACTION_UPDATE_BUTTON_CLICKED,
            dashboardID: dropdownProps.dashboardID,
          }),
      },
      {
        label: copyText.actionMenuItemDeleteDashboard,
        locked: dropdownProps.isGlobal
          ? !gatekeeper.canUpdateSystemDashboard &&
            !gatekeeper.getCanDeleteSpecificDashboard(dropdownProps.createdByID)
          : !gatekeeper.getCanDeleteSpecificDashboard(
              dropdownProps.createdByID
            ),
        onClick: () =>
          dropdownProps.onInteraction({
            type: DashboardTable.INTERACTION_DELETE_BUTTON_CLICKED,
            dashboardID: dropdownProps.dashboardID,
          }),
      },
      {
        label: copyText.actionMenuItemMakeHomePageDashboard,
        onClick: () =>
          dropdownProps.onInteraction({
            type: DashboardTable.INTERACTION_PREFERRED_DASHBOARD_CLICKED,
            dashboardID: dropdownProps.dashboardID,
          }),
      },
      {
        label: copyText.actionMenuItemSubscribeToDashboard,
        locked: !gatekeeper.canUpdateDashboard(dropdownProps.createdByID ?? ""),
        onClick: () =>
          dropdownProps.onInteraction({
            type: DashboardTable.INTERACTION_SUBSCRIBE_DASHBOARD_CLICKED,
            dashboardID: dropdownProps.dashboardID,
          }),
      },
    ];

    return (
      <LockedWrapper locked={dropdownProps.disabled}>
        <Dropdown
          disabled={dropdownProps.disabled}
          options={options}
          placement="bottom-end"
        >
          <ActionMenuButton />
        </Dropdown>
      </LockedWrapper>
    );
  }
}

function getScopeText(scope: string) {
  switch (scope) {
    case DashboardScope.GLOBAL:
      return copyText.tableScopeGlobal;
    case DashboardScope.PRIVATE:
      return copyText.tableScopePrivate;
    case DashboardScope.SHARED:
      return copyText.tableScopeShared;
    default:
      return copyText.tableScopeShared;
  }
}

DashboardTable.INTERACTION_DELETE_BUTTON_CLICKED =
  `DashboardList.INTERACTION_DELETE_BUTTON_CLICKED` as const;
DashboardTable.INTERACTION_DISPLAY_MULTI_SELECT_CLICKED =
  `DashboardList.INTERACTION_DISPLAY_MULTI_SELECT_CLICKED` as const;
DashboardTable.INTERACTION_DISTRIBUTE_DASHBOARD_BUTTON_CLICKED =
  `DashboardList.INTERACTION_DISTRIBUTE_DASHBOARD_BUTTON_CLICKED` as const;
DashboardTable.INTERACTION_EDIT_MULTI_SELECT_CLICKED =
  `DashboardList.INTERACTION_EDIT_MULTI_SELECT_CLICKED` as const;
DashboardTable.INTERACTION_EDIT_MUTLIPLE_TAGS_CLICKED =
  `DashboardList.INTERACTION_EDIT_MUTLIPLE_TAGS_CLICKED` as const;
DashboardTable.INTERACTION_EDIT_SINGLE_TAGS_CLICKED =
  `DashboardList.INTERACTION_EDIT_SINGLE_TAGS_CLICKED` as const;
DashboardTable.INTERACTION_PREFERRED_DASHBOARD_CLICKED =
  `DashboardList.INTERACTION_PREFERRED_DASHBOARD_CLICKED` as const;
DashboardTable.INTERACTION_FAVORITE_DASHBOARD_CLICKED =
  `Dashboard.INTERACTION_FAVORITE_DASHBOARD_CLICKED` as const;
DashboardTable.INTERACTION_FILTER_FAVORITES_CLICKED =
  `Dashboard.INTERACTION_FILTER_FAVORITES_CLICKED` as const;
DashboardTable.INTERACTION_REMOVE_DASHBOARD_BUTTON_CLICKED =
  `DashboardList.INTERACTION_REMOVE_DASHBOARD_BUTTON_CLICKED` as const;
DashboardTable.INTERACTION_ROW_CLICKED =
  `DashboardList.INTERACTION_ROW_CLICKED` as const;
DashboardTable.INTERACTION_SUBSCRIBE_DASHBOARD_CLICKED =
  `DashboardList.INTERACTION_SUBSCRIBE_DASHBOARD_CLICKED` as const;
DashboardTable.INTERACTION_TAG_CLICKED =
  `Dashboard.INTERACTION_TAG_CLICKED` as const;
DashboardTable.INTERACTION_UPDATE_BUTTON_CLICKED =
  `DashboardList.INTERACTION_UPDATE_BUTTON_CLICKED` as const;
DashboardTable.INTERACTION_VIEW_BUTTON_CLICKED =
  `DashboardList.INTERACTION_VIEW_BUTTON_CLICKED` as const;

type InteractionDeleteButtonClicked = {
  type: typeof DashboardTable.INTERACTION_DELETE_BUTTON_CLICKED;
  dashboardID: string;
};

type InteractionDistributeDashboardButtonClicked = {
  type: typeof DashboardTable.INTERACTION_DISTRIBUTE_DASHBOARD_BUTTON_CLICKED;
  dashboardID: string;
};

type InteractionPreferredDashboardClicked = {
  type: typeof DashboardTable.INTERACTION_PREFERRED_DASHBOARD_CLICKED;
  dashboardID: string;
};
type InteractionDisplayMultiSelectClicked = {
  type: typeof DashboardTable.INTERACTION_DISPLAY_MULTI_SELECT_CLICKED;
  display: boolean;
};

type InteractionEditMultiSelectClicked = {
  type: typeof DashboardTable.INTERACTION_EDIT_MULTI_SELECT_CLICKED;
};

type InteractionEditMultipleTagsClicked = {
  type: typeof DashboardTable.INTERACTION_EDIT_MUTLIPLE_TAGS_CLICKED;
  dashboardIDs: string[];
};

type InteractionEditSingleTagsClicked = {
  type: typeof DashboardTable.INTERACTION_EDIT_SINGLE_TAGS_CLICKED;
  dashboardID: string;
};

type InteractionFavoriteDashboardClicked = {
  type: typeof DashboardTable.INTERACTION_FAVORITE_DASHBOARD_CLICKED;
  dashboardID: string;
};

type InteractionFilterFavoritesClicked = {
  type: typeof DashboardTable.INTERACTION_FILTER_FAVORITES_CLICKED;
};

type InteractionRemoveDashboardButtonClicked = {
  type: typeof DashboardTable.INTERACTION_REMOVE_DASHBOARD_BUTTON_CLICKED;
  dashboardID: string;
};

type InteractionRowClicked = {
  type: typeof DashboardTable.INTERACTION_ROW_CLICKED;
  dashboardID: string;
};

type InteractionSubscribeDashboardButtonClicked = {
  type: typeof DashboardTable.INTERACTION_SUBSCRIBE_DASHBOARD_CLICKED;
  dashboardID: string;
};

type InteractionTagClicked = {
  type: typeof DashboardTable.INTERACTION_TAG_CLICKED;
  tag: string;
};

type InteractionUpdateButtonClicked = {
  type: typeof DashboardTable.INTERACTION_UPDATE_BUTTON_CLICKED;
  dashboardID: string;
};

type InteractionViewButtonClicked = {
  type: typeof DashboardTable.INTERACTION_VIEW_BUTTON_CLICKED;
  dashboardID: string;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DashboardTable {
  export type Interaction =
    | InteractionDeleteButtonClicked
    | InteractionDisplayMultiSelectClicked
    | InteractionDistributeDashboardButtonClicked
    | InteractionEditMultipleTagsClicked
    | InteractionEditMultiSelectClicked
    | InteractionEditSingleTagsClicked
    | InteractionPreferredDashboardClicked
    | InteractionFavoriteDashboardClicked
    | InteractionFilterFavoritesClicked
    | InteractionRemoveDashboardButtonClicked
    | InteractionRowClicked
    | InteractionSubscribeDashboardButtonClicked
    | InteractionTagClicked
    | InteractionUpdateButtonClicked
    | InteractionViewButtonClicked;
}

export default DashboardTable;
