import Icon, { Props as IconProps } from "@ternary/web-ui-lib/components/Icon";
import React from "react";

export default function IconGCPCloud(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.8701 3.46494L11.8681 3.46194C11.1148 2.80517 10.2078 2.34927 9.2313 2.13652C8.25478 1.92377 7.24036 1.96106 6.2821 2.24494C5.32623 2.53372 4.45947 3.06077 3.76326 3.77658C3.06705 4.49238 2.56425 5.37343 2.3021 6.33694C1.75899 6.71554 1.31291 7.21701 1.00018 7.80054C0.687441 8.38408 0.516875 9.0332 0.502355 9.69509C0.487834 10.357 0.62977 11.013 0.916615 11.6097C1.20346 12.2063 1.62711 12.7269 2.1531 13.1289C2.88861 13.6891 3.78656 13.9946 4.7111 13.9989H11.2311C12.1384 14.0075 13.0244 13.7243 13.7586 13.1912C14.4928 12.6581 15.0363 11.9033 15.3091 11.0379C15.5783 10.1735 15.561 9.24517 15.2597 8.39135C14.9584 7.53754 14.3893 6.80396 13.6371 6.29994C13.337 5.20293 12.7228 4.21754 11.8701 3.46494ZM4.0421 11.2449C4.2521 11.3399 4.4801 11.4049 4.7121 11.4049L11.2301 11.4069C12.1301 11.4069 12.8601 10.6819 12.8601 9.78794C12.8601 8.89394 12.1301 8.16894 11.2301 8.16894V7.84594C11.2391 7.35718 11.137 6.87276 10.9314 6.42928C10.7257 5.98579 10.422 5.59483 10.0431 5.28594H10.0411C8.7021 4.20794 6.7361 4.37394 5.5761 5.63994L5.5621 5.65494C6.5581 5.85494 7.4591 6.40594 8.0871 7.22294L6.1981 9.10094C6.05244 8.78029 5.80685 8.51544 5.49808 8.34604C5.18931 8.17664 4.83398 8.1118 4.48529 8.16123C4.13659 8.21066 3.81328 8.3717 3.56375 8.62023C3.31423 8.86877 3.15191 9.19144 3.1011 9.53994C3.05171 9.88774 3.11693 10.2422 3.28689 10.5496C3.45684 10.8571 3.7223 11.1008 4.0431 11.2439L4.0421 11.2449Z"
          fill="#646C76"
        />
      </svg>
    </Icon>
  );
}
