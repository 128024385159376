/* eslint-disable @typescript-eslint/no-empty-object-type */
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Theme, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  faArrowDown,
  faArrowUp,
  faEllipsisV,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Cell as CellType,
  ColumnDef,
  createColumnHelper,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Header,
  RowData,
  Row as RowType,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table";
import { noop } from "lodash";
import React, {
  createElement,
  CSSProperties,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from "react";
import ContentLoader from "react-content-loader";
import { COMPARISON_KEY } from "../../../analytics/constants";
import useRefFn from "../../../analytics/ui/hooks/useRefFn";
import { removeInvalidCharacters } from "../../../analytics/utils/ChartFormatUtils";
import { ResourceType } from "../../../constants/enums";
import copyText from "../../copyText";
import Box from "../Box";
import EmptyPlaceholder from "../EmptyPlaceholder";
import Flex from "../Flex";
import Icon from "../Icon";
import Pagination from "../Pagination";
import ResourceSelector from "../ResourceSelector";
import Text from "../Text";
import { Tooltip } from "../Tooltip";
import {
  FooterCell,
  FooterRow,
  HeaderCell,
  HeaderRow,
  StyledActionMenuButton,
  StyledCell,
  StyledRow,
  StyledTable,
} from "./styled";

const Resizer = styled("div")(({ theme }) => ({
  cursor: "col-resize",
  height: "100%",
  opacity: 1,
  position: "absolute",
  right: 0,
  top: 0,
  touchAction: "none",
  userSelect: "none",
  borderRight: `2px solid ${theme.border_color}`,
  padding: `0px ${theme.space_sm}`,
  width: "1px",

  "&:hover": {
    opacity: 1,
    borderRight: `2px solid ${theme.primary_color_background}`,
  },

  ".ltr": {
    right: 0,
  },

  ".isResizing": {
    background: theme.primary_color_background,
    opacity: 1,
  },
}));

interface TableRowProps<TData extends Record<string, unknown>> {
  alternateRows?: boolean;
  clickableRows?: boolean;
  compact?: boolean;
  disableRow?: (row: RowType<TData>) => boolean;
  draggableRows?: boolean;
  footer?: boolean;
  getLeftPosition: (index: number) => number | undefined;
  isColumnResizable?: boolean;
  isLastRow?: boolean;
  isRowHighlighted?: (originalRow: TData) => boolean;
  resourceType?: ResourceType;
  row: RowType<TData>;
  rowHeight?: string;
  selectedRowID?: string;
  onClickRow?: (row: RowType<TData>) => void;
}

interface TableProps<TData> extends TableOptions<TData> {
  alternateRows?: boolean;
  clickableRows?: boolean;
  compact?: boolean;
  draggableRows?: boolean;
  footer?: boolean;
  footerDelta?: boolean;
  expandable?: boolean;
  isColumnResizable?: boolean;
  isLoading?: boolean;
  pinnedColumns?: number;
  placeholderRowLimit?: number;
  resourceType?: ResourceType;
  rowHeight?: string;
  selectedRowID?: string;
  showPagination?: boolean;
  sortable?: boolean;
  truncateRows?: boolean;
  disableRow?: (row: RowType<TData>) => boolean;
  isRowHighlighted?: (datum: TData) => boolean;
  onChangePage?: (pageContent: TData[], pageIndex: number) => void;
  onChangeRowOrder?: (data: TData[]) => void;
  onChangeSortBy?: (sortRule: { id: string; desc: boolean }[]) => void;
  onClick?: (data: TData) => void;
}

interface CustomColumnMeta {
  align?: "center" | "left" | "right";
  borderStyle?: {
    border?: string;
    borderBottom?: string;
    borderLeft?: string;
    borderRight?: string;
    borderTop?: string;
  };
  disableClick?: boolean;
  truncate?: boolean;
}

const SKELETON_ANIMATION_TIME = 1.5;

declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue>
    extends CustomColumnMeta {}
}

interface TableContextState {
  columnSizeVars: Record<string, number>;
}
const TableContext = React.createContext<TableContextState | null>(null);

export function useTableContext() {
  const context = useContext(TableContext);

  if (!context) {
    throw new Error("useTableContext must be used within a TableProvider");
  }

  return context;
}

export default function Table<TData extends Record<string, unknown>>(
  props: Omit<TableProps<TData>, "getCoreRowModel" | "sortingFns">
): JSX.Element {
  const theme = useTheme();

  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  // If using draggableRows, we need to manage the data state manually for
  // React Table to know when the order changes.
  const [dataState, setDataState] = React.useState<TData[]>(
    props.data && props.draggableRows ? props.data : []
  );

  useEffect(() => {
    if (props.data && props.draggableRows) {
      setDataState(props.data);
    }
  }, [props.data, props.draggableRows]);

  // TableData must have an `id` field for this to work.
  const dataIDs = React.useMemo<UniqueIdentifier[]>(
    () =>
      props.data && props.draggableRows
        ? props.data.map((row) => row.id as UniqueIdentifier)
        : [],
    [props.data]
  );

  const tableInstance = useReactTable<TData>({
    autoResetPageIndex: props.autoResetPageIndex,
    columns: props.columns,
    enableColumnResizing: props.isColumnResizable ?? false,
    columnResizeMode: "onChange",
    data: props.data && props.draggableRows ? dataState : props.data,
    enableSortingRemoval: false,
    getRowId: props.draggableRows
      ? (row: TData) => row.id as string // TableData must have an `id` field for this to work.
      : undefined,
    state: {
      expanded: props.state?.expanded ?? expanded,
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel:
      props.showPagination || props.truncateRows
        ? getPaginationRowModel()
        : undefined,
    getSubRows: props.expandable ? props.getSubRows : undefined,
    getSortedRowModel: props.sortable ? getSortedRowModel() : undefined,
    getExpandedRowModel: props.expandable ? getExpandedRowModel() : undefined,
    initialState: props.initialState,
  });

  // onSortingChange
  const sortBy = tableInstance.getState().sorting ?? [];

  const totalRows = props.expandable
    ? props.data.reduce((acc, row, index) => {
        const subRows = props?.getSubRows?.(row, index) ?? [];
        return acc + 1 + subRows.length;
      }, 0)
    : props.data.length;

  useEffect(() => {
    if (tableInstance.getState().sorting && props.onChangeSortBy) {
      const sortRules = sortBy.map((sortBy) => ({
        id: sortBy.id,
        desc: !!sortBy.desc,
      }));
      props.onChangeSortBy(sortRules);
    }
  }, [sortBy?.[0]?.id, sortBy?.[0]?.desc]);

  const pinnedColumns = props.pinnedColumns ?? 0;
  const pinnedWidths = tableInstance
    .getAllColumns()
    .slice(0, pinnedColumns)
    .map((column) => column.getSize() ?? 150);

  const smallestColumnWidth = Math.min(...pinnedWidths);

  //For resizing
  const columnSizeVars = React.useMemo(() => {
    const headers = tableInstance.getFlatHeaders();
    const colSizes: { [key: string]: number } = {};

    for (let i = 0; i < headers.length; i++) {
      const header = headers[i];

      colSizes[`--header-${removeInvalidCharacters(header.id)}-size`] =
        header.getSize();
      colSizes[`--col-${removeInvalidCharacters(header.column.id)}-size`] =
        header.column.getSize();
    }
    return colSizes;
  }, [
    tableInstance.getState().columnSizingInfo,
    tableInstance.getState().columnSizing,
    tableInstance.getFlatHeaders(),
  ]);

  function getLeftPosition(index: number): number | undefined {
    if (index >= pinnedColumns) {
      return undefined;
    }

    //Shrink column sizes to smallest pinned column when more than 3 dimensions are selected
    const columnWidths =
      pinnedColumns > 3
        ? Array(pinnedColumns).fill(smallestColumnWidth)
        : pinnedWidths;

    let left = 0;

    columnWidths.forEach((width, colIndex) => {
      if (colIndex < index) {
        left += width;
      }
    });

    return left;
  }

  function handleClickRow(row: RowType<TData>): void {
    if (!props.onClick) return;
    props.onClick(row.original);
  }

  const handleChangePage = useRefFn(props.onChangePage ?? (() => {}));

  const { pagination, sorting: sortingState } = tableInstance.getState();

  // If the sorting state has changed, the contents of the page may have changed
  // This matches v7 functionality
  const sortingStateString = sortingState
    .map((state) => `${state.id}-${state.desc}`)
    .join("_");

  const rowModel = tableInstance.getRowModel();
  const rows = rowModel.rows;

  useEffect(() => {
    if (props.isLoading) {
      handleChangePage([], 0);
      return;
    }

    handleChangePage(
      rows.map((row) => row.original),
      pagination.pageIndex
    );
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    props.isLoading,
    rows,
    sortingStateString,
  ]);

  // reorder rows after drag & drop
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      setDataState((currentData) => {
        const oldIndex = dataIDs.indexOf(active.id);
        const newIndex = dataIDs.indexOf(over.id);

        const reorderedData = arrayMove(currentData, oldIndex, newIndex);

        if (props.onChangeRowOrder) {
          props.onChangeRowOrder(reorderedData);
        }

        return reorderedData;
      });
    }
  }

  //
  // Render
  //

  function TableRow<TData extends Record<string, unknown>>(
    rowProps: TableRowProps<TData>
  ) {
    const theme = useTheme();

    const { transform, transition, setNodeRef, isDragging } = useSortable({
      id: rowProps.row.original.id as UniqueIdentifier,
    });

    const dndStyles: CSSProperties = {
      opacity: isDragging ? 0.8 : 1,
      position: "relative",
      transform: CSS.Transform.toString(transform),
      transition: transition,
      zIndex: isDragging ? theme.zIndex_400 : "initial",
    };

    const clickable =
      rowProps.clickableRows && rowProps.disableRow
        ? !rowProps.disableRow(rowProps.row)
        : rowProps.clickableRows;

    const isRowDisabled = rowProps.disableRow
      ? rowProps.disableRow(rowProps.row)
      : false;

    const isRowHighlighted = rowProps.isRowHighlighted
      ? rowProps.isRowHighlighted(rowProps.row.original)
      : false;

    const isRowSelected =
      rowProps.selectedRowID !== undefined &&
      rowProps.selectedRowID === rowProps.row.original.id;

    const resourceID = rowProps.row.original.resourceID as string | undefined;
    const resourceName = rowProps.row.original.resourceName as
      | string
      | undefined;
    const resourceContext = rowProps.row.original.resourceContext as
      | { forecast: number }
      | undefined;

    function handleClickRow() {
      if (!clickable || !rowProps.onClickRow) return;

      rowProps.onClickRow(rowProps.row);
    }

    function renderCell(params: {
      cell: CellType<TData, unknown>;
      index: number;
      isDisabled: boolean;
      isHighlighted: boolean;
      isSelected: boolean;
    }) {
      const { cell, index } = params;
      const { align, borderStyle, disableClick, truncate } =
        cell.column.columnDef.meta ?? {};
      const key = cell.id;
      const value = cell.getValue();

      return (
        <StyledCell
          key={key}
          align={align}
          borderStyle={borderStyle}
          disabled={params.isDisabled}
          footer={rowProps.footer}
          isCellMasked={key.toString().includes(COMPARISON_KEY)}
          isHighlighted={params.isHighlighted}
          isSelected={params.isSelected}
          left={rowProps.getLeftPosition(index)}
          role="cell"
          style={{
            width: `calc(var(--col-${removeInvalidCharacters(cell.column.id)}-size) * 1px)`,
            zIndex: rowProps.isColumnResizable ? 0 - index : undefined,
          }}
          size={cell.column.getSize()}
          onClick={disableClick ? (event) => event.stopPropagation() : noop}
        >
          {truncate ? (
            <Box width="100%">
              <Tooltip
                hide={typeof value !== "string"}
                content={typeof value === "string" ? value : ""}
                delayHide={250}
              >
                <Text
                  color={
                    params.isSelected
                      ? theme.text_color_inverse
                      : theme.text_color
                  }
                  truncate
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Text>
              </Tooltip>
            </Box>
          ) : (
            flexRender(cell.column.columnDef.cell, cell.getContext())
          )}
        </StyledCell>
      );
    }

    return (
      <StyledRow
        ref={rowProps.draggableRows ? setNodeRef : undefined}
        alternate={rowProps.alternateRows}
        clickable={clickable}
        compact={rowProps.compact}
        dndStyles={props.draggableRows ? dndStyles : undefined}
        footer={rowProps.footer}
        height={rowProps.rowHeight}
        isHighlighted={isRowHighlighted}
        isLastRow={rowProps.isLastRow}
        role="row"
        onClick={handleClickRow}
      >
        {rowProps.resourceType && resourceID && resourceName && (
          <ResourceSelector
            key={rowProps.row.id}
            resourceID={resourceID}
            resourceContext={resourceContext}
            resourceName={resourceName}
            resourceType={rowProps.resourceType}
          />
        )}

        {rowProps.row.getVisibleCells().map((cell, index) => {
          return renderCell({
            cell,
            index,
            isDisabled: isRowDisabled,
            isHighlighted: isRowHighlighted,
            isSelected: isRowSelected,
          });
        })}
      </StyledRow>
    );
  }

  function renderColumnResizer(header: Header<TData, unknown>) {
    if (!props.isColumnResizable) return;

    return (
      <Resizer
        {...{
          className: `resizer ${tableInstance.options.columnResizeDirection} ${
            header.column.getIsResizing() ? "isResizing" : ""
          }`,
          onClick: (event) => event.stopPropagation(),
          onDoubleClick: () => header.column.resetSize(),
          onMouseDown: header.getResizeHandler(),
          onTouchStart: header.getResizeHandler(),
        }}
      />
    );
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  if (props.isLoading) {
    return (
      <LoadingTable
        alternateRows={props.alternateRows}
        columns={props.columns}
        compact={props.compact}
        footer={props.footer}
        footerDelta={props.footerDelta}
        rowHeight={props.rowHeight}
        rowLimit={
          props.placeholderRowLimit
            ? props.placeholderRowLimit
            : props.showPagination
              ? pagination.pageSize
              : undefined
        }
        showPagination={props.showPagination}
      />
    );
  }

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      sensors={sensors}
      onDragEnd={handleDragEnd}
    >
      <TableContext.Provider value={{ columnSizeVars }}>
        <StyledTable
          compact={props.compact}
          role="table"
          style={{ ...columnSizeVars }}
          width="100%"
        >
          <thead
            style={{
              position: props.showPagination ? "inherit" : "sticky",
              top: 0,
              zIndex: 2,
            }}
          >
            {tableInstance.getHeaderGroups().map((headerGroup) => {
              return (
                <HeaderRow
                  key={headerGroup.id}
                  alternate={props.alternateRows}
                  compact={props.compact}
                  role="row"
                >
                  {headerGroup.headers.map((header, columnIndex) => {
                    const column = header.column;

                    const { truncate } = column.columnDef.meta ?? {};

                    const align = column.columnDef.meta?.align;
                    const borderStyle = column.columnDef.meta?.borderStyle;
                    const canSort = !!props.sortable && column.getCanSort();
                    const isSorted = !!column.getIsSorted();
                    const isSortedDesc = column.getIsSorted() === "desc";
                    const size = column.getSize();
                    const sortArrow =
                      canSort && isSorted ? (
                        <Box marginHorizontal={theme.space_xs}>
                          <FontAwesomeIcon
                            color={theme.primary_color_text}
                            icon={isSortedDesc ? faArrowDown : faArrowUp}
                          />
                        </Box>
                      ) : null;
                    return (
                      <HeaderCell
                        align={align}
                        borderStyle={borderStyle}
                        colSpan={header.colSpan}
                        isPinned={props.pinnedColumns !== 0}
                        key={header.id}
                        left={getLeftPosition(columnIndex)}
                        role="columnheader"
                        sortable={canSort}
                        size={size}
                        style={{
                          width: `calc(var(--header-${removeInvalidCharacters(header.id)}-size) * 1px)`,
                        }}
                        zIndex={
                          props.isColumnResizable
                            ? headerGroup.headers.length - columnIndex
                            : undefined
                        }
                      >
                        <Flex
                          onClick={
                            canSort
                              ? header.column.getToggleSortingHandler()
                              : noop
                          }
                        >
                          {align === "right" ? sortArrow : null}
                          {truncate ? (
                            <Box width="100%">
                              <Tooltip
                                content={
                                  typeof column.columnDef.header === "string"
                                    ? column.columnDef.header
                                    : ""
                                }
                                delayHide={250}
                                hide={
                                  typeof column.columnDef.header !== "string"
                                }
                              >
                                <Text align={align ?? "left"} truncate>
                                  {flexRender(
                                    column.columnDef.header,
                                    header.getContext()
                                  )}
                                </Text>
                              </Tooltip>
                            </Box>
                          ) : (
                            flexRender(
                              column.columnDef.header,
                              header.getContext()
                            )
                          )}
                          {align !== "right" ? sortArrow : null}
                        </Flex>
                        {renderColumnResizer(header)}
                      </HeaderCell>
                    );
                  })}
                </HeaderRow>
              );
            })}
          </thead>

          {/* BODY */}
          <tbody role="rowgroup">
            {rows.length === 0 && (
              <tr>
                <EmptyPlaceholder
                  height={500}
                  icon={faList}
                  loading={false}
                  text={copyText.noDataPlaceholderMessage}
                />
              </tr>
            )}

            {/* ROWS */}
            <SortableContext
              items={dataIDs}
              strategy={verticalListSortingStrategy}
            >
              {rows.map((row, index) => {
                const isLastRow = index + 1 === rows.length;

                return (
                  <TableRow
                    key={row.id}
                    alternateRows={props.alternateRows}
                    clickableRows={props.clickableRows}
                    compact={props.compact}
                    disableRow={props.disableRow}
                    draggableRows={props.draggableRows}
                    footer={props.footer}
                    getLeftPosition={getLeftPosition}
                    isColumnResizable={props.isColumnResizable}
                    isLastRow={isLastRow}
                    isRowHighlighted={props.isRowHighlighted}
                    resourceType={props.resourceType}
                    row={row}
                    selectedRowID={props.selectedRowID}
                    onClickRow={handleClickRow}
                  />
                );
              })}
            </SortableContext>
          </tbody>

          {/* FOOTER */}
          {props.footer && (
            <tfoot>
              {tableInstance.getFooterGroups().map((group) => {
                return (
                  <FooterRow
                    key={group.id}
                    compact={props.compact}
                    footerDelta={props.footerDelta}
                  >
                    {group.headers.map((footer, i: number) => {
                      const align = footer.column.columnDef.meta?.align;

                      return (
                        <FooterCell
                          key={footer.id}
                          align={align}
                          colSpan={footer.colSpan}
                          left={getLeftPosition(i)}
                          style={{
                            width: `calc(var(--col-${removeInvalidCharacters(footer.column.id)}-size) * 1px)`,
                          }}
                          size={footer.column.getSize()}
                          isSelected={false}
                        >
                          <Box width="100%">
                            {i === 0
                              ? copyText.dataTableTotalsHeader
                              : flexRender(
                                  footer.column.columnDef.footer,
                                  footer.getContext()
                                )}
                            {props.footerDelta && i === 0 && (
                              <Text color={theme.text_color} truncate>
                                {copyText.dataTableTotalsDeltasHeader}
                              </Text>
                            )}
                          </Box>
                        </FooterCell>
                      );
                    })}
                  </FooterRow>
                );
              })}
            </tfoot>
          )}
        </StyledTable>
        {totalRows > 0 && props.showPagination ? (
          <Pagination
            currentPageIndex={pagination.pageIndex}
            loading={false}
            pageSize={pagination.pageSize}
            totalPageCount={tableInstance.getPageCount()}
            totalRows={totalRows}
            onPageChange={(page) => tableInstance.setPageIndex(page)}
          />
        ) : null}
      </TableContext.Provider>
    </DndContext>
  );
}

export function Skeleton(props: { height: string; theme: Theme }): JSX.Element {
  const RADIUS = Number(props.theme.borderRadius_1.replace("px", ""));

  return (
    <ContentLoader
      backgroundColor={props.theme.loading_skeleton_bg_color}
      foregroundColor={props.theme.loading_skeleton_fg_color}
      speed={SKELETON_ANIMATION_TIME}
      viewBox="0 0 300 100"
      preserveAspectRatio="none"
      style={{
        width: "80%",
        height: props.height,
      }}
    >
      <rect x="0" y="0" rx={RADIUS} ry={RADIUS} width="300" height="90" />
    </ContentLoader>
  );
}

//
// Loading Table
//

type LoadingData = {
  loading: true;
};

type LoadingTableProps<TData extends Record<string, unknown>> = {
  alternateRows?: boolean;
  columns: ColumnDef<TData>[];
  compact?: boolean;
  footer?: boolean;
  footerDelta?: boolean;
  rowHeight?: string;
  rowLimit?: number;
  showPagination?: boolean;
};

function LoadingTable<TData extends Record<string, unknown>>(
  props: LoadingTableProps<TData>
) {
  const theme = useTheme();

  const loadingColumnHelper = createColumnHelper<LoadingData>();

  const loadingColumns = useMemo(
    () =>
      props.columns.map((column, index) =>
        loadingColumnHelper.display({
          id: `loading-${index}`,
          cell: () => <Skeleton height={theme.h3_fontSize} theme={theme} />,
          header: typeof column.header === "string" ? column.header : "",
          meta: { align: column.meta?.align },
          size: column.size,
        })
      ),
    [props.columns]
  );

  const loadingData = useMemo<LoadingData[]>(
    () => new Array(10).fill({ loading: true }),
    []
  );

  const loadingTableInstance = useReactTable<LoadingData>({
    columns: loadingColumns,
    data: loadingData,
    getCoreRowModel: getCoreRowModel(),
  });

  const loadingRowModel = loadingTableInstance.getRowModel();
  const loadingRows = props.rowLimit
    ? loadingRowModel.rows.slice(0, props.rowLimit)
    : loadingRowModel.rows;

  return (
    <StyledTable compact={props.compact} role="table" width="100%">
      <thead
        style={{
          position: props.showPagination ? "inherit" : "sticky",
          top: 0,
          zIndex: 2,
        }}
      >
        {loadingTableInstance.getHeaderGroups().map((headerGroup) => (
          <HeaderRow key={headerGroup.id} compact={props.compact} role="row">
            {headerGroup.headers.map((header) => {
              const column = header.column;

              const align = column.columnDef.meta?.align;
              const size = column.getSize();

              return (
                <HeaderCell
                  align={align}
                  colSpan={header.colSpan}
                  isPinned={false}
                  key={header.id}
                  role="columnheader"
                  size={size}
                  sortable={false}
                >
                  <Flex>
                    {flexRender(column.columnDef.header, header.getContext())}
                  </Flex>
                </HeaderCell>
              );
            })}
          </HeaderRow>
        ))}
      </thead>

      {/* BODY */}
      <tbody role="rowgroup">
        {loadingRows.map((row) => (
          <StyledRow
            key={row.id}
            alternate={props.alternateRows}
            clickable={false}
            compact={props.compact}
            footer={props.footer}
            height={props.rowHeight}
            role="row"
          >
            {row.getVisibleCells().map((cell) => (
              <StyledCell
                key={cell.id}
                align={cell.column.columnDef.meta?.align}
                footer={props.footer}
                isSelected={false}
                role="cell"
                size={cell.column.getSize()}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </StyledCell>
            ))}
          </StyledRow>
        ))}
      </tbody>

      {/* FOOTER */}
      {props.footer && (
        <tfoot>
          {loadingTableInstance.getFooterGroups().map((group) => {
            return (
              <FooterRow
                key={group.id}
                compact={props.compact}
                footerDelta={props.footerDelta}
              >
                {group.headers.map((footer, i: number) => {
                  const align = footer.column.columnDef.meta?.align;
                  const size = footer.column.getSize();

                  return (
                    <FooterCell
                      key={footer.id}
                      colSpan={footer.colSpan}
                      size={size}
                      align={align}
                      isSelected={false}
                    >
                      <Box width="100%">
                        {i === 0
                          ? copyText.dataTableTotalsHeader
                          : flexRender(
                              footer.column.columnDef.footer,
                              footer.getContext()
                            )}
                        {props.footerDelta && i === 0 && (
                          <Text color={theme.text_color} truncate>
                            {copyText.dataTableTotalsDeltasHeader}
                          </Text>
                        )}
                      </Box>
                    </FooterCell>
                  );
                })}
              </FooterRow>
            );
          })}
        </tfoot>
      )}
    </StyledTable>
  );
}

export function ActionMenuButton(props: { children?: ReactNode }) {
  const {
    children = createElement(Icon, { icon: faEllipsisV }),
    ...restProps
  } = props;

  return <StyledActionMenuButton children={children} {...restProps} />;
}
