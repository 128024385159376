import copyText from "../../copyText";
import { FormData } from "./types";
export const BigQueryScope = {
  JOBS_BY_FOLDER: "JOBS_BY_FOLDER",
  JOBS_BY_PROJECT: "JOBS_BY_PROJECT",
};

export const defaultValues: FormData = {
  // General
  monitoringRoleARN: "",
  name: "",
  roleARN: "",

  // Linked Accounts
  linkedAccounts: [],
  linkedAccountARN: "",

  // Cost And Usage Report
  configuredCURs: [],
  nameCURs: "",
  regionCURs: null,
  reportPathPrefixCURs: "",
  s3BucketNameCURs: "",
};
export const AWS_ROLE_ARN_REGEX = /arn:aws:iam::[0-9]+:role\/.*/;
export const AWS_ROLE_ARN_ACCOUNT_ID_REGEX = /^arn:aws:iam::(\d+):role\/.+$/;
export const AWS_BUCKET_NAME_REGEX =
  /(?!^(\d{1,3}\.){3}\d{1,3}$)(^[a-z0-9]([a-z0-9-]*(\.[a-z0-9])?)*$)/;
export const AWS_REPORT_PATH_REGEX = /([^./]$)+/;

export const regionLocations = [
  "ap-northeast-1",
  "ap-northeast-2",
  "ap-south-1",
  "ap-southeast-1",
  "ap-southeast-2",
  "ca-central-1",
  "eu-central-1",
  "eu-north-1",
  "eu-west-1",
  "eu-west-2",
  "eu-west-3",
  "sa-east-1",
  "us-east-1",
  "us-east-2",
  "us-gov-east-1",
  "us-gov-west-1",
  "us-west-1",
  "us-west-2",
];

export const validatesBucketName = (value: string) =>
  !AWS_BUCKET_NAME_REGEX.test(value)
    ? copyText.awsFormBucketNameValidationErrorCaption
    : undefined;

export const validatesReportPath = (value: string) =>
  !AWS_REPORT_PATH_REGEX.test(value)
    ? copyText.awsFormReportPathValidationErrorCaption
    : undefined;
