import { buildCubeQuery } from "@/api/analytics/utils";
import { validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import {
  GCPComputeInstanceGroup,
  GCPComputeInstanceGroupStruct,
} from "../types";

export interface Params {
  dateRange: Date[];
}

export default function useGetGCPComputeInstanceGroups(
  params: Params,
  options?: UseQueryOptions<GCPComputeInstanceGroup[], UError>
): UseQueryResult<GCPComputeInstanceGroup[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["gcpComputeInstanceGroups", params],
    queryFn: async () => {
      const dimensions = ["family", "region", "projectId", "familyType"];

      const measures = [
        "cpuUtilization",
        "instanceIDCount",
        "productMemoryBytes",
        "productVCPU",
        "provisionedDiskBytes",
        "totalCost",
      ];

      const result = await client.load(
        buildCubeQuery({
          dataSource: DataSource.GCP_COMPUTE_INSTANCES,
          dateRange: params.dateRange,
          dimensions,
          measures,
        })
      );

      const instanceGroups = result.map((datum): GCPComputeInstanceGroup => {
        const [error, validData] = validate(
          datum,
          GCPComputeInstanceGroupStruct
        );

        if (error) {
          throw new UError("INVALID_GCP_COMPUTE_TABLE_DATUM", {
            context: { error, result: datum },
          });
        }

        return {
          // DIMENSIONS
          family: validData.family ?? "",
          familyType: validData.familyType ?? "",
          projectId: validData.projectId ?? "",
          region: validData.region ?? "",

          // MEASURES
          instanceIDCount: validData.instanceIDCount ?? 0,
          productMemoryBytes: validData.productMemoryBytes ?? 0,
          productVCPU: validData.productVCPU ?? 0,
          provisionedDiskBytes: validData.provisionedDiskBytes ?? 0,
          totalCost: validData.totalCost ?? 0,
        };
      });

      return instanceGroups;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
