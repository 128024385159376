import ErrorBoundary from "@/components/ErrorBoundary";
import Tabs from "@/ui-lib/components/Tabs";
import { useTheme } from "@emotion/react";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React from "react";
import { Navigate } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import paths from "../../../../constants/paths";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import _copyText from "../copyText";
import { MspBillingStatementGlobalSettingsContainer } from "./MspBillingStatementGlobalSettingsContainer";
import { MspBillingStatementTenantsContainer } from "./MspBillingStatementTenantsContainer";
import { useFeatureText } from "@/context/FeatureTextProvider";

const TAB_BILL_SETTINGS = "settings";
const TAB_TENANTS = "tenants";

export function MspBillingStatementsPage(): JSX.Element {
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  const { copyText } = useFeatureText(_copyText);

  const [searchParamState, setSearchParamState] = useQueryParams({
    tab: withDefault(StringParam, TAB_TENANTS),
  });

  //
  // State
  //

  //
  // Render
  //

  if (!gatekeeper.canAccessMspAdmin) {
    return <Navigate to={paths._home} replace />;
  }

  const containers = [
    {
      component: <MspBillingStatementTenantsContainer />,
      label: copyText.tabLabelTenants,
      value: TAB_TENANTS,
    },
    {
      component: <MspBillingStatementGlobalSettingsContainer />,
      label: copyText.tabLabelGlobalBillSettings,
      value: TAB_BILL_SETTINGS,
    },
  ];

  return (
    <>
      <ErrorBoundary boundaryName="MspBillingStatementsPage">
        <Text appearance="h3" marginBottom={theme.space_lg}>
          Billing Statements
        </Text>
        <Tabs
          activeValue={searchParamState.tab}
          tabs={containers}
          onSelect={(tab: string) => setSearchParamState({ tab })}
        />
      </ErrorBoundary>
    </>
  );
}
