import { DateHelper } from "@/lib/dates";
import ConfirmationModal from "@/ui-lib/components/ConfirmationModal";
import { FormField } from "@/ui-lib/components/Form";
import Select from "@/ui-lib/components/Select";
import Switch from "@/ui-lib/components/Switch";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import { faEdit, faFolder, faTimes } from "@fortawesome/free-solid-svg-icons";
import type { DeepKeys, FieldApi } from "@tanstack/react-form";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { some } from "lodash";
import React, { MouseEvent, useEffect, useState } from "react";
import { DatePicker } from "../../../../../ui-lib/components/DateRangeControls/DatePicker";
import copyText from "../../copyText";
import {
  defaultValues as _defaultValues,
  AZURE_INTEGRATION_BILLING_TYPES,
} from "./constants";
import type { BillingExport, FormData, FormProps } from "./types";

const validators = {
  exportType: ({ value }: { value: string }) => {
    if (value.length === 0) {
      return copyText.errorInputFieldRequired;
    }

    return !AZURE_INTEGRATION_BILLING_TYPES.find((e) => e.value === value)
      ? copyText.errorInputFieldRequired
      : undefined;
  },
  serviceURL: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
  storageContainer: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
  storagePrefix: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
};

type DateFields =
  | FieldApi<
      FormData,
      "billingConfig.startDate",
      undefined,
      undefined,
      string | null
    >
  | FieldApi<
      FormData,
      "billingConfig.endDate",
      undefined,
      undefined,
      string | null
    >;

function DateField(props: { field: DateFields; label: string }) {
  const { field, label } = props;
  const now = new DateHelper();
  const value = field.state.value;
  const selected =
    typeof value === "string" && value?.length > 0 ? new Date(value) : null;

  return (
    <FormField label={label}>
      <DatePicker
        dateFormat="MM/dd/yyyy"
        minDate={now.date}
        onChange={(date) => {
          field.handleChange((date && date.toISOString().split("T")[0]) ?? "");
        }}
        selected={selected}
      />
    </FormField>
  );
}

function BillingRow(props: {
  data: BillingExport;
  onEdit: () => void;
  onRemove: () => void;
  disableActionButtons?: boolean;
}) {
  const { data, onEdit, onRemove, disableActionButtons } = props;
  const theme = useTheme();
  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex alignItems="center">
        <Flex justifyContent="center" minWidth={20}>
          <Icon color={theme.text_color} icon={faFolder} />
        </Flex>
        <Flex marginLeft={theme.space_xs} style={{ maxWidth: "40vw" }}>
          <Text fontSize={theme.fontSize_base} truncate>
            {data.exportType}
          </Text>
          ,
          <Text fontSize={theme.fontSize_base} truncate>
            {data.serviceURL}
          </Text>
          ,
          <Text fontSize={theme.fontSize_base} truncate>
            {data.storageContainer}
          </Text>
          ,
          <Text fontSize={theme.fontSize_base} truncate>
            {data.storagePrefix}
          </Text>
          {typeof data.startDate === "string" && data.startDate && (
            <>
              ,
              <Text fontSize={theme.fontSize_base} truncate>
                {formatDate(new Date(data.startDate), "MM/dd/yyyy")}
              </Text>
            </>
          )}
          {typeof data.endDate === "string" && data.endDate && (
            <>
              ,
              <Text fontSize={theme.fontSize_base} truncate>
                {formatDate(new Date(data.endDate), "MM/dd/yyyy")}
              </Text>
            </>
          )}
          {data.dateColumn && (
            <>
              ,
              <Text fontSize={theme.fontSize_base} truncate>
                {data.dateColumn}
              </Text>
            </>
          )}
        </Flex>
      </Flex>

      <Box>
        <Button
          disabled={disableActionButtons}
          iconEnd={<Icon icon={faEdit} height={"24px"} />}
          secondary
          size="tiny"
          onClick={onEdit}
        />
        <Button
          disabled={disableActionButtons}
          iconEnd={<Icon icon={faTimes} height={"24px"} />}
          secondary
          size="tiny"
          onClick={onRemove}
        />
      </Box>
    </Flex>
  );
}

export default function AzureIntegrationFormBillingExports(
  props: FormProps
): JSX.Element {
  const theme = useTheme();
  const { form } = props;

  const requiredKeys: Array<DeepKeys<FormData>> = [
    "billingConfig.exportType",
    "billingConfig.serviceURL",
    "billingConfig.storageContainer",
    "billingConfig.storagePrefix",
  ];

  //
  // State
  //

  const [editRowIdx, setEditRowIdx] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  //
  // Side Effects
  //

  useEffect(() => {
    return () => {
      // Reset billing form on unmount for the submit button.
      setFieldErrors(false);
    };
  }, []);

  //
  // Interaction Handlers
  //

  function handleEditRow() {
    if (editRowIdx === null) {
      return;
    }

    const value = form.getFieldValue("billingConfig")[editRowIdx];
    form.setFieldValue("billingConfig", value);
  }

  function setFieldErrors(error: boolean) {
    for (const key of requiredKeys) {
      form.setFieldMeta(key, {
        errors: error ? [copyText.errorInputFieldRequired] : [],
        errorMap: error ? { onMount: copyText.errorInputFieldRequired } : {},
        isBlurred: false,
        isDirty: false,
        isPristine: true,
        isTouched: false,
        isValidating: false,
      });
    }
  }

  return (
    <form.Field
      name="billingExports"
      mode="array"
      children={(configsField) => (
        <Flex padding={theme.space_xxs}>
          <Box width="80%">
            <Flex gap={theme.space_sm} direction="row" alignItems="center">
              <form.Field
                name={"billingConfig.exportType"}
                validators={{
                  onChange: validators.exportType,
                  onMount: validators.exportType,
                }}
                children={(field) => {
                  const shouldShowError =
                    field.state.meta.isTouched &&
                    field.state.meta.errors.length > 0;

                  return (
                    <Flex style={{ width: "100%" }}>
                      <FormField
                        label={copyText.azureFormBillingExportTypeInputLabel}
                        errorCaption={
                          shouldShowError
                            ? field.state.meta.errors.join(", ")
                            : undefined
                        }
                      >
                        <Select
                          isSearchable
                          options={AZURE_INTEGRATION_BILLING_TYPES}
                          value={
                            AZURE_INTEGRATION_BILLING_TYPES.find(
                              (option) => option.value === field.state.value
                            ) || null
                          }
                          onChange={(option) =>
                            option && field.handleChange(option.value)
                          }
                        />
                      </FormField>
                    </Flex>
                  );
                }}
              />
              <form.Field
                name={`billingConfig.strictStoragePrefix`}
                children={(field) => {
                  return (
                    <Flex>
                      <FormField
                        label={
                          <Text style={{ textWrap: "nowrap" }}>
                            {
                              copyText.azureFormBillingStrictStoragePrefixInputLabel
                            }
                          </Text>
                        }
                      >
                        <Switch
                          checked={field.state.value}
                          onChange={(checked) => field.handleChange(checked)}
                        />
                      </FormField>
                    </Flex>
                  );
                }}
              />
            </Flex>
            <form.Field
              name="billingConfig.serviceURL"
              validators={{
                onChange: validators.serviceURL,
                onMount: validators.serviceURL,
              }}
              children={(field) => {
                const shouldShowError =
                  field.state.meta.isTouched &&
                  field.state.meta.errors.length > 0;

                return (
                  <FormField
                    input={TextInput}
                    errorCaption={
                      shouldShowError
                        ? field.state.meta.errors.join(", ")
                        : undefined
                    }
                    label={copyText.azureFormBillingServiceURLInputLabel}
                    required
                    value={field.state.value}
                    variant={shouldShowError ? "danger" : undefined}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                  />
                );
              }}
            />
            <form.Field
              name="billingConfig.storageContainer"
              validators={{
                onChange: validators.storageContainer,
                onMount: validators.storageContainer,
              }}
              children={(field) => {
                const shouldShowError =
                  field.state.meta.isTouched &&
                  field.state.meta.errors.length > 0;

                return (
                  <FormField
                    input={TextInput}
                    errorCaption={
                      shouldShowError
                        ? field.state.meta.errors.join(", ")
                        : undefined
                    }
                    label={copyText.azureFormBillingStorageContainerInputLabel}
                    required
                    value={field.state.value}
                    variant={shouldShowError ? "danger" : undefined}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                  />
                );
              }}
            />
            <form.Field
              name="billingConfig.storagePrefix"
              validators={{
                onChange: validators.storagePrefix,
                onMount: validators.storagePrefix,
              }}
              children={(field) => {
                const shouldShowError =
                  field.state.meta.isTouched &&
                  field.state.meta.errors.length > 0;

                return (
                  <FormField
                    input={TextInput}
                    errorCaption={
                      shouldShowError
                        ? field.state.meta.errors.join(", ")
                        : undefined
                    }
                    label={copyText.azureFormBillingStoragePrefixInputLabel}
                    required
                    value={field.state.value}
                    variant={shouldShowError ? "danger" : undefined}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                  />
                );
              }}
            />
            <Flex gap={theme.space_sm}>
              <form.Field
                name="billingConfig.startDate"
                children={(field) => {
                  return (
                    <DateField
                      field={field}
                      label={copyText.azureFormBillingStartDateInputLabel}
                    />
                  );
                }}
              />

              <form.Field
                name="billingConfig.endDate"
                children={(field) => {
                  return (
                    <DateField
                      field={field}
                      label={copyText.azureFormBillingEndDateInputLabel}
                    />
                  );
                }}
              />
            </Flex>
            <form.Field
              name="billingConfig.dateColumn"
              children={(field) => {
                return (
                  <FormField
                    input={TextInput}
                    label={copyText.azureFormBillingDateColumnInputLabel}
                    value={field.state.value || ""}
                    onChange={(e) => field.handleChange(e.target.value)}
                  />
                );
              }}
            />

            <form.Subscribe
              children={(state) => {
                function clearFieldValues() {
                  form.setFieldValue(
                    "billingConfig",
                    _defaultValues.billingConfig
                  );
                  setEditRowIdx(null);
                }

                function handleCancelConfig(event: MouseEvent) {
                  event.preventDefault();
                  event.stopPropagation();

                  clearFieldValues();
                  setFieldErrors(true);
                }

                function handleAddConfig(event: MouseEvent) {
                  event.preventDefault();
                  event.stopPropagation();

                  // Add completed config to array.
                  configsField.pushValue(form.getFieldValue("billingConfig"));
                  clearFieldValues();
                  setFieldErrors(true);
                }

                function handleSaveConfig(event: MouseEvent) {
                  event.preventDefault();
                  event.stopPropagation();
                  if (editRowIdx === null) {
                    return;
                  }
                  configsField.replaceValue(
                    editRowIdx,
                    form.getFieldValue("billingConfig")
                  );
                  clearFieldValues();
                  setFieldErrors(true);
                }

                const disabled =
                  state.values.billingConfig.exportType?.length === 0 ||
                  state.values.billingConfig.serviceURL.length === 0 ||
                  state.values.billingConfig.storageContainer.length === 0 ||
                  state.values.billingConfig.storagePrefix.length === 0;

                return (
                  <>
                    {isOpen && (
                      <ConfirmationModal
                        message={copyText.azureFormEditModalMessage}
                        title={copyText.azureFormEditConfirmationTitle}
                        variant="danger"
                        onConfirm={(event) => {
                          if (event) {
                            handleEditRow();
                            setIsOpen(false);
                          }
                        }}
                        onCancel={() => setIsOpen(false)}
                      />
                    )}
                    {editRowIdx !== null ? (
                      <Flex>
                        <Button
                          marginRight={theme.space_xs}
                          size="small"
                          type="button"
                          onClick={handleCancelConfig}
                        >
                          {copyText.actionCancel}
                        </Button>
                        <Button
                          disabled={disabled}
                          size="small"
                          type="button"
                          primary
                          onClick={handleSaveConfig}
                        >
                          {copyText.actionSave}
                        </Button>
                      </Flex>
                    ) : (
                      <Button
                        disabled={disabled}
                        size="small"
                        type="button"
                        primary
                        onClick={handleAddConfig}
                      >
                        {copyText.alibabaFormAddReportButtonLabel}
                      </Button>
                    )}
                    <Text
                      marginVertical={theme.space_xs}
                      fontSize={theme.h4_fontSize}
                      fontWeight={theme.h4_fontWeight}
                    >
                      {copyText.alibabaFormCreatedReportsHeader.replace(
                        "%reports%",
                        `${state.values.billingExports.length}`
                      )}
                    </Text>
                    <Flex direction="column" paddingVertical={theme.space_xxs}>
                      {configsField.state.value.map((config, index) => (
                        <BillingRow
                          key={`billing-row-${index}`}
                          data={config}
                          disableActionButtons={index === editRowIdx}
                          onEdit={() => {
                            setEditRowIdx(index);

                            const currTypedAccount =
                              form.getFieldValue("billingConfig");

                            const showModal = some(
                              currTypedAccount,
                              (value) => {
                                return !!value;
                              }
                            );

                            if (showModal) {
                              setIsOpen(true);
                            } else {
                              form.setFieldValue("billingConfig", config);
                              setFieldErrors(false);
                            }
                          }}
                          onRemove={() => {
                            configsField.removeValue(index);
                          }}
                        />
                      ))}
                    </Flex>
                  </>
                );
              }}
            />
          </Box>
        </Flex>
      )}
    />
  );
}
