import { useTheme } from "@emotion/react";
import { faChartArea, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { toZonedTime } from "date-fns-tz";
import { enUS } from "date-fns/locale";
import React, { useMemo } from "react";
import {
  Area,
  AreaChart,
  Tooltip as ChartTooltip,
  ReferenceLine,
  XAxis,
  YAxis,
} from "recharts";
import { BudgetThresholdWarningType } from "../../constants/enums";
import Box from "../../ui-lib/components/Box";
import EmptyPlaceholder from "../../ui-lib/components/EmptyPlaceholder";
import Flex from "../../ui-lib/components/Flex";
import Text from "../../ui-lib/components/Text";
import { Tooltip } from "../../ui-lib/components/Tooltip";
import timing from "../../ui-lib/constants/timing";
import copyText from "../../ui-lib/copyText";
import { REPORT_PDF_CHART_HEIGHT, REPORT_PDF_CHART_WIDTH } from "../constants";
import { UnitType } from "../enums";
import { formatMeasureValueWithUnit } from "../utils/ChartFormatUtils";
import { formatDate } from "../utils/DateUtils";
import { formatCurrencyRounded } from "../utils/NumberFormatUtils";
import { getAmountFromBudget } from "./BudgetsCurrentDailyGraph";
import { ChartWrapper } from "./ChartWrapper";
import TimeSeriesChartTooltip from "./TimeSeriesChartTooltip";

type BudgetSpendDatum = {
  cost?: number;
  customNetCost?: number;
  netCost?: number;
  timestamp: string;
};

type BudgetScope = {
  key: string;
  values: string[];
};

type BudgetThreshold = {
  percent: number;
  warningType?: BudgetThresholdWarningType;
};

type BudgetStatus = {
  forecasted: number;
};

type Budget = {
  amount: {
    specifiedAmount: { amount: number } | null;
    variableAmount: Record<string, never> | null;
  };
  measure: string;
  periodVersions: PeriodVersion[];
  scopes?: BudgetScope[];
  status: BudgetStatus | null;
  thresholds: BudgetThreshold[];
};

type PeriodVersion = {
  amount: number;
  creationTime: string;
  period: { month: string };
};

interface Props {
  budget: Budget | null;
  data: BudgetSpendDatum[];
  height: number | string;
  hideTitle?: boolean;
  isServer?: boolean;
  loading: boolean;
}

export default function BudgetsHistoricalDailyGraph(props: Props): JSX.Element {
  const theme = useTheme();

  // Each element represents a day number, and has all months' spends for that day
  const {
    data,
    monthNumbers,
  }: {
    data: { [monthNumber: string]: number; dayNumber: number }[];
    monthNumbers: string[];
  } = useMemo(() => {
    const monthNumbers: Record<string, boolean> = {};
    const dict = props.data.reduce<{
      [dayNumber: number]: { [monthNumber: string]: number };
    }>((accum, spend) => {
      const date = toZonedTime(new Date(spend.timestamp), "UTC");
      const dayNumber = date.getDate();
      const monthNumber = formatDate(date, "MM");

      const measure = (props.budget?.measure ?? "cost") as
        | "cost"
        | "customNetCost"
        | "netCost";

      const applicableSpend = spend[measure];

      if (typeof applicableSpend !== "number") return accum;

      if (!accum[dayNumber]) {
        accum[dayNumber] = { [monthNumber]: applicableSpend };
      } else {
        accum[dayNumber][monthNumber] = applicableSpend;
      }

      if (!monthNumbers[monthNumber]) {
        monthNumbers[monthNumber] = true;
      }
      return accum;
    }, {});

    const data = Object.keys(dict)
      .map((dayNumber) => ({
        dayNumber: Number(dayNumber),
        ...dict[Number(dayNumber)],
      }))
      .sort((a, b) => a.dayNumber - b.dayNumber);

    return {
      data,
      monthNumbers: Object.keys(monthNumbers).sort(
        (a, b) => Number(a) - Number(b)
      ),
    };
  }, [props.data, props.budget]);

  const ticks = [5, 10, 15, 20, 25, 30];

  const spendKeyedByDayNumber = useMemo(
    () =>
      data.reduce<Record<string, { [monthName: string]: number }>>(
        (accum, datum) => {
          const obj: { [monthName: string]: number } = {};
          Object.keys(datum).forEach((key) => {
            if (key === "dayNumber") return;
            obj[getMonthNameForMonthNumber(Number(key))] = datum[key];
          });
          accum[datum.dayNumber] = obj;
          return accum;
        },
        {}
      ),
    [data]
  );

  const monthNames = useMemo(
    () => monthNumbers.map((num) => getMonthNameForMonthNumber(Number(num))),
    [monthNumbers]
  );

  const shouldRenderChart = data.length > 0 && props.budget && !props.loading;
  const monthNumbersReversed = monthNumbers.reverse();

  const budgetAmount = props.budget
    ? getAmountFromBudget(props.budget) || Infinity
    : Infinity;

  const handleDailySpendWarning = () => {
    if (props.budget) {
      return props.budget.thresholds.map((threds, index) => {
        if (threds.warningType === BudgetThresholdWarningType.DAILY_SPEND) {
          const wanrningAmount = budgetAmount * (threds.percent / 100);
          return (
            <ReferenceLine
              key={index}
              y={wanrningAmount}
              stroke={theme.feedback_negative}
              strokeWidth={2}
              strokeDasharray="4 4"
            />
          );
        }
      });
    }
  };

  return (
    <Box height={props.hideTitle ? "100%" : "90%"} width="100%">
      {!props.hideTitle && (
        <Flex
          alignItems="center"
          marginBottom={theme.space_xs}
          marginLeft={theme.space_md}
        >
          <Text appearance="h4">
            {copyText.budgetsHistoricalDailyGraphTitle}
          </Text>
          <Tooltip
            content={copyText.budgetsHistoricalDailyGraphTooltip}
            icon={faInfoCircle}
            width={"12rem"}
          />
        </Flex>
      )}
      {!shouldRenderChart && (
        <EmptyPlaceholder
          icon={faChartArea}
          loading={props.loading}
          small
          skeletonVariant="cartesian"
        />
      )}
      {shouldRenderChart && (
        <ChartWrapper height={props.height} isServer={props.isServer}>
          <AreaChart
            data={data}
            height={props.isServer ? REPORT_PDF_CHART_HEIGHT : undefined}
            margin={{ bottom: 0, left: 0, right: 20, top: 10 }}
            width={props.isServer ? REPORT_PDF_CHART_WIDTH : undefined}
          >
            <XAxis
              dataKey="dayNumber"
              stroke={theme.chart_axis_text}
              tick={{ fontSize: 10 }}
              ticks={ticks}
              tickSize={8}
              padding={{ right: 20 }}
            />
            <YAxis
              stroke={theme.chart_axis_text}
              tick={{ fontSize: 12 }}
              tickFormatter={(number) =>
                formatCurrencyRounded({
                  number,
                })
              }
              tickSize={8}
            />
            <ChartTooltip
              content={(e) => (
                <TimeSeriesChartTooltip
                  entry={{
                    date: e.label,
                    ...spendKeyedByDayNumber[e.label],
                  }}
                  excludedGroupings={[]}
                  formatter={(value: number) =>
                    formatMeasureValueWithUnit({
                      unit: UnitType.CURRENCY,
                      value: value,
                    })
                  }
                  hideColors
                  label={e.label}
                  reverseSortedGroupings={monthNames}
                  skipLabelFormat
                  tooltipDataKey={monthNames[monthNames.length - 1]}
                />
              )}
            />
            {monthNumbersReversed.map((monthNumber, i) => (
              <Area
                animationDuration={timing.chartAnimationDuration}
                dataKey={monthNumber}
                fill={theme.budgets_chart_fill_actual}
                key={monthNumber}
                opacity={i === 0 ? 0.5 : 0.3}
                stroke={theme.budgets_chart_stroke}
                strokeWidth={i === 0 ? 2 : 0}
                type="monotone"
              />
            ))}
            <ReferenceLine
              stroke={theme.budgets_chart_reference_line}
              strokeWidth={3}
              y={budgetAmount}
            />
            {handleDailySpendWarning()}
          </AreaChart>
        </ChartWrapper>
      )}
    </Box>
  );
}

type Month = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

function getMonthNameForMonthNumber(
  monthNumber: number,
  fullName?: boolean
): string {
  return enUS.localize?.month(
    (monthNumber - 1) as Month,
    fullName ? {} : { width: "abbreviated" }
  );
}
