import {
  DataSource,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/analytics/enums";
import {
  AlertRuleConditionType,
  AlertRuleDirection,
  AlertRuleStatus,
} from "@ternary/api-lib/constants/enums";
import { AlertRuleEntity } from "@ternary/api-lib/core/types";

export const LEGACY_BIG_QUERY_RULE_ID = "LEGACY_BIGQUERY_ANOMALY_ML";
export const LEGACY_BILLING_RULE_ID = "LEGACY_BILLING_ANOMALY_ML";

export const legacyBillingAlertRule: AlertRuleEntity = {
  id: LEGACY_BILLING_RULE_ID,
  condition: {
    direction: AlertRuleDirection.INCREASE_ONLY,
    lookbackDays: 90,
    minimumDelta: 200,
    sensitivity: 0.5,
    type: AlertRuleConditionType.ANOMALY_DETECTION,
  },
  createdAt: "",
  createdByUserID: "",
  dataSource: DataSource.BILLING,
  dimensions: ["category", "projectId"],
  excludeTax: false,
  filters: [],
  measure: "cost",
  name: "System Alert - Billing",
  status: AlertRuleStatus.ACTIVE,
  subscribers: { externalEmails: [], includeAllUsers: false, userIDs: [] },
  tenantDocID: "",
  timeGranularity: TimeGranularity.DAY,
  updatedAt: "",
  updatedByUserID: "",
};

export const legacyBigQueryAlertRule: AlertRuleEntity = {
  id: LEGACY_BIG_QUERY_RULE_ID,
  condition: {
    direction: AlertRuleDirection.INCREASE_ONLY,
    lookbackDays: 90,
    minimumDelta: 500,
    sensitivity: 0.5,
    type: AlertRuleConditionType.ANOMALY_DETECTION,
  },
  createdAt: "",
  createdByUserID: "",
  dataSource: DataSource.BIGQUERY_USAGE,
  dimensions: ["projectId"],
  excludeTax: false,
  filters: [
    { name: "jobType", operator: Operator.NOT_EQUALS, values: ["LOAD"] },
    { name: "reservationId", operator: Operator.NOT_SET, values: null },
  ],
  measure: "onDemandCost",
  name: "System Alert - BigQuery",
  status: AlertRuleStatus.ACTIVE,
  subscribers: { externalEmails: [], includeAllUsers: false, userIDs: [] },
  tenantDocID: "",
  timeGranularity: TimeGranularity.HOUR,
  updatedAt: "",
  updatedByUserID: "",
};
