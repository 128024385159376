import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { DateHelper } from "@/lib/dates";
import { useQueries } from "@tanstack/react-query";
import { DataSource, DurationType } from "@ternary/api-lib/analytics/enums";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { startOfDay } from "date-fns";
import UError from "unilib-error";
import useBuildDataQuery from "../../../../api/analytics/utils/useDataQuery";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { StorageSpendSummaryEntity } from "../../types";
import { azureStorageBBMeasures } from "../types";

export interface Params {
  queryFilters?: QueryFilter[];
}

type UseQueriesOptions = [
  [StorageSpendSummaryEntity, UError],
  [StorageSpendSummaryEntity, UError],
  [StorageSpendSummaryEntity, UError],
];

export default function useGetAzureStorageSpendSummaries(
  params?: Params,
  options?: UseQueryOptions<StorageSpendSummaryEntity, UError>
): UseQueryResult<StorageSpendSummaryEntity, UError>[] {
  const client = useAnalyticsApiClient();
  const dateHelper = new DateHelper();

  const [tenantID, thisMTDQuery] = useBuildDataQuery({
    ...params,
    dataSource: DataSource.AZURE_STORAGE_VISIBILITY,
    dateRange: [dateHelper.firstOfMonth(), startOfDay(dateHelper.date)],
    durationType: DurationType.MONTH_TO_DATE,
    measures: [azureStorageBBMeasures.cost],
  });

  const lastFullMonthQuery = useBuildDataQuery({
    ...params,
    dataSource: DataSource.AZURE_STORAGE_VISIBILITY,
    dateRange: [dateHelper.firstOfLastMonth(), dateHelper.lastDayLastMonth()],
    durationType: DurationType.MONTH_TO_DATE,
    measures: [azureStorageBBMeasures.cost],
  })[1];

  const lastMTDQuery = useBuildDataQuery({
    ...params,
    dataSource: DataSource.AZURE_STORAGE_VISIBILITY,
    dateRange: [dateHelper.firstOfLastMonth(), dateHelper.lastDayLastMonth()],
    durationType: DurationType.MONTH_TO_DATE,
    measures: [azureStorageBBMeasures.cost],
  })[1];

  return useQueries<UseQueriesOptions>({
    queries: [
      // This MTD
      {
        queryFn: async () => {
          const result = await client.loadData(tenantID, thisMTDQuery);

          return {
            totalCost: result.response[0]?.[azureStorageBBMeasures.cost] ?? 0,
          } as StorageSpendSummaryEntity;
        },
        queryKey: ["storageSpendSummary", "currentMTD", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        ...options,
      },
      // Last Month Full
      {
        queryFn: async () => {
          const result = await client.loadData(tenantID, lastFullMonthQuery);

          return {
            totalCost: result.response[0]?.[azureStorageBBMeasures.cost] ?? 0,
          } as StorageSpendSummaryEntity;
        },
        queryKey: ["storageSpendSummary", "lastMonthFull", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        ...options,
      },
      // LAST MONTH TO DATE
      {
        queryFn: async () => {
          const result = await client.loadData(tenantID, lastMTDQuery);

          return {
            totalCost: result.response[0]?.[azureStorageBBMeasures.cost] ?? 0,
          } as StorageSpendSummaryEntity;
        },
        queryKey: ["storageSpendSummary", "lastMTD", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        ...options,
      },
    ],
  });
}
