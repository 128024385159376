import getCn from "@/utils/getCn";
import getMergeState from "@/utils/getMergeState";
import { convertResource } from "@/utils/resources";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  faDollarSign,
  faInfoCircle,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  formatNumberRounded,
  formatPercentage,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Text from "@ternary/web-ui-lib/components/Text";
import prettyBytes from "pretty-bytes";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React, { useEffect, useRef, useState } from "react";
import copyText from "../../copyText";
import { CustomPricingEntity } from "../types";

import { DEFAULT_CUD_1_MULTIPLIER, DEFAULT_CUD_3_MULTIPLIER } from "../utils";
import { SECONDS_PER_HOUR } from "./CUDOptimizerSection";
const COMPONENT_NAME = "CUDSlider";
const SLIDER_DEBOUNCE_MS = 50;

const cn = getCn(COMPONENT_NAME);

interface StyledDivProps {
  totalColor: string;
}

const StyledDiv = styled.div<StyledDivProps>`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  z-index: 0;

  .${cn("label")} {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 1rem;
    background-color: ${(props) => props.totalColor};
    width: 15rem;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    text-align: center;
    font-size: 0.75rem;

    svg {
      margin: 0 !important;
    }
  }

  /* Not currently necessary */
  .${cn("total")} {
    justify-content: space-around;
    width: 15rem;
  }

  .rc-slider {
    margin: 0 2rem;
  }

  .rc-slider-dot {
    background-color: ${(props) => props.theme.text_color_secondary};
    padding-top: 1rem;
    width: 5px;
    border-radius: 0;
    border: none;
    top: 0;
  }
`;

interface Props {
  customPricing: CustomPricingEntity | undefined;
  duration: "1yr" | "3yr";
  markBottom: number;
  markTop: number;
  max: number;
  min: number;
  type: "cpu" | "mem";
  unit: string;
  value: number;
  onChange: (value: number) => void;
}

export default function CUDSlider(props: Props): JSX.Element {
  const theme = useTheme();
  const [state, setState] = useState({ value: 0 });
  const mergeState = getMergeState(setState);

  useEffect(() => {
    mergeState({ value: props.value });
  }, [props.value]);

  const timeout = useRef<NodeJS.Timeout | null>(null);

  const configs = {
    "1yr": {
      cudExplanation: copyText.cudExplanation1yr.replace(
        "%PERCENTAGE%",
        `${formatPercentage(
          props.customPricing?.oneYearDiscount
            ? props.customPricing.oneYearDiscount
            : 1 - DEFAULT_CUD_1_MULTIPLIER
        )}`
      ),
      labelText: copyText.cudSliderLabel1Yr,
      handle: theme.cud_color_1yr,
      track: `linear-gradient(to right, ${theme.secondary_color_background}, ${theme.cud_color_1yr})`,
    },
    "3yr": {
      cudExplanation: copyText.cudExplanation3yr.replace(
        "%PERCENTAGE%",
        `${formatPercentage(
          props.customPricing?.threeYearDiscount
            ? props.customPricing.threeYearDiscount
            : 1 - DEFAULT_CUD_3_MULTIPLIER
        )}`
      ),
      labelText: copyText.cudSliderLabel3Yr,
      handle: theme.cud_color_3yr,
      track: `linear-gradient(to right, ${theme.secondary_color_background}, ${theme.cud_color_3yr})`,
    },
  };

  const config = configs[props.duration];

  function handleChange(value: number | number[]) {
    if (Array.isArray(value)) return;

    mergeState({ value: value });

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      props.onChange(value);
    }, SLIDER_DEBOUNCE_MS);
  }

  const { quantity, unit } = convertResource({
    quantity: state.value,
    unit: props.unit,
  });

  return (
    <StyledDiv totalColor={config.handle}>
      <div className={cn("label")}>
        <Text color={theme.text_color_inverse}>{config.labelText}</Text>
        <Tooltip
          content={config.cudExplanation}
          icon={faInfoCircle}
          width="15rem"
        />
      </div>
      <Slider
        step={1}
        max={Math.floor(props.max)}
        min={props.min}
        marks={{
          [props.markTop]: {
            style: {
              fontSize: "0.8rem",
              top: "5px",
              color: theme.text_color,
            },
            label: (
              <Tooltip
                content={copyText.cudSliderMarkMaximizeSavings}
                icon={faDollarSign}
                width={"7rem"}
              />
            ),
          },
          [props.markBottom]: {
            style: {
              fontSize: "0.8rem",
              top: "5px",
              color: theme.text_color,
            },
            label: (
              <Tooltip
                icon={faMapMarkerAlt}
                content={copyText.cudSliderMarkCurrentLevel}
                width={"7rem"}
              />
            ),
          },
        }}
        handleStyle={{
          backgroundColor: config.handle,
          padding: "0.75rem",
          border: "none",
          marginTop: "-0.45rem",
          boxShadow: "-0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.2)",
        }}
        trackStyle={{
          backgroundImage: config.track,
          padding: "0.3rem",
        }}
        railStyle={{
          backgroundColor: theme.secondary_color_background,
          padding: "0.3rem",
        }}
        value={state.value}
        onChange={handleChange}
      />
      <div className={`${cn("label")} ${cn("total")}`}>
        <Text color={theme.text_color_inverse}>
          {props.type === "mem"
            ? prettyBytes(state.value / SECONDS_PER_HOUR)
            : `${formatNumberRounded(quantity / SECONDS_PER_HOUR)} ${unit}`}
        </Text>
        <Tooltip
          content={
            props.type === "cpu"
              ? copyText.cudExplanationUnitsCPU
              : copyText.cudExplanationUnitsMemory
          }
          icon={faInfoCircle}
          width="9rem"
        />
      </div>
    </StyledDiv>
  );
}
