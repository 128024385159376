import ConfirmationModal from "@/ui-lib/components/ConfirmationModal";
import Form, { FormField } from "@/ui-lib/components/Form";
import Select from "@/ui-lib/components/Select";
import { Option } from "@/ui-lib/components/SelectDropdown";
import TextArea from "@/ui-lib/components/TextArea";
import TextInput from "@/ui-lib/components/TextInput";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import {
  faExchange,
  faInfoCircle,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { getFormattedResourceType } from "@ternary/api-lib/analytics/utils";
import { CaseType } from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import { useCaseManagementStore } from "@ternary/api-lib/ui-lib/context/CaseManagementStoreProvider";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { isEmpty, keyBy } from "lodash";
import React, { ChangeEvent, useEffect, useState } from "react";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import { Input } from "../../../types";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import Switch from "../../../ui-lib/components/Switch";
import { getFullName } from "../../../utils/UserUtils";
import copyText from "../copyText";
import useGetCasesByTenantID from "../hooks/useGetCasesByTenantID";

const INPUT_ASSIGNEE_IDS = "assigneeIDs";
const INPUT_DESCRIPTION = "description";
const INPUT_IS_FOLLOWING = "isFollowing";
const INPUT_LINK_TO_JIRA = "linkToJira";
const INPUT_NAME = "name";
const INPUT_TYPE = "type";

type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

interface Props {
  hasJiraIntegration: boolean;
  isLoading: boolean;
  isProcessing: boolean;
  users: User[];
  onInteraction: (interaction: CreateCaseForm.Interaction) => void;
}

interface State {
  assigneeIDsInput: Input<string[]>;
  descriptionInput: Input<string>;
  isFollowingInput: Input<boolean>;
  linkToJiraInput: Input<boolean>;
  nameInput: Input<string>;
  showExistingCaseWarning: boolean;
  typeInput: Input<CaseType>;
}

const initialState: State = {
  assigneeIDsInput: { value: [], isValid: true },
  descriptionInput: { value: "", isValid: false },
  isFollowingInput: { value: true, isValid: true },
  linkToJiraInput: { value: false, isValid: true },
  nameInput: { value: "", isValid: false },
  showExistingCaseWarning: false,
  typeInput: { value: CaseType.INVESTIGATION, isValid: true },
};

const resourceRegex = /^Resource Name:.*\nResource Type:.*$/m;

export function CreateCaseForm(props: Props): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const theme = useTheme();
  const resourceSelectionModeState = useCaseManagementStore();

  //
  // State
  //

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const { data: cases = [] } = useGetCasesByTenantID(
    authenticatedUser.tenant.id
  );

  const casesKeyedByResourceID = keyBy(cases, "resourceID");

  useEffect(() => {
    const selectedResourceID = resourceSelectionModeState.selectedResourceID;

    if (selectedResourceID && casesKeyedByResourceID[selectedResourceID]) {
      mergeState({ showExistingCaseWarning: true });
    }
  }, [resourceSelectionModeState.selectedResourceID]);

  //
  // Interaction Handlers
  //

  function handleChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const name = event.target.name;
    let value: boolean | string = event.target.value;

    let isValid = false;

    switch (name) {
      case INPUT_ASSIGNEE_IDS: {
        value = JSON.parse(value);
        isValid = true;
        break;
      }
      case INPUT_DESCRIPTION: {
        isValid = value.length > 0;
        break;
      }
      case INPUT_IS_FOLLOWING: {
        isValid = true;
        value = value === "true" ? true : false;
        break;
      }
      case INPUT_LINK_TO_JIRA: {
        isValid = true;
        value = value === "true" ? true : false;
        break;
      }
      case INPUT_NAME: {
        isValid = value.length > 0;
        break;
      }
      case INPUT_TYPE: {
        isValid = true;
        break;
      }
    }

    mergeState({ [`${name}Input`]: { value, isValid } });
  }

  function handleCancel(): void {
    props.onInteraction({
      type: CreateCaseForm.INTERACTION_CANCEL_BUTTON_CLICKED,
    });

    setState(initialState);
    resourceSelectionModeState.reset();
  }

  function handleSubmit(): void {
    props.onInteraction({
      type: CreateCaseForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      caseType: state.typeInput.value,
      description: state.descriptionInput.value,
      isFollowing: state.isFollowingInput.value,
      linkToJira: state.linkToJiraInput.value,
      name: state.nameInput.value,
      ...(state.assigneeIDsInput.value.length > 0
        ? { assigneeIDs: state.assigneeIDsInput.value }
        : {}),
    });
  }

  //
  // Resource Selection
  //

  const selectedResourceID = resourceSelectionModeState.selectedResourceID;
  const selectedResourceName = resourceSelectionModeState.selectedResourceName;
  const selectedResourceType = resourceSelectionModeState.selectedResourceType;
  const selectedReportSnapshot = resourceSelectionModeState.reportSnapshot;

  const insightsResourceSelected =
    selectedReportSnapshot &&
    !isEmpty(selectedReportSnapshot) &&
    !!selectedResourceName;
  const entityResourceSelected = selectedResourceName && selectedResourceID;
  const resourceSelected = insightsResourceSelected || entityResourceSelected;

  useEffect(() => {
    if (!selectedResourceID) return;

    const oldDescription = state.descriptionInput.value;

    const newResourceText = `${copyText.resourceNameLabel}: ${selectedResourceName}\n${copyText.resourceTypeLabel}: ${getFormattedResourceType(selectedResourceType)}`;
    let updatedDescription = oldDescription;

    if (oldDescription === "") {
      updatedDescription = newResourceText;
    } else if (resourceRegex.test(oldDescription)) {
      updatedDescription = oldDescription.replace(
        resourceRegex,
        newResourceText
      );
    }

    mergeState({
      descriptionInput: {
        value: updatedDescription,
        isValid: updatedDescription.trim().length > 0,
      },
    });
  }, [selectedResourceID, selectedResourceName, selectedResourceType]);

  const canSubmit =
    Object.entries(state).every(([key, value]): boolean =>
      key.endsWith("Input") ? value.isValid : true
    ) &&
    (insightsResourceSelected || entityResourceSelected);

  //
  // Rendering
  //

  const caseTypeOptions: Option[] = [
    {
      label: copyText.caseType_INVESTIGATION,
      value: CaseType.INVESTIGATION,
    },
    { label: copyText.caseType_OPTIMIZATION, value: CaseType.OPTIMIZATION },
    { label: copyText.caseType_TASK, value: CaseType.TASK },
  ];

  const selectedType = caseTypeOptions.find(
    (caseTypeOption) => caseTypeOption.value === state.typeInput.value
  );

  const userOptions: Option[] = props.users.map((user) => ({
    label: getFullName(user) || user.email,
    value: user.id,
  }));

  const usersKeyedByID = keyBy(props.users, "id");

  const selectedUserOptions = state.assigneeIDsInput.value.map((userID) => {
    const user = usersKeyedByID[userID];

    return { label: getFullName(user) || user.email, value: user.id };
  });

  function renderButtonContent() {
    if (!resourceSelected) {
      return copyText.selectResourceButtonLabel;
    }

    const content = (
      <Box>
        <Flex marginBottom={theme.space_sm}>
          <Box marginRight={theme.space_sm}>Name:</Box>
          <Box>{selectedResourceName}</Box>
        </Flex>
        <Flex>
          <Box marginRight={theme.space_sm}>Type:</Box>
          <Box>{selectedResourceType}</Box>
        </Flex>
      </Box>
    );
    return (
      <Flex alignItems="center">
        <Box marginRight={theme.space_sm}>
          {copyText.changeResourceButtonLabel}
        </Box>
        <Tooltip content={content}>
          <Icon icon={faInfoCircle} />
        </Tooltip>
      </Flex>
    );
  }

  function renderCaseWarningModal() {
    const existingCase =
      !!selectedResourceID && !!casesKeyedByResourceID[selectedResourceID];

    if (existingCase && state.showExistingCaseWarning) {
      return (
        <ConfirmationModal
          closeOnClickOutside={false}
          message={copyText.selectedCaseExistingResourceWarningMessage}
          title={copyText.selectedCaseExistingResourceWarningTitle}
          onCancel={handleCancel}
          onConfirm={() => {
            mergeState({ showExistingCaseWarning: false });
          }}
        />
      );
    }
  }

  return (
    <>
      {renderCaseWarningModal()}
      <Form>
        <FormField
          name={INPUT_NAME}
          disabled={props.isProcessing}
          input={TextInput}
          label={copyText.nameInputLabel}
          required
          value={state.nameInput.value}
          onChange={handleChange}
        />
        <FormField
          name={INPUT_DESCRIPTION}
          disabled={props.isProcessing}
          input={TextArea}
          label={copyText.descriptionInputLabel}
          required
          resizeable
          rows={8}
          value={state.descriptionInput.value}
          onChange={handleChange}
        />
        <Button
          disabled={props.isProcessing}
          iconStart={<Icon icon={resourceSelected ? faExchange : faPlus} />}
          marginBottom={theme.space_lg}
          primary={!!resourceSelected && !state.showExistingCaseWarning}
          secondary={!resourceSelected || state.showExistingCaseWarning}
          size="small"
          type="button"
          variant={resourceSelected ? "success" : undefined}
          width="100%"
          onClick={() =>
            props.onInteraction({
              type: CreateCaseForm.INTERACTION_ADD_RESOURCE_BUTTON_CLICKED,
            })
          }
        >
          {renderButtonContent()}
        </Button>
        <FormField label={copyText.formFieldLabelSelectAssignee}>
          <Select
            disabled={props.isProcessing}
            isLoading={props.isLoading}
            isMulti
            isSearchable
            menuPlacement="bottom"
            options={userOptions}
            value={selectedUserOptions}
            onChange={(options) =>
              handleChange({
                target: {
                  name: INPUT_ASSIGNEE_IDS,
                  value: JSON.stringify(options.map((option) => option.value)),
                },
              } as ChangeEvent<HTMLInputElement>)
            }
          />
        </FormField>
        <Flex alignItems="center">
          {props.hasJiraIntegration && (
            <Box width="30%">
              <FormField
                label={
                  <Tooltip
                    content={copyText.linkToJiraTooltipCaption}
                    width="8rem"
                  >
                    <Flex alignItems="center">
                      <Text marginRight={theme.space_sm}>
                        {copyText.linkToJiraTooltipLabel}
                      </Text>
                      <Icon icon={faInfoCircle} />
                    </Flex>
                  </Tooltip>
                }
              >
                <Switch
                  checked={state.linkToJiraInput.value}
                  onChange={(checked) =>
                    handleChange({
                      target: {
                        name: INPUT_LINK_TO_JIRA,
                        value: String(checked),
                      },
                    } as ChangeEvent<HTMLInputElement>)
                  }
                />
              </FormField>
            </Box>
          )}
          <Box width={props.hasJiraIntegration ? "30%" : "40%"}>
            <FormField
              label={
                <Tooltip content={copyText.tooltipFollowCase} width="8rem">
                  <Flex alignItems="center">
                    <Text marginRight={theme.space_sm}>
                      {copyText.formFieldLabelFollowCase}
                    </Text>
                    <Icon icon={faInfoCircle} />
                  </Flex>
                </Tooltip>
              }
            >
              <Switch
                checked={state.isFollowingInput.value}
                onChange={(checked) =>
                  handleChange({
                    target: {
                      name: INPUT_IS_FOLLOWING,
                      value: String(checked),
                    },
                  } as ChangeEvent<HTMLInputElement>)
                }
              />
            </FormField>
          </Box>
          <Box width={props.hasJiraIntegration ? "40%" : "60%"}>
            <FormField label={copyText.caseTypeInputLabel}>
              <Select
                isSearchable
                isDisabled={props.isProcessing}
                options={caseTypeOptions}
                value={selectedType}
                onChange={(option) => {
                  handleChange({
                    target: {
                      name: INPUT_TYPE,
                      value: option?.value ?? "",
                    },
                  } as ChangeEvent<HTMLInputElement>);
                }}
              />
            </FormField>
          </Box>
        </Flex>
        <Flex justifyContent={"flex-end"} marginTop={theme.space_sm}>
          <Button
            disabled={props.isProcessing}
            secondary
            type="reset"
            width={100}
            onClick={handleCancel}
          >
            {copyText.cancelButtonLabel}
          </Button>
          <Button
            disabled={!canSubmit || props.isProcessing}
            marginLeft={theme.space_sm}
            primary
            width={100}
            type="button"
            onClick={handleSubmit}
          >
            {props.isProcessing ? (
              <LoadingSpinner />
            ) : (
              copyText.submitButtonLabel
            )}
          </Button>
        </Flex>
      </Form>
    </>
  );
}

CreateCaseForm.INTERACTION_ADD_RESOURCE_BUTTON_CLICKED =
  "CreateCaseForm.INTERACTION_ADD_RESOURCE_BUTTON_CLICKED" as const;

CreateCaseForm.INTERACTION_CANCEL_BUTTON_CLICKED =
  "CreateCaseForm.INTERACTION_CANCEL_BUTTON_CLICKED" as const;

CreateCaseForm.INTERACTION_SUBMIT_BUTTON_CLICKED =
  "CreateCaseForm.INTERACTION_SUBMIT_BUTTON_CLICKED" as const;

type InteractionAddResourceButtonClicked = {
  type: typeof CreateCaseForm.INTERACTION_ADD_RESOURCE_BUTTON_CLICKED;
};

type InteractionCancelButtonClicked = {
  type: typeof CreateCaseForm.INTERACTION_CANCEL_BUTTON_CLICKED;
};

type InteractionSubmitButtonClicked = {
  type: typeof CreateCaseForm.INTERACTION_SUBMIT_BUTTON_CLICKED;
  assigneeIDs?: string[];
  caseType: CaseType;
  description: string;
  isFollowing: boolean;
  linkToJira: boolean;
  name: string;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CreateCaseForm {
  export type Interaction =
    | InteractionAddResourceButtonClicked
    | InteractionCancelButtonClicked
    | InteractionSubmitButtonClicked;
}

export default CreateCaseForm;
