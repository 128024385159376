import { useTheme } from "@emotion/react";
import { DurationType } from "@ternary/api-lib/analytics/enums";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useState } from "react";
import copyText from "../constants/copyText";
import { DatePicker } from "../ui-lib/components/DateRangeControls/DatePicker";
import DateRangeControls from "../ui-lib/components/DateRangeControls/DateRangeControls";
import Form, { FormField } from "../ui-lib/components/Form";
import Modal from "../ui-lib/components/Modal";
import { DateRange } from "../utils/dates";
import getMergeState from "../utils/getMergeState";

interface Props {
  dateRange: DateRange;
  durationType: DurationType;
  isFiscalMode: boolean;
  isOpen: boolean;
  onInteraction: (interaction: GlobalDatePickerModal.Interaction) => void;
}

interface State {
  customEndDate: Date;
  customStartDate: Date;
  durationType: DurationType;
}

export function GlobalDatePickerModal(props: Props): JSX.Element {
  const theme = useTheme();
  const [state, setState] = useState<State>({
    customStartDate: props.dateRange[0],
    customEndDate: props.dateRange[1],
    durationType: props.durationType,
  });
  const mergeState = getMergeState(setState);

  function handleSubmitDateRange() {
    if (state.durationType !== DurationType.CUSTOM) {
      const dateRange = getCubeDateRangeFromDurationType(state.durationType);
      props.onInteraction({
        type: GlobalDatePickerModal.INTERACTION_SUBMIT_BUTTON_CLICKED,
        dateRange,
        durationType: state.durationType,
      });
    } else {
      props.onInteraction({
        type: GlobalDatePickerModal.INTERACTION_SUBMIT_BUTTON_CLICKED,
        dateRange: [state.customStartDate, state.customEndDate],
        durationType: state.durationType,
      });
    }
  }

  function handleChangeCustomDate(
    date: Date,
    key: "customStartDate" | "customEndDate"
  ) {
    mergeState({ [key]: date });
  }

  function handleChangeDuration(
    durationType: DurationType,
    dateRange: DateRange | undefined
  ) {
    mergeState({
      durationType,
      ...(dateRange &&
        durationType === DurationType.CUSTOM && {
          customStartDate: dateRange[0],
          customEndDate: dateRange[1],
        }),
    });
  }

  function handleClose() {
    props.onInteraction({
      type: GlobalDatePickerModal.INTERACTION_CLOSE_BUTTON_CLICKED,
    });
  }

  function canSubmit() {
    return (
      state.customStartDate instanceof Date &&
      typeof state.customStartDate.getMonth === "function" &&
      state.customEndDate instanceof Date &&
      typeof state.customEndDate.getMonth === "function"
    );
  }

  return (
    <Modal
      closeOnClickOutside={true}
      isOpen={props.isOpen}
      showCloseButton
      onClose={handleClose}
    >
      <Modal.Header>
        <Text appearance="h4">{copyText.globalDateSelectorTitle}</Text>
      </Modal.Header>
      <Modal.Body>
        <Box marginBottom={theme.space_lg}>
          <Flex></Flex>
          <DateRangeControls
            dateRange={[state.customStartDate, state.customEndDate]}
            durationType={state.durationType}
            hiddenOptions={[DurationType.INVOICE, DurationType.QUARTER_TO_DATE]}
            isFiscalMode={props.isFiscalMode}
            isGlobalDateControls
            suspendCustomDates
            onChangeDateRange={(durationType, dateRange) => {
              handleChangeDuration(durationType, dateRange);
            }}
          />
        </Box>

        {state.durationType === DurationType.CUSTOM ? (
          <Form title="Custom">
            <Text
              fontWeight="bold"
              fontSize="medium"
              marginBottom={theme.space_sm}
            >
              {copyText.globalDateSelectorCustomDateRangeLabel}
            </Text>
            <Flex>
              <Box marginRight={theme.space_lg} width={210}>
                <FormField label={copyText.uberControlsDatePickerLabelStart}>
                  <DatePicker
                    disabled={state.durationType !== DurationType.CUSTOM}
                    selected={state.customStartDate}
                    onChange={(val) =>
                      handleChangeCustomDate(val as Date, "customStartDate")
                    }
                  />
                </FormField>
              </Box>
              <Box width={210}>
                <FormField label={copyText.uberControlsDatePickerLabelEnd}>
                  <DatePicker
                    disabled={state.durationType !== DurationType.CUSTOM}
                    selected={state.customEndDate}
                    onChange={(val) =>
                      handleChangeCustomDate(val as Date, "customEndDate")
                    }
                  />
                </FormField>
              </Box>
            </Flex>
          </Form>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Flex width={"100%"} justifyContent="flex-end">
          <Button
            disabled={!canSubmit()}
            primary
            width={100}
            onClick={handleSubmitDateRange}
          >
            {copyText.globalDateSelectorApplyButtonLabel}
          </Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}
GlobalDatePickerModal.INTERACTION_CLOSE_BUTTON_CLICKED =
  "INTERACTION_CLOSE_BUTTON_CLICKED" as const;
GlobalDatePickerModal.INTERACTION_SUBMIT_BUTTON_CLICKED =
  "INTERACTION_SUBMIT_BUTTON_CLICKED" as const;
GlobalDatePickerModal.INTERACTION_REMOVE_DATE_BUTTON_CLICKED =
  "INTERACTION_REMOVE_BUTTON_CLICKED" as const;

interface InteractionCloseDateButtonClicked {
  type: typeof GlobalDatePickerModal.INTERACTION_CLOSE_BUTTON_CLICKED;
}

interface InterctionSubmitButtonClicked {
  type: typeof GlobalDatePickerModal.INTERACTION_SUBMIT_BUTTON_CLICKED;
  dateRange: DateRange;
  durationType: DurationType;
}

interface InteractionRemoveDateButtonClicked {
  type: typeof GlobalDatePickerModal.INTERACTION_REMOVE_DATE_BUTTON_CLICKED;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace GlobalDatePickerModal {
  export type Interaction =
    | InteractionCloseDateButtonClicked
    | InterctionSubmitButtonClicked
    | InteractionRemoveDateButtonClicked;
}

export default GlobalDatePickerModal;
