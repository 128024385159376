import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";

export function useGenerateMspBillingStatementCsv(
  statementID: string,
  options?: UseQueryOptions<Buffer, CoreAPIClientError>
): UseQueryResult<Buffer, CoreAPIClientError> {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["mspBillingStatements", statementID, "csv"],
    queryFn: () => client.generateMspBillingStatementCsv(statementID),
    ...options,
  });
}
