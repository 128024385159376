import { useTheme } from "@emotion/react";
import { SimpleChartTooltip } from "@ternary/api-lib/analytics/ui/SimpleChartTooltip";
import { ChartDatum } from "@ternary/api-lib/analytics/ui/types";
import { formatTimestamp } from "@ternary/api-lib/analytics/utils/ChartFormatUtils";
import { formatCurrencyRounded } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Box from "@ternary/web-ui-lib/components/Box";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export interface AnomalyChartData {
  amount: number;
  time: string;
}

interface Props {
  data: AnomalyChartData[];
  firstDetected: string;
}

export default function AnomalyChart(props: Props): JSX.Element {
  const theme = useTheme();

  const timeToAmount: { [key: string]: ChartDatum } = props.data.reduce(
    (accum, datum) => ({
      ...accum,
      [datum.time]: datum,
    }),
    {}
  );

  const firstDetected = toZonedTime(
    new Date(format(new Date(props.firstDetected), "yyyy-MM-dd hh:mm:SS")),
    "UTC"
  ).toISOString();

  return (
    <Box width="100%" height="10rem">
      <ResponsiveContainer width="100%">
        <LineChart
          data={props.data}
          margin={{
            bottom: 5,
            left: 20,
            right: 30,
            top: 5,
          }}
        >
          <CartesianGrid stroke={theme.chart_cartesian_grid_lines} />
          <XAxis
            dataKey="time"
            stroke={theme.chart_axis_text}
            tickFormatter={(value) => formatTimestamp(value, "hh:mm a")}
            tick={{
              fontSize: "0.8rem",
              stroke: theme.text_color,
            }}
          />
          <YAxis
            stroke={theme.chart_axis_text}
            tickFormatter={(number) =>
              formatCurrencyRounded({
                number,
              })
            }
            tick={{
              fontSize: "0.8rem",
              stroke: theme.text_color,
            }}
          />
          <Tooltip
            content={(e) => (
              <SimpleChartTooltip
                label={e.label && formatTimestamp(e.label, "hh:mm a")}
                entry={timeToAmount[e.label]}
                reverseSortedGroupings={["amount"]}
              />
            )}
          />
          <Line
            dataKey="amount"
            dot={false}
            stroke={theme.recommendations_chart_line_color}
            strokeWidth={2}
            type="monotone"
          />
          <ReferenceLine
            stroke={theme.feedback_negative}
            strokeDasharray="4 4"
            strokeWidth={2}
            x={firstDetected}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}
