import { UnitType } from "../enums";

const measures = [
  { name: "locationBasedOffset", unit: UnitType.KILOGRAMS },
  { name: "scope1", unit: UnitType.KILOGRAMS },
  { name: "scope2Location", unit: UnitType.KILOGRAMS },
  { name: "scope3", unit: UnitType.KILOGRAMS },
];

const dimensions = [
  "carbonModel",
  "billingAccountId",
  "location",
  "region",
  "projectId",
  "serviceId",
  "serviceDescription",
];

export const carbonFootprintSchema = { dimensions, measures };
