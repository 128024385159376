import { createStructParam } from "@/lib/use-query-params";
import { createColumnHelper } from "@tanstack/react-table";
import { formatCurrency } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Text from "@ternary/web-ui-lib/components/Text";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import {
  AWSRedshiftResourcesEntity,
  awsRedshiftDimensions,
  awsRedshiftMeasures,
} from "../types";

type Props = {
  resources: AWSRedshiftResourcesEntity[];
  isLoadingResources: boolean;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

const columnHelper = createColumnHelper<AWSRedshiftResourcesEntity>();

export function AWSWarehouseSubTable(props: Props) {
  const [sortRule, setSortRule] = useQueryParam(
    "group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "cost",
    })
  );

  const columns = useMemo(
    () => [
      ...(
        [
          [awsRedshiftDimensions.clusterArn, 120],
          [awsRedshiftDimensions.payerAccountId, 120],
          [awsRedshiftDimensions.clusterId, 120],
          [awsRedshiftDimensions.usageAccountId, 120],
          [awsRedshiftDimensions.region, 120],
          [awsRedshiftDimensions.productFamily, 120],
          [awsRedshiftDimensions.instanceMemory, 120],
          [awsRedshiftDimensions.instanceType, 120],
        ] as const
      ).map(([dimensionKey, size]) => {
        const headerKey: keyof typeof copyText = `awsWarehouseResourceTableHeader_${dimensionKey}`;

        return columnHelper.accessor(dimensionKey, {
          cell: ({ getValue }) => (
            <Text truncate>
              <Tooltip content={getValue()}>
                {getValue() || copyText.azureKubernetesTableNull}
              </Tooltip>
            </Text>
          ),
          header: copyText[headerKey],
          meta: { align: "center" },
          size,
        });
      }),
      columnHelper.accessor(awsRedshiftMeasures.cost, {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.awsWarehouseResourceTableHeader_cost,
        meta: { align: "center" },
        size: 120,
      }),
      columnHelper.accessor(awsRedshiftMeasures.nodeUsage, {
        header: copyText.awsWarehouseResourceTableHeader_nodeUsage,
        meta: { align: "center" },
        size: 120,
      }),
      columnHelper.accessor(awsRedshiftMeasures.nodeCost, {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.awsWarehouseResourceTableHeader_nodeCost,
        meta: { align: "center" },
        size: 120,
      }),
      ...(
        [
          [awsRedshiftDimensions.instanceVcpu, 120],
          [awsRedshiftDimensions.io, 120],
          [awsRedshiftDimensions.storage, 120],
        ] as const
      ).map(([dimensionKey, size]) => {
        const headerKey: keyof typeof copyText = `awsWarehouseResourceTableHeader_${dimensionKey}`;

        return columnHelper.accessor(dimensionKey, {
          cell: ({ getValue }) => (
            <Text truncate>
              {getValue() || copyText.azureKubernetesTableNull}
            </Text>
          ),
          header: copyText[headerKey],
          meta: { align: "center" },
          size,
        });
      }),
      columnHelper.accessor(awsRedshiftMeasures.storageCost, {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.awsWarehouseResourceTableHeader_storageCost,
        meta: { align: "center" },
        size: 120,
      }),
      columnHelper.accessor(awsRedshiftMeasures.storageUsage, {
        cell: ({ getValue }) => prettyBytes(getValue()),
        header: copyText.awsWarehouseResourceTableHeader_storageUsage,
        meta: { align: "center" },
        size: 120,
      }),
      columnHelper.accessor(awsRedshiftMeasures.dataScanCost, {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.awsWarehouseResourceTableHeader_dataScanCost,
        meta: { align: "center" },
        size: 120,
      }),
      columnHelper.accessor(awsRedshiftMeasures.dataScanUsage, {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header: copyText.awsWarehouseResourceTableHeader_dataScanUsage,
        meta: { align: "center" },
        size: 120,
      }),
      columnHelper.accessor(awsRedshiftMeasures.serverlessComputeCost, {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.awsWarehouseResourceTableHeader_serverlessComputeCost,
        meta: { align: "center" },
        size: 120,
      }),
      columnHelper.accessor(awsRedshiftMeasures.serverlessComputeUsage, {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header: copyText.awsWarehouseResourceTableHeader_serverlessComputeUsage,
        meta: { align: "center" },
        size: 120,
      }),
      columnHelper.accessor(awsRedshiftMeasures.backupStorageCost, {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.awsWarehouseResourceTableHeader_backupStorageCost,
        meta: { align: "center" },
        size: 120,
      }),
      columnHelper.accessor(awsRedshiftMeasures.backupStorageUsage, {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header: copyText.awsWarehouseResourceTableHeader_backupStorageUsage,
        meta: { align: "center" },
        size: 120,
      }),
    ],
    []
  );

  return (
    <Box maxWidth={"100%"} overflowX="auto">
      <Table
        columns={columns}
        compact
        data={props.resources}
        initialState={{ sorting: [sortRule] }}
        isLoading={props.isLoadingResources}
        showPagination
        sortable
        onChangeSortBy={([sortRule]) => setSortRule(sortRule, "replaceIn")}
      />
    </Box>
  );
}
