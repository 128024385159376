import IconAzureA from "@/ui-lib/icons/IconAzureA";
import IconGCPCloud from "@/ui-lib/icons/IconGCPCloud";
import { useTheme } from "@emotion/react";
import { faAws } from "@fortawesome/free-brands-svg-icons";
import { faGear, faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Divider from "@ternary/api-lib/ui-lib/components/Divider";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import palette from "@ternary/api-lib/ui-lib/theme/palette";
import React, { useEffect, useState } from "react";
import { Input } from "../../../types";
import Form from "../../../ui-lib/components/Form";
import Grid from "../../../ui-lib/components/Grid";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import Switch from "../../../ui-lib/components/Switch";
import getMergeState from "../../../utils/getMergeState";
import _copyText from "../copyText";
import { useFeatureText } from "@/context/FeatureTextProvider";

type Settings = {
  id: string;
  allowAwsRateRecs: boolean;
  allowAwsUsageRecs: boolean;
  allowAzureRateRecs: boolean;
  allowAzureUsageRecs: boolean;
  allowCloudConfiguration: boolean;
  allowGcpRateRecs: boolean;
  allowGcpUsageRecs: boolean;
};

type State = {
  allowAwsRateRecsInput: Input<boolean>;
  allowAwsUsageRecsInput: Input<boolean>;
  allowAzureRateRecsInput: Input<boolean>;
  allowAzureUsageRecsInput: Input<boolean>;
  allowCloudConfigurationInput: Input<boolean>;
  allowGcpRateRecsInput: Input<boolean>;
  allowGcpUsageRecsInput: Input<boolean>;
};

interface Props {
  settings?: Settings;
  isProcessing: boolean;
  onCancel: () => void;
  onInteraction: (interaction: MspTenantSettingsForm.Interaction) => void;
}

export function MspTenantSettingsForm(props: Props) {
  const theme = useTheme();

  const { copyText } = useFeatureText(_copyText);

  const initialFormState = getStateFromSettings(props.settings);

  const [state, setState] = useState<State>(initialFormState);
  const mergeState = getMergeState(setState);

  function handleChangeSwitch(name: string, value: boolean) {
    const hasChanged = props.settings
      ? props.settings[name] !== value
      : initialFormState[name] !== value;

    mergeState({ [`${name}Input`]: { value, isValid: true, hasChanged } });
  }

  function handleSubmit(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    if (props.settings) {
      props.onInteraction({
        type: MspTenantSettingsForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
        settingsID: props.settings.id,
        config: {
          ...(state.allowAwsRateRecsInput.hasChanged
            ? { allowAwsRateRecs: state.allowAwsRateRecsInput.value }
            : {}),
          ...(state.allowAwsUsageRecsInput.hasChanged
            ? { allowAwsUsageRecs: state.allowAwsUsageRecsInput.value }
            : {}),
          ...(state.allowAzureRateRecsInput.hasChanged
            ? { allowAzureRateRecs: state.allowAzureRateRecsInput.value }
            : {}),
          ...(state.allowAzureUsageRecsInput.hasChanged
            ? { allowAzureUsageRecs: state.allowAzureUsageRecsInput.value }
            : {}),
          ...(state.allowCloudConfigurationInput.hasChanged
            ? {
                allowCloudConfiguration:
                  state.allowCloudConfigurationInput.value,
              }
            : {}),
          ...(state.allowGcpRateRecsInput.hasChanged
            ? { allowGcpRateRecs: state.allowGcpRateRecsInput.value }
            : {}),
          ...(state.allowGcpUsageRecsInput.hasChanged
            ? { allowGcpUsageRecs: state.allowGcpUsageRecsInput.value }
            : {}),
        },
      });
    } else {
      props.onInteraction({
        type: MspTenantSettingsForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
        config: {
          allowAwsRateRecs: state.allowAwsRateRecsInput.value,
          allowAwsUsageRecs: state.allowAwsUsageRecsInput.value,
          allowAzureRateRecs: state.allowAzureRateRecsInput.value,
          allowAzureUsageRecs: state.allowAzureUsageRecsInput.value,
          allowCloudConfiguration: state.allowCloudConfigurationInput.value,
          allowGcpRateRecs: state.allowGcpRateRecsInput.value,
          allowGcpUsageRecs: state.allowGcpUsageRecsInput.value,
        },
      });
    }
  }

  // This is to be able to reset button canSubmit state after a successful update.
  useEffect(() => {
    if (!props.settings) return;

    const state = getStateFromSettings(props.settings);

    mergeState(state);
  }, [props.settings]);

  const canSubmit = Object.values(state).some((input) => input.hasChanged);
  const isCustomSetting = props.settings !== undefined;
  return (
    <Flex direction="column" height="100%" paddingTop={theme.space_md}>
      <Form>
        <Flex direction="column" justifyContent="space-between" height="100%">
          <Box paddingHorizontal={theme.space_lg}>
            <Flex alignItems="center" marginBottom={theme.space_md}>
              <Text marginRight={theme.space_xs}>
                {copyText.headerLabelIntegrations}
              </Text>
              <Tooltip
                content={
                  isCustomSetting
                    ? copyText.integrationsTooltipContent
                    : copyText.integrationsSelectedTenantsTooltipContent
                }
                width="250px"
              >
                <Icon color={palette.grey[60]} icon={faQuestionCircle} />
              </Tooltip>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between">
              <Flex alignItems="center">
                <Box
                  borderRadius={theme.borderRadius_2}
                  border={`1px solid ${theme.border_color}`}
                  boxShadow={theme.box_shadow2}
                >
                  <Flex
                    height={40}
                    width={40}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Icon size="lg" icon={faGear} />
                  </Flex>
                </Box>
                <Text
                  marginLeft={theme.space_xs}
                  fontSize={theme.fontSize_base}
                >
                  {copyText.tableHeaderConfiguration}
                </Text>
              </Flex>
              <Flex justifyContent="flex-end">
                <Switch
                  checked={state.allowCloudConfigurationInput.value}
                  onChange={(checked) =>
                    handleChangeSwitch("allowCloudConfiguration", checked)
                  }
                />
                <Text marginLeft={theme.space_xs}>
                  {copyText.switchLabelAllowConfiguration}
                </Text>
              </Flex>
            </Flex>
            <Divider />
            <Flex alignItems="center" marginBottom={theme.space_md}>
              <Text marginRight={theme.space_xs}>
                {copyText.headerLabelOptimizations}
              </Text>
              <Tooltip
                content={
                  isCustomSetting
                    ? copyText.optimizationsTooltipContent
                    : copyText.optimizationsTooltipSelectedTenantsContent
                }
                width="250px"
              >
                <Icon color={palette.grey[60]} icon={faQuestionCircle} />
              </Tooltip>
            </Flex>

            <Grid
              gridTemplateColumns="50% 25% 25%"
              gridTemplateRows="repeat(3, 60px)"
            >
              <Flex alignItems="center">
                <Box
                  borderRadius={theme.borderRadius_2}
                  border={`1px solid ${theme.border_color}`}
                  boxShadow={theme.box_shadow2}
                >
                  <Flex
                    height={40}
                    width={40}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Icon size="lg" icon={faAws} />
                  </Flex>
                </Box>
                <Text
                  marginLeft={theme.space_xs}
                  fontSize={theme.fontSize_base}
                >
                  {copyText.formLabelAWS}
                </Text>
              </Flex>

              <Flex alignItems="center" justifyContent="flex-end">
                <Switch
                  checked={state.allowAwsRateRecsInput.value}
                  onChange={(checked) =>
                    handleChangeSwitch("allowAwsRateRecs", checked)
                  }
                />
                <Text marginLeft={theme.space_xs}>
                  {copyText.switchLabelRate}
                </Text>
              </Flex>
              <Flex alignItems="center" justifyContent="flex-end">
                <Switch
                  checked={state.allowAwsUsageRecsInput.value}
                  onChange={(checked) =>
                    handleChangeSwitch("allowAwsUsageRecs", checked)
                  }
                />
                <Text marginLeft={theme.space_xs}>
                  {copyText.switchLabelUsage}
                </Text>
              </Flex>
              <Flex alignItems="center">
                <Box
                  borderRadius={theme.borderRadius_2}
                  border={`1px solid ${theme.border_color}`}
                  boxShadow={theme.box_shadow2}
                >
                  <Flex
                    height={40}
                    width={40}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <IconAzureA size={20} />
                  </Flex>
                </Box>
                <Text
                  marginLeft={theme.space_xs}
                  fontSize={theme.fontSize_base}
                >
                  {copyText.formLabelAzure}
                </Text>
              </Flex>
              <Flex alignItems="center" justifyContent="flex-end">
                <Switch
                  checked={state.allowAzureRateRecsInput.value}
                  onChange={(checked) =>
                    handleChangeSwitch("allowAzureRateRecs", checked)
                  }
                />
                <Text marginLeft={theme.space_xs}>
                  {copyText.switchLabelRate}
                </Text>
              </Flex>
              <Flex alignItems="center" justifyContent="flex-end">
                <Switch
                  checked={state.allowAzureUsageRecsInput.value}
                  onChange={(checked) =>
                    handleChangeSwitch("allowAzureUsageRecs", checked)
                  }
                />
                <Text marginLeft={theme.space_xs}>
                  {copyText.switchLabelUsage}
                </Text>
              </Flex>
              <Flex alignItems="center">
                <Box
                  borderRadius={theme.borderRadius_2}
                  border={`1px solid ${theme.border_color}`}
                  boxShadow={theme.box_shadow2}
                >
                  <Flex
                    height={40}
                    width={40}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <IconGCPCloud size={20} />
                  </Flex>
                </Box>
                <Text
                  marginLeft={theme.space_xs}
                  fontSize={theme.fontSize_base}
                >
                  {copyText.formLabelGCP}
                </Text>
              </Flex>
              <Flex alignItems="center" justifyContent="flex-end">
                <Switch
                  checked={state.allowGcpRateRecsInput.value}
                  onChange={(checked) =>
                    handleChangeSwitch("allowGcpRateRecs", checked)
                  }
                />
                <Text marginLeft={theme.space_xs}>
                  {copyText.switchLabelRate}
                </Text>
              </Flex>
              <Flex alignItems="center" justifyContent="flex-end">
                <Switch
                  checked={state.allowGcpUsageRecsInput.value}
                  onChange={(checked) =>
                    handleChangeSwitch("allowGcpUsageRecs", checked)
                  }
                />
                <Text marginLeft={theme.space_xs}>
                  {copyText.switchLabelUsage}
                </Text>
              </Flex>
            </Grid>
          </Box>
          <Box paddingBottom={theme.space_md}>
            <Divider margin={theme.space_md} />
            <Flex
              gap={theme.space_sm}
              justifyContent="flex-end"
              marginTop={theme.space_md}
              paddingHorizontal={theme.space_lg}
            >
              <Button type="button" width={100} onClick={props.onCancel}>
                {copyText.cancelButtonLabel}
              </Button>
              <Button
                disabled={!canSubmit || props.isProcessing}
                primary
                width={100}
                onClick={handleSubmit}
              >
                {props.isProcessing ? (
                  <LoadingSpinner />
                ) : (
                  copyText.submitButtonLabel
                )}
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Form>
    </Flex>
  );
}

function getStateFromSettings(settings?: Settings) {
  return {
    allowAwsRateRecsInput: {
      value: settings?.allowAwsRateRecs ?? false,
      isValid: true,
      hasChanged: false,
    },
    allowAwsUsageRecsInput: {
      value: settings?.allowAwsUsageRecs ?? false,
      isValid: true,
      hasChanged: false,
    },
    allowAzureRateRecsInput: {
      value: settings?.allowAzureRateRecs ?? false,
      isValid: true,
      hasChanged: false,
    },
    allowAzureUsageRecsInput: {
      value: settings?.allowAzureUsageRecs ?? false,
      isValid: true,
      hasChanged: false,
    },
    allowCloudConfigurationInput: {
      value: settings?.allowCloudConfiguration ?? false,
      isValid: true,
      hasChanged: false,
    },
    allowGcpRateRecsInput: {
      value: settings?.allowGcpRateRecs ?? false,
      isValid: true,
      hasChanged: false,
    },
    allowGcpUsageRecsInput: {
      value: settings?.allowGcpUsageRecs ?? false,
      isValid: true,
      hasChanged: false,
    },
  };
}

MspTenantSettingsForm.INTERACTION_SUBMIT_BUTTON_CLICKED =
  "MspTenantSettingsForm.INTERACTION_SUBMIT_BUTTON_CLICKED" as const;

interface InteractionSubmitButtonClicked {
  type: typeof MspTenantSettingsForm.INTERACTION_SUBMIT_BUTTON_CLICKED;
  settingsID?: string;
  config: {
    allowAwsRateRecs?: boolean;
    allowAwsUsageRecs?: boolean;
    allowAzureRateRecs?: boolean;
    allowAzureUsageRecs?: boolean;
    allowCloudConfiguration?: boolean;
    allowGcpRateRecs?: boolean;
    allowGcpUsageRecs?: boolean;
  };
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MspTenantSettingsForm {
  export type Interaction = InteractionSubmitButtonClicked;
}

export default MspTenantSettingsForm;
