import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import { TimeGranularity, UnitType } from "@ternary/api-lib/analytics/enums";
import { RawData } from "@ternary/api-lib/analytics/types";
import {
  barStyleProps,
  cartesianStyleProps,
  xAxisStyleProps,
  yAxisStyleProps,
} from "@ternary/api-lib/analytics/ui/styles";
import TimeSeriesChartTooltip from "@ternary/api-lib/analytics/ui/TimeSeriesChartTooltip";
import {
  formatMeasureValueWithUnit,
  formatTimestamp,
} from "@ternary/api-lib/analytics/utils/ChartFormatUtils";
import { getFormatForGranularity } from "@ternary/api-lib/analytics/utils/DateUtils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import { groupBy, keyBy, uniq } from "lodash";
import React, { useMemo } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import copyText from "../../copyText";

type Props = {
  customMeasures: CustomMeasure[];
  data: RawData[];
  isLoading: boolean;
};

type CustomMeasure = {
  key: string;
  label: string;
  color: string;
};

export default function AWSCommittedUseVisibilityChart(props: Props) {
  const theme = useTheme();

  const dateFormat = getFormatForGranularity(TimeGranularity.DAY);
  const keyedCustomMeasures = keyBy(props.customMeasures, "key");

  const customColors = Object.fromEntries(
    props.customMeasures.map((customMeasure) => [
      customMeasure.key,
      customMeasure.color,
    ])
  );

  const formatYAxisValue = (value: number) =>
    formatMeasureValueWithUnit({
      unit: UnitType.CURRENCY,
      value: value,
    });

  const chartData = useMemo(() => {
    const unmergedChartData = props.data.map((datum) =>
      Object.fromEntries([
        ["timestamp", datum.timestamp],
        ...props.customMeasures.map((measure) => [
          measure.key,
          datum[measure.key],
        ]),
      ])
    );

    const timestamps = uniq(unmergedChartData.map((datum) => datum.timestamp));
    const chartGroupedKeyedByTimestamp = groupBy(
      unmergedChartData,
      "timestamp"
    );
    const chartData = timestamps.map((timestamp) =>
      mergeAtKeys(chartGroupedKeyedByTimestamp[timestamp], props.customMeasures)
    );

    return chartData;
  }, [props.data]);

  if (props.isLoading || props.data.length === 0) {
    return (
      <EmptyPlaceholder
        loading={props.isLoading}
        icon={faChartBar}
        skeletonVariant="cartesian"
        text={copyText.chartEmptyPlaceholderText}
      />
    );
  }

  return (
    <StyledBox>
      <ResponsiveContainer debounce={1} height="100%" width="100%">
        <ComposedChart data={chartData} margin={{ left: 25, right: 25 }}>
          <CartesianGrid
            {...cartesianStyleProps}
            stroke={theme.chart_cartesian_grid_lines}
          />

          <XAxis
            {...xAxisStyleProps}
            dataKey="timestamp"
            stroke={theme.chart_axis_text}
            tick={{
              stroke: theme.chart_axis_text,
              fontWeight: 100,
              fontSize: "0.8rem",
            }}
            tickFormatter={(value) => formatTimestamp(value, dateFormat)}
          />

          <YAxis
            {...yAxisStyleProps}
            type="number"
            domain={["auto", "auto"]}
            stroke={theme.chart_axis_text}
            tick={{
              stroke: theme.chart_axis_text,
              fontWeight: 100,
              fontSize: "0.8rem",
            }}
            tickCount={8}
            tickFormatter={formatYAxisValue}
          />

          <Tooltip
            content={(tooltipProps) => (
              <TimeSeriesChartTooltip
                customColorsKeyedByGrouping={customColors}
                dateFormat={dateFormat}
                entry={tooltipProps.payload?.[0]?.payload}
                excludedGroupings={[]}
                hideTotal
                label={tooltipProps.label}
                reverseSortedGroupings={props.customMeasures.map((m) => m.key)}
                formatter={formatYAxisValue}
                formatGroupingLabelFromKey={(key) =>
                  keyedCustomMeasures[key]?.label ?? key
                }
              />
            )}
            wrapperStyle={{ outline: "none" }}
          />

          <Legend
            iconType="square"
            verticalAlign="bottom"
            formatter={(key) => keyedCustomMeasures[key]?.label ?? key}
          />

          {props.customMeasures.map((measure) => (
            <Bar
              {...barStyleProps}
              key={measure.key}
              barSize={20}
              dataKey={measure.key}
              fill={measure.color}
              fillOpacity={theme.chart_fill_opacity}
              stackId="1"
            />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </StyledBox>
  );
}

const StyledBox = styled(Box)`
  height: 100%;

  .recharts-legend-wrapper {
    max-height: 8rem;
    overflow-y: auto;
  }

  .recharts-legend-item {
    display: flex !important;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: ${({ theme }) => theme.space_xs};

    .recharts-symbols {
      d: path("M -8 -16 h 32 v 32 h -32 Z");
    }
  }

  .recharts-text.recharts-label {
    color: ${(props) => props.theme.text_color} !important;
    font-size: ${({ theme }) => theme.fontSize_ui};
  }

  span.recharts-legend-item-text {
    color: ${(props) => props.theme.text_color} !important;
    display: block;
    font-size: ${({ theme }) => theme.fontSize_ui};
    /* Bring in the below if legend key lengths become an issue */
    /* max-width: 40rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
  }

  .recharts-default-legend {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;

    li {
      cursor: pointer;
    }
  }
`;

function mergeAtKeys(data: RawData[], keys: { key: string }[]) {
  return data.reduce((sum, datum) => {
    const copy = { ...sum };

    keys.forEach(({ key }) => {
      const current = datum[key];
      const total = copy[key];
      if (typeof current === "number" && typeof total === "number") {
        copy[key] = current + total;
      }
    });

    return copy;
  });
}
