import { ServiceType } from "@ternary/api-lib/constants/enums";
import {
  nullable,
  number,
  optional,
  string,
} from "../../../api/analytics/utils/Cubestruct";

export const AWSCommitmentUsageStruct = {
  dailyCommitmentCost: nullable(number()),
  onDemandCost: nullable(number()),
  onDemandNormalizedUsageHours: nullable(number()),
  onDemandUsageHours: nullable(number()),
  riCoveragePercentage: nullable(number()),
  riCoveredNormalizedUsageHours: nullable(number()),
  riCoveredUsageCost: nullable(number()),
  riCoveredUsageHours: nullable(number()),
  riRealizedSavings: nullable(number()),
  riUnusedCommitmentCost: nullable(number()),
  riUnusedHours: nullable(number()),
  spCoveragePercentage: nullable(number()),
  spCoveredNormalizedUsageHours: nullable(number()),
  spCoveredUsageCost: nullable(number()),
  spRealizedSavings: nullable(number()),
  timestamp: optional(string()),
};

export const AWSCommitmentAllocationStruct = {
  // MEASURES
  amortizedCost: nullable(number()),
  countDistinctARN: nullable(number()),
  coveredUsageHours: nullable(number()),
  effectiveCost: nullable(number()),
  lineItemBlendedCost: nullable(number()),
  lineItemUnblendedCost: nullable(number()),
  netEffectiveCost: nullable(number()),
  riNetAmortizedUpfrontCostForUsage: nullable(number()),
  riNetRecurringFeeForUsage: nullable(number()),
  riRecurringFeeForUsage: nullable(number()),
  spCoveredUsageCost: nullable(number()),
  spNetAmortizedUpfrontCommitmentForBillingPeriod: nullable(number()),
  spNetRecurringCommitmentForBillingPeriod: nullable(number()),
  spRecurringCommitmentForBillingPeriod: nullable(number()),

  // DIMENSIONS
  billPayerAccountId: optional(nullable(string())),
  commitmentARN: optional(nullable(string())),
  commitmentType: optional(nullable(string())),
  coverageType: optional(nullable(string())),
  instanceId: optional(nullable(string())),
  lineItemUsageAccountId: optional(nullable(string())),
  offeringType: optional(nullable(string())),
  region: optional(nullable(string())),
};

export const AWSCommitmentInventoryStruct = {
  // measures
  effectiveSavingsRate: nullable(number()),
  realizedSavings: nullable(number()),
  unrealizedSavings: nullable(number()),
  utilizationPercentage: nullable(number()),

  // dimensions
  availabilityZone: optional(nullable(string())),
  cloudId: optional(nullable(string())),
  commitmentId: optional(nullable(string())),
  expirationDate: optional(nullable(string())),
  instance_count: optional(nullable(number())),
  instance_type: optional(nullable(string())),
  offeringClass: optional(nullable(string())),
  offeringType: optional(nullable(string())),
  operatingSystem: optional(nullable(string())),
  purchaseDate: optional(nullable(string())),
  region: optional(nullable(string())),
  scope: optional(nullable(string())),
  serviceDescription: optional(nullable(string())),
  state: optional(nullable(string())),
  term: optional(nullable(string())),
  type: optional(nullable(string())),
  upfrontCost: optional(nullable(number())),
  usagePrice: optional(nullable(number())),
};

export type AWSAllocationChartDatum = {
  accountID: string;
  effectiveCost: number;
  netCost: number;
};

export type AWSCommittedUseChartData = {
  covered: number;
  estimated: number;
  timestamp: string;
  usage: number;
};

export type AWSCommitmentAllocationDatum = {
  amortizedCost: number;
  countDistinctARN: number;
  coveredUsageHours: number;
  effectiveCost: number;
  lineItemBlendedCost: number;
  lineItemUnblendedCost: number;
  netEffectiveCost: number;
  riNetAmortizedUpfrontCostForUsage: number;
  riNetRecurringFeeForUsage: number;
  riRecurringFeeForUsage: number;
  spCoveredUsageCost: number;
  spNetAmortizedUpfrontCommitmentForBillingPeriod: number;
  spNetRecurringCommitmentForBillingPeriod: number;
  spRecurringCommitmentForBillingPeriod: number;

  billPayerAccountId: string;
  commitmentARN: string;
  commitmentType: string;
  coverageType: string;
  instanceId: string | null;
  lineItemUsageAccountId: string;
  offeringType: string;
  region: string;
};
export type AWSCommitmentInventoryDatum = {
  availabilityZone: string;
  cloudId: string;
  commitmentId: string;
  effectiveSavingsRate: number;
  expirationDate: string;
  family: string;
  instanceCount: number;
  instanceSize: string;
  instanceType: string;
  offeringClass: string;
  offeringType: string;
  operatingSystem: string;
  purchaseDate: string;
  realizedSavings: number;
  region: string;
  scope: string;
  serviceDescription: string;
  state: string;
  term: string;
  type: string;
  unrealizedSavings: number;
  upfrontCost: number;
  usagePrice: number;
  utilizationPercentage: number;
};

export type AWSCommitmentInventoryFilter = {
  commitmentId: string | null;
  family: string | null;
  instanceSize: string | null;
  operatingSystem: string | null;
  region: string | null;
  status: string | null;
  type: string | null;
  serviceDescription: string | null;
};

export type AWSCommitmentUsageDatum = {
  dailyCommitmentCost: number;
  onDemandCost: number;
  onDemandNormalizedUsageHours: number;
  onDemandUsageHours: number;
  realizedSavings: number;
  riCoveragePercentage: number;
  riCoveredNormalizedUsageHours: number;
  riCoveredUsageCost: number;
  riCoveredUsageHours: number;
  riRealizedSavings: number;
  riUnusedCommitmentCost: number;
  riUnusedHours: number;
  spCoveragePercentage: number;
  spCoveredNormalizedUsageHours: number;
  spCoveredUsageCost: number;
  spRealizedSavings: number;
  timestamp: string;
};

export type AWSCommittedUseFilter = {
  payerAccountID: string;
  linkedAccountIDs: string[];
  accountScope: AWSAccountLevel;
  lookbackPeriod: string;
  minSavings: number;
  minUtil: number;
  offeringClass: string;
  paymentOption: string;
  savingsPlanType: string;
  serviceType: ServiceType;
  term: string;
};

// Enums

export const AWSAccountLevel = {
  PAYER: "PAYER",
  LINKED: "LINKED",
} as const;

export type AWSAccountLevel =
  (typeof AWSAccountLevel)[keyof typeof AWSAccountLevel];

export const AWSCommittedUseAllocationType = {
  ACCOUNT: "ACCOUNT",
  RESOURCE: "RESOURCE",
} as const;

export type AWSCommittedUseAllocationType =
  (typeof AWSCommittedUseAllocationType)[keyof typeof AWSCommittedUseAllocationType];

export const AWSCommittedUseLookbackPeriod = {
  FOURTEEN_DAYS: "FOURTEEN_DAYS",
  SEVEN_DAYS: "SEVEN_DAYS",
  SIXTY_DAYS: "SIXTY_DAYS",
  THIRTY_DAYS: "THIRTY_DAYS",
} as const;

export type AWSCommittedUseLookbackPeriod =
  (typeof AWSCommittedUseLookbackPeriod)[keyof typeof AWSCommittedUseLookbackPeriod];

export const AWSCommittedUseOfferingClass = {
  CONVERTIBLE: "CONVERTIBLE",
  STANDARD: "STANDARD",
} as const;

export type AWSCommittedUseOfferingClass =
  (typeof AWSCommittedUseOfferingClass)[keyof typeof AWSCommittedUseOfferingClass];

export const AWSCommittedUsePaymentOption = {
  ALL_UPFRONT: "ALL_UPFRONT",
  NO_UPFRONT: "NO_UPFRONT",
  PARTIAL_UPFRONT: "PARTIAL_UPFRONT",
} as const;

export type AWSCommittedUsePaymentOption =
  (typeof AWSCommittedUsePaymentOption)[keyof typeof AWSCommittedUsePaymentOption];

export const AWSCommittedUseSavingsPlanType = {
  COMPUTE_SP: "COMPUTE_SP",
  EC2_INSTANCE_SP: "EC2_INSTANCE_SP",
  SAGEMAKER_SP: "SAGEMAKER_SP",
} as const;

export type AWSCommittedUseSavingsPlanType =
  (typeof AWSCommittedUseSavingsPlanType)[keyof typeof AWSCommittedUseSavingsPlanType];

export const AWSCommittedUseTerm = {
  ONE_YEAR: "ONE_YEAR",
  THREE_YEARS: "THREE_YEARS",
} as const;

export type AWSCommittedUseTerm =
  (typeof AWSCommittedUseTerm)[keyof typeof AWSCommittedUseTerm];

export const AWSCommittedUseType = {
  RI: "RI",
  SP: "SP",
} as const;

export const AWSCommittedServiceType = {
  EC2: "Amazon Elastic Compute Cloud",
  RDS: "Amazon Relational Database Service",
};

export type AWSCommittedUseType =
  (typeof AWSCommittedUseType)[keyof typeof AWSCommittedUseType];

export type AWSCommittedServiceType =
  (typeof AWSCommittedServiceType)[keyof typeof AWSCommittedServiceType];

export const AWSCommittedUseDimensions = {
  family: "family",
  offeringID: "offeringID",

  platform: "platform",
  region: "region",
  tenancy: "tenancy",
  type: "type",
} as const;

export const AWSCommittedUseMeasures = {
  averageUtilization: "averageUtilization",
  currentAverageHourlyOnDemandSpend: "currentAverageHourlyOnDemandSpend",
  estimatedAverageUtilization: "estimatedAverageUtilization",
  estimatedBreakEvenInMonths: "estimatedBreakEvenInMonths",
  estimatedMonthlySavingsAmount: "estimatedMonthlySavingsAmount",
  estimatedMonthlySavingsPercentage: "estimatedMonthlySavingsPercentage",
  estimatedOnDemandCost: "estimatedOnDemandCost",
  estimatedROI: "estimatedROI",
  estimatedSavingsPlanCost: "estimatedSavingsPlanCost",
  hourlyCommitmentToPurchase: "hourlyCommitmentToPurchase",
  instanceSize: "instanceSize",
  normalizedUnitsToPurchase: "normalizedUnitsToPurchase",
  recurringStandardMonthlyCost: "recurringStandardMonthlyCost",
  recommendedNumberOfInstancesToPurchase:
    "recommendedNumberOfInstancesToPurchase",
  totalCost: "totalCost",
  upfrontCost: "upfrontCost",
} as const;
