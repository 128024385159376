import externalLinks from "@/constants/externalLinks";
import ParsedText from "@/ui-lib/components/ParsedText";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import Box from "@ternary/web-ui-lib/components/Box";
import React from "react";
import { FormField } from "../../../../../ui-lib/components/Form";
import copyText from "../../copyText";
import { AWS_ROLE_ARN_REGEX } from "./constants";
import { FormProps, RequiredInputValidation } from "./types";

const validators = {
  name: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
  monitoringRoleARN: ({ value }: { value: string }) => {
    return value.length > 0 && !AWS_ROLE_ARN_REGEX.test(value)
      ? copyText.errorInputFieldArnError
      : undefined;
  },
  roleARN: (props: RequiredInputValidation) => {
    if (props.value.length === 0) {
      return !props.isMspSharedIntegration && copyText.errorInputFieldRequired;
    }

    return !AWS_ROLE_ARN_REGEX.test(props.value)
      ? copyText.errorInputFieldArnError
      : undefined;
  },
};

const CaptionLink = (props: { link: string; children?: React.ReactNode }) => (
  <a href={props.link} rel="noreferrer" target="_blank">
    {props.children}
  </a>
);

export function AwsFormGeneral(props: FormProps): JSX.Element {
  const theme = useTheme();
  const { form, isMspSharedIntegration } = props;

  return (
    <Box padding={theme.space_xxs}>
      <form.Field
        name="name"
        validators={{ onChange: validators.name, onMount: validators.name }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              input={TextInput}
              disabled={isMspSharedIntegration}
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              label={copyText.awsFormAttributeName}
              required={isMspSharedIntegration ? undefined : true}
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <form.Field
        name="roleARN"
        validators={{
          onChange: (value) =>
            validators.roleARN({ ...value, isMspSharedIntegration }),
          onMount: (value) =>
            validators.roleARN({ ...value, isMspSharedIntegration }),
        }}
        children={(field) => {
          let shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          if (isMspSharedIntegration && field.state.value.length === 0) {
            shouldShowError = false;
          }

          return (
            <FormField
              input={TextInput}
              caption={
                <ParsedText
                  text={copyText.awsFormRoleARNCaption}
                  components={{
                    payerAccountLink: (
                      <CaptionLink
                        link={
                          externalLinks.awsIntegrationPayerAccountDocumentation
                        }
                      />
                    ),
                  }}
                />
              }
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              label={copyText.awsFormRoleARNLabel}
              placeholder={copyText.awsFormPlaceholderRoleArn}
              required={isMspSharedIntegration ? undefined : true}
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <form.Field
        name="monitoringRoleARN"
        validators={{
          onChange: validators.monitoringRoleARN,
          onMount: validators.monitoringRoleARN,
        }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              input={TextInput}
              caption={
                <ParsedText
                  text={copyText.awsFormMonitoringRoleARNCaption}
                  components={{
                    monitoringLink: (
                      <CaptionLink
                        link={
                          externalLinks.awsIntegrationMonitoringDocumentation
                        }
                      />
                    ),
                    centralizedLink: (
                      <CaptionLink
                        link={
                          externalLinks.awsIntegrationCentralizedMonitoringDocumentation
                        }
                      />
                    ),
                  }}
                />
              }
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              label={copyText.awsFormMonitoringRoleARNLabel}
              placeholder={copyText.awsFormPlaceholderRoleArn}
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
    </Box>
  );
}
