import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import UError from "unilib-error";
import { buildCubeQuery } from "../../../../api/analytics/utils";
import { ANALYTICS_QUERY_GC_TIME } from "../../../../constants";
import { useAnalyticsApiClient } from "../../../../context/AnalyticsQueryLoaderProvider";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { GCPCloudRunServiceGroup } from "../types";

interface Params {
  dateRange: Date[];
}

export default function useGetCloudRunServiceGroups(
  params: Params,
  options?: UseQueryOptions<GCPCloudRunServiceGroup[], UError>
): UseQueryResult<GCPCloudRunServiceGroup[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["cloudRunServiceGroups", params],
    queryFn: async () => {
      const visibilityDimensions = [
        "lowCarbon",
        "projectId",
        "region",
        "regionalPriceTier",
      ];

      const visibilityMeasures = [
        "averageNumServices",
        "averageMemory",
        "averageVCPU",
        "cost",
      ];

      const visibilityResult = (await client.load(
        buildCubeQuery({
          dataSource: DataSource.CLOUD_RUN,
          dateRange: params.dateRange,
          dimensions: visibilityDimensions,
          measures: visibilityMeasures,
        })
      )) as GCPCloudRunServiceGroup[];

      const validatedServiceGroup = visibilityResult.map(
        (datum): GCPCloudRunServiceGroup => {
          return {
            // DIMENSIONS
            lowCarbon: datum.lowCarbon,
            projectId: datum.projectId,
            region: datum.region,
            regionalPriceTier: datum.regionalPriceTier,

            // MEASURES
            averageMemory: datum.averageMemory,
            averageNumServices: datum.averageNumServices,
            averageVCPU: datum.averageVCPU,
            cost: datum.cost,
          };
        }
      );

      return validatedServiceGroup;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
