import { Input } from "@/types";
import Checkbox from "@/ui-lib/components/Checkbox";
import Form, { FormField } from "@/ui-lib/components/Form";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import {
  faEdit,
  faInfoCircle,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  DataSource,
  MetricAggregate,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/analytics/enums";
import { Dimension, Filter } from "@ternary/api-lib/analytics/ui/types";
import {
  AlertRuleConditionType,
  AlertRuleDirection,
  AlertRuleOperator,
  AlertRuleStatus,
} from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { isEqual, isInteger, keyBy, orderBy } from "lodash";
import React, { ChangeEvent, useRef, useState } from "react";
import isEmail from "validator/lib/isEmail";
import DualListbox from "../../../ui-lib/components/DualListBox";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import NumberInput from "../../../ui-lib/components/NumberInput";
import Select from "../../../ui-lib/components/Select";
import Switch from "../../../ui-lib/components/Switch";
import getMergeState from "../../../utils/getMergeState";
import { isTimeGranularity } from "../../../utils/typeGuards";
import copyText from "../copyText";
import { isAlertRuleOperator, isDirection } from "../utils";

export enum Action {
  CREATE = "CREATE",
  COPY = "COPY",
  UPDATE = "UPDATE",
}

type AlertSubscribers = {
  externalEmails: string[];
  includeAllUsers: boolean;
  userIDs: string[];
};

type AnomalyDetection = {
  type: typeof AlertRuleConditionType.ANOMALY_DETECTION;
  direction: AlertRuleDirection;
  lookbackDays: number;
  minimumDelta: number;
  sensitivity: number;
};

type Threshold = {
  type: typeof AlertRuleConditionType.THRESHOLDING;
  aggregation: MetricAggregate;
  evaluationWindow: {
    calendarWindow: TimeGranularity;
  };
  operator: AlertRuleOperator;
  useForecasting: boolean;
  value: number;
};

export type AlertRule = {
  id: string;
  condition: AnomalyDetection | Threshold;
  createdAt: string;
  createdByUserID: string;
  dataSource: DataSource;
  dimensions: string[];
  excludeTax: boolean;
  filters: Filter[];
  measure: string;
  name: string;
  subscribers: AlertSubscribers;
  status: AlertRuleStatus;
  timeGranularity: TimeGranularity;
  updatedAt: string | null;
  updatedByUserID: string | null;
};

interface DimensionValuesMap {
  [key: string]: string[];
}

export interface Props {
  action: Action;
  isLoadingDimensionValuesMap: boolean;
  isProcessing: boolean;
  availableDimensions: Dimension[];
  users: User[];
  alertRule?: AlertRule;
  dimensionValuesMap: DimensionValuesMap;
  onInteraction: (interaction: AlertRuleForm.Interaction) => void;
}

export type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

interface State {
  accountsInput: Input<string[]>;
  directionInput: Input<AlertRuleDirection>;
  enableAiDetectionInput: Input<boolean>;
  externalSubscriberEmailInput: string;
  excludeTaxInput: Input<boolean>;
  isUpdatingAccounts: boolean;
  isUpdatingLabels: boolean;
  isUpdatingProjectIDs: boolean;
  isUpdatingProjectNames: boolean;
  isUpdatingServices: boolean;
  isUpdatingSkus: boolean;
  isUpdatingSubscribers: boolean;
  labelsInput: Input<string[]>;
  lookbackDaysInput: Input<number>;
  minimumDeltaInput: Input<number>;
  nameInput: Input<string>;
  operatorInput: Input<AlertRuleOperator>;
  projectIDsInput: Input<string[]>;
  projectNamesInput: Input<string[]>;
  removeFilters: boolean;
  sensitivityInput: Input<number>;
  servicesInput: Input<string[]>;
  skusInput: Input<string[]>;
  subscribersInput: Input<AlertSubscribers>;
  timeGranularityInput: Input<TimeGranularity>;
}

const initialState: State = {
  accountsInput: { value: [], isValid: true, hasChanged: false },
  directionInput: {
    value: AlertRuleDirection.DEFAULT,
    isValid: true,
    hasChanged: false,
  },
  enableAiDetectionInput: { value: true, isValid: true, hasChanged: false },
  externalSubscriberEmailInput: "",
  excludeTaxInput: { value: false, isValid: true, hasChanged: false },
  isUpdatingAccounts: false,
  isUpdatingLabels: false,
  isUpdatingProjectIDs: false,
  isUpdatingProjectNames: false,
  isUpdatingServices: false,
  isUpdatingSkus: false,
  isUpdatingSubscribers: false,
  labelsInput: { value: [], isValid: true, hasChanged: false },
  lookbackDaysInput: { value: 3, isValid: true, hasChanged: false },
  minimumDeltaInput: { value: 0, isValid: true, hasChanged: false },
  nameInput: { value: "", isValid: false, hasChanged: false },
  operatorInput: {
    value: AlertRuleOperator.GREATER_EQUAL,
    isValid: true,
    hasChanged: false,
  },
  projectIDsInput: { value: [], isValid: true, hasChanged: false },
  projectNamesInput: { value: [], isValid: true, hasChanged: false },
  removeFilters: false,
  sensitivityInput: { value: 0, isValid: true, hasChanged: false },
  servicesInput: { value: [], isValid: true, hasChanged: false },
  skusInput: { value: [], isValid: true, hasChanged: false },
  subscribersInput: {
    value: { includeAllUsers: true, userIDs: [], externalEmails: [] },
    isValid: true,
    hasChanged: false,
  },
  timeGranularityInput: {
    value: TimeGranularity.DAY,
    isValid: true,
    hasChanged: false,
  },
};

export function AlertRuleForm(props: Props): JSX.Element {
  const theme = useTheme();
  //
  // State
  //

  const previousAccountFilters =
    props.alertRule?.filters.find(
      (filter) => filter.name === "billingAccountId"
    )?.values ?? [];

  const previousProjectIDFilters =
    props.alertRule?.filters.find((filter) => filter.name === "projectId")
      ?.values ?? [];

  const previousProjectNameFilters =
    props.alertRule?.filters.find((filter) => filter.name === "projectName")
      ?.values ?? [];

  const previousServiceFilters =
    props.alertRule?.filters.find(
      (filter) => filter.name === "serviceDescription"
    )?.values ?? [];

  const previousSkuFilters =
    props.alertRule?.filters.find((filter) => filter.name === "skuDescription")
      ?.values ?? [];

  const [state, setState] = useState(
    props.alertRule
      ? {
          ...initialState,
          excludeTaxInput: {
            value: props.alertRule.excludeTax,
            isValid: true,
            hasChanged: false,
          },
          nameInput: {
            value: `${props.alertRule.name} ${
              props.action === Action.COPY
                ? copyText.alertRuleFormCopyNameLabel
                : ""
            }`,
            isValid: true,
            hasChanged: false,
          },
          enableAiDetectionInput: {
            value:
              props.alertRule.condition.type ===
              AlertRuleConditionType.ANOMALY_DETECTION,
            isValid: true,
            hasChanged: false,
          },
          ...(props.alertRule.condition.type ===
          AlertRuleConditionType.ANOMALY_DETECTION
            ? {
                lookbackDaysInput: {
                  value: props.alertRule.condition.lookbackDays,
                  isValid: true,
                  hasChanged: false,
                },
                minimumDeltaInput: {
                  value: props.alertRule.condition.minimumDelta,
                  isValid: true,
                  hasChanged: false,
                },
                sensitivityInput: {
                  value: props.alertRule.condition.sensitivity * 100,
                  isValid: true,
                  hasChanged: false,
                },
                directionInput: {
                  value: props.alertRule.condition.direction,
                  isValid: true,
                  hasChanged: false,
                },
              }
            : {
                minimumDeltaInput: {
                  value: props.alertRule.condition.value,
                  isValid: true,
                  hasChanged: false,
                },
                operatorInput: {
                  value: props.alertRule.condition.operator,
                  isValid: true,
                  hasChanged: false,
                },
                sensitivityInput: {
                  value: 0,
                  isValid: true,
                  hasChanged: false,
                },
              }),

          timeGranularityInput: {
            value: props.alertRule.timeGranularity,
            isValid: true,
            hasChanged: false,
          },
          accountsInput: {
            value: previousAccountFilters,
            isValid: true,
            hasChanged: false,
          },
          projectIDsInput: {
            value: previousProjectIDFilters,
            isValid: true,
            hasChanged: false,
          },
          projectNamesInput: {
            value: previousProjectNameFilters,
            isValid: true,
            hasChanged: false,
          },
          labelsInput: {
            value: props.alertRule.dimensions,
            isValid: true,
            hasChanged: false,
          },
          servicesInput: {
            value: previousServiceFilters,
            isValid: true,
            hasChanged: false,
          },
          skusInput: {
            value: previousSkuFilters,
            isValid: true,
            hasChanged: false,
          },
          subscribersInput: {
            value: {
              includeAllUsers: props.alertRule.subscribers.includeAllUsers,
              userIDs: props.alertRule.subscribers.userIDs,
              externalEmails: props.alertRule.subscribers.externalEmails,
            },
            isValid: true,
            hasChanged: false,
          },
          externalSubscriberEmailInput: "",
        }
      : initialState
  );
  const mergeState = getMergeState(setState);
  const externalEmailInputRef = useRef<HTMLInputElement | null>(null);

  //
  // Interaction Handlers
  //

  function handleChangeName(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = event.target.value;

    const isValid = value.trim().length > 0;
    const hasChanged = !isEqual(value, props.alertRule?.name);

    mergeState({ nameInput: { value, isValid, hasChanged } });
  }

  function handleChangeAiDetection(event: ChangeEvent<HTMLInputElement>) {
    mergeState({
      enableAiDetectionInput: {
        value: event.target.checked,
        isValid: true,
        hasChanged: !isEqual(
          props.alertRule?.condition.type ===
            AlertRuleConditionType.ANOMALY_DETECTION,
          event.target.checked
        ),
      },
      sensitivityInput: {
        value: 0,
        isValid: true,
        hasChanged:
          props.alertRule?.condition.type ===
          AlertRuleConditionType.ANOMALY_DETECTION
            ? props.alertRule.condition.sensitivity === 0
            : false,
      },
      timeGranularityInput: {
        value: TimeGranularity.DAY,
        isValid: true,
        hasChanged: !isEqual(
          props.alertRule?.timeGranularity,
          TimeGranularity.DAY
        ),
      },
    });
  }

  function handleChangeMinimumDelta(value: number): void {
    const isValid = value >= 0;

    const hasChanged = !isEqual(
      value,
      props.alertRule
        ? props.alertRule.condition.type ===
          AlertRuleConditionType.ANOMALY_DETECTION
          ? props.alertRule.condition.minimumDelta
          : props.alertRule.condition.value
        : false
    );

    mergeState({
      minimumDeltaInput: { value, isValid, hasChanged },
    });
  }

  function handleChangeSensitivity(sensitivity: number): void {
    if (!state.enableAiDetectionInput.value) {
      return;
    }

    const isValid = sensitivity >= 0;

    const hasChanged =
      props.alertRule &&
      props.alertRule.condition.type ===
        AlertRuleConditionType.ANOMALY_DETECTION
        ? !isEqual(sensitivity, props.alertRule.condition.sensitivity)
        : true;

    mergeState({
      sensitivityInput: { value: sensitivity, isValid, hasChanged },
    });
  }

  function handleChangeGranularity(granularity: string): void {
    if (!isTimeGranularity(granularity)) return;

    const hasChanged = !isEqual(granularity, props.alertRule?.timeGranularity);

    mergeState({
      timeGranularityInput: { value: granularity, isValid: true, hasChanged },
    });
  }

  function handleChangeDirection(direction: string): void {
    if (
      !state.enableAiDetectionInput.value ||
      (props.alertRule &&
        props.alertRule?.condition.type !==
          AlertRuleConditionType.ANOMALY_DETECTION)
    ) {
      return;
    }

    if (!isDirection(direction)) return;

    const hasChanged =
      props.alertRule &&
      props.alertRule.condition.type ===
        AlertRuleConditionType.ANOMALY_DETECTION
        ? !isEqual(direction, props.alertRule.condition.direction)
        : true;

    mergeState({
      directionInput: { value: direction, isValid: true, hasChanged },
    });
  }

  function handleChangeLookbackDays(days: number): void {
    if (
      props.alertRule &&
      props.alertRule?.condition.type !==
        AlertRuleConditionType.ANOMALY_DETECTION
    ) {
      return;
    }

    if (!isInteger(days)) {
      days = Math.round(days);
    }

    const isValid = days >= 2;

    const hasChanged =
      props.alertRule &&
      props.alertRule.condition.type ===
        AlertRuleConditionType.ANOMALY_DETECTION
        ? !isEqual(days, props.alertRule?.condition.lookbackDays)
        : true;

    mergeState({
      lookbackDaysInput: { value: days, isValid, hasChanged },
    });
  }

  function handleChangeOperator(operator: string): void {
    if (
      state.enableAiDetectionInput.value ||
      props.alertRule?.condition.type !== AlertRuleConditionType.THRESHOLDING
    ) {
      return;
    }

    if (!isAlertRuleOperator(operator)) return;

    const hasChanged = !isEqual(operator, props.alertRule.condition.operator);

    mergeState({
      operatorInput: { value: operator, isValid: true, hasChanged },
    });
  }

  function handleAddExternalSubscriberEmail(): void {
    const email = state.externalSubscriberEmailInput;

    if (!isEmail(email)) return;

    const internalUserWithEmail = props.users.find((user) =>
      isSameIgnoreCase(user.email, email)
    );
    if (internalUserWithEmail) return;

    setState((currentState) => {
      let nextExternalEmails: string[];

      if (
        currentState.subscribersInput.value.externalEmails.find((other) =>
          isSameIgnoreCase(other, email)
        )
      ) {
        // this email is already in the list. Move to top
        nextExternalEmails = [
          email,
          ...currentState.subscribersInput.value.externalEmails.filter(
            (other) => !isSameIgnoreCase(other, email)
          ),
        ];
      } else {
        nextExternalEmails = [
          email,
          ...currentState.subscribersInput.value.externalEmails,
        ];
      }

      const hasChanged = !isEqual(
        nextExternalEmails,
        props.alertRule?.subscribers.externalEmails
      );

      return {
        ...currentState,
        externalSubscriberEmailInput: "",
        subscribersInput: {
          value: {
            ...currentState.subscribersInput.value,
            externalEmails: nextExternalEmails,
          },
          isValid: true,
          hasChanged,
        },
      };
    });
  }

  function handleInternalSubscribedUsersChange(newSelection: string[]): void {
    setState((currentState) => {
      if (newSelection.length === props.users.length) {
        const newSubscribers = {
          externalEmails: currentState.subscribersInput.value.externalEmails,
          includeAllUsers: true,
          userIDs: [],
        };

        const hasChanged = !isEqual(
          newSubscribers,
          props.alertRule?.subscribers
        );
        return {
          ...currentState,
          subscribersInput: {
            value: newSubscribers,
            isValid: true,
            hasChanged,
          },
        };
      } else {
        const newSubscribers = {
          externalEmails: currentState.subscribersInput.value.externalEmails,
          includeAllUsers: false,
          userIDs: newSelection.map((id) => id),
        };

        const hasChanged = !isEqual(
          newSubscribers,
          props.alertRule?.subscribers
        );
        return {
          ...currentState,
          subscribersInput: {
            value: newSubscribers,
            isValid: true,
            hasChanged,
          },
        };
      }
    });
  }

  function handleRemoveExternalSubscriber(emailToRemove: string) {
    function filterExternal<T extends AlertSubscribers | null>(
      subscribers: T
    ): T {
      if (!subscribers) return subscribers;

      return {
        ...subscribers,
        externalEmails: subscribers.externalEmails.filter(
          (email) => !isSameIgnoreCase(email, emailToRemove)
        ),
      };
    }

    setState((currentState) => {
      const newSubscribers = filterExternal(
        currentState.subscribersInput.value
      );

      const hasChanged = !isEqual(newSubscribers, props.alertRule?.subscribers);

      return {
        ...currentState,
        subscribersInput: {
          value: newSubscribers,
          isValid: true,
          hasChanged,
        },
      };
    });
  }

  function handleRemoveInternalSubscriber(userIDToRemove: string) {
    setState((currentState) => {
      const newUserIDs = currentState.subscribersInput.value.userIDs.filter(
        (id) => !isSameIgnoreCase(id, userIDToRemove)
      );

      const newSubscribers = {
        ...currentState.subscribersInput.value,
        userIDs: newUserIDs,
      };

      const hasChanged = !isEqual(newSubscribers, props.alertRule?.subscribers);

      return {
        ...currentState,
        subscribersInput: {
          value: newSubscribers,
          isValid: true,
          hasChanged,
        },
      };
    });
  }

  function handleChangeIsUpdating(input: string) {
    setState((currentState) => ({
      ...currentState,
      isUpdatingAccounts: false,
      isUpdatingProjectIDs: false,
      isUpdatingProjectNames: false,
      isUpdatingLabels: false,
      isUpdatingServices: false,
      isUpdatingSkus: false,
      isUpdatingSubscribers: false,
      [`isUpdating${input}`]: !currentState[`isUpdating${input}`],
    }));
  }

  function handleCancel(): void {
    props.onInteraction({
      type: AlertRuleForm.INTERACTION_CANCEL_BUTTON_CLICKED,
    });

    setState(initialState);
  }

  function handleSubmit(event: React.MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();

    const accountFilters = {
      name: "billingAccountId",
      operator: Operator.EQUALS,
      values: state.accountsInput.value,
    };

    const projectIDFilters = {
      name: "projectId",
      operator: Operator.EQUALS,
      values: state.projectIDsInput.value,
    };

    const projectNameFilters = {
      name: "projectName",
      operator: Operator.EQUALS,
      values: state.projectNamesInput.value,
    };

    const serviceFilters = {
      name: "serviceDescription",
      operator: Operator.EQUALS,
      values: state.servicesInput.value,
    };

    const skuFilters = {
      name: "skuDescription",
      operator: Operator.EQUALS,
      values: state.skusInput.value,
    };

    const filters = [
      ...(state.accountsInput.value.length > 0 ? [accountFilters] : []),
      ...(state.projectIDsInput.value.length > 0 ? [projectIDFilters] : []),
      ...(state.projectNamesInput.value.length > 0 ? [projectNameFilters] : []),
      ...(state.servicesInput.value.length > 0 ? [serviceFilters] : []),
      ...(state.skusInput.value.length > 0 ? [skuFilters] : []),
    ];

    const filtersHaveChanged =
      state.accountsInput.hasChanged ||
      state.projectIDsInput.hasChanged ||
      state.projectNamesInput.hasChanged ||
      state.servicesInput.hasChanged ||
      state.skusInput.hasChanged;

    const condition = state.enableAiDetectionInput.value
      ? {
          type: AlertRuleConditionType.ANOMALY_DETECTION,
          direction: state.directionInput.value,
          lookbackDays: state.lookbackDaysInput.value,
          minimumDelta: state.minimumDeltaInput.value,
          sensitivity: state.sensitivityInput.value / 100,
        }
      : {
          type: AlertRuleConditionType.THRESHOLDING,
          aggregation: MetricAggregate.SUM,
          evaluationWindow: {
            calendarWindow: state.timeGranularityInput.value,
          },
          operator: state.operatorInput.value,
          useForecasting: false,
          value: state.minimumDeltaInput.value,
        };

    if (props.action === Action.COPY || props.action === Action.CREATE) {
      props.onInteraction({
        type: AlertRuleForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE,
        condition: condition,
        direction: state.directionInput.value,
        excludeTax: state.excludeTaxInput.value,
        filters: filters,
        name: state.nameInput.value,
        selectedLabels: state.labelsInput.value,
        subscribers: state.subscribersInput.value,
        timeGranularity: state.timeGranularityInput.value,
      });
    } else {
      const conditionHasChanged =
        state.enableAiDetectionInput.hasChanged ||
        (state.enableAiDetectionInput.value
          ? state.directionInput.hasChanged ||
            state.lookbackDaysInput.hasChanged ||
            state.minimumDeltaInput.hasChanged ||
            state.sensitivityInput.hasChanged
          : state.minimumDeltaInput.hasChanged ||
            state.operatorInput.hasChanged ||
            state.timeGranularityInput.hasChanged);

      props.onInteraction({
        type: AlertRuleForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE,
        ...(conditionHasChanged ? { condition } : {}),
        ...(filtersHaveChanged ? { filters } : {}),
        ...(state.excludeTaxInput.hasChanged
          ? { excludeTax: state.excludeTaxInput.value }
          : {}),
        ...(state.labelsInput.hasChanged
          ? { selectedLabels: state.labelsInput.value }
          : {}),
        ...(state.nameInput.hasChanged ? { name: state.nameInput.value } : {}),
        ...(state.subscribersInput.hasChanged
          ? { subscribers: state.subscribersInput.value }
          : {}),
        ...(state.timeGranularityInput.hasChanged
          ? { timeGranularity: state.timeGranularityInput.value }
          : {}),
      });
    }
  }

  //
  // Validations
  //

  const hasChanged = Object.values(state).some((input) => input.hasChanged);

  const isValid =
    Object.values(state).every((input) => input.isValid !== false) &&
    // at least one of these must be greater than 0.
    (state.sensitivityInput.value > 0 || state.minimumDeltaInput.value > 0);

  const canSubmit =
    props.action === Action.UPDATE ? hasChanged && isValid : isValid;

  //
  // Render
  //

  const granularityOptions = [
    ...(state.enableAiDetectionInput.value
      ? [
          {
            label: copyText.granularityOptionLabelMinute,
            value: TimeGranularity.MINUTE,
          },
          {
            label: copyText.granularityOptionLabelHour,
            value: TimeGranularity.HOUR,
          },
        ]
      : []),
    {
      label: copyText.granularityOptionLabelDay,
      value: TimeGranularity.DAY,
    },
    ...(!state.enableAiDetectionInput.value
      ? [
          {
            label: copyText.granularityOptionLabelMonth,
            value: TimeGranularity.MONTH,
          },
        ]
      : []),
  ];

  const selectedGranularityOption = granularityOptions.find(
    (option) => option.value === state.timeGranularityInput.value
  );

  const directionOptions = [
    {
      label: copyText.directionOptionLabelIncreaseOnly,
      value: AlertRuleDirection.INCREASE_ONLY,
    },
    {
      label: copyText.directionOptionLabelDecreaseOnly,
      value: AlertRuleDirection.DECREASE_ONLY,
    },
    {
      label: copyText.directionOptionLabelDefault,
      value: AlertRuleDirection.DEFAULT,
    },
  ];

  const selectedDirectionOption = directionOptions.find(
    (option) => option.value === state.directionInput.value
  );

  const operatorOptions = [
    {
      label: copyText.operatorOptionGreaterEqual,
      value: AlertRuleOperator.GREATER_EQUAL,
    },
  ];

  const selectedOperatorOption = operatorOptions.find(
    (option) => option.value === state.operatorInput.value
  );

  const labelOptions = props.availableDimensions.map((dimension) => ({
    label: dimension.name,
    value: dimension.name,
  }));

  const billingAccountOptions =
    props.dimensionValuesMap.billingAccountId?.map((value) => ({
      label: value,
      value: value,
    })) ?? [];

  const projectIDOptions =
    props.dimensionValuesMap.projectId?.map((value) => ({
      label: value,
      value: value,
    })) ?? [];

  const projectNameOptions =
    props.dimensionValuesMap.projectName?.map((value) => ({
      label: value,
      value: value,
    })) ?? [];

  const serviceDescriptionOptions =
    props.dimensionValuesMap.serviceDescription?.map((value) => ({
      label: value,
      value: value,
    })) ?? [];

  const skuOptions =
    props.dimensionValuesMap.skuDescription?.map((value) => ({
      label: value,
      value: value,
    })) ?? [];

  //
  // JSX
  //

  return (
    <Form>
      <Flex direction="column" justifyContent="space-between">
        {/* Name */}
        <Box minWidth="300px">
          <FormField
            name="name"
            input={TextInput}
            label={copyText.nameInputLabel}
            value={state.nameInput.value}
            onChange={handleChangeName}
          />
        </Box>
        <Box width={420}>
          <Flex alignItems="center" marginBottom={theme.space_sm}>
            <Text appearance="h4">
              {copyText.alertRuleFormMinimumThresholdLabel}
            </Text>
            <Box marginLeft={theme.space_md}>
              <Checkbox
                checked={state.enableAiDetectionInput.value}
                label={copyText.alertRuleFormAiDetectionEnabledLabel}
                style={{ marginBottom: theme.space_xs }}
                onChange={handleChangeAiDetection}
              />
            </Box>
          </Flex>
          {state.enableAiDetectionInput.value && (
            <Flex>
              <Text appearance="h6" marginBottom={theme.space_xs}>
                {copyText.thresholdMinimumHelperText}
              </Text>
            </Flex>
          )}
          <Flex alignItems="center" justifyContent="space-between">
            {/* Minimum Delta and Sensitivity */}
            <FormField label={copyText.alertRuleFormCurrencyLabel}>
              <Box width={104}>
                <NumberInput
                  formatter="currency"
                  value={state.minimumDeltaInput.value}
                  onChange={handleChangeMinimumDelta}
                />
              </Box>
            </FormField>
            <Text
              marginHorizontal={theme.space_sm}
              color={
                !state.enableAiDetectionInput.value
                  ? theme.text_color_disabled
                  : theme.text_color
              }
            >
              {copyText.alertRuleFormAndLabel}
            </Text>
            <FormField label={copyText.alertRuleFormPercentageLabel}>
              <Box width={104}>
                <NumberInput
                  // Leaving the max unbounded for sensitivity because 150% is valid.
                  // Ex. Time series billing data with expected range [$50, $100].
                  // User might want to specify a sensitivity of 1.5 to express the intent of
                  // "alert me only if the observed value is $250 or higher"
                  disabled={!state.enableAiDetectionInput.value}
                  formatter="percent-divided"
                  value={state.sensitivityInput.value}
                  onChange={handleChangeSensitivity}
                />
              </Box>
            </FormField>
            <Text marginHorizontal={theme.space_sm}>
              {copyText.alertRuleFormPerLabel}
            </Text>
            {/* Granularity */}
            <FormField label={copyText.alertRuleFormGranularityLabel}>
              <Box width={104}>
                <Select
                  menuPlacement="bottom"
                  options={granularityOptions}
                  value={selectedGranularityOption}
                  onChange={(option) =>
                    option && handleChangeGranularity(option.value)
                  }
                />
              </Box>
            </FormField>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            {/* Direction*/}
            {state.enableAiDetectionInput.value ? (
              <FormField label={copyText.alertRuleFormDirectionLabel}>
                <Box width={220}>
                  <Select
                    menuPlacement="bottom"
                    options={directionOptions}
                    value={selectedDirectionOption}
                    onChange={(option) =>
                      option && handleChangeDirection(option.value)
                    }
                  />
                </Box>
              </FormField>
            ) : (
              <FormField label={copyText.alertRuleFormOperatorLabel}>
                <Box width={220}>
                  <Select
                    menuPlacement="bottom"
                    options={operatorOptions}
                    value={selectedOperatorOption}
                    onChange={(option) =>
                      option && handleChangeOperator(option.value)
                    }
                  />
                </Box>
              </FormField>
            )}
            {/* Lookback Days */}
            <FormField label={copyText.alertRuleFormLookbackDaysLabel}>
              <Box width={104}>
                <Tooltip
                  content={
                    state.enableAiDetectionInput.value
                      ? copyText.lookbackDaysTooltip
                      : copyText.lookbackDaysDisabledTooltip
                  }
                  placement="right"
                  width={
                    state.enableAiDetectionInput.value ? undefined : "200px"
                  }
                >
                  <NumberInput
                    disabled={!state.enableAiDetectionInput.value}
                    min={3}
                    max={90}
                    value={state.lookbackDaysInput.value}
                    onChange={handleChangeLookbackDays}
                  />
                </Tooltip>
              </Box>
            </FormField>
          </Flex>
        </Box>
        <Flex alignItems="center" marginBottom={theme.space_md}>
          <Text appearance="h5" marginRight={theme.space_sm}>
            {copyText.alertRuleFormExcludeTaxLabel}
          </Text>
          <Switch
            checked={state.excludeTaxInput.value}
            onChange={(checked) =>
              mergeState({
                excludeTaxInput: {
                  value: checked,
                  isValid: true,
                  hasChanged: true,
                },
              })
            }
          />
        </Flex>
        <Flex direction="column" justifyContent="space-between">
          <Text appearance="h4">{copyText.alertRuleFormFiltersLabel}</Text>
          {/* Filter: Billing Account */}
          <Flex direction="column">
            <Flex marginBottom={theme.space_xs} marginTop={theme.space_xs}>
              <Text appearance="h5">{copyText.alertRuleFormAccountsLabel}</Text>
              <Button
                iconEnd={<Icon icon={faEdit}></Icon>}
                secondary={state.isUpdatingAccounts}
                marginLeft={theme.space_xxs}
                size="tiny"
                type="button"
                onClick={() => {
                  handleChangeIsUpdating("Accounts");
                }}
              />
            </Flex>
            <Flex alignItems="center">
              {!state.isUpdatingAccounts ? (
                state.accountsInput.value.length > 0 ? (
                  <Flex direction="column">
                    {state.accountsInput.value.map((account) => {
                      return (
                        <Text appearance="h6" key={account}>
                          {account}
                        </Text>
                      );
                    })}
                  </Flex>
                ) : (
                  <Text appearance="h6">
                    {copyText.alertRuleFormAccountsAll}
                  </Text>
                )
              ) : null}
            </Flex>
          </Flex>
          {state.isUpdatingAccounts && (
            <Flex height={300}>
              <DualListbox
                isLoading={props.isLoadingDimensionValuesMap}
                options={billingAccountOptions}
                selectedOptions={state.accountsInput.value}
                onChange={(selectedOptions) => {
                  mergeState({
                    accountsInput: {
                      value: selectedOptions,
                      isValid: true,
                      hasChanged: !isEqual(
                        selectedOptions,
                        previousAccountFilters
                      ),
                    },
                  });
                }}
              ></DualListbox>
            </Flex>
          )}
          {/* Filter: ProjectID */}
          <Flex direction="column">
            <Flex marginBottom={theme.space_xs} marginTop={theme.space_lg}>
              <Text appearance="h5">
                {copyText.alertRuleFormProjectIDsLabel}
              </Text>
              <Button
                iconEnd={<Icon icon={faEdit}></Icon>}
                secondary={state.isUpdatingProjectIDs}
                marginLeft={theme.space_xxs}
                size="tiny"
                type="button"
                onClick={() => {
                  handleChangeIsUpdating("ProjectIDs");
                }}
              />
            </Flex>
            <Flex alignItems="center">
              {!state.isUpdatingProjectIDs ? (
                state.projectIDsInput.value.length > 0 ? (
                  <Flex direction="column">
                    {state.projectIDsInput.value.map((project) => {
                      return (
                        <Text appearance="h6" key={project}>
                          {project}
                        </Text>
                      );
                    })}
                  </Flex>
                ) : (
                  <Text appearance="h6">
                    {copyText.alertRuleFormProjectIDsAll}
                  </Text>
                )
              ) : null}
            </Flex>
          </Flex>
          {state.isUpdatingProjectIDs && (
            <Flex direction="column">
              <Flex height={300}>
                <DualListbox
                  isLoading={props.isLoadingDimensionValuesMap}
                  options={projectIDOptions}
                  selectedOptions={state.projectIDsInput.value}
                  onChange={(selectedOptions) => {
                    mergeState({
                      projectIDsInput: {
                        value: selectedOptions,
                        isValid: true,
                        hasChanged: !isEqual(
                          selectedOptions,
                          previousProjectIDFilters
                        ),
                      },
                    });
                  }}
                ></DualListbox>
              </Flex>
            </Flex>
          )}
          {/* Filter: Project Name */}
          <Flex direction="column">
            <Flex marginBottom={theme.space_xs} marginTop={theme.space_lg}>
              <Text appearance="h5">
                {copyText.alertRuleFormProjectNamesLabel}
              </Text>
              <Button
                iconEnd={<Icon icon={faEdit}></Icon>}
                secondary={state.isUpdatingProjectNames}
                marginLeft={theme.space_xxs}
                size="tiny"
                type="button"
                onClick={() => {
                  handleChangeIsUpdating("ProjectNames");
                }}
              />
            </Flex>
            <Flex alignItems="center">
              {!state.isUpdatingProjectNames ? (
                state.projectNamesInput.value.length > 0 ? (
                  <Flex direction="column">
                    {state.projectNamesInput.value.map((project) => {
                      return (
                        <Text appearance="h6" key={project}>
                          {project}
                        </Text>
                      );
                    })}
                  </Flex>
                ) : (
                  <Text appearance="h6">
                    {copyText.alertRuleFormProjectNamesAll}
                  </Text>
                )
              ) : null}
            </Flex>
          </Flex>
          {state.isUpdatingProjectNames && (
            <Flex direction="column">
              <Flex height={300}>
                <DualListbox
                  isLoading={props.isLoadingDimensionValuesMap}
                  options={projectNameOptions}
                  selectedOptions={state.projectNamesInput.value}
                  onChange={(selectedOptions) => {
                    mergeState({
                      projectNamesInput: {
                        value: selectedOptions,
                        isValid: true,
                        hasChanged: !isEqual(
                          selectedOptions,
                          previousProjectNameFilters
                        ),
                      },
                    });
                  }}
                ></DualListbox>
              </Flex>
            </Flex>
          )}
          {/* Filter: Service */}
          <Flex direction="column">
            <Flex marginBottom={theme.space_xs} marginTop={theme.space_lg}>
              <Text appearance="h5">{copyText.alertRuleFormServicesLabel}</Text>
              <Button
                iconEnd={<Icon icon={faEdit}></Icon>}
                secondary={state.isUpdatingServices}
                marginLeft={theme.space_xxs}
                size="tiny"
                type="button"
                onClick={() => {
                  handleChangeIsUpdating("Services");
                }}
              />
            </Flex>
            <Flex alignItems="center">
              {!state.isUpdatingServices ? (
                state.servicesInput.value.length > 0 ? (
                  <Flex direction="column">
                    {state.servicesInput.value.map((service) => {
                      return (
                        <Text appearance="h6" key={service}>
                          {service}
                        </Text>
                      );
                    })}
                  </Flex>
                ) : (
                  <Text appearance="h6">
                    {copyText.alertRuleFormServicesAll}
                  </Text>
                )
              ) : null}
            </Flex>
          </Flex>
          {state.isUpdatingServices && (
            <Flex direction="column">
              <Flex height={300}>
                <DualListbox
                  isLoading={props.isLoadingDimensionValuesMap}
                  options={serviceDescriptionOptions}
                  selectedOptions={state.servicesInput.value}
                  onChange={(selectedOptions) => {
                    mergeState({
                      servicesInput: {
                        value: selectedOptions,
                        isValid: true,
                        hasChanged: !isEqual(
                          selectedOptions,
                          previousServiceFilters
                        ),
                      },
                    });
                  }}
                ></DualListbox>
              </Flex>
            </Flex>
          )}
          {/* Filter: SKU */}
          <Flex direction="column">
            <Flex marginBottom={theme.space_xs} marginTop={theme.space_lg}>
              <Text appearance="h5">{copyText.alertRuleFormSkusLabel}</Text>
              <Button
                iconEnd={<Icon icon={faEdit}></Icon>}
                secondary={state.isUpdatingSkus}
                marginLeft={theme.space_xxs}
                size="tiny"
                type="button"
                onClick={() => {
                  handleChangeIsUpdating("Skus");
                }}
              />
            </Flex>
            <Flex alignItems="center">
              {!state.isUpdatingSkus ? (
                state.skusInput.value.length > 0 ? (
                  <Flex direction="column">
                    {state.skusInput.value.map((sku) => {
                      return (
                        <Text appearance="h6" key={sku}>
                          {sku}
                        </Text>
                      );
                    })}
                  </Flex>
                ) : (
                  <Text appearance="h6">{copyText.alertRuleFormSkusAll}</Text>
                )
              ) : null}
            </Flex>
          </Flex>
          {state.isUpdatingSkus && (
            <Flex direction="column">
              <Flex height={300}>
                <DualListbox
                  isLoading={props.isLoadingDimensionValuesMap}
                  options={skuOptions}
                  selectedOptions={state.skusInput.value}
                  onChange={(selectedOptions) => {
                    mergeState({
                      skusInput: {
                        value: selectedOptions,
                        isValid: true,
                        hasChanged: !isEqual(
                          selectedOptions,
                          previousSkuFilters
                        ),
                      },
                    });
                  }}
                ></DualListbox>
              </Flex>
            </Flex>
          )}

          {/* Labels */}
          <Flex direction="column">
            <Flex marginBottom={theme.space_xs} marginTop={theme.space_lg}>
              <Text appearance="h4">{copyText.alertRuleFormGroupByLabel}</Text>
              <Button
                iconEnd={<Icon icon={faEdit}></Icon>}
                secondary={state.isUpdatingLabels}
                marginLeft={theme.space_xxs}
                size="small"
                type="button"
                onClick={() => {
                  handleChangeIsUpdating("Labels");
                }}
              />
            </Flex>
            <Flex>
              <Text
                appearance="h6"
                marginBottom={theme.space_xs}
                marginRight={theme.space_xs}
              >
                {copyText.alertRuleFormGroupByHelperText}
              </Text>
              {state.isUpdatingLabels ? (
                <Tooltip
                  content={copyText.alertRuleFormGroupByTooltipContent}
                  width="225px"
                >
                  <Icon
                    color={theme.text_color_secondary}
                    icon={faInfoCircle}
                  />
                </Tooltip>
              ) : (
                ""
              )}
            </Flex>
            <Flex alignItems="center">
              {!state.isUpdatingLabels ? (
                state.labelsInput.value.length > 0 ? (
                  <Flex direction="column">
                    {state.labelsInput.value.map((label) => {
                      return (
                        <Text appearance="h5" key={label}>
                          {label}
                        </Text>
                      );
                    })}
                  </Flex>
                ) : (
                  <Flex>
                    <Text>{copyText.alertRuleFormNoGroupingsLabel}</Text>
                  </Flex>
                )
              ) : null}
            </Flex>
          </Flex>
          {state.isUpdatingLabels && (
            <Flex direction="column">
              <Flex height={300}>
                <DualListbox
                  isLoading={props.isLoadingDimensionValuesMap}
                  options={labelOptions}
                  selectedOptions={state.labelsInput.value}
                  onChange={(selectedOptions) => {
                    mergeState({
                      labelsInput: {
                        value: selectedOptions,
                        isValid: true,
                        hasChanged: !isEqual(
                          selectedOptions,
                          props.alertRule?.dimensions
                        ),
                      },
                    });
                  }}
                />
              </Flex>
            </Flex>
          )}
          {/* Subscribers */}
          <Flex direction="column">
            <Flex marginBottom={theme.space_xs} marginTop={theme.space_lg}>
              <Text appearance="h4">
                {copyText.alertRuleFormSubscribersLabel}
              </Text>
              <Button
                iconEnd={<Icon icon={faEdit}></Icon>}
                secondary={state.isUpdatingSubscribers}
                marginLeft={theme.space_xxs}
                size="small"
                type="button"
                onClick={() => {
                  handleChangeIsUpdating("Subscribers");
                }}
              />
            </Flex>
          </Flex>
          {state.isUpdatingSubscribers ? (
            <>
              {renderInternalUserSubscriberForm()}
              {renderExternalUserSubscriberForm()}
            </>
          ) : (
            renderSubscriberList()
          )}
        </Flex>
      </Flex>

      <Flex
        justifyContent={"flex-end"}
        marginTop={theme.space_sm}
        paddingBottom={theme.space_sm}
      >
        <Button
          disabled={props.isProcessing}
          secondary
          type="reset"
          width={100}
          onClick={handleCancel}
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit || props.isProcessing}
          marginLeft={theme.space_sm}
          primary
          type="button"
          width={100}
          onClick={handleSubmit}
        >
          {props.isProcessing ? <LoadingSpinner /> : copyText.submitButtonLabel}
        </Button>
      </Flex>
    </Form>
  );

  function renderInternalUserSubscriberForm() {
    if (!state.isUpdatingSubscribers) return null;

    const options = orderBy(props.users, "email", "asc").map((user) => ({
      label: user.email,
      value: user.id,
    }));

    const selectedOptions = state.subscribersInput.value.includeAllUsers
      ? props.users.map(({ id }) => id)
      : state.subscribersInput.value.userIDs;

    return (
      <FormField>
        <DualListbox
          options={options}
          selectedOptions={selectedOptions}
          onChange={handleInternalSubscribedUsersChange}
        />
      </FormField>
    );
  }

  function renderExternalUserSubscriberForm() {
    if (!state.isUpdatingSubscribers) return null;

    const inputIsInternalUser = !!props.users.find(({ email }) =>
      isSameIgnoreCase(email, state.externalSubscriberEmailInput)
    );

    return (
      <FormField label={copyText.alertRuleFormSubscribeNonAccountAddressLabel}>
        <Flex marginLeft={theme.space_md}>
          <Box width={200} flex="0 0 auto">
            <TextInput
              placeholder={copyText.alertRuleFormSubscribersEmailPlaceholder}
              inputRef={externalEmailInputRef}
              onChange={(e) =>
                mergeState({ externalSubscriberEmailInput: e.target.value })
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleAddExternalSubscriberEmail();
                }
              }}
              size="small"
              value={state.externalSubscriberEmailInput}
              variant={inputIsInternalUser ? "danger" : undefined}
            />
            {inputIsInternalUser && (
              <Box
                color={theme.feedback_negative}
                fontSize={theme.fontSize_small}
                fontWeight={theme.fontWeight_bold}
                marginLeft={theme.space_xxs}
                marginTop={theme.space_xxs}
              >
                {copyText.alertRuleFormSubscribersIsInternalWarning}
              </Box>
            )}
          </Box>
          <Box flex="1 0 0">
            <Flex direction="column" alignItems="flex-end">
              <Button
                disabled={
                  inputIsInternalUser ||
                  !isEmail(state.externalSubscriberEmailInput)
                }
                primary
                size="small"
                type="button"
                onClick={() => {
                  externalEmailInputRef.current?.focus();
                  handleAddExternalSubscriberEmail();
                }}
              >
                {copyText.alertRuleFormSubscribeNonAccountAddressLabel}
              </Button>
              <Box marginTop={theme.space_xxs} width={200}>
                {state.subscribersInput.value.externalEmails.map((email) => (
                  <Flex
                    key={email}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box flex="1 0 0" width={0}>
                      <Text truncate>{email}</Text>
                    </Box>

                    <Box>
                      <Button
                        onClick={() => handleRemoveExternalSubscriber(email)}
                        size="tiny"
                        type="button"
                      >
                        <Icon icon={faXmark} />
                      </Button>
                    </Box>
                  </Flex>
                ))}
              </Box>
            </Flex>
          </Box>
        </Flex>
      </FormField>
    );
  }

  function renderSubscriberList() {
    const usersKeyedByID = keyBy(props.users, "id");

    const externalEmails = state.subscribersInput.value.externalEmails;
    const internalIds = state.subscribersInput.value.userIDs;

    const isShowingNonAccountEmails = externalEmails.length > 0;
    const indent = isShowingNonAccountEmails ? theme.space_md : "";

    return (
      <FormField>
        <Flex direction="column" justifyContent="space-between">
          {/* Internal Subscribers */}
          {isShowingNonAccountEmails && (
            <Text appearance="h5">
              {copyText.alertRuleFormSubscribersListAccountLabel}
            </Text>
          )}
          {state.subscribersInput.value.includeAllUsers ? (
            <Text marginLeft={indent}>
              {copyText.alertRuleFormSubscribersAll}
            </Text>
          ) : internalIds.length === 0 ? (
            <Text marginLeft={indent}>
              {copyText.alertRuleFormSubscribersNone}
            </Text>
          ) : (
            internalIds.map((id) => (
              <Flex key={id} justifyContent="space-between" width={250}>
                <Text marginLeft={indent} truncate>
                  {usersKeyedByID[id]?.email ?? "---"}
                </Text>
                <Button
                  onClick={() => handleRemoveInternalSubscriber(id)}
                  size="tiny"
                  type="button"
                >
                  <Icon icon={faXmark} />
                </Button>
              </Flex>
            ))
          )}

          {/* External Subscribers */}
          {isShowingNonAccountEmails && (
            <>
              <Text appearance="h5">
                {copyText.alertRuleFormSubscribersListNonAccount}
              </Text>
              {externalEmails.map((email) => (
                <Flex key={email} justifyContent="space-between" width={250}>
                  <Text marginLeft={indent} truncate>
                    {email}
                  </Text>
                  <Button
                    key={email}
                    onClick={() => handleRemoveExternalSubscriber(email)}
                    size="tiny"
                    type="button"
                  >
                    <Icon icon={faXmark} />
                  </Button>
                </Flex>
              ))}
            </>
          )}
        </Flex>
      </FormField>
    );
  }
}

function isSameIgnoreCase(a: string, b: string) {
  return a.trim().toLowerCase() === b.trim().toLowerCase();
}

AlertRuleForm.INTERACTION_CANCEL_BUTTON_CLICKED =
  "AlertRuleForm.INTERACTION_CANCEL_BUTTON_CLICKED" as const;

AlertRuleForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE =
  "AlertRuleForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE" as const;

AlertRuleForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE =
  "AlertRuleForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE" as const;

type InteractionCancelButtonClicked = {
  type: typeof AlertRuleForm.INTERACTION_CANCEL_BUTTON_CLICKED;
};

type InteractionSubmitButtonClickedCreate = {
  type: typeof AlertRuleForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE;
  condition: AnomalyDetection | Threshold;
  direction: AlertRuleDirection;
  excludeTax: boolean;
  filters: Filter[];
  name: string;
  selectedLabels: string[];
  subscribers: AlertSubscribers;
  timeGranularity: TimeGranularity;
};

type InteractionSubmitButtonClickedUpdate = {
  type: typeof AlertRuleForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE;
  condition?: AnomalyDetection | Threshold;
  direction?: AlertRuleDirection;
  excludeTax?: boolean;
  filters?: Filter[];
  lookbackDays?: number;
  minimumDelta?: number;
  name?: string;
  selectedAccounts?: string[];
  selectedLabels?: string[];
  sensitivity?: number;
  subscribers?: AlertSubscribers;
  timeGranularity?: TimeGranularity;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AlertRuleForm {
  export type Interaction =
    | InteractionCancelButtonClicked
    | InteractionSubmitButtonClickedCreate
    | InteractionSubmitButtonClickedUpdate;
}

export default AlertRuleForm;
