import { DownloadOCIFormField } from "@/features/admin/components/DownloadOCIFormField";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import { FormField } from "@/ui-lib/components/Form";
import Select from "@/ui-lib/components/Select";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import copyText from "../../copyText";
import { OCID_REGEX, ociRegions } from "./constants";
import { FormProps, RequiredInputValidation } from "./types";

const validators = {
  name: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
  tenancyOCID: (props: RequiredInputValidation) => {
    if (props.value.length === 0) {
      return !props.isMspSharedIntegration && copyText.errorInputFieldRequired;
    }

    return !OCID_REGEX.test(props.value)
      ? copyText.oracleFormGeneralOCIDInputError
      : undefined;
  },
  userOCID: (props: RequiredInputValidation) => {
    if (props.value.length === 0) {
      return !props.isMspSharedIntegration && copyText.errorInputFieldRequired;
    }

    return !OCID_REGEX.test(props.value)
      ? copyText.oracleFormGeneralOCIDInputError
      : undefined;
  },
  region: (props: RequiredInputValidation) => {
    return props.value.length === 0 && !props.isMspSharedIntegration
      ? copyText.errorInputFieldRequired
      : undefined;
  },
};

const regions = ociRegions.map((region) => ({
  label: region,
  value: region,
}));

export function OracleIntegrationFormGeneral(
  props: FormProps & {
    showCertificate?: boolean;
  }
) {
  const theme = useTheme();
  const user = useAuthenticatedUser();

  const { form, showCertificate, isMspSharedIntegration } = props;

  return (
    <Box padding={theme.space_xxs}>
      <form.Field
        name="name"
        validators={{ onChange: validators.name, onMount: validators.name }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              input={TextInput}
              disabled={isMspSharedIntegration}
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              label={copyText.oracleFormGeneralNameLabel}
              required={isMspSharedIntegration ? undefined : true}
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
      <form.Field
        name="tenancyOCID"
        validators={{
          onChange: (value) =>
            validators.tenancyOCID({ ...value, isMspSharedIntegration }),
          onMount: (value) =>
            validators.tenancyOCID({ ...value, isMspSharedIntegration }),
        }}
        children={(field) => {
          let shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          if (isMspSharedIntegration && field.state.value.length === 0) {
            shouldShowError = false;
          }
          return (
            <FormField
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(",") : undefined
              }
              input={TextInput}
              label={copyText.oracleFormGeneralTenancyOCIDLabel}
              required={isMspSharedIntegration ? undefined : true}
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
              onBlur={field.handleBlur}
            />
          );
        }}
      />
      <form.Field
        name="userOCID"
        validators={{
          onChange: (value) =>
            validators.userOCID({ ...value, isMspSharedIntegration }),
          onMount: (value) =>
            validators.userOCID({ ...value, isMspSharedIntegration }),
        }}
        children={(field) => {
          let shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          if (isMspSharedIntegration && field.state.value.length === 0) {
            shouldShowError = false;
          }

          return (
            <FormField
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              input={TextInput}
              label={copyText.oracleFormGeneralUserOCIDLabel}
              required={isMspSharedIntegration ? undefined : true}
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
              onBlur={field.handleBlur}
            />
          );
        }}
      />

      <form.Field
        name="region"
        validators={{
          onChange: (value) =>
            validators.region({ ...value, isMspSharedIntegration }),
          onMount: (value) =>
            validators.region({ ...value, isMspSharedIntegration }),
        }}
        children={(field) => {
          return (
            <FormField
              label={copyText.oracleFormGeneralRegionLabel}
              required={isMspSharedIntegration ? undefined : true}
            >
              <Select
                options={regions}
                value={regions.find(
                  (option) => option.value === field.state.value
                )}
                onChange={(option) =>
                  option && field.handleChange(option.value)
                }
              />
            </FormField>
          );
        }}
      />

      {showCertificate && (
        <Box>
          <Text>{copyText.oracleFormGeneralOCICertificateLabel}</Text>
          <DownloadOCIFormField
            text={copyText.oracleFormGeneralOCICertificateAction}
            user={user}
          />
        </Box>
      )}
    </Box>
  );
}
