import timing from "@/constants/timing";
import getCn from "@/utils/getCn";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { formatCurrencyRounded } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import copyText from "../../copyText";
import { SpendData } from "../utils";

const COMPONENT_NAME = "CUDChart";
const cn = getCn(COMPONENT_NAME);

interface Props {
  isUnadjusted: boolean;
  modifiedSpendData: SpendData;
  referenceSpendData: SpendData;
}

const StyledDiv = styled.div`
  display: flex;
  z-index: 0;

  .${cn("right")} {
    flex-grow: 1;
  }

  .${cn("left")} {
    min-width: 40%;
  }
`;

const StyledSquare = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  padding: 0.3rem;
  margin: 0.15rem 0.5rem;
  min-height: 0.8rem;
  opacity: 0.6;
`;

const StyledTable = styled.table`
  color: ${(props) => props.theme.text_color};
  width: 90%;
  margin-left: 10%;
  font-size: 0.75rem;

  td:nth-of-type(1) {
    font-size: 0.5rem;
  }

  td:nth-of-type(4) {
    font-weight: bold;
  }

  th,
  td {
    text-align: right;
  }

  th:nth-of-type(3) {
    font-weight: unset;
  }

  tr:last-of-type {
    td:nth-of-type(3),
    td:nth-of-type(4) {
      border-top: 1px solid ${(props) => props.theme.text_color};
    }
  }
`;

const StyledSavings = styled.div<{
  negative: boolean;
  positive: boolean;
}>`
  font-size: 0.65rem;
  margin-bottom: 1rem;
  margin-top: -1rem;
  text-align: center;
  color: ${(props) => props.theme.text_color};
  font-weight: bold;

  span {
    display: flex;
    justify-content: center;
    font-size: 1.25rem;
    color: ${(props) =>
      props.negative
        ? props.theme.feedback_negative
        : props.positive
          ? props.theme.feedback_positive
          : props.theme.text_color};
  }
`;

export default function CUDPlannerChart(props: Props): JSX.Element {
  const theme = useTheme();

  const data = [
    { ...props.referenceSpendData, label: copyText.cudChartLabelActual },
    { ...props.modifiedSpendData, label: copyText.cudChartLabelAdjusted },
  ];

  const isNegative =
    props.referenceSpendData.cost - props.modifiedSpendData.cost <= -0.5;
  const isPositive =
    props.referenceSpendData.cost - props.modifiedSpendData.cost >= 0.5;

  return (
    <StyledDiv>
      <div className={cn("left")}>
        <ResponsiveContainer width="100%" height={170}>
          <BarChart
            data={data}
            margin={{ bottom: 10, left: 20, right: 0, top: 0 }}
          >
            <CartesianGrid
              stroke={theme.chart_cartesian_grid_lines}
              vertical={false}
            />
            ;
            <Bar
              animationDuration={timing.chartAnimationDuration}
              dataKey="cud3YearCost"
              fill={theme.cud_color_3yr}
              fillOpacity={theme.chart_fill_opacity}
              stackId="all"
            />
            <Bar
              animationDuration={timing.chartAnimationDuration}
              dataKey="cud1YearCost"
              fill={theme.cud_color_1yr}
              fillOpacity={theme.chart_fill_opacity}
              stackId="all"
            />
            <Bar
              animationDuration={timing.chartAnimationDuration}
              dataKey="remainingOnDemandSpend"
              fill={theme.cud_color_on_demand}
              fillOpacity={theme.chart_fill_opacity}
              stackId="all"
            />
            <XAxis
              dataKey="label"
              dy={10}
              stroke={theme.chart_axis_text}
              tick={{
                stroke: theme.text_color,
                fontSize: "0.75rem",
                fontWeight: 100,
              }}
            />
            <YAxis
              dx={-5}
              label={{
                value: copyText.cudChartAxisLabelTotalCost,
                position: "insideTopLeft",
                angle: -90,
                dy: 80,
                dx: -20,
                style: { fontWeight: "bold", textTransform: "lowercase" },
                fill: theme.text_color,
                fontSize: "0.8rem",
              }}
              stroke={theme.chart_axis_text}
              tick={{
                stroke: theme.text_color,
                fontSize: "0.75rem",
                fontWeight: 100,
              }}
              tickFormatter={(value) =>
                formatCurrencyRounded({ number: value })
              }
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className={cn("right")}>
        <StyledSavings negative={isNegative} positive={isPositive}>
          <span>
            {formatCurrencyRounded({
              number:
                Math.abs(
                  props.referenceSpendData.cost - props.modifiedSpendData.cost
                ) < 0.5
                  ? 0
                  : props.referenceSpendData.cost -
                    props.modifiedSpendData.cost,
            })}
            {isNegative && props.isUnadjusted && (
              <Tooltip
                content={copyText.cudSavingExplainNegative}
                icon={faInfoCircle}
                width={"18rem"}
              />
            )}
          </span>
          <div>{copyText.cudChartSummaryAdditionalSavings}</div>
        </StyledSavings>
        <StyledTable>
          <thead>
            <tr>
              <th />
              <th />
              <th>{copyText.cudChartLabelActual}</th>
              <th>{copyText.cudChartLabelAdjusted}</th>
            </tr>
          </thead>
          <tbody>
            {[
              {
                color: theme.cud_color_on_demand,
                modified: props.modifiedSpendData.remainingOnDemandSpend,
                reference: props.referenceSpendData.remainingOnDemandSpend,
                text: copyText.cudChartSummaryOnDemand,
              },
              {
                color: theme.cud_color_1yr,
                modified: props.modifiedSpendData.cud1YearCost,
                reference: props.referenceSpendData.cud1YearCost,
                text: copyText.cudChartSummary1yrCUDs,
              },
              {
                color: theme.cud_color_3yr,
                modified: props.modifiedSpendData.cud3YearCost,
                reference: props.referenceSpendData.cud3YearCost,
                text: copyText.cudChartSummary3YrCUDs,
              },
              {
                color: "transparent",
                modified:
                  props.modifiedSpendData.remainingOnDemandSpend +
                  props.modifiedSpendData.cud1YearCost +
                  props.modifiedSpendData.cud3YearCost,
                reference:
                  props.referenceSpendData.remainingOnDemandSpend +
                  props.referenceSpendData.cud1YearCost +
                  props.referenceSpendData.cud3YearCost,
                text: copyText.totals,
              },
            ].map((params) => getRow(params))}
          </tbody>
        </StyledTable>
      </div>
    </StyledDiv>
  );
}

function getRow(params: {
  color: string;
  modified: number;
  reference: number;
  text: string;
}) {
  return (
    <tr key={params.text}>
      <td>{params.text}</td>
      <td>
        <StyledSquare color={params.color} />
      </td>
      <td>
        {formatCurrencyRounded({
          number: params.reference,
        })}
      </td>
      <td>
        {formatCurrencyRounded({
          number: params.modified,
        })}
      </td>
    </tr>
  );
}
