import IconAlibabaCloud from "@/ui-lib/icons/IconAlibabaCloud";
import IconAwsCloud from "@/ui-lib/icons/IconAws";
import IconAzure from "@/ui-lib/icons/IconAzure";
import IconGCP from "@/ui-lib/icons/IconGCP";
import IconMongoDb from "@/ui-lib/icons/IconMongoDb";
import IconOracleCloud from "@/ui-lib/icons/IconOracleCloud";
import { Theme, useTheme } from "@emotion/react";
import { createColumnHelper } from "@tanstack/react-table";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import { DataIntegrationEntity } from "@ternary/api-lib/core/types";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useMemo } from "react";
import IconSnowflake from "../../../ui-lib/icons/IconSnowflake";
import copyText from "../copyText";
import DataStatusTooltip from "./DataStatusTooltip";

type TableData = {
  id: string;
  name: string;
  providerType: CloudProviderType;
  status: {
    latestRefresh: string | null;
    latestUpstreamTimestamp: string | null;
  };
};

const columnHelper = createColumnHelper<TableData>();

interface Props {
  data: DataIntegrationEntity[];
  isLoading: boolean;
  onInteraction: (interaction: DataIntegrationsTable.Interaction) => void;
}

export function DataIntegrationsTable(props: Props) {
  const theme = useTheme();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("name", {
        cell: ({ row, getValue }) =>
          renderIntegrationName(row.original.providerType, getValue(), theme),
        header: copyText.tableHeaderName,
        meta: { align: "left" },
        minSize: 800,
      }),
      columnHelper.accessor("status", {
        cell: ({ getValue }) => {
          return (
            <DataStatusTooltip
              latestRefresh={getValue().latestRefresh}
              latestUpstream={getValue().latestUpstreamTimestamp}
            />
          );
        },
        header: copyText.tableHeaderStatus,
        minSize: 200,
        meta: { align: "left" },
      }),
      columnHelper.display({
        id: "details",
        cell: ({ row }) => {
          return (
            <Button
              size="small"
              secondary
              onClick={() =>
                props.onInteraction({
                  type: DataIntegrationsTable.INTERACTION_DETAIL_BUTTON_CLICKED,
                  integrationID: row.original.id,
                })
              }
            >
              {copyText.integrationButtonLabelDetails}
            </Button>
          );
        },
        meta: { align: "right" },
      }),
    ];
  }, [props.data]);

  const data = useMemo(
    () =>
      props.data.map((integration) => {
        const status = integration.dataStatus.BILLING;
        return {
          id: integration.id,
          name: integration.name,
          providerType: integration.providerType,
          status: {
            latestRefresh: status.latestRefresh,
            latestUpstreamTimestamp: status.latestUpstreamTimestamp,
          },
        };
      }),
    [props.data]
  );

  return (
    <Table
      columns={columns}
      data={data}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

DataIntegrationsTable.INTERACTION_DETAIL_BUTTON_CLICKED =
  `DataIntegrationsTable.INTERACTION_DETAIL_BUTTON_CLICKED` as const;

interface InteractionDetailButtonClicked {
  type: typeof DataIntegrationsTable.INTERACTION_DETAIL_BUTTON_CLICKED;
  integrationID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DataIntegrationsTable {
  export type Interaction = InteractionDetailButtonClicked;
}

export default DataIntegrationsTable;

function renderIntegrationName(
  providerType: CloudProviderType,
  name: string,
  theme: Theme
) {
  let iconComponent: JSX.Element = <IconGCP size={24} />;
  switch (providerType) {
    case CloudProviderType.ALIBABA:
      iconComponent = <IconAlibabaCloud size={24} />;
      break;
    case CloudProviderType.AWS:
      iconComponent = <IconAwsCloud size={24} />;
      break;
    case CloudProviderType.AZURE:
      iconComponent = <IconAzure size={24} />;
      break;
    case CloudProviderType.MONGO_DB:
      iconComponent = <IconMongoDb size={24} />;
      break;
    case CloudProviderType.OCI:
      iconComponent = <IconOracleCloud size={24} />;
      break;
    case CloudProviderType.SNOWFLAKE:
      iconComponent = <IconSnowflake size={24} />;
      break;
    default:
      break;
  }
  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex alignItems="center">
        <Flex
          alignItems="center"
          backgroundColor="white"
          border={`1px solid ${theme.border_color}`}
          borderRadius="8px"
          height={40}
          marginRight={theme.space_sm}
          justifyContent="center"
          width={40}
        >
          {iconComponent}
        </Flex>
        <Text bold>{name}</Text>
      </Flex>
    </Flex>
  );
}
