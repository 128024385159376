import { useTheme } from "@emotion/react";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Row, createColumnHelper } from "@tanstack/react-table";
import { DataSource, TimeGranularity } from "@ternary/api-lib/analytics/enums";
import { Filter } from "@ternary/api-lib/analytics/ui/types";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import { formatCurrency } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import {
  CostAlertEventType,
  ResourceType,
} from "@ternary/api-lib/constants/enums";
import { AlertRuleEntity, LabelMapsEntity } from "@ternary/api-lib/core/types";
import { CostAlertStatus } from "@ternary/api-lib/core/types/CostAlert";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import Text from "@ternary/web-ui-lib/components/Text";
import { Dictionary } from "lodash";
import React, { useMemo } from "react";
import {
  AnomalyDetection,
  Forecasting,
  Threshold,
} from "../../../api/core/types";
import GroupingTag from "../../../components/GroupingTag";
import GroupingTooltipContent from "../../../components/GroupingTooltip";
import copyText from "../copyText";
import {
  LEGACY_BIG_QUERY_RULE_ID,
  LEGACY_BILLING_RULE_ID,
} from "../defaultAlertRules";
import { CostAlertDimension, CostAlertFilters } from "../types";
import {
  getDelta,
  getSourceAlertRuleName,
  getStringifiedDelta,
} from "../utils";
import CostAlertSparkLineChart from "./CostAlertSparkLineChart";
import CostAlertStatusButton from "./CostAlertStatusButton";

export type CostAlertRule = {
  id: string;
  condition: AnomalyDetection | Forecasting | Threshold;
  dataSource: DataSource;
  dimensions: string[];
  name: string;
  timeGranularity: TimeGranularity;
};

type CostAlert = {
  id: string;
  alertRule?: CostAlertRule;
  alertRuleID: string;
  createdAt: string;
  dimensions: CostAlertDimension[];
  eventValue: number;
  expectedValue: {
    upperBound: number;
    lowerBound: number;
  } | null;
  eventTime: string;
  eventType: CostAlertEventType;
  status: CostAlertStatus;
};

type TableData = {
  id: string;
  actualValue: number;
  alertedAt: string;
  alertRuleGranularity?: TimeGranularity;
  alertRuleID: string;
  alertRuleName?: string;
  delta: number;
  dimensionGrouping: CostAlertDimension[];
  eventType: CostAlertEventType;
  expectedRange: number[];
  occurredAt: string;
  ruleFilters?: Filter[];
  sourceAlertRuleName: string;
  status: CostAlertStatus;
};

type Interaction =
  | CostAlertStatusButton.Interaction
  | CostAlertTable.Interaction;

interface Props {
  alertRulesKeyedByID?: Dictionary<AlertRuleEntity>;
  alertRule?: AlertRuleEntity;
  alertRuleGranularity?: TimeGranularity;
  costAlerts: CostAlert[];
  isLoading: boolean;
  labelMaps?: LabelMapsEntity;
  showSourceRule?: boolean;
  onInteraction: (interaction: Interaction) => void;
}

const columnHelper = createColumnHelper<TableData>();

export function CostAlertTable(props: Props): JSX.Element {
  const theme = useTheme();

  const columns = useMemo(
    () => [
      ...(props.showSourceRule
        ? [
            columnHelper.display({
              id: "sourceRule",
              cell: ({ row }) => (
                <Text truncate>{row.original.alertRuleName}</Text>
              ),
              header: copyText.costAlertTableHeader_alertRuleName,
              meta: { align: "center" },
              size: 130,
            }),
          ]
        : []),
      columnHelper.accessor("occurredAt", {
        cell: ({ getValue }) => (
          <>{formatDate(new Date(getValue()), "yyyy-MM-dd")}</>
        ),
        header: copyText.costAlertTableHeader_occurredAt,
        meta: { align: "center" },
        size: 100,
      }),
      columnHelper.display({
        id: "status",
        cell: ({ row }) => (
          <CostAlertStatusButton
            costAlertID={row.original.id}
            status={row.original.status}
            onInteraction={props.onInteraction}
          />
        ),
        header: copyText.costAlertTableHeader_status,
        meta: { align: "center" },
        size: 130,
      }),
      columnHelper.accessor("dimensionGrouping", {
        cell: ({ row }) => {
          const dimensionGrouping = row.original.dimensionGrouping;
          if (dimensionGrouping.length > 0) {
            return (
              <Box minWidth={0}>
                <Tooltip
                  content={
                    <GroupingTooltipContent
                      dimensionGrouping={dimensionGrouping}
                    />
                  }
                  delayHide={250}
                  placement="top"
                >
                  <GroupingTag dimensionGrouping={dimensionGrouping} />
                </Tooltip>
              </Box>
            );
          }
          return "--";
        },
        header: copyText.costAlertTableHeader_dimensionGrouping,
        meta: { align: "center" },
        size: 130,
        sortingFn: customGroupingSort,
      }),
      columnHelper.accessor("actualValue", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.costAlertTableHeader_actualValue,
        meta: { align: "right" },
        size: 130,
      }),
      columnHelper.accessor("delta", {
        cell: ({ row }) => (
          <Text color={theme.feedback_negative}>
            {getStringifiedDelta(row.original.actualValue, {
              lowerBound: row.original.expectedRange[0],
              upperBound: row.original.expectedRange[1],
            })}
          </Text>
        ),
        header: copyText.costAlertTableHeader_delta,
        meta: { align: "right" },
        size: 130,
      }),
      columnHelper.display({
        id: "chart",
        cell: ({ row }) => (
          <CostAlertSparkLineChart
            dimensionGrouping={row.original.dimensionGrouping}
            granularity={row.original.alertRuleGranularity}
            occurredAt={row.original.occurredAt}
            ruleFilters={row.original.ruleFilters}
          />
        ),
        meta: { align: "center" },
        size: 75,
      }),
      columnHelper.display({
        id: "view",
        cell: ({ row }) => (
          <Button
            iconStart={<Icon icon={faEye} />}
            marginRight={30}
            primary
            size="tiny"
            onClick={() => {
              props.onInteraction({
                type: CostAlertTable.INTERACTION_VIEW_ALERT_CLICKED,
                costAlertID: row.original.id,
              });
            }}
          >
            {copyText.costAlertTableView}
          </Button>
        ),
        meta: { align: "right" },
        size: 100,
      }),
    ],
    [props.costAlerts]
  );

  const data = useMemo(() => {
    if (props.isLoading) return [];

    const labelMap = props.labelMaps?.BILLING ?? {};

    const reversedLabelMap = Object.entries(labelMap).reduce(
      (accum: { [key: string]: string }, [key, value]) => ({
        ...accum,
        [String(value)]: key,
      }),
      {}
    );

    return props.costAlerts.map((event) => {
      let dimensions = event.dimensions;

      dimensions = dimensions.map((dimension) => {
        return {
          key: reversedLabelMap[dimension.key] ?? dimension.key,
          value: dimension.value,
        };
      });

      let alertRuleName = event.alertRule?.name;

      if (
        !alertRuleName ||
        event.alertRuleID === LEGACY_BIG_QUERY_RULE_ID ||
        event.alertRuleID === LEGACY_BILLING_RULE_ID
      ) {
        alertRuleName = copyText.alertRuleNameSystemAlert;
      }

      const ruleFilters =
        props.alertRule?.filters ??
        (props.alertRulesKeyedByID?.[event.alertRuleID]?.filters || []);

      return {
        id: event.id,
        alertedAt: event.createdAt,
        alertRuleID: event.alertRuleID,
        alertRuleName,
        alertRuleGranularity:
          props.alertRuleGranularity ?? event.alertRule?.timeGranularity,
        actualValue: event.eventValue,
        dimensionGrouping: dimensions,
        delta: getDelta(
          event.eventValue,
          event.expectedValue
            ? event.expectedValue
            : { lowerBound: 0, upperBound: 0 }
        ),
        eventType: event.eventType,
        expectedRange: [
          event.expectedValue ? event.expectedValue.lowerBound : 0,
          event.expectedValue ? event.expectedValue.upperBound : 0,
        ],
        occurredAt: event.eventTime,
        resourceID: event.id,
        resourceName: event.eventType,
        ruleFilters,
        sourceAlertRuleName: getSourceAlertRuleName(event),
        status: event.status,
      };
    });
  }, [props.costAlerts, props.isLoading === false]);

  return (
    <Table
      columns={columns}
      data={data}
      initialState={{ sorting: [{ id: "occurredAt", desc: true }] }}
      isLoading={props.isLoading}
      resourceType={ResourceType.COST_ALERT}
      showPagination
      sortable
    />
  );

  function customGroupingSort(
    rowA: Row<TableData>,
    rowB: Row<TableData>,
    id: string
  ) {
    const a = rowA.original[id];
    const b = rowB.original[id];
    if (a.length > 0 && b.length === 0) {
      return -1;
    }
    if (b.length > 0 && a.length === 0) {
      return 1;
    }

    if (a.length > 0 && b.length > 0) {
      const dimensionGroupingJoinA = `${a[0].key}=${a[0].value}`;
      const dimensionGroupingJoinB = `${b[0].key}=${b[0].value}`;
      if (
        typeof dimensionGroupingJoinA !== "string" &&
        typeof dimensionGroupingJoinB !== "string"
      )
        return 0;

      if (
        dimensionGroupingJoinA.toLowerCase() ===
        dimensionGroupingJoinB.toLowerCase()
      )
        return 0;
      return dimensionGroupingJoinA.toLowerCase() <
        dimensionGroupingJoinB.toLowerCase()
        ? 1
        : -1;
    }
    return 0;
  }
}

CostAlertTable.INTERACTION_CREATE_CASE_CLICKED =
  `CostAlertTable.INTERACTION_CREATE_CASE_CLICKED` as const;

CostAlertTable.INTERACTION_FILTER_CLICKED =
  `CostAlertTable.INTERACTION_FILTER_CLICKED` as const;

CostAlertTable.INTERACTION_VIEW_ALERT_CLICKED =
  `CostAlertTable.INTERACTION_VIEW_ALERT_CLICKED` as const;

CostAlertTable.INTERACTION_VIEW_EVENT_FEED_CLICKED =
  `CostAlertTable.INTERACTION_VIEW_EVENT_FEED_CLICKED` as const;

CostAlertTable.INTERACTION_VIEW_SOURCE_ALERT_RULE_CLICKED =
  `CostAlertTable.INTERACTION_VIEW_SOURCE_ALERT_RULE_CLICKED` as const;

interface InteractionCreateCaseClicked {
  type: typeof CostAlertTable.INTERACTION_CREATE_CASE_CLICKED;
  costAlertID: string;
  costAlertEventType: CostAlertEventType;
}

interface InteractionFilterClicked {
  type: typeof CostAlertTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof CostAlertFilters;
  filterValue: string;
}

interface InteractionViewAlertClicked {
  type: typeof CostAlertTable.INTERACTION_VIEW_ALERT_CLICKED;
  costAlertID: string;
}

interface InteractionViewEventFeedClicked {
  type: typeof CostAlertTable.INTERACTION_VIEW_EVENT_FEED_CLICKED;
  alertRuleID: string;
}

interface InteractionViewSourceAlertRuleClicked {
  type: typeof CostAlertTable.INTERACTION_VIEW_SOURCE_ALERT_RULE_CLICKED;
  alertRuleID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CostAlertTable {
  export type Interaction =
    | InteractionCreateCaseClicked
    | InteractionFilterClicked
    | InteractionViewAlertClicked
    | InteractionViewEventFeedClicked
    | InteractionViewSourceAlertRuleClicked;
}

export default CostAlertTable;
