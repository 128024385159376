import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import { InputDropdown } from "@/ui-lib/components/SelectDropdownFilter";
import { getFilterValuesButtonLabel } from "@/utils/filters";
import getMergeState from "@/utils/getMergeState";
import { groupOptionsByPreferences } from "@/utils/groupOptionsByPreferences";
import { useTheme } from "@emotion/react";
import {
  faChevronDown,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { DataSource } from "@ternary/api-lib/analytics/enums";
import { CustomLabelOperator } from "@ternary/api-lib/constants/enums";
import { DimensionPreferenceEntity } from "@ternary/api-lib/core/types";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Divider from "@ternary/api-lib/ui-lib/components/Divider";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { isEqual } from "lodash";
import React, { ChangeEvent, useEffect, useState } from "react";
import isEmpty from "validator/lib/isEmpty";
import externalLinks from "../../../constants/externalLinks";
import { Input } from "../../../types";
import Form from "../../../ui-lib/components/Form";
import Modal from "../../../ui-lib/components/Modal";
import Select from "../../../ui-lib/components/Select";
import SelectDropdown, {
  Option,
} from "../../../ui-lib/components/SelectDropdown";
import copyText from "../copyText";

const INPUT_MATCH_KEY = "matchKey";
const INPUT_MATCH_VALUES = "matchValues";
const INPUT_OUTPUT_KEY = "outputKey";
const INPUT_OUTPUT_VALUE = "outputValue";

type CustomLabel = {
  id: string;
  matchers: {
    key: string;
    operator: CustomLabelOperator;
    values: string[];
  }[];
  outputs: {
    key: string;
    value: string;
  }[];
};

export type SaveParams = {
  matched: {
    key: string;
    operator: CustomLabelOperator;
    values: string[];
  }[];
  output: {
    key: string;
    value: string;
  }[];
};

interface Props {
  availableDimensions: string[];
  customLabel?: CustomLabel;
  dimensionsPreferences: DimensionPreferenceEntity[];
  existingKeys: string[];
  existingValues: string[];
  isLoading: boolean;
  isOpen: boolean;
  isProcessing: boolean;
  onInteraction: (interaction: CustomLabelManagerModal.Interaction) => void;
  selectedDimensionValues: string[];
  title: string;
}

interface State {
  matchKeyInput: Input<string>;
  matchValuesInput: Input<string[]>;
  outputPairs: {
    outputKeyInput: Input<string>;
    outputValueInput: Input<string>;
  }[];
}

export function CustomLabelManagerModal(props: Props): JSX.Element | null {
  const theme = useTheme();

  //
  // State
  //

  const initialState = props.customLabel
    ? {
        matchKeyInput: {
          value: props.customLabel.matchers[0].key,
          hasChanged: false,
          isValid: true,
        },
        matchValuesInput: {
          value: props.customLabel.matchers[0].values,
          hasChanged: false,
          isValid: true,
        },
        outputPairs: props.customLabel.outputs.map((output) => ({
          outputKeyInput: {
            value: output.key,
            hasChanged: false,
            isValid: true,
          },
          outputValueInput: {
            value: output.value,
            hasChanged: false,
            isValid: true,
          },
        })),
      }
    : {
        matchKeyInput: {
          value: "",
          hasChanged: false,
          isValid: false,
        },
        matchValuesInput: {
          value: [],
          hasChanged: false,
          isValid: false,
        },
        outputPairs: [
          {
            outputKeyInput: {
              value: "",
              hasChanged: false,
              isValid: false,
            },
            outputValueInput: {
              value: "",
              hasChanged: false,
              isValid: false,
            },
          },
        ],
      };

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  useEffect(() => {
    if (!props.customLabel) return;

    props.onInteraction({
      type: CustomLabelManagerModal.INTERACTION_MATCH_KEY_CHANGED,
      matchKey: props.customLabel.matchers[0].key,
    });
  }, [props.customLabel === undefined]);

  //
  // Interaction Handlers
  //

  function handleChange(event: ChangeEvent<HTMLInputElement>, index?: number) {
    const name = event.target.name;
    let value = event.target.value;

    let derivedState = {};
    let isValid = false;
    let hasChanged = false;

    switch (name) {
      case INPUT_MATCH_KEY: {
        props.onInteraction({
          type: CustomLabelManagerModal.INTERACTION_MATCH_KEY_CHANGED,
          matchKey: value,
        });

        derivedState = {
          matchValuesInput: { value: [], hasChangd: true, isValid: false },
        };

        isValid = value.length > 0;
        hasChanged = initialState[`${name}Input`].value !== value;
        break;
      }
      case INPUT_MATCH_VALUES: {
        value = JSON.parse(value);

        isValid = value.length > 0;

        hasChanged = !isEqual(initialState.matchValuesInput.value, value);
        break;
      }
      case INPUT_OUTPUT_KEY: {
        if (index === undefined) return;

        isValid = true;

        hasChanged = initialState.outputPairs[index]
          ? initialState.outputPairs[index].outputKeyInput.value !== value
          : true;

        setState((currentState) => ({
          ...currentState,
          outputPairs: currentState.outputPairs.map((pair, i) => {
            const isValueValid = pair.outputValueInput.value.trim().length > 0;
            if (index === i) {
              return {
                ...pair,
                outputKeyInput: { value, isValid, hasChanged },
                outputValueInput: {
                  ...pair.outputValueInput,
                  isValid: isValueValid,
                },
              };
            }

            return pair;
          }),
        }));
        return;
      }
      case INPUT_OUTPUT_VALUE: {
        if (index === undefined) return;

        isValid = value.length > 0 && !isEmpty(value.trim());

        hasChanged = initialState.outputPairs[index]
          ? initialState.outputPairs[index].outputValueInput.value !== value
          : true;

        setState((currentState) => ({
          ...currentState,
          outputPairs: currentState.outputPairs.map((pair, i) => {
            if (index === i) {
              return {
                ...pair,
                outputValueInput: { value, isValid, hasChanged },
              };
            }

            return pair;
          }),
        }));
        return;
      }
    }

    mergeState({
      ...derivedState,
      [`${name}Input`]: { value, hasChanged, isValid },
    });
  }

  function handleSubmit() {
    props.onInteraction({
      type: CustomLabelManagerModal.INTERACTION_SUBMIT_BUTTON_CLICKED,
      matcher: {
        key: state.matchKeyInput.value ?? "",
        operator: props.customLabel
          ? props.customLabel.matchers[0].operator
          : CustomLabelOperator.EQUALS,
        values: state.matchValuesInput.value,
      },
      outputs: state.outputPairs.map((pair) => ({
        key: pair.outputKeyInput.value,
        value: pair.outputValueInput.value,
      })),
    });
  }

  function handleClickAddOutputPair() {
    setState((currentState) => ({
      ...currentState,
      outputPairs: [
        ...currentState.outputPairs,
        {
          outputKeyInput: {
            value: "",
            hasChanged: false,
            isValid: true,
          },
          outputValueInput: {
            value: "",
            hasChanged: false,
            isValid: true,
          },
        },
      ],
    }));
  }

  function handleClickRemoveOutputPair(index: number) {
    setState((currentState) => ({
      ...currentState,
      outputPairs: [
        ...currentState.outputPairs.slice(0, index),
        ...currentState.outputPairs.slice(index + 1),
      ],
    }));
  }

  const isValid =
    Object.entries(state).every(([key, value]): boolean =>
      key.endsWith("Input") ? value.isValid : true
    ) &&
    state.outputPairs.every(
      (pair) => pair.outputKeyInput.isValid && pair.outputValueInput.isValid
    );

  const hasChanged =
    Object.entries(state).some(([key, value]): boolean =>
      key.endsWith("Input") ? value.hasChanged : false
    ) ||
    state.outputPairs.some(
      (pair) =>
        pair.outputKeyInput.hasChanged || pair.outputValueInput.hasChanged
    );

  const canSubmit = isValid && hasChanged;

  const formattedDimensions = props.availableDimensions.map((dimension) => ({
    label: dimension,
    value: dimension,
  }));

  const customLabelKeyOptions = groupOptionsByPreferences(
    formattedDimensions,
    props.dimensionsPreferences,
    DataSource.BILLING
  );

  const customLabelValueOptions = props.selectedDimensionValues.map(
    (value) => ({
      label: value,
      value: value,
    })
  );

  //
  // Render
  //

  const keyOptions = props.existingKeys.reduce(
    (accum: Option[], existingKey) => {
      const keys = state.outputPairs.map((pair) => pair.outputKeyInput.value);
      if (!keys.find((key) => key === existingKey)) {
        return [
          ...accum,
          {
            label: existingKey,
            value: existingKey,
          },
        ];
      } else return accum;
    },
    []
  );

  const valueOptions = props.existingValues.map((key) => ({
    label: key,
    value: key,
  }));

  //
  // JSX
  //

  return (
    <Modal
      closeOnClickOutside={false}
      isOpen={props.isOpen}
      showCloseButton
      onClose={() =>
        props.onInteraction({
          type: CustomLabelManagerModal.INTERACTION_CLOSE_BUTTON_CLICKED,
        })
      }
    >
      <Modal.Header>
        <Text>{props.title}</Text>
      </Modal.Header>
      <Modal.Body>
        <Box maxWidth={1300} minWidth={900}>
          <Form>
            <Flex justifyContent="space-around">
              <Box width="25%">
                <Text
                  fontSize={theme.h4_fontSize}
                  marginBottom={theme.space_sm}
                >
                  {copyText.customLabelsListMatchedKey}
                </Text>
                <Text
                  color={theme.text_color_secondary}
                  marginBottom={theme.space_lg}
                >
                  {copyText.customLabelsModalInstructionMatchKey}
                </Text>
                <Flex alignItems="center">
                  <Select
                    isSearchable
                    options={customLabelKeyOptions}
                    placeholder={
                      copyText.customLabelsModalManagerPlaceholderKey
                    }
                    value={{
                      label: state.matchKeyInput.value,
                      value: state.matchKeyInput.value,
                    }}
                    onChange={(option) =>
                      handleChange({
                        target: {
                          name: INPUT_MATCH_KEY,
                          value: option?.value ?? "",
                        },
                      } as ChangeEvent<HTMLInputElement>)
                    }
                  />
                  <Text
                    bold
                    marginHorizontal={theme.space_xs}
                    whiteSpace="nowrap"
                  >
                    {copyText.labelApplicatorLabelOneOf}
                  </Text>
                </Flex>
              </Box>
              <Box width="25%">
                <Text
                  fontSize={theme.h4_fontSize}
                  marginBottom={theme.space_sm}
                >
                  {copyText.customLabelsListMatchedValues}
                </Text>
                <Text
                  color={theme.text_color_secondary}
                  marginBottom={theme.space_lg}
                >
                  {copyText.customLabelsModalInstructionMatchValues}
                </Text>
                <SelectDropdown
                  closeOnSubmit
                  isLoading={props.isLoading}
                  isMulti
                  options={customLabelValueOptions}
                  placement="bottom-end"
                  selectedValues={state.matchValuesInput.value}
                  sortSelected
                  submitButtonText={copyText.applyLabelValuesButton}
                  onChange={(value: string | string[]) => {
                    handleChange({
                      target: {
                        name: INPUT_MATCH_VALUES,
                        value: JSON.stringify(value),
                      },
                    } as ChangeEvent<HTMLInputElement>);
                  }}
                >
                  <InputDropdown>
                    <Text
                      truncate
                      style={{
                        textWrap: "nowrap",
                      }}
                    >
                      {state.matchValuesInput.value.length === 0
                        ? copyText.selectValueLabel
                        : getFilterValuesButtonLabel(
                            state.matchValuesInput.value
                          )}
                    </Text>
                    <Icon
                      clickable
                      color={theme.text_color_placeholder}
                      icon={faChevronDown}
                    />
                  </InputDropdown>
                </SelectDropdown>
              </Box>
              <Divider direction="vertical" minHeight={400} />
              <Box width="40%">
                <Text
                  fontSize={theme.h4_fontSize}
                  marginBottom={theme.space_sm}
                >
                  {copyText.customLabelsListHeaderOutput}
                </Text>
                <Text
                  color={theme.text_color_secondary}
                  marginBottom={theme.space_lg}
                >
                  {copyText.customLabelsModalInstructionOutput}
                </Text>
                <Box height={300} scrollable>
                  {state.outputPairs.map((pair, i) => {
                    const outputKeyInput = pair.outputKeyInput;
                    const outputValueInput = pair.outputValueInput;

                    return (
                      <Flex
                        key={i}
                        alignItems="center"
                        paddingTop={theme.space_xxs}
                        paddingLeft={theme.space_xxs}
                        marginBottom={theme.space_sm}
                      >
                        <Select
                          formatCreateLabel={(value) => {
                            return copyText.keyOptionCreateDialogue.replace(
                              "%input%",
                              value
                            );
                          }}
                          isCreatable
                          isValidNewOption={(value) => {
                            const keys = state.outputPairs.map(
                              (pair) => pair.outputKeyInput.value
                            );

                            // Output keys must be unique
                            return (
                              !keys.find((key) => key === value) &&
                              value.trim().length > 0
                            );
                          }}
                          menuPlacement="bottom"
                          menuPosition="fixed"
                          options={keyOptions}
                          searchable
                          value={[
                            {
                              label: outputKeyInput.value,
                              value: outputKeyInput.value,
                            },
                          ]}
                          onChange={(option) =>
                            handleChange(
                              {
                                target: {
                                  name: INPUT_OUTPUT_KEY,
                                  value: option?.value ?? "",
                                },
                              } as ChangeEvent<HTMLInputElement>,
                              i
                            )
                          }
                        />
                        <Text bold marginHorizontal={theme.space_xs}>
                          :
                        </Text>
                        <Select
                          formatCreateLabel={(value) => {
                            return copyText.valueOptionCreateDialogue.replace(
                              "%input%",
                              value
                            );
                          }}
                          isCreatable
                          isValidNewOption={(value) => {
                            return value.trim().length > 0;
                          }}
                          menuPlacement="bottom"
                          menuPosition="fixed"
                          options={valueOptions}
                          searchable
                          value={[
                            {
                              label: outputValueInput.value,
                              value: outputValueInput.value,
                            },
                          ]}
                          onChange={(option) =>
                            handleChange(
                              {
                                target: {
                                  name: INPUT_OUTPUT_VALUE,
                                  value: option?.value ?? "",
                                },
                              } as ChangeEvent<HTMLInputElement>,
                              i
                            )
                          }
                        />
                        {i > 0 ? (
                          <Button
                            iconStart={<Icon icon={faTimes} />}
                            marginLeft={theme.space_xs}
                            size="small"
                            type="button"
                            onClick={() => handleClickRemoveOutputPair(i)}
                          />
                        ) : (
                          <Box marginLeft={40} />
                        )}
                      </Flex>
                    );
                  })}
                  <Flex
                    marginBottom={theme.space_xs}
                    paddingTop={theme.space_xxs}
                    paddingLeft={theme.space_xxs}
                  >
                    <Button
                      iconStart={<Icon icon={faPlus} />}
                      secondary
                      size="tiny"
                      type="button"
                      onClick={handleClickAddOutputPair}
                    >
                      {copyText.addPairButtonLabel}
                    </Button>
                  </Flex>
                </Box>
              </Box>
            </Flex>
          </Form>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <a
            href={externalLinks.readmeCustomLabelsDocumentation}
            rel="noreferrer"
            target="_blank"
          >
            {copyText.customLabelsDocumentation}
          </a>
          <Flex>
            <Button
              disabled={props.isProcessing}
              secondary
              width={75}
              onClick={() =>
                props.onInteraction({
                  type: CustomLabelManagerModal.INTERACTION_CLOSE_BUTTON_CLICKED,
                })
              }
            >
              {copyText.actionClose}
            </Button>
            <Button
              disabled={props.isProcessing || !canSubmit}
              marginLeft={theme.space_md}
              primary
              width={75}
              onClick={handleSubmit}
            >
              {props.isProcessing ? <LoadingSpinner /> : copyText.actionSubmit}
            </Button>
          </Flex>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}

CustomLabelManagerModal.INTERACTION_CLOSE_BUTTON_CLICKED =
  "CustomLabelsManagerModal.INTERACTION_CLOSE_BUTTON_CLICKED" as const;
CustomLabelManagerModal.INTERACTION_SUBMIT_BUTTON_CLICKED =
  "CustomLabelsManagerModal.INTERACTION_SUBMIT_BUTTON_CLICKED" as const;
CustomLabelManagerModal.INTERACTION_MATCH_KEY_CHANGED =
  "CustomLabelsManagerModal.INTERACTION_MATCH_KEY_CHANGED" as const;

type InteractionCloseButtonClicked = {
  type: typeof CustomLabelManagerModal.INTERACTION_CLOSE_BUTTON_CLICKED;
};

type InteractionSubmitButtonClicked = {
  type: typeof CustomLabelManagerModal.INTERACTION_SUBMIT_BUTTON_CLICKED;
  matcher: {
    key: string;
    operator: CustomLabelOperator;
    values: string[];
  };
  outputs: {
    key: string;
    value: string;
  }[];
};

type InteractionMatchKeyChanged = {
  type: typeof CustomLabelManagerModal.INTERACTION_MATCH_KEY_CHANGED;
  matchKey: string;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CustomLabelManagerModal {
  export type Interaction =
    | InteractionCloseButtonClicked
    | InteractionSubmitButtonClicked
    | InteractionMatchKeyChanged;
}

export default CustomLabelManagerModal;
