import { SortingFn } from "@tanstack/react-table";

interface SortingUtilsOptions<T> {
  validKeys?: Array<keyof T>;
}

/**
 * Creates type-safe sorting functions for a specific table data type
 */
export function createSortingUtils<T extends Record<string, unknown>>(
  options?: SortingUtilsOptions<T>
) {
  function isTableDataKey(key: string) {
    if (!options?.validKeys) return true;
    return options.validKeys.includes(key as keyof T);
  }

  /**
   * Number sorting function for table columns
   */
  const numberSort: SortingFn<T> = (rowA, rowB, columnId) => {
    if (!isTableDataKey(columnId)) return 0;

    const a = rowA.original[columnId];
    const b = rowB.original[columnId];

    if (typeof a !== "number" && typeof b !== "number") return 0;
    if (typeof a !== "number") return 1;
    if (typeof b !== "number") return -1;

    return a === b ? 0 : a < b ? -1 : 1;
  };

  /**
   * String sorting function for table columns
   */
  const stringSort: SortingFn<T> = (rowA, rowB, columnId) => {
    if (!isTableDataKey(columnId)) return 0;

    const a = rowA.original[columnId];
    const b = rowB.original[columnId];

    if (typeof a !== "string" && typeof b !== "string") return 0;
    if (typeof a !== "string" || a === "") return 1;
    if (typeof b !== "string" || b === "") return -1;

    if (a.toLowerCase() === b.toLowerCase()) return 0;
    return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
  };

  return {
    numberSort,
    stringSort,
  };
}
