import { keyframes, useTheme } from "@emotion/react";
import {
  faArrowDown,
  faArrowUp,
  faCaretDown,
  faCaretUp,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { partition } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import Box from "../../ui-lib/components/Box";
import EmptyPlaceholder from "../../ui-lib/components/EmptyPlaceholder";
import Flex from "../../ui-lib/components/Flex";
import Icon from "../../ui-lib/components/Icon";
import Text from "../../ui-lib/components/Text";
import { COMPARISON_KEY } from "../constants";
import { DurationType } from "../enums";
import { RawData } from "../types";
import { formatMeasureValueWithUnit } from "../utils/ChartFormatUtils";
import { formatPercentage } from "../utils/NumberFormatUtils";
import { getTimeDurationCaption } from "../utils/ReportUtils";
import { Measure } from "./types";

const customFontSize = "2.5rem";

export const ComparisonFormat = {
  PERCENT: "PERCENT",
  UNIT: "UNIT",
} as const;

export type ComparisonFormat =
  (typeof ComparisonFormat)[keyof typeof ComparisonFormat];

type Props = {
  compact?: boolean;
  data: RawData[];
  durationType: DurationType;
  isFiscalMode: boolean;
  isLoading: boolean;
  isServerChart: boolean;
  measures: Measure[];
  unitDisplay?: ComparisonFormat;
};

export default function KPIChart(props: Props) {
  const theme = useTheme();

  const [formattedValue, value, formattedComparisonValue, comparisonValue] =
    useMemo(() => {
      const datum = props.data.at(0) ?? null;

      if (!datum) {
        return [null, null, null, null] as const;
      }

      const [measures, comparisonMeasures] = partition(
        props.measures,
        (measure) => !measure.name.includes(COMPARISON_KEY)
      );

      const measure = measures.at(0) ?? null;
      const value = measure ? datum[measure.name] : null;
      let formattedValue: string | null = null;

      if (typeof value === "number" && measure !== null) {
        formattedValue = formatMeasureValueWithUnit({
          value,
          unit: measure.unit,
        });
      }

      const comparisonMeasure = comparisonMeasures.at(0) ?? null;
      const comparisonValue = comparisonMeasure
        ? datum[comparisonMeasure.name]
        : null;

      let formattedComparisonValue: string | null = null;

      if (typeof comparisonValue === "number" && comparisonMeasure !== null) {
        formattedComparisonValue = formatMeasureValueWithUnit({
          value: comparisonValue,
          unit: comparisonMeasure.unit,
        });
      }

      return [
        formattedValue,
        typeof value === "number" ? value : null,
        formattedComparisonValue,
        typeof comparisonValue === "number" ? comparisonValue : null,
      ] as const;
    }, [props.data, props.measures]);

  if (props.isLoading) {
    return (
      <Box maxHeight={300} maxWidth={600}>
        <EmptyPlaceholder
          height="100%"
          icon={faList}
          loading={props.isLoading}
          skeletonVariant="meter"
          small
          width="100%"
        />
      </Box>
    );
  }

  return (
    <Flex
      alignItems="center"
      backgroundColor={props.compact ? undefined : theme.panel_backgroundColor}
      direction="column"
      height="100%"
      justifyContent="center"
      width="100%"
    >
      {props.compact ? (
        <CompactKPI
          comparisonValue={comparisonValue}
          formattedComparisonValue={formattedComparisonValue}
          formattedValue={formattedValue}
          isServerChart={props.isServerChart}
          measures={props.measures}
          unitDisplay={props.unitDisplay ?? ComparisonFormat.PERCENT}
          value={value}
        />
      ) : (
        <>
          <Flex
            direction="column"
            alignItems="center"
            height="100%"
            justifyContent="center"
          >
            <Flex alignItems="center">
              <Text as="div" bold fontSize={theme.fontSize_base}>
                {camelCaseToReadableString(props.measures[0].name)}
              </Text>
              <Text
                as="div"
                marginLeft={theme.space_sm}
                color={theme.text_color_secondary}
              >
                {getTimeDurationCaption(props.durationType, props.isLoading)}
              </Text>
            </Flex>

            <Text as="div" bold fontSize={customFontSize}>
              <AnimatedText
                durationMS={200}
                delayMS={50}
                isServer={props.isServerChart}
              >
                {formattedValue ?? "---"}
              </AnimatedText>
            </Text>

            {formattedComparisonValue !== null && (
              <Text
                as="div"
                bold
                color={theme.text_color_secondary}
                fontSize={theme.fontSize_base}
              >
                <AnimatedText
                  durationMS={200}
                  delayMS={50}
                  isServer={props.isServerChart}
                >
                  {formattedComparisonValue ?? "---"}
                </AnimatedText>
              </Text>
            )}

            {value !== null && comparisonValue !== null && (
              <Box>
                <PercentageDelta
                  value={value}
                  comparison={comparisonValue}
                  isServer={props.isServerChart}
                />
              </Box>
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
}

type PercentageDeltaProps = {
  value: number;
  comparison: number;
  isServer: boolean;
};

function PercentageDelta(props: PercentageDeltaProps) {
  const theme = useTheme();
  const percentageDelta = getChangePercent(props.value, props.comparison);

  return (
    <Flex alignItems="center">
      {percentageDelta > 0 && <Icon icon={faArrowUp} />}
      {percentageDelta < 0 && <Icon icon={faArrowDown} />}
      <Text
        as="div"
        bold
        fontSize={theme.h4_fontSize}
        marginLeft={percentageDelta === 0 ? 0 : theme.space_xs}
      >
        <AnimatedText durationMS={200} delayMS={50} isServer={props.isServer}>
          {formatPercentage(percentageDelta)}
        </AnimatedText>
      </Text>
    </Flex>
  );
}

type CompactKPIProps = {
  comparisonValue: number | null;
  formattedComparisonValue: string | null;
  formattedValue: string | null;
  isServerChart: boolean;
  measures: Measure[];
  unitDisplay: ComparisonFormat;
  value: number | null;
};

function CompactKPI(props: CompactKPIProps) {
  const theme = useTheme();

  const isComparison = props.formattedComparisonValue !== null;

  let deltaColor: string | undefined = undefined;
  let rawDifference = 0;

  if (props.value !== null && props.comparisonValue !== null) {
    rawDifference = props.value - props.comparisonValue;
    deltaColor =
      props.value < props.comparisonValue
        ? theme.feedback_positive
        : props.value > props.comparisonValue
          ? theme.feedback_negative
          : undefined;
  }

  return (
    <Flex height="100%" justifyContent="space-evenly" width="100%">
      {!isComparison ? (
        <Flex alignItems="center" direction="column" justifyContent="center">
          <Box maxWidth={170}>
            <Text as="div" bold appearance="h5" truncate>
              {camelCaseToReadableString(props.measures[0].name)}
            </Text>
          </Box>

          <Text as="div" appearance="h2">
            <AnimatedText
              durationMS={200}
              delayMS={50}
              isServer={props.isServerChart}
            >
              {props.formattedValue ?? "---"}
            </AnimatedText>
          </Text>
        </Flex>
      ) : (
        <Flex
          alignItems="center"
          justifyContent="center"
          direction="column"
          width="100%"
        >
          <Flex justifyContent="flex-start" maxWidth={170}>
            <Text as="div" appearance="h5" bold truncate>
              {camelCaseToReadableString(props.measures[0].name)}
            </Text>
          </Flex>

          <Flex alignItems="center">
            <Text as="div" appearance="h2">
              <AnimatedText
                durationMS={200}
                delayMS={50}
                isServer={props.isServerChart}
              >
                {props.formattedValue ?? "---"}
              </AnimatedText>
            </Text>

            {props.value !== null && props.comparisonValue !== null && (
              <Flex alignItems="center">
                <Text
                  bold
                  appearance="h5"
                  color={deltaColor}
                  marginLeft={theme.space_sm}
                  marginRight={theme.space_xs}
                >
                  <AnimatedText
                    durationMS={200}
                    delayMS={50}
                    isServer={props.isServerChart}
                  >
                    {props.unitDisplay === ComparisonFormat.PERCENT
                      ? formatPercentage(
                          getChangePercent(props.value, props.comparisonValue)
                        )
                      : formatMeasureValueWithUnit({
                          value: rawDifference,
                          unit: props.measures[0].unit,
                        })}
                  </AnimatedText>
                </Text>
                {props.value > props.comparisonValue && (
                  <Icon color={deltaColor} icon={faCaretUp} />
                )}
                {props.value < props.comparisonValue && (
                  <Icon color={deltaColor} icon={faCaretDown} />
                )}
              </Flex>
            )}
          </Flex>

          <Flex alignItems="center">
            <Box maxWidth={200}>
              <Text
                as="div"
                bold
                color={theme.text_color_secondary}
                fontSize={theme.fontSize_small}
                fontStyle="italic"
                truncate
              >
                {`${camelCaseToReadableString(props.measures[1].name)}:`}
              </Text>
            </Box>

            <Text
              as="div"
              color={theme.text_color_secondary}
              marginLeft={theme.space_xs}
            >
              <AnimatedText
                durationMS={200}
                delayMS={50}
                isServer={props.isServerChart}
              >
                {props.formattedComparisonValue ?? "---"}
              </AnimatedText>
            </Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

function getChangePercent(value: number, comparison: number): number {
  // no comparison is supported as empty space
  if (isNaN(Number(value)) || isNaN(Number(comparison))) {
    return 0;
  }

  // In the case of Infinity, just force the UI to "100%"
  if (comparison === 0) return 1;

  return (value - comparison) / Math.abs(comparison);
}

//TODO: remove after FOCUS and Data Analytics switch
function camelCaseToReadableString(text: string): string {
  const abbreviations = ["cpu", "gpu", "hdd", "ram", "ssd", "vram"];

  let formattedText = text;
  // Replace abbreviation with a placeholder, to avoid splitting the abbreviation
  for (const item of abbreviations) {
    const regex = new RegExp(item, "i");
    formattedText = formattedText.replace(regex, `%${item}%`);
  }

  formattedText = formattedText.replace(/([A-Z])/g, " $1");

  // Replace the abbreviations with the original text in uppercase
  for (const item of abbreviations) {
    formattedText = formattedText.replace(
      `%${item}%`,
      ` ${item.toUpperCase()}`
    );
  }

  formattedText = formattedText.replace(/\b\w/g, (char) => char.toUpperCase());

  return formattedText.trim();
}

const slideIn = keyframes({
  from: {
    transform: "translateY(-10px)",
    opacity: 0,
  },
  to: {
    transform: "translateY(0)",
    opacity: 1,
  },
});

type AnimatedTextProps = {
  children: string;
  delayMS?: number;
  durationMS?: number;
  isServer: boolean;
};

function AnimatedText(props: AnimatedTextProps) {
  const [updateID, setUpdateID] = useState(0);

  useEffect(() => {
    if (!props.isServer) {
      setUpdateID((updateID) => updateID + 1);
    }
  }, [props.children]);

  if (props.isServer) {
    return (
      <Box display="inline-block" position="relative">
        {props.children}
      </Box>
    );
  }

  return (
    <>
      {props.children.split("").map((char, index) => {
        const delay = (props.delayMS ?? 0) * index;

        return (
          <Box
            animation={`${slideIn} ${props.durationMS}ms ${delay}ms forwards`}
            display="inline-block"
            key={`${char}-${index}-${updateID}`}
            opacity={0}
            position="relative"
          >
            {char}
          </Box>
        );
      })}
    </>
  );
}
