import EventReporter from "@/utils/EventReporter";
import registry from "unilib-registry/instance";

// Zendesk type definitions. If they're in their own file it breaks. This is
// included from Zendesk's CDN using a direct script tag in tracking.hbs.
// Information gleaned from https://developer.zendesk.com/embeddables/docs/widget/core

declare interface ZendeskIdentification {
  name: string;
  email: string;
  organization?: string;
}

declare interface ZendeskUpdatePath {
  title: string;
  url: string;
}

declare interface ZendeskPrefillField {
  value: string;
  readOnly?: boolean;
}

declare interface ZendeskPrefill {
  name: ZendeskPrefillField;
  email: ZendeskPrefillField;
  phone?: ZendeskPrefillField;
}

declare function zE(
  target: "webWidget",
  action: "identify",
  data: ZendeskIdentification
);

declare function zE(
  target: "webWidget",
  action: "prefill",
  data: ZendeskPrefill
);

declare function zE(
  target: "webWidget",
  action: "updatePath",
  data: ZendeskUpdatePath
);

declare function zE(target: "webWidget", action: "helpCenter:reauthenticate");

type TernaryUser = {
  email: string;
  firstName: string;
  lastName: string;
  tenant: { name: string };
};

async function populateZendesk(user: TernaryUser): Promise<void> {
  // Populate the zendesk widget fields
  zE("webWidget", "prefill", {
    name: {
      value: `${user.firstName} ${user.lastName}`,
      readOnly: true,
    },
    email: {
      value: user.email,
      readOnly: true,
    },
  });

  // Populate the zendesk widget fields a different way?
  zE("webWidget", "identify", {
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    organization: user.tenant.name,
  });

  // When submitting a ticket, make sure to also provide the current URL.
  zE("webWidget", "updatePath", {
    title: document.title,
    url: window.location.href,
  });
}

function registerZendeskJWT(token: string): void {
  const eventReporter = registry.get<EventReporter>(EventReporter.name);

  window["zESettings"] = {
    webWidget: {
      authenticate: {
        jwtFn: async (callback) => {
          try {
            callback(token);
          } catch (e) {
            eventReporter.reportError(e);
          }
        },
        // Disable KB-related features.
        answerBot: {
          suppress: true,
        },
        helpCenter: {
          suppress: true,
        },
      },
    },
  };
}

/**
  This function enforces that we execute the following items in the following order:
    - RegisterZendeskJWT (provides auth callback on global window object)
    - The zd script (immediately uses the auth callback to get a token)
    - PopulateZendesk (personalizes the zendesk tooling that's ready to make authenticated requests)
*/
export function initZendesk(
  token: string,
  user: TernaryUser,
  url: string
): void {
  registerZendeskJWT(token);

  const script = document.createElement("script");

  script.id = "ze-snippet";
  script.src = `${url}`;

  document.body.appendChild(script);

  script.onload = () => populateZendesk(user);
}
