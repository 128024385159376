import { AnalyticsOptionSchema } from "@/api/analytics/AnalyticsApiClient";
import { DataSource } from "@ternary/api-lib/analytics/enums";

type Option = {
  label: string;
  value: string;
};

type OptionWithProviderName = {
  name: string;
  providerName?: string;
};

type Preference = {
  dataSource: DataSource;
  category: string;
  values: string[];
};

function isOptionWithProviderName(
  option: string | Option | OptionWithProviderName | AnalyticsOptionSchema
): option is OptionWithProviderName {
  return (option as OptionWithProviderName).name !== undefined;
}

function isAnalyticsSchema(
  option: string | Option | OptionWithProviderName | AnalyticsOptionSchema
): option is AnalyticsOptionSchema {
  return (option as AnalyticsOptionSchema).displayName !== undefined;
}

export function filterOutHiddenPreferences<
  T extends string | Option | OptionWithProviderName | AnalyticsOptionSchema,
>(options: T[], dataSource: DataSource, preferences?: Preference[]): T[] {
  if (
    !preferences ||
    !preferences.some((label) => label.dataSource === dataSource)
  ) {
    return options;
  }

  const dataSourcePreferences = preferences.filter(
    (preference) => preference.dataSource === dataSource
  );
  const hiddenPreference = dataSourcePreferences.find(
    (preference) => preference.category === "HIDDEN"
  );

  const filteredOptions = options.filter((option) => {
    return !hiddenPreference?.values.includes(
      typeof option === "string"
        ? option
        : isOptionWithProviderName(option)
          ? option.name
          : isAnalyticsSchema(option)
            ? option.displayName
            : option.value
    );
  });

  return filteredOptions;
}
