//
// Datalligator
//

export const DatalligatorOperator = {
  afterDate: "afterDate",
  afterOrOnDate: "afterOrOnDate",
  beforeDate: "beforeDate",
  beforeOrOnDate: "beforeOrOnDate",
  contains: "contains",
  endsWith: "endsWith",
  equals: "equals",
  gt: "gt",
  gte: "gte",
  inDateRange: "inDateRange",
  lt: "lt",
  lte: "lte",
  notContains: "notContains",
  notEndsWith: "notEndsWith",
  notEquals: "notEquals",
  notInDateRange: "notInDateRange",
  notSet: "notSet",
  notStartsWith: "notStartsWith",
  set: "set",
  startsWith: "startsWith",
} as const;

export type DatalligatorOperator =
  (typeof DatalligatorOperator)[keyof typeof DatalligatorOperator];

export const OrderDirection = { asc: "asc", desc: "desc" } as const;

export type OrderDirection =
  (typeof OrderDirection)[keyof typeof OrderDirection];

export type DatalligatorFilter =
  | DatalligatorSingleFilter
  | DatalligatorCompositeFilter;

export type DatalligatorSingleFilter = {
  schema_field_name: string;
  operator: DatalligatorOperator;
  values: string[];
};

export type DatalligatorCompositeFilter = {
  boolean_logical_operator: "and" | "or";
  filters: (DatalligatorFilter | DatalligatorCompositeFilter)[];
};

export type DatalligatorOrder = {
  field_name: string;
  direction: OrderDirection;
};

//
// Cube
//

// TODO: Remove these types when fully cutover to datalligator
export type CubeFilter =
  | CubeBinaryFilter
  | CubeUnaryFilter
  | CubeLogicalOrFilter
  | CubeLogicalAndFilter;

export type CubeLogicalAndFilter = {
  and: CubeFilter[];
};

export type CubeLogicalOrFilter = {
  or: CubeFilter[];
};

// Temporary to support cube chim
export type CubeUnaryOperator = "set" | "notSet";

export type CubeBinaryOperator =
  | "equals"
  | "notEquals"
  | "contains"
  | "notContains"
  | "startsWith"
  | "notStartsWith"
  | "endsWith"
  | "notEndsWith"
  | "gt"
  | "gte"
  | "lt"
  | "lte"
  | "inDateRange"
  | "notInDateRange"
  | "beforeDate"
  | "beforeOrOnDate"
  | "afterDate"
  | "afterOrOnDate";

export interface CubeBinaryFilter {
  /**
   * @deprecated Use `member` instead.
   */
  dimension?: string;
  member?: string;
  operator: CubeBinaryOperator;
  values: string[];
}

export interface CubeUnaryFilter {
  /**
   * @deprecated Use `member` instead.
   */
  dimension?: string;
  member?: string;
  operator: CubeUnaryOperator;
  values?: never;
}

export type CubeDateRange = string | [string, string];

export type CubeTimeDimensionGranularity =
  | "second"
  | "minute"
  | "hour"
  | "day"
  | "week"
  | "month"
  | "quarter"
  | "year";

export interface CubeTimeDimensionBase {
  dimension: string;
  granularity?: CubeTimeDimensionGranularity;
}

type CubeTimeDimensionComparisonFields = {
  compareDateRange: Array<CubeDateRange>;
  dateRange?: never;
};
export type CubeTimeDimensionComparison = CubeTimeDimensionBase &
  CubeTimeDimensionComparisonFields;

type CubeTimeDimensionRangedFields = {
  dateRange?: CubeDateRange;
};
export type CubeTimeDimensionRanged = CubeTimeDimensionBase &
  CubeTimeDimensionRangedFields;

export type CubeTimeDimension =
  | CubeTimeDimensionComparison
  | CubeTimeDimensionRanged;
