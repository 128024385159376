import { isAfter, isBefore } from "date-fns";
import { DEFAULT_X_AXIS_KEY, NOT_SHOWN_KEY } from "../constants";
import { RawData, RawValue } from "../types";
import { createGetGrouping, totalRawData } from "../ui/ChartDataManager";
import { isFiscalDate } from "./DateUtils";

type RawDataCompare = (a: RawData, b: RawData) => -1 | 0 | 1;

type Order = "asc" | "desc";

type SortRawDataParams = {
  data: RawData[];
  groupingKeys?: string[];
  order?: { xAxis?: Order; yAxis?: Order };
  xAxisKey?: string;
  yAxisKeys: string[];
};

/**
 * Sorts an array of RawData objects based on x-axis and y-axis values
 * @param {SortRawDataParams} params - Configuration for sorting raw data
 * @param {RawData[]} params.data - The array of data to sort
 * @param {string[]} [params.groupingKeys] - Keys used for grouping data
 * @param {Object} [params.order] - Sort direction for x and y axes
 * @param {Order} [params.order.xAxis='asc'] - Sort direction for x-axis ('asc' or 'desc')
 * @param {Order} [params.order.yAxis='asc'] - Sort direction for y-axis ('asc' or 'desc')
 * @param {string} [params.xAxisKey] - Key to use for x-axis values
 * @param {string[]} params.yAxisKeys - Keys to use for y-axis values
 * @returns {RawData[]} - The sorted array of data
 */
export function sortRawData(params: SortRawDataParams): RawData[] {
  const compare = getRawDataCompare(params);

  params.data.sort(compare);

  return params.data;
}

/**
 * Creates a comparison function for sorting RawData objects
 * @param {SortRawDataParams} params - Configuration for creating comparison function
 * @returns {RawDataCompare} A function that compares two RawData objects
 * @private
 */
function getRawDataCompare(params: SortRawDataParams): RawDataCompare {
  const order = {
    xAxis: params.order?.xAxis ?? "asc",
    yAxis: params.order?.yAxis ?? "asc",
  };
  const tie: RawDataCompare = () => 0;
  let xAxisCompare = tie;
  let yAxisCompare = tie;

  // X AXIS COMPARE
  if (params.xAxisKey === DEFAULT_X_AXIS_KEY) {
    xAxisCompare = compareDataByTimestamp;
  } else if (params.xAxisKey === "invoiceMonth") {
    xAxisCompare = compareDataByInvoiceMonth;
  } else if (typeof params.xAxisKey === "string") {
    const compareXAxisByYAxisTotals = createCompareXAxisValuesByYAxisTotals({
      data: params.data,
      xAxisKey: params.xAxisKey,
      yAxisKey: params.yAxisKeys,
    });

    xAxisCompare = useNextCompareIfTie(
      createOtherCompare(params.groupingKeys ?? []),
      compareXAxisByYAxisTotals
    );
  }

  // Y AXIS COMPARE
  if (!params.groupingKeys || params.groupingKeys.length === 0) {
    const comparesByYAxisValue = params.yAxisKeys.map((yAxisKey) =>
      createCompareByValueAt(yAxisKey)
    );

    yAxisCompare = useNextCompareIfTie(...comparesByYAxisValue);
  } else {
    const compareByGroupingTotals = createCompareByGroupingTotals({
      data: params.data,
      groupingKeys: params.groupingKeys,
      sumKeys: params.yAxisKeys,
    });

    yAxisCompare = useNextCompareIfTie(
      createOtherCompare(params.groupingKeys),
      compareByGroupingTotals
    );
  }

  // ASC / DESC
  if (order.xAxis === "desc") {
    xAxisCompare = invert(xAxisCompare);
  }
  if (order.yAxis === "desc") {
    yAxisCompare = invert(yAxisCompare);
  }

  // FINAL RESULT
  return useNextCompareIfTie(
    xAxisCompare,
    yAxisCompare,
    createIndexCompare(params.data) // stable sort
  );
}

/**
 * Compares two raw values for sorting
 * @param {RawValue} [a] - First raw value to compare
 * @param {RawValue} [b] - Second raw value to compare
 * @returns {-1 | 0 | 1} Comparison result (-1: a < b, 0: a = b, 1: a > b)
 */
export function compareRawValues(a?: RawValue, b?: RawValue) {
  const aVal = a ?? null;
  const bVal = b ?? null;

  if (aVal === null && bVal === null) return 0;
  if (aVal === null) return -1;
  if (bVal === null) return 1;

  if (aVal < bVal) return -1;
  if (aVal > bVal) return 1;

  return 0;
}

/**
 * Adds two raw values together if they are numbers
 * @param {RawValue} value - Base value
 * @param {RawValue} add - Value to add
 * @returns {RawValue} The sum if both are numbers, otherwise returns one of the values
 */
export function addRawValues(value: RawValue, add: RawValue) {
  if (typeof add !== "number") {
    return value;
  }

  if (typeof value !== "number") {
    return add;
  }

  return value + add;
}

//
// COMPARE FUNCTIONS
//

/**
 * Compares two RawData objects by their timestamp values
 * @param {RawData} a - First data point
 * @param {RawData} b - Second data point
 * @returns {-1 | 0 | 1} Comparison result based on chronological order
 * @private
 */
function compareDataByTimestamp(a: RawData, b: RawData) {
  if (typeof a.timestamp !== "string" || typeof b.timestamp !== "string") {
    return 0;
  }
  if (isFiscalDate(a.timestamp) && isFiscalDate(b.timestamp)) {
    return compareFiscalDates(a, b);
  }
  if (isBefore(new Date(a.timestamp), new Date(b.timestamp))) {
    return -1;
  }
  if (isAfter(new Date(a.timestamp), new Date(b.timestamp))) {
    return 1;
  }
  return 0;
}

/**
 * Compares two RawData objects by their invoiceMonth values
 * @param {RawData} a - First data point
 * @param {RawData} b - Second data point
 * @returns {-1 | 0 | 1} Comparison result based on chronological order
 * @private
 */
function compareDataByInvoiceMonth(a: RawData, b: RawData) {
  if (
    typeof a.invoiceMonth !== "string" ||
    typeof b.invoiceMonth !== "string"
  ) {
    return 0;
  }
  if (isBefore(new Date(a.invoiceMonth), new Date(b.invoiceMonth))) {
    return -1;
  }
  if (isAfter(new Date(a.invoiceMonth), new Date(b.invoiceMonth))) {
    return 1;
  }

  return 0;
}

/**
 * Converts a fiscal date string to a numeric representation for comparison
 * @param {string} fiscalTimestamp - Fiscal date in format like "FY23 Q4 M12 W53"
 * @returns {number} Numeric representation of the fiscal date
 * @private
 */
function getFiscalDateNumber(fiscalTimestamp: string): number {
  if (!isFiscalDate(fiscalTimestamp)) return -1;

  const fyMatch = fiscalTimestamp.match(/fy\d+/i);
  const fy = fyMatch ? fyMatch[0].slice(2) : "0000";

  const qMatch = fiscalTimestamp.match(/q\d+/i);
  const q = qMatch ? qMatch[0].slice(1) : "0";

  const mMatch = fiscalTimestamp.match(/m\d+/i);
  const m = mMatch ? mMatch[0].slice(1).padStart(2, "0") : "00";

  const wMatch = fiscalTimestamp.match(/w\d+/i);
  const w = wMatch ? wMatch[0].slice(1).padStart(2, "0") : "00";

  return Number(fy + q + m + w);
}

/**
 * Compares two RawData objects by their fiscal date timestamps
 * @param {RawData} a - First data point
 * @param {RawData} b - Second data point
 * @returns {-1 | 0 | 1} Comparison result based on fiscal period order
 */
export function compareFiscalDates(a: RawData, b: RawData) {
  const aDateNumber =
    typeof a.timestamp === "string" ? getFiscalDateNumber(a.timestamp) : -1;
  const bDateNumber =
    typeof b.timestamp === "string" ? getFiscalDateNumber(b.timestamp) : -1;

  if (aDateNumber < bDateNumber) return -1;
  if (aDateNumber > bDateNumber) return 1;
  return 0;
}

//
// HIGHER ORDER COMPARE FUNCTIONS
//

/**
 * Creates a comparison function that tries each provided comparison function in sequence
 * until a non-zero result is found
 * @param {...RawDataCompare} compareFns - Comparison functions to try in sequence
 * @returns {RawDataCompare} A function that compares using the first non-tie result
 * @private
 */
function useNextCompareIfTie(...compareFns: RawDataCompare[]): RawDataCompare {
  return (a, b) => {
    for (const compare of compareFns) {
      const result = compare(a, b);

      if (result !== 0) return result;
    }

    return 0;
  };
}

/**
 * Inverts the result of a comparison function
 * @param {RawDataCompare} compare - Comparison function to invert
 * @returns {RawDataCompare} Inverted comparison function
 * @private
 */
function invert(compare: RawDataCompare): RawDataCompare {
  return (a, b) => {
    const result = compare(a, b);
    if (result < 0) return 1;
    if (result > 0) return -1;
    return 0;
  };
}

/**
 * Creates a comparison function based on totals of grouped data
 * @param {Object} params - Parameters for creating the comparison
 * @param {RawData[]} params.data - The data to analyze for totals
 * @param {string[]} params.groupingKeys - Keys used for grouping
 * @param {string[]} params.sumKeys - Keys to sum within groups
 * @returns {RawDataCompare} A function that compares based on group totals
 * @private
 */
function createCompareByGroupingTotals(params: {
  data: RawData[];
  groupingKeys: string[];
  sumKeys: string[];
}): RawDataCompare {
  const totals = totalRawData(params);
  const getGroupingKey = createGetGrouping(params.groupingKeys);
  const getTotalKey = (datum: RawData, sumKey: string) =>
    getGroupingKey(datum) + "---" + sumKey;

  const allTotalsObject: { [valueKey: string]: RawValue } = {};

  totals.forEach((totalDatum) => {
    params.sumKeys.forEach((sumKey) => {
      allTotalsObject[getTotalKey(totalDatum, sumKey)] =
        totalDatum[sumKey] ?? null;
    });
  });

  return useNextCompareIfTie(
    ...params.sumKeys.map(
      (sumKey): RawDataCompare =>
        (a, b) => {
          const aTotalKey = getTotalKey(a, sumKey);
          const bTotalKey = getTotalKey(b, sumKey);

          const aTotal = allTotalsObject[aTotalKey];
          const bTotal = allTotalsObject[bTotalKey];

          return compareRawValues(aTotal, bTotal);
        }
    )
  );
}

/**
 * Creates a comparison function that compares by a specific key's value
 * @param {string} key - The key to compare
 * @returns {RawDataCompare} A function that compares two objects by the specified key
 * @private
 */
function createCompareByValueAt(key: string): RawDataCompare {
  return (a, b) => compareRawValues(a[key], b[key]);
}

/**
 * Creates a comparison function that compares based on totals of y-axis values
 * for each x-axis value
 * @param {Object} params - Parameters for creating the comparison
 * @param {RawData[]} params.data - The data to analyze
 * @param {string} params.xAxisKey - Key used for x-axis
 * @param {string|string[]} params.yAxisKey - Key(s) used for y-axis
 * @returns {RawDataCompare} A function that compares based on x-axis totals
 * @private
 */
function createCompareXAxisValuesByYAxisTotals(params: {
  data: RawData[];
  xAxisKey: string;
  yAxisKey: string | string[];
}): RawDataCompare {
  const yAxes =
    typeof params.yAxisKey === "string" ? [params.yAxisKey] : params.yAxisKey;

  const getTotalKey = (datum: RawData, yAxisKey: string) =>
    `${String(datum[params.xAxisKey])} - ${String(yAxisKey)}`;

  const totalsKeyedByXYAxis: { [xValueYKey: string]: RawValue } = {};

  params.data.forEach((datum) => {
    yAxes.forEach((yAxisKey) => {
      const totalKey = getTotalKey(datum, yAxisKey);

      const total = totalsKeyedByXYAxis[totalKey];

      totalsKeyedByXYAxis[totalKey] = addRawValues(total, datum[yAxisKey]);
    });
  });

  const compareYAxisTotalsInOrder = useNextCompareIfTie(
    ...yAxes.map(
      (yAxis): RawDataCompare =>
        (a, b) => {
          const aVal = totalsKeyedByXYAxis[getTotalKey(a, yAxis)];
          const bVal = totalsKeyedByXYAxis[getTotalKey(b, yAxis)];

          return compareRawValues(aVal, bVal);
        }
    )
  );

  return (a, b) => compareYAxisTotalsInOrder(a, b);
}

/**
 * Creates a comparison function that preserves the original order of elements
 * @param {RawData[]} data - The original data array
 * @returns {RawDataCompare} A function that compares based on original indices
 * @private
 */
function createIndexCompare(data: RawData[]): RawDataCompare {
  const indexMap = new Map<RawData, number>();

  data.forEach((datum, index) => {
    indexMap.set(datum, index);
  });

  return (a, b) => {
    const aIndex = indexMap.get(a) ?? 0;
    const bIndex = indexMap.get(b) ?? 0;

    if (aIndex < bIndex) return -1;
    if (aIndex > bIndex) return 1;
    return 0;
  };
}

/**
 * Creates a comparison function that places "Other" categories
 * (identified by NOT_SHOWN_KEY) at the beginning
 * @param {string[]} groupingKeys - Keys used for grouping
 * @returns {RawDataCompare} A function that compares based on "Other" status
 * @private
 */
function createOtherCompare(groupingKeys: string[]): RawDataCompare {
  return (a, b) => {
    const aIsOther = groupingKeys.every(
      (groupingKey) => a[groupingKey] === NOT_SHOWN_KEY
    );
    const bIsOther = groupingKeys.every(
      (groupingKey) => b[groupingKey] === NOT_SHOWN_KEY
    );

    if (aIsOther !== bIsOther) {
      return aIsOther ? -1 : 1;
    }

    return 0;
  };
}
