import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { MspBillingInfoEntity } from "@ternary/api-lib/core/types/MspBillingInfo";
import { UpdateMspBillingInfoParams } from "../../../../api/core/types";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { billingInfoID: string } & UpdateMspBillingInfoParams,
  { previousBillingInfo: MspBillingInfoEntity }
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { billingInfoID: string } & UpdateMspBillingInfoParams,
  { previousBillingInfo: MspBillingInfoEntity }
>;

export function useUpdateMspBillingInfo(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ billingInfoID, ...params }) =>
      client.updateMspBillingInfo(billingInfoID, params),
    ...options,
  });
}
