import { endOfDay } from "date-fns";
import { format } from "date-fns-tz";
import { DataSource, DurationType, Operator, TimeGranularity } from "../enums";
import { Order, QueryFilter } from "../types";
import { DateRange, getSchemaFromDataSource, roundDate } from "../utils";
import { configureFiscalDateParams } from "../utils/FiscalDateUtils";
import {
  DatalligatorFilter,
  DatalligatorOperator,
  DatalligatorOrder,
  DatalligatorSingleFilter,
} from "./types";
import { isStringTuple } from "./utils";

export const ISO_TIMESTAMP_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";

export interface Properties {
  dataSource: DataSource;
  dateRange: Date[] | [string, string];
  dimensions?: string[];
  durationType?: DurationType; // this shoud be removed
  fiscalPeriodMap?: Record<string, string> | null;
  granularity?: TimeGranularity;
  isFiscalMode?: boolean;
  limit?: number;
  measures?: string[];
  offset?: number;
  order?: Order;
  postAggFilters?: QueryFilter[];
  preAggFilters?: QueryFilter[];
  ignoreGlobalFilters?: boolean;
}

export default class DataQuery {
  public data_source: string = "";
  public dimensions?: string[];
  public end_time: string;
  public limit?: null | number;
  public measures?: string[];
  public offset?: number;
  public order_by?: DatalligatorOrder[];
  public post_agg_filters?: DatalligatorFilter[];
  public pre_agg_filters?: DatalligatorFilter[];
  public precision?: TimeGranularity;
  public response_format?: string;
  public start_time: string;

  constructor(props: Properties) {
    // Configure props for fiscal mode if enabled
    if (props.isFiscalMode && props.fiscalPeriodMap) {
      props = configureFiscalDateParams(props);
    }

    let dateRange: [string, string];

    if (isStringTuple(props.dateRange)) {
      dateRange = props.dateRange;
    } else {
      dateRange = this._getDateRange(props.dateRange);
    }

    this.setDataSource(props.dataSource);

    if (props.dimensions) {
      this.dimensions = props.dimensions;
    }

    if (props.postAggFilters) {
      this.post_agg_filters = props.postAggFilters.map(transformFilter);
    }

    if (props.preAggFilters) {
      this.pre_agg_filters = props.preAggFilters.map(transformFilter);
    }

    this.limit = props.limit;

    if (props.measures) {
      this.measures = props.measures;
    }

    if (props.order) {
      this.order_by = Object.entries(props.order).map(([key, value]) => ({
        field_name: key,
        direction: value,
      }));
    }

    this.precision = props.granularity;

    this.end_time = dateRange[1];

    this.start_time = dateRange[0];

    this.offset = props.offset ?? 0;
  }

  setDataSource(value: DataSource | string) {
    const dataSourceValue = Object.values(DataSource).find(
      (dataSourceValue) => dataSourceValue === value
    );

    if (dataSourceValue) {
      // TODO: Remove the need to do this conversion
      this.data_source = getSchemaFromDataSource(dataSourceValue);
    } else {
      this.data_source = value;
    }
  }

  setDateRange(value: Date[] | [string, string]) {
    let dateRange: [string, string];

    if (isStringTuple(value)) {
      dateRange = value;
    } else {
      dateRange = this._getDateRange(value);
    }

    this.end_time = dateRange[1];

    this.start_time = dateRange[0];
  }

  static removeInvalidFilters = removeInvalidFilters;
  static transformFilter = transformFilter;

  protected _getDateRange(dateRange: DateRange): [string, string] {
    const startDate = roundDate(dateRange[0]);
    const endDate = roundDate(dateRange[1]);

    return [
      format(startDate, ISO_TIMESTAMP_FORMAT),
      format(endOfDay(endDate), ISO_TIMESTAMP_FORMAT),
    ];
  }
}

export function getOperator(operator: Operator): DatalligatorOperator {
  switch (operator) {
    case Operator.CONTAINS:
      return DatalligatorOperator.contains;
    case Operator.EQUALS:
      return DatalligatorOperator.equals;
    case Operator.GTE:
      return DatalligatorOperator.gte;
    case Operator.LTE:
      return DatalligatorOperator.lte;
    case Operator.NOT_CONTAINS:
      return DatalligatorOperator.notContains;
    case Operator.NOT_EQUALS:
      return DatalligatorOperator.notEquals;
    case Operator.NOT_SET:
      return DatalligatorOperator.notSet;
    case Operator.SET:
      return DatalligatorOperator.set;
    default:
      return DatalligatorOperator.equals;
  }
}

function removeInvalidFilters(
  filters: DatalligatorFilter[]
): DatalligatorFilter[] {
  const updatedFilters: DatalligatorFilter[] = [];

  filters.forEach((filter) => {
    if ("boolean_logical_operator" in filter) {
      const subFilters = removeInvalidFilters(filter.filters);

      if (subFilters.length > 0) {
        updatedFilters.push({
          boolean_logical_operator: filter.boolean_logical_operator,
          filters: subFilters,
        });
      }

      return;
    }

    if (isValidFilterSingleFilter(filter)) {
      updatedFilters.push({
        operator: filter.operator,
        schema_field_name: filter.schema_field_name,
        values: filter.values,
      });
    }
  });

  return updatedFilters;
}

function isValidFilterSingleFilter(filter: DatalligatorSingleFilter): boolean {
  switch (filter.operator) {
    case DatalligatorOperator.set:
    case DatalligatorOperator.notSet:
      return true;
    default:
      return filter.values.length > 0;
  }
}

function transformFilter(filter: QueryFilter): DatalligatorFilter {
  if ("name" in filter) {
    return {
      schema_field_name: filter.name,
      // TODO: Remove the need to do this conversion
      operator: getOperator(filter.operator),
      values: filter.values ?? [],
    };
  }

  if ("and" in filter) {
    return {
      boolean_logical_operator: "and",
      filters: filter.and.map((filter) => transformFilter(filter)),
    };
  }

  if ("or" in filter) {
    return {
      boolean_logical_operator: "or",
      filters: filter.or.map((filter) => transformFilter(filter)),
    };
  }

  throw new Error("INVALID_FILTER");
}
