import { useTheme } from "@emotion/react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { DataSource, Operator } from "@ternary/api-lib/analytics/enums";
import {
  CloudProviderType,
  RecommendationCategory,
  ServiceType,
} from "@ternary/api-lib/constants/enums";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import { Navigate } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import ErrorBoundary from "../../../../components/ErrorBoundary";
import paths from "../../../../constants/paths";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import { LinkWithSearchParams } from "../../../../lib/react-router";
import { RECOMMENDATION_KIND_DEFAULT } from "../../../../types";
import Tabs from "../../../../ui-lib/components/Tabs";
import RecommendationsContainer from "../../components/RecommendationsContainer";
import copyText from "../../copyText";
import GCPStorageVisibilityContainer from "./GCPStorageVisibilityContainer";

export default function GCPStorageInsightsPage() {
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  const [searchParamState, setSearchParamState] = useQueryParams({
    tab: withDefault(StringParam, "visibility"),
  });

  if (!gatekeeper.hasGCPIntegration) {
    return <Navigate to={paths._insightsStorage} replace={true} />;
  }

  const containers = [
    {
      component: <GCPStorageVisibilityContainer />,
      label: copyText.visibilityTabLabel,
      value: "visibility",
    },
  ];

  // NOTE: This doubles as a check for being able to access Optimizations tabs
  if (gatekeeper.canViewGcpUsageRecs && gatekeeper.canUpdateRecommendations) {
    containers.push({
      component: (
        <RecommendationsContainer
          category={RecommendationCategory.STORAGE}
          cloudProviderType={CloudProviderType.GCP}
          dataSource={DataSource.BILLING}
          recommendationsKind={RECOMMENDATION_KIND_DEFAULT}
          serviceType={ServiceType.GCS}
          skuCategory="Storage"
          summaryFilters={[
            {
              name: "serviceDescription",
              operator: Operator.EQUALS,
              values: ["Cloud Storage"],
            },
          ]}
        />
      ),
      label: copyText.optimizationsTabLabel,
      value: "optimizations",
    });
  }

  return (
    <ErrorBoundary boundaryName="GCPStorageInsightsPage">
      <Flex alignItems="center" marginBottom={theme.space_lg}>
        <Text
          as={LinkWithSearchParams}
          color={theme.text_color_secondary}
          marginRight={theme.space_sm}
          to={paths._insightsStorage}
        >
          {copyText.storageInsightsPageTitle}
        </Text>
        <Icon
          color={theme.text_color_secondary}
          icon={faChevronRight}
          size="xs"
        />
        <Text appearance="h2" marginLeft={theme.space_sm}>
          {copyText.gcpCloudStorageTitle}
        </Text>
      </Flex>
      <Tabs
        activeValue={searchParamState.tab}
        tabs={containers}
        onSelect={(tab: string) => setSearchParamState({ tab })}
      />
    </ErrorBoundary>
  );
}
