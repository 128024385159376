import { useEventReporter } from "@/context/EventReporterProvider";
import EventReporter from "@/utils/EventReporter";
import { getTenant } from "@/utils/window";
import { useQuery } from "@tanstack/react-query";
import {
  GcpCommitmentDurationType,
  GcpCommitmentType,
  TenantType,
} from "@ternary/api-lib/constants/enums";
import {
  DEFAULT_DASHBOARD_ID,
  SYSTEM_TENANT_ID,
} from "@ternary/api-lib/constants/system";
import { groupBy, uniq } from "lodash";
import registry from "unilib-registry/instance";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import CoreAPIClient from "../CoreAPIClient";
import CoreAPIClientError from "../CoreAPIClientError";
import { useCoreAPIClient } from "../CoreAPIClientProvider";
import { AuthenticatedUserEntity } from "../types";

type Options = UseQueryOptions<AuthenticatedUserEntity, CoreAPIClientError>;
type Result = UseQueryResult<AuthenticatedUserEntity, CoreAPIClientError>;

export default function useGetAuthenticatedUser(options?: Options): Result {
  const apiClient = useCoreAPIClient();
  const eventReporter = useEventReporter();

  return useQuery({
    queryKey: ["authenticatedUser"],
    queryFn: () => getAuthenticatedUser({ apiClient, eventReporter }),
    ...options,
  });
}

const defaultSettings = {
  id: "0",
  createdAt: new Date().toISOString(),
  createdByID: SYSTEM_TENANT_ID,
  updatedAt: null,
  updatedByID: null,
  cudRecCommitmentLength: GcpCommitmentDurationType.THIRTY_SIX_MONTH,
  cudRecCommitmentType: GcpCommitmentType.RESOURCE,
  fiscalMode: false,
  minimumEstimatedSavingsThreshold: 0,
  notifyAlerts: false,
  notifyBudgets: false,
  notifyCaseUpdates: false,
  notifyRecs: false,
  notifyRecsDaily: false,
  notifyRecsMonthly: false,
  notifyRecsWeekly: false,
  notifyReportsDaily: false,
  notifyReportsMonthly: false,
  notifyReportsWeekly: false,
  preferredDashboardID: DEFAULT_DASHBOARD_ID,
  recFilters: { services: [], subAccountIDs: [], vendors: [] },
};

interface Config {
  apiClient: CoreAPIClient;
  eventReporter: EventReporter;
}

async function getAuthenticatedUser({
  apiClient,
  eventReporter,
}: Config): Promise<AuthenticatedUserEntity> {
  const user = await apiClient.getAuthenticatedUser();

  const tenant = getTenant(user.tenants);

  let settings = user.tenantSettings.find(
    (settings) => settings.tenantID === tenant.id
  );

  // This is a temporary stopgap for the interim while settings exist
  // in postgres and grants exist in firestore. Otherwise access granted
  // in prod means there will be no tenant settings in staging or vice versa.
  settings = settings
    ? settings
    : { ...defaultSettings, tenantID: tenant.id, userID: user.id };

  // if (!settings) {
  //   throw new Error("UNEXPECTED_SETTINGS_NOT_FOUND");
  // }

  const featureFlagsGroupedByTenantID = groupBy(
    user.featureFlags,
    (flag) => flag.tenantID
  );

  settings.fiscalMode = settings.fiscalMode && Boolean(tenant.fiscalCalendar);

  // NOTE: Set authenticated user where we need access to it
  const authenticatedUser: AuthenticatedUserEntity = {
    ...user,
    settings,
    tenant: {
      ...tenant,
      featureFlags: featureFlagsGroupedByTenantID[tenant.id] ?? [],
    },
  };

  // If logging into an MSP tenant. We need to check to see if the MSP
  // has added custom logo images for branding.
  if (
    (tenant.type === TenantType.MSP_CUSTOMER && tenant.parentTenantID) ||
    tenant.type === TenantType.MSP_PARENT
  ) {
    const tenantID =
      tenant.type === TenantType.MSP_CUSTOMER && tenant.parentTenantID
        ? tenant.parentTenantID
        : tenant.id;

    const mspTenantSettings =
      await apiClient.getMspTenantSettingsByTenantID(tenantID);

    const parentTenantFlags = featureFlagsGroupedByTenantID[tenantID] ?? [];

    if (tenant.parentTenantID === tenantID) {
      authenticatedUser.tenant.featureFlags = uniq([
        ...authenticatedUser.tenant.featureFlags,
        ...parentTenantFlags,
      ]);
    }

    if (mspTenantSettings) {
      if (mspTenantSettings.logoImageSquareURL) {
        authenticatedUser.logoImageSquareURL =
          mspTenantSettings.logoImageSquareURL;
      }

      if (mspTenantSettings.logoImageWideURL) {
        authenticatedUser.logoImageWideURL = mspTenantSettings.logoImageWideURL;
      }
    }
  }

  eventReporter.registerUser(authenticatedUser);

  registry.set("authenticatedUser", authenticatedUser);

  return authenticatedUser;
}
