import { useTheme } from "@emotion/react";
import {
  formatCurrency,
  formatPercentage,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import { AWSRateRecommendationTerm } from "@ternary/api-lib/constants/enums";
import {
  AWSRateReservedInstanceRecommendationEntity,
  AWSRateSavingsPlanRecommendationEntity,
} from "@ternary/api-lib/core/types";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import React from "react";
import Meter from "../../../../components/Meter";
import copyText from "../../copyText";
import { AWSCommittedUseType } from "../types";

type Props = {
  committedUseType: AWSCommittedUseType;
  isLoading: boolean;
  reservedInstanceRecommendations: AWSRateReservedInstanceRecommendationEntity[];
  savingsPlanRecommendations: AWSRateSavingsPlanRecommendationEntity[];
};

export default function AWSCommittedUseMeter(props: Props) {
  const theme = useTheme();

  const meterValues =
    props.committedUseType === AWSCommittedUseType.RI
      ? getRIMeterValues(props.reservedInstanceRecommendations)
      : getSPMeterValues(props.savingsPlanRecommendations);

  return (
    <Flex
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_1}
      padding={theme.space_md}
      paddingVertical={theme.space_sm}
      overflowX="auto"
    >
      <Box flex="1 0 175px">
        <Meter
          header={copyText.awsMeter_0_count}
          hideFooter
          loading={props.isLoading}
          value={String(meterValues.count)}
        />
      </Box>

      <Box
        borderRight={`1px solid ${theme.border_color}`}
        flex="0 0 auto"
        marginHorizontal={theme.space_lg}
      />

      <Box flex="1 0 175px">
        <Meter
          header={copyText.awsMeter_1_estMonthlySavings}
          hideFooter
          loading={props.isLoading}
          value={formatCurrency({ number: meterValues.estMonthlySavings })}
        />
      </Box>

      <Box
        borderRight={`1px solid ${theme.border_color}`}
        flex="0 0 auto"
        marginHorizontal={theme.space_lg}
      />

      <Box flex="1 0 175px">
        <Meter
          header={copyText.awsMeter_2_estTotalSavings}
          hideFooter
          loading={props.isLoading}
          value={formatCurrency({ number: meterValues.estTotalSavings })}
        />
      </Box>

      <Box
        borderRight={`1px solid ${theme.border_color}`}
        flex="0 0 auto"
        marginHorizontal={theme.space_lg}
      />

      <Box flex="1 0 175px">
        <Meter
          header={copyText.awsMeter_3_estSavingsPercentage}
          hideFooter
          loading={props.isLoading}
          value={formatPercentage(meterValues.estSavingsPercentage)}
        />
      </Box>

      <Box
        borderRight={`1px solid ${theme.border_color}`}
        flex="0 0 auto"
        marginHorizontal={theme.space_lg}
      />

      <Box flex="1 0 175px">
        <Meter
          header={copyText.awsMeter_4_upfrontPayment}
          hideFooter
          loading={props.isLoading}
          value={formatCurrency({ number: meterValues.upfrontPayment })}
        />
      </Box>

      <Box
        borderRight={`1px solid ${theme.border_color}`}
        flex="0 0 auto"
        marginHorizontal={theme.space_lg}
      />

      <Box flex="1 0 175px">
        <Meter
          header={
            props.committedUseType === AWSCommittedUseType.RI
              ? copyText.awsMeter_5_monthlyPayment
              : copyText.awsMeter_5_hourlyCommitmentToPurchase
          }
          hideFooter
          loading={props.isLoading}
          value={formatCurrency({
            number:
              props.committedUseType === AWSCommittedUseType.RI
                ? meterValues.monthlyPayment
                : meterValues.hourlyCommitmentToPurchase,
          })}
        />
      </Box>

      <Box
        borderRight={`1px solid ${theme.border_color}`}
        flex="0 0 auto"
        marginHorizontal={theme.space_lg}
      />

      <Box flex="1 0 175px">
        <Meter
          header={
            props.committedUseType === AWSCommittedUseType.RI
              ? copyText.awsMeter_6_totalRICost
              : copyText.awsMeter_6_totalSPCost
          }
          hideFooter
          loading={props.isLoading}
          value={formatCurrency({ number: meterValues.totalCost })}
        />
      </Box>
    </Flex>
  );
}

function getRIMeterValues(ris: AWSRateReservedInstanceRecommendationEntity[]) {
  const count = ris.length;
  let estMonthlySavings = 0;
  let estTotalSavings = 0;
  let estSavingsPercentage = 0;
  let upfrontPayment = 0;
  let monthlyPayment = 0;
  let estimatedOnDemandCost = 0;
  let totalCost = 0;

  ris.forEach((ri) => {
    const termMult = ri.term === AWSRateRecommendationTerm.ONE_YEAR ? 12 : 36;
    estMonthlySavings += ri.estimatedMonthlySavingsAmount;
    estTotalSavings += ri.estimatedMonthlySavingsAmount * termMult;
    upfrontPayment += ri.upfrontCost;
    monthlyPayment += ri.recurringStandardMonthlyCost;
    estimatedOnDemandCost += ri.estimatedOnDemandCost * termMult;
    totalCost += (ri.recurringStandardMonthlyCost + ri.upfrontCost) * termMult;
  });

  estSavingsPercentage = estTotalSavings / estimatedOnDemandCost;

  return {
    count,
    estMonthlySavings,
    estSavingsPercentage,
    estTotalSavings,
    hourlyCommitmentToPurchase: 0,
    monthlyPayment,
    totalCost,
    upfrontPayment,
  };
}

function getSPMeterValues(sps: AWSRateSavingsPlanRecommendationEntity[]) {
  const count = sps.length;
  let estMonthlySavings = 0;
  let estTotalSavings = 0;
  let estSavingsPercentage = 0;
  let upfrontPayment = 0;
  let hourlyCommitmentToPurchase = 0;
  let totalCost = 0;

  sps.forEach((sp) => {
    const termMult = sp.term === AWSRateRecommendationTerm.ONE_YEAR ? 12 : 36;
    estMonthlySavings += sp.estimatedMonthlySavingsAmount;
    estTotalSavings += sp.estimatedMonthlySavingsAmount * termMult;
    upfrontPayment += sp.upfrontCost;
    hourlyCommitmentToPurchase += sp.hourlyCommitmentToPurchase;
    totalCost += sp.estimatedTotalCost;
  });

  sps.forEach((sp) => {
    estSavingsPercentage += sp.estimatedSavingsPercentage;
  });

  estSavingsPercentage = estSavingsPercentage / sps.length / 100;

  return {
    count,
    estMonthlySavings,
    estSavingsPercentage,
    estTotalSavings,
    hourlyCommitmentToPurchase,
    monthlyPayment: 0,
    totalCost,
    upfrontPayment,
  };
}
