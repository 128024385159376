import Modal from "@/ui-lib/components/Modal";
import TextInput from "@/ui-lib/components/TextInput";
import { DateRange } from "@/utils/dates";
import { createSortingUtils } from "@/utils/sort";
import { useTheme } from "@emotion/react";
import { faFileExport, faSearch } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import { formatCurrency } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Table from "@ternary/api-lib/ui-lib/components/Table/Table";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import prettyBytes from "pretty-bytes";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import copyText from "../../copyText";
import { EBStore } from "../types";

interface Props {
  date: DateRange;
  isLoading: boolean;
  onClose: () => void;
  snapshots: EBStore[];
}

type TableData = {
  lineItemUsageAccountId: string;
  snapshotId: string;
  snapshotUsageBytes: number;
  totalCost: number;
};

const { numberSort } = createSortingUtils<TableData>();

export default function AWSStorageEBSSnapshotsTable(props: Props): JSX.Element {
  const theme = useTheme();
  const columnHelper = createColumnHelper<TableData>();
  const [searchText, setSearchText] = useState("");

  const subTableTitle = useMemo(
    () =>
      props.snapshots.length > 0
        ? `Snapshots ${props.snapshots[0].billPayerAccountId} / ${props.snapshots[0].region}`
        : null,
    [props.snapshots]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("snapshotId", {
        header: copyText.ebsSubTableSnapshotGroupHeader_snapshotId,
        meta: { align: "left", truncate: true },
        size: 125,
      }),
      columnHelper.accessor("lineItemUsageAccountId", {
        header: copyText.ebsSubTableSnapshotGroupHeader_lineItemUsageAccountId,
        meta: { align: "left", truncate: true },
        size: 125,
      }),
      columnHelper.accessor("snapshotUsageBytes", {
        cell: ({ getValue }) => <>{prettyBytes(getValue())}</>,
        header: copyText.ebsSubTableSnapshotGroupHeader_snapshotUsageBytes,
        meta: { align: "right" },
        size: 80,
      }),
      columnHelper.accessor("totalCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.ebsSubTableSnapshotGroupHeader_totalCost,
        meta: { align: "right" },
        size: 80,
        sortingFn: numberSort,
      }),
    ],
    []
  );

  const data = useMemo(
    () =>
      props.snapshots.map((snapshot) => ({
        lineItemUsageAccountId: snapshot.lineItemUsageAccountId,
        snapshotId: snapshot.volumeId.split("/")[1],
        snapshotUsageBytes: snapshot.snapshotUsageBytes,
        totalCost: snapshot.totalCost,
      })),
    [props.snapshots]
  );

  const filteredData = useMemo(() => {
    if (searchText.length === 0) return data;

    return data.filter((snapshot) => {
      const str = searchText.toLowerCase();
      const snapshotName = snapshot.snapshotId.toLowerCase();
      const snapshotLineItemAccountID =
        snapshot.lineItemUsageAccountId.toLowerCase();

      return (
        snapshotName.includes(str) || snapshotLineItemAccountID.includes(str)
      );
    });
  }, [data, searchText]);

  return (
    <Modal isOpen showCloseButton onClose={props.onClose} minWidth={1100}>
      <Modal.Header>
        <Flex
          justifyContent="space-between"
          marginLeft={theme.space_sm}
          width="100%"
        >
          <Text fontSize={theme.h4_fontSize}>{subTableTitle}</Text>

          <Flex alignItems="center">
            <Box width={250} marginRight={theme.space_lg}>
              <TextInput
                disabled={!props.snapshots.length}
                iconEnd={
                  <Icon color={theme.text_color_secondary} icon={faSearch} />
                }
                placeholder={copyText.searchInputPlaceholder}
                size="medium"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Box>

            <CSVLink
              data={getCSVData(filteredData).rows}
              headers={getCSVData(filteredData).headers}
              filename={`${subTableTitle}-${formatDate(new Date(), "MM-dd-yyyy")}`}
            >
              <Button
                iconStart={<Icon color="inherit" icon={faFileExport} />}
                primary
                size="small"
                marginRight={theme.space_md}
              >
                {copyText.exportButtonLabel}
              </Button>
            </CSVLink>
          </Flex>
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <Box width="100%" overflowX="auto">
          <Table
            columns={columns}
            data={filteredData}
            initialState={{ sorting: [{ id: "totalCost", desc: true }] }}
            isLoading={props.isLoading}
            showPagination
            sortable
          />
        </Box>
      </Modal.Body>
    </Modal>
  );
}

const csvAccessors = [
  "snapshotId",
  "lineItemUsageAccountId",
  "snapshotUsageBytes",
  "totalCost",
] as const;

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

function getCSVData(data: TableData[]): CSVData {
  if (!data.length) {
    return { headers: [], rows: [] };
  }

  const rows = data.map((datum) => ({
    snapshotId: datum.snapshotId,
    lineItemUsageAccountId: datum.lineItemUsageAccountId,
    snapshotUsageBytes: datum.snapshotUsageBytes,
    totalCost: datum.totalCost,
  }));

  const headers = csvAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `ebsSubTableSnapshotGroupHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}
