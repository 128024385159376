import Modal from "@/ui-lib/components/Modal";
import { useTheme } from "@emotion/react";
import { TimeGranularity } from "@ternary/api-lib/analytics/enums";
import { Filter } from "@ternary/api-lib/analytics/ui/types";
import {
  formatCurrency,
  formatPercentage,
} from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import {
  AlertRuleConditionType,
  AlertRuleDirection,
  AlertRuleStatus,
} from "@ternary/api-lib/constants/enums";
import { AlertRuleEventEntity } from "@ternary/api-lib/core/types";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import Box from "@ternary/web-ui-lib/components/Box";
import React from "react";
import copyText from "../copyText";
import {
  getStringifiedDirection,
  getStringifiedFilters,
  getStringifiedGranularity,
  isRuleCreatedEvent,
} from "../utils";

export const SIDE_DRAWER_WIDTH = "35rem";

interface RuleEventObject {
  condition?: {
    direction: AlertRuleDirection;
    lookbackDays: number;
    minimumDelta: number;
    sensitivity: number;
    type: AlertRuleConditionType;
  };
  dimensions?: string[];
  filters?: Filter[];
  timeGranularity?: TimeGranularity;
  status?: AlertRuleStatus;
}

interface Props {
  isOpen: boolean;
  event: AlertRuleEventEntity;
  onClose: () => void;
}

export default function RuleDetailModal(props: Props) {
  const theme = useTheme();

  function renderUpdatedField(
    field: RuleEventObject,
    index: number
  ): JSX.Element {
    // Note: ignoring subscriber and name changes
    if (field.condition) {
      return (
        <tbody key={index}>
          <tr>
            <td>
              <Text bold marginRight={theme.space_xl}>
                {copyText.eventStreamLabelChangesAlertType}
              </Text>
            </td>
            <td>
              <Text>{field.condition.type}</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text bold marginRight={theme.space_xl}>
                {copyText.eventStreamLabelChangesMinimumThreshold}
              </Text>
            </td>
            <td>
              <Text>
                {field.condition.sensitivity > 0
                  ? formatPercentage(field.condition.sensitivity)
                  : formatCurrency({
                      number: field.condition.minimumDelta,
                    })}
              </Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text bold marginRight={theme.space_xl}>
                {copyText.eventStreamLabelChangesDirection}
              </Text>
            </td>
            <td>
              <Text>{getStringifiedDirection(field.condition.direction)}</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text bold marginRight={theme.space_xl}>
                {copyText.eventStreamLabelChangesLookback}
              </Text>
            </td>
            <td>
              <Text>{`${field.condition.lookbackDays} ${copyText.eventStreamLabelChangesDays}`}</Text>
            </td>
          </tr>
        </tbody>
      );
    } else if (field.dimensions) {
      return (
        <tbody key={index}>
          <tr>
            <td>
              <Text bold marginRight={theme.space_xl}>
                {copyText.eventStreamLabelChangesDimensions}
              </Text>
            </td>
            <td>
              <Text>
                {field.dimensions.join(", ") || copyText.stringifiedEmptyValue}
              </Text>
            </td>
          </tr>
        </tbody>
      );
    } else if (field.filters) {
      const filterList = getStringifiedFilters(field.filters);
      return (
        <tbody key={index}>
          <tr>
            <td style={{ verticalAlign: "top" }}>
              <Flex>
                <Text bold marginRight={theme.space_xl}>
                  {copyText.eventStreamLabelChangesFilters}
                </Text>
              </Flex>
            </td>
            <td>
              {filterList.length === 0 ? (
                <Text>{copyText.stringifiedEmptyValue}</Text>
              ) : (
                <Box>
                  {filterList.map((filter, index) => {
                    return <Text key={filter + index + "f"}>{filter}</Text>;
                  })}
                </Box>
              )}
            </td>
          </tr>
        </tbody>
      );
    } else if (field.status) {
      return (
        <tbody key={index}>
          <tr>
            <td>
              <Text bold marginRight={theme.space_xl}>
                {copyText.eventStreamLabelChangesStatus}
              </Text>
            </td>
            <td>
              <Text>{field.status}</Text>
            </td>
          </tr>
        </tbody>
      );
    } else if (field.timeGranularity) {
      return (
        <tbody key={index}>
          <tr>
            <td>
              <Text bold marginRight={theme.space_xl}>
                {copyText.eventStreamLabelTimeGranularity}
              </Text>
            </td>
            <td>
              <Text>{getStringifiedGranularity(field.timeGranularity)}</Text>
            </td>
          </tr>
        </tbody>
      );
    } else return <Box key={index}></Box>;
  }

  return (
    <Modal
      title={
        isRuleCreatedEvent(props.event)
          ? copyText.eventStreamRuleCreatedTitle
          : copyText.eventStreamRuleUpdatedTitle
      }
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <Box
        marginTop={`-${theme.space_xxl}`}
        minWidth={`calc(${SIDE_DRAWER_WIDTH} - ${theme.space_jumbo})`}
      >
        <Text marginBottom={theme.space_md}>
          {isRuleCreatedEvent(props.event)
            ? copyText.eventStreamCreatedText
            : copyText.eventStreamChangesText}
        </Text>
        <table>
          {Object.entries(props.event.data).map(([key, value], index) => {
            return renderUpdatedField({ [key]: value }, index);
          })}
        </table>
      </Box>
    </Modal>
  );
}
