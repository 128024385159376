import Meter, { getChangePercent } from "@/components/Meter";
import { useTheme } from "@emotion/react";
import { DurationType } from "@ternary/api-lib/analytics/enums";
import { formatCurrency } from "@ternary/api-lib/analytics/utils/NumberFormatUtils";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import React from "react";
import copyText from "../../copyText";
import { getStringifiedDurationType } from "../../utils";

type RateRecommendation = {
  estimatedMonthlySavings: number;
};
type UsageRecommendation = {
  estimateValue: number;
};

interface Props {
  dateRange: Date[];
  durationType: DurationType;
  isLoading: boolean;
  previousPeriodSpend: number;
  rateRecommendations: RateRecommendation[];
  selectedPeriodSpend: number;
  usageRecommendations: UsageRecommendation[];
}

export default function GcpBigQueryMeters(props: Props): JSX.Element {
  const theme = useTheme();

  const spendComparison = getChangePercent({
    biggerIsBetter: false,
    value: props.selectedPeriodSpend,
    comparison: props.previousPeriodSpend,
    theme,
  });

  const usageRecommendationSavings = props.usageRecommendations.reduce(
    (accum, recommendation) => {
      return accum + recommendation.estimateValue;
    },
    0
  );

  const rateRecommendationSavings = props.rateRecommendations.reduce(
    (accum, recommendation) => {
      return accum + recommendation.estimatedMonthlySavings;
    },
    0
  );

  return (
    <Flex justifyContent="space-between">
      <Meter
        footerColor={spendComparison.color}
        footerIcon={spendComparison.icon}
        footerTitle={copyText.bigQueryMeterSpendComparisonCaption}
        footerValue={spendComparison.textValue}
        header={copyText.bigQueryMeterSpendComparisonLabel.replace(
          "%RANGE%",
          getStringifiedDurationType(props.durationType, props.dateRange)
        )}
        loading={props.isLoading}
        value={formatCurrency({ number: props.selectedPeriodSpend })}
        width="25%"
      />
      <Box
        borderRight={`1px solid ${theme.border_color}`}
        marginHorizontal={theme.space_lg}
      />
      <Meter
        footerColor={theme.text_color_secondary}
        footerTitle={copyText.bigQueryMeterUsageOptimizationsCaption}
        footerValue={formatCurrency({ number: usageRecommendationSavings })}
        header={copyText.bigQueryMeterUsageOptimizationsTitle}
        loading={props.isLoading}
        value={`${props.usageRecommendations.length}`}
        width="25%"
      />
      <Box
        borderRight={`1px solid ${theme.border_color}`}
        marginHorizontal={theme.space_lg}
      />
      <Meter
        footerColor={theme.text_color_secondary}
        footerTitle={copyText.bigQueryMeterRateOptimizationsCaption}
        footerValue={formatCurrency({ number: rateRecommendationSavings })}
        header={copyText.bigQueryMeterRateOptimizationsTitle}
        loading={props.isLoading}
        value={`${props.rateRecommendations.length}`}
        width="25%"
      />
    </Flex>
  );
}
