import isPropValid from "@emotion/is-prop-valid";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/pro-light-svg-icons";
import { DurationType } from "@ternary/api-lib/analytics/enums";
import { DateRange } from "@ternary/api-lib/analytics/utils";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React, { useRef, useState } from "react";
import CustomDatePickerContent from "../../../../ui-lib/components/DateRangeControls/CustomDatePickerContent";
import getMergeState from "../../../../utils/getMergeState";

const StyledSelect = styled("div", {
  shouldForwardProp: (propName) => isPropValid(propName),
})(({ theme }) => ({
  alignItems: "center",
  borderColor: theme.secondary_color_border,
  borderStyle: "solid",
  borderWidth: "1px",
  borderRadius: theme.borderRadius_2,
  cursor: "pointer",
  display: "flex",
  height: "38px",
  justifyContent: "space-between",
  paddingLeft: theme.space_xs,
  paddingRight: theme.space_xs,
  width: "100%",

  "&:hover": {
    borderColor: theme.primary_color_border,
  },

  "&:focus": {
    borderColor: "transparent",
    boxShadow: `0 0 0 2px ${theme.primary_color_focus}`,
  },
}));

type State = {
  endDate: string | null;
  invoiceMonth: string | null;
  isCustomDropdownOpen: boolean;
  isInvoiceMonth: boolean;
  startDate: string | null;
  tab: string;
};

const initialState = {
  endDate: null,
  invoiceMonth: null,
  isCustomDropdownOpen: false,
  isInvoiceMonth: true,
  startDate: null,
  tab: "invoice",
};

interface Props {
  endDate?: Date | null;
  invoiceMonth?: Date | null;
  startDate?: Date | null;
  onChangeDateRange: (dateRange: DateRange, isInvoiceMonth: boolean) => void;
}

export function DatePickerWithSelect(props: Props) {
  const theme = useTheme();

  const ref = useRef<HTMLDivElement>(null);

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  let dateRange: Date[] = [];
  let value: string | undefined = undefined;

  if (props.invoiceMonth) {
    dateRange = [props.invoiceMonth, props.invoiceMonth];
    value = formatDate(props.invoiceMonth, "MM-yyyy");
  } else if (props.startDate && props.endDate) {
    dateRange = [props.startDate, props.endDate];
    value = `${formatDate(props.startDate, "MM-dd-yyyy")} - ${formatDate(props.endDate, "MM-dd-yyyy")}`;
  }

  const CustomInput = (
    <StyledSelect ref={ref} tabIndex={-1}>
      <Flex alignItems="center">
        <Icon icon={faCalendar} />
        <Text color={theme.text_color_placeholder} marginLeft={theme.space_sm}>
          {value ? value : "Select"}
        </Text>
      </Flex>
      <Icon icon={faChevronDown} />
    </StyledSelect>
  );

  return (
    <CustomDatePickerContent
      dateRange={dateRange}
      durationType={DurationType.INVOICE}
      isOpen={state.isCustomDropdownOpen}
      showInvoice
      singleMonthInvoice
      onChangeDateRange={props.onChangeDateRange}
      onClose={() =>
        mergeState({ isInvoiceMonth: true, isCustomDropdownOpen: false })
      }
      onOpen={() => mergeState({ isCustomDropdownOpen: true })}
    >
      {CustomInput}
    </CustomDatePickerContent>
  );
}
