import { Theme } from "@emotion/react";
import { COMPARISON_KEY } from "../constants";

export const DASHED_MEASURES: Record<string, boolean> = {
  maxOfAverageCPUUtilization: true,
  maxOfAverageDiskUtilization: true,
  maxOfAverageGPUUtilization: true,
  maxOfAverageMemoryUtilization: true,
  minOfAverageCPUUtilization: true,
  minOfAverageDiskUtilization: true,
  minOfAverageGPUUtilization: true,
  minOfAverageMemoryUtilization: true,
  p50OfMaxCpuUtilization: true,
  p50OfMaxDiskUtilization: true,
  p50OfMaxGpuUtilization: true,
  p50OfMaxMemoryUtilization: true,
  p95OfMaxCpuUtilization: true,
  p95OfMaxDiskUtilization: true,
  p95OfMaxGpuUtilization: true,
  p95OfMaxMemoryUtilization: true,
  p99OfMaxCpuUtilization: true,
  p99OfMaxDiskUtilization: true,
  p99OfMaxGpuUtilization: true,
  p99OfMaxMemoryUtilization: true,
};

/**
 * Gets a color by reverse index
 */
export function getColorByReverseIndex(
  length: number,
  i: number,
  colors: string[]
): string {
  const index = (i - (length - 1)) * -1;
  const adjusted = index % colors.length;
  return colors[adjusted];
}

/**
 * Gets a fill color by index
 */
export function getFillByIndex(i: number, colors: string[]) {
  const length = colors.length;
  const adjusted = i % length;
  return colors[adjusted];
}

/**
 * Interface for custom colors
 */
export interface StrictCustomColors {
  [key: string]: string;
}

/**
 * Gets a fill color by grouping
 */
export function getFillByGrouping(key: string, colors: StrictCustomColors) {
  return colors[key];
}

/**
 * Gets green colors for environmental charts
 */
export function getGreenColors(isEnvironmental: boolean, themes: Theme) {
  const axisGreenColor = isEnvironmental
    ? themes.feedback_positive_background
    : themes.chart_axis_text;
  return { axis: axisGreenColor };
}

/**
 * Determines if a measure should be displayed with a dashed line
 */
export function getIsDashedMeasure(measure?: string | null) {
  if (typeof measure !== "string") return false;
  return DASHED_MEASURES[measure] || measure.includes(COMPARISON_KEY);
}
