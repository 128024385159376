import { UnitType } from "../enums";

const measures = [
  { name: "productMemoryBytes", unit: UnitType.BYTES },
  { name: "productVCPU", unit: UnitType.STANDARD },
  { name: "cost", unit: UnitType.CURRENCY },
  { name: "absoluteCredits", unit: UnitType.CURRENCY },
  { name: "usageAmount", unit: UnitType.STANDARD },
  { name: "cpuUtilization", unit: UnitType.PERCENTAGE },
  { name: "ramAvailableBytes", unit: UnitType.BINARY_BYTES },
  { name: "receivedBytes", unit: UnitType.BINARY_BYTES },
  { name: "sentBytes", unit: UnitType.BINARY_BYTES },
  { name: "diskReadBytes", unit: UnitType.BINARY_BYTES },
  { name: "diskWriteBytes", unit: UnitType.BINARY_BYTES },
];

const dimensions = [
  "family",
  "instanceType",
  "location",
  "operatingSystem",
  "projectId",
];

export const azureComputeVisibilitySchema = { dimensions, measures };
